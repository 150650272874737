import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {
    withStyles,
    Paper,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    CircularProgress,
    Grid,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Button,
    Checkbox, FormControlLabel,Divider
} from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {fetchErrorExpediente, formatMenssage} from "../../../../actions/expedientes";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {grey} from "@material-ui/core/colors";
import {Check, Search,RemoveRedEye} from "@material-ui/icons";
import Close from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ApiCoag from "../../../../api/ApiCoag";


import TablePagination from '@material-ui/core/TablePagination';

import { ValidatorForm } from 'react-material-ui-form-validator';
import Contacto from "../../../Agentes/contacto"
import {nifCifValido,tipoEntidadPersona,letra} from "../../../Agentes/contacto"
import {ltrim,rtrim} from "../../../../api/index";
import Confirmation from "../../../Errors/confirmation";
import {isMobile} from "../../../../api/index";

const styles = theme => ({
    marginPanel: {
        margin: '15px 0px'
    },
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: '100%',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    subtitle: {
        padding: "10px 20px"
    },
    tableBodyHeight: {
        minHeight: 100
    },
    headHeight: {
        height: 30
    },
    fab: {
        margin: theme.spacing.unit,
        marginTop: -25,
        position: 'absolute',
        marginLeft: '7%'
    },  
    centerText: {
        textAlign: "center"
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    paddingButtons: {
        padding: 15
    },
    button: {
        margin: theme.spacing.unit,
    },
    resultPanel: {
        padding: "20px 30px"
    },
    subtitleData: {
        marginTop: 10,
        fontWeight: "normal"
    },
    usericon: {
        margin: theme.spacing.unit,
        fontSize: 150,
    },
    mt0: {
        marginTop: -10
    },
    iconoption: {
        margin: theme.spacing.unit,
        color: theme.palette.text.primary
    },
    buttonEdit: {
        border: "1px solid",
        padding:4,
        margin: 2
    },
    selectAgent: {
        backgroundColor: theme.palette.background.main,
    },
    divGrey: {
        backgroundColor: grey[100],
        padding: 18,
        borderBottom: "1px solid #eee",
        borderTop: "1px solid #eee",
        width: '100%'
    }, 
     withoutRadius: {
        borderRadius: 0
      },
});
const CustomTableHead = withStyles(theme => ({
    head: {
        backgroundColor: grey[100],
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CODIGOFUNCIONDE='DE';
const IDTIPOAGENTEAT=1;

const emptyAgent={
    "Id_Entidad": -1,
    "Nif": "",
    "Id_Tipo_Entidad":1,
    "Id_Tipo_Organismo":null,
    "Nombre": "",
    "Apellido1": "",
    "Apellido2": '',
    "Mail": "" ,
    "Id_Tipo_Agente":IDTIPOAGENTEAT,
    "Firma":1,
    "Porcentaje":'',
    "Ids_funciones":CODIGOFUNCIONDE,
    'Funciones':[CODIGOFUNCIONDE],
    "PorcentajesEquitativos":1,
    "Numero_Colegiado":'',
    "Mail": "",
    "Telefono": "",
    "Calle": "",
    "Numero": "",
    "Piso": "",
    "Codigo_Postal": "",
    "Porcentaje": 100,
    "PorcentajesEquitativos": 1,
    "Id_Concello": null,
    "Id_Provincia": 15,
    "Id_Autonomia": 71,
    "Id_Pais": 100,
    'acceptTerm1':false,
    "acceptTerm2":false,
}

class OtrosAgentes extends Component {
    constructor() {
        super();
        this.state = {
            expandSectionTempFile: false,
            OtrosAgentes:[],
            loadingData: true,
            text:'',
            tiposAgentes:[],
            showResults:false,
            respuestaCalculo:false,
            tiposUso: [],
            resultados:[],
            showDetalles:false,
            fetchingSave:false,
            loadingSearch:false,
            showSearchBox:false,
            showAddAgentForm:false,
            fetchingSaveAgent:false,            
            customAgent:emptyAgent,
            currentPage: 0,
            rowsPerPage: 5,
            totalRecords: 100,
            totalPages: 10,
        }
    }

    componentDidMount() {

        this.loadData();

        ValidatorForm.addValidationRule('isNif', (value) => {               
            return nifCifValido(value)
         });
           
 
    }

    

    async loadData(){

        let tiposAgentes = await ApiCoag.tipos.tiposOtrosAgentes();
        let funcionesOtrosAgentes = await ApiCoag.tipos.guia.funcionesOtrosAgentes();
        if(tiposAgentes.MensajesProcesado&&tiposAgentes.MensajesProcesado.length>0){
            this.props.fetchErrorExpediente(tiposAgentes)
        }

       await this.loadAgentes()


        this.setState({
            loadingData:false,

            tiposAgentes:tiposAgentes.Tipos_Otros_Agentes,
            funcionesTipologia:funcionesOtrosAgentes.Tipos_Trabajos_Tunciones
        })
    }
    async loadAgentes(){
        let agentes = await ApiCoag.expedientes.trabajo.otrosAgentes(this.props.work.Id_Expediente,this.props.work.Id_Trabajo);
        if(agentes.MensajesProcesado&&agentes.MensajesProcesado.length>0){
            this.props.fetchErrorExpediente(agentes)
        }

       this.setState({
            OtrosAgentes:agentes.OtrosAgentes,
            idEntidadEliminar:-1,
            deleting:false
        })
    }
    async addAgent(){
        this.setState({
            showSearchBox:true,
            showAddAgentForm:false,
            showResults:false
        })

        this.resituar()     


    }
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
            showResults:false
        });
    };

    handleBlur = (name) => event => {          
          this.setState({
            [name]: rtrim(ltrim(event.target.value)),
            showResults:false
        }); 
    }

    handleChangeCustomAgent = name => event => {
        let {customAgent}= this.state;
        if(name=='Funciones'){
            let tag = event.target.tagName === "SPAN" ? event.target.parentNode : event.target;
            let functionCode = event.target.tagName === "SPAN"
                ? event.target.textContent
                : event.target.firstChild.textContent;

            if (!customAgent.Funciones.some(x => x === functionCode)) {
                customAgent.Funciones.push(functionCode);
                tag.className = tag.className + " slectedFunction";
                this.setState({customAgent });
            }
            else {
                customAgent.Funciones = customAgent.Funciones.filter(x => x !== functionCode);
                tag.className = tag.className.replace("slectedFunction", "");
                this.setState({ customAgent });
            }
        }else{
            let {customAgent}= this.state;
            if (event.target.type === 'checkbox')
                customAgent[name]=event.target.checked?1:0
            else
                customAgent[name]=event.target.value
           
                this.setState({
                    customAgent,

                });
        }

    };


    handleChangeContacto (agentContacto,nameCampoContacto) {
        let agent = {};
        Object.assign(agent, this.state.customAgent);
       
      
        agent[nameCampoContacto] = agentContacto[nameCampoContacto] ;                 
   
        agent['Id_Tipo_Entidad']=1;
        this.setState({ customAgent: agent })
    };

    handleCancel(){
        this.setState({
            showSearchBox:false,
            showResults:false,
            text:null
        })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
           this.handleSearch()
        }
    }


    // async handleSearchOld() {
    //     this.setState({loadingSearch: true,showResults:false});
    //     try {
    //         let result =await  ApiCoag.general.buscadorOtrosAgentes(this.state.text);
    //         this.setState({
    //             resultados:result.OtrosAgentes,
    //             loadingSearch:false,
    //             showResults:true

    //         })

    //         this.resituar()     

    //     }
    //     catch (e) {
    //         this.setState({loadingSearch: false});
    //     }

    // }


    async search(){
        let result  = await ApiCoag.general.buscadorOtrosAgentes(rtrim(ltrim(this.state.text)),  (this.state.currentPage + 1), this.state.rowsPerPage);       
        if (result ) {
          let pagination = result .Paginacion && result.Paginacion.length > 0
            ? result.Paginacion[0] : {};
          this.setState({

            resultados:result.OtrosAgentes,
            loadingSearch:false,
            showResults:true,                    
            
            totalRecords: pagination.Numero_Total_Registros,
            totalPages: pagination.Numero_Paginas,
           

          });
        }
    }

 

    async handleSearch() {
        this.setState({loadingSearch: true,showResults:false});
       
        await this.search();

        this.resituar()     

     }
       
    async resituar(){
        if (this.element)
        this.element.scrollIntoView({ behavior: 'smooth', block: "start" });           
    }
    

      handleChangePage = async (event, page) => {
        this.state.currentPage = page;
        await this.search();
      };
    
      handleChangeRowsPerPage = async (event) => {
        this.state.rowsPerPage = event.target.value;
        await this.handleSearch();
      };
    

    async addAgentForm(){

        this.setState({
            showAddAgentForm:true,
           showSearchBox: false
        });

        this.resituar()     


    }
    async cancelEdit(){
        this.setState({
            showAddAgentForm:false,
            showSearchBox: false            
        });
        this.emptyCustomAgent()
    }
    
    ValidateEmail(mail)
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true)
        }

        return (false)
    }


 

    async handleSubmit(){  
        this.handleAddAgent()
    }

    async handleAddAgent(){      
        
        let {customAgent} = this.state;    

        if (this.ValidateEmail(customAgent.Mail)==false){    
            return  this.props.fetchErrorExpediente(formatMenssage("Datos de contacto: Introduce un email válido."))            
         }   
                 
        
        let selected= this.state.funcionesTipologia.filter((item)=>this.state.customAgent.Funciones.includes(item.Codigo));
        let s=[];
        selected.map((item,pos)=>{
            s.push(item.id_Funcion);
        })
        customAgent.Ids_Funciones=s.toString();
        let response = null;
        this.setState({fetchingSaveAgent:true})
        if(customAgent.update){
            response = await ApiCoag.expedientes.trabajo.putOtroAgenteTrabajo(this.props.work.Id_Expediente,this.props.work.Id_Trabajo,{'OtrosAgentes':[customAgent]});
        }else{
             response = await ApiCoag.expedientes.trabajo.postOtrosAgentes(this.props.work.Id_Expediente,this.props.work.Id_Trabajo,{'OtrosAgentes':[customAgent]});
        }
        this.setState({fetchingSaveAgent:false})


        if (response=='ERROR2') {//(response.MensajesProcesado&&response.MensajesProcesado.length>0){
            //this.props.fetchErrorExpediente(response)
        }
        else{
            this.loadAgentes();
            this.setState({showAddAgentForm:false,showSearchBox: false,showResults:false });                
            this.emptyCustomAgent()
            this.props.isNewOrDelAgent();
        }
    }

    async emptyCustomAgent (){
        let  emptyAgentCopia= {...emptyAgent};
        
        this.setState({customAgent:emptyAgentCopia});
    }
    async selectAgent(agente,update=false){

        await this.emptyCustomAgent();
        let {customAgent}= this.state;
        Object.keys(agente).map((key)=>{
            customAgent[key]=agente[key];
        });
        if(agente.Funcion){

            customAgent.Funciones=agente.Funcion.split(',');
        }
        if(update){
            customAgent.update=true;
            customAgent.acceptTerm1=true;
        }
       
        
        
        this.setState({customAgent,  showAddAgentForm:true, showSearchBox: false});
        //this.addAgentForm()

    }

   


    async deleteAgente(idEntidad){
       
        this.setState({openCon:false,deleting:true})

        let response = await ApiCoag.expedientes.trabajo.deleteOtroAgenteTrabajo(this.props.work.Id_Expediente,this.props.work.Id_Trabajo,idEntidad)
        if(response=='ERROR2'){ //(response.MensajesProcesado&&response.MensajesProcesado.length>0){
           // this.props.fetchErrorExpediente(response)
           this.setState({idEntidadEliminar:-1,deleting:false})
        }
        else        
        {   
          
            if (this.state.customAgent.Id_Entidad==idEntidad){
            this.setState({ showAddAgentForm:false}) 
            }
            await this.loadAgentes();            

            this.setState({idEntidadEliminar:-1,deleting:false})
           
            this.props.isNewOrDelAgent();
        }            
    }

    renderSearchContainer(){
        let {classes} = this.props;
        let nombre= this.props.translate("languages.generalText.nombre") 
        if(this.state.showSearchBox)
        return(
            <Grid container >
                <Grid item xs={128}></Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <FormControl className={classes.formControl} style={{width:'100%'}}>
                        <TextField
                            id="filter"

                            placeholder={'Buscar por  NIF, ' +nombre}
                            value={this.state.text}
                            onKeyDown={this._handleKeyDown}
                            onChange={this.handleChange('text')}
                            onBlur={this.handleBlur('text')}
                            margin="normal"
                            autoFocus={true}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <div className="d-flex justify-content-center" style={{width:'100%'}} >
                        <Button color="primary" className={classes.button} disabled={this.state.loadingSearch} onClick={async ()=>{await this.handleCancel()}}>
                            <Translate id="languages.generalButton.cancel"/>
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} disabled={this.state.loadingSearch} onClick={async ()=>{await this.handleSearch()}}>
                            <Translate id="languages.generalButton.search"/>
                            <Search className="ml-3" />
                        </Button>
                        {this.state.loadingSearch && <CircularProgress className="align-self-center" size={24}/>}
                    </div>
                </Grid>
                <Grid item xs={2}></Grid>
               
                

                {   this.state.showResults ?
                            this.state.resultados.length>0?

                      <div style={{width:'100%'}}>
                            <Grid item xs={2}><br></br></Grid>
                            <Paper className={`${classes.withoutRadius} text-center`} style={{width:'100%'}}>
                                <Grid container>
                                     
                                    <Grid item xs={12} style={{paddingTop:10}}>
                                    {/*<Divider />*/}
                                    <Typography variant={"subtitle2"} gutterBottom className="text-center p-2">
                                         <Translate id="languages.generalText.resultados" />
                                   </Typography>
                                    <Divider />
                                        <Table className={classes.table} >
                                            <TableHead>
                                                <TableRow className={classes.headHeight}>
                                                    <CustomTableHead className="text-uppercase px-3">NIF</CustomTableHead>
                                                    <CustomTableHead className="p-0 text-left text-uppercase">
                                                    {nombre}
                                                    </CustomTableHead>
                                                    <CustomTableHead className="px-3 text-center text-uppercase"></CustomTableHead>

                                                </TableRow>
                                            </TableHead>

                                            <TableBody className={classes.tableBodyHeight}>
                                                {
                                                    this.state.resultados.map((agente,pos)=>

                                                            <TableRow className={classes.headHeight} >
                                                                <TableCell>{agente.Nif}</TableCell>
                                                                <TableCell>{agente.Nombre_Completo}</TableCell>
                                                                <TableCell style={{textAlign:"right"}}>
                                                                    <IconButton className={classes.buttonEdit} aria-label="Edit" color="primary"
                                                                                onClick={() => { this.selectAgent(agente) }}>
                                                                        <Check />
                                                                    </IconButton >
                                                                </TableCell>

                                                            </TableRow>

                                                    )

                                                }
                                            </TableBody>
                                        </Table>

                                       
      { /*si no hay mas de 10 qu es el tamaño de página no mostramos info de paginación */
      this.state.totalRecords>5  ?      
     
      <Translate>
        { 
          ({ translate }) => <TablePagination
            labelRowsPerPage={`${ translate("languages.trabajo.otrosAgentes") } ${translate("languages.promotores.itemsPerPage")}`}
            rowsPerPageOptions={[5,10]}
            component="div"
            count={this.state.totalRecords}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.currentPage}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage} />
        }
      </Translate>
        
     
     : null }
                                    </Grid>
                                </Grid>                             
                              </Paper>
                              </div>
                                :
                                <Grid container>
                                    <Grid item xs={12} style={{paddingTop:16}}>
                                        <Typography variant={"subtitle2"} style={{textAlign:"center"}}><Translate id="languages.generalText.sinResultados" /></Typography>
                                    </Grid>
                                            </Grid>
                                            : null                                       

                                       
                                           
                }

                <Grid item xs={12} style={{textAlign:"left",paddingTop:this.state.showResults && this.state.resultados.length>0?12:0}}>
                    <Button color="primary" className={classes.button} disabled={this.state.loadingSearch} onClick={async ()=>{this.emptyCustomAgent();await this.addAgentForm()}}>
                    <Translate id="languages.generalButton.annadirNuevo" />
                    </Button>
                </Grid>


         
              
                
               

            </Grid>

        )
    }
    renderAddAgent(){
        let {classes} =this.props
        let {customAgent} = this.state;      
        let tipoagente="TIPO DE " +this.props.translate("languages.trabajo.agente")
        let isMobile_=isMobile();

        let datosContactoIncompletos=false;       
        datosContactoIncompletos=customAgent.Calle=='' || customAgent.Calle==null || customAgent.Id_Concello==null || customAgent.Mail==null || customAgent.Mail=='';       
                

        if(this.state.showAddAgentForm){
            return (

                <ValidatorForm
                ref="form"
                onSubmit={() => { this.handleSubmit() }}>

                <Grid container>
             
                
                <Contacto key={customAgent.update?customAgent.Nif:'nuevo'} 
                              tipoAgente='otros' tipoEntidad={tipoEntidadPersona} 
                              agente={customAgent} 
                              datosContactoEnGrupo={true}
                              datosContactoEnGrupoExpandidos={customAgent.Id_Entidad===-1 || (this.props.work.SePuede_Editar && customAgent.Id_Entidad>0 && datosContactoIncompletos)}
                              modoConsulta={!this.props.work.SePuede_Editar}                              
                              modoConsultaConEdicionDatosContacto={!this.props.work.SePuede_Retirar}                                                         
                              onAddEditContacto={(customAgent,namecampo) => this.handleChangeContacto(customAgent,namecampo)} />
 
 <div className={`${classes.divGrey} `}>            
      <Grid item xs={12} style={!isMobile_?{backgroundColor:'#f5f5f5',padding:'12px 24px'}:{backgroundColor:'#f5f5f5',paddingLeft:'5px'}}>
                        <Grid container >
                            <Grid item xs={12} >
                                <Typography variant='button' style={{float:"left"}}>
                                    Características
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl2} style={{width:'80%'}}>
                                    <label className='MuiFormLabel-root-214'
                                           style={{marginTop: 12, marginBottom: -12,color:'rgba(0, 0, 0, 0.54)',fontSize:9}}>{tipoagente.toUpperCase()} *</label>
                                    <Select classes={{select: classes.uppercase}}
                                            id='Id_Tipo_Agente'
                                            label={tipoagente.toUpperCase()}
                                            onChange={
                                                this.handleChangeCustomAgent('Id_Tipo_Agente')
                                                }
                                            value={customAgent.Id_Tipo_Agente}
                                            inputProps={{
                                                name: 'Descripcion',
                                                id: 'Id_Tipo_Agente',
                                            }}
                                            required={true}
                                            disabled={!this.props.work.SePuede_Editar}
                                    >

                                        {this.state.tiposAgentes.map(item => (
                                            <MenuItem className="text-uppercase"
                                                      value={item.Id_Tipo_Agente}>{item.Descripcion}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                               
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl1} style={{width:'50%'}}>
                                    <TextField
                                        id="Numero_Colegiado"
                                        label={('Número de '+ this.props.translate("languages.generalText.colegiado")).toUpperCase()}
                                        //type={'number'}
                                        value={customAgent.Numero_Colegiado}
                                        onChange={this.handleChangeCustomAgent('Numero_Colegiado')}
                                        margin="normal"
                                        disabled={!this.props.work.SePuede_Editar}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className="functionTipology">
                                <Typography variant="body2" className={`${classes.subtitleData} text-uppercase`}>
                                    <Translate id="languages.agentes.funciones" /> *
                                </Typography>
                                {
                                    this.state.funcionesTipologia.map((valueTiplogia, indexCode) => {
                                        return <Button onClick={this.handleChangeCustomAgent("Funciones")}
                                                       className={this.state.customAgent.Funciones.some(x => x === valueTiplogia.Codigo) ? "slectedFunction" : ""}
                                                       variant="contained"
                                                       key={indexCode}
                                                       disabled={!this.props.work.SePuede_Editar}>
                                                        {valueTiplogia.Codigo}
                                                      
                                        </Button>
                                    })
                                }

                        <Grid item xs={12}>     
                              
                              <FormControl className={classes.formControl1} style={{width:'100%',flexDirection:'row'}}>
                                   
                                   <FormControlLabel
                                       style={{width:'85%',marginLeft:'0%'}}
                                       control={
                                           <Checkbox
                                               checked={customAgent.PorcentajesEquitativos}
                                               onChange={this.handleChangeCustomAgent("PorcentajesEquitativos")}
                                               disabled={!this.props.work.SePuede_Editar}
                                               color="primary"/>
                                       }
                                       label={<Translate id="languages.agentes.percentLabelAgente" />} />                                      
                                   

                               </FormControl>
                               </Grid>

                               {!customAgent.PorcentajesEquitativos && <Grid item xs={12} >                                 

                                 <FormControl style={{width:'100%',flexDirection:'row'}}>
                                                                                                  
                                    <TextField
                                       id="porcentaje" 
                                       label={this.props.translate("languages.agentes.percentTitle").toUpperCase()}
                                       type={'number'}
                                       style={{width:'15%', marginLeft:'3%' }}
                                       value={customAgent.Porcentaje}
                                       onChange={this.handleChangeCustomAgent('Porcentaje')}
                                       margin="normal"
                                       disabled={customAgent.PorcentajesEquitativos}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}

                                   />    
                               </FormControl>
                               </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                
                    </div>
                    
                   
                
                 <div className={`${classes.divGrey} `}>             
                    <Grid item xs={12} style={{paddingLeft:24,paddingRight:24,paddingTop:8}}>
                        {!customAgent.acceptTerm1
                            ? <Typography variant="caption" gutterBottom color="error" className="ml-3 pl-3">
                                <Translate id="languages.agentes.termsValidation" /> *
                            </Typography> : ""}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={customAgent.acceptTerm1 }
                                    onChange={this.handleChangeCustomAgent("acceptTerm1")}
                                    color="primary" />
                            }
                            label={<Translate id="languages.agentes.conditionTerm3" />} />


                    </Grid>
                    </div>
                    <Grid item xs={12} className="text-right" style={{paddingLeft:24,paddingRight:24}}>
                        {!this.state.fetchingSaveAgent &&  <Button color="primary" size="small" className={classes.button}
                                onClick={() => { this.cancelEdit() }}>
                            <Translate id="languages.generalButton.cancel" /><Close className={classes.rightIcon} />
                        </Button>}
                        {!this.state.fetchingSaveAgent && (this.props.work.SePuede_Editar || (!this.props.work.SePuede_Editar && !this.props.work.SePuede_Retirar)) ? 
                            <Button type="submit" variant="contained" size="small" color="primary" className={classes.button}
                              
                                disabled={customAgent.acceptTerm1 && customAgent.Funciones.length > 0                             
                                && ((customAgent.Porcentaje !== "" && customAgent.Porcentaje !=='0')|| customAgent.PorcentajesEquitativos) ? false : true}>
                            {
                                customAgent.update? <Translate id="languages.generalButton.modification" />:
                                    <Translate id="languages.generalButton.added" />
                            }


                        </Button>:null}
                        {this.state.fetchingSaveAgent && <CircularProgress className="align-self-center" size={24}/>}
                    </Grid>
                </Grid>

</ValidatorForm>
            )
        }
    }




    render() {
        let {classes} = this.props;
        let marginLeftLista=!isMobile()? 24:6;

        return (
            <div ref={element => { this.element = element; }} style={{marginTop: 10}} >
           
                {
                    this.state.loadingData ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                     
                         
                      
                        <Paper
                            style={{borderColor: '#cecece', borderWidth: 1, marginBottom: 10}}>
                            <ExpansionPanel expanded={this.state.expandSectionTempFile}
                                            onChange={() => this.setState({expandSectionTempFile: !this.state.expandSectionTempFile})}>
                                <ExpansionPanelSummary style={{paddingLeft: 8}} expandIcon={<ExpandMoreIcon/>}>
                                    <div className="d-flex justify-content-between align-items-center"
                                         style={{width: "100%", marginLeft: 10}}>
                                        <div>
                                            <Typography variant='button' style={{float:"left"}}>
                                            <Translate id="languages.trabajo.otrosAgentes" />
                                            </Typography>
                                            {this.state.OtrosAgentes.length==0 && this.props.work.SePuede_Editar == 1 && this.props.work.Necesita_De == 1
                                            &&
                                             <Typography variant='button'
                                                        style={{color: '#ffc107', float: "left", marginLeft: 5}}> <Translate id="languages.generalButton.sinCompletar" /></Typography>}
                                        </div>

                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{padding: "8px 0px 0px"}}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {
                                                this.state.expandSectionTempFile&&<Grid container style={{paddingLeft:24,paddingRight:24}}>
                                                    <Grid item xs={10} md={10} className={classes.subtitle}></Grid>
                                                    <Grid item xs={2} md={2}>
                                                    {this.props.work.SePuede_Editar &&
                                                        <Fab size="small" color="primary" aria-label="Add"
                                                             className={classes.fab} onClick={() => { this.addAgent() }}>
                                                            <AddIcon />
                                                        </Fab>}
                                                    </Grid>
                                                </Grid>
                                            }

                                        </Grid>


                                        <Grid item xs={12}>
                                            <div style={{marginLeft: marginLeftLista, marginRight: marginLeftLista, marginTop: -25, marginBottom: 24}}>
                                                {
                                                    this.state.OtrosAgentes.length?<Grid container>
                                                            <Grid item xs={12}>
                                                                <Table className={classes.table}>
                                                                    <TableHead>
                                                                        <TableRow className={classes.headHeight}>
                                                                            <CustomTableHead className="text-uppercase px-3">NIF</CustomTableHead>
                                                                            <CustomTableHead className="p-0 text- text-uppercase"><Translate id="languages.fichaExpediente.tableColumnName" /> </CustomTableHead>
                                                                            <CustomTableHead className="p-0 text-center text-uppercase"><Translate id="languages.agentes.funciones" /> </CustomTableHead>
                                                                            <CustomTableHead className="px-3 text-center text-uppercase"></CustomTableHead>

                                                                        </TableRow>
                                                                    </TableHead>

                                                                    <TableBody className={classes.tableBodyHeight}>
                                                                        {
                                                                            this.state.OtrosAgentes.length === 0 ?
                                                                                <TableRow>
                                                                                    <TableCell colSpan={5}></TableCell>
                                                                                </TableRow>
                                                                                :
                                                                                this.state.OtrosAgentes.map((agente)=>{
                                                                                    return (
                                                                                        <TableRow className={classes.headHeight} > 
                                                                                            <TableCell style={!isMobile?null:{padding:'0px 12px'}}>{agente.Nif}</TableCell>
                                                                                            <TableCell style={!isMobile?null:{padding:'0px 12px'}}>{agente.Nombre_Completo}</TableCell>
                                                                                            <TableCell style={!isMobile?null:{padding:'6px 6px'}}>{agente.Funcion}</TableCell>
                                                                                            <TableCell  style={{textAlign:"right"}}>
                                                                                                <IconButton className={classes.buttonEdit} color="primary" aria-label="Select"
                                                                                                            onClick={() => this.selectAgent(agente,true)}>
                                                                                                    {this.props.work.SePuede_Editar || (!this.props.work.SePuede_Editar && !this.props.work.SePuede_Retirar) ? <EditIcon />:<RemoveRedEye/>}
                                                                                                </IconButton>

                                                                                               
                                                                                                {this.props.work.SePuede_Editar ? 
                                                                                                this.state.deleting && this.state.idEntidadEliminar==agente.Id_Entidad?<CircularProgress size={22} /> :
                                                                                                <IconButton className={classes.buttonEdit} color="primary" aria-label="Delete"
                                                                                                onClick={() => { this.setState({openCon: true,idEntidadEliminar:agente.Id_Entidad,agentSel:agente.Nombre_Completo + " (Nif " + agente.Nif+")"  }) }}
                                                                                                >
                                                                                                    <DeleteIcon />
                                                                                                </IconButton> : null}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </Grid>
                                                        </Grid>:
                                                        <Grid  container>
                                                            <Grid item xs={12} style={{textAlign:"center"}}>
                                                                <Table className={classes.table}>
                                                                    <TableHead>
                                                                        <TableRow className={classes.headHeight}>
                                                                            <CustomTableHead className="text-uppercase px-3">NIF</CustomTableHead>
                                                                            <CustomTableHead className="p-0 text-center text-uppercase">  <Translate id="languages.fichaExpediente.tableColumnName" /></CustomTableHead>
                                                                            <CustomTableHead className="p-0 text-center text-uppercase"><Translate id="languages.agentes.funciones" /> </CustomTableHead>
                                                                            <CustomTableHead className="px-3 text-center text-uppercase"></CustomTableHead>

                                                                        </TableRow>
                                                                    </TableHead>

                                                                    <TableBody className={classes.tableBodyHeight}>
                                                                        <TableRow>
                                                                            <TableCell colSpan={4} >
                                                                                <Typography variant={"body1"} style={{textAlign:"center"}}><Translate id="languages.agentes.sinOtrosAgentes"/></Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>

                                                            </Grid>
                                                        </Grid>
                                                }
                                                <p></p>                                                
                                                {   
                                                    this.renderSearchContainer()
                                                }

                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                this.renderAddAgent()
                                            }
                                        </Grid>
                                    </Grid>

                                    <Confirmation openConfirmation={this.state.openCon}     
                                        title={'Confirmación' }                           
                                        confirmationMessage={this.props.translate("languages.agentes.delOtros") + '<br>' + this.state.agentSel }
                                        aceptConfirmation={async () => {
                                        this.setState({openCon: false})
                                        this.deleteAgente(this.state.idEntidadEliminar) }}                                         
                                        declineConfirmation={() => this.setState({openCon: false})}/>     


                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Paper>
                       
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps =
    {
        fetchErrorExpediente: fetchErrorExpediente
    };
    export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(OtrosAgentes)));