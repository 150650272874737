import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {
    withStyles,
    Paper,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    CircularProgress,
    Grid,   
    Button  
} from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactQuill from 'react-quill';
import {fetchErrorExpediente} from "../../../../actions/expedientes";

import TableCell from "@material-ui/core/TableCell";
import {grey} from "@material-ui/core/colors";
import ApiCoag from "../../../../api/ApiCoag";

const styles = theme => ({
    withoutRadius: {
        borderRadius: 0
    }
});
{/*const CustomTableHead = withStyles(theme => ({
    head: {
        backgroundColor: grey[100],
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);*/}


class ComunicacionVisado extends Component {
    constructor() {
        super();
        this.state = {
            expandSectionTempFile: false,
            comunicacion:'',
            fetchSave:false,
            loadingData: true,
            modified:false,
            exists:false

        }
    }

    componentDidMount() {

        this.loadData();
    }
    async loadData(){
        let comunicacion =  await ApiCoag.expedientes.trabajo.comunicacionTramitacion(this.props.work.Id_Expediente,this.props.work.Id_Trabajo);
        await this.setState({
            loadingData:false,
            comunicacion:comunicacion.ComunicacionTramitacion.length==0?'':comunicacion.ComunicacionTramitacion[0].Comunicacion,
            exists:comunicacion.ComunicacionTramitacion.length==0?false:true,
            modified:false
        })
    }


    handleChange = name => event => {
        this.setState({
            [name]: event.target.value        

        });
    };

    handleChangeCustom(value) {
        this.setState({ comunicacion: value ,   modified:true})
        //this.props.isModified(true);
    }

    async save(){
        await this.setState({fetchSave:true})
        let {comunicacion} = this.state;
       let result =  await ApiCoag.expedientes.trabajo.putComunicacionTramitacion(this.props.work.Id_Expediente,this.props.work.Id_Trabajo,{comunicacion});
        if(result.MensajesProcesado&&result.MensajesProcesado.length>0){
            //this.props.fetchErrorExpediente(result)
        }
        await this.setState({fetchSave:false,modified:false, exists:true})
        //await this.props.isModified(false);
    }

    async cancel(){
      
       await this.loadData();  
       await this.setState({modified:false})     
       //await this.props.isModified(false);
     
      
    }


    render() {
        let {classes} = this.props;
        return (
            <div style={{marginTop: 10}}>
                {
                    this.state.loadingData ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                        <Paper
                            style={{borderColor: '#cecece', borderWidth: 1, marginBottom: 10}}>
                            <ExpansionPanel expanded={this.state.expandSectionTempFile}
                                            onChange={() => this.setState({expandSectionTempFile: !this.state.expandSectionTempFile})}>
                                <ExpansionPanelSummary style={{paddingLeft: 8}} expandIcon={<ExpandMoreIcon/>}>
                                    <div className="d-flex justify-content-between align-items-center"
                                         style={{width: "100%", marginLeft: 10}}>
                                        <div>
                                          
                                            <Typography variant='button' style={{float:"left"}}>
                                            
                                            <Translate id="languages.trabajo.comVisado" />

                                               
                                            </Typography>

                                            {(this.props.esTrabajoNuevo!=null && (this.props.esTrabajoNuevo != 1 || this.props.work.Id_Estado == 2) && this.state.comunicacion == '') &&
                                            <Typography variant='button'
                                                        style={{color: '#ffc107', float: "left", marginLeft: 5}}>
                                                         <Translate id="languages.generalButton.sinCompletar" />
                                                </Typography>
                                            }

                                        </div>

                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{padding: "0px 24px 8px"}}>


                                <Grid container spacing={0} >
                                    <Grid item xs={12} >

                                                 <ReactQuill value={this.state.comunicacion}                                                                   
                                                                    placeholder={((this.props.work.Es_Trabajo_Nuevo != 1 || this.props.work.Id_Estado == 2) && this.state.comunicacion == '')  ? this.props.translate("languages.trabajo.helperTextComVisadoModInc"): this.props.translate("languages.trabajo.helperTextComVisado")}
                                                                    style={{ height: '150px' }}
                                                                   readOnly = {(this.props.work.Id_Estado==1 || this.props.work.Id_Estado>2)}                                                   
                                                                    onChange={(value)=>this.handleChangeCustom(value)} />

                                    </Grid>
                                                                                        
                                    <Grid item xs={12} >
                                                        <p><br></br></p>
                                    </Grid>
                                    <Grid item xs={12} >
                                                       <p></p>
                                    </Grid>
                                    <Grid item xs={12} style={{textAlign:"right"}}>
                                                <Button  disabled= {(!this.state.modified || (this.props.work.Id_Estado==1 || this.props.work.Id_Estado>2))} color="primary" onClick={async () => { await this.cancel() } }  >
                                                <Translate id="languages.generalButton.cancel" />
                                                    </Button>
                                                    {!this.state.fetchSave && <Button  className={classes.button} disabled= {this.props.work.Id_Estado==1 || this.props.work.Id_Estado>2 || this.state.modified === false} color="primary" onClick={async () => { await this.save() } }  >
                                                    <Translate id="languages.generalButton.save" />
                                                    </Button>}
                                                    {this.state.fetchSave && <CircularProgress className="align-self-center" size={22}/>}
                                                   
                                    </Grid>
                                            
                               </Grid>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Paper>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps =
    {
        fetchErrorExpediente: fetchErrorExpediente
    };
    export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(ComunicacionVisado)));