import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {Translate, withLocalize} from "react-localize-redux";
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {Grid} from '@material-ui/core';
import {fetchFuncionesTipologia} from '../../../actions/trabajos/index';
import '../indexstyle.css';
import FormArquitecto from './arquitecto';
import FormSociedad from './FormSociedad';
import SearchAgents from '../search';
import Confirmation from "../../Errors/confirmation";
import ApiCoag from "../../../api/ApiCoag";



const styles = theme => ({
  marginPanel: {
    margin: '15px 0px'
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 200,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  subtitle: {
    padding: "10px 20px"
  },
  tableBodyHeight: {
    minHeight: 100
  },
  headHeight: {
    height: 40
  },
  fab: {
    margin: theme.spacing.unit,
    marginTop: 25,
    position: 'absolute',
    marginLeft: 40
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300,
    textAlign: 'left',
    marginTop: 5
  },
  centerText: {
    textAlign: "center"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  paddingButtons: {
    padding: 15
  },
  button: {
    margin: theme.spacing.unit,
  },
  resultPanel: {
    padding: "20px 30px"
  },
  subtitleData: {
    marginTop: 10,
    fontWeight: "normal"
  },
  usericon: {
    margin: theme.spacing.unit,
    fontSize: 150,
  },
  mt0: {
    marginTop: -10
  },
  iconoption: {
    margin: theme.spacing.unit,
    color: theme.palette.text.primary
  },
  buttonEdit: {
    border: "1px solid",
    padding: 8,
    margin: 4
  },
  selectAgent: {
    backgroundColor: theme.palette.background.main,
  }
});

const CustomTableHead = withStyles(theme => ({
  head: {
    backgroundColor: grey[100],
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

class Arquitecto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: ["Nombre", "Nif"],
      selectedOption: "Nombre",
      searchQuery: "",
      canSearch: false,
      selectedAgent: null,
      encomenda: this.props.encomenda,
      encomendaActual: this.props.encomenda.EncomendaActual && this.props.encomenda.EncomendaActual.length > 0
        ? this.props.encomenda.EncomendaActual[0] : null,
      tipoBusqueda: "Colegiados",
      porcenEq:true
    }
  }

  

  async componentDidMount() {

    try {
      /*this.props.fetchFuncionesTipologia(this.props.activeLanguage.code,
        this.state.encomendaActual.Id_Tipo_Grupo_Tematico,
        this.state.encomendaActual.Id_Tipo_Autorizacion_Municipal);*/
        let response =  await ApiCoag.tipos.guia.funcionesTipologia(this.state.encomendaActual.Id_Tipo_Grupo_Tematico,  this.state.encomendaActual.Id_Tipo_Autorizacion_Municipal);
        if(response){
            this.setState({funcionesTipologia:response.Tipos_Trabajos_Tunciones})
        }  
      this.handleCanSearch(false);
      this.parseAgents(response.Tipos_Trabajos_Tunciones);
      this.loadLoguedAgent();
    }
    catch (e) {

    }
  }

  parseAgents(funcionesTipologia) {
    let agents = this.state.encomenda.Colegiados.map((agent) => {
    
      if (agent) {
        agent["Porcentaje"] = agent.Porcentaje;
        agent["acceptTerm1"] = true;
        agent["acceptTerm2"] = true;
        if (agent.Ids_Funciones) {
          agent=this.initInfoParaControlesFuncionesSel(agent,funcionesTipologia)
          if (agent["Funcion"] != null  && agent["Funcion"]=='')
          {agent["Funcion"] = agent.Funciones.join(",");
          agent["Funcion"] = agent["Funcion"]!=null &&  agent["Funcion"]!=''? agent["Funcion"].substring(0,agent["Funcion"].length) : ''
          }

          agent=this.excluirFuncionesNoPermitidas(agent,funcionesTipologia)

        }
      }
      return agent;
    })
    let newEncomenda = {};
    Object.assign(newEncomenda, this.state.encomenda);
    newEncomenda.Colegiados = agents;
    this.setState({ encomenda: newEncomenda })
  }

  initInfoParaControlesFuncionesSel(agent,funcionesTipologia){
    let arrayIds = agent.Ids_Funciones.split(",");
          agent["Funciones"] = arrayIds.map(f => {
            let fun = funcionesTipologia.find(x => x.id_Funcion === parseInt(f)); // this.props.funcionesTipologia.find(x => x.id_Funcion === parseInt(f));
            if (fun)
              return fun.Codigo;
            else
              return null
          });
    return agent
  }

  excluirFuncionesNoPermitidas(agent,funcionesTipologia){
    agent["Funcion"] = agent.Funciones.map(value => {
      let idFuncion = funcionesTipologia.find(x => x.Codigo === value);//this.props.funcionesTipologia.find(x => x.Codigo === value);
      if (idFuncion)
        return idFuncion.Codigo;
      else
        return -1
      }).join(",");
    agent["Funcion"]=agent["Funcion"].replaceAll(",-1","").replaceAll("-1,","").replaceAll("-1","");

    agent["Ids_Funciones"] = agent.Funciones.map(value => {
        let idFuncion = funcionesTipologia.find(x => x.Codigo === value);//this.props.funcionesTipologia.find(x => x.Codigo === value);
        if (idFuncion)
          return idFuncion.id_Funcion;
        else
          return -1          
        }).join(",");
        //quitamos eliminados marcados con -1
      agent["Ids_Funciones"]=agent["Ids_Funciones"].replaceAll(",-1","").replaceAll("-1,","").replaceAll("-1","");

      return agent
  }

  handleCanSearch = cansearch => {
    this.setState({ canSearch: cansearch})
  }

  handleCancelResults() {
   // this.setState({ showSearchResultInitial:false})
  }

  handleCancelAdd(){ 
    this.setState({  canSearch:false, showSearchResultInitial:false,searchResultInitial:false}) //
  }

  handleAdd() {
    this.handleCanSearch(true);
    this.setState({  selectedAgent:false,adding:true,colegiadosSociedad:false });
  }

  handleSelectedAgent(agent) {
    this.setState({ selectedAgent:agent, adding :false  });
  }

  addAgenteTrabajoToSelection(agent,arquitectos=false) {
    if (agent) {
      let arrayArquitectos=[];
      if(arquitectos){
        for(let i=0;i<arquitectos.length;i++){
          /* md corregir para que no falle con miembros de sociedad que no tienen funciones*/
         
          if (arquitectos[i].Funciones != null)
          {         
            arquitectos[i]=this.excluirFuncionesNoPermitidas(arquitectos[i],this.state.funcionesTipologia)
          }                           

          arrayArquitectos.push(arquitectos[i])
        }
      }
      //1- Se actualiza el agente
      agent["Porcentaje"] = agent.Porcentaje;
    
      agent["PorcentajesEquitativos"] = agent.percentChecked === true ? 1 : 0;
      if (agent.Apellido1 && agent.Apellido2)
        agent["Nombre"] = `${agent.Nombre_Completo} ${agent.Apellido2}, ${agent.Nombre}`

          /* md corregir para que no falle con arquitectos sociedad que no tienen funciones*/
      if (agent["Id_Tipo_Colegiado"]==1)
      {
          agent=this.excluirFuncionesNoPermitidas(agent,this.state.funcionesTipologia)
      }
            

      //2- Actualizar el estado del componente y con el la encomenda general. 
      let newEncomenda = {}
      Object.assign(newEncomenda, this.state.encomenda);
      
         //borramos colegiado. Si venimos de añadir un colegiado existente también se elimina y despues se vuelve a meter con los datos de la edicion
      if (newEncomenda.Colegiados.some(x => x.Nif === agent.Nif && agent.Id_Sociedad==0)) 
         newEncomenda.Colegiados = newEncomenda.Colegiados.filter(x => x.Nif !== agent.Nif || (x.Nif==agent.Nif && x.Id_Sociedad>0));


      //metemos colegiado
      newEncomenda.Colegiados.push(agent);

      //3- Si es colegiado sociedad modificar el listado de colegiados con los agentes colegiados que pertenecen a esa sociedad
      for(let i=0; i<arrayArquitectos.length;i++){
        if(arrayArquitectos[i].Id_Sociedad!=0){          
           if (newEncomenda.Colegiados.some(x => x.Id_Colegiado === arrayArquitectos[i].Id_Colegiado && arrayArquitectos[i].Id_Sociedad === x.Id_Sociedad) )             
            newEncomenda.Colegiados=newEncomenda.Colegiados.filter(x => {
            return !(x.Id_Colegiado === arrayArquitectos[i].Id_Colegiado && arrayArquitectos[i].Id_Sociedad === x.Id_Sociedad);
          });
         
          //metemos los miembros de la sociedad modificados
          newEncomenda.Colegiados.push(arrayArquitectos[i]);
        }
      }

       //4-recalcular porcentajes
       if (agent.percentChecked)       {  
         newEncomenda=this.recalcularPorcen(newEncomenda);
       }

 

      this.setState({ encomenda: newEncomenda, selectedAgent: false,adding:false,showSearchResultInitial:false,searchResultInitial:false,porcenEq:agent.percentChecked });

      this.props.updateEncomenda(newEncomenda);
    }
    this.handleCanSearch(false);
  }

 
recalcularPorcen(newEncomenda)
{  let porcen=100;
  let k=newEncomenda.Colegiados.filter(x => (x.Id_Sociedad== null || x.Id_Sociedad == 0)).length;; 
  
  { 
    if (k>1){
    porcen=porcen/k;}     
 
    for(let i=0; i<newEncomenda.Colegiados.length;i++){
    if(newEncomenda.Colegiados[i].Id_Sociedad==null || newEncomenda.Colegiados[i].Id_Sociedad==0){
      newEncomenda.Colegiados[i].Porcentaje=porcen;
    }}

    return newEncomenda
  }
} 
   
  

  deleteAgentSelection(id) {
    
    let newEncomenda = {}
    Object.assign(newEncomenda, this.state.encomenda);

   /* md importante, si borro un colegiado sociedad hay que borrar sus miembros,pero si borro un colegiado no tengo que borrar si este figura en una sociedad */
   newEncomenda.Colegiados = newEncomenda.Colegiados.filter(x => ((x.Id_Colegiado !== id && x.Id_Sociedad !== id)  || (x.Id_Colegiado == id && x.Id_Sociedad >0)))

    if (this.state.porcenEq)
    {  //4-recalcular porcentajes  
      newEncomenda=this.recalcularPorcen(newEncomenda);
    }

    this.props.updateEncomenda(newEncomenda);
    //* md al eliminar colegiado hay que cancelar su ficha de edición
    //* this.setState({ encomenda: newEncomenda})    
  
    this.setState({ encomenda: newEncomenda , selectedAgent: false,  adding : false })
  }



  editAgenteSeleccion(id) {
    let edit = this.state.encomenda.Colegiados.find(x => x.Id_Colegiado === id);
    if (edit) {
      if((edit.Id_Tipo_Colegiado>1)){
        let colegiados = this.getColegiadosSociedad(edit.Id_Colegiado);
        let agents = colegiados.map((agent) => {    
          if (agent) {         
            agent=this.initInfoParaControlesFuncionesSel(agent,this.state.funcionesTipologia)                          
            return agent;
          }         
          }
          ) 
          this.setState({colegiadosSociedad:agents})
      
      }else{
        edit.PorcentajesEquitativos=this.state.porcenEq;
        edit=this.initInfoParaControlesFuncionesSel(edit,this.state.funcionesTipologia)
        this.setState({colegiadosSociedad:false})      
      }
      /* md si estoy editando no se puede buscar*/     
      this.handleCanSearch(true)     
           
      this.setState({ selectedAgent: edit });

    }
  }

  existAgentSelected(id) {
    /* md importante filtrar con x.Id_Sociedad==0 para ver si existe colegiado*/
    return this.state.encomenda.Colegiados.some(x => (x.Id_Colegiado === id  &&  x.Id_Sociedad === 0));

  }

  
  /*async loadLoguedAgent() {
    if (this.state.encomenda.Colegiados && this.state.encomenda.Colegiados.length === 0
      && this.state.Colegiados && !this.state.Colegiados.some(x => x.Id_Colegiado === this.props.loguedUser.Id_Colegiado)) {
      let search = await ApiCoag.general.buscador(this.props.loguedUser.Id_Colegiado, this.state.tipoBusqueda, 1, 1);
      if (search  && search[this.props.tipoBusqueda]
        && search[this.props.tipoBusqueda].length > 0) {
        let loguedUser = search[this.props.tipoBusqueda][0];
        if (loguedUser)
          this.setState({ selectedAgent: loguedUser });
      }
    }
  }*/

  async loadLoguedAgent() {
    if (this.state.encomenda.Colegiados && this.state.encomenda.Colegiados.length === 0){
   
      //En el metodo con los datos de la encomenda nos vienen los colegiados que pertenecen al usuario validado para proponerlos cuando no hay colegiados aún
        let loguedUserCols = this.state.encomenda.ColegiadosDeUsuario;
      
          //si tiene sociedades cargamos su numero y el de sus sociedades
        if (loguedUserCols && loguedUserCols.length>0)
          await this.setState({  canSearch: true, searchResultInitial:loguedUserCols , showSearchResultInitial:true  });
         
      
    }
  }
  getColegiadosSociedad(sociedad){
    return this.state.encomenda.Colegiados.filter((item) => item.Id_Sociedad === sociedad);
  }

  renderSelection = () => {
    let { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Grid container >
          <Grid item md={10} className={classes.subtitle}>Arquitectos</Grid>
          <Grid item md={2}>     
            {/*!this.state.showSearchResultInitial && */
             <Fab size="small" color="primary" aria-label="Add"
              className={classes.fab} onClick={() => { this.handleAdd() }}>
              <AddIcon />
            </Fab>}
          </Grid>
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headHeight}>
              <CustomTableHead className="text-uppercase px-3"><Translate id="languages.agentes.numeroColegiado" /></CustomTableHead>
              <CustomTableHead className="p-0 text-center text-uppercase">
                <Translate id="languages.agentes.tableColumnName" />
              </CustomTableHead>
              <CustomTableHead className="px-3 text-center text-uppercase">%</CustomTableHead>
              <CustomTableHead className="p-0 text-center text-uppercase">
                <Translate id="languages.agentes.tableColumnFunction" />
              </CustomTableHead>
              <CustomTableHead></CustomTableHead>
            </TableRow>
          </TableHead>

          <TableBody className={classes.tableBodyHeight}>
            {
              this.state.encomenda.Colegiados.length === 0 ?
                <TableRow>
                  <TableCell colSpan={5}></TableCell>
                </TableRow>
                : this.state.encomenda.Colegiados.map((row, index) => {
                  if((row.Id_Tipo_Colegiado===1)&&(row.Id_Sociedad===0||row.Id_Sociedad===undefined)){
                    return (
                        <TableRow className={`${classes.row}`} key={index}>
                          <TableCell component="th" scope="row" className="px-1 text-center">
                            {row.Id_Colegiado}
                          </TableCell>
                          <TableCell className="p-0 text-center">{row.Nombre_Completo}</TableCell>
                          <TableCell className="p-3 text-center">{row.Porcentaje != null ? `${parseFloat(row.Porcentaje).toFixed(2).toString().replace('.00','')}` : ""}</TableCell>
                          <TableCell className="p-0 text-center">{row.Funcion}</TableCell>

                          <TableCell className="p-0">
                            <IconButton className={row.Id_Sociedad==1?'hidden':classes.buttonEdit} aria-label="Edit" color="primary"
                                        onClick={() => this.editAgenteSeleccion(row.Id_Colegiado)}>
                              <EditIcon />
                            </IconButton >
                            <IconButton className={classes.buttonEdit} color="primary" aria-label="Delete"
                                           onClick={() => this.setState({openCon: true,colSel:row.Id_Colegiado,nombreSel:row.Nombre_Completo + " (Col. " + row.Id_Colegiado + ")"})}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                    );
                  }
                  
                    else if((row.Id_Tipo_Colegiado>=2) ) { 
                    return (
                        <Fragment>
                        <TableRow className={`${classes.row}`} key={index}>
                          <TableCell style={{borderBottom:'0px solid',padding:0}}  scope="row" className="px-1 text-center">
                            {row.Id_Colegiado}<br/>

                          </TableCell>
                          <TableCell style={{borderBottom:'0px solid',padding:0}} className="p-0 text-center">
                            {row.Nombre}


                          </TableCell>
                          <TableCell style={{borderBottom:'0px solid',padding:0}} className="p-3 text-center">
                            {row.Porcentaje != null ? `${parseFloat(row.Porcentaje).toFixed(2).toString().replace('.00','')}` : ""}

                          </TableCell>
                          <TableCell style={{borderBottom:'0px solid',padding:0}} className="p-0 text-center">
                            <p>-</p>

                          </TableCell>

                          <TableCell style={{borderBottom:'0px solid',padding:0}} className="p-0">
                            <IconButton className={row.Id_Sociedad==1?'hidden':classes.buttonEdit} aria-label="Edit" color="primary"
                                        onClick={() => this.editAgenteSeleccion(row.Id_Colegiado)}>
                              <EditIcon />
                            </IconButton >
                            <IconButton className={classes.buttonEdit} color="primary" aria-label="Delete"
                                        onClick={() => this.setState({openCon: true,colSel:row.Id_Colegiado,nombreSel:row.Nombre + " (Col. " + row.Id_Colegiado + ")"})}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                          {
                            this.getColegiadosSociedad(row.Id_Colegiado).map((colegiado,p)=> {
                              let conBorde = p<(this.getColegiadosSociedad(row.Id_Colegiado).length-1)?'0px solid':'1px solid rgba(224, 224, 224, 1);'
                              return (<TableRow className={`${classes.row}`} key={index}>
                                <TableCell style={{borderBottom:conBorde,padding:0}} scope="row" className="text-center">
                                  -
                                </TableCell>
                                <TableCell style={{borderBottom:conBorde,padding:0}} className=" text-center">
                                  {colegiado.Nombre_Completo + ' ('+colegiado.Id_Colegiado+')'}

                                </TableCell>
                                <TableCell  style={{borderBottom:conBorde,padding:0}}className=" text-center">
                                  -
                                </TableCell>
                                <TableCell style={{borderBottom:conBorde,padding:0}} className=" text-center">

                                  {colegiado.Funcion}
                                </TableCell>
                                <TableCell style={{borderBottom:conBorde,padding:0}} className=" text-center">

                                </TableCell>


                              </TableRow>)
                            })
                          }
                        </Fragment>
                    );
                  }

                })
            }
          </TableBody>
        </Table>

        <Confirmation openConfirmation={this.state.openCon}     
                    title={'Confirmación' }                           
                    confirmationMessage={this.props.translate("languages.agentes.delCol") + this.state.nombreSel }
                    aceptConfirmation={async () => {
                        this.setState({openCon: false})
                        this.deleteAgentSelection(this.state.colSel) }}        
                                 
                    declineConfirmation={() => this.setState({openCon: false})}/>     
      </Paper>
    );
  }

  renderSearchBox = () => {

    return (
      this.state.canSearch &&
      <div>

        { /* md se muestra  búsqueda si hay agente seleccionado y se esta adding*/
        !this.state.selectedAgent  && this.state.adding && <SearchAgents showSearchResultInitial={this.state.showSearchResultInitial} searchResultInitial={this.state.searchResultInitial} tipoBusqueda={this.state.tipoBusqueda} resetResultados={this.state.resetResultados} selectAgent={(selectAgent) => { this.handleSelectedAgent(selectAgent) }}
          handleAddAgent={() => { this.handleCanSearch(false) }} allowAdd={false} cancel={()=>{ this.handleCancelResults()}}/>
        }  
          

        {this.state.selectedAgent &&
          <div>
            {this.state.selectedAgent.Id_Tipo_Colegiado==1?<FormArquitecto key={this.state.selectedAgent.Nif} arquitecto={this.state.selectedAgent}
                                                                            funcionesTipologia={this.state.funcionesTipologia /*this.props.funcionesTipologia*/ } handleCanSearch={() => this.handleSelectedAgent(null)}
                                                                            addAgenteTrabajoToSelection={agent => { this.addAgenteTrabajoToSelection(agent); this.handleSelectedAgent(null); }}
                                                                            existAgentSelected={agent => this.existAgentSelected(agent)}
                                                                            handleCancelAdd={()=> this.handleCancelAdd()} 
                                                                           />:
                <FormSociedad key={this.state.selectedAgent.Nif} arquitecto={this.state.selectedAgent}
                                funcionesTipologia={this.state.funcionesTipologia /*this.props.funcionesTipologia*/} handleCanSearch={() => this.handleSelectedAgent(null)}
                                colegiadosSociedad={this.state.colegiadosSociedad}
                                expediente={this.props.encomenda.EncomendaActual[0].Id_Expediente}
                                addAgenteTrabajoToSelection={(agent,arquitectos) => { this.addAgenteTrabajoToSelection(agent,arquitectos); this.handleSelectedAgent(null); }}
                                existAgentSelected={agent => this.existAgentSelected(agent)} 
                                 />}
            </div>
        }
      </div>
    );
  }

  render() {
    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          {  this.renderSelection()}
        </Grid>

        <Grid item xs={12}>
          {this.renderSearchBox()}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  //funcionesTipologia: state.trabajos.funcionesTipologia.data ? state.trabajos.funcionesTipologia.data.Tipos_Trabajos_Tunciones : [],
  loguedUser: state.user.DatosUsuarioValidado,
  state
})

const mapDispatchToProps = {
  //fetchFuncionesTipologia: fetchFuncionesTipologia,
};

Arquitecto.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(Arquitecto)));