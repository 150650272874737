import React from 'react';
import {withLocalize} from "react-localize-redux";
import {withStyles} from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {fetchErrorExpediente,awaitConfirmation} from "../../actions/expedientes";
import {fetchErrorTrabajo} from "../../actions/trabajos";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,FormControlLabel,Checkbox  
} from "@material-ui/core";
import ReactQuill from "react-quill";
import {requireConfirmation,incidencias,observaciones,observacionesIniciales,aclaraciones} from "../../api/index";
import ApiCoag from "../../api/ApiCoag";

const mapStateToProps = (state) => {
    return {
        errorExpediente: loadErros(state.expedientes.error),
        errorTrabajo: loadErros(state.trabajos.error)
    }
}

function loadErros(error) {
    return error && error.MensajesProcesado ? error.MensajesProcesado : []
}

const mapDispatchToProps = (dispatch) => {
    return {
        onErrorExpediente: (value) => dispatch(fetchErrorExpediente(value)),
        onErrorTrabajo: (value) => dispatch(fetchErrorTrabajo(value)),
        awaitConfirmation:(value)=>dispatch(awaitConfirmation(value))

    }
}

const styles = theme => ({
    close: {
        padding: theme.spacing.unit / 2,
    },
});

class ErrorSnackbars extends React.Component {
    constructor() {
        super();
        this.state={
            modeConfirm:false
        }
    }
    componentDidMount() {
        if(requireConfirmation(this.props.errorExpediente)||requireConfirmation(this.props.errorTrabajo)){
             this.setState({
                modeConfirm:true
            })
        }else{
            this.setState({
                modeConfirm:false
            })
        }
    }
    modeCOnfirm=async ()=>{
        debugger;
        if(requireConfirmation(this.props.errorExpediente)||requireConfirmation(this.props.errorTrabajo)){
            await this.setState({
                modeConfirm:true
            })
        }

    }

    handleClose = () => {
        let reset = {
            "MensajesProcesado": []
        }
        
        this.props.onErrorExpediente(reset);
        this.props.onErrorTrabajo(reset);  
              
      
        this.props.awaitConfirmation('ok')

        if (this.state.idAclaracion && this.state.leido)        
        {     let data = {
                "Id_Aclaracion":this.state.idAclaracion,
                "Leido":1 
                }             
            let response = ApiCoag.usuario.putUsuarioAclaracionLeida(data)          
            if (response && response != 'ERROR2') 
             { }  
        }   

       
     
    };
    handleConfirm = () => {
        let reset = {
            "MensajesProcesado": []
        }
        this.props.onErrorExpediente(reset);
        this.props.onErrorTrabajo(reset)
        this.props.awaitConfirmation('ok')
    };
    handleCancelar = () => {
        let reset = {
            "MensajesProcesado": []
        }
        this.props.onErrorExpediente(reset);
        this.props.onErrorTrabajo(reset)
        this.props.awaitConfirmation('canceled')
    };

    
    handleChange = (idAclaracion) => (event) => {      
        this.setState({ leido: event.target.checked,idAclaracion:idAclaracion});                                 
        }

    observaciones() {
        let errores = observaciones(this.props.errorExpediente);
        let erroresTrabajo = observaciones(this.props.errorTrabajo);
        let errorInicial=observacionesIniciales(this.props.errorExpediente);
       
        if (errores.length>0||erroresTrabajo.length>0) {
            return (
                <div>
                    <DialogTitle id="alert-dialog-title">
                        <Translate>
                            {({ translate }) => <h2 style={{color:'#2196f3',fontSize:'1rem'}}>{errorInicial.length==0?translate('languages.generalButton.observaciones'):translate('languages.generalButton.observacionInicial')}</h2>
                            }
                        </Translate>


                    </DialogTitle>
                    <DialogContent>
                        {errores.length > 0
                        && errores.map((error) => {
                            return <DialogContentText id="alert-dialog-description">
                                <ReactQuill value={error.Mensaje} readOnly theme='bubble'/>
                            </DialogContentText>
                        })}
                        {erroresTrabajo.length > 0
                        && erroresTrabajo.map((error) => {
                            return <DialogContentText id="alert-dialog-description">
                                <ReactQuill value={error.Mensaje} readOnly theme='bubble'/>
                            </DialogContentText>
                        })}       

                    </DialogContent>
                </div>
            )
        }


    }

    errores() {
        let errores = incidencias(this.props.errorExpediente);
        let erroresTrabajo = incidencias(this.props.errorTrabajo);
        if (errores.length>0||erroresTrabajo.length>0) {
            return (
                <div>
                    <DialogTitle style={{color:'#ed4140'}} id="alert-dialog-title">
                        <Translate>
                            {({ translate }) => <h2 style={{color:'#ed4140',fontSize:'1rem'}}>{translate('languages.generalButton.erroresDetectados')}</h2>
                               }
                        </Translate>


                    </DialogTitle>
                    <DialogContent>
                        {errores.length > 0
                        && errores.map((error) => {
                            return <DialogContentText id="alert-dialog-description">
                                <ReactQuill value={error.Mensaje} readOnly theme='bubble'/>
                            </DialogContentText>
                        })}
                        {erroresTrabajo.length > 0
                        && erroresTrabajo.map((error) => {
                            return <DialogContentText id="alert-dialog-description">
                                <ReactQuill value={error.Mensaje} readOnly theme='bubble'/>
                            </DialogContentText>
                        })}
                    </DialogContent>
                </div>
            )
        }


    }
    renderButtons(){

        let erroraclaracion=aclaraciones(this.props.errorExpediente);

        
        if(requireConfirmation(this.props.errorExpediente)||requireConfirmation(this.props.errorTrabajo)){
            return (
                <DialogActions>  

                    <Button onClick={this.handleConfirm} color="primary" autoFocus>
                        <Translate id="languages.generalButton.confirmar"/>
                    </Button>
                    <Button onClick={this.handleCancelar} color="primary" autoFocus>
                        <Translate id="languages.generalButton.cancel"/>
                    </Button>
                </DialogActions>
            )
        }else{
            return (              




              
                <DialogActions>

{erroraclaracion.length>0 && <FormControlLabel style={{ paddingTop:0, paddingLeft:3}}
                                  key="confirm"
                                  control={
                                      <Checkbox                                          
                                          checked={this.state.leido }                                                                                        
                                            onChange={this.handleChange(erroraclaracion[0].IdAclaracion)}                                
                                            value={this.state.leido}     
                                          color="primary" />
                                  }
                                  label={"No volver a mostrar"}                                  
                              />
                              }   

                    <Button onClick={this.handleClose} color="primary" autoFocus>
                        <Translate id="languages.generalButton.aceptar"/>
                    </Button>
                </DialogActions>
            )
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <div>
                <Dialog
                    open={this.props.errorExpediente.length > 0 || this.props.errorTrabajo.length > 0}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    {
                        this.errores()
                    }
                    {
                        this.observaciones()
                    }
                    {
                        this.renderButtons()
                    }


                </Dialog>
            </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(ErrorSnackbars)));