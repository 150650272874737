import React,{ useState,useEffect } from 'react';
import {
 Delete 
} from '@material-ui/icons';
import { Grid, RadioGroup , FormControlLabel,FormControl,Radio,IconButton,Typography ,Tooltip  } from '@material-ui/core';
import { ID_FORMATO_DIGITAL} from "../../api/config";
import { Translate } from "react-localize-redux";


const Renuncia = ({ valor0,valor1, valor2, valor3, valor4, formato,initialValue,onRadioChange,onDelete,onClickExpediente }) => {

  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onRadioChange(newValue); // Llamar a la función callback
  };

  



  return (
  
    <div>
    <Grid container spacing={0} style={{
        marginTop: 10,
        padding: 2,
        paddingTop: 0,
        border: '1px solid #cecece',
        marginRight: 10,     
    }}>
       
      <Grid item xs={4} style={{ fontSize:'11px',borderRight: '1px solid #ccc', padding: '1 1px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
        {/*<div><a href={'/visualizar-expediente/'+valor0}  onClick={handleOpenNewTab}>{valor1}</a></div>*/}
        <div>

    {formato==ID_FORMATO_DIGITAL ?
        <Typography          
          onClick={onClickExpediente} 
          color={"primary"}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {valor1}
        </Typography>:
       <div>{valor1}</div>
        }

      </div>
        <div>{valor2}</div>
        <div>{valor3}</div>
        <div>{valor4}</div>
      </Grid>
      <Grid item xs={7}  style={{ borderRight: '1px solid #ccc', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FormControl component="fieldset" >
      <Typography variant="subtitle2" gutterBottom  className="align-self-center" >
      {/*selectedValue*/}
      {/*Certifico que a renuncia prodúcese por causar baixa na actividade como arquitecto*/}       
      </Typography>
     
          <RadioGroup  style={{ fontSize:'4px',paddingLeft:'3px'}} name="cert" value={selectedValue} onChange={handleChange}>
            <FormControlLabel  value="1"  control={<Radio color="primary" />} label={<Translate id="languages.renuncias.renunciasSinObras"/>} />
            <FormControlLabel value="2" control={<Radio color="primary" />} label={<Translate id="languages.renuncias.renunciasConObras"/>} />
          </RadioGroup>
        </FormControl>
      </Grid>
      {<Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Tooltip title={  'Eliminar expediente de lista de seleccionados para renunciar'}>
              <IconButton  color="primary" aria-label="Delete" onClick={onDelete}>                         
                                <Delete/>
                                </IconButton>
                                </Tooltip>
      </Grid>}
    </Grid>

    
 
</div>

  );
};

export default Renuncia;