import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {
    withStyles,
    Paper,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    CircularProgress,
    Grid,
    TextField,
    FormControl,  
    Button,
    Tooltip
    /*,Dialog,
    DialogTitle,
    DialogContent, DialogActions,
    Select,
    MenuItem*/
} from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {fetchErrorExpediente} from "../../../../actions/expedientes";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {grey} from "@material-ui/core/colors";
import moment from "moment";
import ApiCoag from "../../../../api/ApiCoag";


const styles = theme => ({
    withoutRadius: {
        borderRadius: 0
    },
    table: {
        minWidth: 200,
    },
    tableBodyHeight: {
        minHeight: 100
    },
    headHeight: {
        height: 40
    },
    fab: {
        margin: theme.spacing.unit,
        marginTop: 25,
        position: 'absolute',
        marginLeft: 40
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300,
        textAlign: 'left',
        marginTop: 5
    },
    centerText: {
        textAlign: "center"
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    paddingButtons: {
        padding: 15
    },
    button: {
        margin: theme.spacing.unit,
    },
    resultPanel: {
        padding: "20px 30px"
    },
    subtitleData: {
        marginTop: 10,
        fontWeight: "normal"
    },
    usericon: {
        margin: theme.spacing.unit,
        fontSize: 150,
    },
    mt0: {
        marginTop: -10
    },
    iconoption: {
        margin: theme.spacing.unit,
        color: theme.palette.text.primary
    },
    buttonEdit: {
        border: "1px solid",
        padding: 8,
        margin: 4
    },
});
const CustomTableHead = withStyles(theme => ({
    head: {
        backgroundColor: grey[100],
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


class DatosEconomicos extends Component {
    constructor() {
        super();
        this.state = {
            expandSectionTempFile: false,
            loadingData: false,
            formUpdated:false,
            fetchingSave:false,
            datosEconomicos:{},
            loaded:false

        }
    }

    componentDidMount() {

        //this.loadData();
    }

    async loadData() {
        if (!this.state.loaded || this.state.formUpdated) {
            if (!this.state.formUpdated)
            await this.setState({loadingData: true})

            let data = await ApiCoag.expedientes.trabajo.datosEconomicos(this.props.work.Id_Expediente, this.props.work.Id_Trabajo);

            await this.setState({
                loadingData: false,
                datosEconomicos: data,
                formUpdated:false,
                loaded:true
            })
        }
    }
    notifyPropertyChange = (propertyName,pos,type='number') => event => {
        if(this.props.work.Id_Estado!=5){
            let {datosEconomicos} = this.state;
            switch (propertyName) {

                default:

                    datosEconomicos.Colegiados[pos][propertyName] = type=='text'?event.target.value: parseFloat(event.target.value);
                    this.setState({
                        datosEconomicos,
                        formUpdated:true
                    })

                    break;

            }
        }

    }
    download_file(fileURL, fileName) {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
            save.download = fileName || filename;
            if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href;
                // window event not working here
            } else {
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
        }

        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    }

    async cancel(){
      
        await this.loadData();       
               
     }

    async saveData(){
        this.setState({fetchingSave:true})
        let {datosEconomicos} = this.state;
        let data = await ApiCoag.expedientes.trabajo.putDatosEconomicos(this.props.work.Id_Expediente, this.props.work.Id_Trabajo,datosEconomicos);
        //if (data.MensajesProcesado && data.MensajesProcesado.length > 0) {
        // this.props.fetchErrorExpediente(data)
           if (data =='ERROR2') {
           await this.setState({fetchingSave:false})
        }
        else
            await this.setState({fetchingSave:false,formUpdated:false})
        
    }
    async descargarFactura(item){
        let {classes} = this.props;
       let url = await  ApiCoag.expedientes.trabajo.urlFacturaUri(this.props.work.Id_Expediente, this.props.work.Id_Trabajo,item.Factura)

       this.download_file(url.Archivos[0].Url,url.Archivos[0].Nombre)


    }

    async lanzarPago(item){
       window.open(item.Url_Pago, '_blank');
    }

    async onChangeEP(){
        await this.setState({expandSectionTempFile: !this.state.expandSectionTempFile})
        if (this.state.expandSectionTempFile)
        {await this.loadData()}
    }


    render() {


        let {classes,work} = this.props;
        let eF = this.state.datosEconomicos.EfectosTramitados;
        let eD= this.state.datosEconomicos;
        let edicion=(work.Id_Estado==0 || work.Id_Estado==2)?true:false;
       let pPago=work.Id_Estado!=null && (work.Id_Estado>= 3 && work.Id_Estado < 11) && work.SePuede_Retirar==0

        return (
            <div style={{marginTop: 10}}>
                {
                    this.state.loadingData ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :                        
                        <Paper
                            style={{borderColor: '#cecece', borderWidth: 1, marginBottom: 10}}>
                            <ExpansionPanel expanded={this.state.expandSectionTempFile}
                                            onChange={() => this.onChangeEP()}>
                                <ExpansionPanelSummary style={{paddingLeft: 8}} expandIcon={<ExpandMoreIcon/>}>
                                    <div className="d-flex justify-content-between align-items-center"
                                         style={{width: "100%", marginLeft: 10}}>
                                        <div>
                                            <Typography variant='button' style={{float: "left"}}>
                                                Datos Facturación
                                            </Typography>
                                            {pPago &&
                                            <Typography variant='button'
                                                        style={{color: '#ffc107', float: "left", marginLeft: 5}}>
                                                         <Translate id="languages.generalButton.sinPagar" />
                                                </Typography>
                                            }

                                        </div>

                                    </div>
                                </ExpansionPanelSummary>
                                
                                {this.state.loaded && 

                                <ExpansionPanelDetails style={{padding: '8px 16px'}}>
                                    <Grid container>
                                        <Grid item xs={12}> <Typography variant={"body2"} style={{
                                            fontWeight: 600,
                                            fontSize: '13px!important'
                                        }}>Información económica para cálculo</Typography></Grid>
                                        <Grid item xs={12}>
                                            <div style={{marginTop: 20}}>

                                                <Table className={classes.table} style={{width: '100%'}}>
                                                    <TableHead>
                                                        <TableRow className={classes.headHeight}>
                                                            <CustomTableHead width="6%"
                                                                className="text-uppercase px-3">COL</CustomTableHead>
                                                            <CustomTableHead width="35%" className="p-0 text-center text-uppercase">
                                                                Nombre
                                                            </CustomTableHead>
                                                            <CustomTableHead width="9%"
                                                                className="px-0 text-center text-uppercase">%</CustomTableHead>
                                                            <CustomTableHead  width="50%" className="p-0 text-center text-uppercase">
                                                                Reseña
                                                            </CustomTableHead>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody className={classes.tableBodyHeight}>
                                                        {
                                                            eD.Colegiados.length === 0 ?
                                                                <TableRow>
                                                                    <TableCell colSpan={5}></TableCell>
                                                                </TableRow> :
                                                                eD.Colegiados.map((colegiado, pos) => {
                                                                    return (
                                                                        <TableRow className={`${classes.row}`}
                                                                                  key={pos}>
                                                                            <TableCell  scope="row" className="px-1 text-center">{colegiado.Id_Colegiado}</TableCell>
                                                                            <TableCell  scope="row" className="px-1 text-center">{colegiado.Nombre}</TableCell>
                                                                            <TableCell   scope="row" className={edicion?"px-0 text-center pt-0": "px-1 text-center pt-1"} >
                                                                            {edicion ?
                                                                                <TextField

                                                                                    type={'number'}     
                                                                                    value={this.state.formUpdated ? colegiado.Porcentaje : parseFloat(colegiado.Porcentaje).toFixed(2).toString().replace('100.00','100')}                                                                                                                                                                                                                                                       
                                                                                    onChange={this.notifyPropertyChange('Porcentaje',pos)}
                                                                                    margin="normal"
                                                                                    inputProps={{ min: 0,  max:100, step: "0.01" }}
                                                                                   
                                                                                />:
                                                                                `${parseFloat(colegiado.Porcentaje).toFixed(2).toString().replace('100.00','100')}` 
                                                                                }
                                                                               </TableCell>
                                                                            <TableCell   scope="row" className={edicion?"px-1 text-center pt-0": "px-1 text-center pt-1"} >
                                                                            {edicion ?
                                                                                <TextField


                                                                                    value={colegiado.Resena == null ?  '':colegiado.Resena}                                                                                    
                                                                                    onChange={this.notifyPropertyChange('Resena',pos,'text')}
                                                                                   
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                />
                                                                                :
                                                                                colegiado.Resena }
                                                                                </TableCell>
                                                                        </TableRow>)
                                                                })
                                                        }
                                                    </TableBody>
                                                </Table>


                                            </div>
                                        </Grid>
                                        {
                                            edicion &&  <Grid item xs={12} style={{textAlign:"right"}}>

                                                    <Button  disabled= {!this.state.formUpdated} color="primary" className={classes.button} onClick={async () => { await this.cancel() } }  >
                                                    <Translate id="languages.generalButton.cancel" />
                                                    </Button>
                                                {!this.state.fetchingSave &&<Button
                                                    color="primary" className={classes.button}
                                                    disabled={!this.state.formUpdated||this.state.fetchingSave}
                                                    onClick={()=>this.saveData()}
                                                > <Translate id="languages.generalButton.save" />
                                                </Button>}
                                                {this.state.fetchingSave && <CircularProgress className="align-self-center" size={22}/>}
                                            </Grid>

                                        }


                                        {  
                                            eF &&eF.length>0 && eF[0].Id_Entidad_Destino>0?
                                            eF.map((item,pos)=>{return(
                                                    <Grid item xs={12} style={{marginTop:10, padding:'5px 10px', border: '1px solid #cecece'}}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <FormControl className={classes.formControl2}
                                                                                >
                                                                        <label className='MuiFormLabel-root-214' style={{
                                                                            marginTop: 10,
                                                                            marginBottom:0,

                                                                            color: 'rgba(0, 0, 0, 0.54)',
                                                                            padding: 0,
                                                                            fontSize: 10,
                                                                            fontFamily: 'Montserrat',
                                                                            lineHeight: 1
                                                                        }}>RECIBE</label>
                                                                        <Typography variant={"body1"}> {item.Recibe}</Typography>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControl className={classes.formControl2}
                                                                                 >
                                                                        <label className='MuiFormLabel-root-214' style={{
                                                                            marginTop: 10,
                                                                            marginBottom:0,
                                                                            color: 'rgba(0, 0, 0, 0.54)',
                                                                            padding: 0,
                                                                            fontSize: 10,
                                                                            fontFamily: 'Montserrat',
                                                                            lineHeight: 1
                                                                        }}>DESCRIPCIÓN</label>
                                                                        <Typography variant={"body1"}> {item.Descripcion}</Typography>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <FormControl className={classes.formControl2}
                                                                                 style={{width: '90%', paddingRight: '3%'}}>
                                                                        <label className='MuiFormLabel-root-214' style={{
                                                                            marginTop: 10,
                                                                            marginBottom:0,
                                                                            color: 'rgba(0, 0, 0, 0.54)',
                                                                            padding: 0,
                                                                            fontSize: 10,
                                                                            fontFamily: 'Montserrat',
                                                                            lineHeight: 1
                                                                        }}>IMPORTE</label>
                                                                        <Typography variant={"body1"}> {item.Importe?item.Importe+' €':null}</Typography>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <FormControl className={classes.formControl2}
                                                                                 style={{width: '90%', paddingRight: '3%'}}>
                                                                        <label className='MuiFormLabel-root-214' style={{
                                                                            marginTop: 10,
                                                                            marginBottom:0,
                                                                            color: 'rgba(0, 0, 0, 0.54)',
                                                                            padding: 0,
                                                                            fontSize: 10,
                                                                            fontFamily: 'Montserrat',
                                                                            lineHeight: 1
                                                                        }}>IVA</label>
                                                                        <Typography variant={"body1"}> {item.Iva?item.Iva+' €':null}</Typography>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <FormControl className={classes.formControl2}
                                                                                 style={{width: '90%', paddingRight: '3%'}}>
                                                                        <label className='MuiFormLabel-root-214' style={{
                                                                            marginTop: 10,
                                                                            marginBottom:0,
                                                                            color: 'rgba(0, 0, 0, 0.54)',
                                                                            padding: 0,
                                                                            fontSize: 10,
                                                                            fontFamily: 'Montserrat',
                                                                            lineHeight: 1
                                                                        }}>TOTAL</label>
                                                                        <Typography variant={"body1"}> {item.Total?item.Total+' €':null}</Typography>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <FormControl className={classes.formControl2}
                                                                                 style={{width: '90%', paddingRight: '3%'}}>
                                                                        <label className='MuiFormLabel-root-214' style={{
                                                                            marginTop: 10,
                                                                            marginBottom:0,
                                                                            color: 'rgba(0, 0, 0, 0.54)',
                                                                            padding: 0,
                                                                            fontSize: 10,
                                                                            fontFamily: 'Montserrat',
                                                                            lineHeight: 1
                                                                        }}>FECHA</label>
                                                                        <Typography variant={"body1"}> {moment(new Date(item.Fecha)).format("DD/MM/YYYY").toString().replace('01/01/1970','')}</Typography>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={4}></Grid>
                                                                <Grid item xs={4}>
                                                                    <FormControl className={classes.formControl2}
                                                                                 style={{width: '90%', paddingRight: '3%'}}>
                                                                        <label className='MuiFormLabel-root-214' style={{
                                                                            marginTop: 10,
                                                                            marginBottom:0,
                                                                            color: 'rgba(0, 0, 0, 0.54)',
                                                                            padding: 0,
                                                                            fontSize: 10,
                                                                            fontFamily: 'Montserrat',
                                                                            lineHeight: 1
                                                                        }}>ESTATUS</label>
                                                                        <Typography variant={"body1"}> {item.Estatus }</Typography>
                                                                    </FormControl>
                                                                </Grid>
                                                                { 
                                                                    item.Url_Pago != null &&  item.Url_Pago != '' ?
                                                                    
                                                                       
                                                                        <Grid item xs={12} style={{textAlign:"right",paddingRight:10}}>
                                                                          <Tooltip title={item.Estatus_Concepto=='CAP'?<Translate id="languages.datosEco.pagoPen" />:item.Estatus_Concepto=='DA'?<Translate id="languages.datosEco.pagoDom"/>:''}>     
                                                                          
                                                                             <Button
                                                                             color="primary" className={classes.button}
                                                                                onClick={()=>this.lanzarPago(item)}>
                                                                                {item.Estatus_Concepto=="DA"?<Translate id="languages.datosEco.adelantarPago" />:<Translate id="languages.datosEco.efectuarPago"></Translate>}
                                                                            </Button>
                                                                          </Tooltip> 
                                                                            
                                                                        </Grid> : null
                                                                    
                                                                }
                                                                {
                                                                    item.Factura && item.Factura!=''?

                                                                        <Grid item xs={12} style={{textAlign:"right",paddingRight:10}}>
                                                                            <Button
                                                                                color="primary" className={classes.button}
                                                                                onClick={()=>this.descargarFactura(item)}>Descargar factura
                                                                            </Button>
                                                                        </Grid>:null
                                                                }
                                                                

                                                            </Grid>

                                                    </Grid>
                                                )})
                                                :null
                                        }
                                    </Grid>


                                </ExpansionPanelDetails>
                            }
                            </ExpansionPanel>
                        </Paper>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps =
    {
        fetchErrorExpediente: fetchErrorExpediente
    };
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(DatosEconomicos)));