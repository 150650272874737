
/*
*funciones auxiliares api
*/


import { getCookie } from "../reducers/userExport";
import {URL_CATASTRO,URL_MAPS} from "./config";

export const formatMenssage = (error) => (
  {
    "MensajesProcesado": [{ "Mensaje": error }]
  }
)

export const isMobile = () => {  
  return ( 
      
      (navigator.userAgent.match(/Android/i)) ||
      (navigator.userAgent.match(/webOS/i)) ||
      (navigator.userAgent.match(/iPhone/i)) ||        
      (navigator.userAgent.match(/BlackBerry/i))
      || window.innerWidth<960
      
  );
}

export const getDefaultLanguage = () => {
  let language = getCookie("language");
   
  return language ? language : 2;
}

export function requireConfirmation(errores){
  let flag=false;
  for(let i = 0; i<errores.length;i++){

      if(errores[i].IdTipo==1||errores[i].IdTipo==0){
          flag=true
      }else if(errores[i].IdTipo!=1&&errores[i].IdTipo!=3&&errores[i].IdTipo!=0&&errores[i].IdTipo!=6){
        return false;
      }


  }
  return flag;
}

export function incidencias(mensajes){
  return mensajes.filter((item) => item.IdTipo != 1&&item.IdTipo != 3&&item.IdTipo!=0&&item.IdTipo!=6);  
}



export function observaciones(mensajes){
  return mensajes.filter((item) =>item.IdTipo==1||item.IdTipo==3||item.IdTipo==0||item.IdTipo==6);
}

export function observacionesIniciales(mensajes){
  return mensajes.filter((item) =>item.IdTipo==0);
}

export function aclaraciones(mensajes){
  return mensajes.filter((item) =>item.IdTipo==6);
}




export function operationCancelled(errores){

  for(let i = 0; i<errores.length;i++){

      //if(errores[i].IdTipo!=1&&errores[i].IdTipo!=3){
          if(errores[i].IdTipo === 2){            
          return true;
      }


  }
  return false;
}


export function ltrim(str) {
  return str.replace(/^\s+/, ''); // Elimina espacios en blanco del lado izquierdo
}

export function rtrim(str) {
  return str.replace(/\s+$/, ''); // Elimina espacios en blanco del lado derecho
}

export function imgCatastro  (ref,size)  {
   
        return (              
           <a alt='catastro' target="_blank" href={urlCatastro(ref)} ><img  width={size} height={size} alt={''} src={require('../components/Address/Iconos/logosec.jpg')}/> </a>           
        );
      }   
      
       
      
 export function imgUbicacion  (lat,lon,size)  {
   
        return (              
           <a alt='catastro' target="_blank" href={urlUbicacion(lat,lon)} ><img  width={size} height={size} alt={''} src={require('../components/Address/Iconos/ubicacion.png')}/> </a>           
        );
      }          


      export function  urlUbicacion  (lat,lon)  {            
        if (lat!=null && lon!=null && lat!='' && lon!=''){  
           return URL_MAPS +lat+","+lon   
                   }                
      }

      export function  urlCatastro  (ref)  {            
        if (ref!=null && ref!=''){  
           return URL_CATASTRO +ref
                   }                
      }

      export function iconEstados (nombreLimpio)  {
        try {
            return require(`../components/Tramitaciones/IconosEstados/${nombreLimpio}.svg`);
        } catch (e) {
            //return require(`../../../Tramitaciones/IconosEstados/borrador.svg`);
        }
    }


     



