import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {
    withStyles,
    Paper,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    CircularProgress,
    Grid,
    TextField,
    FormControl,  
    Button,
    Dialog,
    DialogTitle,
    DialogContent, DialogActions
} from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {fetchErrorExpediente} from "../../../../../actions/expedientes";
import ApiCoag from "../../../../../api/ApiCoag";


const styles = theme => ({
    withoutRadius: {
        borderRadius: 0
    }
});

class DTDemolicion extends Component {
    constructor() {
        super();
        this.state = {
            expandSectionTempFile: false,
            loadingDatosTecnicos: true,
            respuestaCalculo:false,           
            showDetalles:false,
            fetchingSave:false,                        
            allowSave:false,
            isModified:false,
            isCalculated:false,
            isModifiedDatosEst:false,
            enabledCalc:false
        }
    }

    componentDidMount() {
        this.loadDatosTecnicos()

    }

    async loadDatosTecnicos() {
        await this.setState({loadingDatosTecnicos: true})
       
        let datosTecnicosTrabajo = await ApiCoag.expedientes.trabajo.datosTecnicos.obtener(this.props.work.Id_Expediente, this.props.work.Id_Trabajo);
       


        datosTecnicosTrabajo.Caracteristicas = datosTecnicosTrabajo.Caracteristicas[0]
        datosTecnicosTrabajo.Parametros = datosTecnicosTrabajo.Parametros[0]
        datosTecnicosTrabajo.DatosEstadisticos = datosTecnicosTrabajo.DatosEstadisticos[0]

        if (datosTecnicosTrabajo.Parametros == undefined) {
            
            datosTecnicosTrabajo.Parametros =
                {
                    "Demolicion_Volumen": null
                   
                }
        }
        await this.setState({
            datosTecnicos: datosTecnicosTrabajo,
            respuestaCalculo:{Precios:datosTecnicosTrabajo.Precios,DetallesCalculo:datosTecnicosTrabajo.DetallesCalculo},
            allowSave:false,
            loadingDatosTecnicos: false,
            isModified:false ,
            isModifiedDatosEst:false,
            isCalculated:datosTecnicosTrabajo.Parametros.Demolicion_Volumen == null ? false:true    ,
            enabledCalc:datosTecnicosTrabajo.Parametros.Demolicion_Volumen == null ?true:false     
        })
    }


 


    async saveDatosTecnicos(){
        let {datosTecnicos} = this.state;
        let {work} = this.props;


        await this.setState({
            fetchingSave:true
        })

        this.validateDatosEstadisticos()

        let response = await ApiCoag.expedientes.trabajo.datosTecnicos.guardar(work.Id_Expediente,work.Id_Trabajo,datosTecnicos);
       if(response.Parametros){
           let datosTecnicosTrabajo = response;
           datosTecnicosTrabajo.Caracteristicas = datosTecnicosTrabajo.Caracteristicas[0]
           datosTecnicosTrabajo.Parametros = datosTecnicosTrabajo.Parametros[0]
           datosTecnicosTrabajo.DatosEstadisticos = datosTecnicosTrabajo.DatosEstadisticos[0]
           await this.setState({
               datosTecnicos: datosTecnicosTrabajo,

           })
            }
       

        if (response=='ERROR2' || (response.MensajesProcesado && response.MensajesProcesado.length > 0 )) {
            { if (response.MensajesProcesado) 
                this.props.fetchErrorExpediente(response)

                this.setState({
                    'respuestaCalculo':this.state.respuestaCalculo, //volvemos ao de antes
                    fetchingSave:false,
                    isModified:false,
                    isModifiedDatosEst:false,
                    allowSave:false
                })
    
            }
        }else{
            this.setState({
                'respuestaCalculo':response,
                fetchingSave:false,
                isModified:false,
                isModifiedDatosEst:false,
                allowSave:false
            })

        }


    }


    async calcular() {
        let {datosTecnicos} = this.state;
        let {work} = this.props;

        this.validateParametros();

        let response = await ApiCoag.expedientes.trabajo.datosTecnicos.simular(work.Id_Expediente, work.Id_Trabajo, datosTecnicos);

        if ( !response || response == 'ERROR2' || (response.MensajesProcesado && response.MensajesProcesado.length > 0)) {
            if (response.MensajesProcesado) 
                this.props.fetchErrorExpediente(response)

            this.setState({
                'respuestaCalculo': false, // this.state.respuestaCalculo, //Volvemos ao de antes false  ,
                isCalculated:false   
            })

        } else {
            this.setState({
                'respuestaCalculo': response,                                     
                allowSave:true,                
                isCalculated:true
            })

        }

    }

    valorUnidadeComplexidade(){
        if (this.state.datosTecnicos && this.state.respuestaCalculo && this.state.respuestaCalculo.Precios[0].Precio_Unitario_Unidad_Complejidad)
            return this.state.respuestaCalculo.Precios[0].Precio_Unitario_Unidad_Complejidad
        else
            return ''            
    }
    
    
    resultadoCalculo() {
            let {classes} = this.props;          
            let complejidadFinal = 0;
            let precioFinal = 0;
         
                return (
                    <Grid container style={{backgroundColor: '#f9f9f9', padding: 48, paddingTop: 12, paddingBottom: 12}}>
                        <Grid item xs={12}>
                        {this.state.isCalculated ? <Translate id="languages.datosTecnicos.complejidadFinalYPrecio" /> : <Translate id="languages.datosTecnicos.complejidadFinalYPrecioPend" />}
                        </Grid>
                        
                        {this.state.isCalculated  &&            
                            this.state.respuestaCalculo.Precios.map((precio) => {
                                complejidadFinal += precio.Complejidad;
                                precioFinal += parseFloat(precio.Precio).toFixed(2);
                                return (
                                    <Grid container style={{backgroundColor: '#f9f9f9'}}>
                                       {/* <Grid item xs={6}>
                                            <label className='MuiFormLabel-root-214' style={{
                                                marginTop: 17,
                                                marginBottom: -12,
                                                color: 'rgba(0, 0, 0, 0.54)',
                                                padding: 0,
                                                fontSize: 10,
                                                fontFamily: 'Montserrat',
                                                lineHeight: 1
                                            }}>COMPLEJIDAD</label><br/>
                                            { //md precio.Complejidad
                                             precio.Complejidad != null ? parseFloat(precio.Complejidad).toFixed(2) : ''}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label className='MuiFormLabel-root-214' style={{
                                                marginTop: 17,
                                                marginBottom: -12,
                                                color: 'rgba(0, 0, 0, 0.54)',
                                                padding: 0,
                                                fontSize: 10,
                                                fontFamily: 'Montserrat',
                                                lineHeight: 1
                                            }}>PRECIO (SIN IVA)</label><br/>
                                            { // md precio.Precio == null ? parseFloat(precio.Precio).toFixed(2) : ''
                                            precio.Precio != null ? parseFloat(precio.Precio).toFixed(2) : ''
    
                                            } €
                                        </Grid>
                                        */}
                                        <Grid item xs={6}>
                                            <label className='MuiFormLabel-root-214' style={{
                                                marginTop: 17,
                                                marginBottom: -12,
                                                color: 'rgba(0, 0, 0, 0.54)',
                                                padding: 0,
                                                fontSize: 10,
                                                fontFamily: 'Montserrat',
                                                lineHeight: 1
                                            }}><Translate id="languages.datosTecnicos.complejidadFinal" /></label><br/>
                                             
                                             { // md precio.Complejidad_Final == null ? parseFloat(precio.Complejidad_Final).toFixed(2) : ''
                                            precio.Complejidad_Final != null ? parseFloat(precio.Complejidad_Final).toFixed(2) : ''}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label className='MuiFormLabel-root-214' style={{
                                                marginTop: 17,
                                                marginBottom: -12,
                                                color: 'rgba(0, 0, 0, 0.54)',
                                                padding: 0,
                                                fontSize: 10,
                                                fontFamily: 'Montserrat',
                                                lineHeight: 1
                                            }}><Translate id="languages.datosTecnicos.precioFinal" /></label><br/>
                                            {// md parseFloat(precio.Precio_Final).toFixed(2) 
                                              precio.Precio_Final != null ? parseFloat(precio.Precio_Final).toFixed(2) : ''} €
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        <Grid container style={{backgroundColor: '#f9f9f9'}}>
    
                        </Grid>
                        <Grid container style={{backgroundColor: '#f9f9f9'}}>
                            <Grid item xs={12}>
                                <label className='MuiFormLabel-root-214' style={{
                                    marginTop: 17,
                                    marginBottom: -12,
                                    color: 'rgba(0, 0, 0, 0.54)',
                                    padding: 0,
                                    fontSize: 11,
                                    fontFamily: 'Montserrat',
                                    lineHeight: 1
                                }}>{this.props.translate("languages.datosTecnicos.precioFinalCoeficiente") + ' ' + this.valorUnidadeComplexidade()}</label>

    
                            </Grid>
                            {
    
                                this.state.isCalculated && this.state.respuestaCalculo.DetallesCalculo  ? <Grid item xs={12}>
    
                                    <Button
                                        color="primary" className={classes.button}
                                        onClick={() => this.setState({showDetalles: true})}
                                    ><Translate id="languages.datosTecnicos.mostrarDetallesCalculo" />
                                    </Button>
                                </Grid> : null
    
                            }
    
    
                        </Grid>
                    </Grid>
                )
            }
           
        
    
    notifyPropertyChange = (propertyName) => event => {
        let datosTecnicosTrabajo = this.state.datosTecnicos;        
        this.setState({isModified:true,allowSave:false,isCalculated:false,enabledCalc:false})
        
        switch (propertyName) {

            default:

                datosTecnicosTrabajo ['Parametros'][propertyName] = event.target.type == 'checkbox' ? event.target.checked ? 1 : 0 : parseFloat(event.target.value);
                this.setState({ datosTecnicos: datosTecnicosTrabajo })

                break;

        }
    }

    modifyDatosEstadisticos = (propertyName) => event => {
        let datosTecnicos = this.state.datosTecnicos;
        
        /* md si está pendiente calculo no se puede guardar*/
        if (this.state.isCalculated || !this.state.isModified )
        {this.setState({isModifiedDatosEst:true,allowSave:true})}
        else
        {this.setState({isModifiedDatosEst:true})}
        
        switch (propertyName) {

            default:

                datosTecnicos['DatosEstadisticos'][propertyName] = event.target.type == 'checkbox' ? event.target.checked ? 1 : 0 : parseFloat(event.target.value);
                this.setState({
                    datosTecnicos
                })

                break;

        }
    }


    validateDatosEstadisticos (){

        let datosTecnicos = this.state.datosTecnicos;     
        
        let valor = datosTecnicos['DatosEstadisticos'].PEM;                 
        datosTecnicos['DatosEstadisticos'].PEM = (valor == '' || valor == null  || isNaN(valor)) ? null:  parseFloat(valor ).toFixed(2);

        valor = datosTecnicos['DatosEstadisticos'].Numero_Viviendas;
        datosTecnicos['DatosEstadisticos'].Numero_Viviendas= (valor == '' || valor == null  || isNaN(valor)) ? null:    parseInt(valor );
        valor = datosTecnicos['DatosEstadisticos'].Numero_Viviendas_Afectada;
        datosTecnicos['DatosEstadisticos'].Numero_Viviendas_Afectadass = (valor == '' || valor == null  || isNaN(valor)) ? null:    parseInt(valor );
                
        this.setState({
             datosTecnicos
            })         
        
    }


    validateParametros (){
        let datosTecnicos = this.state.datosTecnicos;       
              
        datosTecnicos['Parametros']["Demolicion_Volumen"] =  datosTecnicos['Parametros']["Demolicion_Volumen"]== null ? null : parseFloat(datosTecnicos['Parametros']["Demolicion_Volumen"]).toFixed(2);
        
        if (isNaN(datosTecnicos['Parametros']["Demolicion_Volumen"]))
            datosTecnicos['Parametros']["Demolicion_Volumen"] = 0;
    
                                   
        this.setState({
            datosTecnicos
        })            
        
    }

    
    renderDatosEstadisticos(){
        let {datosTecnicos} = this.state;
        let {classes} = this.props;
        let edicion=this.state.datosTecnicos.Caracteristicas.SePuede_Editar;
        return (
            <Grid container>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '100%', paddingRight: '10%'}}>
                        <TextField
                            id="Numero_Viviendas"
                            label={<Translate id="languages.datosTecnicos.viviendas" />}
                            disabled={parseInt(edicion)!=1}
                            type={'number'}
                            inputProps={{ min: "0",  step: "1" }}
                            value={datosTecnicos.DatosEstadisticos.Numero_Viviendas == null ? '':datosTecnicos.DatosEstadisticos.Numero_Viviendas}
                            onChange={this.modifyDatosEstadisticos('Numero_Viviendas')}
                            margin={"normal"}                            
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '100%', paddingRight: '10%'}}>
                        <TextField
                            id="Numero_Viviendas_Afectadas"
                            label={<Translate id="languages.datosTecnicos.viviendasAfectadas" />}
                            type={'number'}
                            inputProps={{ min: "0",  step: "1" }}
                            disabled={parseInt(edicion)!=1}
                            value={datosTecnicos.DatosEstadisticos.Numero_Viviendas_Afectadas == null ? '':datosTecnicos.DatosEstadisticos.Numero_Viviendas_Afectadas}
                            onChange={this.modifyDatosEstadisticos('Numero_Viviendas_Afectadas')}
                            margin="normal"                            
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
               
                <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '100%', paddingRight: '10%'}}>
                        <TextField
                            id="pem"
                            label="PEM"
                            type={'number'}
                            inputProps={{ min: "0",  step: "1" }}                            
                            disabled={edicion!=1}
                            value={datosTecnicos.DatosEstadisticos.PEM == null ?'':datosTecnicos.DatosEstadisticos.PEM}
                            onChange={this.modifyDatosEstadisticos('PEM')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
               
                        {/*
                <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '80%', paddingRight: '10%'}}>
                        <TextField
                            id="Superficie"
                            label="Superficie"
                            disabled={true}
                            type={'number'}
                            inputProps={{ min: "0",  step: "0.1" }}
                            value={datosTecnicos.DatosEstadisticos.Superficie}
                            onChange={this.modifyDatosEstadisticos('Superficie')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                */}

            </Grid>
        )

    }

    renderForm() {
        let {datosTecnicos} = this.state;
        let {classes} = this.props;
       

        if(this.state.datosTecnicos.Caracteristicas.SeRequiere_IntroducirDatosTecnicos == 1)
        return (
            <Grid container spacing={16} style={{margin: 0}}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{
                    fontSize: 12,

                    textAlign: 'justify',
                    padding: '0 18px',

                }}>
                    {/* md no es necesario este texto
                    Selecciona el/los uso(s) al que se destinará el edificio o establecimiento
                    DB SUA */}
                </Typography>
            </Grid>
          
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl2}
                                     style={{width: '40%', paddingRight: '3%'}}>
                            <TextField
                                id="Demolicion_Volumen"
                                label={<Translate id="languages.datosTecnicos.volumen" />}
                                type="number"
                                autoFocus={true}   /* md */    
                                value={datosTecnicos.Parametros.Demolicion_Volumen == null ? '' : datosTecnicos.Parametros.Demolicion_Volumen}
                                onChange={this.notifyPropertyChange('Demolicion_Volumen')}
                                margin="normal"
                                disabled={datosTecnicos.Caracteristicas.SePuede_Editar!=1}
                                inputProps={{ min: "0",  step: "1" }}
                            />
                        </FormControl>


                    </Grid>
                   

                    <Grid item xs={12} style={{textAlign: "right", paddingRight:20,marginTop: 0}}>

                    <Button style={{textAlign: "right", paddingRight:10,paddingLeft:10}}
                        color="primary" className={classes.button}
                        variant={!((!this.state.isModified || this.state.isCalculated) && !this.state.enabledCalc) ?"contained":''}
                        disabled= {(!this.state.isModified || this.state.isCalculated) && !this.state.enabledCalc }
                        onClick={() => this.calcular()}>Calcular
                    </Button>


                  </Grid>
                  {/* md mejor debajo

                    <Grid item xs={12}>
                        {
                            this.renderDatosEstadisticos()
                        }
                    </Grid>*/}
            </Grid>
        )
    }

    detalles() {

        if(this.state.showDetalles){
            let {respuestaCalculo} = this.state
            return (<div>
                <Dialog open={this.state.showDetalles}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                    <Translate id="languages.datosTecnicos.detallesCalculo" />
                    </DialogTitle>

                    <DialogContent>
                        <Grid container spacing={8}>
                            {
                                respuestaCalculo!=false && respuestaCalculo.DetallesCalculo.map((resp,p)=>(
                                    <Grid item md={12}>
                                        {resp.Parametro}:<br/> {resp.Valor_Parametro}
                                    </Grid>
                                ))
                            }
                        </Grid>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={async () => {
                            this.setState({ showDetalles: false });

                        }} variant="contained"
                                color="primary" autoFocus style={{ height: 30, minHeight: 30 }}
                                className="p-0">
                            Aceptar

                        </Button>

                    </DialogActions>
                </Dialog>
            </div>);
        }

    }

    render() {
        let {classes} = this.props;
        return (
            <div style={{marginTop: 10}}>
                {
                    this.state.loadingDatosTecnicos && this.state.expandSectionTempFile == false ? 
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :<Paper
                            style={{borderColor: '#cecece', borderWidth: 1, marginBottom: 10}}>
                            <ExpansionPanel expanded={this.state.expandSectionTempFile}
                                            onChange={() => this.setState({expandSectionTempFile: !this.state.expandSectionTempFile})}>
                                <ExpansionPanelSummary style={{paddingLeft: 8}} expandIcon={<ExpandMoreIcon/>}>
                                    <div className="d-flex justify-content-between align-items-center"
                                         style={{width: "100%", marginLeft: 10}}>
                                        <Typography variant='button'>
                                        <Translate id="languages.datosTecnicos.datosTecnicosPrecio"/>
                                        </Typography>
                                        {this.state.datosTecnicos.Caracteristicas.SeRequiere_IntroducirDatosTecnicos==1&&this.state.datosTecnicos.Caracteristicas.Datos_Tecnicos_Precio_Pendientes==1&&
                                        <Typography variant='button' style={{color:'#ffc107',float:"left",marginLeft:5}}><Translate id="languages.generalButton.sinCalcular" /></Typography>
                                        }

                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{padding: "8px 0px 0px"}}>


                                    <div style={{marginLeft: 24, marginRight: 24, marginTop: -25, marginBottom: 24}}>

                                        {
                                            this.renderForm()
                                        }
                                        <div style={{marginLeft:-24, marginRight:-24}}>
                                            {
                                                this.resultadoCalculo()
                                            }
                                            {
                                                this.detalles()
                                            }



                                        </div>
                                        {
                                            this.renderDatosEstadisticos()
                                        }
                                        {
                                            this.state.datosTecnicos.Caracteristicas.SeRequiere_IntroducirDatosTecnicos==1&&this.state.datosTecnicos.Caracteristicas.SePuede_Editar==1&& <Grid container>
                                                <Grid item xs={12} style={{textAlign:"right",paddingRight:5, marginBottom:0, paddingTop:10}}>

                                                    <Button
                                                       disabled={!this.state.isModified && !this.state.isModifiedDatosEst} 
                                                        color="primary" className={classes.button}
                                                        onClick={()=>this.loadDatosTecnicos()}><Translate id="languages.generalButton.cancel" />
                                                    </Button>
                                                    {
                                                        this.state.fetchingSave?null:<Button
                                                            variant="contained"
                                                            disabled={this.state.fetchingSave||!this.state.allowSave}
                                                            color="primary" className={classes.button}
                                                            onClick={()=>this.saveDatosTecnicos()}><Translate id="languages.generalButton.save" />
                                                        </Button>
                                                    }

                                                    {this.state.fetchingSave && <CircularProgress size={24} className={classes.buttonProgress} />}


                                                </Grid>
                                            </Grid>
                                        }
                                    </div>


                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Paper>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps =
    {
        fetchErrorExpediente: fetchErrorExpediente
    };
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(DTDemolicion)));