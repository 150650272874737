import React, { Component } from 'react';
import LoginFormaFinal from './LoginFormaFinal.js';
import { connect } from 'react-redux';
import {
    fetchLoginExito, errorLogin, fetchUserLogin,
    fetchLoading, dispatchErrorLogin
} from './../../actions/usuarios/index';
import { withRouter } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import "./styles.css";
import { Typography } from '@material-ui/core';
import ApiCoag from "../../api/ApiCoag";
import {URL_ALTA_USU,URL_RECUPERAR} from "../../api/config";

class Login extends Component {
    constructor(props) {

        super(props);
        this.state = {
            submitting: false
        };
    }

    componentDidMount() {
        this.props.dispatchErrorLogin(null);
    }

    async userLogin(data) {
        this.props.fetchLoading(true);
        this.setState({ submitting: true,user:data.usuario,password:data.password  });
        try {

            await this.props.fetchUserLogin(data, this.props);
            this.setState({ submitting: false});
            this.props.fetchLoading(false);

            //aquí llamar a darse de alta previa confirmacion
            let hm=0;
            let uv=0;
            let ko=0;
            hm=this.props.mensaje?1:0;
            uv=hm && this.props.mensaje.toString().indexOf('403')>0 ? 1:0;
            ko=this.props.mensaje.toString().indexOf('400')>0?1:0;

            if (uv==1)
               await this.usuarioAlta(data);

        }
        catch (e) {
            await this.setState({ submitting: false });
            this.props.fetchLoading(false);
        }
    }

    async usuarioAlta(data) {
       
           
            let datapost = {
                "usuario": data.usuario,
                "password": data.password                         
            }     
            let response =await ApiCoag.usuario.postUsuario(datapost)          
            if (response && response != 'ERROR2' && response.DatosUsuarioValidado && response.DatosUsuarioValidado.length > 0) 
            {             //si                                                              
                await this.userLogin(data);                       
            }  
                      
        }   
    


    render() {
        let hm=0;
         let uv=0;
         let ko=0;
         hm=this.props.mensaje?1:0;
         uv=hm && this.props.mensaje.toString().indexOf('403')>0 ? 1:0;
         ko=this.props.mensaje.toString().indexOf('400')>0?1:0;

        return (     
                
            <div className="centrado login">
                <div className="box-position">
                    <div className="white-box">
                        <img alt={200} src={require('./images/loginlogo.png')} />
                        <LoginFormaFinal submitting={this.state.submitting} onSubmit={async (data) => { await this.userLogin(data) }} />
                            
                        <div className="error">
                            {hm==1
                                ? (ko==0 ?
                                  (uv==1?                                    
                                    <Typography variant="subtitle2" gutterBottom style={{color:'#02AC66'}} className="text-left"> 
                                        {/*<Translate id="languages.messages.loginOkSinAlta" /> */}
                                    </Typography>                                     
                                    :                                    
                                    <Typography variant="subtitle2" gutterBottom color="error" className="text-left"> 
                                    {!this.state.password || !this.state.user ?   <Translate id="languages.messages.loginFaltanDatos" /> : 
                                    (this.state.password && this.state.password.length<6 ?  <Translate id="languages.messages.loginCaracteres" /> :
                                    <Translate id="languages.messages.loginFailed" />     ) }                                   
                                    </Typography>                                  
                                   ):
                                   <Typography variant="subtitle2" gutterBottom color="error" className="text-left">
                                       <Translate id="languages.messages.loginServiceKo" />                                    
                                   </Typography> )
                               : ""}                                              
                        </div>

                        <div className="recuperar">
                        {/*  hm==1 && uv==1 && <a href="https://portal.coag.es/coag-estudio/"  target="_blank">Solicitar alta en CoagEstudio Web</a>
                        */}
                        </div>

                        <br></br>
                        
                        <div className="recuperar">
                        {   uv==0 &&  <a href={URL_ALTA_USU}  target="_blank">Solicitar alta de usuario web COAG</a>
                        }
                        </div>
                        <div className="recuperar">     
                        {    uv==0 &&<a href={URL_RECUPERAR}   target="_blank">Recuperar datos de acceso</a> 
                        }
                        </div>



         
                    </div>
                </div>
            </div>
        )
     }

}



const mapStateToProps = state => (
    {
        mensaje: state.user.mensaje ? state.user.mensaje : '',
        user: state.user ? state.user : '',
    });

const mapDispatchToProps = {
    fetchLoginExito,
    errorLogin,
    fetchUserLogin,
    fetchLoading,
    dispatchErrorLogin
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));


