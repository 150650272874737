import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button ,Select, MenuItem,FormControlLabel,Checkbox} from "@material-ui/core";
import ReactQuill from "react-quill";
import { CircularProgress, Typography } from '@material-ui/core';


const styles = theme => ({

});

class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExecution: false,
            valueId:this.props.confirmationItems!=null?this.props.confirmationItems[0].id:-1,
            checkedItem:false
        }
    }

    
    handleChange = () => (event) => {  
        if (event.target.value != this.state.valueId )
          {this.setState({ valueId: event.target.value });           
           }                           
    }

    handleChangeCheck = () => (event) => {        
          this.setState({ checkedItem: event.target.checked });                              
    }

    
    render() {

        let botonConfirmar=this.props.translate("languages.generalButton.yes")
        let botonCancelar=this.props.translate("languages.generalButton.no")
   
        if (this.props.acceptCancel || this.props.onlyAccept){
            botonConfirmar=this.props.translate("languages.generalButton.aceptar") 
            botonCancelar='Cancelar'
        }

        if (this.props.botonConfirmarTexto)
            botonConfirmar=this.props.botonConfirmarTexto
        
        if (this.props.botonCancelarTexto)
            botonCancelar=this.props.botonCancelarTexto
                

        return (<div>
            <Dialog open={this.props.openConfirmation} maxWidth={this.props.maxWidth?this.props.maxWidth:"sm"}  fullWidth 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" >
                {this.props.title?this.props.title:"Confirmación" }                      
                </DialogTitle>

                <DialogContent>
                    {this.props.confirmationMessage.props ?                     
                    /*<Typography className="ml-1" component="h2" variant="display1"
                        gutterBottom style={{ fontSize: "1rem" }}>
                        {this.props.confirmationMessage}                        
                    </Typography>*/
                    <ReactQuill value={this.props.translate(this.props.confirmationMessage.props.id)} readOnly theme='bubble' className="d-flex align-items-center justify-content-between"/>
                    :
                    <ReactQuill value={this.props.confirmationMessage} readOnly theme='bubble' className="d-flex align-items-center justify-content-between"/>
                    }
                    {this.props.confirmationItems!=null  ?<p>&nbsp;</p>:null}
                    {
                     this.props.confirmationItems!=null &&                   
                        <Select  value={this.state.valueId==-1?this.props.confirmationItems[0].id:this.state.valueId} style={{ marginLeft:'20px',paddingBottom:'1px',fontSize:14,width: '95%' }}
                        displayEmpty   
                        onChange={this.handleChange()   }            
                             
                        inputProps={{name: 'name',id: 'id'  }}>                      
                                 {this.props.confirmationItems.map(c=> (
                                 <MenuItem   style={{ height:8,fontSize:14}}
                                    value={c.id}>{c.name}</MenuItem>
                                                     ))}
                       </Select>
                     }
                      {this.props.confirmationCheckItem && <FormControlLabel className="ml-2 mr-2 mt-0 mb-0"  style={{ fontSize:14,fontWeight:'bold' }}  
                                                            control={<Checkbox checked={this.state.checkedItem} value={this.state.checkedItem} onChange={this.handleChangeCheck()}          />}
                                                            label={this.props.confirmationCheckItemText}
                                                            />}
                      {this.props.confirmationItems!=null  ?<p>&nbsp;</p>:null}
                     
                </DialogContent>

                <DialogActions>
                     {!this.props.onlyAccept && 
                    <Button onClick={async () => {
                        this.setState({ isExecution: true });                                              
                        await this.props.aceptConfirmation(this.props.confirmationItems?(this.state.valueId==-1?this.props.confirmationItems[0].id:this.state.valueId):null);
                        this.setState({ isExecution: false,valueId:-1 });
                        }} 
                        variant="contained"
                        color="primary" autoFocus style={{ height: 30, minHeight: 30 }}
                        disabled={false || (this.props.confirmationCheckItem && !this.state.checkedItem)}
                       >
                        {botonConfirmar}
                        {this.state.isExecution && <CircularProgress className="mx-1"
                            style={{ height: 20, width: 20, color: "white" }} />}
                    </Button>}
                    {!this.props.onlyAccept && 
                    <Button onClick={async () => {
                        this.props.declineConfirmation()
                        this.setState({valueId:-1 });
                        }} 
                        color="primary"
                        style={{ height: 30, minHeight: 30 }} >
                         { botonCancelar}
                    </Button>}
                    {this.props.onlyAccept && <Button onClick={async () => {
                        this.setState({ isExecution: true });
                        await this.props.aceptConfirmation(this.state.valueId);
                        this.setState({ isExecution: false });
                    }} variant={this.props.buttonVariant?this.props.buttonVariant:"contained"} color="primary"
                     style={{ height: 30, minHeight: 30 }} 
                     disabled={false || (this.props.confirmationCheckItem && !this.state.checkedItem)}>
                      {botonConfirmar}
                    </Button>}
                    
                </DialogActions>
            </Dialog>
        </div>);
    }
}
const mapStateToProps = (state) => {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(Confirmation)));