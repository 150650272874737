import React from 'react';
import { Col, Row } from 'reactstrap';
import { IconButton,Typography, Divider ,Button} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import moment from "moment";


const styles = theme => ({
   
    formControl: {
        width: "100%"
    },
    gridContainer: {
        minHeight: 10 * 48 + 56 // Altura mínima: 10 filas * altura de fila + altura del encabezado
    },
    centeredButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop:'40px'
    
    }
});

class RenunciasResumen  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data1: this.props.data1, 
            data2: this.props.data2, 
            data3: this.props.data3, 
            data4: this.props.data4,
            data5: this.props.data5  
        };
    }

    
      
    handleClose = () => {
        this.props.handleClose();
    };

      columnDefs = [
        { headerName: "CÓDIGO", field: "Expediente_Codigo", width: this.props.divWidth >= 118 ? 118 : 106 },
        { headerName: "PROMOTOR", field: "Promotor", width: this.props.divWidth >= 314 ? 314 : 214 },
        { headerName: "TÍTULO", field: "Titulo", width: this.props.divWidth >= 314 ? 314 : 234 },
        { headerName: "UBICACIÓN", field: "Ubicacion", width: this.props.divWidth >= 300 ? 300 : 210 },
        { headerName: "ENTRADA", field: "Fecha_Entrada", width: 105, cellRenderer: (data) => { return (data.value != null ? moment(data.value).format('DD/MM/YY') : null) } }
    ];

    handleClose = () => {
        this.props.handleClose();
    };

      
        

    render() {
        const {   classes,  } = this.props;

        const heightResults= '360px';            
        

        return (
            <Col xs={12}>
                <Row>
                    <Col xs={12} className="d-flex justify-content-between align-items-center pt-2 pb-1">
                        <Typography variant="h5" gutterBottom className="m-0">
                        <Translate id="languages.renuncias.resumen"/>        
                        </Typography>
                        
                        
                        <IconButton  aria-label="Close"  onClick={this.handleClose}>
                            <Close />
                        </IconButton>
                        </Col>                    
                </Row>
                <Divider />
                {this.state.data1.length>0 &&
                <Row>                
               <Col xs={12} className="d-flex justify-content-between align-items-center pt-4 pb-0">
                        <Typography variant="h5" gutterBottom className="m-0" >
                        <Translate id="languages.renuncias.completadas"/>        
                        </Typography>
                    </Col>                                               
               </Row>}
               {this.state.data1.length>0 &&
                <Row>
                    <Col xs={12} className="pb-3 pt-3">
                        <Typography variant="h6"  gutterBottom className="pt-2">
                        <Translate id="languages.renuncias.expSinObras"/>     
                        </Typography>      
                        <Typography variant="subtitle2" gutterBottom className="pt-0" style={{fontWeight:'bold'}}>
                        <Translate id="languages.renuncias.expSinObrasAccion"/>    
                                </Typography>     
                                <div className="ag-theme-balham"     style={{  width: '100%' }}>
                                <AgGridReact
                                    columnDefs={this.columnDefs}
                                    rowData={this.state.data1}
                                    domLayout='autoHeight'
                                    noRowsOverlayComponentFramework={() => <div style={{ padding: '10px' }}>Datos inexistentes</div>}
                                />
                                </div>     
                    </Col>                    
               </Row>}               
               {(this.state.data2.length>0 || this.state.data3.length>0 || this.state.data4.length>0) && 
               <Row>
               <Col xs={12} className="d-flex justify-content-between align-items-center pt-5 pb-0">
                        <Typography variant="h5" gutterBottom className="m-0" style={{color:'red'}}>
                        <Translate id="languages.renuncias.accionesPendientes"/>     
                        </Typography>
                        </Col>                                               
               </Row>}

               {this.state.data2.length>0  && 
               <Row>
                    <Col xs={12} className="pb-3 pt-2" >
                        <Typography variant="h6" gutterBottom className="pt-3">                         
                        <Translate id="languages.renuncias.expDigGuiaObras"/>     
                        </Typography>        
                        <Typography variant="subtitle2" gutterBottom className="pt-0" style={{color:'red'}}>
                        <Translate id="languages.renuncias.expDigGuiaObrasAccion" />     
                                </Typography>           
                                <div className="ag-theme-balham"     style={{ width: '100%' }}>
                                <AgGridReact
                                    columnDefs={this.columnDefs}
                                    rowData={this.state.data2}
                                    domLayout='autoHeight'
                                    noRowsOverlayComponentFramework={() => <div style={{ padding: '10px' }}>Datos inexistentes</div>}
                                />
                                </div>     
                               
                    </Col>                    
               </Row>}
               {this.state.data3.length>0  && 
               <Row>
                    <Col xs={12} className="pb-3 pt-2">
                        <Typography variant="h6"  gutterBottom className="pt-2">
                        <Translate id="languages.renuncias.expDigNoGuiaObras"/>     
                        </Typography>    
                        <Typography variant="subtitle2" gutterBottom className="pt-0" style={{color:'red'}}>
                        <Translate id="languages.renuncias.expDigNoGuiaObrasAccion"/>        
                        </Typography>  
                            <div className="ag-theme-balham"     style={{  width: '100%' }}>
                                <AgGridReact
                                    columnDefs={this.columnDefs}
                                    rowData={this.state.data3}
                                    domLayout='autoHeight'
                                    noRowsOverlayComponentFramework={() => <div style={{ padding: '10px' }}>Datos inexistentes</div>}
                                />
                                </div>     
                                       
                    </Col>                    
               </Row>}

               {this.state.data4.length>0  && 
               <Row>
                
                    <Col xs={12} className="pb-3 pt-2">
                        <Typography variant="h6"  gutterBottom className="pt-2">
                        <Translate id="languages.renuncias.expPapelObras"/>                       
                        </Typography>    
                        <Typography variant="subtitle2" gutterBottom className="pt-0" style={{color:'red'}}>
                        <Translate id="languages.renuncias.expPapelObrasAccion" />     
                        </Typography>  
                            <div className="ag-theme-balham"     style={{  width: '100%' }}>
                                <AgGridReact
                                    columnDefs={this.columnDefs}
                                    rowData={this.state.data4}
                                    domLayout='autoHeight'
                                    noRowsOverlayComponentFramework={() => <div style={{ padding: '10px' }}>Datos inexistentes</div>}
                                />
                                </div>     
                                       
                    </Col>                    
               </Row>}

               {this.state.data5.length>0  && 
               <Row>
                
                    <Col xs={12} className="pb-3 pt-2">
                        <Typography variant="h6"  gutterBottom className="pt-2">
                        Expedientes con errores              
                        </Typography>    
                        <Typography variant="subtitle2" gutterBottom className="pt-0" style={{color:'red'}}>
                        Contactar con COAG
                        </Typography>  
                            <div className="ag-theme-balham"     style={{  width: '100%' }}>
                                <AgGridReact
                                    columnDefs={this.columnDefs}
                                    rowData={this.state.data5}
                                    domLayout='autoHeight'
                                    noRowsOverlayComponentFramework={() => <div style={{ padding: '10px' }}>Datos inexistentes</div>}
                                />
                                </div>     
                                       
                    </Col>                    
               </Row>}

                <Row className={classes.centeredButton}>
                    <Button variant="contained" color="primary" onClick={this.handleClose}>
                        Volver
                    </Button>
                </Row>
                

            </Col>
        );
    }
}

export default withStyles(styles)(RenunciasResumen);