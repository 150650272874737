import React, {Component} from 'react';
import {
    Grid,
    Paper,
    CircularProgress,
    Typography,    
    Button,
    ExpansionPanel,
    Checkbox,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    withStyles,
    ListItemText,     
    Divider   , 
    IconButton,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Tooltip
} from '@material-ui/core';


import RefreshIcon from '@material-ui/icons/Refresh'
import CloudUpload from '@material-ui/icons/CloudUpload';
//import CheckCircle from '@material-ui/icons/Check';
//import ErrorOutline from '@material-ui/icons/ErrorOutline';
//import Check from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dropzone from "react-dropzone";
import * as actionsExpedientes from '../../../../actions/expedientes';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {red, green, orange} from '@material-ui/core/colors';

import '../../../Tramitaciones/estados.css';
import {formatMenssage} from "../../../../actions/expedientes";
import ApiCoag from "../../../../api/ApiCoag";
import {URL_AUTOASIGN_1,URL_AUTOASIGN_2} from "../../../../api/config";
import {isMobile} from "../../../../api/index";


import AsignFilesFolders from "./AsignFilesFolders"

const styles = theme => ({
    root: {
        width: '100%',
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    red: {
        color: red[500],


    },
    green: {
        color: green[500]
    },
    orange: {
        color: orange[500],
        overflow: 'hidden',
        marginRight: '5%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    size: {
        fontSize: 12,
        marginTop: 6
    },
    formControl: {
        width: "100%"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
        textAlign: 'left',
    },
    textFieldInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
    link: {
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    black: {
        fontWeight: 700
    },
    margin: {
        margin: 0
    },
    backgroundColor: {
        backgroundColor: "#f5f5f5"
    },
    rootPanel: {
        position: "inherit"
    },
    helperText: {
        textAlign: "end",
        fontWeight: "bold"
    },
    disabledContainer: {
        pointerEvents: "none",
        opacity: 0.4
    }
});

const mapStateToProps = (state) => {
    return (
        {
            fileUpload: state.status.files
        }
    )
};

const mapDispatchToProps =
    {
        fetchErrorExpediente: actionsExpedientes.fetchErrorExpediente,
        uploadFiles: actionsExpedientes.uploadFiles,
        resetUploadStates: actionsExpedientes.resetUpladStates,
        showUploadComponent: actionsExpedientes.showUploadComponent,
        hideUploadComponent: actionsExpedientes.hideUploadComponent,
        formatMessage: actionsExpedientes.formatMenssage,
        dispatchSetFetchingDone: actionsExpedientes.dispatchSetFetchingDone
    };

class TemporalFolder extends Component {
    // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    //     this.reloadAfterDragAndDrop()
    //     this.reloadAfterUpload(nextProps)
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.reloadAfterDragAndDrop()
            this.reloadAfterUploadNew()
                }
    }
  
    componentWillUnmount() {
        this.props.showUploadComponent()
    }

       constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            error: false,
            data: [],
            firmasDigitales: [],
            panelExpanded: false,
            folderInfo: false,
            expediente: false,
            workDetails: false,
            aclaracionesOpen: false,
            uploadInProgress: false,
            uploadLength: 0,
            currentUpload: 0,
            itemSelected: [],
            checkAll: false,
            currentUploadItem: false,
            pendingUploadList: [],
            fetchingRemove: false,
            allowUpload: true,
            disableAutoAsignButton: true,
            expandSectionTempFile:!this.props.collapseTemporalFolder,
            detallesArchivo: null,
            loadingDetallesArchivo: false,           
            loadingUpdateFichaTrabajo: false,
            temporalFiles: [],
            administrativeNotivficationSave: null ,
            accionDragAndDrop:false ,
            fetchingAutoAsign:false,
            itemSelToDragAndDrop:false,
            size:0,
            modifydate:null ,
            dialogOpen: false,
            dialogTitle: "",
            dialogContent: "",
            loading:true,
            filesAuto:[],
            filesAutoEdit:[],
            showModal:false
        }
    }

    async reloadAfterUpload(nextProps) {
       
        await setTimeout(async () => {

            if (this.props.dataFolderTemporal==null && this.props.fileUpload.currentUpload === null && nextProps.fileUpload.fetchingDone&&this.props.fileUpload.fetchingDone&&this.props.fileUpload.typeUpload=='toTemporal' ) {
                this.loadInformation()
                this.props.dispatchSetFetchingDone()
                await this.setState({isMyRequest:false})
            }                                            
                
        }, 500)
    }

    async reloadAfterUploadNew() {
       
        //await setTimeout(async () => {

            if (this.props.dataFolderTemporal==null && this.props.fileUpload.currentUpload === null && this.props.fileUpload.fetchingDone&&this.props.fileUpload.fetchingDone&&this.props.fileUpload.typeUpload=='toTemporal' ) {
                this.loadInformation()
                this.props.dispatchSetFetchingDone()
                this.setState({isMyRequest:false})
            }                                            
                
        //}, 500)
    }

    async reloadAfterDragAndDrop() {
        if ( this.props.dataFolderTemporal!=null  &&  this.props.fileUpload.currentUpload === null )      
        { 
            let temporalFiles = this.props.dataFolderTemporal;
            //this.endAccionDragAndDrop()

            this.setState({                            
        temporalFiles,checkAll: false ,showDeleteButton: false, showDownloadButton: false,disableAutoAsignButton:true,loading:false 
         })                       
        }
    }


    async onDrop(acceptedFiles) {    
        this.props.onDropTemp(acceptedFiles);
        await this.setState({
            uploadInProgress: true,
            isMyRequest:true,                       
            checkAll:false
        })
        if (this.props.notInFolderPlace) {
            this.props.showUploadComponent()
        }
        this.dragOverUploadFileEnd()
    }

    abortUpload() {
        this.setState({
            uploadInProgress: false,
            uploadLength: 0,
            currentUpload: 0,
            currentUploadItem: false,
            pendingUploadList: [],
        })
    }

     expandPanel = (nombre, idEstructura) => async (event, expanded) => {
        if (event.target.type != 'checkbox')  /* por alguna razón al hacer click en el checkbox entra aquí y no debe */
        {
        this.setState({panelExpanded: expanded ? (idEstructura ? idEstructura : nombre) : false   });            
       
        try {
            if (expanded && idEstructura) {
               
                let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.detallesArchivo(this.state.expediente.Id_Expediente, this.props.trabajo, idEstructura);
                if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {                                   
                } else {
                    this.setState({detallesArchivo: response});
                }
            }

          

            if (expanded && nombre && !idEstructura){           
             this.setState({size:'--',modifydate:'--',firmasDigitales:null});
             this.loadInformationFile(nombre)             
            }
            
                       

        } catch (e) {
            this.props.fetchErrorExpediente(e.message);           
        }
    }
    };

    async componentDidMount() {
        this.props.hideUploadComponent()
        this.props.showUploadComponent()
        this.loadGeneralInformation();
    }

    // async UNSAFE_componentWillMount() {
    //     this.props.showUploadComponent()
    //     this.loadGeneralInformation();
       
    // }
  
    async loadGeneralInformation() {
        await this.setState({fetching: true})
        let expediente = this.props.expediente.Expediente[0];
        try {


            await this.setState({
                expediente,
            })
        } catch (e) {
            this.props.fetchErrorExpediente(e);
        }

        await this.loadInformation();
        await this.setState({fetching: false});
    }

    async loadInformation() {

        let expediente = this.props.expediente.Expediente[0];
        await this.setState({loading:true})
        //this.props.toDataFolderTemp(null)
        try {

            let temporalFiles = await ApiCoag.expedientes.getArchivosTemporales(expediente.Id_Expediente)
            temporalFiles = temporalFiles.Archivos;
            await this.setState({
                fetchingCenter: false,
                loading:false,
                temporalFiles
            })
            //this.props.toDataFolderTemp(temporalFiles)
        } catch (e) {
            await this.setState({loading:false,fetchingCenter: false})
            this.props.fetchErrorExpediente(actionsExpedientes.formatMenssage(e.message));            
        }

    }

    async refresh() {
        await this.setState({refresh: true})
        this.loadInformation()
        await this.setState({refresh:false})
    }

    /* md al desplegar info tenemos que ver firmas archivo*/
    async loadInformationFile(fileName) {

        let expediente = this.props.expediente.Expediente[0];
        //await this.setState({fetchingCenter: true})
        try {

            let temporalFile = await ApiCoag.expedientes.getArchivoTemporal(expediente.Id_Expediente,fileName)
         
            await this.setState({
               // fetchingCenter: false  ,                
                firmasDigitales:  temporalFile.FirmasDigitales  ,
                size:(temporalFile.Archivos.length>0 ? temporalFile.Archivos[0].Longitud : ''),
                modifydate:(temporalFile.Archivos.length>0 ? temporalFile.Archivos[0].Fecha : '')                
            })
        } catch (e) {
            this.props.fetchErrorExpediente(actionsExpedientes.formatMenssage(e.message));
        }

    }

    handleCheckAll = () => async event => {
        let tf = [...this.state.temporalFiles];

        tf.map(item => {
            item.checked = event.target.checked;
        });
        let data = [...this.state.data];
        data.map(item => {
            item.checked = event.target.checked;
        });
        this.setState({temporalFiles: tf, data, checkAll: event.target.checked});
              
        this.setOptions();
    }

    handleChange = (name, index, arrName) => async event => {
        let a = [];
        Object.assign(a, this.state[arrName]);
        a[index][name] = event.target.checked;
        this.setState({[arrName]: a, panelExpanded: -1});             
            
        this.setOptions();
    };

    setOptions() {
        let {files, temporalFiles} = this.itemsToRemove();

        if (temporalFiles.length > 0 && !this.state.showDeleteButton) 
        this.setState({disableAutoAsignButton: false, showDeleteButton: true, showDownloadButton: true})
   
        if (temporalFiles.length == 0 && this.state.showDeleteButton) 
        this.setState({disableAutoAsignButton: true, showDeleteButton: false, showDownloadButton: false})

        if ( !this.state.checkAll && temporalFiles.length === this.state.temporalFiles.length && this.state.temporalFiles.length>0)
        this.setState({checkAll: true})
   
        if ( this.state.checkAll && temporalFiles.length != this.state.temporalFiles.length)            
        this.setState({checkAll: false})     
    }

    itemsToRemove() {
        let temporalFiles = this.state.temporalFiles ? this.state.temporalFiles.filter((item) => item.checked) : []
        let files = this.state.data ? this.state.data.filter((item) => item.checked) : []
        return {files, temporalFiles}
    }

    getSelectedItems() {
        let temporalFiles = this.state.temporalFiles ? this.state.temporalFiles.filter((item) => item.checked) : []
        let files = this.state.data ? this.state.data.filter((item) => item.checked) : []
        return files.concat(temporalFiles);
    }

    async handleRemove() {
        let {files, temporalFiles} = this.itemsToRemove();
        let count = 0;
        count += files.length
        count += temporalFiles.length
                
        if (count) {
             this.setState({fetchingRemove: true,  disableAutoAsignButton:true,showDownloadButton: false})

            if (temporalFiles.length) {
                let arrayArchivos = [];
                temporalFiles.map(item => {
                    arrayArchivos.push({Nombre: item.Nombre})
                    return null
                });
                let response = await ApiCoag.expedientes.eliminarArchivosTemporales(this.state.expediente.Id_Expediente, arrayArchivos)
                if (!response) {
                   // this.props.fetchErrorExpediente(response);
                } else {
                    let newData = [...this.state.temporalFiles];
                    temporalFiles.map(item => {
                        newData = newData.filter(current => current.Nombre !== item.Nombre)
                        return null
                    });

                   this.setState({
                        fetchingRemove: false,
                        showDeleteButton: false,
                        showDownloadButton: false,

                        temporalFiles: newData,
                        checkAll: false

                    })
                  
                }

            }
            this.setState({fetchingRemove: false, showDeleteButton: false, showDownloadButton: false,disableAutoAsignButton:true })
        }
        this.unselectAll()
        

    }   
  

      dragStart = (item, temporal) => (event) => {
        let allFiles = this.itemsToRemove();
       
        if (!item.checked && temporal)
            allFiles.temporalFiles.push(item);
        if (!item.checked && !temporal)
            allFiles.files.push(item);          

        this.setState({itemSelToDragAndDrop:true})
      
        this.props.dragging(allFiles)                     

           }
   
    async unselectAll()
    {
        /*let {...temporalFiles} = this.state
        temporalFiles.map((item) =>
            item.checked=false
         )
        this.setState({temporalFiles})*/
        let tf = [...this.state.temporalFiles]; 
        tf.map((item) =>item.checked=false)     
        this.setState({temporalFiles:tf}) 
                 
    }

    download_file(fileURL, fileName) {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
            save.download = fileName || filename;
            if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href;
                // window event not working here
            } else {
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
        }

        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    }

    async handleDownload() {
        let {files, temporalFiles} = this.itemsToRemove();
        let count = 0;
        count += files.length
        count += temporalFiles.length
        
        if (count) {

            await this.setState({fetchingDownload: 0})
            if (files.length) {
                if (files.length === 1) {
                    let item = files[0];
                    await this.setState({fetchingDownload: this.state.fetchingDownload++})
                    let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.getUrlDescarga(this.state.expediente.Id_Expediente, this.props.trabajo, item.Id_Estructura)
                    if (response.Archivos && response.Archivos.length === 1) {
                        this.download_file(response.Archivos[0].Url, response.Archivos[0].Nombre);
                        let state_files = this.state.data
                        state_files.map(async (_item, _pos) => {
                            if (_item.Id_Estructura === item.Id_Estructura)
                                await this.handleChange("checked", _pos, 'data')
                        })
                    }
                    await this.setState({fetchingDownload: this.state.fetchingDownload})
                } else {
                    let arrayFiles = []
                    files.map(async item => {
                        await this.setState({fetchingDownload: this.state.fetchingDownload++})
                        arrayFiles.push({
                            Id_Estructura: item.Id_Estructura
                        })
                    });
                    let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.getUrlDescargaMultiple(this.state.expediente.Id_Expediente, this.props.trabajo, arrayFiles)
                    if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
                       // this.props.fetchErrorExpediente(response);
                    }

                    if (response.Archivos) {
                        response.Archivos.map((i, p) => {
                            this.download_file(i.Url, i.Nombre);
                            return null
                        })
                    }

                    await this.setState({fetchingDownload: this.state.fetchingDownload})

                }
            }
            if (temporalFiles.length) {
                let arrayArchivos = [];
                temporalFiles.map(item => {
                    arrayArchivos.push({Nombre: item.Nombre})
                    return null
                });
                let response = await ApiCoag.expedientes.downloadTemporalFiles(this.state.expediente.Id_Expediente, arrayArchivos)
                if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
                   // this.props.fetchErrorExpediente(response);
                }
                if (response.Archivos) {
                    response.Archivos.map((i, p) => {
                        this.download_file(i.Url, i.Nombre);
                        return null
                    })
                }
                let newData = [...this.state.temporalFiles];
                temporalFiles.map(item => {
                    newData = newData.filter(current => current.Nombre !== item.Nombre)
                    return null
                });

            }

        }
    }

    renderContenidoInfo() {
        let sHtmlStyle = "<style type='text/css'>.mdap table{border:1px solid #A9A9A9; border-collapse:collapse} .mdap table th{background-color:#F0F0F0;font-weight:800} .mdap table th, .mdap table td{color:black; border-bottom:1px solid #A9A9A9; padding:0.2em 0.4em; empty-cells:show;}</style>"  
        sHtmlStyle += "<div class='mdap' >"

        return(
            <Grid container className="px-1 py-4">
                <Grid item  xs={12}>
                <Typography variant={"body1"} style={{
                                        fontSize: 12,

                                        textAlign: 'left',
                                        fontWeight:200,


                                    }}>
                                       
                                        {this.props.translate("languages.fileUpload.labelNombresClavesAutoAsign")}

                                </Typography>
                </Grid>
                    <Grid item xs={12}>
                  <br></br>      
            <div dangerouslySetInnerHTML={{ __html: sHtmlStyle+this.state.dialogContent +"</div>"}}></div>
            </Grid>
            </Grid>
         
        );
      }

    async handleAutoAsignInfo() {
        let files = [];
        let result = await ApiCoag.expedientes.autoAssignFilesInfo(this.state.expediente.Id_Expediente, this.props.trabajo, files)
       


        if (result && result!='ERROR2')  
        { if (result.MensajesProcesado && result.MensajesProcesado.length==0 )                        
           {   
            this.setState({dialogOpen: true, dialogTitle: result.Titulo[0].Titulo, dialogContent:result.Contenido[0].Contenido});
            }
        }
    }   

   

    async handleAutoAsign() {      
        let {temporalFiles}=this.itemsToRemove();  
                                                
        if (temporalFiles.length > 0) {
            try {
                await this.setState({fetchingAutoAsign: true})
                let files = [];
                temporalFiles.map(item => {
                    files.push({
                        Nombre: item.Nombre                       
                    })
                    return null
                });
                let result = await ApiCoag.expedientes.autoAssignFiles(this.state.expediente.Id_Expediente, this.props.trabajo, files)                
                let insertados = []
                let asignados = []
                /* md este mensaje no tiene mucha utilidad
                //let resultados = []                
                if (result.Archivos) {
                    result.Archivos.map(item => {
                        if (item.Insertado !== 1) {
                            resultados.push(`<li style="padding-left: 0px">${item.Nombre} ${this.props.translate("languages.fileUpload.noInsertion")}</li>`)
                            //this.props.fetchErrorExpediente(api.formatMenssage(`${item.Nombre} ${this.props.translate("languages.fileUpload.noInsertion")}`))

                        } else {
                            resultados.push(`<li style="padding-left: 0px">${item.Nombre} ${this.props.translate("languages.fileUpload.successInsertion")} ${item.Carpeta}</li>`)
                            //this.props.fetchErrorExpediente(api.formatMenssage(`${item.Nombre} ${this.props.translate("languages.fileUpload.successInsertion")} ${item.Carpeta}`))
                        }
                        return null
                    })
                    this.props.fetchErrorExpediente(api.formatMenssage(`<ul  style="padding-left: 0px">${resultados.join('')} </ul>`))
                 */
                    
                if (result.Archivos) {

                    result.Archivos.map(item => {
                        if (item.Insertado == 1) {
                            insertados.push(`<li style="padding-left: 0px">${item.Nombre} ${this.props.translate("languages.fileUpload.noInsertion")}</li>`)                            
                        }
                        /*if (item.Id_Documentacion != '') {
                            asignados.push(item.Id_Estructura)                            
                        }*/
                        return null
                    })

                    if (insertados.length>0){
                        await this.props.refreshEstadoVisualEstructuraAfterAsignacionAuto()
                        this.loadInformation()                        
                    }
                    let title=''                     
                    title= this.props.trabajo + ' - '+ result.Titulo[0].Titulo//'Asignación automática'    

                     let filesAutoCopy = [];
                    result.Archivos.map(item => {
                        filesAutoCopy.push({
                            Nombre: item.Nombre,
                            Id_Documentacion:0,
                            //Carpeta_Primer_Nivel:item.Carpeta_Primer_Nivel,
                            //Carpeta:item.Carpeta,
                            Orden:0 //primero mostramos sin asignar y por tanto sin orden, será alfabético
                        })})
                                                                        
                   
                   this.setState({filesAuto: result.Archivos,filesAutoEdit: filesAutoCopy,carpetas:result.Carpetas,openConfirm:true,dialogTitle:title,asignados:0})
               
                   //await this.props.fetchErrorExpediente(api.formatMenssage(`Asignación automática de ${this.props.translate("languages.fileUpload.fileName")}s a carpetas de ${result.Titulo[0].Titulo}: <br><br> <ul  style="padding-left: 0px"> Asignados ${insertados.length}  de ${result.Archivos.length} </ul> `))
                     
                  
                } else {
                    if(result&&result.MensajesProcesado&&result.MensajesProcesado[0].Mensaje){
                        this.props.fetchErrorExpediente(actionsExpedientes.formatMenssage(result.MensajesProcesado[0].Mensaje));
                    }else{
                        //this.props.fetchErrorExpediente(actionsExpedientes.formatMenssage(this.props.translate("languages.messages.fetchError")));
                    }                
                }

                await this.setState({fetchingAutoAsign: false, showDeleteButton: false, showDownloadButton: false, checkAll:false, disableAutoAsignButton:true})
                await this.loadInformation();
               
            } catch (e) {
                await this.setState({fetchingAutoAsign: false})
                this.props.fetchErrorExpediente(actionsExpedientes.formatMenssage(e.message));
            }
        }
    }

       async handleSaveAsign() {
        let temporalFiles = this.state.filesAutoEdit
                               
        if (temporalFiles.length > 0 ) {
            try {
                await this.setState({fetchingAutoAsign: true})
                              
                let result = await ApiCoag.expedientes.autoAssignFilesSave(this.state.expediente.Id_Expediente, this.props.trabajo,temporalFiles)                
                let insertados = []
                let asignados = []
                let filesAutoEdit=[...this.state.filesAutoEdit]
               
                if (result.Archivos) {
                    result.Archivos.map(item => {
                        if (item.Insertado == 1) {
                         
                            insertados.push(item.Nombre)                           
                        }
                        if (item.Id_Documentacion != '') {
                            asignados.push(item.Id_Estructura)                            
                        }
                        return null
                    })

                    if (insertados.length>0){
                        await this.props.refreshEstadoVisualEstructuraAfterAsignacionAuto()
                        this.loadInformation()                        
                    }     
                    
                   
                    insertados.map(item => {
                        filesAutoEdit = filesAutoEdit.filter(current => current.Nombre !== item)                        
                    });

                    asignados = filesAutoEdit                    
                    asignados = asignados.filter(current => current.Id_Documentacion!=0)                                         
                              
                                                         
                } else {
                    if(result&&result.MensajesProcesado&&result.MensajesProcesado[0].Mensaje){
                        this.props.fetchErrorExpediente(actionsExpedientes.formatMenssage(result.MensajesProcesado[0].Mensaje));
                    }                
                }
                
                await this.setState({fetchingAutoAsign: false, showDeleteButton: false, showDownloadButton: false, checkAll:false, disableAutoAsignButton:true,filesAutoEdit:filesAutoEdit,asignados:asignados.length,openConfirm:filesAutoEdit==0?false:true})
                //await this.loadInformation();
               
            } catch (e) {
                await this.setState({fetchingAutoAsign: false})
                this.props.fetchErrorExpediente(actionsExpedientes.formatMenssage(e.message));
            }
        }
    }

    autoAsign()
    { 
        let newfilesAutoEdit = [];
        
        let asignados=[];
        let i=0;

        this.state.filesAutoEdit.map(item => {

            let enAsignAuto=[];

            //Nota:se excluyen de al asign .auto los asignados previamente a mano
            enAsignAuto=this.state.filesAuto.filter(x=>x.Nombre==item.Nombre && x.Id_Documentacion!=0)

            if (enAsignAuto.length==0){           
                newfilesAutoEdit.push({
                Nombre: item.Nombre,
                Id_Documentacion:item.Id_Documentacion,
                //Carpeta_Primer_Nivel:item.Carpeta_Primer_Nivel,
                //Carpeta:item.Carpeta,
                Orden:item.Orden
                    })
             }
            else
            {   if (item.Id_Documentacion==0) 
                    i+=1

                newfilesAutoEdit.push({
                Nombre: item.Nombre,
                Id_Documentacion:item.Id_Documentacion!=0?item.Id_Documentacion:enAsignAuto[0].Id_Documentacion,
                //Carpeta_Primer_Nivel:item.Carpeta_Primer_Nivel!='-'?item.Carpeta_Primer_Nivel:enAsignAuto[0].Carpeta_Primer_Nivel,
                //Carpeta:item.Carpeta!=''?item.Carpeta:enAsignAuto[0].Carpeta,
                Orden:item.Orden!=''?item.Orden:enAsignAuto[0].Orden
                     })

               
            }        
        })   
        
        asignados=newfilesAutoEdit.filter(x => x.Id_Documentacion!=0)
             
        this.setState({filesAutoEdit: newfilesAutoEdit,asignados:asignados.length,identificados:i})                      

    }

    async cellValueChanged(idDoc,nombre){
        let newFiles=this.state.filesAutoEdit
        let fileSel=newFiles.find(x => x.Nombre === nombre)
        let rowIndex=newFiles.indexOf(fileSel)
        newFiles[rowIndex]["Id_Documentacion"]=idDoc
        if (idDoc!=0)
            newFiles[rowIndex]["Orden"]=this.state.carpetas.find(x=>x.Id_Documentacion==idDoc).Orden_Documentacion
        else
            newFiles[rowIndex]["Orden"]=0;       

        let asign=newFiles.filter(x => x.Id_Documentacion!=0)
             
        this.setState({filesAutoEdit: newFiles,asignados:asign.length})
    }

    async changeOrder(type){
        let newFiles=[...this.state.filesAutoEdit]
        if (type=='c')
        {  
            newFiles.sort((a, b) => {
                let oa = (a.Nombre).toLowerCase()
                let ob = (b.Nombre).toLowerCase()
                 if (oa < ob) {return -1;}
                 if (oa> ob) {return 1;}
                 })

            newFiles.sort((a, b) => {
            let oa=0
            let ob=0
            oa = a.Orden?parseInt(a.Orden):0
            ob = b.Orden?parseInt(b.Orden):0
            if (oa < ob) {return -1;}
            if (oa> ob) {return 1;}
            })
        }
        else
        {newFiles.sort((a, b) => {
            let oa = (a.Nombre).toLowerCase()
            let ob = (b.Nombre).toLowerCase()
             if (oa < ob) {return -1;}
             if (oa> ob) {return 1;}
             })}
        this.setState({filesAutoEdit: newFiles})
    }

    
    async handleDocumentView(file) {
         try {
            let expediente = this.props.expediente.Expediente[0];
            let response = file.Id_Estructura
                ? await ApiCoag.expedientes.trabajo.estructuraDocumental.infoArchivovisualizacion(expediente.Id_Expediente, this.props.trabajo, file.Id_Estructura)
                : await ApiCoag.expedientes.downloadTemporalFiles(expediente.Id_Expediente, [{Nombre: file.Nombre}])

            if (response.Archivos && response.Archivos.length > 0) {
                window.open(response.Archivos[0].Url, "_blank");
            } else if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
             //   this.props.fetchErrorExpediente(response);
            }
        } catch (error) {
            this.props.fetchErrorExpediente(formatMenssage(error));
        }
    }


    renderSize(size) {       
            if (size < 1048576) {
                return (size / 1024).toFixed(2) + ' Kb'
            } else if (size < 1073741824) {
                return (size / 1024 / 1024).toFixed(2) + ' Mb'
            } else {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' Gb'
            }              
     }
     
 
    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    async dragOverUploadFile(dragInternoOn=false) {
        if (!this.state.itemSelToDragAndDrop && !this.props.itemsDragging)
        {      
            let makeid=this.makeid(50);

            /*NOTA 22/11/2022 comentado  mientras no se puede arrastrar desde equipo*/
            await this.setState({userLocalFileInsertRequest: true,makeid})
            setTimeout(async()=>{
            if(this.state.makeid==makeid){
                await  this.setState({userLocalFileInsertRequest: false})
            }},3000)
        }
    }

    async dragOverUploadFileEnd(dragInternoOn=false) {       
        setTimeout(async()=>{
            await  this.setState({userLocalFileInsertRequest: false})
        },500)
    }
    

    openInfo (titulocolumna){
        let language=2         
        if (titulocolumna.indexOf('Archivo')>=0)
        {language=1;}       
        if (language == 2)
        { window.open(URL_AUTOASIGN_1, '_blank')}
        else
        { window.open(URL_AUTOASIGN_2, '_blank')}
    }
    

     handleCloseDialog = () => {
         this.setState({ dialogOpen: false});
    };
    
        
   
    renderConfirmAsign() {
       
        return (this.state.openConfirm ?
                    <AsignFilesFolders openConfirm={this.state.openConfirm} 
                                aceptConfirmation={async () => {
                                     await this.handleSaveAsign();                                   
                                    }}                            
                                declineConfirmation={() => this.setState({openConfirm:false})}   
                                autoAsign={async () => {
                                    await this.autoAsign();                                   
                                    }}                                                       
                                cellValueChanged={(idDoc,name)=>this.cellValueChanged(idDoc,name)}
                                changeOrder={(orderEst)=>this.changeOrder(orderEst)}
                                openInfo={(tc)=>this.openInfo(tc)}
                                archivos={this.state.filesAutoEdit}
                                carpetas={this.state.carpetas}
                                asignados={this.state.asignados}
                                identificados={this.state.identificados}
                                title={this.state.dialogTitle}                             
                                />
                                :null                            
            )
        }                


    render() {
        let {classes} = this.props
        let disableButtons = this.props.disableActions;

        let isMobile_=isMobile();

        return (
            <div style={{paddingBottom: this.props.notInFolderPlace ? 0 : 20}}>
                {this.state.fetchingCenter ?
                    <Paper className="mt-3 mb-3">
                        <Grid container spacing={24}>
                            <Grid item xs={12} className='p-3 text-center'>
                                <CircularProgress/>
                            </Grid>
                        </Grid>
                    </Paper> : 
                    <ExpansionPanel expanded={this.state.expandSectionTempFile }
                                               onChange={() => this.setState({expandSectionTempFile: !this.state.expandSectionTempFile})}>
                        <ExpansionPanelSummary  style={{paddingLeft:8}} expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"display4"} style={{paddingLeft: this.props.notInFolderPlace ? 0 :10}}>
                            {!this.state.openConfirm ? this.props.translate("languages.fileUpload.TitleTemporalFolder"):                           
                            <Typography variant="display4" className="d-flex mb-0 mr-1" style={{paddingLeft: 4}}gutterBottom >
                                        Asignación de <Translate id="languages.fileUpload.fileName"/>s {'('}
                                        <Typography variant="display4" color="primary" style={{paddingLeft: 1,paddingRight: 6,color: 'orange'}}  gutterBottom >
                                        {this.state.asignados}  </Typography>  {' de ' + this.state.filesAutoEdit.length+')'}  
                                    </Typography>  }
                            {!this.state.openConfirm ? 
                             <text
                                style={{color: 'orange',paddingLeft:5}}> 
                                
                                {this.state.loading || (this.props.tempDragging) || this.state.fetchingAutoAsign?  <CircularProgress  size={14} style={{marginLeft: 4, marginTop: 1}}/>
                                :'('+(this.state.temporalFiles.length)+')' }

                                </text>:null}
                            </Typography>


                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{padding: "8px 0px 0px"}}>
                       
                           
                         <div style={{width: '100%'}}
                                 onDragLeave={async () => {this.dragOverUploadFileEnd()}}
                                 onDragEnter={async () => {this.dragOverUploadFile()}} >

                                {!this.state.openConfirm ?    
                                <Typography variant={"body1"} style={{
                                    fontSize: 12, marginLeft:15, marginRight:15,textAlign: 'justify', padding: '0 18px', marginTop: -27,paddingBottom:6
                                    }}>
                                    {/*<Typography variant={"body1"} style={{
                                        fontSize: 12,
                                        textAlign: 'left',
                                        fontWeight:600,
                                        }}> {this.props.translate("languages.fileUpload.ExplanationTemporalFolder1")}
                                        </Typography>*/}
                                       
                                        <b>{this.props.translate("languages.fileUpload.ExplanationTemporalFolder1")}</b>
                                        {this.props.translate("languages.fileUpload.ExplanationTemporalFolder2")}                                                                                 
                                        
                                        <span style={{cursor:'pointer', color:'#2196f3', textDecoration:'underline'}} 
                                    onClick={() => {this.handleAutoAsignInfo();}} >
                                                                       {this.props.translate("languages.fileUpload.TablaAsignacion")} 
                                                                        </span>
                                                                       
                                        {this.props.translate("languages.fileUpload.ExplanationTemporalFolder3")}                                     
                                    
                                </Typography>:
                                <Typography>
                                   
                                    <Typography variant={"body1"} style={{
                                        fontSize: 12, marginLeft:15, marginRight:15,textAlign: 'justify', padding: '0 18px', marginTop: -30
                                        }}>                                                 
                                        <b>{this.state.dialogTitle.replace(" - ","-")}</b>.{' '+this.props.translate("languages.fileUpload.autoAsignEdit") }  {/*<p><b> {this.state.dialogTitle} </b></p>*/}
                                    </Typography>  
                                 </Typography>     
                                } 
  
                                   
                                {this.state.openConfirm ?
                                    this.renderConfirmAsign():
                                <div>
                                    
                                    {this.state.userLocalFileInsertRequest  ?
                                        <Grid item xs={12}
                                              style={{paddingLeft: 2, paddingBottom: 2,marginBottom:'4px'}}>
                                            {<Dropzone accept="application/pdf"
                                                      style={{width:'calc(100% - 12px)',height:200,marginBottom:6,marginLeft:6,marginRight:6}}

                                                onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)}>
                                                {() => (
                                                    <div  style={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        flexDirection: "column",
                                                        alignItems:"center",
                                                        padding: 20,
                                                        borderWidth: 2,
                                                        borderRadius: 2,
                                                        borderColor:' #eeeeee',
                                                        borderStyle:"dashed",
                                                        width:'100%',
                                                        height:'100%',
                                                        backgroundColor:"#fafafa",
                                                        color: "#bdbdbd",
                                                        outline: "none",
                                                        transition:"border .24s ease-in-out"
                                                    }}> 
                                                        <inpu />
                                                           <p><Translate id="languages.fileUpload.fileUploadDropTemp"/></p>
                                                    </div>
                                                )}
                                            </Dropzone>}

                                        </Grid>
                                        :
                                        <div>
                                            <div>
                                            <Grid container>
                                            <Grid item xs={12} className=" text-right" style={{marginRight:5}}>
                                                <div className="" style={{float: 'right',marginBottom:6}}>            
                                                    <Tooltip id="t1" title={'Refrescar almacen'}>
                                                   <IconButton   color="primary" disabled={this.props.fileUpload.uploadInProgress}
                                                    onClick={() => {this.refresh();}}
                                                                   style={{                                                                    
                                                                       padding: '4px 12px 0 0',
                                                                       marginLeft: 0,marginTop: 1, marginBottom: 0,
                                                                   }}>
                                                                      {this.state.refresh ? <CircularProgress/>:<RefreshIcon/>}
                                                                                                                                            
                                                                   </IconButton>   

                                                    </Tooltip>
                                                                
                                                       </div>

                                                        <div className="" style={{float: 'right'}}>                                                   
                                                                                                          
                                                      { !this.state.allowUpload ? null :
                                                               
                                                                    <Dropzone
                                                                        className={'dropSelector'}
                                                                        style={{

                                                                        borderStyle: 'none',

                                                                    }}
                                                                              accept="application/pdf"
                                                                              onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)}>                                                                             
                                                                        <Button color="primary"
                                                                                style={{
                                                                                    fontSize: 12,
                                                                                    padding: '6px 12px'                                                                                 
                                                                                }}
                                                                                disabled={disableButtons || this.state.fetchingAutoAsign || this.props.fileUpload.uploadInProgress}>
                                                                            <Translate
                                                                                id="languages.generalButton.uploadFile"/>
                                                                            {!this.props.fileUpload.uploadInProgress && 
                                                                            <CloudUpload style={{marginLeft: 5}}/>}
                                                                            {(this.props.fileUpload.uploadInProgress &&this.props.fileUpload.typeUpload=='toTemporal') &&
                                                                            <CircularProgress size={22} style={{marginLeft: 2}}/>}    
                                                                        </Button>
                                                                            </Dropzone> 
                                                                                                                                                                                                         
                                                                 }     
                                                      
                                                            
                                                        </div>
                                                        {
                                                            this.state.temporalFiles  ?
                                                                <div className="" style={{float: 'right'}}>
                                                                    
                                                                    <Button className="px-1"
                                                                            style={{fontSize: 12, padding: '4px 10px'}}
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                this.handleAutoAsign()
                                                                            }}
                                                                            disabled={this.props.fileUpload.uploadInProgress || this.state.disableAutoAsignButton || this.state.fetchingAutoAsign  || this.props.notInFolderPlace}>
                                                                        <Translate
                                                                            id="languages.fileUpload.automaticSection"/>
                                                                         { this.state.fetchingAutoAsign &&
                                                                        <div className="" style={{marginLeft: 2}}>
                                                                        <CircularProgress size={22}   />
                                                                        </div>
                                                                            }                                                                        
                                                                    </Button>
                                                                  
                                                                        {/* !this.state.fetchingAutoAsign && !isMobile_ &&
                                                                        
                                                                        <Tooltip title={this.props.translate("languages.fileUpload.labelInfoClavesAutoAsign") }>
                                                                        <IconButton   onClick={() => {this.handleAutoAsignInfo();}} color="primary" aria-label="Info"  style={{marginLeft: 0,marginTop: 1, marginBottom: 0, fontSize: 14, padding: '0px 0px'}}>
                                                                        {  <Info/>}                                                                            
                                                                        </IconButton>   
                                                                        </Tooltip> 
                                                                        */                                                                                                                                          
                                                                      }    
                                                                                                                                             
                                                                    </div>                                                                     
                                                                    :
                                                                    null
                                                                
                                                        }
                                                        <div className="" style={{float: 'right'}}>
                                                            <Button className="px-2"
                                                                    color="primary"
                                                                    style={{fontSize: 12, padding: '4px 8px'}}
                                                                    onClick={() => {
                                                                        this.handleDownload()
                                                                    }}
                                                                    disabled={this.state.showDownloadButton != true || this.state.fetchingRemove || this.state.fetchingDownload || this.state.fetchingAutoAsign || this.props.fileUpload.uploadInProgress}>
                                                                <Translate id="languages.generalButton.download"/>
                                                            </Button>
                                                            {this.state.fetchingDownload ? <CircularProgress size={24}
                                                                                                             className={classes.buttonProgress}/> : null}
                                                        </div>
                                                        <div className="" style={{float: 'right'}}>
                                                            <Button className="px-2"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        this.handleRemove()
                                                                    }}
                                                                    style={{fontSize: 12, padding: '4px 8px'}}
                                                                    disabled={this.state.showDeleteButton != true || this.state.fetchingRemove 
                                                                    || disableButtons || this.state.fetchingAutoAsign || this.state.fetchingDownload || this.props.fileUpload.uploadInProgress}>
                                                                <Translate id="languages.generalButton.delete"/>
                                                            </Button>
                                                            {this.state.fetchingRemove  &&
                                                            <CircularProgress size={24}
                                                                              />}
                                                    </div>
                                                </Grid>
                                                </Grid>

                                                <div style={{background: "#f5f5f5"}}>

                                                    
                                                    <Divider height={2}/>
                                                    <Grid container className="py-1 px-2 "   >
                                                        <Grid item md={6} xs={6} className="d-flex mr-1">
                                                            <Checkbox
                                                                checked={this.state.checkAll}
                                                                disabled={this.state.temporalFiles.length==0}
                                                                onChange={this.handleCheckAll()}
                                                                className="pl-1"
                                                                style={{
                                                                    padding: 0,
                                                                    marginRight: 6
                                                                }}
                                                            />
                                                            <Typography variant="subtitle2" className="text-uppercase text-center">
                                                                <Translate id="languages.fileUpload.fileName"/>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={2} xs={2}>
                                                            <Typography variant="subtitle2" className="text-uppercase ">
                                                                {/*<Translate id="languages.fileUpload.folder"/>*/}
                                                            </Typography>
                                                        </Grid>
                                                        
                                                        <Grid item md={2} xs={2} className="p-2" > 
                                                        {/* md no mostramos icono firma
                                                            <Typography variant="subtitle2">
                                                                <Translate id="languages.fileUpload.firm"/>
                                                            </Typography>*/}                                                                                                    

                                                        </Grid> 
                                                    
                                                                                                                

                                                       
                                                    </Grid>
                                                    <Divider height={2}/>
                                                </div>
                                            </div>
                                            <div style={{width: '100%', overflow: 'hidden'}}>
                                                <div style={{
                                                    width: 'calc(100% + 15px)',
                                                    overflowY: 'scroll',
                                                    overflowX: 'hidden'
                                                }}>
                                                    <Grid container spacing={16} className="m-0">
                                                    
                                                        {
                                                            (this.state.data && this.state.data.length > 0) || (this.state.temporalFiles && this.state.temporalFiles.length > 0) ?
                                                                <Grid item xs={12} className="p-0">
                                                                    <Grid container spacing={24} className="mb-0">
                                                                        <Grid item xs={12}
                                                                              style={{
                                                                                  paddingLeft: 12,
                                                                                  paddingBottom: 0
                                                                              }}>
                                                                            {
                                                                                this.state.temporalFiles && this.state.temporalFiles.map((item, pos) => {
                                                                                    return (
                                                                                        <div draggable="true"
                                                                                             className={'draggable'}
                                                                                             onDragEnd={() => {   
                                                                                                        this.props.dragging(false);                                                                                                 
                                                                                                        this.setState({ itemSelToDragAndDrop:false });                                                                                                                                                                                                                                                      
                                                                                                     }}
                                                                                             onDragStart={this.dragStart(item, true) }   
                                                                                            
                                                                                                                                                                                                                                                                                    
                                                                                             style={{backgroundColor: '#cecece'}}
                                                                                        ><ExpansionPanel
                                                                                            classes={{root: classes.rootPanel}}
                                                                                            expanded={this.state.panelExpanded === item.Nombre}
                                                                                            onChange={this.expandPanel(item.Nombre, false)}
                                                                                            style={{borderRadius: 0}}>
                                                                                            <ExpansionPanelSummary
                                                                                                expandIcon={
                                                                                                    <ExpandMoreIcon/>}
                                                                                                classes={{
                                                                                                    content: classes.margin,
                                                                                                    expanded: classes.margin,
                                                                                                    root: pos % 2 !== 0 && classes.backgroundColor
                                                                                                }}
                                                                                                className="pl-0">
                                                                                                <Grid container
                                                                                                      spacing={0}>
                                                                                                    <Grid item md={6}
                                                                                                          xs={6}
                                                                                                          className='d-flex align-items-center'>
                                                                                                        <Checkbox                                                                                                           
                                                                                                            checked={item.checked ? item.checked : false}
                                                                                                            onChange={this.handleChange("checked", pos, 'temporalFiles')}
                                                                                                            value={item.Nombre}
                                                                                                        />
                                                                                                        <Typography
                                                                                                            title={item.Nombre}
                                                                                                            className={classes.orange}>{item.Nombre}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item md={2}ign
                                                                                                          xs={2}
                                                                                                          className="align-self-center">
                                                                                                        <Typography
                                                                                                         
                                                                                                            className={classes.orange}>
                                                                                                            {/*<Translate
                                                                                                                id="languages.fileUpload.unAssigned"/>*/}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    
                                                                                                    <Grid item md={2}
                                                                                                          xs={2}
                                                                                                          className="text-right align-self-center">
                                                                                                              {/* md no mostramos icono firma
                                                                                                        <ErrorOutline
                                                                                                            className={classes.orange}
                                                                                                            size={24}/>*/}
                                                                                                     </Grid>
                                                                                                </Grid>
                                                                                            </ExpansionPanelSummary>
                                                                                            <ExpansionPanelDetails
                                                                                            className={pos % 2 !== 0 && classes.backgroundColor}>
                                                                                                
                                                                                                <Grid container
                                                                                                      spacing={16}>
                                                                                                    <Grid item xs={6}
                                                                                                          className="pt-0" /*"align-items-center"*/>
                                                                                                        <Grid container
                                                                                                              spacing={0}>
                                                                                                            <Grid item
                                                                                                                  xs={12}>
                                                                                                                <Typography
                                                                                                                    variant="button"
                                                                                                                    gutterBottom
                                                                                                                    className="text-uppercase">                                                                                                                  
                                                                                                                    <Translate
                                                                                                                        id="languages.fileUpload.fileSize"/>
                                                                                                                </Typography>
                                                                                                            </Grid>

                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6}
                                                                                                          className="pt-0">
                                                                                                        <Grid container
                                                                                                              spacing={0}>
                                                                                                            <Grid item
                                                                                                                  xs={12}>
                                                                                                                <Typography
                                                                                                                    variant="button"
                                                                                                                    gutterBottom>
                                                                                                                      {this.state.size!='--'?this.renderSize(this.state.size):<CircularProgress  size={12}/>}
                                                                                                                </Typography>
                                                                                                            </Grid>

                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    {/* <Grid item xs={2}
                                                                                                          className="pt-0">
                                                                                                        </Grid>*/}

                                                                                                    <Grid item xs={6}
                                                                                                          className="pt-0">
                                                                                                        <Grid container
                                                                                                              spacing={0}>
                                                                                                            <Grid item
                                                                                                                  xs={12}>
                                                                                                                <Typography
                                                                                                                    variant="button"
                                                                                                                    gutterBottom
                                                                                                                    className="text-uppercase">
                                                                                                                    <Translate
                                                                                                                        id="languages.fileUpload.uploadDate"/>
                                                                                                                </Typography>
                                                                                                            </Grid>

                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6}
                                                                                                          className="pt-0">
                                                                                                        <Grid container
                                                                                                              spacing={0}>
                                                                                                            <Grid item
                                                                                                                  xs={12}>
                                                                                                                <Typography
                                                                                                                    variant="button"
                                                                                                                    gutterBottom>
                                                                                                                 {this.state.modifydate!='--'?this.state.modifydate:<CircularProgress  size={12}/>}
                                                                                                                </Typography>
                                                                                                            </Grid>

                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                   {/* <Grid item xs={2}
                                                                                                          className="pt-0">
                                                                                                        </Grid>*/}

                                                                               

                                                                               {/* md metemos firmas del archivo */}
                                                                               
                                                                               < Grid item xs={6}
                                                                                                          className="pt-0">
                                                                                                        <Grid container
                                                                                                              spacing={0}>
                                                                                                                <Grid item
                                                                                                                  xs={12}>
                                                                                            <Typography variant="button" gutterBottom >
                                                                                                <Translate id="languages.fileUpload.fileFirms" />
                                                                                            </Typography>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                     </Grid>
                                                                                     < Grid item xs={6}
                                                                                                          className="pt-0">
                                                                                                        <Grid container
                                                                                                              spacing={0}>
                                                                                                                <Grid item
                                                                                                                  xs={12}>
                                                                                                {                                                                                                   
                                                                                                    this.state.firmasDigitales ? 
                                                                                                    this.state.firmasDigitales.length > 0
                                                                                                        ? this.state.firmasDigitales.map((fd, pos) => {
                                                                                                            return fd.Id_Archivo === item.Id_Archivo
                                                                                                                ? <div className="pt-0 pb-2 px-0">
                                                                                                                    <Typography variant="body1">
                                                                                                                        {`${fd.Nif} ${fd.Nombre}`}
                                                                                                                        <br/>
                                                                                                                        {`${fd.Numero_Serie != null &&  fd.Numero_Serie.length>15 ? fd.Numero_Serie.substring(0,15) + "..." : fd.Numero_Serie}`  /*md por si es largo*/}
                                                                                                                        <br/>
                                                                                                                        {`${fd.Autoridad_Certificadora}`}
                                                                                                                    </Typography>

                                                                                                                </div>
                                                                                                                : ""
                                                                                                        })
                                                                                                        : <div>
                                                                                                            <ListItemText primary="--" />
                                                                                                        </div>
                                                                                                     : <CircularProgress  size={12}/>
                                                                                                       
                                                                                                }
                                                                                            </Grid>

                                                                                        </Grid>

                                                                                    </Grid>
                                                                                
                                                                               

                                                                                                    <Grid item xs={12}>
                                                                            <span className={classes.link}
                                                                                  onClick={() => this.handleDocumentView(item)}>
                                                                               <Translate id="languages.fileUpload.viewDocument" /> { item.Nombre /*md vemos el nombre del archivo*/} 
                                                                            </span>
                                                                                                    </Grid>
                                                                                                </Grid>

                                                                                    
                                                                        </ExpansionPanelDetails>
                                                                                        </ExpansionPanel></div>)
                                                                                })
                                                                            }

                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                :


                                                                <Grid item xs={12}>
                                                                    <Grid container spacing={24}>
                                                                     
                                                                      
                                                                       <Grid item xs={12} className="text-center py-4">
                                                                            
                                                                            <Typography variant="subtitle1"
                                                                                        gutterBottom>
                                                                                <Translate
                                                                                    id="languages.fileUpload.noResult"/><br/>
                                                                                    
                                                                            </Typography>

                                                                            <Typography variant={"body1"} className="text-center py-2"
                                                                                        gutterBottom>
                                                                                
                                                                                {<Translate  id="languages.fileUpload.noFileUpload"/>}
                                                                                                                                                                
                                                                            </Typography>
                                                                        
                                                                            {/*<Typography variant={"body1"} style={{fontSize: 12, padding: "2 2px 2 2",textAlign: 'left'}}
                                                                                        gutterBottom>                                                                                                                                                             
                                                                                <Translate
                                                                                    id="languages.fileUpload.ExplanationTemporalFolder2"/><br/>
                                                                                   
                                                                            </Typography>*/}
                                                                       
                                                                        </Grid>
                                                                     
                                                                    </Grid>
                                                                 
                                                                </Grid>                                                    

                                                        }
                                    
                                                    </Grid>
                                                </div>
                                            </div>
                                        </div>}
                                </div>

                                }
                            </div>
                       
                        </ExpansionPanelDetails>
                    </ExpansionPanel>                             
                }
                     


                
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"                    
                   >
                    <DialogTitle id="alert-dialog-title">
                        {this.state.dialogOpen?this.state.dialogTitle:null}
                    </DialogTitle>
                    <DialogContent>

                  
                    <DialogContentText id="alert-dialog-description">
                    <div  style={{ fontSize:12 , marginLeft: 24, marginRight: 24, marginTop: -25, marginBottom: 24}}>   
                                { this.renderContenidoInfo() }        
                                </div>
                    </DialogContentText>
                  

                    </DialogContent>
                    <DialogActions>                  
                    <Button onClick={this.handleCloseDialog} color="primary" >
                    <Translate id="languages.generalButton.aceptar"/>
                    </Button>
                    </DialogActions>
                </Dialog>


            </div>


        );
    }


}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(TemporalFolder)));
