import React from 'react';
import { Col, Row } from 'reactstrap';
import { IconButton, Button, TextField, FormControl, Select, MenuItem, CircularProgress, Typography, Divider } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import {rtrim,ltrim,isMobile} from "../../api/index";


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    formControl: {
        width: "100%"
    }
});

class Buscador  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          
            text: this.props.text,
          
        };
       
      }

    handleClose = () => {
        this.props.handleClose();
    };

    handleSearch = async () => {        
        let filtro=ltrim(rtrim(this.state.text));
        this.setState({
            text: filtro,
        });
        await this.props.handleSearch(filtro);
    };

   

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
        this.props.handleChangeText(event.target.value);
    };

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {          
            this.handleSearch(this.state.text)
        }
    }

    handleSelectChange = (event) => {
        this.props.handleSelectChange(event);
    };

    

    render() {
        const { modal, tituloModal, descripcion, classes,  types, loadingSearch } = this.props;

        return (
            
            <Col xs={12}>
                <Row>
                    <Col xs={12} className="d-flex justify-content-between align-items-center">
                        <Typography variant="h5" gutterBottom className="m-0">
                            {modal ? <Translate id="languages.search.generalTitle" /> : tituloModal!=''?<Translate id={tituloModal.props?tituloModal.props.id:tituloModal} />:null}
                        </Typography>
                        <IconButton className={classes.button} aria-label="Close" onClick={this.handleClose}>
                            <Close />
                        </IconButton>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col xs={12} className="pb-3">
                        <Typography variant="subtitle2" gutterBottom className="pt-3">
                            {modal ? <Translate id="languages.search.generalDescription" /> : descripcion!=''?<Translate id={descripcion.props?descripcion.props.id:descripcion} />:null}
                        </Typography>
                        {this.props.idAccion==6     && 
                                    <Typography variant="subtitle2" gutterBottom className="pt-3" style={{fontWeight:'bold'}}>
                                    { <Translate id="languages.messages.trabajoComunicacionCese" />}
                                </Typography>
                            }
                        <div className="align-items-center" style={!isMobile() ? { width: 600, margin: "auto" } : { margin: "0 0 0 4" }}>
                            <Row className="align-items-center">
                                <Col xs={modal ? !isMobile() ? 6 : 7 : 6} className={!isMobile() ? "pl-0 pr-2" : "pl-4 pr-2"}>
                                    <FormControl width="95%" className={classes.formControl}>
                                    <TextField
                                                                    id="filter"
                                                                    label={<Translate id="languages.search.generalTerm"/>}
                                                                    onKeyDown={this._handleKeyDown}
                                                                    value={this.state.text}
                                                                    onChange={this.handleChange('text')}
                                                                    margin="normal"
                                                                    autoFocus={true}
                                                                />
                                    </FormControl>
                                </Col>
                              
                                {modal &&
                                    <Col xs={!isMobile() ? 3 : 5} className={!isMobile() ? "p-0" : "pr-4"}>
                                        <FormControl className={classes.formControl}>
                                            <Select
                                                className="pt-4"
                                                value={types}
                                                onChange={this.handleSelectChange}
                                            >
                                                <MenuItem value="expedientes">
                                                    <Translate id="languages.generalText.expedientes" />
                                                </MenuItem>
                                                <MenuItem value="trabajos">
                                                    <Translate id="languages.generalText.trabajos" />
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                }
                                {!isMobile() &&
                                    <Col xs={2} className="p-1">
                                        <Button variant="contained" color="primary" style={{ marginTop: 23, paddingLeft: 10, paddingRight: 10 }} className={classes.button} disabled={loadingSearch} onClick={this.handleSearch}>
                                            <Translate id="languages.generalButton.search" />
                                            <Search className="ml-2" />
                                        </Button>
                                    </Col>
                                }
                                {!isMobile() &&
                                    <Col xs={1} className="align-self-center pt-2">
                                        {loadingSearch && <CircularProgress size={26} />}
                                    </Col>
                                }
                            </Row>
                            {isMobile() &&
                                <div className="d-flex justify-content-center">
                                    <Button variant="contained" color="primary" style={{ paddingLeft: 10, paddingRight: 10 }} className={classes.button} disabled={loadingSearch} onClick={this.handleSearch}>
                                        <Translate id="languages.generalButton.search" />
                                        <Search className="ml-3" />
                                    </Button>
                                    {loadingSearch && <CircularProgress size={24} className="align-self-center" />}
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </Col>

          
        );
    }
}

export default withStyles(styles)(Buscador);