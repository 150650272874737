import React, {Component} from 'react';
import {
    AppBar, Toolbar, withStyles, Grid, Button, Collapse,
    ListItemText, Divider, Dialog, DialogTitle, DialogContent, DialogActions,IconButton, RadioGroup, FormControlLabel, Radio,FormControl,Tooltip,
    MenuList, MenuItem, Popper, Grow, ClickAwayListener,Paper
} from '@material-ui/core';


import {
    CloudDownload, ExpandLess,
    ExpandMore,  Notifications, Check, Add,Delete, CancelOutlined,  NotificationsNoneOutlined,
    Input    
} from '@material-ui/icons';
import Menu from '@material-ui/icons/MoreVert'


import {List, ListItem, ListSubheader, CircularProgress} from '@material-ui/core';
import {grey, red} from '@material-ui/core/colors';
import {connect} from "react-redux";
import {withLocalize} from "react-localize-redux";
import {Translate} from "react-localize-redux";
import PropTypes from 'prop-types';
import './index.css';
import {withRouter} from 'react-router-dom';
import TrabajoComunicacion from './Trabajos/ComunicacionEncargo/index';
import TrabajoEjecucion from './Trabajos/ProyectoEjecucion/index';
import MenuOption from './Trabajos/ProyectoEjecucion/menuProyectoEjecucion';
import {fetchErrorExpediente, formatMenssage} from "../../actions/expedientes";
import {groupBy, filter, isNull} from 'lodash';
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import ExpedienteGeneral from "./Trabajos/ComunicacionEncargo/general";
import * as actionsExpedientes from '../../actions/expedientes';
import Confirmation from "../Errors/confirmation";
import ApiCoag from "../../api/ApiCoag";
import Typography from "@material-ui/core/Typography";
import {isMobile,getDefaultLanguage} from "../../api/index";
import {TAM_BASE,TAM_MAX,ID_TRABAJO_ENCOMENDA,ID_TRABAJO_VAL_GEOTECNICO,ID_DOCUMENTACION_ANEXOS_MEMORIA,ID_DOCUMENTACION_GEOTECNICO} from "../../api/config";

import TramitacionUrgente from "./Trabajos/ProyectoEjecucion/tramitacionUrgente";




//import { faClosedCaptioning } from '@fortawesome/free-solid-svg-icons';


const styles = theme => ({
        fichaExpediente: {
            backgroundColor: theme.palette.primary.main,
            color: "white !important"
        },
      
        root: {
            flexGrow: 1,
            overflowX: 'auto',
            marginTop: -34,
            position:'fixed', //modificado ble porque si no no salen opciones navbar correctamente tras fijar encabezado en app.js con el style panel
            right:0, //modificado ble porque si no no salen opciones navbar correctamente 
            marginRight:10
        },
        rootMobile: { //sin fixed para que se vean bien las  opciones navbar 
            flexGrow: 1,
            overflowX: 'auto',
            marginTop: 0,            
            marginLeft: 5,            
            right:0 //modificado ble porque si no no salen opciones navbar correctamente
        },
        rootMasOpciones: {          
            marginTop: -48,
            marginRight: -12, /*-12*/
            position:'fixed', //modificado ble porque si no no salen opciones navbar correctamente tras fijar encabezado en app.js con el style panel   
            right:0, //modificado ble porque si no no salen opciones navbar correctamente 
            zIndex:40      
        },
        rootMasOpcionesMobile: {   
            marginTop: -53,
            marginRight: -30,            
            right:0, //modificado ble porque si no no salen opciones navbar correctamente 
            zIndex:40 ,
            float: 'right'      
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginLeft: -12,
            marginRight: 20,
        },
        main: {
            height: 900
        },
        mainNav: {
            boxShadow: "none",
            flexDirection: "row-reverse",
            backgroundColor: "transparent"
        },
        button: {
            margin: '0px 3px',
            padding: '4px!important',
            paddingLeft: 6,           
            fontSize: 13,
            '&:hover': {
                background: '#fff',
                webkitBoxShadow: '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
                ///-webkit-box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
                boxShadow: '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
                borderRadius: 0,
                padding: '4px!important',
            }


        },

        buttonEdit: {
            border: "1px solid",
            padding: 6,
            margin: 6          
          },
               
     
        button2: {
            margin: 0,
            padding: "0px 12px",
            fontSize: 13,            
            '&:hover': {
                backgroundColor: "#1976d2 !important",
                padding: "0px 12px",
                borderRadius: '4px!important',
                webkitBoxShadow: '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
                ///-webkit-box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
                boxShadow: '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
               
            }
        },
        headerNav: {
            margin: "auto",
            borderBottom: "solid 1px " + grey[300],
            position: "relative",
            cursor: "pointer",
            lineHeight: 1.5,
            fontSize: 16,
            fontWeight: 500,
            color: "#232323"
        },
        headerNavTituloExpediente: {
            margin: "auto",
            borderBottom: "solid 1px " + grey[300],
            position: "relative",
            cursor: "pointer",
            lineHeight: 1.5,
            fontSize: 14,
            fontWeight: 500,
            color: "#232323"
        },
        leftNav: {
            flexGrow: 1,
            height: 50 //window.innerHeight * 0.81 
            //height:'100%'
        },
       
        backgroundGrey: {
            backgroundColor: grey[100]
        },
        boredrRight: {
            borderRight: "1.5px solid #CCC",
            
        },
        noMaxWidth: {
            maxWidth: 'none',
            fontSize: 16
        },       
        panelLateralEstructura:{
            scrollbarWidth: 'none',           
            height: `calc(100% - 132px)` ,                            
            width: '419px',
            position: 'fixed',
            left: '0',
            bottom: '0',
            overflow:'auto',        
            boxSizing:'border-box',
            borderRight: "1px solid #CCC",
            backgroundColor:"white"
        },       
        panelLateralEstructuraMobile:{                 
            width: '100%',  
            backgroundColor:"white"                      
        },
        panelLateralEstructuraIn:{height:'100%'},
        panelCentralConEstructura:{
            height: '100%',
            marginLeft:'419px',
            overflowy: 'auto',
            boxSizing:'borderbox',
            width:'100%'                         
        },
        panelCentralConEstructuraMobile:{           
            width:'100%'                         
        },
        iconMasOpcionesSafari:{
            paddingTop:2,
            color: '#0080FF',  /*'#fff'*/
            zIndex:60                 
        },
        iconMasOpcionesSafariMobile:{
            paddingTop:2,
            color: '#0080FF',
            marginRight: -25 
        },
        masOpciones:{        
            "&:hover": {
                background: "white"                
              }
        },
        buttonActionUrgencia: {
            color:'orange' 
        }  

    })    
;

const mapStateToProps = (state) => ({
    fileUpload: state.status.files
})

const mapDispatchToProps = {
    fetchErrorExpediente: actionsExpedientes.fetchErrorExpediente,
    uploadFiles: actionsExpedientes.uploadFiles,
    resetUploadStates: actionsExpedientes.resetUpladStates,
   
};


class VisualizarExpediente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            loadPrimerTrabajo:false,
            renderComponent: "TrabajoComunicacion",
            expediente: null,
            currentExpediente: null,
            idTrabajoActivo: null,
            idEstructuraActiva: null,
            idEstructuraActivaPadre: null,            
            estructurasAbiertas: [],
            titleEstructuraActiva: "",
            estructuraActiva: null,
            estructuraDocumental: [],
            estructurasPadre: [],
            //isLoadEstructura: false,
            active: false,
            optionsComponent: this.props.match.params.idTrabajo ? 2 : 1, //Indica si lo seleccionado en Expediente(1), Trabajo(2), Estructura(3)
            presentWork: false,
            openConfirmWork: false,
            openConfirmExp: false,
            showModal:false,
            fetchingUrgencia:false,                       
            fetchingAutoRetener:false,            
            dataFolder:null, 
            dataFolderTemporal:null, 
            dragging:null,   
            tempDragging:null,       
            idEstructuraActivaBeforeDragging:null,          
            refreshRequest:false,
            refreshRequestFolders:false,                             
            tipoExportarADisco:'Individuales',
            tipoExportarADiscoArchivosIndividuales:'SubCarpetas',
            //modifiedWork:false,
            removingFiles:false ,
            //changedTrabajo:false,           
            idEstrUpload:0,
            idEstrUploadPadre:0,
            tieneArchivos:0,
            openConfirmUpload50:false,            
            openConfirmUploadTam:false,
            openMenuMasOp:false,
            tituloTrabajoActivo :'' ,
            estructuraIdDocumentacion:null ,       
            openConfirmImportFiles:false,
            openConfirmImportFilesGeo:false,
            openConfirmImportFilesGeoPresentado:false                
        };
        this.menuLateral = React.createRef()
    }

    // async UNSAFE_componentWillMount() {
    //             await this.fetchExpediente();                                    
                
    // }

    async componentDidMount() {
        await this.fetchExpediente();                   
}

    // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {        

    //     this.reloadAfther1Second(nextProps)
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.recargarTrasUpload()
                }
    }
     
    
    /*async isModified(item)    
    {    this.setState({modifiedWork:item});              
    }*/


    async recargarTrasUpload() {

         setTimeout(() => {
              if (this.props.fileUpload.fetchingDone&&this.props.fileUpload.typeUpload=='toEstructura') {                              
                              
               
                      this.refreshEstadoVisualEstructuraAfterUploadFiles();                              
                    
                      this.setState({idEstrUpload:0,idEstrUploadPadre:0,mustRefresh:false});                                      
                  }                                                      
          },100)
  
      }

    
    // async reloadAfther1Second(nextProps) {

    //   //'2'+this.props.fileUpload.uploadInProgress+' '+this.props.fileUpload.pendingUploadList.length+' '+ this.props.fileUpload.uploadLength+' '+ this.props.fileUpload.currentUpload+' '+ this.props.fileUpload.fetchingDone+' '+ this.props.fileUpload.typeUpload});   
    //     //this.setState({log:moment(value, 'HH:mm:ss')})

    //     await setTimeout(() => {
    //         if (nextProps.fileUpload.fetchingDone&&this.props.fileUpload.fetchingDone&&this.props.fileUpload.typeUpload=='toEstructura') {                              
                            
             
    //                 this.refreshEstadoVisualEstructuraAfterUploadFiles();                              
                              
    //                 if (this.state.mustRefresh)
    //                     this.loadInformationFolder();                
    //                 //this.props.dispatchSetFetchingDone() se hace esto en FilesFolder2
    //                 //*this.props.refreshTree();*/                
    //                 //this.setState({idEstrDropLocal:0,idEstrDropLocalPadre:0});              
    //                 this.setState({idEstrUpload:0,idEstrUploadPadre:0,mustRefresh:false});                                      
    //             }                                                      
    //     },0)

    // }

    switcToolbar(option) {
        this.setState({optionsComponent: option});
    }

    //Consumir api con el id de expediente espicificado por ur
    async fetchExpediente() {
        let response = await ApiCoag.expedientes.getDatosGenerales(this.props.match.params.id);   


        //3- Success
        if (response)    {
            if  (response != 'ERROR2') {
            let expediente = response;
            let currentExpediente = expediente.Expediente.length > 0 ? expediente.Expediente[0] : null;
            let activeTrabajo = this.props.match.params.idTrabajo
                ? this.props.match.params.idTrabajo
                : null;
           

            await this.setState({
                expediente: expediente,
                currentExpediente: currentExpediente,
                idTrabajoActivo: activeTrabajo, 
                loadPrimerTrabajo:true              
            });
            if (activeTrabajo != 0)
            {//await this.handleChangeMenuOption(activeTrabajo); Nota:no hace falta llamar aquí, ya se hace en evento hace click en arbol izquierda
            }
            
            if (activeTrabajo == null)
            await this.handleChangeMenuOption(null);

            await this.setState({               
                loadPrimerTrabajo:false              
            });

             }
            else { /*Si hay error2 en la carga es que es operación prohibida y mandamos a pantalla inicio*/
                 await this.setState({         
                    loadPrimerTrabajo:false   , 
                    expediente:null ,
                    currentExpediente:null         
                    });

                    this.props.history.push(`/`);
            }
        }
    }

    async recargaNavBarListaTrabajosYContenidoTrabajoActivo(iIdActiveTrabajo) {
        let response = await ApiCoag.expedientes.getDatosGenerales(this.props.match.params.id);   


        //3- Success
        if (response) {
            let expediente = response;
            let currentExpediente = expediente.Expediente.length > 0 ? expediente.Expediente[0] : null;
            let activeTrabajo = iIdActiveTrabajo >0 ?iIdActiveTrabajo : null;
           

            await this.setState({
                expediente: expediente,
                currentExpediente: currentExpediente,
                idTrabajoActivo: activeTrabajo               
            });

            
            if (activeTrabajo && activeTrabajo != 0 )
            {
                let iTipoTrabajo=expediente.Trabajos.find(x=>x.Id_Trabajo==activeTrabajo).Id_Tipo_Trabajo
                await this.handleChangeMenuOption(activeTrabajo,true,iTipoTrabajo);
            }
            else
            await this.handleChangeMenuOption(null);
           
        }
    }

    async recargaNavBarYListaTrabajos() {
        let response = await ApiCoag.expedientes.getDatosGenerales(this.props.match.params.id);   
        
        if (response) {
            let expediente = response;
            let currentExpediente = expediente.Expediente.length > 0 ? expediente.Expediente[0] : null;                     

           this.setState({
                expediente: expediente,
                currentExpediente: currentExpediente           
            });
           
        }
    }


    updateExpediente(expedienteData) {
 
        if (expedienteData) {
            this.setState({
                expediente: expedienteData,
                currentExpediente: expedienteData.Expediente.length > 0 ? expedienteData.Expediente[0] : null
            })
        }
    }


    handleExpandMenu() {
        this.setState(state => ({open: !state.open}));
    };

    async getSetEstructuraDocumentalYArchivos(idExpediente, idTrabajo, idEstructuraMostrarArchivos=-1,loadingTree=false,responseCarpetas=null) {
       
        //md si no queremos archivos no los incluimos en la respuesta y evitamos tráfico, para ello idEstructuraMostrarArchivos=-1

        if (idExpediente === null)
            return false;
        let estructurasNivel2 = [];
        let groupEstructura = [];
        let estructurasNivel1 = [];
        let estructuraChildrens = {};
       
        try {
                        
            let response=null;
            let  estructurasDocumentales=null
            if ( idEstructuraMostrarArchivos>=0) /*0 es el trabajo*/
             {  response = await ApiCoag.expedientes.trabajo.getEstructuraDocumentalYArchivosDeCarpeta(idExpediente, idTrabajo,idEstructuraMostrarArchivos==null?0:idEstructuraMostrarArchivos)
                estructurasDocumentales=response.EstructurasDocumentales                    
             }
            else
             { if (responseCarpetas==null){ //si non null xa non calculamos
                response = await ApiCoag.expedientes.trabajo.getEstructuraDocumentalCarpetas(idExpediente, idTrabajo);
                estructurasDocumentales=response.Carpetas
                }
                else
                estructurasDocumentales=responseCarpetas               
             }             

            if (response && response.MensajesProcesado && response.MensajesProcesado.length > 0) {
                //// this.props.fetchErrorExpediente(response);             
                this.setState({estructuraDocumental: []});
            } else {
                estructurasNivel1 = filter(estructurasDocumentales, {"Nivel_Documentacion": 1});
                estructurasNivel2 = filter(estructurasDocumentales, {"Nivel_Documentacion": 2});
                groupEstructura = groupBy(estructurasNivel2, "Titulo_Padre");
                for (let i = 0; i < estructurasNivel1.length; i++) {                    
                    estructuraChildrens[estructurasNivel1[i].Titulo] = groupEstructura[estructurasNivel1[i].Titulo] ? groupEstructura[estructurasNivel1[i].Titulo] : estructurasNivel1[i];
                  
                }

                if (idEstructuraMostrarArchivos<0) //includeFiles == null || includeFiles== false)
                {
                    this.setState({
                        estructuraDocumental: estructuraChildrens,
                        estructurasPadre: estructurasNivel1,                       
                    });
                }
                else
                {   let documentos = response.Archivos;
                    let firmasDigitales = response.FirmasDigitales;                        
                                  
                    this.setState({
                        estructuraDocumental: estructuraChildrens,
                        estructurasPadre: estructurasNivel1,                                            
                        dataFolder: documentos, firmasDigitales,  
                        folders:response.Folders,                        
                        fetchingData:(!loadingTree?false:true), /*Importante decir que se acabo la carga de bd salvo en carga inicial*/
                        //folderInfo:null,
                        tieneArchivos:documentos.length>0?1:0,                                                
                                                         
                    });            
                    
                    // if (recargaDatosBasicosTrabajos) /*solo debe entrar aquí si estamos refrescando un trabajo (icono refrescar). El refresco se indica desde componente menuProyectoEjecucion(arbol)*/
                    //     this.recargaNavBarYListaTrabajos()
                    
                }

            }
            return {estructuraDocumental: estructuraChildrens, estructurasPadre: estructurasNivel1};
        } catch (e) {
            this.props.fetchErrorExpediente(formatMenssage(e.message));     
            return {estructuraDocumental: [], estructurasPadre: []};
        }
    }

      

    async handleChangeMenuOption(idTrabajo, showLoading = true,idTipoTrabajo,loadingTree=false) {
        let active = this.state.active == idTrabajo ? -1 : idTrabajo;
       
         
        if (idTrabajo==0) /* estamos refrescando */
        {
            this.setState({fetchingRefresh: true}) /*Nota:importante eso aquí para que mande lanze propiedades a objeto TrabajoEjecucion y recargue, que es cuando idTrabajo=0}*/
        } 

        if (this.state.currentExpediente) {
            if (showLoading) {
                if (null === idTrabajo) {
                    this.setState({
                        renderComponent: "ExpedienteGeneral",
                        idTrabajoActivo: idTrabajo,
                        idEstructuraActiva: null,
                        titleEstructuraActiva: "",
                        estructuraActiva: null,
                        estructuraIdDocumentacion: null,   
                        active: active,
                        idEstructuraActivaPadre:   null  ,
                        dataFolder: null 
                    });
                } else {

                    if ( idTipoTrabajo==ID_TRABAJO_ENCOMENDA ) {
                            this.setState({
                            renderComponent: "TrabajoComunicacion",
                            idTrabajoActivo: idTrabajo,
                            idEstructuraActiva: null,
                            titleEstructuraActiva: "",
                            estructuraActiva: null,
                            estructuraIdDocumentacion: null,   
                            active: active,
                            idEstructuraActivaPadre:   null    ,
                            /*dataFolder: null     /*md importante: si cambiamos de trabajo los datos para actualizar vista carpetas Folder y Folder2 a null */  
                            folderInfo:null, 
                            fetchingData:true     
                        });
                    } else {                 
                        
                            this.setState({
                            renderComponent: "TrabajoEjecucion",
                            idTrabajoActivo: idTrabajo,
                            idEstructuraActiva: null,
                            titleEstructuraActiva: "",
                            estructuraActiva: null,
                            estructuraIdDocumentacion: null,   
                            active: active,
                            idEstructuraActivaPadre:   null,   
                            /*dataFolder: null ,      /*md importante: si cambiamos de trabajo los datos para actualizar vista carpetas Folder y Folder2 a null */                                                                        
                            folderInfo:null, 
                            fetchingData:true       
                        });                                                                
                    }

                       /*Nota:  cambio de trab. obtemos arbol*/
                       if (idTrabajo && idTrabajo>0 && !this.state.loadPrimerTrabajo  && !loadingTree)
                       {
                          if (this.state.idTrabajoActivo && this.state.idTrabajoActivo != idTrabajo && idTrabajo>0)                              { 
                                await this.getSetEstructuraDocumentalYArchivos(this.state.currentExpediente.Id_Expediente, idTrabajo,0);}
                          else   
                              {                 
                              /*Nota:  sen cambio de trab click en trabajo sin refresco, obtemos arbol e de paso tamen obtemos arquivos*/                         
                              await this.getSetEstructuraDocumentalYArchivos(this.state.currentExpediente.Id_Expediente, idTrabajo,0);                                                                                                                                                        
                              }
                          await this.throwRefreshEstadoVisualEstructura()

                          this.recargaNavBarYListaTrabajos() 
                       }   

                       if (loadingTree)
                          this.setState({fetchingData:false})
                       
                      
                }
            }

          
            
           if (idTrabajo==0)
            {this.setState({fetchingRefresh: false})}           
         
        }
    }


   async getArchivos(idEstructura)   {                                        
    let response=null;

    if (idEstructura>0)
         response =  await ApiCoag.expedientes.trabajo.estructuraDocumental.archivos(this.state.expediente.Expediente[0].Id_Expediente, this.state.idTrabajoActivo, idEstructura );             
    else
         response=await ApiCoag.expedientes.trabajo.getEstructuraDocumental(this.state.expediente.Expediente[0].Id_Expediente, this.state.idTrabajoActivo);    
        
       return response;                                                      

   }

  

    setWorkSpaceToTrabajoEjecucion = (idTrabajo) => {
        this.setState({
            renderComponent: "TrabajoEjecucion",
            idTrabajoActivo: idTrabajo
        });
    }

    handleExpediente() {
        this.setState({
            idTrabajoActivo: null,
            active: false,
            renderComponent: "ExpedienteGeneral",
            idEstructuraActiva: null,
            estructuraIdDocumentacion: null,   
            titleEstructuraActiva: "",
            estructuraActiva: null,       
            idEstructuraActivaPadre:   null           
        });
        this.switcToolbar(1);
    }

    async handleChangeEstructuran(idEstructura, titleEstructura, estructura = false) {
     
       
        if (!estructura ) {
            Object.values(this.state.estructuraDocumental).map((mayores, p) => {
                if (mayores[0]) {
                    Object.values(mayores).map(item => {
                        if (item.Id_Estructura == idEstructura) {
                            estructura = item
                        }
                    })
                } else {
                    if (mayores.Id_Estructura == idEstructura) {
                        estructura = mayores
                    }
                }
            })                  
        }

      

         this.setState({fetchingData: true, 
            titleEstructuraActiva: titleEstructura,
            //estructuraNivel:estructura.Nivel_Documentacion,
            estructuraPermiteArchivos:estructura.Permite_Anexar_Archivos, 
            estructuraEditable :estructura.Editable          
             });
       

        /*Estamos en carpeta e solo obtemos os seus archivos xa que compensa a maior rapidez a obter toda a estructura*/

        let r =  await this.getArchivos(idEstructura);            
                            
        let documentos=r.Archivos;
        //let firmasDigitales=r.FirmasDigitales;
        let folderInfo=r.InfoCarpeta[0];       
    
           
        await this.setState({                
                idEstructuraActiva: idEstructura ,
                titleEstructuraActiva: titleEstructura,
                estructuraActiva: estructura,
                idEstructuraActivaPadre:   estructura.Id_Estructura_Padre ,                             
                idEstructuraActivaBeforeDragging: null,
              
                //estructuraNivel:estructura.Nivel_Documentacion,
                estructuraPermiteArchivos:estructura.Permite_Anexar_Archivos,                 
                estructuraEditable :estructura.Editable,             
                estructuraTamMax:estructura.Proporcion_Tamanho_Maximo_Archivos,   
                estructuraIdDocumentacion: estructura.Id_Documentacion,   
                dataFolder : documentos    ,       //Al cambiar de carpeta el contenido de la misma ya no va a ser el resultado devuelto                                                 
                folderInfo:folderInfo ,
                fetchingData: false 
                             
        });

        await this.compruebaUpload()
        await this.setState({mustRefresh:this.checkRefreshFolder(this.state.idEstrUpload,this.state.idEstrUploadPadre,true)}); 
       
              
    }

 
    getLi = async () => {
        let response = await ApiCoag.expedientes.postExpedienteAccion(this.props.match.params.id, 4);
        if (response.InfoAccion && response.InfoAccion.length > 0) {
            let url = response.InfoAccion[0].Url;
            url = url.replace(" ", "");
            window.open(url, '_blank');
        }
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
        } else
            this.props.fetchErrorExpediente(
                formatMenssage(<Translate id="languages.messages.noLi"/>));
    }

   
    avisaryPresentarTrabajo = async () => {           
 
        /*Comprobamos geo*/       
        if (this.comprobarAvisoGeotecnicoValidado() ) {/*&& !this.state.avisosPresentacionRealizados ){*/
         this.handleImport(false,true)                
         }
        else{ 
            this.presentarTrabajo()
         }
     }


    presentarTrabajo = async () => {
      
        let iIdTrabajoActivo=this.state.idTrabajoActivo
       
         this.setState({presentWork: true  });
         let result = await ApiCoag.expedientes.trabajo.cerrarTrabajo(this.props.match.params.id, this.state.idTrabajoActivo);
         if(result){
            // md si viene mensaje error no hacemos nada 
            //(result.MensajesProcesado && result.MensajesProcesado.length > 0) {
 
                 // this.props.fetchErrorExpediente(result);
                 ////window.location.reload();
             if (result == 'ERROR2')   
             { 
 
                 
             } else {               
                   
                 await this.setState({                    
                     idTrabajoActivo: -1
                   });             
                 //this.fetchExpediente();              
                 this.recargaNavBarListaTrabajosYContenidoTrabajoActivo(iIdTrabajoActivo)
                                                
                 }   
             }   
         
             this.setState({presentWork: false});
         }
     

  

    handleChangeTipoExportarADisco = () => async (event) => {
        if (event.target.value != undefined && (event.target.value == 'Individuales' || event.target.value == 'Compendio' || event.target.value == 'CompendioCarpetas' ))
        await this.setState({    tipoExportarADisco : event.target.value  })

    }

    handleChangeTipoExportarADiscoArchivosIndividuales = () => async (event) => {
        if (event.target.value != undefined && (event.target.value == 'Trabajo' || event.target.value == 'Carpetas' || event.target.value == 'SubCarpetas' ))
        await this.setState({    tipoExportarADiscoArchivosIndividuales : event.target.value  })

    }

       
    async exportarADisco() {
          this.setState({  showModalExportarADisco:true , tipoExportarADisco : "Individuales" })                                                           
    }

    async exportarADiscoModal(tipo,subTipoIndividual) {
        
        await this.setState({ fetchingDownload: 1 , showModalExportarADisco:false })     
        let response = null;
        if (tipo =='Individuales' ) {               
            
            if (subTipoIndividual == 'SubCarpetas')
                { response =  await ApiCoag.expedientes.trabajo.estructuraDocumental.getUrlDescargaTrabajoParaRetirar(this.props.match.params.id, this.state.idTrabajoActivo)  }                                                                                                         
            if (subTipoIndividual == 'Carpetas')
                { response =  await ApiCoag.expedientes.trabajo.estructuraDocumental.getUrlDescargaTrabajoParaRetirarCarpetasPrincipales(this.props.match.params.id, this.state.idTrabajoActivo)   }
            if (subTipoIndividual == 'Trabajo')
                { response =  await ApiCoag.expedientes.trabajo.estructuraDocumental.getUrlDescargaTrabajoParaRetirarSinCarpetas(this.props.match.params.id, this.state.idTrabajoActivo)   }
        
            if (response.Archivos && response.Archivos.length === 1) {                        
                this.download_file(response.Archivos[0].Url, response.Archivos[0].Nombre);                                                   
                }      
            } 
        else {                      
          let response =  await ApiCoag.expedientes.trabajo.estructuraDocumental.getUrlDescargaTrabajoParaRetirarCompendio(this.props.match.params.id, this.state.idTrabajoActivo)                                                                    
          if (response.Archivos && response.Archivos.length === 1) {                        
            this.download_file(response.Archivos[0].Url, response.Archivos[0].Nombre);                                                   
            }      
        } 
               
        await this.setState({ fetchingDownload: 0 })                                                   
  }

    

    download_file(fileURL, fileName) {
        // for non-IE
        if (fileURL!=null){
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
            save.download = fileName || filename;
            if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href;
                // window event not working here
            } else {
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
        }

        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
        }
    }


    
    autoRetener = async () => {
       
        this.setState({
            fetchingAutoRetener:true
        })

        let iIdTrabajoActivo = this.state.idTrabajoActivo

        let result = await ApiCoag.expedientes.trabajo.autoretener(this.props.match.params.id, this.state.idTrabajoActivo);
     
        if (result == 'ERROR2')   
        { 
            
        } else {                     

            await this.setState({                    
                idTrabajoActivo: -1
            });             
            //this.fetchExpediente();              
            this.recargaNavBarListaTrabajosYContenidoTrabajoActivo(iIdTrabajoActivo)        
         
            }

        this.setState({
            fetchingAutoRetener:false,idTrabajoActivo: iIdTrabajoActivo
          });
                         

    }


    solicitarUrgencia(trabajoActual) {
        this.setState({
            showModal: true,
            tituloTrabajoActivo:trabajoActual.Id_Trabajo+' - '+trabajoActual.Titulo             
        })
    }

    aceptarSolicitarUrgencia() {         
         this.setState({showModal:false}) 
         this.recargaNavBarYListaTrabajos() 
    }

    anularUrgencia() {        
        this.setState({showModal:false})
        this.recargaNavBarYListaTrabajos()
    }

    comprobarAvisoGeotecnicoValidado()
    { 
        let trabajoActual = this.state.expediente.Trabajos.find(t => t.Id_Trabajo == this.state.idTrabajoActivo)
        let hayTrabajoGeoValidacion = this.state.expediente.Trabajos.find(t => t.Id_Tipo_Trabajo == ID_TRABAJO_VAL_GEOTECNICO && t.Id_Trabajo<this.state.idTrabajoActivo)
        let carpetaAnexos=null;
        let carpetaGeo=null;    

        if (hayTrabajoGeoValidacion){
            carpetaAnexos=this.state.estructurasPadre.find(x=>x.Id_Documentacion==ID_DOCUMENTACION_ANEXOS_MEMORIA)               
            if (carpetaAnexos!=null )
                carpetaGeo=this.state.estructuraDocumental[carpetaAnexos.Titulo].find(x=>x.Id_Documentacion==ID_DOCUMENTACION_GEOTECNICO)            
        }
        
        if (hayTrabajoGeoValidacion!=null  && trabajoActual.Es_Trabajo_Nuevo && carpetaGeo!=null && carpetaGeo.Numero_Archivos_Carpeta==0) 
            return true
        else
            return false        

    }


    modificarEstado = (propertyName) => event => {

        let estado = this.state;
        estado[propertyName] = event.target.value;

        this.setState(estado)


    }

    async descargarCertPre(){   
        this.setState({ openMenuMasOp:false,fetchingDownload: 1 }) 
        let url = await  ApiCoag.expedientes.trabajo.estructuraDocumental.getUrlDescargaCertPresentacion(this.props.match.params.id, this.state.idTrabajoActivo)
        this.download_file(url.Archivos[0].Url,url.Archivos[0].Nombre)
        this.setState({ fetchingDownload: 0 }) 
     }

     handleToggle = () => {
        this.setState(state => ({ openMenuMasOp: !state.openMenuMasOp }));       
    };

    handleClose = () => {
        this.setState({openMenuMasOp: false });
    };

    renderIconMasOpciones(isMobile_,classes,trabajoActual){    
        let {openMenuMasOp}=this.state;
        return (
            <div className={!isMobile_?classes.rootMasOpciones:classes.rootMasOpcionesMobile} >    
                     
                                    <Tooltip id="t1" title={!openMenuMasOp?this.props.translate("languages.generalButton.masOpciones"):''}>
                                        <IconButton   color="primary" aria-label="Info"  style={{marginLeft:0,marginTop: 4,marginRight:42,fontSize: 14, padding: '0px 0px 0px'}}
                                          buttonRef={node => {
                                            this.anchorEl = node;
                                        }}
                                        aria-owns={openMenuMasOp ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleToggle}>                                    
                                                <Menu style={{paddingTop:2}}/>                                    
                                       </IconButton>                                     
                                    </Tooltip>   
                                    <Popper open={openMenuMasOp} disablePortal={!isMobile_?true:false} anchorEl={this.anchorEl} style={{zIndex:61 }} transition >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            id="menu-list-grow"
                                            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={this.handleClose}>
                                                    <MenuList >     
                                                    <MenuItem  className={classes.masOpciones} onClick={()=>{this.descargarCertPre()}} >
                                                            <Button  color="primary" className="px-2" > Certificado de presentación                                                      
                                                            </Button>
                                                        </MenuItem>                                                                 
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                    </Popper>
                            
                         </div>                                          
        )
    }


   

    detalles() {

        if (this.state.showModal) {
            return (<TramitacionUrgente showModal={this.state.showModal}  
        idExpediente=  {this.props.match.params.id}    
        titulo={this.state.tituloTrabajoActivo}                                          
        idTrabajo= {this.state.idTrabajoActivo}
        solicitar={() => this.aceptarSolicitarUrgencia()}
        anular={() => this.anularUrgencia()}
        cancelar={() => this.setState({showModal:false}) }
         />
        )}
       


        if (this.state.showModalExportarADisco) {
            let {titulo} = this.state
            return (<div>
                <Dialog open={this.state.showModalExportarADisco}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                      <Translate id="languages.fileUpload.downloadWork"/> {this.state.currentExpediente.Expediente_Codigo + ',' + this.state.idTrabajoActivo}
                    </DialogTitle>

                    <DialogContent>
                        <Grid container spacing={0}>
             
                            <Grid item xs={12} sm={6}>                                            
                            </Grid>  

                            <Grid container spacing={0} >
                                                               <Grid item xs={12}>
                                 
                                                                        
                                                                   </Grid>
                                                                   <FormControl>
                                                                   <RadioGroup
                                                                     aria-label="Gender"
                                                                     name="gender1"                                                                                   
                                                                     value={this.state.tipoExportarADisco ? this.state.tipoExportarADisco : "Individuales"}                                                                                    
                                                                     onChange={this.handleChangeTipoExportarADisco()}
                                                                     row >
                                                                                                                                  
                                                      
                                                                                    <FormControlLabel value="Individuales"
                                                                                                      control={<Radio color={ this.state.tipoExportarADisco == undefined || this.state.tipoExportarADisco == "Individuales" ?  "primary" : "secondary"}/>}
                                                                                                       label={<Translate id="languages.fileUpload.filesInd"/> }
                                                                                                      className="m-0 text-uppercase font-weight-bold"
                                                                                                      />  
                                                                                                      {  

                                                                                                         <RadioGroup
                                                                                                            aria-label="Gender"
                                                                                                            name="gender1Individuales"                                                                                                                                                                                             
                                                                                                            value={this.state.tipoExportarADiscoArchivosIndividuales !=null ? this.state.tipoExportarADiscoArchivosIndividuales : 'Trabajo'}                                                                                    
                                                                                                            onChange={this.handleChangeTipoExportarADiscoArchivosIndividuales()}
                                                                                                            row >  
                                                                                                            
                                                                                                                    <FormControlLabel value="SubCarpetas"
                                                                                                                          control={<Radio color={this.state.tipoExportarADiscoArchivosIndividuales == "SubCarpetas" ? "primary" : "secondary"}/>}
                                                                                                                          label={<Translate id="languages.fileUpload.filesInd1"/> }
                                                                                                                            className="pl-5  pr-2 font-weight-normal"
                                                                                                                            disabled={ this.state.tipoExportarADisco!= undefined && this.state.tipoExportarADisco!='Individuales'}
                                                                                                                    />  
                                                                                                            

                                                                                                            
                                                                                                                    <FormControlLabel value="Carpetas"
                                                                                                                          control={<Radio color={this.state.tipoExportarADiscoArchivosIndividuales == "Carpetas" ? "primary" : "secondary"}/>}
                                                                                                                          label={<Translate id="languages.fileUpload.filesInd2"/> }
                                                                                                                            className="pl-5  pr-2  font-weight-normal"
                                                                                                                            disabled={ this.state.tipoExportarADisco!= undefined && this.state.tipoExportarADisco!='Individuales'}
                                                                                                                    />  
                                                                                                          

                                                                                                          
                                                                                                                    <FormControlLabel value="Trabajo"
                                                                                                                          control={<Radio color={this.state.tipoExportarADiscoArchivosIndividuales == "Trabajo" ? "primary" : "secondary"}/>}
                                                                                                                          label={<Translate id="languages.fileUpload.filesInd3"/> }
                                                                                                                            className="pl-5 pr-2  font-weight-normal"
                                                                                                                            disabled={ this.state.tipoExportarADisco!= undefined && this.state.tipoExportarADisco!='Individuales'}
                                                                                                                    />  
                                                                                                            
                                                                                                         

                                                                                                        </RadioGroup>     
                                                                                                      }                                                                                                                                                                                                                                                                                                                                                                                           
                                                                                                                            
                                                                                     
                                                                                   {/*} <FormControlLabel value="CompendioCarpetas"
                                                                                                      control={<Radio color={this.state.tipoExportarADisco == "CompendioCarpetas" ? "primary" : "secondary"}/>}
                                                                                                      label={"Por cada carpeta principal un .pdf* "}
                                                                                                      className="m-1 text-uppercase font-weight-bold"
                                                                                                    />   */}                                                                                                                                                                                                                                                                                                                                                                                                  
                                                                   
                                                                                                                                   
                                                      
                                                                                    <FormControlLabel value="Compendio"
                                                                                                      control={<Radio color={ this.state.tipoExportarADisco == "Compendio" ? "primary" : "secondary"}/>}
                                                                                                      label={<Translate id="languages.fileUpload.filesOneFile"/>}
                                                                                                      className="m-0 text-uppercase font-weight-bold"
                                                                                    />                                                                                                                                                                                                                                                                                                                                                                                                     
                                                                   
                                                                   

                                                                    </RadioGroup>                   
                                                                    </FormControl>                                                                                                   

                                                                                                   

                            </Grid>   

                             <Grid item xs={12}>               
                                <p></p>
                            </Grid>     

                            <Grid item xs={12}>     
                            <Typography  style={{textAlign: "center"}}>
                            <Translate id="languages.fileUpload.validoEfectosImpresion"/>
                            </Typography>                                                            
                            </Grid>

                        </Grid>
                    </DialogContent>

                    <DialogActions>
                   
                        <Button onClick={async () => {
                         //this.exportarADisco()
                         this.exportarADiscoModal(this.state.tipoExportarADisco,this.state.tipoExportarADiscoArchivosIndividuales)
                      

                        }} variant="contained"
                                disabled={false}
                                color="primary" autoFocus style={{height: 30, minHeight: 30}}
                                className="p-1">
                            Aceptar

                        </Button>
                        
                        <Button onClick={async () => {
                            this.setState({

                                showModalExportarADisco: false,
                                tipoExportarADisco:'Individuales'
                                
                            })

                        }} variant="contained"
                                color="primary" autoFocus style={{minHeight: 30}}
                                className="p-1">
                            <Translate id="languages.fileUpload.cerrar" />

                        </Button>

                    </DialogActions>
                </Dialog>
            </div>);

        }
   
    }

    getLoa = async () => {
        let response = await ApiCoag.expedientes.postExpedienteAccion(this.props.match.params.id, 3);
        if (response.InfoAccion && response.InfoAccion.length > 0) {
            let url = response.InfoAccion[0].Url;
            url = url.replace(" ", "");
            window.open(url, '_blank');
        }
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
        } else
            this.props.fetchErrorExpediente(
                formatMenssage(<Translate id="languages.messages.noLoa"/>));
    }

    deleteTrabajoExpediente = async () => {
        let response = await ApiCoag.expedientes.trabajo.deleteTrabajo(this.props.match.params.id, this.state.idTrabajoActivo);
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
        }
        //this.props.fetchErrorExpediente(response);
        else
            this.props.history.push(`/visualizar-expediente/${this.props.match.params.id}`);
    }

    deleteExpedient = async () => {
        let result = await ApiCoag.expedientes.deleteExpediente(this.props.match.params.id);
        if (result && result.MensajesProcesado && result.MensajesProcesado.length === 0)
            this.props.history.push("/");
        else {
            //this.props.fetchErrorExpediente(result);
        }
    }

  
    checkRefreshFolder(idEstrUpload,idEstrUploadPadre,bCompruebaUpload=false)
    {   //Recargamos tras upload si añadimos desde carpeta o Temporal
        //En caso de upload, solo recargamos si:
             //-no hay estructura activa
             //- estructura activa coincide con estructura upload  (upload carpeta, drop carpeta, drop en arbol)                    
             //- esctructura activa coincide con estructura padre upload
       
       let idEstrActiva=this.state.idEstructuraActiva;
       return (idEstrUpload==0 || (!idEstrActiva || idEstrActiva==idEstrUpload || idEstrActiva==idEstrUploadPadre ))    
    }

    async compruebaUpload(){      

       if (this.props.fileUpload&&!this.props.fileUpload.uploadInProgress&&this.props.fileUpload.typeUpload=='toEstructura'&&this.state.idEstrUpload!=0){      
         this.setState({idEstrUpload:0,idEstrUploadPadre:0}) 
        }
    }

   


    async onDropEstructura(acceptedFiles,idEstrDropLocal=0,idEstrDropLocalPadre=0,tamMax=0) {
        this.onDrop(acceptedFiles,idEstrDropLocal,idEstrDropLocalPadre,tamMax)        
    }

    async onDropFolder(acceptedFiles) {           
        this.onDrop(acceptedFiles)
    }    


    checkFiles50(acceptedFiles){
        let files = []
        let sfilesKO=''

        acceptedFiles.forEach(file => { 
            if (file.name.length>50)               
                {
                files.push({                    
                    name: file.name,
                    name50:file.name.toLowerCase().replace(".pdf","").substring(0,45)+".pdf",
                    data: file
                    })                
                sfilesKO+="<p> - "+file.name+"<p>"  
                }          
        });
        return files
    }

    /* excluimos os nomes repetidos */
    soloDis(files,filesKO50){
        let result= files;
        let rep=[];
        for (let i=0; i<filesKO50.length-1; i++)        
        {  rep=filesKO50.filter(x=>x.name50==filesKO50[i].name50)
            if (rep.length>1)
               {
                rep.map(item => {
                    result = result.filter(c => c.name !== item.name)                    
                });
               }                
           }        
        return result
    }
   

  

    checkFilesTam(acceptedFiles,tamMb,temp=false,maxtemp=false){
        let files = []      
        let topSize = tamMb *1024*1024;           
        
        acceptedFiles.forEach(file => { 
            if( (!temp && file.size>topSize ) || (temp && file.size>topSize && file.size<TAM_MAX*1024*1024) || (temp && maxtemp && file.size>topSize ) )                       
                {
                files.push({
                    name: file.name,
                    data: file,
                    size: file.size
                    })                                 
                }                         
        });        
        return files     
    }

    archivosKO(files){
        let sfilesKO=""       
        files.forEach(file => {    
                if (!file.name50)                
                    sfilesKO+="<p> - "+file.name
                else
                    sfilesKO+="<p> - "+file.name.toLowerCase().replace(".pdf","").substring(0,45)+"<u style='color:red;'>"+file.name.toLowerCase().replace(".pdf","").substring(45) +"</u>.pdf"
                if (file.size)
                    sfilesKO+=" ("+this.renderSize(file.size)+")"
                sfilesKO+="</p>"      
                })
        return sfilesKO
    }

    renderSize(size) {
        if (size < 1048576) {
            return (size / 1024).toFixed(2) + ' Kb'
        } else if (size < 1073741824) {
            return (size / 1024 / 1024).toFixed(2) + ' Mb'
        } else {
            return (size / 1024 / 1024 / 1024).toFixed(2) + ' Gb'
        }    
    }

    mensaje50(archivos,temp=false){
        let mensaje=''
        if (archivos!=''){
            let subir='subiranse'       
            if (getDefaultLanguage()=="2"){            
                if (temp){}else{subir='engadiranse'} 
                mensaje='Os seguintes arquivos teñen un nome que supera os 50 caracteres. Se o desexas '+ subir +' recortando do nome os últimos caracteres que se indican en vermello:  <br></br>' + archivos + '<br></br>' + 'En calquera caso, lembra que o nome dos arquivos subidos debe ser descritivo do seu contido e que non poden existir na estrutura documental dun traballo dous arquivos co mesmo nome. <br></br>¿Continuar?' //e ' +subirlos+' recortando o nome?'
            }
            else{   
                if (temp){subir='se subirán'}else{subir='se añadirán'} 
                mensaje= 'Los siguientes archivos tienen un nombre que supera los 50 caracteres. Si lo deseas '+ subir +' recortando del nombre los últimos caracteres que se indican en rojo: <br></br>' + archivos + '<br></br>' + 'En cualquier caso, recuerda que el nombre de los archivos subidos debe ser descriptivo de su contenido y que no pueden existir en la estructura documental de un trabajo dos archivos con el mismo nombre. <br></br>¿Continuar?' //e ' +subirlos+' recortando o nome?'
            }  
        }  
        return mensaje    
    }

    mensajeTam(archivos,tamMb,temp=false,tempmax=false){   
        let mensaje='';
        if (archivos!='') 
            { if (!temp || tempmax){
                if (getDefaultLanguage()=="2"){                        
                    mensaje='Os seguintes arquivos superan o tamaño máximo permitido '
                    if (!tempmax)  mensaje+='para esta carpeta ('+tamMb+' Mb), polo que non se engadirán:'                    
                    }
                else{   
                    mensaje='Los siguientes archivos superan el tamaño máximo permitido '
                    if (!tempmax)  mensaje+='para esta carpeta ('+tamMb+' Mb), por lo que no se añadirán:'                    
                }
                mensaje+='<br></br>'+archivos
            }
            else{
                if (getDefaultLanguage()=="2"){                        
                    mensaje='Subiranse ao almacén os seguintes arquivos con tamaño superior a '+tamMb+' Mb: <br></br>'+archivos+'<br></br>'
                            +'Lémbrase que estes arquivos só se poderán engadir a carpetas correspondentes a documentación de terceiros (proxectos parciais, escrituras, autorizacións,...), '
                            +'sempre que estas carpetas admitan o tamaño correspondente. <br></br> ¿Desexas continuar coa subida?'
                    }
                else{   
                    mensaje='Se subirán al almacén los seguientes archivos con tamaño superior a '+tamMb+' Mb: <br></br>'+archivos+'<br></br>'
                            +'Se recuerda que estos archivos solo se podran añadir a carpetas correspondientes con documentación de terceros (proyectos parciales, escrituras, autorizaciones,...), '
                            +'siempre que estas carpetas admitan el tamaño correspondiente. <br></br> ¿Deseas continuar con la subida?'    
                }
            }
        }
        return mensaje
    }

    

    async onDrop(acceptedFiles,idEstr=0,idEstrPadre=0,tamMax=0) {
                    
                        
        tamMax=(tamMax==0)?this.state.estructuraTamMax*TAM_BASE:tamMax*TAM_BASE       
        let filesKOTam=this.checkFilesTam(acceptedFiles,tamMax)  
        let mensajeKOTam=this.mensajeTam(this.archivosKO(filesKOTam),tamMax)     
        
        let finalFiles=acceptedFiles
        filesKOTam.map(item => {
            finalFiles = finalFiles.filter(c => c.name !== item.name)            
        });
       
        let filesKO50 =this.checkFiles50(finalFiles) 
        let mensajeKO50=this.mensaje50(this.archivosKO(filesKO50))  
        
        
        //excluir de filesKO50 los repetidos
        finalFiles=this.soloDis(finalFiles,filesKO50)
       
        if (idEstr==0)
            this.setState({uploadFiles:finalFiles,idEstrUpload:this.state.idEstructuraActiva,idEstrUploadPadre:this.state.idEstructuraActivaPadre})                        
        else
            this.setState({uploadFiles:finalFiles,idEstrUpload:idEstr,idEstrUploadPadre:idEstrPadre})

        if (filesKO50.length==0 && filesKOTam.length==0 )
            this.onDropUpload(finalFiles,idEstr,idEstrPadre)     
        else{

            if (mensajeKOTam!='')
                this.setState({openConfirmUploadTam:true,mensajeKOTam:mensajeKOTam,cancelUpload:false})                    
            
            if (mensajeKO50!='')
                this.setState({openConfirmUpload50:true,mensajeKO50:mensajeKO50,cancelUpload:false})                                
            }   
    }

    async onDropTemp(acceptedFiles) {
     
        let filesKOTam=this.checkFilesTam(acceptedFiles,TAM_BASE,true)  
        let mensajeKOTam=this.mensajeTam(this.archivosKO(filesKOTam),TAM_BASE,true) 

        let filesKOTamMax=this.checkFilesTam(acceptedFiles,TAM_MAX,true,true)  
        let mensajeKOTamMax=this.mensajeTam(this.archivosKO(filesKOTamMax),TAM_MAX,true,true) 
        let finalFiles = acceptedFiles;
        filesKOTamMax.map(item => {
            finalFiles = finalFiles.filter(c => c.name !== item.name)            
        });
        
        let filesKO50=this.checkFiles50(finalFiles)                  
        let mensajeKO50=this.mensaje50(this.archivosKO(filesKO50),true)

        this.setState({uploadFiles:finalFiles})     

        if (filesKO50.length==0&&filesKOTam.length==0&&filesKOTamMax.length==0)
            this.onDropTempUpload(finalFiles)                         
        else{    
            if (mensajeKOTamMax!='')
                this.setState({openConfirmUploadTamMax:true,mensajeKOTamMax:mensajeKOTamMax,cancelUpload:false,idEstrUpload:0})   

            if (mensajeKOTam!='')
                this.setState({openConfirmUploadTam:true,mensajeKOTam:mensajeKOTam,cancelUpload:false,idEstrUpload:0 })                
        
            if (mensajeKO50!='')
                this.setState({openConfirmUpload50:true,mensajeKO50:mensajeKO50,cancelUpload:false,idEstrUpload:0})                    
               
        }
    }


    async onDropConfirmTam(acceptedFiles,idEstr=0,idEstrPadre=0){
        /*non se empeza a subir ata que se confirme o aviso 50, se o hay*/
        if (!this.state.openConfirmUpload50){
            if (this.state.idEstrUpload==0) 
                this.onDropTempUpload(acceptedFiles)            
            else{
                this.onDropUpload(acceptedFiles,idEstr,idEstrPadre)
                }
        }
    }
            

    async onDropConfirm50(acceptedFiles,idEstr=0,idEstrPadre=0){
        if (this.state.idEstrUpload>0)
            this.onDropUpload(acceptedFiles,idEstr,idEstrPadre)
        else
            this.onDropTempUpload(acceptedFiles)
    }

    async onDropUpload(acceptedFiles,idEstr=0,idEstrPadre=0) {
       
        let expediente = this.state.expediente.Expediente[0];
        let estructura={id:0};         
              
        
        this.setState({mustRefresh:this.checkRefreshFolder(this.state.idEstrUpload,this.state.idEstrUploadPadre)})          
               
        estructura={id: this.state.idEstrUpload};
        if (estructura) {
            await this.props.uploadFiles(acceptedFiles, true, expediente, this.state.idTrabajoActivo, estructura)
        } else {
            await this.props.uploadFiles(acceptedFiles, false, expediente, this.state.idTrabajoActivo)
        }                       
    }

    async onDropTempUpload(acceptedFiles){
        let expediente = this.state.expediente.Expediente[0];        
        await this.props.uploadFiles(acceptedFiles, false, expediente)
        if (this.props.notInFolderPlace) {
            this.props.showUploadComponent()
        }    
    }


    dragOverChildren = (permitido=false) => (event) => {
      
        event.preventDefault();
         
        if (permitido)
            {event.dataTransfer.dropEffect = "copy"}          
        else
            {event.dataTransfer.dropEffect = "none"}                             
            
        }

        dragOver = (permitido=false) => (event) => {
         
            event.preventDefault();
            if (permitido)
                {event.dataTransfer.dropEffect = "copy"}               
            else
                {event.dataTransfer.dropEffect = "none"}                        
            }
                    
        
    
        dragEnd = (estructura) => (event) => {
            event.dataTransfer.dropEffect = "default"                      
            
         }


    async throwRefreshEstadoVisualEstructura()
    {
        this.setState({refreshRequest:true,removingFiles:false});

            setTimeout(()=>{
                this.setState({refreshRequest:false})
            },100)
    }

          
    async refreshEstadoVisualEstructuraAndFiles() {
        await this.getSetEstructuraDocumentalYArchivos(this.state.expediente.Expediente[0].Id_Expediente,this.state.idTrabajoActivo,this.state.idEstructuraActiva?this.state.idEstructuraActiva:0);                
        this.throwRefreshEstadoVisualEstructura();
    }
    async refreshEstadoVisualEstructuraAfterUploadFiles() {
        /* Estamos aquí para actualizar Estado Visual de carpeta despues de añadir archivos desde el componente Folder2, 
        es decir en carpeta nivel 2 o nivel 1 con Permite_Anexar_Archivos=1 */           
        if (this.state.idEstructuraActiva || this.state.idEstrUpload!=0)
            await this.refreshEstadoVisualEstructuraAndFiles();       
    } 

    async refreshEstadoVisualEstructuraAfterAsignacionAuto()
    {       
        await this.refreshEstadoVisualEstructuraAndFiles()                          
    }

    
    async refreshEstructuraAfterChangeTrabajo(responseAfterChangeTrabajo){
        // md después de cambiar tipo modificaciones trabajo hay que actualizar info

        let expediente = this.state.expediente

        // esto para los datos del trabajo
        //let responseAfterChangeTrabajo= await this.getTrabajoActivo();       
        for (let i = 0; i < expediente.Trabajos.length; i++)
        {  if (responseAfterChangeTrabajo[0].Id_Trabajo == expediente.Trabajos[i].Id_Trabajo)
               { expediente.Trabajos[i] = responseAfterChangeTrabajo[0];  }        
           }
        this.setState({ expediente: expediente })            
                     
        await this.refreshEstadoVisualEstructuraAndFiles();      
       
        this.throwRefreshEstadoVisualEstructura();        
        
   }

   
    async refreshEstadoVisualEstructura(responseCarpetas=null) {                                       
       await this.getSetEstructuraDocumentalYArchivos(this.state.expediente.Expediente[0].Id_Expediente,  this.state.idTrabajoActivo,  -1,false,responseCarpetas);                        
       this.throwRefreshEstadoVisualEstructura();
    };    
      
   a

    trabajoActivo(){
        if(this.state.idTrabajoActivo){
            let trabajo = this.state.expediente.Trabajos.filter(item=>item.Id_Trabajo==parseInt(this.state.idTrabajoActivo));
            return trabajo[0]
        }
        return false;
    }

    disableActions = () => {

        if (!this.state.idEstructuraActiva) {

            return true
        }
        if (this.state.idTrabajoActivo) {
            let trabajoActivo = this.state.expediente.Trabajos
                ? this.state.expediente.Trabajos.find(x => x.Id_Trabajo === parseInt(this.state.idTrabajoActivo))
                : null;
            if (trabajoActivo) {
                if (trabajoActivo.Entrega_Cerrada === 1)
                    return true;
                else {
                    if (this.state.estructuraActiva !== null) {
                        let disable = this.state.estructuraActiva.Permite_Anexar_Archivos === 0;
                        return disable;
                    }
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    openConfirm = (isWork) => {
        this.setState({openConfirmWork: isWork, openConfirmExp: !isWork});
    }

    openConfirmAutoRetener = (isWork) => {
        this.setState({openConfirmWorkAutoRetener: isWork});
    }

    openConfirmGeoValidado = (isWork) => {
        this.setState({openConfirmGeoValidado: true});
    }

    

    async crearTrabajo() {

        this.props.history.push(`/crear-trabajo/${this.props.match.params.id}`);
    }

    setTrabajoActivo = (idTrabajo) => {
        /*Si cambiamos de trabajo hay que volver a avisar de posibles geotecnicos validados*/
        if (idTrabajo!=this.state.idTrabajoActivo)
            this.setState({idTrabajoActivo: idTrabajo,loadingTree: false});   
        else  
            this.setState({idTrabajoActivo: idTrabajo,loadingTree: false});   
    }
   
    async handleSaveAsign(files) {

        this.setState({savingAsign:true})             

        //let mustRefresh=(temp&&target==idEstructuraActiva)||temp==false       
        let idEstructuraActiva = 0;                
        idEstructuraActiva= this.state.idEstructuraActiva ? this.state.idEstructuraActiva:0;
        let expediente = this.state.expediente.Expediente[0];

        let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.moverOtraEstructuraMostrarEn(expediente.Id_Expediente,
        this.state.idTrabajoActivo,-1,idEstructuraActiva , files);
        if (response!=null && response != false && response!='ERROR2') 
            {  
                //importante await para que todo vaya bien
                await this.refreshEstadoVisualEstructura(response.Carpetas)                                                                    
                this.setState({ dataFolder: response.Archivos,fetchingData:false, mustRefresh:false,savingAsign:null,openAsign:false})  
                                                                                                                                                                                                                                             
            }           
            /*else{
             this.setState({fetchingData:false, mustRefresh:false})             
            } */                                          
     }

     async handleOpenAsign(open){
        this.setState({openAsign:open})        
    }

     async handleImport(importGeo,importGeoPresentando) {

        let trabajosAbiertos=[];
        if (!importGeo && !importGeoPresentando)
            trabajosAbiertos=this.state.expediente.Trabajos.filter(x=>x.SePuede_Editar==1 && x.Id_Trabajo!=this.state.idTrabajoActivo && x.Id_Tipo_Trabajo!=ID_TRABAJO_ENCOMENDA)
        else
            trabajosAbiertos=this.state.expediente.Trabajos.filter(x=>x.Fecha_Tramitacion!=null && x.Id_Trabajo<this.state.idTrabajoActivo && x.Id_Tipo_Trabajo==ID_TRABAJO_VAL_GEOTECNICO)

        let trabajos = [];       
        if (trabajosAbiertos.length>0){
        trabajosAbiertos.forEach(trabajo =>            
                {
                    trabajos.push({      
                    id:trabajo.Id_Trabajo,                  
                    name: trabajo.Id_Trabajo + ' - ' +trabajo.Titulo,                   
                    })      
        });        

        this.setState({openConfirmImportFiles:!importGeoPresentando&&!importGeo,
            openConfirmImportFilesGeo:importGeo,
            openConfirmImportFilesGeoPresentando:importGeoPresentando,
            confirmationItems:trabajos           
           })    
        }               
     }
    

     async handleSaveImport(iIdTrabajoOrigen) {

        this.setState({savingImport:true})             

        //let mustRefresh=(temp&&target==idEstructuraActiva)||temp==false        
      
        let expediente = this.state.expediente.Expediente[0];

        let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.importarArchivos(expediente.Id_Expediente,
        this.state.idTrabajoActivo,iIdTrabajoOrigen);
        if (response!=null && response != false && response!='ERROR2') 
            {  
                //importante await para que todo vaya bien
                await this.refreshEstadoVisualEstructura(response.Carpetas)                                                                    
                this.setState({ dataFolder: response.Archivos,dataFolderTemporal: response.ArchivosTemporales,fetchingData:false, mustRefresh:false ,savingImport:false,openConfirmImportFiles:false,confirmationItems:null})                                                                                                                                                                                                                                               
              
            }           
            else{
             this.setState({fetchingData:false, mustRefresh:false,savingImport:false,openConfirmImportFiles:false,confirmationItems:null,dataFolderTemporal:null})             
            }                                           
     }



    async moveItemTo(target,tamMax) {
       let item = this.state.dragging 
        
       let idEstructuraActiva = 0;                
       idEstructuraActiva= this.state.idEstructuraActiva ? this.state.idEstructuraActiva:0;

       let response= null;

       let temp=false;
       if (item.temporalFiles && item.temporalFiles.length>0)
        temp=true;

       let mustRefresh=(temp&&target==idEstructuraActiva)||temp==false       

       this.setState({ idEstructuraActivaBeforeDragging: idEstructuraActiva, fetchingData:false,tempDragging:temp,moving:true,mustRefresh:mustRefresh})    
                     
        try {
            if (temp) {                      
                //en target viene id_estructura
                response=await this.moveFilesFromTemporalToEstructureShowInOneCall(target, idEstructuraActiva,item.temporalFiles);                
            } else {                                                         
                //let response = await this.moveFilesToEstructure(target,  item.files);                          
                response = await this.moveFilesToEstructureShowIn(target, idEstructuraActiva, item.files);                                                        
            }


            if (response!=null && response != false && response!='ERROR2') 
            {  
                //importante await para que todo vaya bien
                await this.refreshEstadoVisualEstructura(response.Carpetas)                                                    
                await this.refreshFilesAfterDragAndDrop(target,response.Archivos,response.ArchivosTemporal)                                                                                                                                                                                                               
            }           
            
            this.setState({  moving:false, dragging:false,dataFolderTemporal:null,fetchingData:false, tempDragging:false,idEstructuraActivaBeforeDragging:null,mustRefresh:false})    
                       
           
        } catch (error) {
            this.props.fetchErrorExpediente("Error de comunicación con la API");          
            this.setState({ moving:false, dragging:false,dataFolderTemporal:null,fetchingData:false, tempDragging:false,idEstructuraActivaBeforeDragging:null})    
        }
    }


    /*async refreshTree() {
        if (this.state.dragging == null || this.state.dragging == false)          
        {          
            await this.handleChangeMenuOption(this.state.idTrabajoActivo, false)        
        }      
    }*/

    async moveFileTo(target, item) {
        let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.moverFicheroDeTemporal(target.Id_Expediente, target.Id_Trabajo, target.Id_Estructura,  item.Nombre)
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
            //this.props.fetchErrorExpediente(response);
            return response
        } else {
            return response
        }
    }

    async moveFileToShowIn(target, idEstructuraActiva,item) {
        let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.moverFicheroDeTemporalMostrarEn(target.Id_Expediente, target.Id_Trabajo, target.Id_Estructura, idEstructuraActiva, item.Nombre)
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
            //this.props.fetchErrorExpediente(response);
            return response
        } else {
            return response
        }
    }

    async moveFilesFromTemporalToEstructureShowInSeveralCalls(target, idEstructuraActiva,files) {
        let responseFiles = null;
        for (let i = 0; i < files.length; i++) {
            let element = files[i];
               //let response = await this.moveFileTo(target, element);
               let response = await this.moveFileToShowIn(target, idEstructuraActiva,element);
              //devuelve info del último devuelvo el contenido de toda la carpeta
              responseFiles= response;         
        }
        
        return responseFiles
    }

    async moveFilesFromTemporalToEstructureShowInOneCall(targetEstructura, idEstructuraActiva,files) {
        if (files.length === 0) {

            return true;
        }
        let dataPost = {
            Archivos: files.map(element => {
                return {Nombre: element.Nombre}
            })
        };
        let expediente = this.state.expediente.Expediente[0];

        let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.moverFicherosDeTemporalMostrarEn(expediente.Id_Expediente, this.state.idTrabajoActivo, targetEstructura, idEstructuraActiva, dataPost)
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
            // this.props.fetchErrorExpediente(response);
            return response
        } else {
            return response
        }
    }
  

    /*async moveFilesToEstructure(target, files) {
        if (files.length === 0) {

            return true;
        }
        let dataPost = {
            Archivos: files.map(element => {
                return {id_estructura: element.Id_Estructura}
            })
        };
        let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.moverOtraEstructura(target.Id_Expediente,
            target.Id_Trabajo, target.Id_Estructura, dataPost);
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
            // this.props.fetchErrorExpediente(response);
            return response
        } else {
            return response
        }
    }*/

    async moveFilesToEstructureShowIn(targetEstructura, idEstructuraActiva, files) {
        if (files.length === 0) {

            return true;
        }
        let dataPost = {
            Archivos: files.map(element => {
                return {id_estructura: element.Id_Estructura}
            })
        };
        let expediente = this.state.expediente.Expediente[0];

        let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.moverOtraEstructuraMostrarEn(expediente.Id_Expediente,
            this.state.idTrabajoActivo,targetEstructura, idEstructuraActiva, dataPost);
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
            // this.props.fetchErrorExpediente(response);
            return response
        } else {
            return response
        }
    }

    
 
 
     /*async refreshTree() {
         if (this.state.dragging == null || this.state.dragging == false)          
         {          
             await this.handleChangeMenuOption(this.state.idTrabajoActivo, false)        
         }      
     }*/
 
     async moveFileTo(target, item) {
         let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.moverFicheroDeTemporal(target.Id_Expediente, target.Id_Trabajo, target.Id_Estructura,  item.Nombre)
         if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
             //this.props.fetchErrorExpediente(response);
             return response
         } else {
             return response
         }
     }
 

    dragging(action) {       
             this.setState({dragging:action})               
    }

 
 
    expandMenuTree = (trabajo, item, estructura) => {
        this.setState({expansionOrder: {isExpand: true, data: {trabajo, item, estructura}}});    
    }


    async refreshFilesAfterDragAndDrop(target,archivos,archivosTemporal) {
        
        //refrescamos temporal con los archivos que nos vengan en archivosTemporal. Si no viene nada actualizamos el estado dataFolderTemporal        
        this.setState({dataFolderTemporal: archivosTemporal})
                
        //refrescamos folder files de nivel 1 ó 2 llamando de nuevo a la api
        //obtenemos datos 
                          
        let idEstructuraActiva = 0;                
        idEstructuraActiva= this.state.idEstructuraActiva ? this.state.idEstructuraActiva:this.state.estructurasPadre[0]["Id_Estructura_Padre"];
               
      //  if ((idEstructuraActiva>0 && archivosTemporal==null) || (target == idEstructuraActiva))
      if ((idEstructuraActiva>0  || target == idEstructuraActiva) && archivos!=null)
        { //ya vienen los datos de la vista de trabajo en la variable archivos
          let firmasDigitales=null;
          this.setState({ dataFolder: archivos , firmasDigitales })     
        }       

    }
   
   

    async refreshEstadoVisualEstructuraAfterRemoveFolder2(dataFolder)
    {    
   
       this.setState({ dataFolder:dataFolder, fetchingData:true});   

       let expediente = this.state.expediente.Expediente[0];
       await this.getSetEstructuraDocumentalYArchivos(expediente.Id_Expediente,  this.state.idTrabajoActivo);      
   
       this.setState({ fetchingData:false});  

       this.throwRefreshEstadoVisualEstructura(); 

    }

    async isNewOrDelAgent()    
    {  
       let expediente = this.state.expediente.Expediente[0];
       let r=null;
         
      // traemos info carpetas y todos archivos si estamos a nivel trabajo 
       this.getSetEstructuraDocumentalYArchivos(expediente.Id_Expediente,  this.state.idTrabajoActivo,!this.state.idEstructuraActiva ?0:-1);  
       
       this.throwRefreshEstadoVisualEstructura();
       
       //si estamos a nivel carpeta obtenemos en anterior llamada no vienen archivos y se obtienen aquí
       if (this.state.idEstructuraActiva > 0) {
            r = await this.getArchivos(this.state.idEstructuraActiva);                          
            this.setState({ dataFolder:r.Archivos, folderInfo:r.InfoCarpeta[0]});  
        }
                          
    }


    getCleanedString(cadena) {
        if (cadena == null)
          return '';      
        cadena = cadena.toLowerCase();
       
        return cadena;
      }

    estadoTrabajo = (nombre,trabajo) => {
        let nombreLimpio = this.getCleanedString(nombre);
        if (nombreLimpio !== '')
            return (
                <span style={{verticalAlign: "middle"}}>
                    {<img style={{verticalAlign: "-1px"}} src={this.iconUrl(nombreLimpio)} />  }                  
                    {' '+trabajo}                             
                </span>);
    }

  iconUrl = (nombreLimpio) => {
        try {
            return require(`../Tramitaciones/IconosEstados/${nombreLimpio}.svg`);
        } catch (e) {
            let nombreLimpio='borrador'
            return require(`../Tramitaciones/IconosEstados/${nombreLimpio}.svg`);
        }
    }

   

    renderNavBar(isNavBarWork) {
        let {classes} = this.props;
        //let disableActions = this.disableActions();
        let trabajoActual = this.state.expediente ? this.state.expediente.Trabajos.find(t => t.Id_Trabajo == this.state.idTrabajoActivo) : {};
        trabajoActual = trabajoActual ? trabajoActual : {};      
        let isMobile_=isMobile();

        return (
            <div className={!isMobile_?classes.root:classes.rootMobile}>
                <AppBar position="static" className={`${classes.mainNav} nav-expedient`} color="default">
                    {this.state.optionsComponent === 1
                        ? <Toolbar className="tool">
                             <Tooltip title={<Translate id="languages.trabajo.helperTextNuevoTrabajo" />} >
                            <Button disabled={false}
                                    variant="contained" color="primary" className={classes.button2}
                                    onClick={() => this.crearTrabajo()}>
                                <Translate id="languages.generalButton.newWork"/>
                                <Add className={classes.rightIcon}/>
                            </Button>
                            </Tooltip>

                            {!isMobile_ ?
                            <Button color="primary" className={classes.button}
                                    onClick={() => this.openConfirm(false)}>
                                <Translate id="languages.generalButton.delete"/><Delete/>
                              </Button>    :

                                <Tooltip title={'Eliminar'}>
                                <IconButton  className={classes.buttonEdit} color="primary" aria-label="Delete" 
                                onClick={() => this.openConfirm(false)}>
                                <Delete/>
                                </IconButton>

                                
                                </Tooltip> }
                            
                           
                        </Toolbar>
                        : //this.state.optionsComponent === 2
                          //  ?
                          (isNavBarWork?
                                                         
                          <Toolbar className="tool">
                             
                                 <Tooltip title={<Translate id="languages.trabajo.helperTextNuevoTrabajo" />} >
                                <Button disabled={false}
                                    variant="contained" color="primary" className={classes.button2}
                                    onClick={() => this.crearTrabajo()}>
                                <Translate id="languages.generalButton.newWork"/>
                                <Add className={classes.rightIcon}/>
                            </Button>
                            </Tooltip>

                            {!isMobile_ ?
                            (this.state.presentWork ? <CircularProgress className="align-self-center" size={24}/>:
                            <Button disabled={this.state.presentWork || trabajoActual.SePuede_Entregar === 0}
                                        color="primary" className={classes.button}
                                        onClick={this.avisaryPresentarTrabajo}>
                                    <Translate id="languages.generalButton.present"/><Check/>
                                </Button>  ) 
                                : 

                            <Tooltip title={'Presentar'}>
                            <IconButton  disabled={this.state.presentWork || trabajoActual.SePuede_Entregar === 0} 
                            aria-label="View" color="primary" className={classes.buttonEdit} 
                            onClick={this.avisaryPresentarTrabajo}>
                            <Input/>                            
                            </IconButton>
                            </Tooltip> 
                               }


                            { /*md por el momento quitamos la urgencia del menú principal*/
                                !isMobile_ && trabajoActual.SePuede_SolicitarModificarEntrega === 1?
                                (this.state.fetchingAutoRetener ? <CircularProgress className="align-self-center" size={24}/>:
                                    <Button color="primary"  className={classes.button} onClick={()=>this.autoRetener()}>
                                        <Translate id="languages.generalButton.autoRetener"/><CancelOutlined/>
                                    </Button>) 
                                : null
                                }

                            { /*md por el momento quitamos la urgencia del menú principal*/
                                !isMobile_ && trabajoActual.SePuede_SolicitarUrgencia === 1?
                                (this.state.fetchingUrgencia ? <CircularProgress className="align-self-center" size={24}/>:
                                                               
                                 trabajoActual.Tramitacion_Urgente!=1 ?
                                    <Button  color="primary" onClick={()=>this.solicitarUrgencia(trabajoActual)}>
                                        <Translate id="languages.generalButton.urgent"/>
                                        <NotificationsNoneOutlined/>
                                    </Button>:
                                    <Button  className={classes.buttonActionUrgencia} onClick={()=>this.solicitarUrgencia(trabajoActual)}>
                                    <Translate id="languages.generalButton.urgent"/>
                                    <Notifications/>
                                </Button>
                                   ) 
                                : null
                                }


                              
                            {!isMobile_ && trabajoActual.SePuede_Retirar === 1? 
                            (this.state.fetchingDownload ? <CircularProgress className="align-self-center" size={24}/>:    
                            <Button color="primary" className={classes.button}
                                    onClick={() => this.exportarADisco(true)}>
                                <Translate id="languages.generalButton.download"/> <CloudDownload className="ml-1"/>
                              </Button> )   :

                                trabajoActual.SePuede_Retirar === 1 && <Tooltip title={'Descargar'}>
                               <IconButton  className={classes.buttonEdit} color="primary" aria-label="Descargar" 
                                onClick={() => this.exportarADisco(true)}>
                                <CloudDownload className="ml-1"/>
                                </IconButton>
                                    </Tooltip> }
                                                                                    
                              
                              {!isMobile_ ?
                            <Button color="primary" className={classes.button}
                                    onClick={() => this.openConfirm(true)}
                                    disabled={trabajoActual.SePuede_Eliminar === 0}>
                                <Translate id="languages.generalButton.delete"/> <Delete/>
                              </Button>                                                            
                              :
                                <Tooltip title={'Eliminar'}>
                               <IconButton  className={classes.buttonEdit} color="primary" aria-label="Delete" 
                                disabled={trabajoActual.SePuede_Eliminar === 0}
                                onClick={() => this.openConfirm(true)}>
                                <Delete/>
                                </IconButton>
                                </Tooltip> }
                                                                                 
                               {/*Nota: este siguiente elemento está repetido aquí para que se vea en Safari Mac este botón de más opciones,ya que no 
                                está en un Breadcrumbsitem,sino en como un control más en el "cuerpo", donde va el botón y su evento  para que se vea el menú desplegable
                                además también nos vale para mobile*/}                               
                              <Menu className={!isMobile_?classes.iconMasOpcionesSafari:classes.iconMasOpcionesSafariMobile}/>              
                              {/*color: '#fff'*/}
                               
                                                                                 
                                                                                               
                            </Toolbar>
                            /*: 
                            <Toolbar className="tool">
                                           <Button disabled={false}//this.state.expediente.Expediente[0].SePuede_CrearTrabajo != 1}
                                    variant="contained" color="primary" className={classes.button2}
                                    onClick={() => this.crearTrabajo()}>
                                <Translate id="languages.generalButton.newWork"/>
                                <Add className={classes.rightIcon}/>
                            </Button>

                            {!isMobile_ ?
                            (this.state.presentWork ? <CircularProgress className="align-self-center" size={24}/>:
                            <Button disabled={this.state.presentWork || trabajoActual.SePuede_Entregar === 0}
                                        color="primary" className={classes.button}
                                        onClick={this.presentTrabajo}>
                                    <Translate id="languages.generalButton.present"/><Check/>
                                </Button>  ) 
                                : 

                            <Tooltip title={'Presentar'}>
                            <IconButton  disabled={this.state.presentWork || trabajoActual.SePuede_Entregar === 0} 
                            aria-label="View" color="primary" className={classes.buttonEdit} 
                            onClick={this.presentTrabajo}>
                            <Input/>                            
                            </IconButton>
                            </Tooltip> 
                               }

                                                                                          
                              
                              {!isMobile_ ?

                            <Button color="primary" className={classes.button}
                               disabled={trabajoActual.SePuede_Eliminar === 0}
                                    onClick={() => this.openConfirm(true)}>
                                <Translate id="languages.generalButton.delete"/> <Delete/>
                              </Button>    :

                                <Tooltip title={'Eliminar'}>
                                <IconButton  className={classes.buttonEdit} color="primary" aria-label="Delete" 
                                disabled={trabajoActual.SePuede_Eliminar === 0}
                                onClick={() => this.openConfirm(true)}>
                                <Delete/>
                                </IconButton>
                                </Tooltip> }                                
                            </Toolbar>*/
                            :null)}
                </AppBar>
            </div>
        );
    }

   

    renderLeftNav() {
        let {classes} = this.props;

        let isMobile_=isMobile()
        return (
            <List component="nav" className={classes.leftNav}
                  subheader={<ListSubheader component="div"
                                            className={`${classes.headerNavTituloExpediente} py-2 ${this.state.idTrabajoActivo === null ? classes.fichaExpediente : ''}`}
                                            onClick={() => this.handleExpediente()}
                                            onDragEnd={this.dragEnd()}
                                            onDragOver={this.dragOver(false)}>
                      {/*`${this.state.currentExpediente.Expediente_Codigo_Estudio} ${this.state.currentExpediente.Titulo}`*/}
                     {!isMobile_ ? `${this.state.currentExpediente.Titulo.substring(0,40) + '...'}` : `${(this.state.currentExpediente.Expediente_Codigo != null ? this.state.currentExpediente.Expediente_Codigo : this.state.currentExpediente.Expediente_Codigo_Estudio)  + '-'+ this.state.currentExpediente.Titulo.substring(0,40) + '...'}`}
                  </ListSubheader>}
                    
                     >
                <ListItem button onClick={() => {
                    this.handleExpandMenu()                  

                }} className="pl-3 pr-3"
             
                >
                    <ListItemText inset primary={<Translate id="languages.fichaExpediente.titleEstructDoc"/>}
                                  className="pl-0"
                                  onDragEnd={this.dragEnd()}
                                  onDragOver={this.dragOver(false)} />
                    {this.state.open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Divider/>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {this.state.expediente.Trabajos.map((trabajo, index) => {
                            return <MenuOption key={`menu_item_${index}`}
                                               ref={instance => {
                                                   this.menuLateral = instance;
                                               }}
                                               changeOption={(idTrabajo,loadingTree) => {
                                                   this.switcToolbar(2);
                                                   this.handleChangeMenuOption(idTrabajo,true,trabajo.Id_Tipo_Trabajo,loadingTree);
                                               }}
                                               refreshRequest={this.state.refreshRequest && trabajo.Id_Trabajo == this.state.idTrabajoActivo} /* solo hay que actualizar trabajo actual */

                                               //defaultEstructura={this.state.titleEstructura}
                                               changeEstructura={(idEstructura, titleEstructura, estructura) => {                                                  
                                                   this.handleChangeEstructuran(idEstructura, titleEstructura, estructura);
                                                   this.switcToolbar(3);                                                  
                                               }}

                                               expediente={this.state.expediente}
                                               trabajo={trabajo}

                                               dragItems={this.state.dragging?this.state.dragging:false}                                            
                                               dragginOverChildren={this.state.draggingOverChildren} 
                                               moveItemTo={(target) => this.moveItemTo(target)}
                                               onDrop={(acceptedFiles,idEstr,idEstPadre,tanMax) => this.onDropEstructura(acceptedFiles,idEstr,idEstPadre,tanMax)}    
                                               
                                               tempDragging={this.state.tempDragging}     
                                              
                                               estructuraDocumental={this.state.estructuraDocumental}
                                               estructurasPadre={this.state.estructurasPadre}
                                               idEstructuraActiva={this.state.idEstructuraActiva}
                                               idEstructuraActivaPadre={this.state.idEstructuraActivaPadre}
                                               //isLoadEstructura={this.state.isLoadEstructura}
                                               active={this.state.active == trabajo.Id_Trabajo}
                                               activeTrabajo={this.state.idTrabajoActivo}
                                               
                                               getSetEstructuraDocumentalYArchivos={(idExpediente, idTrabajo, idEstructuraMostrarArchivos,loadingTree,responseCarpetas) => this.getSetEstructuraDocumentalYArchivos(idExpediente, idTrabajo, idEstructuraMostrarArchivos,loadingTree,responseCarpetas)}                                               
                                               recargaNavBarYListaTrabajos={()=>this.recargaNavBarYListaTrabajos()}

                                               setTrabajoActivo={(idTrabajo) => this.setTrabajoActivo(idTrabajo)}
                                               setWorkSpaceToTrabajoEjecucion={(idTrabajo) => this.setWorkSpaceToTrabajoEjecucion(idTrabajo)}
                                               expansionRequest={this.state.expansionOrder} 
                                               resetExpansionRequest={() => this.expandMenuTree(null, null, null)}
                                               idParamEstructura={this.props.match.params.idEstructura}   
                                                                                       
                                               //modifiedWork={this.state.modifiedWork}    
                                               removingFiles={this.state.removingFiles}     
                                               estructuraUpload={this.state.idEstrUpload}   
                                                                                    
                                                                                                                                                                                                                                                                                                                                                                   
                                                                                              
                            />
                        })}
                    </List>
                </Collapse>
            </List>
        );
    }

    


    render() {
        let {classes} = this.props;
        let {expediente} = this.state;
        let trabajoActual = this.state.expediente ? this.state.expediente.Trabajos.find(t => t.Id_Trabajo == this.state.idTrabajoActivo) : null; /*Por favor no cambiar los == asi está bien*/
        let disableActions = this.disableActions();           
        let isMobile_=isMobile();

        return (
            this.state.expediente
                ? <Grid container  >
                    <Grid md={12} xs={12}  zeroMinWidth>
                        {this.detalles()}
                        

                      
                        {!isMobile_ ?                        
                      
                            <BreadcrumbsItem key={1} to={'/visualizar-expediente/' + this.state.currentExpediente.Id_Expediente}
                                         className="title-breadcrumb"
                                         onDragEnd={this.dragEnd()}
                                         onDragOver={this.dragOver(false)} >
                            <Tooltip
                               title={(this.state.currentExpediente.Titulo  ?  this.state.idTrabajoActivo ? this.state.currentExpediente.Titulo :'' : this.state.idTrabajoActivo ? this.state.currentExpediente.Expediente_Codigo_Estudio: '') + (/*this.state.renderComponent === "TrabajoComunicacion" ||*/ this.state.renderComponent === "ExpedienteGeneral" && this.state.idTrabajoActivo ? ` ${this.state.currentExpediente.Titulo}` : "")}
                                placement="top-start"
                                classes={{tooltip: classes.noMaxWidth}}>
                                <div style={{fontSize: 20}}
                                  className="title-breadcrumb">{(this.state.currentExpediente.Expediente_Codigo ? this.state.currentExpediente.Expediente_Codigo : this.state.currentExpediente.Expediente_Codigo_Estudio) + (/*this.state.renderComponent === "TrabajoComunicacion" ||*/ this.state.renderComponent === "ExpedienteGeneral" ? ' - '+` ${this.state.currentExpediente.Titulo}` : "")}</div>
                            </Tooltip>

                            {this.renderNavBar(false)}
                            
                        </BreadcrumbsItem>
                  
                        :
                       
                        <BreadcrumbsItem key={1} to={'/visualizar-expediente/' + this.state.currentExpediente.Id_Expediente}
                        className="title-breadcrumb-movile">
                      
                                <div style={{fontSize: 20}} className="title-breadcrumb-movile">{' '}</div>
                                {this.renderNavBar(false)}
                                    </BreadcrumbsItem>
                             }                                                    
                       
                        {                            
                             
                            !isMobile_ && this.state.idTrabajoActivo  && //&&  this.state.renderComponent !== "TrabajoComunicacion")
                                <BreadcrumbsItem key={2}
                                                   to={'/visualizar-expediente/' + this.state.currentExpediente.Id_Expediente + "/" + this.state.idTrabajoActivo}
                                                   className="title-breadcrumb"
                                                   onDragEnd={this.dragEnd()}
                                                   onDragOver={this.dragOver(false)} >
                                                        {this.estadoTrabajo( trabajoActual ? trabajoActual.Estado:'',trabajoActual ? trabajoActual.Id_Trabajo + ' - '+ trabajoActual.Titulo: "") }                                  

                                                        {this.renderNavBar(true)}

                                </BreadcrumbsItem>}

                        {   isMobile_ && this.state.idTrabajoActivo   
                            && <BreadcrumbsItem key={2}
                                               to={'/visualizar-expediente/' + this.state.currentExpediente.Id_Expediente} //+ "/" + this.state.idTrabajoActivo}
                                               className="title-breadcrumb-movile">
                                               <div style={{fontSize: 20}} className="title-breadcrumb-movile">{' '}</div>
                                                {this.renderNavBar(true)}
                                    </BreadcrumbsItem>
                                
                        }


                        {/*!isMobile_ && this.state.estructuraActiva && this.state.estructuraActiva.Nivel_Documentacion === 2
                            ? <BreadcrumbsItem
                                to={'/visualizar-expediente/' + this.state.currentExpediente.Id_Expediente + "/" + this.state.idTrabajoActivo + "/" + this.state.estructuraActiva.Id_Estructura_Padre}
                                key={3}
                                className="title-breadcrumb">
                                {this.state.estructuraActiva.Titulo_Padre}
                        </BreadcrumbsItem> : "" */}

                        {/*!isMobile_ && (this.state.titleEstructuraActiva ) //&& this.state.renderComponent !== "TrabajoComunicacion")
                            ? <BreadcrumbsItem className={'title-breadcrumb-modified'} key={4}
                                               to={'/visualizar-expediente/' + this.state.currentExpediente.Id_Expediente + "/" + this.state.idTrabajoActivo + "/" + this.state.estructuraActiva.Id_Estructura_Padre + "/" + this.state.Id_Estructura}>
                                {this.state.titleEstructuraActiva}
                            </BreadcrumbsItem>
                    : ""*/}
                   
                   {(isMobile_ && this.state.optionsComponent >1 ) && this.renderIconMasOpciones(isMobile_,classes,trabajoActual)}   

                    </Grid>
                    {/*<Grid md={8} xs={12}  onDragEnd={this.dragEnd()}
                                         onDragOver={this.dragOver(false)}>                        
                       
                </Grid>*/}
                    {/*md ampliamos marco un poco en moviles
                    <Grid item md={3} xs={12} style={{height: window.innerHeight * .8, overflow: "hidden", maxWidth: "22%"}}*/}
                    
                    
                                  
                             

                    {isMobile_ ?  

                    <div  className={classes.panelLateralEstructuraMobile} >
                    
                    <Grid item md={3} xs={12} style={{height: window.innerHeight * .81, overflow: "hidden"}}
                          className={classes.boredrRight}
                          >
                        <div style={{
                            width: '103%',
                            height: window.innerHeight * .81 + 12, //10,
                            overflowX: 'hidden',
                            overflow: 'scroll'
                        }}>
                            {this.renderLeftNav()}
                           
                        </div>

                      </Grid>

                      </div> 
                    
                    :  
                   
                   // solo mostramos arbol si
                   (this.state.optionsComponent >1) &&

                   <div  id="panel-lateral-estructura"  className={classes.panelLateralEstructura} >
                 
                   <Grid item md={3} xs={12}  style={{  maxWidth: "100%"  }} 
                          //className={classes.boredrRight}
                          > {/*preBle  style={{height: window.innerHeight * .81, overflow: "hidden", maxWidth: "22%",minWidth: "200px"}} */}
                        <div className={classes.panelLateralEstructuraIn} 
                            //style={{
                                ////width: '107%',
                                ////flex: 1,
                                ////flexDirection: 'row',
                                //height: '100%' //window.innerHeight * 0.81 + 12, //10,
                                //overflowX: 'hidden',
                                //overflow: 'scroll'
                                //}}
                            >
                            { this.renderLeftNav()}
                           
                        </div>
                        

                    </Grid>
                    </div>
                    }               
                 
                    {isMobile_ &&
                    <Grid item xs={12} className={"p-1 "}>
                        <p></p>
                    </Grid>
                    }

                    {(!isMobile_ && this.state.optionsComponent >1 ) && this.renderIconMasOpciones(isMobile_,classes,trabajoActual)}                                                                     

                  
                    <div  className={!isMobile_ && this.state.renderComponent!="ExpedienteGeneral"?classes.panelCentralConEstructura:classes.panelCentralConEstructuraMobile} >
                              
                    <Grid item md={(this.state.optionsComponent >1) ? 9 : 12 } xs={12} className={classes.backgroundGrey}
                          style={{padding: !isMobile_?"0 15px":"0 1px", minWidth:!isMobile_?"100%":"78%"}}  onDragOver={this.dragOver(false)}>

                        {this.state.renderComponent === "TrabajoComunicacion"
                            ? <TrabajoComunicacion expediente={expediente}
                                key={this.state.idTrabajoActivo} 
                                trabajo={this.state.idTrabajoActivo}
                                
                                />
                            : (this.state.renderComponent === "ExpedienteGeneral"
                                ? <ExpedienteGeneral expediente={expediente}
                                                     dragging={(state) => this.dragging(state)}
                                                     changeEstructura={(idTrabajo,idTipoTrabajo) => {
                                                         this.handleChangeMenuOption(idTrabajo,true,idTipoTrabajo);
                                                         this.switcToolbar(2);
                                                     }}

                                                     updateExpediente={(expediente) => this.updateExpediente(expediente)}
                                                     
                                                    />
                                : <TrabajoEjecucion 
                                    key={this.state.idTrabajoActivo} /* + (this.state.idEstructuraActiva ? this.state.idEstructuraActiva : "")}*/
                                    expediente={expediente}
                                    trabajo={this.state.idTrabajoActivo}
                                  
                                
                                    changeEstructura={(idEstructura, titleEstructura) => {                                        
                                        this.handleChangeEstructuran(idEstructura, titleEstructura);
                                        this.switcToolbar(3);
                                    }}
                                   
                                    titleEstructuraActiva={this.state.titleEstructuraActiva}
                                    
                                    estructura={this.state.idEstructuraActiva ? {id: this.state.idEstructuraActiva} : false}                                                

                                    disableActions={disableActions}
                                    expandMenuTree={(trabajo, item, estructura) => this.expandMenuTree(trabajo, item, estructura)}                                   
                                   
                                    refreshEstructuraAfterChangeTrabajo={(dataTrabajo) => this.refreshEstructuraAfterChangeTrabajo(dataTrabajo)}
                                    refreshEstadoVisualEstructuraAfterRemoveFolder2={(dataFolder) => this.refreshEstadoVisualEstructuraAfterRemoveFolder2(dataFolder)}
                                    
                                    refreshEstadoVisualEstructuraAfterAsignacionAuto={() => this.refreshEstadoVisualEstructuraAfterAsignacionAuto()}
                                    
                                    onDrop={(acceptedFiles) => this.onDropFolder(acceptedFiles)}       
                                    onDropTemp={(acceptedFiles) => this.onDropTemp(acceptedFiles)}                               
                                    dragOver={(permitido) => this.dragOver(permitido)}                                   
                                    dragEnd={() => this.dragEnd()}                                   
                                    dataFolder = {this.state.dataFolder}                       
                                    dataFolderTemporal = {this.state.dataFolderTemporal} 
                                    folders={this.state.folders}

                                    idEstructuraActivaBeforeDragging = {this.state.idEstructuraActivaBeforeDragging}  

                                    dragging={(state) => this.dragging(state)}                                   
                                    itemsDragging={this.state.dragging}
                                    moveItemTo={(target) => this.moveItemTo(target)}

                                    handleSaveAsign={(files) => this.handleSaveAsign(files)}
                                    moving={this.state.moving}        
                                    savingAsign={this.state.savingAsign}
                                    openAsign={this.state.openAsign}
                                    handleOpenAsign={(open) => this.handleOpenAsign(open)}
                                    
                                    handleSaveImport={(idTrabajoOr)=>this.handleSaveImport(idTrabajoOr)}
                                    handleImport={(geo)=>this.handleImport(geo)}
                                    savingImport={this.state.savingImport}
                                   
                                   
                                    

                                    //estructuraNivel={this.state.estructuraNivel}
                                    estructuraPermiteArchivos={this.state.estructuraPermiteArchivos}
                                    estructuraEditable={this.state.estructuraEditable}
                                    estructuraIdDocumentacion={this.state.estructuraIdDocumentacion}
                                   
                                    folderInfo={this.state.folderInfo}
                                    fetchingData={this.state.fetchingData}
                                    //isModified={(item) => { this.isModified(item) }}                                           
                                    tempDragging={this.state.tempDragging} 
                                  
                                    isNewOrDelAgent=  {() => this.isNewOrDelAgent()}                                            
                                    //changedTrabajo={this.state.changedTrabajo}                                        
                                    tieneArchivos={this.state.tieneArchivos}
                                    mustRefresh={this.state.mustRefresh}  
                                
                                                                                                                                                                                  
                                />)
                        }
                    </Grid>

                    </div>

                    {/* descomentar para meter aviso 
                    this.state.idTrabajoActivo>0 && this.renderAclaraciones()*/}

                    <Grid item xs={12}>
                        {this.renderConfirmDelExpWork()}
                        {this.renderAcceptUploadTamMax()}    
                        {this.renderAcceptUploadTam()}    
                        {this.renderConfirmUpload50()}    
                        {this.renderConfirmImportFiles(trabajoActual?trabajoActual.Id_Trabajo + ' - '+ trabajoActual.Titulo:this.state.idTrabajoActivo)}  
                        {this.renderConfirmImportFilesGeo(trabajoActual?trabajoActual.Id_Trabajo + ' - '+ trabajoActual.Titulo:this.state.idTrabajoActivo)}  
                        {this.renderConfirmImportFilesGeoPresentando(trabajoActual?trabajoActual.Id_Trabajo + ' - '+ trabajoActual.Titulo:this.state.idTrabajoActivo)}  
                    </Grid>
                    
                </Grid>
                : <div className="text-center my-5">
                    <CircularProgress/>
                </div>
        )
    }

    renderConfirmDelExpWork() {
        return (this.state.openConfirmWork ?
                    <Confirmation openConfirmation={this.state.openConfirmWork}                                
                                confirmationMessage={<Translate id="languages.messages.workConfirmation"/> }
                                acceptCancel={false}
                                aceptConfirmation={async () => {
                                    await this.deleteTrabajoExpediente();
                                    this.setState({openConfirmWork: false})
                                }}
                                declineConfirmation={() => this.setState({openConfirmWork: false})}/>
                    
                    : this.state.openConfirmExp
                    ? <Confirmation openConfirmation={this.state.openConfirmExp}
                                    confirmationMessage={<Translate id="languages.messages.expedientConfirmation"/>}
                                    acceptCancel={false}
                                    aceptConfirmation={async () => {
                                        await this.deleteExpedient();
                                        this.setState({openConfirmExp: false});
                                    }}
                                    declineConfirmation={() => this.setState({openConfirmExp: false})}/>
                    : <div></div>
        );
    }

    renderAcceptUploadTamMax(){  
        return ( this.state.openConfirmUploadTamMax ?
            <Confirmation openConfirmation={this.state.openConfirmUploadTamMax}
                    title={<Translate id="languages.generalText.observaciones"/> }
                    onlyAccept={true} /*solo se avisa en el temporal*/
                    confirmationMessage={this.state.mensajeKOTamMax}
                    acceptCancel={false}
                    aceptConfirmation={async () => {                                                    
                        this.setState({openConfirmUploadTamMax: false}) 
                    }}
                    />
                    :null
        )
        }    

    renderAcceptUploadTam(){  
        return ( this.state.openConfirmUploadTam ?
            <Confirmation openConfirmation={this.state.openConfirmUploadTam && !this.state.openConfirmUploadTamMax}
                    title={<Translate id="languages.generalText.observaciones"/> }
                    onlyAccept={this.state.idEstrUpload && this.state.idEstrUpload>0?true:false} /*en carpeta avisamos, en temporal preguntamos*/
                    confirmationMessage={ this.state.mensajeKOTam }
                    aceptConfirmation={async () => {                            
                        this.onDropConfirmTam(this.state.uploadFiles,this.state.idEstrUpload,this.state.idEstrUploadPadre)
                        this.setState({openConfirmUploadTam: false}) 
                    }}
                    acceptCancel={false}
                    declineConfirmation={() => this.setState({openConfirmUploadTam: false,cancelUpload:true  /*solo se declina en temporal*/})}/>
                    :null
        )
        }    

    renderConfirmUpload50(){  
        return ( this.state.openConfirmUpload50 && !this.state.openConfirmUploadTam && !this.state.cancelUpload ?
            <Confirmation openConfirmation={this.state.openConfirmUpload50}
                    title={<Translate id="languages.generalText.observaciones"/> }
                    confirmationMessage={ this.state.mensajeKO50 } /*en carpeta y en temporal preguntamos*/
                    aceptConfirmation={async () => {
                        this.onDropConfirm50(this.state.uploadFiles,this.state.idEstrUpload,this.state.idEstrUploadPadre)
                        this.setState({openConfirmUpload50: false})
                    }}
                    acceptCancel={false}
                    declineConfirmation={() => this.setState({openConfirmUpload50: false})}/>
                    :null
        )
        }

    renderAclaraciones(){

            let trNoEncNoDe = this.state.expediente.Trabajos.filter(function (tr) {return tr.Id_Tipo_Trabajo!=ID_TRABAJO_ENCOMENDA && tr.Necesita_De==0});      
            let trConDe = this.state.expediente.Trabajos.filter(function (tr) {return (tr.Necesita_De==1)});
            let idActivo=this.state.idTrabajoActivo;
            let trActivoConDe=trConDe.filter(function (tr) {return (tr.Id_Trabajo==idActivo)});
            let trActivoNoEncNoDe=trNoEncNoDe.filter(function (tr) {return (tr.Id_Trabajo==idActivo)});
                                 
            if (!this.state.acl3Show &&  trActivoNoEncNoDe.length>0)      
            {     let data3 = {"Id_Aclaracion":3 }             
                let response = ApiCoag.usuario.postUsuarioAclaracion(data3)          
                if (response && response != 'ERROR2') 
                 { this.setState({acl3Show:true}) }  
            }
    
            if ( !this.state.acl4Show && trActivoConDe.length>0)      
            {     let data4 = { "Id_Aclaracion":4 }             
                let response =  ApiCoag.usuario.postUsuarioAclaracion(data4)          
                if (response && response != 'ERROR2') 
                 { this.setState({acl4Show:true}) }  
            }     
        }


        renderConfirmImportFiles(trabajo){  
            return ( 
                <Confirmation openConfirmation={this.state.openConfirmImportFiles}
                        title={'Importación en '+trabajo }
                        confirmationMessage={<Translate id="languages.fileUpload.infoImportar"/>} 
                        confirmationItems={this.state.confirmationItems}
                        aceptConfirmation={async (id) => {
                            this.setState({openConfirmImportFiles: false})
                            this.handleSaveImport(id)                           
                        }}
                        acceptCancel={true}
                        declineConfirmation={() => this.setState({openConfirmImportFiles: false})}/>                       
            )
            }

            renderConfirmImportFilesGeo(trabajo){  
                return ( 
                    <Confirmation openConfirmation={this.state.openConfirmImportFilesGeo}
                            title={'Importación en '+trabajo }
                            confirmationMessage={<Translate id="languages.fileUpload.infoImportarGeo"/> } 
                            confirmationItems={this.state.confirmationItems}
                            aceptConfirmation={async (id) => {
                                this.setState({openConfirmImportFilesGeo: false})
                                this.handleSaveImport(id)                           
                            }}
                            acceptCancel={true}
                            declineConfirmation={() => this.setState({openConfirmImportFilesGeo: false})}/>                       
                )
                }

                renderConfirmImportFilesGeoPresentando(trabajo){  
                    return ( 
                        <Confirmation openConfirmation={this.state.openConfirmImportFilesGeoPresentando}
                                title={'Importación en '+trabajo }
                                confirmationMessage={<Translate id="languages.fileUpload.infoImportarGeoPresentando"/> } 
                                confirmationItems={this.state.confirmationItems}
                                aceptConfirmation={async (id) => {
                                    this.setState({openConfirmImportFilesGeoPresentando: false})
                                    this.handleSaveImport(id)                           
                                }}
                                acceptCancel={true}
                                botonCancelarTexto='Continuar'
                                botonConfirmarTexto='Importar'
                                declineConfirmation={async () => {this.setState({openConfirmImportFilesGeoPresentando: false});
                                                           this.presentarTrabajo() }}/>
                    )
                    }   


            

}



VisualizarExpediente.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(VisualizarExpediente))));