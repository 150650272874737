import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, TextField, FormControl, Button, CircularProgress, Snackbar, IconButton,Tooltip, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,FormControlLabel,Checkbox,Select,MenuItem} from '@material-ui/core';
import { Check, Clear, Close,Info } from '@material-ui/icons'
import { Container } from "reactstrap";
import { validateAddress, saveAdressTostore, updateAddress, fetchErrorExpediente } from '../../actions/expedientes';
import { connect } from 'react-redux';
import CatastralTable from "./CatastralTable";
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import AddressValidate from "../Address";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { some, findIndex } from 'lodash';
import ApiCoag from "../../api/ApiCoag";
import {isMobile} from "../../api/index";
import ReactQuill from "react-quill";
import {ID_PAIS_OTRO,ID_REG_OTRA,ID_PROV_OTRA,ID_CONCELLO_OTRO,ID_TIPO_RELACION_SUBS,ID_TIPO_RELACION_CF} from "../../api/config";
import ChecksSubstituciones  from "./ChecksSubstituciones"
import Confirmation from "../Errors/confirmation";

const styles = theme => ({
    paper: {
        padding: 24,
        width: "100%"
    },
    margin: {
        marginTop: 30
    },
    formControl1: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        width: "60%"
    },
    formControl2: {
        width: 150, marginRight: 16
    },
    formControl: {
        width: "100%"
    },
    textFieldInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
    divider: {
        [theme.breakpoints.up('sm')]: {
            borderRight: "1px solid #ced4da"
        },

    },
    
    button: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    close: {
        padding: theme.spacing.unit / 2,
    },
});

const mapStateToProps = (state) => (
    {
        addressData: state.expedientes.address ? state.expedientes.address : '',
        catastro: state.expedientes.addressreducida ? state.expedientes.addressreducida : [], /*Contiene arreglo de la tabla de ubicaciones */
        error: state.expedientes.error && state.expedientes.error.MensajesProcesado ? state.expedientes.error.MensajesProcesado : [],
    }
);

const mapDispatchToProps =
{
    validateAddress: validateAddress,
    saveAdressTostore: saveAdressTostore,
    updateAddress: updateAddress,
    fetchErrorExpediente: fetchErrorExpediente

};

const locationEmpty= {   
    "Calle": "-",
    "Numero": "",
    "Piso": "",
    "Codigo_Postal": "", 
    "Id_Concello": ID_CONCELLO_OTRO,
    "Id_Provincia":  ID_PROV_OTRA,
    "Id_Autonomia": ID_REG_OTRA,
    "Id_Pais": ID_PAIS_OTRO
  }

  


class AddExpedient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '', codeStudy: '', title: '', antecedent: '', observations: '',
            location: null,
            alias: '',
            catastro: [],
            linksMaps: [],
            emplazamientos: [],
            isValidate: false,
            isShowAddress: false,
            alert: false,
            msg: "",
            observationsLength: 0,
            isSave: false,
            validate: false,
            allowAdd: false,
            key: 0,
            sinEmp:false,
            substitucion:false,
            voluntaria:false,
            comunicada:false,
            declaracion:false,
            tipoRelacion:-1
        };

    };

    async componentDidMount() {      
          await this.getAclaEmp();      
          
          let response = await ApiCoag.usuario.getUsuario();      
          if (response && response != 'ERROR2')  {               
                this.setState({                                        
                    permiteAltaExpSinEmplazamiento:response.DatosConfiguracionesUsuario[0].Permitir_Alta_Exp_Sin_Emplazamiento
                });    
               }     
      }

    handleChange = name => event => {
        const valor=event.target.value.trimStart();
        if (name!='sinEmp' && name!='declaracion'){
        this.setState({
            [name]: valor
        });

        if (name=='antecedent' && valor=='')
            this.setState({tipoRelacion: -1});
        }
        else
          { this.setState({[name]: event.target.checked});
          if (name=='sinEmp'){
            this.setlocationEmpty();  
            this.setState({allowAdd:false})            
            if (this.state.emplazamientos.length == 0 || !this.state.sinEmp)
                this.setState({declaracion:false})      
          }
         }
       
    };

    handleChangeTipo = name => event => {   
       if (event.target.value==ID_TIPO_RELACION_SUBS )
        this.setState({[name]: event.target.value,substitucion: true,openModal:true});
       else
        this.setState({[name]: event.target.value});            

    };

    handleModalClose = () => {           
        this.setState({openModal:false});          
      };


     handleModalCloseCanceled = () => {
        this.setState({substitucion: false,tipoRelacion:-1});          
        this.handleModalClose();
      };


    handleCheckboxChange = (newState) => {
        //this.setState(newState);
        this.setState({substitucion: newState.checkSubstitucion,comunicada: newState.checkComunicada,voluntaria: newState.checkVoluntaria});
    }


    async setlocationEmpty() {
        let locations = [];
        locations[0] = locationEmpty ;

        this.setState({ location:  locations[0] });
       
    }
   

    handleCanAdd(canAdd) {
        this.setState({ allowAdd: canAdd });
    }

    async handleSave() {

        if (this.state.sinEmp){
            await this.handleSaveAddress()
        }

       
        if (!this.state.title || !this.state.codeStudy || (this.state.observationsLength > 0 && this.state.observationsLength < 10)  ) {
            this.setState({ alert: true, 
                msg: (!this.state.title && !this.state.codeStudy) ? "Título, código de estudio son datos obligatorios" : (!this.state.title ? "Título obligatorio" : (!this.state.codeStudy ? "Código de estudio obligatorio" : "Mínimo 10 caracteres en observaciones"))               
            })
            return false        }

        if (this.state.antecedent!=null &&  this.state.antecedent.length>0 && this.state.tipoRelacion==-1) {
            this.setState({ alert: true,                
                msg:('Indica tipo de relación con antecedente')
            })
            return false
        }
        
        
            this.setState({ isSave: true });
            let fechaEntrada = new Date();
            fechaEntrada = fechaEntrada.toISOString();
            let expediente = {
                'Fecha_Entrada': fechaEntrada,
                'Titulo': this.state.title,
                'Expediente_Codigo_Estudio': this.state.codeStudy,
                'Antecedente': this.state.antecedent,
                'Observaciones': this.state.observations,
                'Emplazamientos': this.state.emplazamientos,
                'Substitucion':this.state.substitucion,
                'Comunicada':this.state.comunicada,
                'Voluntaria':this.state.voluntaria,
                'Id_Tipo_Relacion':this.state.tipoRelacion
                //'IgnorarObservaciones': 1
            }
            let response = await ApiCoag.expedientes.postExpediente(expediente);
            this.setState({ isSave: false });
            if (response == 'ERROR2') {/*response.MensajesProcesado && response.MensajesProcesado.length > 0) {*/
                this.props.fetchErrorExpediente(response);
            }
            else {
                
                if (response.Expediente[0].SePuede_CrearTrabajo>=0)    
                    this.props.history.push("/comunicacion/" + response.Expediente[0].Id_Expediente);
                else
                    this.handleShowDialog('Incidencias',response.Expediente[0].Mensaje_CrearTrabajo,'baja')
            }

        

    }

    handleClose = () => {
        this.setState({ alert: false });
    };

    handleUpdateIsShowAddress(showAddress) {
        this.setState({ isShowAddress: showAddress });
    }

    async handleUpdateLocation(location) {
        /*if(this.state.emplazamientos.some(x=> x.Georeferencia === location.Georeferencia)){
            let index = this.state.emplazamientos.findIndex(x=> x.Georeferencia === location.Georeferencia);
            let copy = Object.assign([], this.state.emplazamientos);
            copy[index] = location;
            await this.setState({ location: location, emplazamientos: copy});
            return;
        }*/
        await this.setState({ location: location });
    }

    handleNoLocation() {       
      }


    handleUpdateEmplazamientos(emplazamientos) {
        this.setState({ emplazamientos: emplazamientos });
    }

    async handleDeleteAddress(emplazamientos) {
        this.setState({ emplazamientos: emplazamientos });
        if (this.state.emplazamientos.length == 0 || !this.state.sinEmp)
            this.setState({declaracion:false})      
    }

    async handleSaveAddress() {
        let { location, emplazamientos } = this.state;
        let locations = [];
        Object.assign(locations, emplazamientos);
        let equal = this.ifEqual(emplazamientos, location);
        if (equal === -1) {
            locations.push(location);
        }
        else {
            locations[equal] = location;
        }

        await this.setState({ emplazamientos: locations, allowAdd: false });
        this.handleUpdateEmplazamientos(locations);
    }

    ifEqual(data, address) {
        let equal = some(data, address);
        let index = -1;
        if (equal) {
            index = findIndex(data, address);
        }
        return index;
    }

    async getAclaEmp() {
        let response=null;       
        response = await ApiCoag.tipos.guia.tipoAclaracion(5)        
        if (response) {           
            this.setState({ aclaraciones: response.Aclaraciones[0].Nombre });
        }         
    }
    
    handleShowDialog(title, content,tipoDialog='') {     
        this.setState({dialogOpen: true, tipoDialog:tipoDialog,dialogTitle: title, dialogContent: content});
    }
  
    handleCloseDialog = () => {
       this.setState({ dialogOpen: false });
    };

    render() {
        let { classes } = this.props;
        let acla=this.props.translate("languages.generalButton.aclaraciones")
        const { substitucion, voluntaria, comunicada } = this.state;
        let muestraDeclaracion=((this.state.emplazamientos && this.state.emplazamientos.length > 0) || this.state.sinEmp ) && !this.state.allowAdd;

        return (
            <Container className={classes.margin}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.alert}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.msg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleClose}
                        >
                            <Close />
                        </IconButton>,
                    ]}
                />
                <BreadcrumbsItem to={'/nuevo-expediente'} >
                    <Translate id="languages.breadcrumb.nuevoExpediente" />
                </BreadcrumbsItem>
                <Grid container spacing={8}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} md={6} className={classes.divider}>

                                {/* md no hace falta
                                <FormControl className={classes.formControl1}>
                                    <TextField
                                        id="code"
                                        label={<Translate id="languages.expedients.fieldCodigoExpediente" />}
                                        value={this.state.code}
                                        onChange={this.handleChange('code')}
                                        margin="normal"
                                        disabled
                                    />
                                 </FormControl>*/}

                                <FormControl className={classes.formControl1} style={{width: '21%', paddingRight: '0%'}}>
                                    <TextField
                                        required
                                        id="codeStudy"
                                        label={<Translate id="languages.expedients.fieldCodigoEstudio" />}
                                        value={this.state.codeStudy}
                                        onChange={this.handleChange('codeStudy')}
                                        margin="normal"
                                        autoFocus={true}   /* md */       
                                        inputProps={{ maxLength: 15 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl1} style={{width: '90%', paddingRight: '0%'}}>
                                    <TextField
                                        required
                                        id="title"
                                        label={<Translate id="languages.expedients.fieldTitleExp" />}
                                        value={this.state.title}
                                        onChange={this.handleChange('title')}
                                        inputProps={{ maxLength: 250 }}
                                      
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl1} style={{width: '21%', paddingRight: '0%'}}>
                                    <TextField
                                        id="antecedent"
                                        /*placeholder="Código de antecedente."*/
                                        label={<Translate id="languages.expedients.fieldAntecedente" />}
                                        value={this.state.antecedent}
                                        onChange={this.handleChange('antecedent')}
                                        inputProps={{ maxLength: 8 }}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>

                                {this.state.antecedent!='' && 
                                <FormControl className={classes.formControl1}
                                                 style={{width: '72%', paddingRight: '3%', paddingLeft: '5%'}}>
                                        <label className='MuiFormLabel-root-214' style={{
                                            marginTop: 17,
                                            marginBottom: -9,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            padding: 0,
                                            fontSize: 8,                                            
                                            fontFamily: 'Montserrat',
                                            lineHeight: 1
                                        }}>TIPO DE ANTECEDENTE</label>
                                        <Select
                                            id='tipoRelacion'
                                            label={'tipoRelacion'}                                                                                   
                                            onChange={this.handleChangeTipo('tipoRelacion')}                                            
                                            
                                            disabled={this.state.substitucion==1 }                     
                                            value={this.state.tipoRelacion==null && this.state.substitucion==0?-1:this.state.substitucion?ID_TIPO_RELACION_SUBS:this.state.tipoRelacion}                            
                                            
                                        >
                                             <MenuItem value={-1}  disabled><em>Indica tipo de relación con antecedente</em></MenuItem>
                                                <MenuItem value={ID_TIPO_RELACION_CF}>{'Cambio de formato en expediente propio'}</MenuItem>
                                                <MenuItem value={ID_TIPO_RELACION_SUBS}>{'Substitución de arquitecto'}</MenuItem>
                                          
                                        </Select>
                                    </FormControl>}
                               
                                <FormControl className={classes.formControl1} style={{width: '100%', paddingTop:'18px', paddingRight: '5%'}}>
                                
                                    <ChecksSubstituciones 
                                        substitucion={substitucion}                                        
                                        comunicada={comunicada}
                                        voluntaria={voluntaria}                                      
                                        mostrarBorde={true}                                       
                                        onChange={this.handleCheckboxChange}/>

                                </FormControl>
                            

                                {/* md las observaciones no aportan nada aquí. No eliminiar esté codigo comentado ya que es un buen ejemplo de textbox
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        id="observations"
                                        label={<Translate id="languages.expedients.fieldObservaciones" />}
                                        placeholder={"Introduce aquí las observaciones que consideres. Estas observaciones solo serán visibles para el estudio."}
                                        value={this.state.observations}
                                        onChange={this.handleChange('observations')}
                                        margin="normal"
                                        multiline
                                        rows={3}

                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                input: classes.textFieldInput,
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onInput={(e) => {
                                            let aux = e.target.value;
                                            this.setState({ observationsLength: aux.length });
                                            if (aux.length > 500)
                                                e.target.value = aux.slice(0, 500)
                                        }}
                                    />
                                    </FormControl>*/}
                            </Grid>
                            <Grid item xs={12} md={6} style={{position: "relative"}} >
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                   
                                     {this.state.aclaraciones!=null && <Tooltip title={!isMobile()?acla:''}>
                                        <IconButton   color="primary" aria-label="Info"   style={{paddingRight:10 ,position: "absolute", right: "0", top: "-12px"}} >
                                        <Info  onClick={()=>{this.handleShowDialog(acla,this.state.aclaraciones)}}></Info>
                                        </IconButton>
                                    </Tooltip>}

                                        {!this.state.sinEmp && <CatastralTable location={this.state.location} emplazamientos={this.state.emplazamientos}
                                            saveAddress={async () => {
                                                this.setState({ key: Math.random(), location: null });
                                                this.handleUpdateIsShowAddress(false);
                                                this.handleCanAdd(true);
                                            }}
                                            deleteAddress={async (emplazamientos) => { await this.handleDeleteAddress(emplazamientos) }}
                                            isShowAddress={this.state.isShowAddress} />
                                        }


                                        {this.state.permiteAltaExpSinEmplazamiento && 
                                            <FormControl>                                                     
                                                <FormControlLabel 
                                                    className="ml-2 mr-2 mt-2 "                                                            
                                                    key="Sin Empl"                                                                                     
                                                    control={
                                                        <Checkbox
                                                            checked={this.state.sinEmp }  
                                                            onChange={this.handleChange("sinEmp")  }                              
                                                            value={this.state.sinEmp }                                                                                        
                                                            color="primary" />
                                                            }
                                                    label={<Translate id="languages.generalText.sinEmplazamiento" />}
                                                />                                                                                       
                                                
                                        </FormControl>}                                    
                  

                                    </Grid>

                                    {this.state.allowAdd && <Grid item xs={12}>
                                        <AddressValidate updateLocation={async (location) => { await this.handleUpdateLocation(location) }}
                                            updateNoLocation={() => { this.handleNoLocation() }}
                                            isShowAddress={this.state.isShowAddress}
                                            validate={async () => await this.handleSaveAddress()}
                                            updateIsShowAddress={(showAddress) => { this.handleUpdateIsShowAddress(showAddress) }}
                                            location={this.state.location}
                                            key={this.state.key} />
                                    </Grid>}

                                  
                                    {this.state.allowAdd && <Grid item xs={12} className="text-right">
                                        <Button color="primary" size="small" className={`mx-2`}
                                            onClick={() => { this.handleCanAdd(false) }}>
                                            <Translate id="languages.generalButton.cancel" /><Close className={classes.rightIcon} />
                                        </Button>
                                        <Button variant="contained" size="small" color="primary"
                                            className={classes.button}
                                            disabled={!this.state.location || !this.state.isShowAddress || (this.state.location.Concello == null || this.state.location.Calle == null || this.state.location.Calle.trim()=='') }
                                            onClick={async () => { await this.handleSaveAddress(); }}>
                                            <Translate id="languages.generalButton.added" />
                                        </Button>
                                    </Grid>}

                                    {muestraDeclaracion &&  <Grid item xs={12} >
                                        <FormControl>                                                     
                                     <FormControlLabel   className="ml-2 mr-2 mt-2 "                                                                                                     
                                                   key="declaracion"                                                                                     
                                                   control={
                                                        <Checkbox
                                                            checked={this.state.declaracion }  
                                                            onChange={this.handleChange("declaracion")  }                              
                                                            value={this.state.declaracion }                                                                                        
                                                            color="primary" />
                                                            }
                                                    label={'Declaro coñecer e ser consciente do réxime disciplinario aplicable no caso de faltar a verdade na comunicación ao Colexio na encomenda deste expediente ou nos traballos para o seu desenvolvemento, así como no caso de incumprir outras obrigas deontolóxicas'}
                                                />                                                                                                                                       
                                    </FormControl> 
                                    </Grid>}

                                    {!this.state.allowAdd && 
                                    <Grid item xs={12} style={{ textAlign: "right" }}>
                                        <Button color="primary" className={classes.button} onClick={() => { this.props.history.push("/") }}>
                                            {<Translate id="languages.generalButton.cancel" />}
                                            <Clear className={classes.rightIcon} />
                                        </Button>
                                        <Button variant="contained" color="primary" className={classes.button}
                                            onClick={() => { this.handleSave() }}
                                            disabled={this.state.emplazamientos.length === 0 && !this.state.sinEmp || !this.state.declaracion}>
                                            <Translate id="languages.expedients.btnGuardarExpediente" />
                                            <Check className={classes.rightIcon} />
                                        </Button>
                                        {this.state.isSave && <CircularProgress size={24} />}
                                    </Grid>}

                                    {                  
                                    <Dialog
                                        open={this.state.dialogOpen}
                                        onClose={this.handleCloseDialog}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">                                    
                                        <DialogTitle id="alert-dialog-title">
                                            {this.state.tipoDialog=='baja'?<h2 style={{color:'#ed4140',fontSize:'1rem'}}>Incidencias</h2>: this.state.dialogTitle}
                                        </DialogTitle>
                                        <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <ReactQuill value={this.state.dialogContent} readOnly theme='bubble' />
                                        </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                        <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
                                            <Translate id="languages.generalButton.aceptar"/>
                                        </Button>
                                        </DialogActions>
                                   </Dialog>
             
                                    }    

                <Confirmation openConfirmation={this.state.openModal}
                    title={'Expediente de substitución' }                
                    confirmationMessage={ <Translate id="languages.messages.expedienteSubstitucion"/> }
                    acceptCancel={true}
                    aceptConfirmation={this.handleModalClose}
                    declineConfirmation={this.handleModalCloseCanceled}   
                    botonConfirmarTexto={'Continuar'}
                    maxWidth={"md"}
                    confirmationCheckItem={true}
                    confirmationCheckItemText={'ENTERADO'}
                    />

                                </Grid>
                            </Grid>

                         
                        </Grid>
                    </Paper>
                </Grid>
            </Container>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(AddExpedient)));