import React from 'react';
import Home from './../components/Home/Home';
import './styles.css';

const Contenedorcentral = () =>(

    <div>
        <Home  />
    </div>
)
export default Contenedorcentral;