import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow  } from '@material-ui/core';
import {  Grid, Button, Typography, Paper, Checkbox, Toolbar, Dialog, DialogActions, DialogContent,  DialogTitle } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Translate } from "react-localize-redux";
import { lighten } from '@material-ui/core/styles/colorManipulator';
import InfoCarpetas from "./infoCarpetas"
import ApiCoag from "../../api/ApiCoag";
import { groupBy, filter } from 'lodash';



/**Table head */
class EnhancedTableHead extends React.Component {
  render() {
    const { onSelectAllClick, numSelected, rowCount } = this.props;
    return (
      <TableHead>
        <TableRow style={{  height: 28, backgroundColor: "#faf9f9" }}>
           
          <TableCell style={{ width: 20  }} >{/*padding="checkbox" >*/}
            {/*md nunca se van a seleccionar todos, como mucho 3
            <Checkbox indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick} color="primary" />
            */}
           </TableCell> 

          <TableCell className="text-left text-uppercase px-0 py-0" style={{ width: 220 }}>
            <Translate id="languages.crearTrabajo.tableTitle" />
          </TableCell>
          <TableCell className="text-center text-uppercase py-0 px-1">
            <Translate id="languages.crearTrabajo.tableRequired" />
          </TableCell>
          <TableCell className="text-center text-uppercase py-0 px-1">
            <Translate id="languages.crearTrabajo.tableVisado" />
          </TableCell>
          <TableCell className="text-center text-uppercase py-0 px-0">
            <Translate id="languages.crearTrabajo.tableRegistro" />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

/**Toolbar para la tabla */
/**Estilos para el toolbar */
const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  titleMainPanel: {
    borderBottom: `2px solid ${grey[100]}`
  }
});

/**Toolbar para la tabla */
/**Funcion encargada de renderear el toolbar */
let EnhancedTableToolbar = props => {
  const { numSelected, classes, title } = props;
  return (
    <Toolbar className={classNames(classes.root, { [classes.highlight]: numSelected > 0, }, classes.titleMainPanel)}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {title} {numSelected > 0 && <Typography color="inherit" variant="subtitle1">{numSelected + ' '}
            {numSelected === 1 ? <Translate id="languages.crearTrabajo.tableSelection" />
              : <Translate id="languages.crearTrabajo.tableSelections" />}
          </Typography>}
        </Typography>
      </div>
    </Toolbar>
  );
};
EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

/**Tabla */
/**Estilos para la tabla */
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  withoutRadius: {
    borderRadius: "0 !important"
  },
  
  titleColor: {
    color: `${theme.palette.primary.main} !important`,
    cursor: "pointer"
  }
});


/**Tabla */
class EnhancedTable extends React.Component {
  state = {
    selected: this.props.previusSelection ? this.props.previusSelection : [],
    dataTable: this.props.data,
    inforCarpetas: [],
    showDetalles: false,
    trabajoTituloSeleccion : ''
  };

 
 

  handleSelectAllClick = event => {
    if (event.target.checked) {
      let selections = this.state.dataTable.trabajos.map(n => n.Id_Tipo_Trabajo);
      this.setState(state => ({ ...state, selected: selections }));
      this.updateSelectWorks(selections);
      return;
    }
    this.setState(state => ({ ...state, selected: [] }));
    this.updateSelectWorks([]);
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    }
    else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    }
    else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    }
    else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState(state => ({ ...state, selected: newSelected }));
    this.updateSelectWorks(newSelected);
  };

  isSelected(id) {
    return this.state.selected.indexOf(id) !== -1;
  };

  updateSelectWorks(selection) {
    let worksSelected = this.state.dataTable.trabajos.filter(f =>
      selection.some(x => x === f.Id_Tipo_Trabajo));
    this.props.updateSelectTrabajos({
      fase: this.props.data.Id_Tipo_Fase,
      trabajos: worksSelected
    });
  }

  async getInfoCarpetasTrabajo(id_tipo_trabajo, id_tipo_tramite, es_modificado) {
    let inforCarpetas = [];
    let response = await ApiCoag.tipos.guia.infoCarpetasDeTrabajo(id_tipo_trabajo, id_tipo_tramite ? id_tipo_tramite : 0, es_modificado);
    if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
       // this.props.fetchErrorExpediente(response);
       
    }
    else {
        let carpetas = response.Carpetas;
        let father = filter(carpetas, { 'Id_Documentacion_Padre': null });
        let children = groupBy(carpetas, 'Id_Documentacion_Padre');
        let fatherChildren = [];
        father.map(value => {
            value["children"] = children[value.Id_Documentacion]
            fatherChildren.push(value);

        })
        inforCarpetas = fatherChildren;
      
    }
    this.setState({ inforCarpetas: inforCarpetas });
}

mostrarDetallesCarpetasFirmasRequeridas(idTipoTrabajo,idTipoTramite,bEsModificado,trabajoTitulo){      

  this.getInfoCarpetasTrabajo(idTipoTrabajo,idTipoTramite,bEsModificado)

  this.setState({
      showDetalles:true,                
      trabajoTituloSeleccion:trabajoTitulo
     
  })
        
}


  detallesCarpetasFirmasRequeridas() {

    if(this.state.showDetalles){

                    
        return (<div>



            <Dialog  maxWidth="md" fullWidth={true} open={this.state.showDetalles}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                <Translate id="languages.trabajo.CarpetasFirmasRequeridas" />
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={4}>
                    <Grid item xs={12} >
                                             
                          
                    <InfoCarpetas  trabajoTitulo={this.state.trabajoTituloSeleccion} inforCarpetas={this.state.inforCarpetas} />
                        
                        </Grid>
                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={async () => {
                        this.setState({ showDetalles: false });

                    }} variant="contained"
                            color="primary" autoFocus style={{ height: 30, minHeight: 30 }}
                            className="p-0">
                        Aceptar

                    </Button>

                </DialogActions>
            </Dialog>
        </div>);
    }

}

  

  render() {
    const { classes } = this.props;
    const { dataTable, selected } = this.state;

    

    return (

      
      
      <Paper className={`${classes.root} ${classes.withoutRadius}`}>
        <EnhancedTableToolbar numSelected={selected.length} title={this.state.dataTable.fase ? this.state.dataTable.fase:'' } />
       

        <div className={classes.tableWrapper}>
      
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead numSelected={selected.length}
              onSelectAllClick={this.handleSelectAllClick}
              rowCount={dataTable.trabajos.length} title={this.state.dataTable.fase} />
            <TableBody>
             
            {/*this.detallesCarpetasFirmasRequeridas()*/}

              {
                  

                dataTable.trabajos.map((n) => {
                  const isSelected = this.isSelected(n.Id_Tipo_Trabajo);
                  return (
                    <TableRow key={n.Id_Tipo_Trabajo} hover style={{ height: 30 }}
                    
                      role="checkbox" aria-checked={isSelected}
                      tabIndex={-1}  selected={isSelected}>
                      <TableCell padding="checkbox" className="py-0"
                        onClick={event => this.handleClick(event, n.Id_Tipo_Trabajo)}>
                        <Checkbox checked={isSelected} color="primary" padding="none" className="py-1" />
                      </TableCell>
                      <TableCell width="30%" component="th" scope="row" className="p-0 text-left">
                    
                      
                      {/*<a  className={classes.titleColor} onClick={() => { this.mostrarDetallesCarpetasFirmasRequeridas(n.Id_Tipo_Trabajo,n.Obligatorio == 'visado' || n.Obligatorio =='--' ? 0:2,0,n.Trabajo_Titulo)}}>
                      
                                           {n.Trabajo_Titulo}
                                          </a> */}
                                          {n.Trabajo_Titulo}                             
                      </TableCell>
                      <TableCell  className="text-center py-0 px-1">{n.Obligatorio ? n.Obligatorio : "--"}</TableCell>
                      <TableCell   className="text-center py-0 px-1">{n.comvisado!=null ? n.Id_Tipo_Calculo_Visado>0?'variable':n.comvisado: "--"}</TableCell>
                      <TableCell    className="text-center py-0 px-0">{n.comrexistro!=null?n.Id_Tipo_Calculo_Registro>0?'variable':n.comrexistro : "--"}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}


export default withStyles(styles)(EnhancedTable);



