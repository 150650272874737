import React, { Component } from 'react';
import {
    Grid,
    Paper,
    CircularProgress,
    Typography,
    TextField,
    Button,
    ExpansionPanel,
    Checkbox,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    LinearProgress, withStyles,
    FormControl,
    Select, MenuItem, FormControlLabel, RadioGroup, Radio, Tooltip,IconButton,
    Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText
} from '@material-ui/core';
import * as api from '../../../../api'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Check,Info } from '@material-ui/icons';
import moment from 'moment'
//import RenderHTML from 'react-native-render-html' /*'react-render-html';*/
import ReactQuill from "react-quill";
import * as actionsExpedientes from '../../../../actions/expedientes';
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";

import { red, green, orange } from '@material-ui/core/colors';

import '../../../Tramitaciones/estados.css';
import { formatMenssage } from "../../../../actions/expedientes";
import TemporalFolder from './TemporalFolder'
/*import FilesInFolder from "./FilesInFolder";*/
import FilesInFolder2 from "./FilesInFolder2";
import DTProyectoEjecucion from "./datosTecnicos/DTProyectoEjecucion";
import DTPlaneamiento from "./datosTecnicos/DTPlaneamiento";
import DTUrbanizacion from "./datosTecnicos/DTUrbanizacion";
import DTDemolicion from "./datosTecnicos/DTDemolicion";
import DTOtros from "./datosTecnicos/DTOtros";
import OtrosAgentes from "./OtrosAgentes";
import DatosEconomicos from "./DatosEconomicos";
import ComunicacionVisado from "./ComunicacionVisado";
import Incidencias from "./Incidencias";
import ApiCoag from "../../../../api/ApiCoag";
import {ID_TRABAJO_ENCOMENDA, ID_TRABAJO_VAL_GEOTECNICO} from "../../../../api/config";
import {isMobile} from "../../../../api/index";


const styles = theme => ({
    root: {
        width: '100%',
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonProgress: {

        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    red: {
        color: red[500],

    },
    green: {
        color: green[500]
    },
    orange: {
        color: orange[500],
        overflow: 'hidden',
        marginRight: '5%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    size: {
        fontSize: 12,
        marginTop: 6
    },
    formControl: {
        width: "100%"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
        textAlign: 'left',
    },
    textFieldInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
    link: {
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    black: {
        fontWeight: 700
    },
    margin: {
        margin: 0
    },
    backgroundColor: {
        backgroundColor: "#f5f5f5"
    },
    rootPanel: {
        position: "inherit"
    },
    helperText: {
        textAlign: "end",
        fontWeight: "bold"
    },
    disabledContainer: {
        pointerEvents: "none",
        opacity: 0.7
    }
});
const mapStateToProps = (state) => {
    return (
        {
            fileUpload: state.status.files
        }
    )
};

const mapDispatchToProps =
{
    fetchErrorExpediente: actionsExpedientes.fetchErrorExpediente,
    uploadFiles: actionsExpedientes.uploadFiles,
    resetUploadStates: actionsExpedientes.resetUpladStates,
    cancelUpload: actionsExpedientes.cancelUpload,
    showUploadComponent: actionsExpedientes.showUploadComponent,
    hideUploadComponent: actionsExpedientes.hideUploadComponent,
    formatMessage: actionsExpedientes.formatMenssage,
    dispatchSetFetchingDone: actionsExpedientes.dispatchSetFetchingDone
};

class TrabajoEjecucion extends Component {

    componentWillUnmount() {
        this.props.showUploadComponent()
    }

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            error: false,
            data: [],
            firmasDigitales: [],
            panelExpanded: false,
            folderInfo: false,
            expediente: false,
            workDetails: false,
            aclaracionesOpen: false,
            uploadInProgress: false,
            uploadLength: 0,
            currentUpload: 0,
            itemSelected: [],
            checkAll: false,
            currentUploadItem: false,
            pendingUploadList: [],
            //fetchingRemove: 0,
            allowUpload: true,
            disableAutoAsignButton: true,
            detallesArchivo: null,
            loadingDetallesArchivo: false,
            tiposTramites: [],
            loadingUpdateFichaTrabajo: false,
            temporalFiles: [],
            administrativeNotivficationSave: null,
            changingWorkData:false  ,
            workEditable:true,
            aclaraciones:null          
        }
    }

    // async UNSAFE_componentWillMount() {

    //     await this.loadGeneralInformation();
    //     await this.getTiposTramites();
    //     await this.getAclaInterop();      
    // }

    async componentDidMount() {

        await this.loadGeneralInformation();
        await this.getTiposTramites();
        await this.getAclaInterop();      
    }

    

    // async UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    //     /*if (this.props.fetchingRefresh)
    //     {this.loadGeneralInformation();}*/       
    // }

    /*async componentDidMount() {

        await this.loadGeneralInformation();
        await this.getTiposTramites();
    }*/

   
    async getAclaInterop() {
        let response=null;       
        response = await ApiCoag.tipos.guia.tipoAclaracion(7)        
        if (response) {           
            this.setState({aclaraciones: response.Aclaraciones[0].Nombre });
        }         
    }
    

    async getTiposTramites() {
        let response = await ApiCoag.tipos.guia.tiposTramites();
        if (response) {
            let tiposTramites = response.Tipos_Trabajos_Tramites;
            this.setState({ tiposTramites: tiposTramites });
        }
    }

    handleShowDialog(title, content,tipoDialog='') {     
        this.setState({dialogOpen: true, tipoDialog:tipoDialog,dialogTitle: title, dialogContent: content});
    }
    handleCloseDialog = () => {
        this.setState({ dialogOpen: false });
     };
 

    
    async loadGeneralInformation(withoutLoading=false) {
        if(!withoutLoading){
            //await this.setState({ fetching: true }) Nota:no hace falta hacer esto ya que se inicializa así y hace mal efecto lanzar estado de nuevo
        }

        let expediente = this.props.expediente.Expediente[0];

        try {
            let workDetails = await ApiCoag.expedientes.trabajo.getDatosGenerales(expediente.Id_Expediente, this.props.trabajo);
            /*workDetails = workDetails;*/
            if (workDetails)    {
                if (workDetails  != 'ERROR2'){               

                    await this.setState({
                        expediente,
                        allowUpload: workDetails.Trabajos[0].Estado && workDetails.Trabajos[0].Estado == 'Tramitado' ? false : true,
                        workDetails,
                        administrativeNotivficationSave: workDetails.Trabajos[0].Es_Trabajo_Modificado_Requerido_Administracion,
                        changingWorkData:false,
                        workEditable :workDetails.Trabajos[0].SePuede_Editar,
                        esTrabajoNuevo:workDetails.Trabajos[0].Es_Trabajo_Nuevo
                    })
                }
                else{ /*Si hay error2 en la carga es que es operación prohibida y mandamos a pantalla inicio*/
           
                   //this.props.history.push(`/`);
                }
        }
        } catch (e) {
            this.props.fetchErrorExpediente(e);
        }

        /*if (this.props.estructura) {

            let idEstructuraMostrar = this.props.estructura.id;                      
                     
            let folderInfoResponse = await ApiCoag.expedientes.trabajo.estructuraDocumental.informacionDeCarpeta(expediente.Id_Expediente, this.props.trabajo, idEstructuraMostrar)
            
            let folderInfo = folderInfoResponse.Carpetas[0];

            await this.setState({ expediente, folderInfo, allowUpload: folderInfo.Permite_Anexar_Archivos === '1' ? true : false, estructuraEditable: folderInfo.SePuede_Editar == 1 ? true : false })
        } else {
          
        }*/

        if(!withoutLoading){
            await this.setState({ fetching: false })
        }
    }
    async handleDocumentView(file) {
        try {
            let expediente = this.props.expediente.Expediente[0];
            let response = file.Id_Estructura
                ? await ApiCoag.expedientes.trabajo.estructuraDocumental.infoArchivovisualizacion(expediente.Id_Expediente, this.props.trabajo, file.Id_Estructura)
                : await ApiCoag.expedientes.downloadTemporalFiles(expediente.Id_Expediente, [{ Nombre: file.Nombre }])

            if (response.Archivos && response.Archivos.length > 0) {
                window.open(response.Archivos[0].Url, "_blank");
            }
            else if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
               // this.props.fetchErrorExpediente(response);
            }
        } catch (error) {
            this.props.fetchErrorExpediente(formatMenssage(error));
        }
    }


    renderSize(size) {
        if (size < 1048576) {
            return (size / 1024).toFixed(2) + ' Kb'
        } else if (size < 1073741824) {
            return (size / 1024 / 1024).toFixed(2) + ' Mb'
        } else {
            return (size / 1024 / 1024 / 1024).toFixed(2) + ' Gb'
        }

    }

    handleChangeFichaTrabajo = (propertyName, checkedProperty = false) => (event) => {

        let trabajoCopy = {};
        Object.assign(trabajoCopy, this.state.workDetails);

        if (!checkedProperty) 
            trabajoCopy.Trabajos[0][propertyName] = event.target.value;
        else {
            trabajoCopy.Trabajos[0][propertyName] = event.target.checked ? 1 : 0;
        }

        if(propertyName=='Tipo_Tramite'){
            //let msgFormatted = formatMenssage('Debe guardar los cambios para actualizar la pantalla.')
            this.state.tiposTramites.map((item,pos)=>{
                if(item.Nombre==event.target.value){
                    trabajoCopy.Trabajos[0].Id_Tipo_Tramite=item.Id_Tipo_Tramite
                }
            })
            //msgFormatted.MensajesProcesado[0].IdTipo=3
            //this.props.fetchErrorExpediente(msgFormatted);
        }
       
        if(propertyName=='Envio_Administracion' && trabajoCopy.Trabajos[0].Id_Estado >= 3 ){           
            this.setState({ workDetails: trabajoCopy});        
            if ( trabajoCopy.Trabajos[0].Envio_Administracion === 1) {                    
                 this.putAutorizacionEnvioAdmTrabajo(true,trabajoCopy)
                }                
            else
                this.putAutorizacionEnvioAdmTrabajo(false,trabajoCopy)                
        }
        else
            this.setState({ workDetails: trabajoCopy, changingWorkData: true });
        
        
        
      
    }


    handleChangeFichaTrabajoTipo = () => (event) => {
        let trabajoCopy = Object.assign({}, this.state.workDetails);
        trabajoCopy.Trabajos[0].Es_Trabajo_Nuevo = ("Es_Trabajo_Nuevo" === event.target.value) ? 1 : 0;
        trabajoCopy.Trabajos[0].Es_Trabajo_Modificado_Sustancial = ("Es_Trabajo_Modificado_Sustancial" === event.target.value) ? 1 : 0;
        trabajoCopy.Trabajos[0].Es_Trabajo_Modificado_Correcion_Basica = ("Es_Trabajo_Modificado_Correcion_Basica" === event.target.value) ? 1 : 0;

        if ("Es_Trabajo_Nuevo" === event.target.value) {
            trabajoCopy.Trabajos[0].Es_Trabajo_Modificado_Requerido_Administracion = 0;
        } else {
            trabajoCopy.Trabajos[0].Es_Trabajo_Modificado_Requerido_Administracion = this.state.administrativeNotivficationSave;
        }

        /*if("Es_Trabajo_Nuevo" === event.target.value||"Es_Trabajo_Modificado_Sustancial" === event.target.value){
            let msgFormatted = formatMenssage('Debe gaurdar los cambios para actualizar la pantalla.')
            msgFormatted.MensajesProcesado[0].IdTipo=3

            this.props.fetchErrorExpediente(msgFormatted);

        }*/
        this.setState({ workDetails: trabajoCopy, changingWorkData : true });
        
    }

    getCleanedString(cadena) {
        if (cadena == null)
          return '';      
        cadena = cadena.toLowerCase();      
        return cadena;
      }

    estadoColor = (nombre) => {
        let nombreLimpio = this.getCleanedString(nombre);
        if (nombreLimpio !== '')
            return (
                <div >{/*className={nombreLimpio}>*/}
                    {/*<img alt={200} src={this.iconUrl(nombreLimpio)} />*/}
                    {nombre}
                </div>);
    }

  iconUrl = (nombreLimpio) => {
        try {
            return require(`../../../Tramitaciones/IconosEstados/${nombreLimpio}.svg`);
        } catch (e) {
            let nombreLimpio='borrador'
            return require(`../../../Tramitaciones/IconosEstados/${nombreLimpio}.svg`);
        }
    }

    async putFichaTrabajo() {
        try {

            this.setState({ loadingUpdateFichaTrabajo: true });
            let trabajo = this.state.workDetails.Trabajos[0];
            let data = {
                "Id_Tipo_Tramite": trabajo.Id_Tipo_Tramite,
                "Es_Trabajo_Nuevo": trabajo.Es_Trabajo_Nuevo,
                "Es_Trabajo_Modificado_Correcion_Basica": trabajo.Es_Trabajo_Modificado_Correcion_Basica,
                "Es_Trabajo_Modificado_Sustancial": trabajo.Es_Trabajo_Modificado_Sustancial,
                "Es_Trabajo_Modificado_Requerido_Administracion": trabajo.Es_Trabajo_Modificado_Requerido_Administracion,
                "Observaciones": trabajo.Observaciones,
                "Envio_Administracion": trabajo.Envio_Administracion,
                "Titulo_Complementario": trabajo.Titulo_Complementario
            }

            let response = await ApiCoag.expedientes.trabajo.putFichaTrabajo(this.props.expediente.Expediente[0].Id_Expediente, this.props.trabajo, data)
            if(response){
                if (response == "ERROR2") // (response.MensajesProcesado && response.MensajesProcesado.length > 0)
                 {
                    // this.props.fetchErrorExpediente(response);
                    this.setState({ loadingUpdateFichaTrabajo: false });

                }
                else {
                    let workDetails = response
                    let administrativeNotivficationSave = response && response.Trabajos && response.Trabajos.length > 0
                        ? response.Trabajos[0].Es_Trabajo_Modificado_Requerido_Administracion : 0;
                    this.setState({
                        loadingUpdateFichaTrabajo: false,
                        administrativeNotivficationSave: administrativeNotivficationSave,
                        changingWorkData: false,
                        workDetails: workDetails,
                        esTrabajoNuevo:response.Trabajos[0].Es_Trabajo_Nuevo

                    });                   
                    //window.location.reload()
                    this.props.refreshEstructuraAfterChangeTrabajo(response.Trabajos);
                    this.renderDatosTecnicos();

                   
                }
               
            }else{
                this.loadGeneralInformation(true);
                this.setState({ loadingUpdateFichaTrabajo: false });
            }




        } catch (e) {
            this.props.fetchErrorExpediente(this.props.formatMenssage(e.message));
            this.setState({ loadingUpdateFichaTrabajo: false });
        }


    }

    async putAutorizacionEnvioAdmTrabajo(autorizar,trabajoCopy) {
        try {
          //  this.setState({ loadingUpdateFichaTrabajo: true });                  
            let data = {}
            let response=null;
           

            if (autorizar)
                response = await ApiCoag.expedientes.trabajo.putAutorizarEnvioAdmTrabajo(this.props.expediente.Expediente[0].Id_Expediente, this.props.trabajo,data)
            else
                response = await ApiCoag.expedientes.trabajo.putDesAutorizarEnvioAdmTrabajo(this.props.expediente.Expediente[0].Id_Expediente, this.props.trabajo,data)

            if(response){
               
                if (response == "ERROR2") 
                 {                              
                   //estamos quitando autorizacion pero nos llega incidencia previa, por lo que mantenemos a 1, o viceversa
                    trabajoCopy.Trabajos[0].Envio_Administracion =  autorizar ? 0:1;    

                    this.setState({ workDetails: trabajoCopy,loadingUpdateFichaTrabajo: false });
                }
                else {
                    /*let workDetails = response
                    let administrativeNotivficationSave = response && response.Trabajos && response.Trabajos.length > 0
                        ? response.Trabajos[0].Es_Trabajo_Modificado_Requerido_Administracion : 0;
                    this.setState({
                        loadingUpdateFichaTrabajo: false,
                        administrativeNotivficationSave: administrativeNotivficationSave,
                        changingWorkData: false,
                        workDetails: workDetails,
                    }); */
                    trabajoCopy.Trabajos[0].Envio_Administracion =  autorizar ? 1:0;  

                    this.setState({ workDetails: trabajoCopy,loadingUpdateFichaTrabajo: false });                                 
                }               
            }else{
                this.loadGeneralInformation(true);
              //  this.setState({ loadingUpdateFichaTrabajo: false });
            }
        } catch (e) {
            this.props.fetchErrorExpediente(this.props.formatMenssage(e.message));
            this.setState({ loadingUpdateFichaTrabajo: false });
        }


    }


    /*dragStart = (item, temporal) => (event) => {
        let allFiles = this.itemsToRemove();
        if (!item.checked && temporal)
            allFiles.temporalFiles.push(item);
        if (!item.checked && !temporal)
            allFiles.files.push(item);
        this.props.dragging(allFiles)
    }*/

    renderDatosTecnicos(){
        let work = this.state.workDetails && this.state.workDetails.Trabajos && this.state.workDetails.Trabajos.length > 0
            ? this.state.workDetails.Trabajos[0] : {};
        let tipoCalculo = parseInt(work.Id_Tipo_Calculo)
        switch (tipoCalculo) {
            case 1:
                return ( <DTProyectoEjecucion work={work}/>);
                break;

            case 4:
                return (<DTPlaneamiento work={work}/>)
                break;
            case 3:
                return (<DTUrbanizacion work={work}/>)
                break;    

            case 2:
                return (<DTDemolicion work={work}/>)
                return null;

            default:
                return (<DTOtros work={work}/>)
                return null;

        }


    }
    otrosAgentes(){
        let work = this.state.workDetails && this.state.workDetails.Trabajos && this.state.workDetails.Trabajos.length > 0
            ? this.state.workDetails.Trabajos[0] : {};
        return (<OtrosAgentes work={work} isNewOrDelAgent={() => {this.props.isNewOrDelAgent() }}/>)
    }

    // onAsign(files,carpetas,asignados,title){       
    //     this.setState({ filesAuto:files,carpetas:carpetas,asignados:asignados,title:title,onAsign: true });
    // }

    // asingFiles(){
    //     return (<AsignFilesFolders openConfirm={this.state.onAsign} 
    //                 //aceptConfirmation={async () => {await this.handleSaveAsign();   }}                            
    //                 declineConfirmation={() => this.setState({onAsign: false})}   
    //                 archivos={this.state.filesAuto}
    //                 carpetas={this.state.carpetas}
    //                 asignados={this.state.asignados}
    //                 title={this.state.title}
    //                 //cellValueChanged={(idDoc,name)=>this.cellValueChanged(idDoc,name)}
    //                 //changeOrder={(orderEst)=>this.changeOrder(orderEst)}
    //              />)
    // }

    renderDatosEconomicos(){
        let work = this.state.workDetails && this.state.workDetails.Trabajos && this.state.workDetails.Trabajos.length > 0
            ? this.state.workDetails.Trabajos[0] : {};
        return (<DatosEconomicos work={work}/>)
    }
    
    renderComunicacionVisado(){
        let work = this.state.workDetails && this.state.workDetails.Trabajos && this.state.workDetails.Trabajos.length > 0
            ? this.state.workDetails.Trabajos[0] : {};
        return (<ComunicacionVisado esTrabajoNuevo={this.state.esTrabajoNuevo} work={work} 
            //isModified={(item) => { this.props.isModified(item) }}
            />)
    }
    renderIncidencias(){
        let work = this.state.workDetails && this.state.workDetails.Trabajos && this.state.workDetails.Trabajos.length > 0
            ? this.state.workDetails.Trabajos[0] : {};
        return (<Incidencias work={work}/>)
    }
  


    renderFichaCarpeta() {
             
        return (
            <Paper
            style={{ borderColor: '#cecece', borderWidth: 1, marginBottom: 8 }}>
            <div className="px-4 py-2">
                <Typography variant='button'>{<Translate id="languages.trabajo.fichaCarpeta" /> }</Typography> 
            </div>
            <div style={{ backgroundColor: '#f5f5f5', marginTop: 10, marginBottom: 10 }}>
                <Grid container spacing={16}>

                    <Grid item xs={12} className="px-4">
                        <Grid container spacing={16}>
                            <Grid item xs={6} className="p-2">
                                <label style={{ textTransform: 'uppercase', fontSize: 12,marginLeft:2 }}>
                                    <Translate id="languages.fileUpload.requiredFirms" /></label><br />
                                {
                                    this.props.folderInfo.Firmas_Requeridas?
                                    <b style={{textTransform: 'uppercase',fontSize: 12,marginLeft:3}}>{this.props.folderInfo.Firmas_Requeridas}</b>:
                                        <Typography variant={"body1"} style={{fontSize: 12,marginLeft:3}}>
                                            {/*md hay que distinguir si es carpeta donde se pueden meter archivos o no  */}
                                            {this.props.folderInfo.Permite_Anexar_Archivos == 0 ?
                                           <Translate id="languages.trabajo.verEnSubcarpetas"/> : '--'}
                                        </Typography>
                                }

                            </Grid>

                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12} className="px-4">
                        <Grid container spacing={16}>
                            <Grid item xs={12} className="p-3">
                                <ExpansionPanel expanded={this.state.aclaracionesOpen}
                                    onChange={() => this.setState({ aclaracionesOpen: !this.state.aclaracionesOpen })}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                            {this.state.aclaracionesOpen
                                            ? <Translate id="languages.fileUpload.hideAclarations" />
                                            : <Translate id="languages.fileUpload.showAclarations" />}
                                            {this.props.folderInfo.Nombre}
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div style={{ width: '100%' }}>
                                            <span>
                                                {/*renderHTML(this.props.folderInfo.Aclaraciones)*/}
                                                <ReactQuill value={this.props.folderInfo.Aclaraciones} readOnly theme='bubble' className="d-flex align-items-center justify-content-between"/>
                                            
                                            </span>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        </Grid>
                    </Grid>

                    
                </Grid>

            </div>
        </Paper>

        )

    }
    renderFichaTrabajo() {
        let { classes } = this.props;
        let work = this.state.workDetails && this.state.workDetails.Trabajos && this.state.workDetails.Trabajos.length > 0
            ? this.state.workDetails.Trabajos[0] : {};

            let isMobile_= isMobile();                        
       
        return (this.state.workDetails && this.state.workDetails.Trabajos
            && this.state.workDetails.Trabajos.length > 0)
            && <ExpansionPanel expanded={!this.state.fichaTrabajoOpen }
                onChange={() => this.setState({ fichaTrabajoOpen: !this.state.fichaTrabajoOpen })}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                        <Typography variant='button'>
                            <Translate id="languages.fileUpload.formWork" />
                        </Typography>

                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0 }}
                    /*className={`${work.SePuede_Editar !== 1 ? classes.disabledContainer : ""}`}*/>
                    <Grid container spacing={0}>

                    <Grid item xs={12} className={classes.backgroundColor}>
                            <Grid container spacing={0} className="p-4">
                                <Grid item xs={8}>
                                    <FormControl className={`${classes.formControl} pr-3`}>
                                        <Tooltip title={`${this.state.workDetails.Trabajos[0].Tipo_Grupo_tematico}/${this.state.workDetails.Trabajos[0].Tipo_Autorizacion_Municipal}`}>
                                            <TextField disabled={true}
                                                value={`${this.state.workDetails.Trabajos[0].Tipo_Grupo_tematico}/${this.state.workDetails.Trabajos[0].Tipo_Autorizacion_Municipal}`}
                                                label={<Translate id="languages.fileUpload.expedientType" />}
                                                className={`text-uppercase`}
                                            />
                                        </Tooltip>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl className={classes.formControl}>
                                        <TextField disabled={true}
                                            value={this.state.workDetails.Trabajos[0].Tipo_Fase}
                                            label={<Translate id="languages.fileUpload.documentation" />}
                                            className={`text-uppercase`}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xs={12}>
                            <Grid container spacing={0} style={{ paddingBottom:0,paddingLeft:20,paddingRight:2,paddingTop:24}}>

                            <Grid item xs={4}>
                                    <FormControl className={classes.formControl} style={{ paddingTop:6}}>
                                        <Select className={`${work.SePuede_EditarTipoTramite !== 1 ? classes.disabledContainer : ""}`}
                                                value={this.state.workDetails.Trabajos[0].Tipo_Tramite}
                                                displayEmpty
                                                onChange={this.handleChangeFichaTrabajo("Tipo_Tramite")}
                                                inputProps={{
                                                    name: 'Tipo_Tramite',
                                                    id: 'Tipo_Tramite',
                                                }}>
                                            {this.state.tiposTramites && this.state.tiposTramites.map(tramite => (
                                                <MenuItem
                                                    value={tramite.Nombre}>{tramite.Nombre}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={!isMobile_ ? 4 : 2}></Grid>

                                <Grid item xs={!isMobile_? 4 : 6}>
                              
                              {work.SePuede_EditarEnvioAdministracion === 1 && work.Administracion_Fecha_Descarga ==null &&
                              <FormControlLabel style={{ paddingTop:0, paddingLeft:0}}
                                  key="administrationSend"
                                  control={
                                      <Checkbox
                                          checked={this.state.workDetails.Trabajos[0].Envio_Administracion === 1}
                                          onChange={this.handleChangeFichaTrabajo("Envio_Administracion", true)}
                                          className="pl-1"
                                          value={this.state.workDetails.Trabajos[0].Envio_Administracion}
                                          color="primary" />
                                  }
                                  label={<Translate id="languages.fileUpload.administrationSend" />}
                                  disabled={work.SePuede_EditarEnvioAdministracion === 0 }                                  
                              />
                                }

                             {!isMobile_ &&  work.SePuede_EditarEnvioAdministracion === 1 && work.Administracion_Fecha_Descarga ==null && this.state.aclaraciones!=null &&                             
                                <IconButton   color="primary" aria-label="Info"   style={{paddingTop:2,marginLeft:-20,marginTop:0,marginBottom:0}} >
                                <Info onClick={()=>{this.handleShowDialog('Información',this.state.aclaraciones)}}></Info>
                                </IconButton>
                                }

                              {work.Administracion_Fecha_Descarga !=null ?
                              <Tooltip title={'Administracion confirma descarga de documentación de Traballo profesional a  ' + work.Administracion_Fecha_Descarga }>
                              <Typography variant="subtitle2" gutterBottom>
                                     {'Acceso administración: ' + work.Administracion_Fecha_Descarga.substring(0,10) }
                                </Typography>                               
                                </Tooltip>
                                : null}



                                          

                             
                          </Grid>

                              
                             
                                <Grid item   xs={!isMobile_ ? 8 : 12} style={{ paddingTop:6, paddingLeft:0}}>
                                    <RadioGroup  style={{ paddingTop:6, paddingLeft:0}}
                                        aria-label="Gender"
                                        name="gender1"
                                        className="flex-nowrap"
                                        value={this.state.workDetails.Trabajos[0].Es_Trabajo_Nuevo ? "Es_Trabajo_Nuevo" : (this.state.workDetails.Trabajos[0].Es_Trabajo_Modificado_Sustancial ? "Es_Trabajo_Modificado_Sustancial" : "Es_Trabajo_Modificado_Correcion_Basica")}
                                        onChange={this.handleChangeFichaTrabajoTipo()}
                                        row>
                                        <FormControlLabel  //style={ { width: "25%" }}
                                                            value="Es_Trabajo_Nuevo" 
                                                            disabled={this.state.workDetails.Trabajos[0].SePuede_EditarComoTrabajoNuevo == 0}
                                                          control={<Radio className="pt-0 pb-1" color={this.state.workDetails.Trabajos[0].Es_Trabajo_Nuevo ? "primary" : "secondary"} />}
                                                          label={<Translate id="languages.trabajo.nuevoTrabajoTitle" />}
                                                          className={`${this.state.workDetails.Trabajos[0].Es_Trabajo_Nuevo ? 'font-weight-bold' : ''} ${work.SePuede_EditarComoTrabajoNuevo === 0 && classes.disabledContainer} m-0 pt-0`}
                                        />
                                        <FormControlLabel //style={ { width: "30%" }}
                                            disabled={this.state.workDetails.Trabajos[0].SePuede_EditarComoTrabajoModificado == 0}
                                            value="Es_Trabajo_Modificado_Sustancial"                                            
                                            control={<Radio className="pt-0 pb-1" color={this.state.workDetails.Trabajos[0].Es_Trabajo_Modificado_Sustancial ? "primary" : "secondary"} />}
                                            label={<Translate id="languages.trabajo.modificacionSustancialTitle" />}
                                            className={`${this.state.workDetails.Trabajos[0].Es_Trabajo_Modificado_Sustancial ? 'font-weight-bold' : ''} m-0 pt-0`} />
                                        <FormControlLabel  //style={ { width: "25%" }}
                                            disabled={this.state.workDetails.Trabajos[0].SePuede_EditarComoTrabajoModificado == 0}
                                            value="Es_Trabajo_Modificado_Correcion_Basica"                                          
                                            control={<Radio className="pt-0 pb-1 " color={this.state.workDetails.Trabajos[0].Es_Trabajo_Modificado_Correcion_Basica ? "primary" : "secondary"} />}
                                            label={<Translate id="languages.trabajo.correccionBasicaTitle" />}
                                            className={`${this.state.workDetails.Trabajos[0].Es_Trabajo_Modificado_Correcion_Basica ? 'font-weight-bold' : ''} m-0 pt-0`} />
                                    </RadioGroup>
                                    
                                    </Grid>
                                    <Grid item xs={!isMobile_ ? 4 : 12}>
                                    <FormControlLabel //style={ { width: "50%" }} 
                                    style={!isMobile_ ? { paddingLeft:0} : {paddingTop:0, paddingLeft:20}}
                                        key="administrativeNotification"
                                        control={
                                            <Checkbox
                                                checked={this.state.workDetails.Trabajos[0].Es_Trabajo_Modificado_Requerido_Administracion === 1}
                                                onChange={this.handleChangeFichaTrabajo("Es_Trabajo_Modificado_Requerido_Administracion", true)}
                                                className="pl-1"
                                                value={this.state.workDetails.Trabajos[0].Es_Trabajo_Modificado_Requerido_Administracion}
                                                color="primary" />
                                        }
                                        label={<Translate id="languages.fileUpload.administrativeNotification" />}
                                        disabled={this.state.workDetails.Trabajos[0].Es_Trabajo_Nuevo === 1 || this.state.workDetails.Trabajos[0].SePuede_Editar !== 1 } />
                                 

                                </Grid>

                                
                            </Grid>

                        </Grid>
                        
                        <Grid item xs={12}>
                           
                        {/*}
                            <Grid item xs={8} className="py-3 px-4">
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            value={this.state.workDetails.Trabajos[0].Titulo_Complementario ? this.state.workDetails.Trabajos[0].Titulo_Complementario : ""}
                                            label={<Translate id="languages.fileUpload.complementaryTitle" />}
                                            className={`text-uppercase`}
                                            placeholder=""
                                            InputLabelProps={{ shrink: true }}
                                            onChange={this.handleChangeFichaTrabajo("Titulo_Complementario")}
                                            name="tituloComplementario" />
                                    </FormControl>
                                    </Grid>*/}
                              
                        </Grid>    



                   

                        <Grid item xs={12} className={classes.backgroundColor}>
                            <Grid container spacing={0} className="p-4">
                            <Grid item xs={4}>
                                    <Typography variant="subtitle1" gutterBottom className="m-0 text-uppercase"
                                        style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: "0.7rem" }}>
                                        <Translate id="languages.fileUpload.state" />
                                    </Typography>
                                    <div className='estados'>
                                        {this.estadoColor(this.state.workDetails.Trabajos[0].Estado ? this.state.workDetails.Trabajos[0].Estado : "")}
                                    </div>
                                </Grid>
                                
                            <Grid item xs={4}>
                                    <Typography variant="subtitle1" gutterBottom className="m-0 text-uppercase"
                                                style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: "0.7rem" }}>
                                        {this.state.workDetails.Trabajos[0].Presentado==1?<Translate id="languages.fileUpload.entryDate" />:<Translate id="languages.fichaExpediente.labelAltaDate" />}
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        {this.state.workDetails.Trabajos[0].Fecha_Entrada ? moment(new Date(this.state.workDetails.Trabajos[0].Fecha_Entrada)).format("DD/MM/YYYY") : ""}
                                    </Typography>
                                </Grid>
                            <Grid item xs={4}>
                                    <Typography variant="subtitle1" gutterBottom className="m-0 text-uppercase"
                                        style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: "0.7rem" }}>
                                        {this.state.workDetails.Trabajos[0].Presentado==1?this.state.workDetails.Trabajos[0].Id_Tipo_Tramite ==0  ?     
                                        <Translate id="languages.fileUpload.visaDate" /> :   <Translate id="languages.fileUpload.tramitacionDate" />:'' }
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        {this.state.workDetails.Trabajos[0].Fecha_Tramitacion ? moment(new Date(this.state.workDetails.Trabajos[0].Fecha_Tramitacion)).format("DD/MM/YYYY") : ""}
                                    </Typography>
                                </Grid>                                                 
                          
                            </Grid>
                        </Grid>

                                               
                     

                        {/* observaciones las quitamos por ahora
                        <Grid item xs={12} className={classes.backgroundColor}>
                            <Grid container spacing={0} className="p-2">
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            id="observations"
                                            label={<Translate id="languages.expedients.fieldObservaciones" />}
                                            value={this.state.workDetails.Trabajos[0].Observaciones ? this.state.workDetails.Trabajos[0].Observaciones : ''}
                                            onChange={this.handleChangeFichaTrabajo("Observaciones")}
                                            margin="normal"
                                            multiline
                                            rows={2}                                            
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    input: classes.textFieldInput
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onInput={(e) => {
                                                let aux = e.target.value;
                                                if (aux.length > 500) {
                                                    e.target.value = aux.slice(0, 500)
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                                        </Grid>*/}



                       {work.Id_Estado < 3 &&
                        <Grid item xs={12}>                                                                                               
                          
                          
                           <div  style={{ float:"left" ,marginRight:"auto", marginTop:1,margingLeft:20,paddingLeft:10 }} >        
                            {/*work.Id_Estado >= 3 && work.SePuede_EditarEnvioAdministracion == 1 && work.Envio_Administracion != 1 &&
                                
                                <Button
                                    color="primary" className={classes.button}  style={{paddingRight:8,paddingLeft:8 }}
                                    onClick={async () => { await this.putAutorizarEnvioAdmTrabajo(this.props.expediente.Expediente[0].Id_Expediente, this.props.trabajo) }}
                                    disabled={false} ><Translate id="languages.fileUpload.administrationSendButton" />
                                </Button>    
                                    */}
                             {/*work.Id_Estado >= 3 && work.SePuede_EditarEnvioAdministracion == 1 && work.Envio_Administracion != 1 && this.state.aclaraciones!=null &&                                
                                <Tooltip title={'Información'}>
                                <IconButton   color="primary" aria-label="Info"   style={{padding:5,marginLeft:-5 }} >
                                <Info onClick={()=>{this.handleShowDialog('Información',this.state.aclaraciones)}}></Info>
                                </IconButton>
                                </Tooltip>                                     
                            */}                                   
                             </div>                                                             
                           <div  style={{textAlign:"right",marginRight:5,marginTop:1} } className={""}>
                            <Button
                                color="primary" className={classes.button}
                                onClick={async () => { await this.loadGeneralInformation() }}
                                disabled={work.SePuede_Editar !== 1 || !this.state.changingWorkData} >Cancelar
                            </Button>

                                   <Button className={classes.button} type="submit" color="primary" 
                                   onClick={async () => { await this.putFichaTrabajo() }}                                

                                   disabled={!this.state.changingWorkData /* ||  work.SePuede_Editar !== 1  && this.state.loadingUpdateFichaTrabajo*/ }  > 
                                        <Translate id="languages.generalButton.generalButton" /> <Check />
                                    </Button>
                                    {this.state.loadingUpdateFichaTrabajo ? <CircularProgress size={24} /> : ""}
                            
                            </div>
                        </Grid>
                        }
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
    }
    render() {
        //let { classes } = this.props

        let isMobile_= isMobile();
        let isNotEncomenda=this.state.workDetails && this.state.workDetails.Trabajos[0].Id_Tipo_Trabajo!=ID_TRABAJO_ENCOMENDA;
        let notDigital=this.state.workDetails && (this.state.workDetails.Trabajos[0].Id_Tratamiento_Formato!=2)
        let isValGeo=this.state.workDetails && this.state.workDetails.Trabajos[0].Id_Tipo_Trabajo==ID_TRABAJO_VAL_GEOTECNICO;

        return (
            <div className="m-2">
                <Grid container spacing={!isMobile_ ? 16 : 6} >
                    <Grid item md={6} xs={12} className={!isMobile_ ? "p-3" : "p-0"}  >                    
                        {
                            this.state.fetching ?
                                <div className="text-center" style={{ widht: '100%' }}>
                                    <Grid item xs={12}>
                                    <CircularProgress />
                                    </Grid>
                                </div>
                                : <div >
                                   
                                    {
                                     this.props.trabajo >0  && isNotEncomenda == true && 
                                     <TemporalFolder 
                                        itemsDragging={this.props.itemsDragging} 
                                        dragging={(item) => { this.props.dragging(item) }} 
                                        
                                        expediente={this.props.expediente}                                        
                                        trabajo={this.props.trabajo}  
                                        dataFolderTemporal={this.props.dataFolderTemporal}                                          
                                        refreshEstadoVisualEstructuraAfterAsignacionAuto={() => this.props.refreshEstadoVisualEstructuraAfterAsignacionAuto()}  
                                        //collapseTemporalFolder={!this.state.workDetails || (this.state.workDetails && (this.state.workDetails.Trabajos[0].Tiene_Archivos==1 || this.state.workDetails.Trabajos[0].SePuede_Editar==0)) }                                                                                                                      
                                        collapseTemporalFolder={!this.state.workDetails || (this.state.workDetails && (this.props.tieneArchivos==1 || this.state.workDetails.Trabajos[0].SePuede_Editar==0)) }                                                                                                                      
                                        
                                        tempDragging={this.props.tempDragging}  
                                        onDropTemp={(acceptedFiles) => this.props.onDropTemp(acceptedFiles)}     
                                        onAsign={(files,carpetas,asignados,title)=>this.onAsign(files,carpetas,asignados,title)}                                         
                                        
                                         />}

                                  {     this.props.trabajo >0 && 

                                  
                                        <FilesInFolder2    onDragOver={this.props.dragOver(this.props.estructuraPermiteArchivos==1)}
                                        dragging={(item) => { this.props.dragging(item) }}
                                        itemsDragging={this.props.itemsDragging} 
                                        
                                        moveItemTo={(target) => this.props.moveItemTo(target)}
                                        moving={this.props.moving}    

                                        handleSaveAsign={(files) => this.props.handleSaveAsign(files)}
                                        savingAsign={this.props.savingAsign}
                                   
                                        handleSaveImport={(idTrabajoOr)=>this.props.handleSaveImport(idTrabajoOr)}
                                        handleImport={(geo)=>this.props.handleImport(geo)}                                        
                                        savingImport={this.props.savingImport}                                    
                                        openAsign={this.props.openAsign}
                                        handleOpenAsign={(open) => this.props.handleOpenAsign(open)}

                                        handleChangeEstructura={(idEstructura, titleEstructura) => {
                                            this.props.changeEstructura(idEstructura, titleEstructura)
                                        }}                                        
                                        
                                        expediente={this.props.expediente}
                                        
                                        trabajo={this.props.trabajo}
                                      
                                        notDigital={notDigital}
                                        
                                        estructura={this.props.estructura}                                                                                                                                             
                                        
                                        expandMenuTree={(trabajo, item) => this.props.expandMenuTree(trabajo, item, this.props.estructura)}                                        
                                        //fetchingRemove = {this.props.fetchingRemove}
                                        //showDeleteButton={this.props.showDeleteButton}
                                        //showDownloadButton={this.props.showDownloadButton}
                                        refreshEstadoVisualEstructuraAfterRemoveFolder2={(dataFolder) => this.props.refreshEstadoVisualEstructuraAfterRemoveFolder2(dataFolder)}

                                        onDrop={(acceptedFiles) => this.props.onDrop(acceptedFiles)}                                             
                                      
                                        dataFolder = {this.props.dataFolder}
                                        folders={this.props.folders}
                                        
                                        idEstructuraActivaBeforeDragging = {this.props.idEstructuraActivaBeforeDragging}  
                                        titleEstructuraActiva={this.props.titleEstructuraActiva}  
                                         
                                        workEditable = {this.state.workEditable}                                     
                                        
                                        //nivel={this.props.estructuraNivel}
                                        permiteArchivos={this.props.estructuraPermiteArchivos}
                                                                                
                                        estructuraEditable={this.props.estructuraEditable}
                                        estructuraIdDocumentacion={this.props.estructuraIdDocumentacion}

                                        fetchingData={this.props.fetchingData}  
                                       // startRemovingFilesFolder2={() => this.props.startRemovingFilesFolder2()}     
                                        mustRefresh={this.props.mustRefresh}       
                                        trabajosAbiertos={this.props.expediente.Trabajos.filter(x=>x.SePuede_Editar==1 && x.Id_Trabajo!=this.props.trabajo && x.Id_Tipo_Trabajo!=ID_TRABAJO_ENCOMENDA )}                           
                                        idTipoTrabajo=  {this.props.expediente.Trabajos.filter(x=> x.Id_Trabajo==this.props.trabajo)[0].Id_Tipo_Trabajo}   
                                        trabajosGeoRegistrado=  {this.props.expediente.Trabajos.filter(x=>x.Fecha_Tramitacion!=null &&  x.Id_Tipo_Trabajo==ID_TRABAJO_VAL_GEOTECNICO && x.Id_Trabajo<this.props.trabajo)}                                                    
                                    />
                                   }
                                </div>
                        }
                    </Grid>
                    {isMobile_ &&
                    <Grid item xs={12} className={"p-1 "}>
                        <p></p>
                    </Grid>
                    }
                    <Grid item md={6} xs={12} className={!isMobile_  ? "p-3" : "p-0 "}  onDragEnd={this.props.dragEnd()}   onDragOver={this.props.dragOver(false)}>

                    {/* { this.state.onAsign && this.asingFiles()
                        } */}

                    {
                            this.props.estructura  && this.props.folderInfo && !isMobile_ ?
                                this.renderFichaCarpeta()                                
                                : null                                                                   
                        }
                      
                      {this.props.estructura == false? null : <Grid item xs={12} className={"p-1 "}> </Grid> }
                      
                        {
                            this.state.workDetails && this.props.estructura == false && this.props.fetchingRefresh!=false ?
                                this.renderFichaTrabajo()
                                : null
                        }
                      
                        {
                            isNotEncomenda ?
                               this.renderDatosTecnicos()
                                : null
                        }
                         {
                         
                         isNotEncomenda && this.state.workDetails.Trabajos[0].Id_Estado!=0 && this.state.workDetails.Trabajos[0].Id_Estado!=11 ? 
                                    this.renderIncidencias():
                                    null                               
                        }
                        {
                           isNotEncomenda && this.state.workDetails.Trabajos[0].Id_Estado<3 ?
                                this.renderComunicacionVisado()
                                : null
                        }
                        {
                            isNotEncomenda ?
                                this.otrosAgentes()
                                : null
                        }                       
                         {
                            isNotEncomenda && !isValGeo? 
                                this.renderDatosEconomicos()
                                : null
                        }

                        {                  
                                    <Dialog
                                        open={this.state.dialogOpen}
                                        onClose={this.handleCloseDialog}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">                                    
                                        <DialogTitle id="alert-dialog-title">
                                            {this.state.dialogTitle}
                                        </DialogTitle>
                                        <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <ReactQuill value={this.state.dialogContent} readOnly theme='bubble' />
                                        </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                        <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
                                            <Translate id="languages.generalButton.aceptar"/>
                                        </Button>
                                        </DialogActions>
                                   </Dialog>
             
                                    }    

                      

                        {
                            this.props.fileUpload.uploadInProgress ?
                                <div style={{ marginTop: 20 }}>
                                    <Paper>
                                        <Grid container spacing={10}>
                                            <Grid item xs={8} className="p-1">
                                                <label style={{ fontSize: 12 }}>
                                                    <Translate id="languages.fileUpload.uploadingProgress" />
                                                    <b style={{ fontSize: 12 }}>
                                                    {this.props.fileUpload.currentUpload} de {this.props.fileUpload.uploadLength}</b>
                                                    {this.props.fileUpload.currentUploadItem ? ' - '+ this.props.fileUpload.currentUploadItem.filename : null}
                                                    </label>
                                                    
                                            </Grid>
                                            <Grid item xs={4} className="p-1"
                                                style={{ paddingRight: 12, paddingLeft: 0, textAlign: 'right' }}>
                                                <a onClick={() =>
                                                {

                                                    this.props.cancelUpload(true)
                                                    this.props.resetUploadStates()
                                                    this.props.fetchErrorExpediente('Subida cancelada');
                                                }} style={{
                                                    fontSize: 12,
                                                    textDecoration: 'underline',
                                                    color: '#2196f3'
                                                }}>Cancelar subida</a>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} className="p-2">
                                                <LinearProgress style={{ height: 20 }} variant="determinate"
                                                    value={this.props.fileUpload.currentUpload * 100 / this.props.fileUpload.uploadLength} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12} className="p-2">
                                               {/* <b style={{ fontSize: 12 }}>{this.props.fileUpload.currentUploadItem ? this.props.fileUpload.currentUploadItem.filename : null}</b>*/}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12} className="p-2">
                                                <ul style={{ listStyle: 'none', overflowX: 'hidden', fontSize: 12 }}>
                                                    {
                                                        this.props.fileUpload.pendingUploadList.map((item, pos) => {

                                                            return (<li>{item.filename}</li>)
                                                        })
                                                    }
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </div> : null
                        }

                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(TrabajoEjecucion)));
