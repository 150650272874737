import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import {traduccionGrid} from '../../helpers/traducciones';
import { goExpedientesUser } from '../../actions/usuarios/index';
import EstadoRenderer from '../Tramitaciones/EstadoRenderer';
import {Button} from "@material-ui/core";
import {getDefaultLanguage} from "../../api/index";

import {fetchErrorExpediente, formatMenssage} from "../../actions/expedientes";
import ApiCoag from "../../api/ApiCoag";
import moment from "moment";
import {isMobile} from "../../api/index";
import MapaBusqueda from './MapaBusqueda';
import ValidateAddressModal from '../Address/validateAddressModal';
import GeoRenderer from './GeoRenderer';
import FechaRenderer from './FechaRenderer';
import TituloTrabajoRenderer from './TituloTrabajoRenderer';
import {urlUbicacion} from "../../api/index";
import { withLocalize } from "react-localize-redux";
import Renuncias  from "./Renuncias";
import { CircularProgress  } from "@material-ui/core";




const styles = theme => ({
    appContainer: {
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
      padding: '20px'
    },
    listContainer: {
      width: '100%',
      height: 'calc(100% - 40px)',
      overflowY: 'auto',
      padding: '10px'
    },
});



  
class ModalDatos extends Component {
    constructor(props) {
        super(props);
        
     {
        this.state =  {
                        
            columnDefs: [],
            
            components: {
                rowNodeIdRenderer: function (params) {
                    return params.node.id + 1;
                }
            },           

            rowGroupPanelShow: "always",
            quickFilterText: null,
            paginationPageSize: 30,
            localeText: traduccionGrid,
            rowSelection: "multiple",
            frameworkComponents: {                
                geoRenderer: GeoRenderer,
                estadoRenderer: EstadoRenderer,
                fechaRenderer:FechaRenderer,
                tituloTrabajoRenderer:TituloTrabajoRenderer               
            },
            idExpedienteEnMapa:0,
            maxSelected:2
        };
        }



    }
    

    componentDidMount(){
        this.renderColumn()
    }
    // UNSAFE_componentWillMount() {
    //     this.renderColumn()
    // }
    //   UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    //       this.renderColumn(nextProps)
    //   }

     componentDidUpdate(prevProps, prevState) {
         if (prevProps !== this.props) {
             this.renderColumn(this.props);
         }
                
     }

   
 
 
    async renderColumn(props = false){
        let columnDefs=[];
        let idi=getDefaultLanguage();
        let fecha=(idi==2?"DATA":"FECHA")

        let maxtamWidth=1150;
        if (!this.props.isTrabajo)
        {
          
                columnDefs= [                                 
                    {headerName: "CÓDIGO", field: "Expediente_Codigo", width: this.props.divWidth>=maxtamWidth?118:106},                
                    {headerName: "PROMOTOR", field: "Promotor", width: this.props.divWidth>=maxtamWidth?314:214},                                
                    {headerName: "TÍTULO", field: "Titulo", width: this.props.divWidth>=maxtamWidth?314:234},
                    {headerName: "EMPRAZAMENTO", field: "Ubicacion",width: this.props.divWidth>=maxtamWidth?300:210,cellRenderer:'geoRenderer'},
                    {headerName: fecha, field: "Fecha_Entrada" , width: 78 ,cellRenderer: (data) => {return (data.value!=null? moment(data.value).format('DD/MM/YY') :null)}}  
                ]           
            
        }
        else
        {   /*trabajos*/       
            columnDefs= [                          
                {headerName: "CÓDIGO", field: "Expediente_Codigo", width: 112},                              
                {headerName: "PROMOTOR", field: "Promotor", width: this.props.divWidth>=maxtamWidth?330:250} ,         
                {headerName: "TÍTULO", field: "Titulo", width: this.props.divWidth>=maxtamWidth?440:288,cellRenderer:'tituloTrabajoRenderer'} ,    
                /*{headerName: " ESTADO", field: "Estado", width: 150,cellRenderer:'estadoRenderer'  }, */
                {headerName: 'DATA' , field: "Fecha_Entrada" , width: 78 ,cellRenderer: (data) => {return (data.value!=null? moment(data.value).format('DD/MM/YY') :null)}},            //, //'fechaRenderer' } //(data) => {return (data.value!=null? moment(data.value).format('DD/MM/YY') :'')}},
                {headerName: 'TRAMITADO', field: "Fecha_Tramitacion" , width: this.props.divWidth>=maxtamWidth?122:116 ,cellRenderer: (data) => {return (data.value!=null? moment(data.value).format('DD/MM/YY') :null)}}            //, //'fechaRenderer' } //(data) => {return (data.value!=null? moment(data.value).format('DD/MM/YY') :'')}},                                    
                /*{headerName: "TRAMITACIÓN", field: "Fecha_Tramitacion" , width: 128  ,'fechaRenderer'} //(data) => {return (data.value!=null? moment(data.value).format('DD/MM/YY') :'')}},*/
            ]                      
                                  
        }
       this.setState({columnDefs})
    }

    


    onGridReady(params) {
        this.gridApi = params.api        
        this.gridColumnApi = params.columnApi
    };

  
    async onSelectionChanged(params) {

        
         
        // Si se hace clic en una celda de la columna específica, deshabilitar el evento onSelectionChanged
       
        const selectedNodes = this.gridApi.getSelectedNodes();
        let selectedData=null;
        if (selectedNodes.length>0){         
            selectedData = selectedNodes.map(node => node.data);                
             this.props.handleRenunciar(true)
         }      
        else {
            this.setState({ mensaje:true}) 
        }

        this.setState({ renunciando:true,selectedData:selectedData}) 

    
}
       
       onCellClicked = (params) => {
    
        if (params && params.colDef) {
          const column = params.colDef.field;
         
         
          // Si se hace clic en una celda de la columna específica, deshabilitar el evento onSelectionChanged
          if (column === 'Ubicacion') {
            if (params.data.Latitud=='' || params.data.Latitud==null) {
                if (params.data.Hay_Tramitados!=null)
                    this.setState({ showGeo: true,idExpediente:params.data.Id_Expediente,idEmplazamiento:params.data.Id_Emplazamiento,expedienteCodigo:params.data.Expediente_Codigo });}
            else{                
            
              /*  if (params.data.Georeferencia!='' && params.data.Georeferencia!=null){
                    window.open(URL_CATASTRO +params.data.Georeferencia);
                }                */
                window.open(urlUbicacion(params.data.Latitud,params.data.Longitud));
            }

          }
          else{
            this.setState({  showGeo: false});           
            this.clikcRow()
          }
        }
      }
    

      async clikcRow() {
       
        let selectedRows = this.gridApi.getSelectedRows();
        let idExpediente = selectedRows[0].Id_Expediente;
        this.props.goExpedientesUser(); //para ocultar el modal de busqueda
        if(this.props.modal){
            if(this.props.isTrabajo){
                this.props.history.push("/visualizar-expediente/" + idExpediente + "/" + selectedRows[0].Id_Trabajo);
            }else
            {
                this.props.history.push("/visualizar-expediente/" + idExpediente);
            }
        }
        else {
            let idAccion = this.props.idAccion;
            switch (idAccion) {
                case 1:
                    this.props.history.push("/visualizar-expediente/" + idExpediente);
                    break;
                case 2:
                    await this.urlAccion(idExpediente,idAccion, 0)
                    break;
                case 3:
                    await this.urlAccion(idExpediente,idAccion, 0)
                    break;
                case 4:
                    await this.urlAccion(idExpediente,idAccion, 1)
                    break;
                case 5:
                    await this.urlAccion(idExpediente,idAccion, 0)
                    break;
                case 6:
                    await this.urlAccion(idExpediente,idAccion, 0)
                    break;
            }
        }
        
    }
   

    // async onSelectionChanged() {
       
    //     let selectedRows = this.gridApi.getSelectedRows();
    //     let idExpediente = selectedRows[0].Id_Expediente;
    //     this.props.goExpedientesUser(); //para ocultar el modal de busqueda
    //     if(this.props.modal){
    //         if(this.props.isTrabajo){
    //             this.props.history.push("/visualizar-expediente/" + idExpediente + "/" + selectedRows[0].Id_Trabajo);
    //         }else
    //         {
    //             this.props.history.push("/visualizar-expediente/" + idExpediente);
    //         }
    //     }
    //     else {
    //         let idAccion = this.props.idAccion;
    //         switch (idAccion) {
    //             case 1:
    //                 this.props.history.push("/visualizar-expediente/" + idExpediente);
    //                 break;
    //             case 2:
    //                 await this.urlAccion(idExpediente,idAccion, 0)
    //                 break;
    //             case 3:
    //                 await this.urlAccion(idExpediente,idAccion, 0)
    //                 break;
    //             case 4:
    //                 await this.urlAccion(idExpediente,idAccion, 1)
    //                 break;
    //             case 5:
    //                 await this.urlAccion(idExpediente,idAccion, 0)
    //                 break;
    //             case 6:
    //                 await this.urlAccion(idExpediente,idAccion, 0)
    //                 break;
    //         }
    //     }
       
    // }

    async urlAccion(id_expediente, id, ignorarObservaciones){
        try {
            let response = await ApiCoag.expedientes.postExpedienteAccion(id_expediente, id);
            if(id === 5){
                await this.props.history.push("/comunicacion/" + id_expediente );
            }
            else {
                if( response =='ERROR2') { //response.MensajesProcesado && response.MensajesProcesado.length > 0)

                }
                else {
                    if(id === 4){
                        await this.props.history.push("/visualizar-expediente/" + id_expediente + "/" + response.Trabajos[0].Id_Trabajo);
                    }else {
                        if(id === 2){
                            if(response.InfoAccion[0].Id_Asistente == 1){
                                await this.props.history.push("/crear-trabajo/" + id_expediente);
                            }else {
                                await this.props.history.push("/comunicacion/" + id_expediente );
                            }
                        }else {
                            if (response.InfoAccion!=null)                            
                            window.open(response.InfoAccion[0].Url, '_blank');
                        }
                    }

                }
            }
        }
        catch (e) {
            this.props.fetchErrorExpediente(formatMenssage(e.message));
        }
    }

    
    async handleLogEnMapa(idExp) {
        let response=null;
          
        let data = {
                 "Tipo": 101,
                 "Identificador": idExp,
                 "Descripcion": "Ir a Expediente"                  
             }     
        response=ApiCoag.usuario.postUsuarioLogAccion(data)                                
        }


    onBtExport() {
        var params = {
            columnGroups: true,
            allColumns: true,
            fileName: "export.csv",
        };       
        this.gridApi.exportDataAsCsv(params);
    };

  


    filtrado= (e)=> {
        this.setState({ quickFilterText: e.target.value });
    }


    async onClickExpedienteEnMapa  (idExpediente)   {
        if (idExpediente!=null)              
                {this.props.history.push("/visualizar-expediente/" + idExpediente);
                this.props.goExpedientesUser()   
                this.handleLogEnMapa(idExpediente) ;
       }
    }

          
    onClickTrabajoEnMapa(idExpediente ,idTrabajo) {
            this.props.history.push("/visualizar-expediente/" + idExpediente + "/" + idTrabajo);
            this.props.goExpedientesUser()          
     }   


     handleUpdateLocation(locationForm,coor,introUtm) {       
      }

     async handleSaveGeo() {       
        this.props.refreshData()
        this.setState({ showGeo:false,idExpediente:-1,idEmplazamiento:-1}) 
        
      }
          
  

  
    render(){        
            
        if (this.props.data!='') {          
         return this.renderGrid()        
        }

        if (this.state.renunciando){
            return this.renderRenunciar()
           
            return false
        }
    }


    renderRenunciar(){
       
        return (
           
            <div style={{ height: '100%'}}> {/* Contenedor de la lista con estilos CSS */}
              {this.state.selectedData && this.state.selectedData.map((fila, index) => (
                <Renuncias key={index} valor1={fila.Expediente_Codigo} valor4={fila.Ubicacion} valor3={fila.Promotor} valor2={fila.Titulo}/>
              ))}   
          
            </div>
          );
      }

    renderGrid() {

        //let { classes } = this.props;
     
        let mastam=0;
             
        if (this.props.divHeight>=1000 )
            mastam=1;
        
        if (this.props.divHeight>=1250 )
            mastam=2;
        

        let heightResults= mastam>0? (mastam>1?'945px' :'664px'):'525px';  
       

        return (
           <div
               className="ag-theme-balham"                           
               style={ {                                            
                    height:  heightResults, /*'519px',/*'376px',*/
                    width: '100%'                    
                }}
            >

          
            {this.props.tipoListado!='mapa' || this.props.isTrabajo? 
            //Se incluye este div para que en caso de que showLoadingSearch:true, por ahora solo si hay cambio de busqueda de expedientes a trabajo se muestre un circularprogress en el centro para evitar el efecto de carga de las columnas personalizadas con controles

            <div style={{ position: 'relative', height:  heightResults }} className="ag-theme-balham"  >
            { this.props.showLoadingSearch && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    zIndex: 1000,
                    width: '100%'     
                }}>
                    <CircularProgress />
                </div>
                )}
              <AgGridReact
                    columnDefs={this.state.columnDefs}
                    rowData={!this.props.loadingSearch?this.props.data ? this.props.data : []:[]}
                    //rowData={this.props.data ? this.props.data : []}

                    enableSorting={true}
                    enableFilter={true}
                    
                    frameworkComponents={this.state.frameworkComponents}

                    enableColResize={
                        true
                    }
                    suppressCsvExport={
                        false
                    }
                    showToolPanel={
                        true
                    }
                    pagination={
                        true
                    }
                    floatingFilter={false}

                    rowGroupPanelShow={
                        this.state.rowGroupPanelShow
                    }
                    localeText={
                        this.state.localeText
                    }
                    onGridReady={
                        this.onGridReady.bind(this)
                    }
                    rowSelection={this.state.rowSelection}
                    quickFilterText={this.state.quickFilterText}
                    //onSelectionChanged={this.onSelectionChanged.bind(this)} 
                    onCellClicked={this.onCellClicked}

                    paginationPageSize={this.state.paginationPageSize}
                                     
                >
                </AgGridReact> 
               </div> : <MapaBusqueda localizaciones={this.props.localizacionesMapa} 
                                               datosExp={this.props.datosMapa}                                                
                                               height={heightResults} 
                                               zoom={mastam>1?8.5:8.4} 
                                               onClickExpediente={(idExpediente)=>{this.onClickExpedienteEnMapa(idExpediente)}}  
                                               habilitarVerTrabajos={true}                                              
                                               onClickVerTrabajos={(idExpediente)=>{this.onClickVerTrabajosEnMapa(idExpediente)}}
                                               onClickTrabajo={(idExpediente,idTrabajo)=>{this.onClickTrabajoEnMapa(idExpediente,idTrabajo)}}                                                                                       
                                               />                                                                                            
                }

              

                  
                <div className="pt-0 text-left">
                    <Button   color="primary" onClick={this.onBtExport.bind(this)}>
                        Exportar a CSV
                    </Button>
                </div>         


                {this.state.showGeo && 
                 <ValidateAddressModal open={this.state.showGeo}   
                         idExpediente={this.state.idExpediente}         
                         idEmplazamiento={this.state.idEmplazamiento}     
                         expedienteCodigo={this.state.expedienteCodigo}           
                         updateLocation={(location,coor,introUtm) => { this.handleUpdateLocation(location,coor,introUtm) }}
                         location={this.state.location} 
                         key={100 }// this.state.location ? this.state.location.Id_Emplazamiento : 1} 
                         updateIsShowAddress={(showAddress) => {}}
                         onClickCancel={() => {this.setState({ showGeo:false});}}
                         saveGeo={() => {this.handleSaveGeo()  }}    
                         fromFichaExpediente={false} />
                }
                         
            </div>




        );
    }
}


const mapStateToProps = state => ({
    FiltroMuestra: state.user.filtroBusqueda ? state.user.filtroBusqueda :'',
    muestraFiltros: state.status.muestraFiltros ? state.status.muestraFiltros : '',
    modal: state.status.modal ? state.status.modal : '',
    idAccion: state.user.idAccion ? state.user.idAccion : 0,
  });

const mapDispatchToProps = {
    goExpedientesUser,
    fetchErrorExpediente
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(ModalDatos)));