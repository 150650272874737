export const FETCH_EXPEDIENTES_INIT = 'FETCH_EXPEDIENTES_INIT';
export const FETCH_EXPEDIENTES_SUCCESS = 'FETCH_EXPEDIENTES_SUCCESS';
export const FETCH_EXPEDIENTES_ERROR = 'FETCH_EXPEDIENTES_ERROR';
export const FETCH_UBICACION_SUCCESS = 'FETCH_UBICACION_SUCCESS';
export const FETCH_UBICACION_UPDATE = 'FETCH_UBICACION_UPDATE';
export const FETCH_EXPEDIENTE_SUCCESS = 'FETCH_EXPEDIENTE_SUCCESS';
export const FETCH_SAVE_ADRESS_TO_STORE = 'FETCH_SAVE_ADRESS_TO_STORE';
export const FETCH_EXPEDIENTSAVE_TO_STORE = 'FETCH_EXPEDIENTSAVE_TO_STORE';
export const FETCH_EXPEDIENTE_SUCCESS_EXP = 'FETCH_EXPEDIENTE_SUCCESS_EXP';
export const FETCH_SAVE_AGENTES_DATA = 'FETCH_SAVE_AGENTES_DATA';
export const FETCH_SAVE_TRABAJO_TO_STORE = 'FETCH_SAVE_TRABAJO_TO_STORE';
export const FETCH_DATAFORTREETRABAJO_SUCCESS = 'FETCH_DATAFORTREETRABAJO_SUCCESS';
export const SET_EXPEDIENTE_SELECTED = 'SET_EXPEDIENTE_SELECTED';
export const SET_EXPEDIENTE_SELECTED_DATOS = 'SET_EXPEDIENTE_SELECTED_DATOS';
export const SET_EXPEDIENTE_SELECTED_DATOS_TRABAJO = 'SET_EXPEDIENTE_SELECTED_DATOS_TRABAJO';
export const FETCH_SAVE_SELECTED_NODE_TO_STORE = 'FETCH_SAVE_SELECTED_NODE_TO_STORE';
export const FETCH_SAVE_SELECTED_EXP_TO_STORE = 'FETCH_SAVE_SELECTED_EXP_TO_STORE';

export const FETCH_SAVE_SELECTED_EXPEDIENTE_TO_STORE = 'FETCH_SAVE_SELECTED_EXPEDIENTE_TO_STORE';
export const CAMBIO_CONTENEDOR_CENTRAL = 'CAMBIO_CONTENEDOR_CENTRAL';
export const END_LOADING = 'END_LOADING';
export const ELIMINAR_TABLA = 'ELIMINAR_TABLA';
export const RESULTADOSBUSQUEDA = 'RESULTADOSBUSQUEDA';
export const FILTROBUSQUEDA = 'FILTROBUSQUEDA';
export const FILTROACCIONES = 'FILTROACCIONES';
export const SET_EXPEDIENTE_SELECTED_DATOS_TRABAJOFICHA = 'SET_EXPEDIENTE_SELECTED_DATOS_TRABAJOFICHA';
export const FETCH_EXPEDIENTE_TRABAJOS_EXP = 'FETCH_EXPEDIENTE_TRABAJOS_EXP';

export const LIMPIAR_BUSQUDA = 'LIMPIAR_BUSQUDA';
export const ADD_PROMOTOR = 'ADD_PROMOTOR';
export const DELETE_PROMOTOR = 'DELETE_PROMOTOR';
export const EDIT_PROMOTOR = 'EDIT_PROMOTOR';
export const ADD_TRABAJO_EXPEDIENTE = 'ADD_TRABAJO_EXPEDIENTE';
export const EDIT_EXPEDIENTE_EN_TRABAJO = 'EDIT_EXPEDIENTE_EN_TRABAJO';
export const ADD_AUTORIZACION_GRUPO_EXPEDIENTE = 'ADD_AUTORIZACION_GRUPO_EXPEDIENTE';

export const FETCH_SHOW_UPLOAD_FILES = 'FETCH_SHOW_UPLOAD_FILES';
export const FETCH_UPLOAD_FILES = 'FETCH_UPLOAD_FILES';
export const FETCH_FILES = 'FETCH_FILES';
export const FETCH_DONE = 'FETCH_DONE';
export const SHOW_UPLOAD = 'SHOW_UPLOAD';
export const HIDE_UPLOAD = 'HIDE_UPLOAD';
export const SET_FETCHING_DONE = 'SET_FETCHING_DONE';
export const CANCEL_UPLOAD = 'CANCEL_UPLOAD';
export const TABLE_PERSONALIZATION = 'TABLE_PERSONALIZATION';

export const AWAIT_CONFIRMATION = 'AWAIT_CONFIRMATION'








