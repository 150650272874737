import React, {Component} from "react";
import {
    Edit, Notifications, RemoveRedEye, FileCopy, Check, Close, CloudDownload,
    ArrowDownwardSharp, Input, EditSharp, Send, EditAttributes, RemoveRedEyeRounded, Payment,NotificationsNoneOutlined
} from '@material-ui/icons';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,

    DialogTitle,
    Grid,
    IconButton,
    Tooltip
} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles/index";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchErrorExpediente} from "../../actions/expedientes";
import Typography from "@material-ui/core/Typography";
import Textarea from "@material-ui/core/InputBase/Textarea";
import {Label} from "reactstrap";
import moment from "moment";
import Confirmation from "../Errors/confirmation";
import {Translate} from "react-localize-redux";
import ApiCoag from "../../api/ApiCoag";
import { getTrabajos } from '../../actions/usuarios/index';
import TramitacionUrgente from "../VisualizarExpediente/Trabajos/ProyectoEjecucion/tramitacionUrgente";






const styles = theme => ({
    buttonAction: {
        border: "2px solid",
        padding: "4px", margin: "4px"
    },
    buttonActionUrgencia: {  
        border: "2px solid",
        padding: "4px", margin: "4px",    
        color:'orange' 
    }  
});

class AccionRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accionName: this.props.value,
            idTrabajo: this.props.data.Id_Trabajo,
            fetchingRequest: [],
            expediente: null,
            trabajo: null,
            showModal: false,
            motivo: '',
            incidencias: [],
            modalIncidencias: false,
            modalConfirm: false,
        }
        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }


    async refreshTrabajos() {
        await this.props.getTrabajos();    
      }


    invokeParentMethod() {
        alert("Id del trabajo: " + this.state.idTrabajo);
        return;
    }

    getActions(stringActions) {
        return stringActions ? stringActions.split(",") : []
    }

    getWorkActions() {
        try {
            debugger;
            return this.props.accionesTrabajos.filter(x => x.Id_Expediente === this.props.data.Id_Expediente
                && x.Id_Trabajo === this.props.data.Id_Trabajo);
        } catch (e) {
            return [];
        }
    }

    /*renderActions = (action) => {

        let {classes} = this.props;
        switch (action.Id_Accion) {
            //Borrador
            case 0:
                return <span>
                    <Tooltip title={action.Nombre}>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <EditSharp/>
                        </IconButton>
                    </Tooltip>
                </span>;
            //En Tramitación - Presentado
            case 1:
                return <span>
                    <Tooltip title={action.Nombre}>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <NotificationsNoneOutlined/>
                        </IconButton>
                    </Tooltip>
                </span>;
            //En Tramitación - Pendente Modificacións
            case 2:
                return <span></span>;
            //Tramitado
            case 3:
                return <span>
                    <Tooltip title={action.Nombre}>
                        <IconButton disabled={false} className={classes.buttonAction} aria-label="View" color="primary">
                            <FileCopy/>
                        </IconButton>
                    </Tooltip>
                </span>;
            //Tramitado - Pendente retirada
            case 4:
                return <span>
                    <Tooltip title={action.Nombre}>
                        <IconButton disabled={false} className={classes.buttonAction} aria-label="View" color="primary">
                            <Input/>
                        </IconButton>
                    </Tooltip>
                </span>;
            //Tramitado - Retirado
            case 5:
                return <span></span>;
            case 6:
                return <span></span>;
            case 7:
                return <span></span>;
            case 8:
                return <span></span>;
            case 9:
                return <span></span>;
            //Entregar
            case 10:
                return <span>
                    <Tooltip title={action.Nombre}>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <NotificationsNoneOutlined/>
                        </IconButton>
                    </Tooltip>
                </span>;
            //Modificar entrega
            case 11:
                return <span>
                    <Tooltip title={action.Nombre}>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                </span>;
            //Solicitar modificar entrega
            case 12:
                return <span>
                    <Tooltip title={action.Nombre}>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <EditAttributes/>
                        </IconButton>
                    </Tooltip>
                </span>;
            //Retirar
            case 18:
                return <span>
                    <Tooltip title={action.Nombre}>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <Input/>
                        </IconButton>
                    </Tooltip>
                </span>;
            default:
                return <span></span>;
        }
    }*/

    editarTrabajo(expediente, trabajo) {
        this.props.history.push(`/visualizar-expediente/${expediente}/${trabajo}`);

    }

    // editarTrabajo = async (expediente, trabajo)=> {
    //     let result =await  ApiCoag.expedientes.getDatosGenerales(expediente);
    //    // this.props.history.push(`/visualizar-expediente/${expediente}/${trabajo}`);
    //
    // }

    retenerTrabajo = async (expediente, trabajo) => {
        let {fetchingRequest} = this.state;
        let code = `pt-${expediente}-${trabajo}`;
        fetchingRequest.push(code);
        this.setState({
            fetchingRequest
        })
        let result = await ApiCoag.expedientes.trabajo.autoretener(expediente, trabajo);
        fetchingRequest = this.state.fetchingRequest;
        let index = fetchingRequest.indexOf(code);
        if (index != -1) {
            fetchingRequest.splice(index, 1);
            this.setState({
                fetchingRequest
            })
        }
        // md eliminamos efecto de recargar todo
        //if (result.MensajesProcesado && result.MensajesProcesado.length == 1&&result.MensajesProcesado[0].IdTipo==3)
        if (result!='ERROR2')  {           
            // window.location.reload();
            this.refreshTrabajos();
        }

    }

    presentTrabajo = async (expediente, trabajo) => {
        let {fetchingRequest} = this.state;
        let code = `pt-${expediente}-${trabajo}`;
        fetchingRequest.push(code);

        this.setState({
            fetchingRequest
        })
        let result = await ApiCoag.expedientes.trabajo.cerrarTrabajo(expediente, trabajo);
        fetchingRequest = this.state.fetchingRequest;
        let index = fetchingRequest.indexOf(code);
        if (index != -1) {
            fetchingRequest.splice(index, 1);
            this.setState({
                fetchingRequest
            })

        }

            // md eliminamos efecto de recargar todo
            //if (result.MensajesProcesado && result.MensajesProcesado.length >= 0) {
            if (result!='ERROR2'){
                // this.props.fetchErrorExpediente(result);                
                // window.location.reload();
                this.refreshTrabajos();
            } 
        }

    
    retirar = async (expediente, trabajo) => {
        let {fetchingRequest} = this.state;
        let code = `pt-${expediente}-${trabajo}`;
        fetchingRequest.push(code);

        this.setState({
            fetchingRequest
        })
        let result = await ApiCoag.expedientes.trabajo.retirarExpediente(expediente, trabajo);
        fetchingRequest = this.state.fetchingRequest;
        let index = fetchingRequest.indexOf(code);
        if (index != -1) {
            fetchingRequest.splice(index, 1);
            this.setState({
                fetchingRequest
            })

        }
        if (result)
        {   
            this.download_file(result.Archivos[0].Url, result.Archivos[0].Nombre)
             
        }

      
        // md eliminamos efecto de recargar todo
        if (result!='ERROR2'){
            // this.props.fetchErrorExpediente(result);                
            // window.location.reload();
            this.refreshTrabajos();
         }      

    }
    pagar = async (expediente, trabajo, accion) => {
        let {fetchingRequest} = this.state;
        let code = `pt-${expediente}-${trabajo}`;
        fetchingRequest.push(code);

        this.setState({
            fetchingRequest
        })
        let result = await ApiCoag.expedientes.trabajo.datosEconomicos(expediente, trabajo);
        //Nota importante: desde la api el primer efecto tramitado tiene que ser el del usuario validado para abrir la url de pago suya, no la de un compañero
        if ( result != null && result.EfectosTramitados != null && result.EfectosTramitados[0].Url_Pago != null && result.EfectosTramitados[0].Url_Pago != '')
                window.open(result.EfectosTramitados[0].Url_Pago, '_blank');

        if ( result != null && result.EfectosTramitados != null && result.EfectosTramitados[0].Url_Pago != null && result.EfectosTramitados[0].Url_Pago == '' && result.EfectosTramitados.length>1 )
        { if(result.EfectosTramitados[1].Estatus =='A')        
           alert("El trabajo no figura preparado para retirar. Puedes consultar los pagos pendientes en los datos de facturación del trabajo. Si no figuran pagos pendientes en el mismo ponte en contacto con el Colexio para poder retirarlo")
           else
           alert("El trabajo no figura preparado para retirar. Puedes consultar los pagos pendientes en los datos de facturación del trabajo. Si no figuran pagos pendientes en el mismo ponte en contacto con el Colexio para poder retirarlo")
        }       
        
        

        fetchingRequest = this.state.fetchingRequest;
        let index = fetchingRequest.indexOf(code);
        if (index != -1) {
            fetchingRequest.splice(index, 1);
            this.setState({
                fetchingRequest
            })

        }        
        
    }

    async loadIncidencias(expediente, trabajo) {
        let {fetchingRequest} = this.state;
        let code = `pt-${expediente}-${trabajo}`;
        fetchingRequest.push(code);

        this.setState({
            fetchingRequest
        })
        let agentes = await ApiCoag.expedientes.trabajo.incidencias(expediente, trabajo);
        fetchingRequest = this.state.fetchingRequest;
        let index = fetchingRequest.indexOf(code);
        if (index != -1) {
            fetchingRequest.splice(index, 1);
            this.setState({
                fetchingRequest
            })

        }
        await this.setState({
            incidencias: agentes.Incidencias,
            modalIncidencias: true
        })
    }

    download_file(fileURL, fileName) {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
            save.download = fileName || filename;
            if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href;
                // window event not working here
            } else {
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
        }

        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    }


    workActionsByStatusWork() {
        let {classes} = this.props;
        switch (this.props.data.Id_Estado) {
            case 0://borrador
                return <span>
                    <Tooltip title='Editar trabajo'>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <EditSharp onClick={() => {
                                this.editarTrabajo(this.props.data.Id_Expediente, this.props.data.Id_Trabajo)
                            }}/>
                        </IconButton>
                    </Tooltip>
                    {
                        this.state.fetchingRequest.indexOf(`pt-${this.props.data.Id_Expediente}-${this.props.data.Id_Trabajo}`) == -1 ?
                            <Tooltip title='Presentar trabajo'>
                                <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                                    <Input onClick={() => {
                                        this.presentTrabajo(this.props.data.Id_Expediente, this.props.data.Id_Trabajo)
                                    }}/>
                                </IconButton>
                            </Tooltip>
                            :  <CircularProgress style={{width: 25,height:25,marginTop:12}}/>
                    }
                </span>;
                break;

            case 1://presentado
                if (this.state.fetchingRequest.indexOf(`pt-${this.props.data.Id_Expediente}-${this.props.data.Id_Trabajo}`) == -1) {
                    return <span>
                    <Tooltip title='Autoretener'>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <Close onClick={() => {
                                this.retenerTrabajo(this.props.data.Id_Expediente, this.props.data.Id_Trabajo)
                            }}/>
                        </IconButton>
                    </Tooltip>

                    
                    {
                        this.renderButtonUrgencia()                                                                                                                             
                     }    
                        
                   
                </span>
                } else {
                    return (<span>
                       <CircularProgress style={{width: 25,height:25,marginTop:12}}/>
                    </span>)
                }
                return;
                break;
            case 2://retenido
                if (this.state.fetchingRequest.indexOf(`pt-${this.props.data.Id_Expediente}-${this.props.data.Id_Trabajo}`) == -1) {
                    return <span>
                    {/*     <Tooltip title='Ver incidencias'>*/}
                        {/*    <IconButton className={classes.buttonAction} aria-label="View" color="primary">*/}
                        {/*        <Check onClick={() => {*/}
                        {/*            this.loadIncidencias(this.props.data.Id_Expediente, this.props.data.Id_Trabajo, `${this.props.data.Titulo_Expediente} ${this.props.data.Titulo_Trabajo}`)*/}
                        {/*        }}/>*/}
                        {/*    </IconButton>*/}
                        {/*</Tooltip>*/}
                        <Tooltip title='Editar trabajo'>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <EditSharp onClick={() => {
                                this.editarTrabajo(this.props.data.Id_Expediente, this.props.data.Id_Trabajo)
                            }}/>
                        </IconButton>
                    </Tooltip>
                        <Tooltip title='Presentar trabajo'>
                                <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                                    <Input onClick={() => {
                                        this.presentTrabajo(this.props.data.Id_Expediente, this.props.data.Id_Trabajo)
                                    }}/>
                                </IconButton>
                            </Tooltip>


                </span>
                } else {
                    return (<span>
                       <CircularProgress style={{width: 25,height:25,marginTop:12}}/>
                    </span>)
                }
                return;
                break;
            case 3://tramitado todo: poner icono si en "id_Accion" aparece un número y mandar a url de pago (ahora es la página del coag")
                if (this.state.fetchingRequest.indexOf(`pt-${this.props.data.Id_Expediente}-${this.props.data.Id_Trabajo}`) == -1) {
                    return <span>
                        {
                            this.props.data.Url_Accion && <Tooltip title='Pagar'>
                                <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                                    <Payment onClick={() => {
                                        this.pagar(this.props.data.Id_Expediente, this.props.data.Id_Trabajo, this.props.data.Url_Accion)
                                    }}/>
                                </IconButton>
                            </Tooltip>

                        }


                </span>
                } else {
                    return (<span>
                        <CircularProgress style={{width: 25,height:25,marginTop:12}}/>
                    </span>)
                }
                return;
                break;

            case 4://Retirado
                if (this.state.fetchingRequest.indexOf(`pt-${this.props.data.Id_Expediente}-${this.props.data.Id_Trabajo}`) == -1) {
                    return <span>
                    <Tooltip title='Retirar'>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <CloudDownload onClick={() => {
                                this.retirar(this.props.data.Id_Expediente, this.props.data.Id_Trabajo)
                            }}/>
                        </IconButton>
                    </Tooltip>

                </span>
                } else {
                    return (<span>
                       <CircularProgress style={{width: 25,height:25,marginTop:12}}/>
                    </span>)
                }
                break;
            case 11://En trámite
                if (this.state.fetchingRequest.indexOf(`pt-${this.props.data.Id_Expediente}-${this.props.data.Id_Trabajo}`) == -1) {
                    return <span>
                     <Tooltip title='Ver trabajo'>
                        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
                            <RemoveRedEye onClick={() => {
                                this.editarTrabajo(this.props.data.Id_Expediente, this.props.data.Id_Trabajo)
                            }}/>
                        </IconButton>
                    </Tooltip>
                    
                    {
                        this.renderButtonUrgencia()                                                                                                                             
                     }                  
                  

                </span>
                } else {
                    return (<span>
                       <CircularProgress style={{width: 25,height:25,marginTop:12}}/>
                    </span>)
                }
                break;


        }
    }

    modificarEstado = (propertyName) => event => {

        let estado = this.state;
        estado[propertyName] = event.target.value;

        this.setState(estado)


    }

       
    

    solicitarUrgencia(expediente, trabajo, titulo) {
        this.setState({
            expediente,
            trabajo,
            titulo,
            showModal: true
        })

    }

    aceptarSolicitarUrgencia() {         
        this.setState({showModal:false}) 
        this.refreshTrabajos() 
   }

   anularUrgencia() {         
    this.setState({showModal:false}) 
    this.refreshTrabajos() 
    }

    detalles() {

        if (this.state.showModal) {
         
            return (<TramitacionUrgente showModal={this.state.showModal}  
            idExpediente=  {this.state.expediente}                                              
            idTrabajo= {this.state.trabajo}
            titulo={this.state.titulo}
            solicitar={() => this.aceptarSolicitarUrgencia()}
            anular={() => this.anularUrgencia()}
            cancelar={() => this.setState({showModal:false}) } />
            )
           
        }      

    }

    renderButtonUrgencia(){
        let {classes} = this.props;
       return (this.props.data.Tramitacion_Urgente==1 ?
        <Tooltip title='Tramitación urgente solicitada'>
        <IconButton className={classes.buttonActionUrgencia} aria-label="View" color="primary">        
            <Notifications onClick={() => {
                this.solicitarUrgencia(this.props.data.Id_Expediente, this.props.data.Id_Trabajo,`${this.props.data.Expediente_Codigo} - ${this.props.data.Titulo_Trabajo} `) 
                                       }}/>
        </IconButton>
        </Tooltip>:
          <Tooltip title='Solicitar tramitación urgente'>
        <IconButton className={classes.buttonAction} aria-label="View" color="primary">
            <NotificationsNoneOutlined onClick={() => {
                this.solicitarUrgencia(this.props.data.Id_Expediente, this.props.data.Id_Trabajo,`${this.props.data.Expediente_Codigo} - ${this.props.data.Titulo_Trabajo} `) 
                                       }}/>
        </IconButton> 
        </Tooltip>                                                                                                                                                                              
       ) 
    }

    modalIncidencias() {

        if (this.state.modalIncidencias) {
            let {incidencias} = this.state
            return (<div>
                <Dialog open={this.state.modalIncidencias}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Incidencias
                    </DialogTitle>

                    <DialogContent>
                        {
                            incidencias.length == 0 ?
                                <Grid container spacing={8}>
                                    <Grid item xs={12}>
                                        <Typography variant={"body1"} style={{textAlign: 'center'}}>No hay
                                            incidencias</Typography>
                                    </Grid>
                                </Grid> :
                                <Grid container spacing={8}>

                                    {
                                        incidencias.map((resp, p) => (
                                            <Grid item md={12} style={{borderBottom: '1px solid #cecece'}}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Label style={{marginTop: 10, marginBottom: 0}}>No.</Label>
                                                        <Typography variant={"body1"}>{resp.Num_Incidencia}</Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Label style={{marginTop: 10, marginBottom: 0}}>Tipo</Label>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Label style={{marginTop: 10, marginBottom: 0}}>Texto</Label>
                                                        <div
                                                            style={{
                                                                textAlign: "justify",
                                                                color: 'rgba(0, 0, 0, 0.87)',
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                fontFamily: 'Montserrat',
                                                                lineHeight: '1.46429em',
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: resp.Texto,

                                                            }}></div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Label style={{marginTop: 10, marginBottom: 0}}>Fecha
                                                            Alta</Label>
                                                        <Typography
                                                            variant={"body1"}>{moment(new Date(resp.Fecha_Alta)).format("DD/MM/YYYY")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Label style={{marginTop: 10, marginBottom: 0}}>Fecha
                                                            Baja</Label>
                                                        <Typography
                                                            variant={"body1"}>{resp.Fecha_Baja ? moment(new Date(resp.Fecha_Baja)).format("DD/MM/YYYY") : '-'}</Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>


                        }


                    </DialogContent>

                    <DialogActions>
                        <Button onClick={async () => {
                            this.setState({modalIncidencias: false});

                        }} variant="contained"
                                color="primary" autoFocus style={{height: 30, minHeight: 30}}
                                className="p-0">
                            Aceptar

                        </Button>

                    </DialogActions>
                </Dialog>
            </div>);
        }

    }

    renderConfirmation() {
        return (this.state.openConfirmationWork
                ? <Confirmation openConfirmation={this.state.openConfirmationWork}
                                confirmationMessage={<Translate id="languages.messages.workConfirmation"/>}
                                aceptConfirmation={async () => {
                                    await this.deleteTrabajoExpediente();
                                    this.setState({openConfirmationWork: false})
                                }}
                                declineConfirmation={() => this.setState({openConfirmationWork: false})}/>
                : this.state.openConfirmationExpedient
                    ? <Confirmation openConfirmation={this.state.openConfirmationExpedient}
                                    confirmationMessage={<Translate id="languages.messages.expedientConfirmation"/>}
                                    aceptConfirmation={async () => {
                                        await this.deleteExpedient();
                                        this.setState({openConfirmationExpedient: false});
                                    }}
                                    declineConfirmation={() => this.setState({openConfirmationExpedient: false})}/>
                    : <div></div>
        );
    }

    
    render() {

        return (
            <div>
                {this.workActionsByStatusWork()}
                {
                    this.detalles()
                }
                {
                    this.modalIncidencias()
                }

            </div>
        );
    }
};

const mapStateToProps = state => ({
    accionesTrabajos: state.user.ultimostrabajos ? state.user.ultimostrabajos.Acciones : [],
});
const mapDispatchToProps = {
    fetchErrorExpediente: fetchErrorExpediente,
    getTrabajos
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccionRenderer)));