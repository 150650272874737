import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import {traduccionGrid} from '../../helpers/traducciones';
import { goExpedientesUser } from '../../actions/usuarios/index';
import {getDefaultLanguage} from "../../api/index";
import {fetchErrorExpediente} from "../../actions/expedientes";
import moment from "moment";
import GeoRenderer from './GeoRenderer';
import FechaRenderer from './FechaRenderer';
import { withLocalize } from "react-localize-redux";
import {urlUbicacion} from "../../api/index";
import Renuncias  from "./Renuncias";
import Confirmation  from "../Errors/confirmation";
import {Divider, Typography,  Button, CircularProgress  } from "@material-ui/core";
import ApiCoag from "../../api/ApiCoag";
import { Translate } from "react-localize-redux";

  
class ModalDatosRenuncias extends Component {
    constructor(props) {
        super(props);
        
     {
        this.state =  {                        
            columnDefs: [],            
            components: {
                rowNodeIdRenderer: function (params) {
                    return params.node.id + 1;
                }
            },           
            rowGroupPanelShow: "always",
            quickFilterText: null,
            paginationPageSize: 10,
            localeText: traduccionGrid,
            rowSelection: "multiple",
            frameworkComponents: {                
                geoRenderer: GeoRenderer,                
                fechaRenderer:FechaRenderer,                
            } ,     
            selectedData:[]
         
        };        
        }

           }
    

    componentDidMount(){
        this.renderColumn()
    }
    //   UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    //       this.renderColumn(nextProps)
    //   }

      onGridReady(params) {
        this.gridApi = params.api        
        this.gridColumnApi = params.columnApi           
    };

      
    async renderColumn(){
        let columnDefs=[];
        let idi=getDefaultLanguage();
        let fecha=(idi==2?"DATA":"FECHA")

        let maxtamWidth=1150;
    
            /*expedientes*/
            columnDefs= [  
                {   headerCheckboxSelection: false,
                    checkboxSelection: true,
                    headerName: '',
                    field: 'checkbox',
                    width: 30,
                 },                
                {headerName: "CÓDIGO", field: "Expediente_Codigo", width: this.props.divWidth>=maxtamWidth?118:106},                
                {headerName: "PROMOTOR", field: "Promotor", width: this.props.divWidth>=maxtamWidth?314:214},                                
                {headerName: "TÍTULO", field: "Titulo", width: this.props.divWidth>=maxtamWidth?314:234},
                {headerName: "EMPRAZAMENTO", field: "Ubicacion",width: this.props.divWidth>=maxtamWidth?300:210,cellRenderer:'geoRenderer',
                    cellRendererParams: {ocultarGeolocalizacion: true}
                },
                {headerName: fecha, field: "Fecha_Entrada" , width: 78 ,cellRenderer: (data) => {return (data.value!=null? moment(data.value).format('DD/MM/YY') :null)}}                                                   
               /* {headerName: "EMPLAZAMIENTO", field: "Emplazamiento", width: 148},*/         
              ]
                     
     
       this.setState({columnDefs})
    }

    



    onCellClicked = (params) => {
    
        if (params && params.colDef) {
          const column = params.colDef.field;         
         
       
          if (column === 'Ubicacion') {
            if (params.data.Latitud=='' || params.data.Latitud==null) {
               return false;
            }
            else{               
                    
                window.open(urlUbicacion(params.data.Latitud,params.data.Longitud));
            }

          }   
          
          
        }
      }
    

  
    async onSelectionChanged(params) {
                 
        // Si se hace clic en una celda de la columna específica, deshabilitar el evento onSelectionChanged

       
       if (this.state.deleting){
        this.setState({deleting:false}) 
       }
       else
        {const selectedNodes = this.gridApi.getSelectedNodes();
        let selectedDataGrid=null;
        if (selectedNodes.length>0){      
            if (selectedNodes.length<=5){
                selectedDataGrid= selectedNodes.map(node => node.data);                 
                selectedDataGrid=this.compareAndUpdateSelectedData(this.state.selectedData,selectedDataGrid)

            }      
            else{
                this.setState({mensajeRenunciasNumero:true})                    
                const lastSelectedNode = selectedNodes[selectedNodes.length - 1];
                lastSelectedNode.setSelected(false);        
                //this.deselectRowById(id) //ojo,esto llama al RadioChange del grid y se carga selectedValue, de ahí el estado deleting
                return null;
            }
        }
        else {
            selectedDataGrid=[];                       
             }

        this.setState({selectedData:selectedDataGrid}) 

       
        }
        //this.props.seleccionRenuncias(selectedData)  
    }


    handleOnRadioChange = (id, value) => {
        this.setState((prevState) => ({
          selectedData: prevState.selectedData.map((item) =>
            item.Id_Expediente === id ? { ...item, selectedValue: value } : item
          )
        }));    
     
      };

      compareAndUpdateSelectedData = (selectedData, selectedDataGrid) => {
        const updatedSelectedData = selectedDataGrid.map(gridItem => {
            const matchingItem = selectedData.find(item => item.Id_Expediente === gridItem.Id_Expediente);
            if (matchingItem) {
                return { ...gridItem, selectedValue: matchingItem.selectedValue };
            } else {
                return gridItem;
            }
        });
        return updatedSelectedData;
    };

  
    filtrado= (e)=> {
        this.setState({ quickFilterText: e.target.value });
    }


    
async handleLimpiar() {
    this.setState({selectedData:[]}); 
    this.deselectAllRow() 
}


  handleDelete = (id) => {
    this.setState({ deleting:true });
    let newdata=[];    
    newdata=[...this.state.selectedData];   
  
    
    this.setState({selectedData:newdata.filter((item) => item.Id_Expediente !== id)});
    /*this.setState((prevState) => ({
      selectedData: prevState.selectedData.filter((item) => item.Id_Expediente !== id)
    }));     */

    this.deselectRowById(id) //ojo,esto llama al RadioChange del grid y se carga selectedValue, de ahí el estado deleting
    
    
  };

  

  deselectRowById = IdExpediente => {
    if (this.gridApi) {        
        this.gridApi.forEachNode(node => {           
            if (node.data.Id_Expediente === IdExpediente) {               
                node.setSelected(false);
            }
        });

    } else {
        console.error('Grid API is not ready.');
    }
}

deselectAllRow  () {
    if (this.gridApi) {        
        this.gridApi.forEachNode(node => {                    
                node.setSelected(false);         
        });

    } else {
        console.error('Grid API is not ready.');
    }
}


async handleConfirmarRenunciar() {
   
    let selectedData = this.state.selectedData.map((item) => ({
        Id_Expediente: item.Id_Expediente,
        Obras_Realizadas: item.selectedValue!=null?item.selectedValue:0
      }));


      if (selectedData.filter((item) => item.Obras_Realizadas==0).length>0)
        {this.setState({mensajeRenunciasIncompletas:true})} 
      else
        {this.setState({mensajeRenunciasDeclaracion:true})}                           
}

async handleRenunciar() {
    let selectedData = this.state.selectedData.map((item) => ({
        Id_Expediente: item.Id_Expediente,
        Obras_Realizadas: item.selectedValue!=null?item.selectedValue:0,
        Expediente_Codigo:item.Expediente_Codigo,
        Titulo:item.Titulo,
        Promotor:item.Promotor,
        Ubicacion:item.Ubicacion,
        Fecha:item.Fecha_Entrada        
      }));



    this.setState({ isLoadingSave: true });
    await this.handleLanzarRenuncias(selectedData)
    this.setState({ isLoadingSave: false });
}



  async handleLanzarRenuncias(selectedData) {
    
    let renuncias = {        
        'Renuncias': selectedData}
        
    let response = await ApiCoag.expedientes.postExpedientesRenuncias(renuncias);
  
  
    if (response =='ERROR2'){//(response && response.MensajesProcesado && response.MensajesProcesado.length > 0) {
    //  this.props.fetchErrorExpediente(response.data);        
    }
    else {   
        this.handleLimpiar();               
        this.props.refreshData();  
        this.props.mostrarRenunciasResumen(true,response);         
    }
  }


  async onClickExpediente  (idExpediente)   {
    
    if (idExpediente!=null)              
            {
            this.props.history.push("/visualizar-expediente/" + idExpediente);
            this.props.goExpedientesUser()              
            }
}


onBtExport() {
    var params = {
        columnGroups: true,
        allColumns: true,
        fileName: "export.csv",
    };       
    this.gridApi.exportDataAsCsv(params);
};


/*async onClickExpediente(idExpediente) {
    if (idExpediente != null) {
        // Abrir la URL en una nueva pestaña
        window.open(`http://serviciosdev.coag.es/visualizar-expediente/${idExpediente}`, '_blank');        
    }
}*/

      
    render(){        
        
      
        if (this.props.data!='' ) {   
            return (
            <div>


        {this.renderGrid()}        

        <div className="pt-0 text-right">
        <Button   color="primary" onClick={this.onBtExport.bind(this)}>
            Exportar a CSV
        </Button>
        </div>   

         {this.renderRenunciasSeleccion()} 

          

         {this.state.mensajeRenunciasIncompletas &&
            <Confirmation openConfirmation={this.state.mensajeRenunciasIncompletas}
            title={'Renuncias' }
            onlyAccept={true}
            confirmationMessage={'Debe indicar estado de obra de todo expediente seleccionado'}
            acceptCancel={false}
            aceptConfirmation={async () => {                                                    
                this.setState({mensajeRenunciasIncompletas: false}) 
            }}/>}

            
         {this.state.mensajeRenunciasDeclaracion &&
            <Confirmation openConfirmation={this.state.mensajeRenunciasDeclaracion}
            title={'' }           
            acceptCancel={true}
            confirmationMessage={'Renuncias'}        
            botonConfirmarTexto={'Continuar'}          
            confirmationCheckItem={true}            
            confirmationCheckItemText={<Translate id="languages.messages.renunciasVoluntariasConfirmacion"/>}
            declineConfirmation={() => this.setState({mensajeRenunciasDeclaracion: false})}            
            aceptConfirmation={async () => {   
                this.setState({mensajeRenunciasDeclaracion: false}) 
                this.handleRenunciar()                     
            }}/>}

        {this.state.mensajeRenunciasNumero &&
            <Confirmation openConfirmation={this.state.mensajeRenunciasNumero}
            title={'Renuncias' }
            onlyAccept={true}
            confirmationMessage={'Número máximo de solicitudes de renuncia simultaneas alcanzado (5)'}
            acceptCancel={false}
            aceptConfirmation={async () => {                                                    
                this.setState({mensajeRenunciasNumero: false}) 
            }}/>}



                        
    
            </div>
        )
        
        }             
    }


 
    renderGrid() {

        //let { classes } = this.props;    
           
        let heightResults= '360px';              

        
        return (
           <div
               className="ag-theme-balham"                           
               style={ {                                            
                    height:  heightResults, /*'519px',/*'376px',*/
                    width: '100%'                    
                }}
            >
          
              <AgGridReact 
                    columnDefs={this.state.columnDefs}
                    rowData={this.props.data? this.props.data: []}
                    enableSorting={true}
                    enableFilter={true}                    
                    frameworkComponents={this.state.frameworkComponents}
                    enableColResize={true}
                    suppressCsvExport={false}
                    showToolPanel={true}
                    pagination={true}
                    floatingFilter={false}
                    rowGroupPanelShow={this.state.rowGroupPanelShow}
                    localeText={this.state.localeText}
                    onGridReady={this.onGridReady.bind(this)}
                    rowSelection={this.state.rowSelection}
                    quickFilterText={this.state.quickFilterText}
                    onSelectionChanged={this.onSelectionChanged.bind(this)} 
                    onCellClicked={this.onCellClicked}
                    paginationPageSize={this.state.paginationPageSize}      
                    suppressRowClickSelection={true}      
                    
                />               


   


             </div>

        );
    }

    

    renderRenunciasSeleccion(){
        let classes=this.props;
     return (
         <div> 
         <div       > {/* Contenedor de la lista con estilos CSS */}
         {!this.state.isLoadingSave && <Col xs={12}>
                 <Row>
                     <Col xs={12} className="d-flex">
                         <Typography variant="subtitle1" gutterBottom className="m-0">
                             { 'Expedientes seleccionados para renunciar'}
                         </Typography>
                         <Typography variant="subtitle1" gutterBottom color="primary" >
                             {' ('+this.state.selectedData.length+')'}
                         </Typography>                                           
                     </Col>
                 </Row>
                 <Divider />
                 <Row>
                     <Col xs={12} className="pb-3">
                         <Typography variant="subtitle2" gutterBottom className="pt-3" >
                             { this.state.selectedData.length>0?<Translate id="languages.messages.renunciasVoluntariasExp"/>
                                                         :null}
                         </Typography>                        
                     </Col>
                 </Row>
             </Col>}
            
             {/*<pre>{JSON.stringify(this.state.selectedData, null, 2)}</pre> */ }
        
           {!this.state.isLoadingSave && this.state.selectedData && this.state.selectedData.map((fila, index) => (
             <Renuncias key={fila.Id_Expediente} 
             valor0={fila.Id_Expediente} valor1={fila.Expediente_Codigo} valor4={fila.Ubicacion} valor3={fila.Promotor} valor2={fila.Titulo} formato={fila.Id_Tratamiento_Formato}
             onRadioChange={(value)=>this.handleOnRadioChange(fila.Id_Expediente,value)} onDelete={() => this.handleDelete(fila.Id_Expediente)}
             onClickExpediente={()=>{this.onClickExpediente(fila.Id_Expediente)}}  
             /> // Propagación de la función onDelete/>          
           ))}   
 
           <br></br>
           </div>
           <div  className={"pt-2 text-center"} >
             {this.state.selectedData.length>0 && !this.state.isLoadingSave && <Button style={{ marginRight: '10px'}}  variant="contained"  color="primary" onClick={()=>this.handleLimpiar()}>Borrar selección</Button> }                                  
      
             {this.state.selectedData.length>0 && this.state.isLoadingSave?  <Typography variant="subtitle2" gutterBottom className="pt-3" >Solicitando. Este proceso demorará en función de número de renuncias solicitadas</Typography> : null }

             {this.state.selectedData.length>0 ? (this.state.isLoadingSave?  <CircularProgress size={30} style={{marginLet: 10 ,marginTop: 2}}/> : <Button style={{ marginLeft: '10px'}}    variant="contained" color="primary" onClick={()=>this.handleConfirmarRenunciar()}>Solicitar renuncias</Button>) :  null }
                            
             <br></br>
             <br></br>
           </div>
           </div>
       
         
       );
   }


 
}




const mapStateToProps = state => ({
    FiltroMuestra: state.user.filtroBusqueda ? state.user.filtroBusqueda :'',
    muestraFiltros: state.status.muestraFiltros ? state.status.muestraFiltros : '',
    modal: state.status.modal ? state.status.modal : '',
    idAccion: state.user.idAccion ? state.user.idAccion : 0,
  });

const mapDispatchToProps = {
    goExpedientesUser,
    fetchErrorExpediente
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(ModalDatosRenuncias)));