import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Grid,
    TextField,
    FormControl,
    Tooltip,
    CircularProgress,
    Fab,
    Select,
    MenuItem ,IconButton  
} from '@material-ui/core';
import {Check} from '@material-ui/icons';
import {fetchErrorExpediente, formatMenssage} from '../../actions/expedientes';
import {connect} from 'react-redux';
import {withLocalize} from "react-localize-redux";
import {Translate} from "react-localize-redux";
import {imgCatastro,urlUbicacion} from "../../api/index";
import { LocationOn } from '@material-ui/icons';
import ApiCoag from "../../api/ApiCoag";
import Confirmation from "../Errors/confirmation"



const styles = theme => ({
    paper: {
        padding: 24,
        width: "100%"
    },
    margin: {
        marginTop: 30
    },
    formControl1: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        width: "60%"
    },
    formControl2: {
        width: 150, marginRight: 16
    },
    formControl: {
        width: "100%"
    },
    textFieldInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
    divider: {
        [theme.breakpoints.up('sm')]: {
            borderRight: "1px solid #ced4da"
        },

    },
    button: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    close: {
        padding: theme.spacing.unit / 2,
    },
});

const mapStateToProps = (state) => (
    {}
);

const mapDispatchToProps =
    {
        fetchErrorExpediente: fetchErrorExpediente
    };


class ValidateAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: null,
            alias: '',
            manualAdd: false,
            catastro: [],
            linksMaps: [],
            locations: [],
            emplacement: [],
            AreasGeograficasPaises: [],
            AreasGeograficasAutonomias: [],
            AreasGeograficasProvincias: [],
            AreasGeograficasConcellos: [],
            isValidating: false,
            idValidated:false,
            isShowAddress: false,
            alert: false,
            isSave: false,
            data: {},
            locationForm:null
        };

    };

    componentDidMount() {

        if (this.props.isShowAddress){
            this.cargaListasEmp()
        }      

        let locationIni=this.getLocationIni()

       this.setState({            
            location:locationIni,
            isValidated:locationIni!=null?true:false,
            isShowAddress: false,
            manualAdd:this.existsManualAdd()        
        });

     

    }

    getLocationIni(){       
        let introUTM=this.props.location && this.props.location.Intro_UTM
        let cutm='';
        let cpolares='';
        if (introUTM==='1') {
            cutm=this.props.location.UTM_X.replace(",",".") + ', '+ this.props.location.UTM_Y.replace(",",".")+' '+(this.props.location.UTM_H!=29?this.props.location.UTM_H:'');        
            
            return cutm
        }
        if (introUTM==='0')   { 
            cpolares=this.props.location.Latitud.replace(",",".") + ', '+ this.props.location.Longitud.replace(",",".")                  
            return cpolares
        }   
        
        return this.props.location && this.props.location.Georeferencia ?this.props.location.Georeferencia:null
    }

    existsManualAdd(){       
        let introUTM=this.props.location && this.props.location.Intro_UTM     
        if (introUTM==='1' || introUTM==='0') 
            return true
        else    
            return false                 
    }

    handleChange = name => event => {
        if (name != 'locationForm')
        {
            this.setState({
                [name]: event.target.value
            });
        }
        else
        {
            this.setState({
            [name]: event.target.value,data:{},
            isValidated:false,
            manualAdd:false,
            latitud:null,
            longitud:null
            });
            this.props.updateNoLocation();        /* tras editar referencia o coor el emplazamiento se elimina hasta que se valida refer o coor de nuevo*/   
        }
        if (name === "alias") {
            this.handleChangeAddress("Alias", event);
        }
    };

    /* md intro en georeferencia*/
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleValidateAddress()
        }
    }

    
    handleChangeAddress(name, event) {
        /*md a los autonomias hai que pasar el pais */
        let data = {};
        let aux = this.props.isShowAddress ? this.props.location : this.state.data;
        Object.assign(data, aux);
        data[name] = event.target.value;
             
       
        data['Latitud'] = this.state.latitud?this.state.latitud:aux.Latitud;
        data['Longitud'] = this.state.longitud?this.state.longitud:aux.Longitud;
        data['UTM_X'] = this.state.utmx?this.state.utmx:aux.UTM_X;
        data['UTM_Y'] = this.state.utmy?this.state.utmy:aux.UTM_Y;
        data['UTM_H'] = this.state.utmh?this.state.utmh:aux.UTM_H;
        data['Intro_UTM'] = this.state.introUtm?this.state.introUtm:aux.Intro_UTM;
        if  (data['Intro_UTM']==null)
            data['Georeferencia'] = this.state.locationForm != null ? this.state.locationForm :this.state.location;
              
        this.setState({data: data});
        switch (name) {
            case 'Pais':
                this.autonomias(event.target.value);
                data['Id_Pais']=event.target.value                  
                this.setState({data: data});
                 break;
            case 'Autonomia':
                this.provincias(event.target.value)
                data['Id_Autonomia']=event.target.value
                this.setState({data: data});
                break;
            case 'Provincia':
                this.concellos(event.target.value)
                data['Id_Provincia']=event.target.value
                this.setState({data: data});
                break;    
             case 'Concello':
                data['Id_Concello'] = event.target.value;    
                let concello=      this.state.AreasGeograficasConcellos.find(x => x.Id_Area === parseInt(event.target.value))  /* Necesario para alta expediente*/           
                data['Concello'] = concello['Nombre']
                this.setState({data: data});
                break;  
        }

        if (data['Piso'] == undefined)
            data['Piso'] ='';
        if (data['Numero'] == undefined)
            data['Numero'] ='';
        if (data['Codigo_Postal'] == undefined)    
            data['Codigo_Postal']='';
        if (data['Alias'] == undefined)    
            data['Alias']='';
         
       
        this.props.updateLocation(data);

    };

    async paises() {
        let result = await ApiCoag.tipos.paises();

       this.setState({
            AreasGeograficasPaises: result.AreasGeograficasPaises,
            AreasGeograficasAutonomias: [],
            AreasGeograficasProvincias: [],
            AreasGeograficasConcellos: []


        })
    }

    async cargaListasEmp(){

        let resultGeoPaises=[];
        let  resultGeoAuto=[];
        let  resultGeoPro=[];
        let  resultGeoCon=[];

        resultGeoPaises = await ApiCoag.tipos.paises();
        resultGeoAuto = await ApiCoag.tipos.regionesAutonomas(this.props.location.Id_Pais);
        resultGeoPro = await ApiCoag.tipos.provincias(this.props.location.Id_Autonomia);
        resultGeoCon = await ApiCoag.tipos.concellos(this.props.location.Id_Provincia);

        this.setState({
            AreasGeograficasPaises:  resultGeoPaises.AreasGeograficasPaises,
            AreasGeograficasAutonomias: resultGeoAuto.AreasGeograficasAutonomias,
            AreasGeograficasProvincias:  resultGeoPro.AreasGeograficasProvincias,
            AreasGeograficasConcellos: resultGeoCon.AreasGeograficasConcellos
        })

    }

    async autonomias(idPais) {
        /*if (idPais == 100)
        { let result = await ApiCoag.tipos.regionesAutonomas();

            await this.setState({
            AreasGeograficasAutonomias: result.AreasGeograficasAutonomias,
            AreasGeograficasProvincias: [],
            AreasGeograficasConcellos: []
            })
        }

        else{
            await this.setState({
                AreasGeograficasAutonomias: [],
                AreasGeograficasProvincias: [],
                AreasGeograficasConcellos: []
                })
        }*/
        
         
        let result = await ApiCoag.tipos.regionesAutonomas(idPais);

      
            this.setState({
            AreasGeograficasAutonomias: result.AreasGeograficasAutonomias,
            AreasGeograficasProvincias: [],
            AreasGeograficasConcellos: []
            })
       
            

    }

    async provincias(idAutonomia) {
        let result = await ApiCoag.tipos.provincias(idAutonomia);
      
        this.setState({
            AreasGeograficasProvincias: result.AreasGeograficasProvincias,
            AreasGeograficasConcellos: []
        })
       

    }

    async concellos(idProvincia) {
        let result = await ApiCoag.tipos.concellos(idProvincia);

         this.setState({
            AreasGeograficasConcellos: result.AreasGeograficasConcellos
        })
    }

    checkIntroducingCoordenadas()
    { if ((this.state.locationForm.trim()).includes(" ") || (this.state.locationForm).includes(","))
        return true
      else
        return false 
    }

    coor()
    {  let coor=[];
       
       let intro=this.state.locationForm.trim().replaceAll(","," ").replace(/\s\s+/g, " ");
       coor = intro.split(" ");
      
       if (coor.length>3){         
         coor[1]='error'
         return coor
        }
              
       return coor
    }

    getIntroducedCoordenadas()
    { 
        return this.state.locationForm.trim().replace(","," ").replace(/\s\s+/g, " ").split(" ");
    }


    async handleValidateAddress() {
        let introCoor=null;
        this.setState({isValidating: true});  
           
        try {
          
            let response=null;
            let coor = null;
            let refcatastral=null;

            if ( this.checkIntroducingCoordenadas())
            {
                coor=this.coor()

                if (coor.length>2 || (coor.length==2 && coor[0]>180)) {                                    
                   response = await ApiCoag.datosCatastro.validateAddressCoor(coor[0],coor[1],29);
                   introCoor=1
                }
                else{                                    
                    response = await ApiCoag.datosCatastro.validateAddressCoor(coor[0],coor[1]);
                    introCoor=0
                }               
            }
            else
            { 
                refcatastral=this.state.locationForm.trim()
                response = await ApiCoag.datosCatastro.validateAddress(refcatastral);                 
            }
            
            if ((coor!=null && coor.length>0) || (response.MensajesProcesado && response.MensajesProcesado.length > 0 && (response.MensajesProcesado[0].IdTipo == 101 || response.MensajesProcesado[0].IdTipo == 102 )) ) {

                    
                 /*España y Galicia vendrán preseleccionadas del servidor en response.Inmuebles[0] siempre y si no se encuentra emp con el resto de la linea vacia*/
                    /*Precargamos con lo encontrado si es necesario, es decir, si hay cambios en pais, autonomia o provincia  */      
                    if (!this.props.isShowAddress && this.state.data.Id_Pais==null)   //Paises basta con cargalos só unha vez
                        await this.paises(); 

                    if (response.Inmuebles!=null && response.Inmuebles[0].Id_Pais!=this.state.data.Id_Pais)                           
                        await this.autonomias(response.Inmuebles[0].Id_Pais);  
                                                          
                    if (response.Inmuebles!=null && response.Inmuebles[0].Id_Autonomia!=this.state.data.Id_Autonomia)              
                        await this.provincias(response.Inmuebles[0].Id_Autonomia); 

                    /* si hay geolocalización auxiliar los datos son imcompleto pero viene concello y provincia*/
                    if (response.Inmuebles!=null && response.Inmuebles[0].Id_Provincia!=null && response.Inmuebles[0].Id_Provincia!=this.state.data.Id_Provincia)        
                       await this.concellos(response.Inmuebles[0].Id_Provincia); 

                    let coorValidas=response.Inmuebles; /*si coor pasan validacion viene algo en Inmuebles, aunque sea solo el dato de España y Galicia*/   

                    /* si viene response.Inmuebles es que pasa la validacion de formato, y por tanto no se ha encontrado emplazamiento*/
                    this.setState({
                        manualAdd: coorValidas ,
                        locationForm: null,    
                        location:null,                    
                                       
                        data:coorValidas ? response.Inmuebles[0]  : [] ,    
                        georeferencia:coorValidas ? response.Inmuebles[0]['Georeferencia']  : null,
                        latitud: coorValidas? response.Inmuebles[0]['Latitud'] : introCoor==0?coor[0]:null,
                        longitud: coorValidas? response.Inmuebles[0]['Longitud'] : introCoor==0?coor[1]:null ,              
                        utmx: coorValidas? response.Inmuebles[0]['UTM_X']  :  introCoor==1?coor[0]:null,
                        utmy: coorValidas ? response.Inmuebles[0]['UTM_Y']  :  introCoor==1?coor[1]:null,                           
                        utmh: coorValidas? response.Inmuebles[0]['UTM_H']  :  introCoor==1?coor[2]:null,
                        /*latitud: response.Inmuebles && introCoor==0?coor[0]:null,
                        longitud: response.Inmuebles && introCoor==0?coor[1]:null,              

                        utmx:  response.Inmuebles  && introCoor==1?coor[0]:null,
                        utmy:  response.Inmuebles  && introCoor==1?coor[1]:null,                           
                        utmh:  response.Inmuebles  &&  introCoor==1?29:null,*/
                       
                        introUtm:introCoor!=null?introCoor==0?'0':'1':null,
                        isValidating: false,
                        isShowAddress:coorValidas,
                        showMessageManualAdd:coorValidas && !this.props.fromGeo
                    })
                                       
                    //this.setState({isValidating: false, isShowAddress: true});
                    await this.props.updateLocation(coorValidas ? response.Inmuebles[0]  : [], coor, introCoor);
                    await this.props.updateIsShowAddress(coorValidas);
                              

            } else {
                if (response != 'ERROR2')                
                {
                    this.setState({
                    manualAdd: false, 
                    data: response.Inmuebles ? response.Inmuebles[0]  : [] ,
                    isValidated:true,
                    isValidating: false,
                    isShowAddress: true,
                                      

                    locationForm:response.Inmuebles ? response.Inmuebles[0]['Georeferencia']  : null,
                    georeferencia:response.Inmuebles ? response.Inmuebles[0]['Georeferencia']  : null,
                    latitud: response.Inmuebles ? response.Inmuebles[0]['Latitud']  : null,
                    longitud: response.Inmuebles ? response.Inmuebles[0]['Longitud']  : null,       
                    utmx: response.Inmuebles ? response.Inmuebles[0]['UTM_X']  : null,
                    utmy: response.Inmuebles ? response.Inmuebles[0]['UTM_Y']  : null,                             
                    utmh: response.Inmuebles ? response.Inmuebles[0]['UTM_H']  : null,  
                    introUtm:introCoor!=null?introCoor==='0'?'0':'1':null                           
                });

            

                await this.props.updateLocation(response.Inmuebles ? response.Inmuebles[0] :  [] );                     
                await this.props.updateIsShowAddress(true);
                }
                else
                {                        
                    await this.setState({                       
                        isValidating: false,
                        isShowAddress: false,
                          });
                    await this.props.updateLocation(response.Inmuebles ? response.Inmuebles[0] :  [] , coor, introCoor);

                }
                
            }
        } catch (e) {
            await this.props.fetchErrorExpediente(formatMenssage(e.message));
            await this.setState({isValidating: false, isShowAddress: false});
        }

    }
    
      
    handleShowGeo(lat,lon){
        window.open(urlUbicacion(lat,lon), '_blank');
      }
   


    render() {
        let {classes} = this.props;
        let data = (this.props.isShowAddress && this.state.locationForm == null) ? this.props.location : this.state.data;
    
      
        return (
            <Grid container spacing={8} className="pl-2">
                <Grid item xs={12}>
                    <div style={{display: "flex", alignItems: "center"}}>                        
                        <FormControl className={classes.formControl1} style={{width: '77%', paddingRight: '0%'}}>
                            <TextField
                                id="locationForm"
                                helperText={!this.props.fromGeo?<Translate id="languages.expedients.helperTextAddressValidate"/>:<Translate id="languages.expedients.helperTextGeoValidate"/>}
                                value={this.state.locationForm != null ? this.state.locationForm :this.state.location/*georeferencia*/}
                                onChange={this.handleChange('locationForm')}
                                margin="normal"
                                onKeyDown={this._handleKeyDown}
                                placeholder="Indicar ref.catastral, coor. UTM(X,Y) o coor. polares(lat.,lon.)"
                                autoFocus={true }   /* md */                            
                                inputProps={{ maxLength: 35 }}
                                InputLabelProps={{
                                    shrink: true
                                }}

                            />
                        </FormControl>                       


                        <Tooltip title={<Translate id="languages.generalButton.validate"/>}>
                            <Fab size="small" color="primary" aria-label="Check" className={classes.button}
                                 disabled={!this.state.locationForm || this.state.isValidating}
                                 onClick={() => {
                                     this.handleValidateAddress()
                                 }}>
                                <Check/>                                
                            </Fab>                         
                           
                           
                        </Tooltip>
                        {this.state.isValidating && <CircularProgress size={24}/>}
                        
                        {/*//Si tengo referencia catastral editada en formulario, que está validada}*/}
                        {((this.state.locationForm!=null ) && (this.state.isValidated && !this.state.manualAdd )) && !this.state.isValidating &&
                        <Tooltip title={<Translate id="languages.generalText.irACatastro" />} >                     
                        {imgCatastro(this.state.locationForm?this.state.locationForm:this.state.location,30)  
                        }                                                                     
                        </Tooltip>
                        }

                         {/*Si tengo coordenadas polares en bd o editadas validas} (this.state.location!=null || this.state.locationForm!=null )*/} 
                         {/*(this.state.latitud!=null) &&  
                       <Tooltip title={'Ir a maps'}>                       
                        {imgUbicacion(this.state.latitud,this.state.longitud,40)  
                        }                                                                     
                    </Tooltip>*/}
                         {(this.state.latitud!=null && this.state.isShowAddress) && !this.state.isValidating && <Tooltip title={<Translate id="languages.generalText.irAEmplazamiento"/>}>
                         <IconButton color="primary" style={{padding:0,margin:0}} aria-label="AddLocation" 
                          onClick={() => { this.handleShowGeo(this.state.latitud,this.state.longitud) }} >                                                                                                               
                         <LocationOn  style={{width: '35px', height: '35px'}}/>
                         </IconButton>     
                         </Tooltip>        
                        }
                        
                                              
                    </div>
                  
                                       
                  
                                  
                </Grid>
                <Grid item xs={12}>
                    {
                        /*this.state.isShowAddress ||*/ this.props.isShowAddress ?
                        <Grid container spacing={16}>
                            <Grid item xs={12}>
                                {
                                    this.state.manualAdd  ?
                                        <FormControl className={classes.formControl2} style={{width: '46%', paddingRight: '0%'}}>
                                            <label className='MuiFormLabel-root-214'
                                                   style={{marginTop: 12, marginBottom: -12}}>PAIS</label>
                                            <Select classes={{select: classes.uppercase}}
                                                    id='pais'
                                                    label="PAIS"
                                                    onChange={(event) => {
                                                        this.handleChangeAddress('Pais', event)
                                                    }}
                                                    value={data && data.Id_Pais ? data.Id_Pais : null}
                                                    inputProps={{
                                                        name: 'Nombre',
                                                        id: 'Id_Area',
                                                    }}
                                            >

                                                {this.state.AreasGeograficasPaises.map(item => (
                                                    <MenuItem className="text-uppercase"
                                                              value={item.Id_Area}>{item.Nombre}</MenuItem>
                                                ))}

                                            </Select>

                                        </FormControl>
                                        :
                                        <FormControl className={classes.formControl2} style={{width: '46%', paddingRight: '0%'}}>
                                            <TextField
                                                id="pais"
                                                label="PAIS"
                                                disabled
                                                value={data && data.Pais ? data.Pais : null}
                                                onChange={(event) => {
                                                    this.handleChangeAddress('Pais', event)
                                                }}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                }
                                {
                                    this.state.manualAdd?
                                        <FormControl className={classes.formControl2} style={{width: '46%', paddingRight: '0%'}}>
                                            <label className='MuiFormLabel-root-214'
                                                   style={{marginTop: 12, marginBottom: -12}}>{<Translate id="languages.generalAddress.region"/>}</label>
                                            <Select classes={{select: classes.uppercase}}
                                                    id='Autonomia'

                                                    label={<Translate id="languages.generalAddress.region"/>}
                                                    onChange={(event) => {
                                                        this.handleChangeAddress('Autonomia', event)
                                                    }}
                                                    value={data && data.Id_Autonomia ? data.Id_Autonomia : null}
                                                    inputProps={{
                                                        name: 'Nombre',
                                                        id: 'Id_Area',
                                                    }}
                                            >

                                                {this.state.AreasGeograficasAutonomias.map(item => (
                                                    <MenuItem className="text-uppercase"
                                                              value={item.Id_Area}>{item.Nombre}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>:
                                        <FormControl className={classes.formControl2} style={{width: '46%', paddingRight: '0%'}}>

                                            <TextField
                                                id="Autonomia"
                                                label={<Translate id="languages.generalAddress.region"/>}
                                                disabled
                                                value={data && data.Autonomia ? data.Autonomia : null}
                                                onChange={(event) => { this.handleChangeAddress('Autonomia', event) }}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                }
                                {
                                    this.state.manualAdd?
                                        <FormControl className={classes.formControl2} style={{width: '46%', paddingRight: '0%'}}>
                                            <label className='MuiFormLabel-root-214'
                                                   style={{marginTop: 12, marginBottom: -12}}>PROVINCIA</label>
                                            <Select classes={{select: classes.uppercase}}
                                                    id='provincia'
                                                    label="PROVINCIA"
                                                    onChange={(event) => {
                                                        this.handleChangeAddress('Provincia', event)
                                                    }}
                                                    value={data && data.Id_Provincia ? data.Id_Provincia : ""}
                                                    inputProps={{
                                                        name: 'Nombre',
                                                        id: 'Id_Area',
                                                    }}
                                                    autoFocus={true && this.state.manualAdd}
                                            >

                                                {this.state.AreasGeograficasProvincias.map(item => (
                                                    <MenuItem className="text-uppercase"
                                                              value={item.Id_Area}>{item.Nombre}</MenuItem>
                                                ))}

                                            </Select>

                                        </FormControl>:
                                        <FormControl className={classes.formControl2} style={{width: '46%', paddingRight: '0%'}}>

                                            <TextField
                                                id="provincia"
                                                label="PROVINCIA"
                                                disabled
                                                value={data && data.Provincia ? data.Provincia : ""}
                                                onChange={(event) => { this.handleChangeAddress('Provincia', event) }}
                                                margin="normal"
                                              
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>

                                }
                                {
                                    this.state.manualAdd?
                                        <FormControl className={classes.formControl2} style={{width: '46%', paddingRight: '0%'}}>
                                            <label className='MuiFormLabel-root-214'
                                                   style={{marginTop: 12, marginBottom: -12}}>{<Translate id="languages.generalAddress.municipio"/>}</label>
                                            <Select classes={{select: classes.uppercase}}
                                                    id='Concello'
                                                    label={<Translate id="languages.generalAddress.municipio"/>}
                                                    onChange={(event) => {
                                                        this.handleChangeAddress('Concello', event)
                                                    }}
                                                    value={data && data.Id_Concello ? data.Id_Concello : ""}
                                                    inputProps={{
                                                        name: 'Nombre',
                                                        id: 'Id_Area',
                                                    }}
                                            >

                                                {this.state.AreasGeograficasConcellos.map(item => (
                                                    <MenuItem className="text-uppercase"
                                                              value={item.Id_Area}>{item.Nombre}</MenuItem>
                                                ))}

                                            </Select>

                                        </FormControl>:
                                        <FormControl className={classes.formControl2} style={{width: '46%', paddingRight: '0%'}}>
                                            <TextField
                                                id="municipio"
                                                label={<Translate id="languages.generalAddress.municipio"/>}
                                                disabled
                                                value={data && data.Concello ? data.Concello : ""}
                                                onChange={(event) => { this.handleChangeAddress('Concello', event) }}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>

                                }

                                            


                            </Grid>

                            <Grid item xs={12}>
                            <FormControl className={classes.formControl2} style={{width: '95%', paddingRight: '0%'}}>
                                    <TextField
                                        label={<Translate id="languages.generalAddress.calle"/>}
                                        value={data && data.Calle ? data.Calle : ""}
                                        onChange={(event) => {
                                            this.handleChangeAddress('Calle', event)
                                        }}
                                        disabled={!this.state.manualAdd}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    />
                                </FormControl>

                                
                                
                                
                             
                             
                            </Grid>
                            <Grid item xs={12}>

                            <FormControl className={classes.formControl2} style={{width: '7%', paddingRight: '0%'}}>
                                    <TextField
                                        id="num"
                                        label="NUM"
                                        disabled={!this.state.manualAdd}
                                        value={data && data.Numero ? data.Numero : ""}
                                        onChange={(event) => {
                                            this.handleChangeAddress('Numero', event)
                                        }}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true
                                        }}/>
                                </FormControl>
                                <FormControl className={classes.formControl2} style={{width: '15%', paddingRight: '0%'}}>
                                    <TextField
                                        id="PISO"
                                        label="ESC-PLANTA"
                                        disabled={!this.state.manualAdd}
                                        value={data && data.Piso ? data.Piso : ""}
                                        onChange={(event) => {
                                            this.handleChangeAddress('Piso', event)
                                        }}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true
                                        }}/>
                                </FormControl>
                            <FormControl className={classes.formControl2} style={{width: '10%', paddingRight: '0%'}}>
                                    <TextField
                                        id="CP"
                                        label="CODIGO POSTAL"
                                        value={data && data.Codigo_Postal ? data.Codigo_Postal : ""}
                                        onChange={(event) => {
                                            this.handleChangeAddress('Codigo_Postal', event)
                                        }}
                                        margin="normal"
                                        disabled={!this.state.manualAdd}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>        
                               
                               
                                <FormControl className={classes.formControl1} style={{width: '54%', paddingRight: '0%'}}>
                                    <TextField
                                        id="alias"
                                        label={<Translate id="languages.expedients.fieldAliasDireccion"/>}
                                        placeholder={data && data.Georeferencia ? '' : ''}
                                        value={data && data.Alias ? data.Alias : ""}
                                        onChange={this.handleChange('alias')}
                                        margin="normal"
                                        autoFocus={true && !this.state.manualAdd}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        helperText={<Translate id="languages.expedients.helperTextAliasDireccion"/>}
                                    />
                                </FormControl>


                            </Grid>
                        </Grid>
                        : ""
                    }

                    
                    <Confirmation openConfirmation={this.state.showMessageManualAdd}
                    title={<Translate id="languages.generalText.observaciones"/>}
                    onlyAccept={true} /*solo se avisa*/
                    buttonVariant={"text"}
                    confirmationMessage={'Puedes introducir manualmente el emplazamiento asociado a estas coordenadas.'}                   
                    aceptConfirmation={async () => {                                                    
                        this.setState({showMessageManualAdd: false}) 
                    }}
                    />


                    
                </Grid>
            </Grid>




        );

        
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(ValidateAddress)));