import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";

import { groupBy, filter } from 'lodash';
import {
    Grid, List, ListItem, ListSubheader,  Button, Typography, FormControl, MenuItem, Select, TextField,
    RadioGroup, FormControlLabel, Radio, CircularProgress, IconButton,    Tooltip,Checkbox
} from "@material-ui/core";



import { TableCell} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
//import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
//import RemoveRedEyeRounded from '@material-ui/icons/RemoveRedEyeRounded';

import Close from '@material-ui/icons/Close';
import { withRouter } from "react-router-dom";
import {fetchErrorExpediente, formatMenssage} from "../../actions/expedientes";
import { connect } from "react-redux";
import {grey} from "@material-ui/core/colors/index";
import InfoCarpetas from "../AsistenteTrabajo/infoCarpetas"
import ApiCoag from "../../api/ApiCoag";
import {isMobile} from "../../api/index";
import {ID_TRAMITE_VISADO,ID_TRAMITE_REGISTRO} from "../../api/config";


const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        width: '100%',
        paddingTop: 0,
        borderTop: "solid 1px" + theme.palette.secondary.light,
    },
    item: {
        borderTop: "solid 1px" + theme.palette.secondary.light,
        padding: 0
    },
    border: {
        borderLeft: "solid 1px" + theme.palette.secondary.light,
        borderRight: "solid 1px" + theme.palette.secondary.light,
        borderBottom: "solid 1px" + theme.palette.secondary.light,
    },
    headerProyect: {
        backgroundColor:  theme.palette.default,
        //borderBottom: "solid 1px" + theme.palette.secondary.light,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
    button: {
        margin: theme.spacing.unit,
    },
    expand: {
        marginRight: 8,
        fontSize: 'medium'
    },
    subHeader: {
        backgroundColor: theme.palette.default
    },
    headHeight: {
        height: 40
    },
    table: {
        minWidth: 200,
    },
    tableArquitecto: {
        minWidth: 190,
    },
    tableBodyHeight: {
        minHeight: 100
    },
    fab: {
        margin: theme.spacing.unit,
        marginTop: 25,
        position: 'absolute',
        marginLeft: 40
    },
    tableBorder: {
        border: "2px solid " + grey[200]
    },
    buttonEdit: {
        border: "1.2px solid",
        margin: 2,
        padding: 6,
    },
    withoutRadius: {
        borderRadius: 0
    },
    headerBorder: {
        border: "2px solid " + grey[200],
        borderBottom: 0
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
        textAlign: 'left',
        marginTop: 5
    },
    borderPrimary: {
        border: "1px solid " +theme.palette.primary.main,
    },
    borderSecondary: {
        border: "1px solid " + theme.palette.secondary.light,
    },
    borderBottomPrimary: {
        borderBottom: "1px solid " +theme.palette.primary.main,
    },
    borderBottomSecondary: {
        borderBottom: "1px solid " + theme.palette.secondary.light,
    },
    panelSumary: {
        margin: "8px 0 !important",
        minHeight: "26px !important"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    expandIcon25: {
        top: "25%"
    },
    expandIcon50: {
        top: "50%"
    },
    labelButton: {
        textTransform: 'capitalize',
      },
      titleColor: {
        color: `${theme.palette.primary.main} !important`,
        cursor: "pointer"
      }
})
const CustomTableHead = withStyles(theme => ({
    head: {
        backgroundColor: grey[100],
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    }
}))(TableCell);
class CrearTrabajo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tiposTrabajos: this.props.trabajos,
            dataEncomenda: this.props.encomenda && this.props.encomenda.EncomendaActual && this.props.encomenda.EncomendaActual.length > 0
                ? this.props.encomenda.EncomendaActual[0] : null,
            tiposTramites: [],
            inforCarpetas: [],
            isCarpetas: false,
            expanded: null,
            expandedControl:null,            
            loadingCrearTrabajo: false,
            idTipoTrabajo:0,
            idTipoTramite:0,
            bEsModificado:false,
            trabajoTitulo:'',
            trabajosNotificadoAdm:{}
        }
    }

    // async UNSAFE_componentWillMount() {
    //     this.groupByFases();
    //     await this.getTiposTramites();
       
    // }

    async componentDidMount() {
        this.groupByFases();
        await this.getTiposTramites();
       
    }


   

    getIdTipoTramite(Name) {
        let id = ID_TRAMITE_VISADO;
        if (!Name) return id;
        let tramite = this.state.tiposTramites.find(t => t.Nombre.toUpperCase() === Name.toUpperCase());
        if (tramite) {
            id = tramite.Id_Tipo_Tramite;
        }
        return id;
    }

    async getTiposTramites() {
        let response = await ApiCoag.tipos.guia.tiposTramites()
        if (response) {
            let tiposTramites = response.Tipos_Trabajos_Tramites;
            this.setState({ tiposTramites: tiposTramites });
        }
    }
 
    groupByFases() {
        let tiposTrabajos = groupBy(this.state.tiposTrabajos, 'Fase');
        this.setState({ tiposTrabajos: tiposTrabajos });
    }

    async getInfoCarpetasTrabajo(id_tipo_trabajo, id_tipo_tramite, es_modificado) {
       
        let inforCarpetas = [];
        let response = await ApiCoag.tipos.guia.infoCarpetasDeTrabajo(id_tipo_trabajo, id_tipo_tramite ? id_tipo_tramite : 0, es_modificado);
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
           // this.props.fetchErrorExpediente(response);
        }
        else {
            let carpetas = response.Carpetas;
            let father = filter(carpetas, { 'Id_Documentacion_Padre': null });
            let children = groupBy(carpetas, 'Id_Documentacion_Padre');
            let fatherChildren = [];
            father.map(value => {
                value["children"] = children[value.Id_Documentacion]
                fatherChildren.push(value);

            })
            inforCarpetas = fatherChildren;
        }
        this.setState({ inforCarpetas: inforCarpetas });
    }

    handleChange = (fase, index, name,idTipoTrabajo) => async (event) => {
       
        let tiposTrabajos = {};        
        Object.assign(tiposTrabajos, this.state.tiposTrabajos);        
        tiposTrabajos[fase][index][name] = event.target.value;       
        let trabajo = tiposTrabajos[fase][index];
        let tipoTramite = ID_TRAMITE_VISADO;

        let trabajosNotificadoAdm={};
       
      /*  if (this.state.trabajosNotificadoAdm != null)
            Object.assign(trabajosNotificadoAdm, this.state.trabajosNotificadoAdm);
        else
        { for (var i=0; i < this.state.tiposTrabajos.length; i++)
           { trabajosNotificadoAdm[i]=false;
           }
        }   */

        
        if (name !='Es_Trabajo_Modificado_Requerido_Administracion')
        {
            if (idTipoTrabajo != null && trabajo.Id_Tipo_Trabajo==this.state.idTipoTrabajo)
            {
            if(name === 'Id_Tipo_Tramite'){
                tipoTramite = this.state.tiposTramites.find(t=>t.Id_Tipo_Tramite === event.target.value);
                tiposTrabajos[fase][index]['Obligatorio']= tipoTramite.Nombre;
                tiposTrabajos[fase][index]['Id_Tipo_Tramite']=event.target.value;
                await this.updateInfoCarpeta(trabajo.Id_Tipo_Trabajo, tipoTramite.Id_Tipo_Tramite, trabajo.defaultSelect === undefined || trabajo.defaultSelect === "Es_Trabajo_Nuevo" ? 0 : 1, trabajo.Id_Tipo_Trabajo);

            }else {
                let idTipoTramite = this.getIdTipoTramite(tiposTrabajos[fase][index]['Obligatorio']);
                await this.updateInfoCarpeta(trabajo.Id_Tipo_Trabajo, idTipoTramite, event.target.value === "Es_Trabajo_Nuevo" ? 0 : 1, trabajo.Id_Tipo_Trabajo);
            }
            
            }

            if(name === 'Id_Tipo_Tramite'){
                tiposTrabajos[fase][index]['Id_Tipo_Tramite']=event.target.value;
            }
        }
        else 
            { trabajosNotificadoAdm[index] = event.target.checked;                
                tiposTrabajos[fase][index]['Es_Trabajo_Modificado_Requerido_Administracion'] = event.target.checked;               
            }

        if ((trabajo.defaultSelect === undefined || trabajo.defaultSelect === "Es_Trabajo_Nuevo") &&  trabajo.Solo_Modificados == 0)
        { tiposTrabajos[fase][index]['Es_Trabajo_Modificado_Requerido_Administracion'] = false;          
        } 

        if (isMobile())
            tiposTrabajos[fase][index]['Es_Trabajo_Modificado_Requerido_Administracion'] =false;
                 

        this.setState({ tiposTrabajos: tiposTrabajos });

    };

    


     handleChangePanel = (id_tipo_trabajo, id_tipo_tramite, es_modificado)  => async(event, expanded) =>{
        await this.updateInfoCarpeta(id_tipo_trabajo, id_tipo_tramite, es_modificado, expanded)
    }

    async updateInfoCarpeta(id_tipo_trabajo, id_tipo_tramite, es_modificado, expanded){
        //this.setState({  expanded: expanded ? id_tipo_trabajo : false, isCarpetas: true });
       
        if(id_tipo_tramite !== undefined){
            await this.getInfoCarpetasTrabajo(id_tipo_trabajo, id_tipo_tramite, es_modificado );
        }
        this.setState({ isCarpetas: false });
    }

    handleClick = (index) => {
        let carpetas = {};
        Object.assign(carpetas, this.state.inforCarpetas);
        carpetas[index]['open'] = this.state.inforCarpetas[index]['open'] ? !this.state.inforCarpetas[index]['open'] : true;
        this.setState({ carpetas: carpetas });
    };

     async handleCrearTrabajo(){
         let idExpediente = this.props.match.params.id;
         let {tiposTrabajos} = this.state;
          let trabajos = [];
          this.setState({loadingCrearTrabajo: true});
          try {
              Object.values(tiposTrabajos).map(fase=>{
                  fase.map(t=>{
                      trabajos.push({
                          "Id_Tipo_Fase": t.Id_Tipo_Fase,
                          "Id_Tipo_Trabajo": t.Id_Tipo_Trabajo,
                          "Id_Tipo_Tramite": t.Id_Tipo_Tramite ? t.Id_Tipo_Tramite : 0,
                          "Es_Trabajo_Nuevo": t.defaultSelect === "Es_Trabajo_Nuevo"  && !t.Solo_Modificados == 1 ? 1 : 0,
                          "Es_Trabajo_Modificado_Correcion_Basica": t.defaultSelect === "Es_Trabajo_Modificado_Correcion_Basica"  || t.Solo_Modificados == 1 ? 1 : 0,
                          "Es_Trabajo_Modificado_Sustancial": t.defaultSelect === "Es_Trabajo_Modificado_Sustancial" ? 1 : 0,
                          "Es_Trabajo_Modificado_Requerido_Administracion": t.Es_Trabajo_Modificado_Requerido_Administracion,
                          "Envio_administracion":0
                      })

                  })

              });

              let data = {
                  "Trabajos": trabajos,

              }
              let response = await ApiCoag.expedientes.postTrabajos(idExpediente, data);
              if (response=='ERROR2' || (response.MensajesProcesado != null && response.MensajesProcesado.length > 0)) {
                  //this.props.fetchErrorExpediente(response);
                  this.setState({loadingCrearTrabajo: false});
              }
              else {
                  this.setState({loadingCrearTrabajo: false});
                  this.props.history.push("/visualizar-expediente/" + idExpediente + "/" + response.Trabajos[0].Id_Trabajo);
              }
          }catch (e) {
              this.setState({loadingCrearTrabajo: false});
              this.props.fetchErrorExpediente(formatMenssage(e.message));
          }

     }

    
 
    
    async mostrarDetallesCarpetasFirmasRequeridas(idTipoTrabajo,idTipoTramite,bEsModificado,trabajoTitulo){      

      

        this.getInfoCarpetasTrabajo(idTipoTrabajo,idTipoTramite,bEsModificado)

        this.setState({
            showDetalles:true,           
            idTipoTrabajo:idTipoTrabajo,
            idTipoTramite:idTipoTramite,
            bEsModificado:bEsModificado,
            trabajoTitulo:trabajoTitulo
           
        })
              
    }


  /*  detallesCarpetasFirmasRequeridasModal() {

        if(this.state.showDetalles){
            
            return (<div>
                <Dialog  maxWidth="md" fullWidth={true} open={this.state.showDetalles}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                    <Translate id="languages.trabajo.CarpetasFirmasRequeridas" />
                    </DialogTitle>
                    
                    <DialogContent>
                        <Grid container spacing={4}>
                        <Grid item xs={12} className={"pb-2 pl-2 pr-2 "} >
                     
                            {  

                                <InfoCarpetas  trabajoTitulo={this.state.trabajoTitulo} inforCarpetas={this.state.inforCarpetas} 
                                              />

                                
                            }
                            </Grid>
                        </Grid>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={async () => {
                            this.setState({ showDetalles: false });

                        }} variant="contained"
                                color="primary" autoFocus style={{ height: 30, minHeight: 30 }}
                                className="p-0">
                            Aceptar

                        </Button>

                    </DialogActions>
                </Dialog>
            </div>);
        }

    }*/


    detallesCarpetasFirmasRequeridas(trabajo) {
        let { classes } = this.props;

        if(this.state.showDetalles && trabajo.Id_Tipo_Trabajo==this.state.idTipoTrabajo){
            
            return (<div>

               
                        <Grid container spacing={4}>
                       

                       <Grid item xs={11} >
                        <Typography  variant="title2" gutterBottom className="pt-3 pl-3 pb-0"> 
                               <Translate id="languages.trabajo.CarpetasFirmasRequeridas" />
                         </Typography   >
                         </Grid>
                         <Grid item xs={1}   style={{ textAlign:"right",paddingRight:1,paddingTop:2, paddingBottom:0}} >
                         <Tooltip title={<Translate id="languages.fileUpload.cerrar" />} >
                        <IconButton  className={classes.buttonEdit} color="primary" aria-label="Close"  
                        onClick={async () => { this.setState({ showDetalles: false });  }}> 
                        <Close/>
                        </IconButton>
                        </Tooltip>                   
                         </Grid  >

                         <Grid xs={12} className="pb-2 pl-2 pr-2" >                            
                                                           
                             <InfoCarpetas  trabajoTitulo={this.state.trabajoTitulo} 
                             inforCarpetas={this.state.inforCarpetas}
                             />                                 
                            
                          </Grid>
                        </Grid> 

                
                
            </div>);
        }

    }



    handleCancel(){
        this.props.history.push("/visualizar-expediente/" + this.state.dataEncomenda.Id_Expediente);
    }

    render() {
        let { classes } = this.props;
        let { tiposTrabajos, tiposTramites, expanded } = this.state;
        return (
            <Grid container spacing={0}>
                {/*<Grid item xs={12}>
                    <Typography variant="h7" gutterBottom className="font-weight-bold">
                        <Translate id="languages.trabajo.datosEncargo" />
                    </Typography>
                  </Grid>*/}
                <Grid item xs={10} sm={6}>
                    <TextField disabled={true}
                               value={this.state.dataEncomenda.Descripcion_Encomenda ? this.state.dataEncomenda.Descripcion_Encomenda : ""}
                               label={<Translate id="languages.crearTrabajo.labelExpedienteType" />}
                               className={`${classes.textField} my-3 text-uppercase mx-0 pl-0 pr-1`} />


            </Grid>
            <Grid xs={5}> </Grid> 
            <Grid xs={1} >     
                
            </Grid>               

                <Grid item xs={12}>
                    
                    <p></p>
                    
                </Grid>
                <Grid item xs={12}>
                    {
                        tiposTramites.length > 0 ?
                            <Grid container spacing={0} className={classes.border}>
                                <Grid item xs={12}>
                                 
                                    { 
                                    
                                    Object.keys(tiposTrabajos).map((fase, indexFase) => {
                                        let trabajos = tiposTrabajos[fase];
                                        return <List key={indexFase}
                                                     subheader={<Typography variant="h7" gutterBottom className="py-2 pl-3 font-weight-bold">{fase}</Typography>}
                                            className={classes.root}
                                        >
                                            <ListItem className="p-0">
                                                <List
                                                    className={classes.root}>
                                                    {
                                                        
                                                        trabajos.map((trabajo, indexTrabajo) => {
                                                            let idTipoTramite =0;
                                                            if (trabajo.Obligatorio != null)
                                                            idTipoTramite = this.getIdTipoTramite(trabajo.Obligatorio);

                                                            if (trabajo.comvisado==null) /*SI NO HAY VISADO ES REGISTRO Y AL REVES*/
                                                                idTipoTramite = ID_TRAMITE_REGISTRO
                                                            if  (trabajo.comrexistro==null)
                                                                idTipoTramite =ID_TRAMITE_VISADO
                                                                                                                                                                                   
                                                            if (trabajo.Id_Tipo_Tramite == null) 
                                                            trabajo.Id_Tipo_Tramite=idTipoTramite;

                                                            return <ListItem key={indexTrabajo} className={classes.item}>
                                                                <Grid container spacing={0}>
                                                                <Grid item xs={12} className={`${classes.headerProyect} px-3 pt-2`}>
                                                                     {/* aquí hay que poner ancho 
                                                                            <Typography style={{width: 250  }} variant="button" gutterBottom className="mb-0"> 
                                                                            <Button  style={{ fontSize: 14 }} classes={{      label: classes.labelButton   }}  variant="info"   color="primary" onClick={() => { this.mostrarDetallesCarpetasFirmasRequeridas(trabajo.Id_Tipo_Trabajo,idTipoTramite,trabajo.defaultSelect === undefined || trabajo.defaultSelect === "Es_Trabajo_Nuevo" ? 0 : 1 ,trabajo.Trabajo_Titulo)}}>
                                                                                {trabajo.Trabajo_Titulo}
                                                                                </Button>
                                                                            </Typography>*/}
                                                                                {<a style={{width: 250 }} className={classes.titleColor} onClick={() => { this.mostrarDetallesCarpetasFirmasRequeridas(trabajo.Id_Tipo_Trabajo,idTipoTramite,trabajo.defaultSelect === undefined || trabajo.defaultSelect === "Es_Trabajo_Nuevo" ? 0 : 1 ,trabajo.Trabajo_Titulo)}}>
                                                                                {trabajo.Trabajo_Titulo}
                                                                                </a>}
                                                                    </Grid>
                                                                    <Grid item xs={12} className={`${classes.headerProyect} px-3`}>
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                           
                                                                            <FormControl className={classes.formControl}>
                                                                                <Select classes={{select: classes.uppercase}}
                                                                                    value={trabajo.Id_Tipo_Tramite != null ?  trabajo.Id_Tipo_Tramite : idTipoTramite}
                                                                                    displayEmpty
                                                                                    onChange={this.handleChange(fase, indexTrabajo, 'Id_Tipo_Tramite',trabajo.Id_Tipo_Trabajo)}
                                                                                    inputProps={{
                                                                                        name: 'Id_Tipo_Tramite',
                                                                                        id: 'Id_Tipo_Tramite',
                                                                                    }}
                                                                                >

                                                                                    {tiposTramites.map(tramite => (
                                                                                      (trabajo.comvisado!=null && tramite.Id_Tipo_Tramite==ID_TRAMITE_VISADO) 
                                                                                      ||   (trabajo.comrexistro!=null && tramite.Id_Tipo_Tramite==ID_TRAMITE_REGISTRO) ? 
                                                                                        <MenuItem className="text-uppercase"
                                                                                            value={tramite.Id_Tipo_Tramite}>{tramite.Nombre}</MenuItem>:
                                                                                            null
                                                                                    
                                                                                    ))}

                                                                                </Select>
                                                                            </FormControl>
                                                                            <FormControl>
                                                                                <RadioGroup
                                                                                    aria-label="Gender"
                                                                                    name="gender1"
                                                                                    className={classes.group}
                                                                                    value={trabajo.defaultSelect ? trabajo.defaultSelect : (trabajo.Solo_Modificados == 1 ? "Es_Trabajo_Modificado_Correcion_Basica" :"Es_Trabajo_Nuevo")}
                                                                                    onChange={this.handleChange(fase, indexTrabajo, 'defaultSelect', trabajo.Id_Tipo_Trabajo)}
                                                                                    row
                                                                                >
                                                                                    <FormControlLabel value="Es_Trabajo_Nuevo"
                                                                                                      disabled={ trabajo.Solo_Modificados == 1}  
                                                                                                      control={<Radio color={ trabajo.defaultSelect === undefined || trabajo.defaultSelect === "Es_Trabajo_Nuevo" ? "primary" : "secondary"}/>}
                                                                                                      label={<Translate id="languages.trabajo.nuevoTrabajoTitle" />}
                                                                                                      className="m-0 text-uppercase font-weight-bold"
                                                                                                      />
                                                                                    <FormControlLabel                                                                                        
                                                                                        value="Es_Trabajo_Modificado_Sustancial"
                                                                                        disabled={ trabajo.Solo_Nuevos == 1}  
                                                                                        control={<Radio color={ trabajo.defaultSelect &&  trabajo.defaultSelect === "Es_Trabajo_Modificado_Sustancial" ? "primary" : "secondary"}/>}
                                                                                        label={<Translate id="languages.trabajo.modificacionSustancialTitle" />}
                                                                                        className="m-0  text-uppercase font-weight-bold" />
                                                                                    <FormControlLabel
                                                                                        disabled={ trabajo.Solo_Nuevos ==1}  
                                                                                        value="Es_Trabajo_Modificado_Correcion_Basica"
                                                                                        control={<Radio color={ (trabajo.defaultSelect &&  trabajo.defaultSelect === "Es_Trabajo_Modificado_Correcion_Basica")  || (trabajo.defaultSelect === undefined && trabajo.Solo_Modificados == 1)? "primary" : "secondary"}/>}
                                                                                        label={<Translate id="languages.trabajo.correccionBasicaTitle" />}
                                                                                       className="m-0  text-uppercase font-weight-bold" />
                                                                                  
                                                                                                                                                                      
                                                                                      {/* md a idea e lanzar modal con explicación de carpetas, tal e como está confunde*/}    
                                                                                 {/*  <Tooltip style={{fontSize: 12 }} title={<Translate id="languages.trabajo.consultarCarpetasFirmasRequeridasToolTip" />}>
                                                                                   //<IconButton  aria-label="View" color="primary"  onClick={() => { this.mostrarDetallesCarpetasFirmasRequeridas(trabajo.Id_Tipo_Trabajo,idTipoTramite,trabajo.defaultSelect === undefined || trabajo.defaultSelect === "Es_Trabajo_Nuevo" ? 0 : 1 ,trabajo.Trabajo_Titulo)}}>                                                                                                                                                                  
                                                                                   //</IconButton> 
                                                                                  <Button color="primary" onClick={() => { this.mostrarDetallesCarpetasFirmasRequeridas(trabajo.Id_Tipo_Trabajo,idTipoTramite,trabajo.defaultSelect === undefined || trabajo.defaultSelect === "Es_Trabajo_Nuevo" ? 0 : 1 ,trabajo.Trabajo_Titulo)}}>
                                                                                   <Translate id="languages.generalButton.masInfo"/>
                                                                                    </Button>
                                                                                   </Tooltip>*/}
                                                                                </RadioGroup>
                                                                              
                                                                                                                                                  
                                                                              
                                                                                </FormControl> 
                                                                                {!isMobile() &&
                                                                                <FormControl> 
                                                                               
                                                                                <FormControlLabel 
                                                                                     
                                                                                     key="Es_Trabajo_Modificado_Requerido_Administracion"                                                                                     
                                                                                     control={
                                                                                    <Checkbox

                                                                                        checked={trabajo.Es_Trabajo_Modificado_Requerido_Administracion != null && trabajo.Es_Trabajo_Modificado_Requerido_Administracion ? true : false}                                                                                        
                                                                                        onChange={this.handleChange(fase, indexTrabajo, "Es_Trabajo_Modificado_Requerido_Administracion", trabajo.Id_Tipo_Trabajo)}
                                                                                        className="pl-1"
                                                                                            value={trabajo.Es_Trabajo_Modificado_Requerido_Administracion != null & trabajo.Es_Trabajo_Modificado_Requerido_Administracion ? true : false}                                                                                        
                                                                                            color="primary" />
                                                                                             }
                                                                                        label={<Translate id="languages.fileUpload.administrativeNotification" />}
                                                                                        disabled={(trabajo.defaultSelect === undefined || trabajo.defaultSelect === "Es_Trabajo_Nuevo")  && !trabajo.Solo_Modificados == 1 }  />
                                                                                        
                                                                             
                                                                                   </FormControl>   
                                                                                   }    
                                                                                                              
                                                                               
                                                                            
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} >
                                                                          
                                                                        {  
                                                                                this.detallesCarpetasFirmasRequeridas(trabajo)
                                                                       
                                                                            }
                                                                    </Grid>

                                                                </Grid>

                                                            </ListItem>
                                                        })
                                                    }
                                                </List>
                                            </ListItem>
                                        </List>
                                    })}
                                </Grid>

                            </Grid>
                            :
                            <CircularProgress />
                    }
                </Grid>
                {tiposTramites.length > 0 ?
                    <Grid item xs={12} className="d-flex justify-content-between py-3">
                           <Button color="primary" size="small" disabled={this.state.loadingCrearTrabajo} className="px-3" onClick={() => { this.handleCancel() }}>
                                <Translate id="languages.generalButton.cancel" /><Close />
                            </Button>
                        <div>
                        <Button color="primary" size="small" disabled={this.state.loadingCrearTrabajo} className="px-4" onClick={() => { this.props.handleNavigation(true) }}>
                            <Translate id="languages.generalButton.volver"/>
                        </Button>
                        
                         
                            <Button variant="contained" size="small" color="primary" disabled={this.state.loadingCrearTrabajo} className="px-3" onClick={()=> {this.handleCrearTrabajo()}}                                            >
                                <Translate id="languages.generalButton.finalizar"/>
                                <CheckIcon/>
                            </Button>
                            {this.state.loadingCrearTrabajo ? <CircularProgress size={24}/> : ""}
                        </div>
                    </Grid>
                    : ""
                }

               
            </Grid>
        );
    }

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    fetchErrorExpediente
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(CrearTrabajo))));