export const FETCH_DATOSDEUSUARIO_SUCCESS = 'FETCH_DATOSDEUSUARIO_SUCCESS';
export const FETCH_EXPEDIENTES_ERROR = 'FETCH_EXPEDIENTES_ERROR';
export const FETCH_EXPEDIENTES_INIT = 'FETCH_EXPEDIENTES_INIT';
export const FETCH_LOADING = 'FETCH_LOGIN';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAIL = 'FETCH_LOGIN_FAIL';
export const FETCH_EXPEDIENTES_SUCCESS = 'FETCH_EXPEDIENTES_SUCCESS';
export const REFRESH_TOKEN_ = 'REFRESH_TOKEN_';
export const BORRASELECTED = 'BORRASELECTED';
export const ULTIMOSTRABAJOS = 'ULTIMOSTRABAJOS';
export const EXPEDIENTESSUSCEPTIBLESTRABAJO = 'EXPEDIENTESSUSCEPTIBLESTRABAJO';
export const ACCIONESTRAMITARNUEVOTRABAJO = 'ACCIONESTRAMITARNUEVOTRABAJO';
export const ACCIONESSOLICITARLOA = 'ACCIONESSOLICITARLOA';
export const ACCIONESSOLICITARLI = 'ACCIONESSOLICITARLI';
export const ACCIONESCONVERTIRDIGITAL = 'ACCIONESCONVERTIRDIGITAL';
export const ACCIONESCESAREXPEDIENTE = 'ACCIONESCESAREXPEDIENTE';
export const GOEXPEDIENTES = 'GOEXPEDIENTES';
export const CAMBIASELECT = 'CAMBIASELECT';
export const GOTRABAJOS = 'GOTRABAJOS';
export const SELECTAGENTTOADD = 'SELECTAGENTTOADD';
export const NUEVA_CONF_USUARIO = 'NUEVA_CONF_USUARIO';
export const FETCH_LANGUAGE = 'FETCH_LANGUAGE';
export const FETCH_ID_ACCION = 'FETCH_ID_ACCION';
export const EXPIRED_SESSION = 'EXPIRED_SESSION';
export const SET_DEFAULT_LANGUAGE = "SET_DEFAULT_LANGUAGE";