import React, { Component } from 'react';
import {connect } from "react-redux";
import {withLocalize } from "react-localize-redux";
import {Translate } from "react-localize-redux";
import {withStyles, Grid, Paper, Typography, Divider, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import {ValidatorForm } from 'react-material-ui-form-validator';
import {Check} from '@material-ui/icons';
import Confirmation from "../../../Errors/confirmation"
import {dispatchEditExpedienteEnTrabajo, fetchErrorExpediente} from '../../../../actions/expedientes';
import {some, findIndex } from 'lodash';
import classNames from 'classnames';
import ApiCoag from "../../../../api/ApiCoag";
import {isMobile} from "../../../../api/index"
import ChecksSubstituciones  from "../../../NewExpedient/ChecksSubstituciones"


const styles = theme => ({ 
  withoutRadius: {
    borderRadius: 0
  },
  disable: {
    pointerEvents: "none",
    opacity: 0.6
  }
});


class Substituciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceExpediente: this.props.sourceExpediente,     
      isUpdating: false,            
      isLoadingSave: false,      
      showModal:false,
      changingWorkData:false,
      initialValues: {
        substitucion: this.props.sourceExpediente.Substitucion,
        voluntaria: this.props.sourceExpediente.Voluntaria,
        comunicada: this.props.sourceExpediente.Comunicada,
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sourceExpediente !== this.props.sourceExpediente) {
      this.setState({
        sourceExpediente: this.props.sourceExpediente,
        changingWorkData: false,
        initialValues: {
          substitucion: this.props.sourceExpediente.Substitucion,
          voluntaria: this.props.sourceExpediente.Voluntaria,
          comunicada: this.props.sourceExpediente.Comunicada,
        }
      });
    }
  }
 

  handleChangeDataExpedient = (propertyName) => (event) => {
    let expedienteCopy = {};
    Object.assign(expedienteCopy, this.state.sourceExpediente);
    if (propertyName !='Observaciones')
      expedienteCopy[propertyName] = event.target.value;
    else
      expedienteCopy[propertyName] = event;
    this.setState({ sourceExpediente: expedienteCopy ,  changingWorkData:true });    
  }

//   handleReloadDataExpedientOriginal () {
//     let expedienteCopy = {};
//     Object.assign(expedienteCopy,  this.props.sourceExpediente);      
//     this.setState({ sourceExpediente: expedienteCopy ,changingWorkData:false });    
//   }

  handleReloadDataExpedientOriginal() {
    this.setState({
      sourceExpediente: this.props.sourceExpediente,
      changingWorkData: false,
      initialValues: {
        substitucion: this.props.sourceExpediente.Substitucion,
        voluntaria: this.props.sourceExpediente.Voluntaria,
        comunicada: this.props.sourceExpediente.Comunicada,
      }
    });
  }


  handleCheckboxChange = newState => {
    const { initialValues } = this.state;
    const changingWorkData = 
      newState.checkSubstitucion !== this.b(initialValues.substitucion) ||
      newState.checkVoluntaria !== this.b(initialValues.voluntaria) ||
      newState.checkComunicada !== this.b(initialValues.comunicada);

    let expedienteCopy = { ...this.state.sourceExpediente };
    expedienteCopy['Substitucion'] = this.fromb(newState.checkSubstitucion);
    expedienteCopy['Voluntaria'] = this.fromb(newState.checkVoluntaria);
    expedienteCopy['Comunicada'] = this.fromb(newState.checkComunicada);

    this.setState({ sourceExpediente: expedienteCopy, changingWorkData });
  };

  b(val){
    return val==1?true:false;
  }

  fromb(val){
    return val?1:0;  
  }

  async handleSubmit() {
    this.setState({ isUpdating: true });
    let result = await ApiCoag.expedientes.putExpedienteSubstituciones(this.state.sourceExpediente)
    if (result && result == 'ERROR2') 
    { } 
    else {
    
      this.props.updateExpediente(result);      
      this.setState({ changingWorkData: false });
    }
    this.setState({ isUpdating: false  });
  }

    
  ifEqual(data, address) {
    let equal = some(data, address);
    let index = -1;
    if (equal) {
      index = findIndex(data, address);
    }
    return index;
  }
 

 
  render() {
    let { classes } = this.props;
    let disabledEdit = this.props.expediente && this.props.expediente.Trabajos
      && this.props.expediente.Trabajos.some(x => x.Id_Estado >= 3);
      
     
    return (
      <div>      

          <Paper className={!isMobile() ? ' m-3' : 'withoutRadius m-1'}>
          <ValidatorForm ref="form" onSubmit={async () => { await this.handleSubmit() }}>
            <Grid container spacing={16} className={classNames("my-3", this.props.disable ? classes.disable : "")} >
         
              <Grid item xs={12}>
                <Grid item xs={12} className="d-flex justify-content-between">
                  <Typography variant="display4" gutterBottom className="mx-2 my-1">
                  <Translate id="languages.expedients.substituciones" />
                  </Typography>
                
                  <Button type="submit" color="primary" style={{textAlign:"right",marginRight:5}} disabled={this.state.isUpdating}>
                 
                  </Button>   
                 
               
                </Grid>
                <Divider style={{ height: 3 }} />
              </Grid>
              <Grid item xs={12}>

               

                    <Grid  xs={12} style={{marginLeft:30,marginTop:-20}} className="p-2">                  
                          <ChecksSubstituciones 
                            substitucion={this.b(this.state.sourceExpediente.Substitucion)}                          
                            comunicada={this.b(this.state.sourceExpediente.Comunicada)}
                            voluntaria={this.b(this.state.sourceExpediente.Voluntaria)}
                            disabledChecks={disabledEdit}
                            onChange={this.handleCheckboxChange}
                            mostrarborde={false}/>
                        </Grid>  
              </Grid> 
                   <Grid item xs={1} className="d-flex justify-content-between align-items-center">
                  </Grid>
                   <Grid item xs={2} className="d-flex justify-content-between align-items-center">     

                  </Grid>

                  
                  <Grid item xs={!isMobile()?3:2} className="p-1">
                  </Grid>

                  <Grid item xs={!isMobile()?2:3}  className="d-flex justify-content-between align-items-center">             
                   <Button type="button"  color="primary"  disabled={!this.state.changingWorkData || disabledEdit}
                    onClick={async () => {  this.handleReloadDataExpedientOriginal() }}>
                    Cancelar
                  </Button> 
                  </Grid>
                  
                  <Grid item xs={4}  className="d-flex justify-content-between align-items-center">    
                  <Button type="submit" color="primary" style={{textAlign:"right",marginRight:5}} disabled={this.state.isUpdating || !this.state.changingWorkData || disabledEdit } 
                  >
                    <Translate id="languages.generalButton.generalButton" /> <Check />
                  </Button>    
                  </Grid> 
                                                             
                  

              </Grid>
            
          </ValidatorForm>


          <Confirmation openConfirmation={this.state.showMessageTramitado}
                    title={'Incidencias' }
                    onlyAccept={true} /*solo se avisa*/
                    buttonVariant={"text"}
                    confirmationMessage={<Translate id="languages.messages.expedienteHaiTrabajosTramitados"/>}                   
                    aceptConfirmation={async () => {                                                    
                        this.setState({showMessageTramitado: false}) 
                    }}
                    />
        </Paper>
        
      </div>
    )
  }
}

Substituciones.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({      
})

const mapDispatchToProps = {
  editExpedienteEnTrabajo: dispatchEditExpedienteEnTrabajo,
  fetchErrorExpediente  
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(Substituciones)));