import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Translate,withLocalize } from "react-localize-redux";
import globalTranslations from "./resources.json";
import { renderToStaticMarkup } from "react-dom/server";
import {Grid, Typography} from "@material-ui/core";
import AppHeader from "./containers/AppHeader";
import Main from "./Main";
import "./index.css";
import Error from "./components/Errors";
import {connect} from "react-redux";
import {Breadcrumbs} from "react-breadcrumbs-dynamic";
import {blue} from '@material-ui/core/colors';
import {Breadcrumb as BootstrapBreadcrumb} from "reactstrap";
import LoadingFiles from "./components/FileUpload/loadingFiles"
import {setCookie} from "./reducers/userExport";
//import {getCookie} from "./reducers/userExport";
import {isMobile} from "./api/index";

const mapStateToProps = state => ({
    idiomaFavorito: state.user.DatosConfiguracionesUsuario.Idioma_Predefinido
});

const styles = theme => ({
        uploadFile:{
            position:'fixed',
            bottom:15,
            width:150,
            right:10
        },
        panelCentralFijarCabecera:{ ////   se fija panel central partir de 130px que es el tamaño de la cabecera, con lo que se consigue el efecto de que lo fijado es la cabecera
            height: `calc(100% - 132px)`, 
            overflow:'auto',                              
            position:'fixed',
            width:'100%' ,
            bottom: 0            
        }
});

class App extends Component {
    constructor(props) {
        super(props);

        this.props.initialize({
            languages: [
                { name: <Translate id="languages.header.castellano" />, code: "1" },
                { name: <Translate id="languages.header.gallego" />, code: "2" }
            ],
            translation: globalTranslations,
            options: { renderToStaticMarkup,
                defaultLanguage: this.props.idiomaFavorito ? this.props.idiomaFavorito.toString() : 2}
        });
    }

    async componentDidMount(){
        ////cargamos lenguaje      
              //await this.props.setActiveLanguage(this.props.idiomaFavorito ? this.props.idiomaFavorito.toString() :2)           
              if (this.props.history.location.pathname == "/" && localStorage.getItem('idiomaFavoritoEstablecido') =='0')
                {    setCookie("language",this.props.idiomaFavorito)
                    await this.props.setActiveLanguage(this.props.idiomaFavorito ? this.props.idiomaFavorito.toString() :2)
                    await localStorage.setItem('idiomaFavoritoEstablecido', '1');
                }  
    }

    // async UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    //     if (this.props.history.location.pathname == "/" && localStorage.getItem('idiomaFavoritoEstablecido') =='0')
    //     {    setCookie("language",this.props.idiomaFavorito)
    //         await this.props.setActiveLanguage(this.props.idiomaFavorito ? this.props.idiomaFavorito.toString() :2)
    //         await localStorage.setItem('idiomaFavoritoEstablecido', '1');
    //     }
    // }
  

    render() {
        let { classes } = this.props;
        let isMobile_=isMobile()
        let enLogin=(this.props.history.location.pathname === "/login")
        let enVisualizarExp=this.props.history.location.pathname.startsWith('/visualizar-expediente/')
       
        return (
            
            <Grid style={{width: '100%'}} >
                { !enLogin ?
                    <div id="encabezado">
                    <Grid>
                        <AppHeader/>
                        {true &&
                        <Breadcrumbs
                            //separator={<b style={{padding: 6}}> // </b>}
                            separator={<b style={{paddingLeft: 4, paddingRight: 8, color: 'silver'}}> / </b>}
                            item={Link}
                            finalItem={Typography}
                            finalProps={{
                                style: {fontWeight: 500,
                                    fontSize: 20,
                                    display: !isMobile_?"flex":null,
                                    alignItems: "center"
                                   }
                            }}
                            container={BootstrapBreadcrumb}
                            containerProps={{style: {
                                    alignItems: "center",
                                    paddingLeft: 32,
                                    color: blue[500],
                                    border: "1px solid #e0e0e0",
                                    boxShadow: "0px 6px 5px -4px rgba(0, 0, 0, 0.3)",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "inherit",
                                    marginBottom: 4,
                                    fontSize: 20,
                                    fontWeight: 500,
                                    minHeight:'56px'
                                    //fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                }}}
                            />}
                            </Grid>
                    </div>
                    : ""

                }
              

               <div style={{backgroundColor:enVisualizarExp?'#f5f5f5':null}} className={isMobile_ || enLogin ?'':classes.panelCentralFijarCabecera} >
                <Grid item>
                    <Main/>
                </Grid>
                <Grid>
                    <Error/>
                </Grid>
                <LoadingFiles/>
              </div> 

            </Grid>
        );
    }
}

export default withRouter(connect(mapStateToProps)(withLocalize(withStyles(styles)(App))));
