import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    fetchFasesTrabajos, fetchTipoAutorizacion, fetchTipoTrabajo, fetchGruposRaiz,
    fetchComunicacionencargo, dispatchError, saveTiposObras
} from "../../actions/trabajos";

import { dispatchAddAutorizacion } from "../../actions/expedientes";
import { connect } from "react-redux";
import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Tooltip,IconButton,RadioGroup, FormControlLabel,Radio,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Container } from "reactstrap";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Next from '@material-ui/icons/NavigateNext';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/InfoRounded'; 
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { grey } from '@material-ui/core/colors';
import { withRouter } from 'react-router-dom';
import InfoCarpetas from "../AsistenteTrabajo/infoCarpetas"
import { groupBy, filter } from 'lodash';
import ApiCoag from "../../api/ApiCoag";
import {isMobile} from "../../api/index";



const styles = theme => ({
    root: {
        width: '100%',
    },
    gridRoot: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    formControl: {
       // width: '100%',
        marginTop: 12
    },
    margin: {
        marginTop: 30
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        minHeight: 42
    },
    title2: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(15),
        minHeight: 42
    },
    subtitle2: {       
        textTransform: 'capitalize',
        
    },
    panelExapnded: {
        border: '1.2px solid ' + theme.palette.primary.main,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(15),
        margin: '0',
        minHeight: 42
    },
    panelBody: {
        backgroundColor: grey[50]
    },
    selectTitle: {
        color: theme.palette.secondary.main + ' !important',
        textTransform: 'uppercase',
    },
    titleMainPanel: {
        borderBottom: '1.5px solid ' + grey[100],
    },
    marginPanel: {
        margin: '15px 0px 0 0'
    },
    marginPanelMobile: {
        margin: '4px 0px 0 0'
    },
    paddingChildPanel: {
        padding: 15
    },
    assistence: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        float: 'right'
    },
    button: {
        margin: theme.spacing.unit,
    },
    buttonEdit: {
        border: "1px solid",
        padding: 6,
        margin: 6
      },
    right: {
        float: 'right',
        marginTop: 20
    },
    labelButton: {
        textTransform: 'capitalize',
      }
    
});



const mapStateToProps = (state) => (
    {
        tiposTrabajos: state.trabajos.tiposTrabajos ? state.trabajos.tiposTrabajos.GruposTematicos : [],
        tiposAutorizacion: state.trabajos.tiposAutorizacion ? state.trabajos.tiposAutorizacion.Tipos_autorizacion_municipal : [],
        gruposRaiz: state.trabajos.gruposRaiz ? state.trabajos.gruposRaiz.GruposRaiz : [],
        currentExpediente: state.expedientes.ExpedientNew ? state.expedientes.ExpedientNew : {},
        tiposObras: state.trabajos.tiposObras ? state.trabajos.tiposObras : []
    }
);

const mapDispatchToProps = {    
    fetchTipoTrabajo: fetchTipoTrabajo,
    fetchTipoAutorizacion: fetchTipoAutorizacion,
    fetchFasesTrabajos: fetchFasesTrabajos,
    fetchGruposRaiz: fetchGruposRaiz,
    fetchComunicacionencargo: fetchComunicacionencargo,
    dispatchError: dispatchError,
    dispatchAddAutorizacion: dispatchAddAutorizacion,
    saveTiposObras: saveTiposObras    
};


class ComunicacionEncargo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: 'panel0',
            build: 0,
            formality: 0,
            expandedChild: null,
            swichTitleChild: '',
            relationWorks: [],
            tiposObra: [],
            Description: '',
            encomenda: this.props.encomenda,
            comunicacionencargo: [],
            indexCurrent: 0,
            isLoad: false,
            tramitacionesUrbanistica: [],
            inforCarpetas: [],
            showDetalles: false,
            idTipoTrabajo:0,
            trabajoTitulo : '',          
            comVisado:null,
            comRegistro:null,
            tipoObraSelected: (this.props.encomenda.EncomendaActual.length>0 ? true:false),
            tipoTramiteMunSelected:(this.props.encomenda.EncomendaActual.length>0 ? true:false),
            nombreGrupoTematico:'',
            mod:0
        };
    };

    async componentDidMount() {
        try {
            this.setState({ isLoad: true });
            this.transformGruposRaiz();
            this.setState({ isLoad: false });
            this.getTipoAclaracion();
            
        } catch (e) {

        }
    }

   

    async getTipoAclaracion() {       
              
        let response = await ApiCoag.tipos.guia.tipoAclaracion(6)
          
        if (response) {           
            this.setState({ aclaraciones: response.Aclaraciones[0].Nombre });
       }   
    }      
    
    

    async transformGruposRaiz() {

        if (this.props.gruposRaiz ) //&& this.props.gruposRaiz.length === 0) 
        {    
            await this.props.fetchGruposRaiz(this.props.activeLanguage.code);

        }

        await this.comunicacionEncargo();
    }

    //Funcion para obtener los tipos de trabajos
    async getTiposTrabajos(gruposRaiz) {
        if (this.props.tiposObras.some(x => x.Id_Tipo_Grupo_Raiz === gruposRaiz)) {
            let tiposTrabajos = this.props.tiposObras.find(x => x.Id_Tipo_Grupo_Raiz === gruposRaiz);
            return tiposTrabajos.tiposTrabajos;
        }
        else {
            let obras = await this.props.fetchTipoTrabajo(gruposRaiz, this.props.activeLanguage.code);
            let trabajos = obras && obras.tiposTrabajos ? obras.tiposTrabajos : null;
            if (trabajos) {
                this.props.saveTiposObras(obras);
            }
            return trabajos;
        }
    }

    async comunicacionEncargo() {

        

        let arrayRaiz = [];
        let gruposRaiz = this.props.gruposRaiz;
        let indexCurrent = 0;
        let isCurrent = false;
        let encomenda = this.state.encomenda.EncomendaActual.length > 0
            ? this.state.encomenda.EncomendaActual[0] : null;
        try{
            for (let i = 0; i < gruposRaiz.length; i++) {
                let value = gruposRaiz[i];
                //Separado para método que verifique la necesidad de carga
                await this.props.fetchTipoTrabajo(value.Id_Tipo_Grupo_Raiz, this.props.activeLanguage.code);
                let tiposTrabajos = this.props.tiposTrabajos;
               // let tiposTrabajos = await this.getTiposTrabajos(value.Id_Tipo_Grupo_Raiz);

                if (encomenda !== null) {
                    isCurrent = tiposTrabajos.some(x => x.Id_Tipo_Grupo_Tematico === encomenda.Id_Tipo_Grupo_Tematico);
                    indexCurrent = isCurrent ? i : indexCurrent;
                }
                let grupo = {
                    id: value.Id_Tipo_Grupo_Raiz,
                    name: value.Nombre,
                    tiposObra: tiposTrabajos,
                    tiposTramite: [],
                    //Cambiar a datos de la encomenda
                     
                    obraSelection: isCurrent ? encomenda.Id_Tipo_Grupo_Tematico : tiposTrabajos[0].Id_Tipo_Grupo_Tematico,
                   
                    //obraSelection: isCurrent ? encomenda.Id_Tipo_Grupo_Tematico :(value.Id_Tipo_Grupo_Raiz==1?null:tiposTrabajos[0].Id_Tipo_Grupo_Tematico),
                    //tramiteSelection: isCurrent ? encomenda.Id_Tipo_Autorizacion_Municipal : tiposTramite[0].Id_Tipo_Autorizacion_Municipal,
                    description: tiposTrabajos.length > 0 ? tiposTrabajos[0].Observaciones : '',
                    fasesTrabajos: [],
                    isSelected: i === 0
                };
                arrayRaiz.push(grupo);
                let tiposTramite = await this.loadDataAutorizacion(arrayRaiz, i);
                arrayRaiz[i].tiposTramite = tiposTramite;
                arrayRaiz[i].tramiteSelection = isCurrent
                    ? encomenda.Id_Tipo_Autorizacion_Municipal
                    : tiposTramite.length > 0
                        ? tiposTramite[0].Id_Tipo_Autorizacion_Municipal : null;
            }
        }catch (e) {
            console.log(e)
        }
        //md no expandimos a menos que ya existe una
        if (indexCurrent>0 || encomenda!=null)
        this.setState({ comunicacionencargo: arrayRaiz, expanded: `panel${indexCurrent}`, indexCurrent: indexCurrent });
        else
        this.setState({ comunicacionencargo: arrayRaiz, expanded: false, indexCurrent: indexCurrent });
        this.updateFaseTrabajo(indexCurrent);
    }

    async loadAutorizacionByGrupoTematico(comunicacion, index) {
        let result = await ApiCoag.tipos.guia.autorizacionmunicipal(comunicacion[index].obraSelection);
        if (result && result.Tipos_Autorizacion_Municipal) {
            comunicacion[index].tiposTramite = result.Tipos_Autorizacion_Municipal;
            this.setState({ comunicacionencargo: comunicacion })
        }
        else {
            comunicacion[index].tiposTramite = [];
            this.setState({ comunicacionencargo: comunicacion })
        }
    }

    async loadDataAutorizacion(comunicacion, index) {
        let result = await ApiCoag.tipos.guia.autorizacionmunicipal(comunicacion[index].obraSelection);
        if (result && result.Tipos_Autorizacion_Municipal) {
            return result.Tipos_Autorizacion_Municipal;
        }
        else {
            return [];
        }
    }


    handleChange = (panel, index) => async (event, expanded) => {
        let updateGrupoRaiz = [];
        Object.assign(updateGrupoRaiz, this.state.comunicacionencargo);
        for (let i = 0; i < updateGrupoRaiz.length; i++)
            updateGrupoRaiz[i].isSelected = i === index;

        await this.setState({
            expanded: expanded ? panel : false,
            comunicacionEncargo: updateGrupoRaiz,
            showDetalles:false,
            tipoObraSelected: (index > 0 ? true : false),
            tipoTramiteMunSelected: (index > 0 ? true : false),
            nombreGrupoTematico:  updateGrupoRaiz[index].name
        });
    };

    handleChildChange = panel => (event, expanded) => {
        this.setState({
            expandedChild: expanded ? panel : false,
            //swichTitleChild: expanded && panel === 'panel12' ? 'ocultar' : 'ver'
            swichTitleChild: expanded  && panel === 'panel12' ? '' : ''
        });
    };

    handleBuildSelect = index => async (event) => {
       
        let comunicacionEncargo = this.state.comunicacionencargo[index];
        let id = event.target.value;
        //Llamar directamente a la api para obtener las fases de trabajo

        let indexTipoObra = comunicacionEncargo.tiposObra.findIndex(x => x.Id_Tipo_Grupo_Tematico === id);
        let updateGrupoRaiz = [];
        Object.assign(updateGrupoRaiz, this.state.comunicacionencargo);
        updateGrupoRaiz[index].obraSelection = id;
        updateGrupoRaiz[index].description = comunicacionEncargo.tiposObra[indexTipoObra].Observaciones;
        

        updateGrupoRaiz[index].tiposTramite = await this.loadDataAutorizacion(updateGrupoRaiz, index);
        let selectTramite = updateGrupoRaiz[index].tiposTramite.length > 0
            ? updateGrupoRaiz[index].tiposTramite[0].Id_Tipo_Autorizacion_Municipal : null;
        if (selectTramite !== null) {
            let result = await ApiCoag.tipos.guia.fasesTrabajos(id, selectTramite);

            updateGrupoRaiz[index].fasesTrabajos = result ? result.FasesTrabajos : [];
        }
        updateGrupoRaiz[index].tramiteSelection = selectTramite;
        this.setState({ comunicacionencargo: updateGrupoRaiz, showDetalles:false, tipoObraSelected:true,  nombreGrupoTematico: comunicacionEncargo.tiposObra[indexTipoObra].Nombre});


        if  (selectTramite !==null) 
           this.setState({  tipoTramiteMunSelected: updateGrupoRaiz[index].tiposTramite.length == 1 ?  true : false }) 
        else    
            this.setState({  tipoTramiteMunSelected: false }) 
    };

    handleFormalitySelect = index => async (event) => {
        let comunicacionEncargo = this.state.comunicacionencargo[index];
        let id = event.target.value;
        //Llamar directamente a la api para obtener las fases de trabajo
        let result = await ApiCoag.tipos.guia.fasesTrabajos(comunicacionEncargo.obraSelection, id);
        let updateGrupoRaiz = [];
        Object.assign(updateGrupoRaiz, this.state.comunicacionencargo);
        updateGrupoRaiz[index].tramiteSelection = id;
        updateGrupoRaiz[index].fasesTrabajos = result ? result.FasesTrabajos : [];
        await this.setState({ comunicacionencargo: updateGrupoRaiz, tipoTramiteMunSelected:true });
    }

    countItems = (relations) => {
        return relations.reduce((prev, item) => prev + item.items.length, 0);
    }

    /**
     *  El resultado será un array de array
     * @returns {*[]} objeto {category : '', items: [{Name: '', id: ''}]}
     */
    transformRelationsWorks = (index) => {
        var relations = [];
        var relationsData = [[], []];
        if (!this.state.comunicacionencargo[index].fasesTrabajos)
            return relationsData;

        this.state.comunicacionencargo[index].fasesTrabajos.map(value => {
            const { Fase } = value;
            if (relations.filter(rel => rel.category === Fase).length === 0) {
                relations.push({ category: Fase, items: [] });
            }
            return false
        })
        this.state.comunicacionencargo[index].fasesTrabajos.filter(value => {
            const { Fase, Trabajo_Titulo, Id_Tipo_Trabajo, comrexistro, comvisado, Obligatorio, rexistro_obrigatorio, visado_obrigatorio,Solo_Modificados } = value;
            relations.forEach(element => {
                if (element.category === Fase) {
                    element.items.push({ Name: Trabajo_Titulo, id: Id_Tipo_Trabajo, comrexistro:  comrexistro, comvisado: comvisado, obrigatorio: Obligatorio, rexistro_obrigatorio:rexistro_obrigatorio, visado_obrigatorio:visado_obrigatorio,Solo_Modificados:Solo_Modificados });
                }
            });
        })

        relations.forEach(element => {
            if (relationsData[0].length === 0) {
                relationsData[0].push(element);
            }
            else if (relationsData[1].length === 0) {
                relationsData[1].push(element);
            }
            else {
                /*if (this.countItems(relationsData[0]) <= this.countItems(relationsData[1])) {
                    relationsData[0].push(element);
                }
                else {*/
                    relationsData[1].push(element);
                //}
            }
        })
        return relationsData;
    }

    

    async updateFaseTrabajo(index) {
        let comunicacionencargo = this.state.comunicacionencargo[index];
        let result = await ApiCoag.tipos.guia.fasesTrabajos(comunicacionencargo.obraSelection, comunicacionencargo.tramiteSelection);
        let updateGrupoRaiz = [];
        Object.assign(updateGrupoRaiz, this.state.comunicacionencargo);
        updateGrupoRaiz[index].fasesTrabajos = result ? result.FasesTrabajos : [];
        this.setState({ comunicacionencargo: updateGrupoRaiz, indexCurrent: index });
    }

    renderRelationWorks(index) {
        //const {classesButton} = useStyles();

        const { classes } = this.props;
        let relationsData = this.transformRelationsWorks(index);
        return (

            <Grid container spacing={!isMobile() ? 22: 4}>   
                          
        <Grid item xs={12 /*md 6 con 12 se ve también bien en movil*/}>
                    {                        
                        relationsData[0].map((value, index) => {
                            return <Grid item xs={12} key={index} className={classes.paddingChildPanel}>
                                <Typography  variant="h6" gutterBottom>{value.category !='' ? value.category : ''}</Typography>
                                {value.items.map((item, itemIndex) => {
                                    
                                    return (
                                        
                                      
                                       <Typography className={!isMobile() ? "px-1 text-left" : "text-center" } style={{ fontSize: 11 }} key={itemIndex} variant="subtitle1" gutterBottom>
                                         <Button   classes={{      label: classes.labelButton   }}     color="primary" onClick={() => { 
                                            this.setState({ mod:0 });
                                            this.mostrarDetallesCarpetasFirmasRequeridas(item.id,0,0,item.Name,item.comvisado,item.comrexistro,item.Solo_Modificados)}}>
                                         {item.Name}
                                       </Button>
                                       {/* !isMobile() && item.obrigatorio && item.visado_obrigatorio ? ' (obrigatorio visado) ':'' */}
                                       {/* !isMobile() && item.obrigatorio && item.rexistro_obrigatorio ? ' (obrigatorio rexistro) ':'' */}
                                       
                                       </Typography>
                                                                      
                                    /*<Typography style={{ fontSize: 12 }} key={itemIndex} variant="subtitle1" gutterBottom>{item.Name}</Typography>*/
                                    
                                
                                    )
                                })}
                            </Grid>
                        })
                    }
                </Grid>
                <Grid item xs={12 /*md 6 con 12 se ve también bien en movil*/}>
                    {
                        relationsData[1].map((value, index) => {
                            return <Grid item xs={12} key={index} className={classes.paddingChildPanel}>
                                <b><Typography variant="h6" gutterBottom>{value.category !='' ? value.category : ''}</Typography></b>
                                {value.items.map((item, itemIndex) => {
                                    return (
                                       
                                    <Typography  className={!isMobile() ? "px-1 text-left" : "text-center" } style={{ fontSize: 11 }} key={itemIndex} variant="subtitle1" gutterBottom>                                         
                                           <Button   classes={{      label: classes.labelButton   }}     color="primary" onClick={() => {
                                            this.setState({ mod: 0 });
                                            this.mostrarDetallesCarpetasFirmasRequeridas(item.id,0,0,item.Name,item.comvisado,item.comrexistro,item.Solo_Modificados)}}>
                                         {item.Name}
                                       </Button>
                                       { /*!isMobile() && item.obrigatorio && item.visado_obrigatorio ? ' (obrigatorio visado) ':''*/}
                                       { /*!isMobile() && item.obrigatorio && item.rexistro_obrigatorio ? ' (obrigatorio rexistro) ':''*/}
                                                                     
                                       </Typography>
                                       
                                    )
                                })}
                            </Grid>
                        })
                    }
                </Grid>
            </Grid>
        )
    }

    /**Función que valida la continuación en el wizard */
    handleNext() {
        try
        {
      let currentGrupoRaiz = this.state.comunicacionencargo[this.state.indexCurrent];

        if  (!this.state.tipoObraSelected ||  !this.state.tipoTramiteMunSelected)                   
        { if (!this.state.tipoObraSelected) 
             {this.props.dispatchError("Selecciona un tipo de obra")
              return null}
          if (!this.state.tipoTramiteMunSelected) this.props.dispatchError("Selecciona un tipo de tramitación")
        } 
        
        // if ( this.state.encomenda.EncomendaActual.length == 0 && currentGrupoRaiz.obraSelection==1 && this.state.expanded == false  )
         //{ this.props.dispatchError("Selecciona un tipo de obra")          
          //  } 

         else {


            //1- Valida
            if (currentGrupoRaiz && currentGrupoRaiz.fasesTrabajos.length === 0) {
                //Coger el mensaje del recurso de traducción
                this.props.dispatchError( this.props.translate("languages.messages.comunicationValidation" ))
            }
            else {
                //2- Actualizar la encomenda
                let newEncomenda = {};
                Object.assign(newEncomenda, this.state.encomenda);
           
                if (newEncomenda.EncomendaActual.length > 0) {
                    newEncomenda.EncomendaActual[0].Id_Tipo_Grupo_Tematico = currentGrupoRaiz.obraSelection;
                    newEncomenda.EncomendaActual[0].Id_Tipo_Autorizacion_Municipal = currentGrupoRaiz.tramiteSelection;
                }
                else {
                    newEncomenda.EncomendaActual.push({
                    Id_Tipo_Grupo_Tematico: currentGrupoRaiz.obraSelection,
                    Id_Tipo_Autorizacion_Municipal: currentGrupoRaiz.tramiteSelection,
                    Id_Tipo_Fase: 1,
                    Id_Tipo_Trabajo: 219,/*219 significa que es una encomenda*/
                    Id_Tipo_Tramite: 0, /*0 Visado normal*/
                    Id_Expediente: this.props.match.params.id
                    });
                }

                this.props.handleChangeTipoExpediente(newEncomenda);
        }
     }
    }
        catch (e) {
    console.log(e)
    alert(e)
        }
    }



    /*md*/

    async getInfoCarpetasTrabajo(id_tipo_trabajo, id_tipo_tramite, es_modificado) {
        let inforCarpetas = [];
        this.setState({ gettingCarpetas: true });
        let response = await ApiCoag.tipos.guia.infoCarpetasDeTrabajo(id_tipo_trabajo, id_tipo_tramite ? id_tipo_tramite : 0, es_modificado);
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
           // this.props.fetchErrorExpediente(response);
           
        }
        else {
            let carpetas = response.Carpetas;
            let father = filter(carpetas, { 'Id_Documentacion_Padre': null });
            let children = groupBy(carpetas, 'Id_Documentacion_Padre');
            let fatherChildren = [];
            father.map(value => {
                value["children"] = children[value.Id_Documentacion]
                fatherChildren.push(value);
    
            })
            inforCarpetas = fatherChildren;
          
        }
        this.setState({ inforCarpetas: inforCarpetas, gettingCarpetas:false });
    }
    
    mostrarDetallesCarpetasFirmasRequeridas(idTipoTrabajo,idTipoTramite,bEsModificado,trabajoTitulo,comVisado,comRegistro,soloMod){         
       
       // this.lanzarcalculo()
      
      this.getInfoCarpetasTrabajo(idTipoTrabajo,idTipoTramite,bEsModificado)
    
    
      this.setState({
          showDetalles:true, 
          idTipoTrabajo: idTipoTrabajo,              
          trabajoTitulo:trabajoTitulo,               
          comVisado:comVisado,
          comRegistro:comRegistro,
          idTipoTramite:idTipoTramite,
          soloMod:soloMod
      })
            
    }

    handleChangeMod = () => (event) => {
        let v=0;
        if ("nuevo" === event.target.value) {
            v=0;}
        if ("mods" === event.target.value) {
            v=1;}
        if ("modb" === event.target.value) {
            v=2;}
       
        this.setState({ mod: v });

        this.mostrarDetallesCarpetasFirmasRequeridas(this.state.idTipoTrabajo,this.state.idTipoTramite,v>0?1:0,this.state.trabajoTitulo,this.state.comVisado,this.state.comRegistro,this.state.soloMod) ;                                                                                                                                                                                              
        
    }

    /*async lanzarcalculo() {
           
        let datosTecnicosTrabajo = await ApiCoag.expedientes.trabajo.datosTecnicos.obtener(8, 1);
      
        datosTecnicosTrabajo.Caracteristicas = datosTecnicosTrabajo.Caracteristicas[0]
        datosTecnicosTrabajo.Parametros = datosTecnicosTrabajo.Parametros[0]
       

        if (datosTecnicosTrabajo.Parametros == undefined) {

            datosTecnicosTrabajo.Parametros =
                {
                    "Superficie_Bruta": 0,
                    "Edificabilidad": 0,
                    "Id_Uso_Caracteristico": 1

                }
        }


        let response = await ApiCoag.expedientes.trabajo.datosTecnicos.calcular(datosTecnicosTrabajo);

        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
           // this.props.fetchErrorExpediente(response)
            this.setState({
                'respuestaCalculo': false,
            })

        } else {
            this.setState({
                'respuestaCalculo': response,
                isModified:false,
                allowSave:true
            })

        }


    }*/
    
    
    
    handleShowDialog(title, content) {
        this.setState({dialogOpen: true, dialogTitle: title, dialogContent: content});
    }

   handleCloseDialog = () => {
       this.setState({ dialogOpen: false });
   };


    

    detallesCarpetasFirmasRequeridas() {

        let { classes } = this.props;

        if(this.state.showDetalles){

                  
            return (<div>
               
                        <Grid container spacing={0}>
                      

                       <Grid item xs={11} >
                          <Typography  variant="h5" gutterBottom className={!isMobile() ? "pt-4 pl-2 pb-0 ": "pt-4 pb-2 pl-2 pr-0 "}>
                               {/*<Translate id="languages.comunicacionEncargo.CarpetasFirmasRequeridas" /> */}
                               {this.state.trabajoTitulo}                             
                         </Typography   >
                        </Grid> 
                        
                        <Grid item xs={1} style={{ textAlign:"right",paddingRight:0,paddingTop:5, paddingBottom:0}} >
                        {!isMobile() ?
                                    
                        <Tooltip title={<Translate id="languages.comunicacionEncargo.buttonVolverAListadeTrabajos" />} >
                        <IconButton  className={classes.buttonEdit} color="primary" aria-label="Close"  
                        onClick={async () => { this.setState({ showDetalles: false });  }}> 
                        <Close/>
                        </IconButton>
                        </Tooltip> 
                        : <Tooltip title={'Cerrar'}>
                        <IconButton  className={classes.buttonEdit} color="primary" aria-label="Close"  
                         onClick={async () => { this.setState({ showDetalles: false });  }}> 
                         <Close/>
                         </IconButton>
                         </Tooltip> }
                        
                        </Grid>
                        <Grid item xs="12" style={{position:"relative"}}>
                         <Typography  variant="subtitle2" className={!isMobile() ? "pt-1 pl-2 pb-0 ": "pt-2 pb-2 pl-2 pr-0"}>

                     


                                                            <FormControl >
                                                      
                                                                                <RadioGroup 
                                                                                    style={{marginLeft:"10px",marginRight:"30px"}} 
                                                                                    aria-label="Gender"
                                                                                    name="gender1"
                                                                                    className={classes.group}
                                                                                    value={this.state.mod==0 && this.state.soloMod!=1? "nuevo":this.state.mod==1?"mods":"modb"}                                                                                    
                                                                                    row
                                                                                    onChange={this.handleChangeMod()} >
                                                                                                            
                                                                                    {this.state.soloMod!=1 &&                        
                                                                                    <FormControlLabel value="nuevo"                                                                                                      
                                                                                                      control={<Radio style={{paddingRight:"1px"}} color={ this.state.mod==0 || this.state.soloMod==0? "primary" : "secondary"}/>}
                                                                                                      label={<Translate id="languages.trabajo.nuevoTrabajoTitle"/>}
                                                                                                      className="m-0 text-uppercase font-weight-bold"
                                                                                                      />}
                                                                                    {this.state.soloMod!=0 &&                                                                                                               
                                                                                    <FormControlLabel                                                                                                                                                               
                                                                                        value="mods"                                                                                        
                                                                                        control={<Radio style={{paddingRight:"1px"}} color={this.state.mod==1 ?"primary":"secondary"}/>}
                                                                                        label={"Modificado sustancial"}
                                                                                        className="m-0  text-uppercase font-weight-bold" />}                                                                                   
                                                                                      
                                                                                     {this.state.soloMod!=0 &&                                                                                                               
                                                                                    <FormControlLabel                                                                                                                                                                    
                                                                                        value="modb"                                                                                        
                                                                                        control={<Radio style={{paddingRight:"1px"}} color={this.state.mod==2 || (this.state.mod==0 && this.state.soloMod==1)?"primary":"secondary"}/>}
                                                                                        label={"Corrección básica"}
                                                                                        className="m-0  text-uppercase font-weight-bold" />}     
                                                                                </RadioGroup>      


                                                                                   
                                                                            </FormControl> 
                         
                      
                
                            
                             {this.state.comVisado != null &&
                                <Button size="sm" variant= "contained"  color={this.state.idTipoTramite ==0 ? "primary":"light"} className={classes.button}  onClick={async () => {   this.mostrarDetallesCarpetasFirmasRequeridas(this.state.idTipoTrabajo,0,this.state.mod>0?1:0,this.state.trabajoTitulo,this.state.comVisado,this.state.comRegistro,this.state.soloMod)}}> 
                                    Visado                  
                             </Button>  }

                             { this.state.comVisado != null && '(Compl: ' + (this.state.comVisado == 0 && this.state.Id_Metodo_Calculo!=null? (this.state.mod>1 || (this.state.mod==0 && this.state.soloMod==1)?15:'Variable')+')': (this.state.mod>0?15:this.state.comVisado) + ')')}
                            
                             {this.state.comRegistro != null &&
                               <Button size="sm" style={{marginLeft:"12px"}} variant= "contained"   color={this.state.idTipoTramite ==2 ?  "primary":"light"} className={classes.button}  onClick={async () => {   this.mostrarDetallesCarpetasFirmasRequeridas(this.state.idTipoTrabajo,2,this.state.mod>0?1:0,this.state.trabajoTitulo,this.state.comVisado,this.state.comRegistro,this.state.soloMod)}}> 
                               <Translate id="languages.crearTrabajo.labelRegistro" />   
                             </Button> }                                        
                             {this.state.comRegistro != null && '(Compl: ' + (this.state.comRegistro == 0 && this.state.Id_Metodo_Calculo!=null? 'Variable)': this.state.comRegistro + ')')}                         


                             <IconButton   color="primary" aria-label="Info"   style={{ position: "absolute", right: "0.5em", top: "0.25em"}} >
                                                                                        <Info  onClick={()=>{this.handleShowDialog("Aclaracións",this.state.aclaraciones)}}></Info>
                                                                                    </IconButton>
                         
                       
                          </Typography>                      
                                                                            

                        </Grid>

                     

                         

                         <Grid xs={12} className={!isMobile() ? "pb-2 pl-2 pr-2 " : "pb-2 pl-0 pr-0 "}>
                            
                           
                            {this.state.gettingCarpetas ? 
                            (!this.state.showDetalles ? null : <InfoCarpetas  trabajoTitulo={this.state.trabajoTitulo}    inforCarpetas={this.state.inforCarpetas} />) 
                              :                            
                             <InfoCarpetas  trabajoTitulo={this.state.trabajoTitulo} inforCarpetas={this.state.inforCarpetas}  />
                            }
                            
                          </Grid>
                        </Grid> 

                
                
            </div>);
        }

    }


    render() {
        let { classes } = this.props;
        let { expandedChild, expanded } = this.state;

        return (
            <Container className={classes.margin}>
                <ExpansionPanel expanded={true}>
                    <ExpansionPanelSummary className={classes.titleMainPanel}
                        style={{ minHeight: 48, height: 48 }}>
                        <Grid container spacing={16} style={{ padding: '0 15px' }}>
                            <Grid item xs={12}>
                                {!this.props.catalogo ? 
                                <Translate id="languages.comunicacionEncargo.titleEligeTipoExpediente" /> :
                                <Translate id="languages.comunicacionEncargo.titleEligeTipoExpedienteCatalogo" /> 
                                   }
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                    {/*this.detallesCarpetasFirmasRequeridas()*/}

                        {this.state.isLoad ? <Grid item xs={12} className="text-center py-2"><CircularProgress /></Grid> :
                            <Grid container spacing={!isMobile() ? 24:20} className={`${classes.marginPanel} py-0`}>
                                <Grid item xs={12}>
                                    {
                                        this.state.comunicacionencargo && this.state.comunicacionencargo.map((value, index) => {
                                            return <ExpansionPanel key={index} expanded={expanded === `panel${index}`} onChange={this.handleChange(`panel${index}`, index)}>
                                                <ExpansionPanelSummary style={{ minHeight: 48, height: 48 }}
                                                    expandIcon={expanded === `panel${index}` ? <ExpandMoreIcon color="primary" /> : <ExpandMoreIcon color="secondary" />}
                                                    className={expanded === `panel${index}` ? classes.panelExapnded : classes.title}
                                                    onClick={() => { this.updateFaseTrabajo(index) }}>
                                                    {value.name}
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.panelBody}>

                                                    <div className={classes.gridRoot}>
                                                        <Grid container spacing={!isMobile() ? 24 :20}>    

                                                        {index === 0 ?                                                                                                                                                         
                                                            <Grid item  xs={12}  >
                                                                  
                                                                <FormControl  style={!isMobile() ? { width: "50%" } : { width: "260px" }} className={classes.formControl}>
                                                                {!this.state.tipoObraSelected && <InputLabel className={classes.selectTitle} htmlFor="build">
                                                                        <Translate id="languages.comunicacionEncargo.fieldSelTipoObra" />
                                                                    </InputLabel>  }                                                       
                                                                    <Select
                                                                        value={this.state.tipoObraSelected ? this.state.comunicacionencargo[index].obraSelection : null}
                                                                        onChange={this.handleBuildSelect(index)}                                                                       
                                                                        inputProps={{ name: 'build', id: 'build-type' }}>
                                                                        {value.tiposObra.map((value, index) => {
                                                                            return <MenuItem key={index} value={value.Id_Tipo_Grupo_Tematico}>{value.Nombre}</MenuItem>
                                                                        })}                                                                    
                                                                    </Select>                                                                                                                                                      
                                                                </FormControl>
                                                                                                                              
                                                            </Grid>
                                                             : null   }

                                                                                                                                                             

                                                            {/* !this.state.showDetalles ?   <Grid item  xs={12} ></Grid> : null*/}
                                                            


                                                            <Grid item xs={!isMobile() ? 12 :12}>
                                                                
                                                                {
                                                                !this.state.showDetalles ?                                                              
                                                                this.state.tipoObraSelected && <ExpansionPanel expanded={/*(true && expandedChild == null) || */expandedChild === 'panel11' } onChange={this.handleChildChange('panel11')}>
                                                                    <ExpansionPanelSummary expandIcon={expandedChild === 'panel11' ? <ExpandMoreIcon color="primary" /> : <ExpandMoreIcon color="secondary" />}
                                                                        className={expandedChild === 'panel11' ? classes.panelExapnded : classes.title2}
                                                                        style={{ minHeight: 48, height: 48 }}>


                                                                    { expandedChild === 'panel11' ?'':'Ver '} <Translate style={{ valign:"middle" }} id="languages.comunicacionEncargo.titleDescription" />  {this.state.nombreGrupoTematico !='' ? '  "'+ this.state.nombreGrupoTematico +'"' :''}                                                                        

                                                                    </ExpansionPanelSummary>
                                                                    <ExpansionPanelDetails>
                                                                    {this.state.tipoObraSelected && <ReactQuill value={this.state.comunicacionencargo[index].description} readOnly theme='bubble' />}
                                                                    </ExpansionPanelDetails>
                                                                </ExpansionPanel>: null }


                                                                {index === 0 ? <br></br> : null}

                                                                {index === 0  ? 
                                                            <Grid item  xs={12} >
                                                            
                                                                <FormControl  style={!isMobile() ? { width: "50%" } : { width: "260px" }} className={classes.formControl}>
                                                                {!this.state.tipoTramiteMunSelected && <InputLabel className={classes.selectTitle} htmlFor="tramit-type">
                                                                        <Translate id="languages.comunicacionEncargo.fieldSelTipoTramitacionMunicipal" />
                                                                    </InputLabel>}
                                                                    <Select
                                                                         value={this.state.tipoTramiteMunSelected ? this.state.comunicacionencargo[index].tramiteSelection: null}
                                                                        onChange={this.handleFormalitySelect(index)}
                                                                        inputProps={{ name: 'tramite', id: 'tramit-type' }}>
                                                                        {value.tiposTramite.map((value, index) => {
                                                                            return <MenuItem key={`item_${index}`} value={value.Id_Tipo_Autorizacion_Municipal}>{value.Nombre}</MenuItem>
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                                  
                                                            </Grid> 
                                                            : null   }             

                                                        {index === 0 ? <br></br> : null}

                                                                {
                                                                    this.state.tipoObraSelected && this.state.tipoTramiteMunSelected && this.state.comunicacionencargo[index].fasesTrabajos.length>0 && this.state.showDetalles == false ?
                                                                   
                                                                    <ExpansionPanel expanded={expandedChild === 'panel12'}
                                                                        onChange={this.handleChildChange('panel12')} >
                                                                        <ExpansionPanelSummary expandIcon={expandedChild === 'panel12' ? <ExpandMoreIcon color="primary" /> : <ExpandMoreIcon color="secondary" />}
                                                                            className={expandedChild === 'panel12' ? classes.panelExapnded : classes.title2}
                                                                            style={{ minHeight: 48, height: 48 }}>
                                                                            {//this.state.swichTitleChild+ ' '
                                                                             expandedChild === 'panel12' ?'':'Ver '
                                                                            }                                                                            
                                                                            <Translate id="languages.comunicacionEncargo.titleTrabajoPosiblesTramitar" />                                                                           
                                                                        </ExpansionPanelSummary>
                                                                        <ExpansionPanelDetails>
                                                                            <Grid container spacing={!isMobile() ? 24 :4} className={!isMobile() ? classes.marginPanel : classes.marginPanelMobile}>
                                                                                {
                                                                                      this.state.tipoTramiteMunSelected && this.renderRelationWorks(index)
                                                                                }
                                                                            </Grid>
                                                                        </ExpansionPanelDetails>
                                                                    </ExpansionPanel>

                                                                      :   this.detallesCarpetasFirmasRequeridas()


                                                                }
                                                               
                                                            </Grid>
                                                        </Grid>
                                                    </div>


                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        })
                                    }
                                </Grid>

                                <Grid item xs={12}>
                                 <Dialog
                                     open={this.state.dialogOpen}
                                     onClose={this.handleCloseDialog}
                                     aria-labelledby="alert-dialog-title"
                                     aria-describedby="alert-dialog-description"
                                 >
                                     <DialogTitle id="alert-dialog-title">
                                         {this.state.dialogTitle}
                                     </DialogTitle>
                                     <DialogContent>
                                         <DialogContentText id="alert-dialog-description">
                                             <ReactQuill value={this.state.dialogContent} readOnly theme='bubble' />
                                         </DialogContentText>
                                     </DialogContent>
                                     <DialogActions>
                                         <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
                                             <Translate id="languages.generalButton.aceptar"/>
                                         </Button>
                                     </DialogActions>
                                 </Dialog>
                             </Grid>

                                {!this.props.catalogo  ?
                                <Grid item xs={12} className="d-flex justify-content-between">
                                
                                    <Button color="primary" className={classes.button} onClick={() => { this.props.history.push("/visualizar-expediente/" + this.props.match.params.id) }}>
                                        <Translate id="languages.generalButton.cancel" /><Close className={classes.rightIcon} />
                                    </Button>

                                                               
                                    <Button variant="contained" color="primary" className={classes.button}
                                        onClick={() => { this.handleNext() }}>
                                        <Translate id="languages.generalButton.next" /><Next className={classes.rightIcon} />                                        
                                    </Button>

                                                                 
                                </Grid>
                                
                                :
                                !this.state.showDetalles ?
                                <Grid item xs={12} align="right">
                                <Button variant="contained" color="primary" className={classes.button}
                                onClick={() => {   this.props.history.push("/") }}>
                                <Translate id="languages.generalButton.close" /> <Close className={classes.rightIcon} />                                        
                                  </Button>                                  
                                </Grid>:
                                  <Grid item xs={12} align="left">
                                  <Button  color="primary" className={classes.button}
                                  onClick={async () => { this.setState({ showDetalles: false });  }}> 
                                  <Translate id="languages.generalButton.volver" /> <Close className={classes.rightIcon} />                                        
                                    </Button>                                  
                                  </Grid>

                                }                               
                            </Grid>                                                                                                           
                              
                            }
                            


                        </ExpansionPanelDetails>
                    </ExpansionPanel>

            </Container>
        );
    }
}

ComunicacionEncargo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(ComunicacionEncargo))));