import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import 'ag-grid/dist/styles/ag-theme-material.css';
import 'ag-grid/dist/styles/ag-theme-material.css';
import FoldersRenderer from './AsignFilesFoldersRenderer';
import Confirmation from "../../../Errors/confirmation";

import {
    withStyles,  
    CircularProgress,
    Grid,   
    Button,IconButton,Tooltip  
} from '@material-ui/core';

import {getDefaultLanguage} from "../../../../api/index";

const styles = theme => ({
    withoutRadius: {
        borderRadius: 0
    }
});

class AsignFilesFolders extends Component {
    constructor() {
        super();
        this.state = {            
            columnDefs: [],
            components: {
                    rowNodeIdRenderer: function (params) {
                        return params.node.id + 1;
                    }
                },
                frameworkComponents: {
                    foldersRenderer: FoldersRenderer,                        
                },                                
                paginationPageSize: 20,
                orderEstr:false,          
                autoAsing:true,          
                rowSelection: "single"                                     
               }
    }
   

    // UNSAFE_componentWillMount() {
    //     this.renderColumns()   
    // }

    componentDidMount() {
        this.renderColumns()   
    }

    // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    //    // this.renderColumns(nextProps)        
    //    /* if (this.gridApi)
    //         this.gridApi.redrawRows()*/
    // }
   
     async renderColumns() {
        let columnDefs=[];
        let idi=getDefaultLanguage();
        let file=(idi==1?"ARCHIVO":"ARQUIVO")
        let ancho=window.innerWidth>1680?400:300
        
            columnDefs = [                  
                { headerName: file, field: "Nombre", width: 250,
                cellStyle: {color:this.props.filesInFolders?'black':'orange'}} ,                
                { headerName: "CARPETA", field: "Id_Documentacion", width: ancho,cellRenderer: 'foldersRenderer',
                 cellRendererParams:{carpetas:this.props.carpetas ,  cellValueChanged:(s) => this.cellValueChanged(s)},
                 colId: "carpeta"        
                },         
                this.props.filesInFolders?{HeaderName: "Id", field: "Id_Estructura", width: 1,hide: "true"}:{headerName: "",hide: "true"} ,                    
                this.props.filesInFolders?{HeaderName: "Id_Documentacion_Inicial", field: "Id_Documentacion_Inicial", width: 1,hide: "true"}:{headerName: "_",hide: "true"}       
            ];      
           
            this.setState({columnDefs})           
        
    }
           
    cellValueChanged(idDoc){
        //let column=this.gridApi.getFocusedCell()      
            let row= this.gridApi.getSelectedRows()
            this.props.cellValueChanged(idDoc, row[0].Nombre) 
    }

  

    onGridReady(params) {      
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi        
    };
            

    renderContent() {   
        //let altura=this.props.archivos.length>19?(window.innerHeight>1000?'600':(window.innerHeight>780?'460':'330')):(this.props.archivos.length)*28+40;
        let altura=this.props.archivos.length*28+40;
        let alturamax=window.innerHeight *.61+6;
        if (altura>alturamax)
            altura=alturamax

        return (       
          
         
          <div
            className="ag-theme-balham"         
            style={ {                                        
                height: altura,
                width: '100%',          
                overflow: 'hidden'     
            }} > 
                  
        
                            {//800-10, 960-15-1050-20           
                            this.props.archivos && this.props.archivos.length > 0  ?
                                <AgGridReact
                                columnDefs={this.state.columnDefs}
                                rowData={this.props.archivos}    
                                frameworkComponents={this.state.frameworkComponents}        
                                enableSorting={false}
                                enableFilter={false}            
                                enableColResize={true}
                                suppressCsvExport={false}
                                showToolPanel={false}                                    
                                pagination={false}
                                floatingFilter={false}            
                             
                                localeText={
                                    this.state.localeText
                                }
                                onGridReady={
                                    this.onGridReady.bind(this)
                                }
                                rowSelection='single'
                                            
                            >                           
                            </AgGridReact>
                                : ''                           
                                }    
                                              
           </div>
                                     
        );
        
    }



    render() {
        
        return (
            <div style={{marginTop: 10,width:"100%"}}>
              
              <Grid container >
            
                    <Grid item xs={12} className="text-right" style={{marginRight:5}}>                     
                                                   
                        <div  style={{width:"100%"}}>    
               
                           
                           {!this.props.filesInFolders && 
                           <div  style={{ float:"left" ,paddingLeft:12 }} >                                                         
                                <span  style={{fontSize:11  }}  > 
                                     {'Orden: '}
                                </span>
                                <span  style={{fontSize:11,cursor:'pointer'}}   
                                    onClick={async () => {                      
                                    await this.props.changeOrder();
                                    this.setState({ orderEstr: false });                              
                                        }} >                                   
                                 {!this.state.orderEstr ?<b>{this.props.translate("languages.fileUpload.fileName")}</b>:this.props.translate("languages.fileUpload.fileName")}
                                </span>
                                <span  style={{fontSize:12}}   >
                                    {' | '} 
                                </span>
                                <span  style={{fontSize:11, cursor:'pointer'}} 
                                    onClick={async () => {                      
                                    await this.props.changeOrder('c');
                                    this.setState({ orderEstr: true });                     
                                    }}>
                                    {this.state.orderEstr?<b>Carpeta</b>:'Carpeta'}
                                   </span>                                                                                                                                
                            </div> }

                            {this.props.filesInFolders && 
                            <div  style={{ float:"left" ,paddingLeft:12 }} >
                                <span  style={{fontSize:11, cursor:'pointer'}} 
                                     onClick={async () => {                      
                                                await this.props.changeOrder('c');                                                              
                                                }}>
                                 <b>{this.props.translate("languages.fileUpload.ordenar")}</b>
                               </span>     
                           </div> }


                          
                                   {/*<Tooltip id="ta" title={'Asignación automática a partir de nombres-claves?'}> */}  
                                {!this.props.filesInFolders &&          
                                <Button onClick={async () => { this.setState({ openAuto: true}); }}  
                                     disabled={this.state.isExecution}                                                             
                                     color="primary" autoFocus style={{ height: 30, minHeight: 30,paddingRight:3,paddingLeft:3}}>
                                    Automática                                                       
                                </Button>      }
                                    {/*/Tooltip>*/}                                  
                                                    
                                    {!this.props.filesInFolders &&      
                                <Tooltip id="t1" title={'¿Como funciona Asignación automática?'}>
                                <IconButton   color="primary" aria-label="Info"  style={{marginLeft:0,marginTop: 1,marginRight:10,fontSize: 14, padding: '0px 0px'}}>
                                    {<a id={this.props.translate("languages.fileUpload.fileName")} onClick={() => {this.props.openInfo(document.getElementById(this.props.translate("languages.fileUpload.fileName")).id);}}>
                                  {/*<Info/>*/ '[?]'}
                                      </a>}
                                   </IconButton>  
                                    </Tooltip>   }                                                                                                                                    
                                
                               
                                  <Button onClick={async () => {
                                    this.setState({ isExecution: true});
                                    await this.props.aceptConfirmation();
                                    this.setState({ isExecution: false });                                    
                                    }}   
                                    disabled={this.props.asignados==0}
                                    color="primary" autoFocus style={{ height: 30, minHeight: 30,paddingRight:3,paddingLeft:2}}
                                    >
                                    {!this.props.filesInFolders ? this.props.translate("languages.generalButton.addFiles") +  ' asignados' : this.props.translate("languages.fileUpload.guardarC")}                       
                                    {this.state.isExecution && <CircularProgress className="mx-1"  style={{ height: 20, width: 20 }} />}
                                </Button>                                                                                 
                                                                                                                                                      
                               
                                <Button onClick={() => this.props.declineConfirmation()} color="primary"
                                    disabled={this.state.isExecution}
                                    style={{ height: 30, minHeight: 30 ,marginLeft:5,marginRight:8,paddingRight:1,paddingLeft:3}} >
                                    {'Cancelar'}
                                 </Button>                        
                            </div> 
                       </Grid>   
                        
                            <Grid xs="12">        
                            {this.renderContent()}    
                            </Grid>     


                            <Confirmation openConfirmation={this.state.openAuto}
                                title={'Asignación automática'}
                                onlyAccept={true}
                                confirmationMessage={this.props.translate("languages.fileUpload.expAutoAsignEdit")}
                                aceptConfirmation={async () => {this.setState({ openAuto: false});
                                                                await this.props.autoAsign();
                                                               this.setState({openResult: true})}}
                                declineConfirmation={() => this.setState({openAuto: false})}/>   
                            
                            <Confirmation openConfirmation={this.state.openResult}
                                title={'Resultado'}
                                onlyAccept={true}
                                confirmationMessage={this.props.translate("languages.fileUpload.autoAsigns") + ': '+ this.props.identificados}                          
                                aceptConfirmation={() => this.setState({openResult: false})}/>                                                                      

                </Grid>                        
                        
            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps =
    {       
    };
    export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(AsignFilesFolders)));