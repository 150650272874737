import React, { useState, useEffect } from 'react';
import { Grid,Dialog, DialogTitle, DialogActions, DialogContent, Button} from "@material-ui/core";
import { Typography } from '@material-ui/core';
import ApiCoag from "../../../../api/ApiCoag";
import Textarea from "@material-ui/core/InputBase/Textarea";
import {getDefaultLanguage} from "../../../../api/index";

const  TramitacionUrgente = (props) => {
    const [state, setState] = useState({
      motivo: '',
      motivoBd: '',
      loadingUrgencia: true,
    });


    const { motivo, motivoBd } = state;

  
    useEffect(() => {      
       
      getUrgencia();
     
    }, []);

    const getUrgencia = async () =>
       {
        setState((prevState) => ({...prevState,loadingUrgencia: true,}));
        
        try {
          const response = await ApiCoag.expedientes.trabajo.comunicacionTramitacionUrgente(props.idExpediente, props.idTrabajo);
  
          if (response !== 'ERROR2') {
            const urgencia = response.ComunicacionTramitacionUrgente;
            const motivoBd = urgencia && urgencia.length > 0 ? urgencia[0].Motivo_Urgencia : '';
  
            setState((prevState) => ({
              ...prevState,
              loadingUrgencia:false,
              urgencia: urgencia && urgencia.length > 0,
              motivo: motivoBd,
              motivoBd: motivoBd,
            }));
          }
        } catch (error) {
          console.error(error);
        }
      };
  
    const solicitarUrgencia = async () => {
      const { motivo } = state;
      await putUrgencia(motivo);
    };
  
    const anularUrgencia = () => {
      putUrgencia('');
    };
  
    const putUrgencia = async (motivo) => {
      setState((prevState) => ({
        ...prevState,
        fetchingUrgencia: true,
      }));
  
      try {
        let response = await ApiCoag.expedientes.trabajo.putTramitacionUrgente(props.idExpediente, props.idTrabajo, motivo);
  
        if (response !== 'ERROR2') {
          let urgencia = response.ComunicacionTramitacionUrgente;
          let motivoBd = urgencia && urgencia.length > 0 ? urgencia[0].Motivo_Urgencia : '';
  
          setState((prevState) => ({
            ...prevState,
            fetchingUrgencia: false,
            urgencia: urgencia && urgencia.length > 0,
            motivo: motivoBd,
            motivoBd: motivoBd,
          }));
  
          if (motivo !== '') {
            props.solicitar(motivo);
          } else {
            props.anular();
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    const modificarEstado = (propertyName) => (event) => {
      const { value } = event.target;
      setState((prevState) => ({
        ...prevState,
        [propertyName]: value,
      }));
    };
  
    
    //por razón que desconozco no carga el archivo de resources en el grid
    let idi=getDefaultLanguage();
    let tituloSolicitarUrgencia=(idi==2?"Tramitación urxente":"Tramitación urgente")      
    let explicacionUrgencia=(idi==2?"Se a motivación é suficiente, establecerase prioridade na tramitación do traballo nas posibilidades do servicio que o atende. Prégase moderación no seu uso."
                              :"Si la motivación es suficiente, se establecerá prioridad en la tramitación del trabajo en las posibilidades del servicio que lo atiende. Se ruega moderación en su uso.")
    let motivoUrgencia=(idi==2?"Motivo*":"Motivo*")
    let buttonSolicitarUrgencia=(idi==2?"Solicitar urxencia":"Solicitar urgencia")
    let buttonAnularUrgencia=(idi==2?"Anular urxencia":"Anular urgencia")
    let buttonModificarMotivoUrgencia=(idi==2?"Modificar motivo":"Modificar motivo")
  
    return (

        
      <div>
      
        <Dialog fullWidth="true" maxWidth="sm" open={props.showModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{props.titulo}</DialogTitle>
          <DialogContent>
            <Grid container spacing={12}>           
            <Grid item xs={12} style={{textAlign: "center", marginBottom: 5}}>
             {tituloSolicitarUrgencia} {/*<Translate id="languages.generalButton.solicitarUrgencia"/>    */}
              </Grid>
             <Grid item xs={12} >
              <Typography variant={"body1"} style={{textAlign: "left", marginBottom: 5,marginLeft: 10}}>
              {explicacionUrgencia}
             </Typography>
               </Grid>
              <Grid item xs={12} style={{ textAlign: "center", paddingLeft: 10, paddingRight: 10 }}>
                <Typography variant={"body1"} style={{ textAlign: "center", marginBottom: 5 }}>
                  {motivoUrgencia}
                </Typography>
                <Textarea value={motivo} rows={5} style={{ border: '1px solid #cecece' }} onChange={modificarEstado('motivo')} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div style={{ float: "left", paddingLeft: 0 }}>
              {motivoBd !== '' && (
                <Button onClick={anularUrgencia} variant="contained" color="primary" style={{ height: 30, minHeight: 30 }} className="p-2">
                  {buttonAnularUrgencia}
                </Button>
              )}
            </div>
            <Button
              onClick={solicitarUrgencia}
              variant="contained"
              disabled={motivo.trim() === '' || motivo.trim() === motivoBd.trim()}
              color="primary"
              autoFocus
              style={{ height: 30, minHeight: 30 }}
              className="p-2"
            >
             {motivoBd.trim()==''? buttonSolicitarUrgencia /*<Translate id="languages.generalButton.buttonUrgencia"/> */:buttonModificarMotivoUrgencia /*<Translate id="languages.generalButton.modificarMotivoUrgencia"/>*/}          
            </Button>
            <Button
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  showModal: false,
                  motivo: prevState.motivoBd,
                }));
                props.cancelar();
              }}
              variant="contained"
              color="primary"
              autoFocus
              style={{ height: 30, minHeight: 30 }}
              className="p-2"
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  
  export default TramitacionUrgente;