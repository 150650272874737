import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import { Grid, FormControl, TextField, Typography, InputLabel, MenuItem } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { fetchErrorExpediente, formatMenssage } from "../../actions/expedientes";
import { connect } from "react-redux";
import { TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { ExpandMore} from '@material-ui/icons';
import ApiCoag from "../../api/ApiCoag";
import {ID_PAIS_ESP,ID_REG_GALICIA,ID_PROV_CORU,ID_PAIS_OTRO,ID_REG_OTRA,ID_PROV_OTRA,ID_CONCELLO_OTRO} from "../../api/config";

import {ltrim} from "../../api/index";
import Confirmation from "../Errors/confirmation";

const styles = theme => ({
    formControl: {
        width: "100%"
    },  
    formControl100: {
        width: "100%"
    },
    formControlNif: {
        width: "45%"
    },    
    label: {
        textTransform: "uppercase"
    },
    formControl2: {
        width: "90%",
        marginTop: 16,
        marginBottom: 8
    },
    formControl3: {
        width: "95%",
        marginTop: 30,
        marginBottom: 8
    },
    divGrey: {
        backgroundColor: grey[100],
        padding: 22,
        borderBottom: "1px solid #eee",
        borderTop: "1px solid #eee"
    },
    button: {
        margin: theme.spacing.unit,
    },
    readOnly: {
        pointerEvents: "none",
        opacity: 0.5
    },
    panelExpanded: {
        border: '1.2px solid ' + theme.palette.primary.main,
        margin: '0',
        minHeight: 42
    }
})



const mapStateToProps = (state) => (
    {
        errorExpediente: state.expedientes.error && state.expedientes.error.MensajesProcesado ? state.expedientes.error.MensajesProcesado : [],      
        state: state.expedientes
    }
);

const mapDispatchToProps = (dispatch) => {
    return {
        onErrorExpediente: (value) => dispatch(fetchErrorExpediente(value)),
    }
}


const nifRegEx = /^[0-9]{8}[A-Z]$/i;
const nieRegEx = /^[XYZ][0-9]{7}[A-Z]$/i;
const cifRegEx1 = /^[ABEH][0-9]{8}$/i;
const cifRegEx2 = /^[KPQS][0-9]{7}[A-J]$/i;
const cifRegEx3 = /^[CDFGJLMNRUVW][0-9]{7}[0-9A-J]$/i;

const letras = "TRWAGMYFPDXBNJZSQVHLCKE";
const letrasCIF = "JABCDEFGHI"

export const tipoEntidadPersona = 1;
export const tipoEntidadOrganismo = 2;

export function nifCifValido(value){
    let tipo=0;
    tipo=nifValido(value)
    if (tipo>0) {
        return tipo;
    }    
    tipo=cifValido(value)
    if (tipo>0) {
        return tipo;
    }    
    return 0;
}

export function nifValido(nif) {
    nif = nif.toUpperCase();    
    if (nifRegEx.test(nif))
         return 1;
    if (nieRegEx.test(nif)) 
        return 2                     
    return 0;
  }
  
  export function cifValido(cif) {
    cif = cif.toUpperCase();               
    if (cifRegEx1.test(cif))
        return 3
    if (cifRegEx2.test(cif))  
        return 4
    if (cifRegEx3.test(cif) ) 
        return 5;            
    return 0;
  }

  export function letra(nif,n){
    let letra='';
    if (n==1) {                    
        letra= (letras[(nif.substring(0, 8) % 23)] )
        if (nif[8]==letra) return 'OK' 
        else return letra
            }

    if (n==2) {
        if (nif[0] == "X") nif[0] = "0";
        if (nif[0] == "Y") nif[0] = "1";
        if (nif[0] == "Z") nif[0] = "2";
        
        letra=(letras[(nif.substring(0, 8) % 23)]);
        if (nif[8]==letra) return 'OK' 
        else return letra
    }

    /*if (n==3 || n==4 || n==5 ) {
        let control = nif[nif.length - 1];
        let sumaA = 0;
        let sumaB = 0;

        for (let i = 1; i < 8; i++) {
            if (i % 2 == 0) sumaA += parseInt(nif[i]);
            else {
                let t = (parseInt(nif[i]) * 2);
                let p = 0;

                for (let j = 0; j < t.length; j++) {
                    p += t.substring(j, 1);
                }
                sumaB += p;
            }
        }

        let sumaC = (parseInt(sumaA + sumaB)) ;
        let sumaD = (10 - parseInt((sumaC[sumaC.length - 1])) % 10);               
            
        if (control >= "0" && control <= "9") 
            return (control == sumaD);
        else 
            return (control.toUpperCase() == letras[sumaD]);     
    }     */     
   
}

export function limpiaNif(n){
    return (n.toUpperCase().replace("-","").replace(".","")).replace(" ","");
  }




class Contacto extends Component {
    
    constructor(props) {
        super(props);
      
        /* let datosContactoIncompletos=false;
       
        datosContactoIncompletos=this.props.agente.Calle=='' || this.props.agente.Calle==null || this.props.agente.Id_Concello==null;
        if (this.props.tipoAgente=='otros'){
            datosContactoIncompletos = datosContactoIncompletos || this.props.agente.Mail==null || this.props.agente.Mail==''
        }  */
        // (this.props.agente.Id_Entidad ===-1 || (this.props.modoConsulta && this.props.tipoAgente!='otros') || (!this.props.modoConsulta  && (this.props.agente.Id_Entidad >0) && datosContactoIncompletos)) ,          
      
        this.state = {
            agente: this.props.agente ,
            domicilio: [],
            paises: [],
            regiones: [],
            provincias: [],
            municipios: [],                       
            expanded: this.props.datosContactoEnGrupoExpandidos,
            domicilioExt:this.props.agente ? (this.props.agente['Id_Pais'] == ID_PAIS_OTRO ?true:false) :false
        };        

        

    }

    
   
   
    async componentDidMount() {
        this.cargarTiposAreasGeograficas()
        if (this.element)
            this.element.scrollIntoView({ behavior: 'smooth', block: "start" });                    
    }
        
      
 async cargarTiposAreasGeograficas(){
    let language = this.props.activeLanguage.code ? this.props.activeLanguage.code : null;
    try {
        if (language) {             
            
            let paises = await ApiCoag.tipos.paises();                             
            //por defecto será España y Galicia si viene vacio             
            let regiones = await ApiCoag.tipos.regionesAutonomas(this.props.agente.Id_Pais !=null?this.props.agente.Id_Pais:ID_PAIS_ESP);                               
            let provincias = await ApiCoag.tipos.provincias(this.props.agente.Id_Autonomia !=null?this.props.agente.Id_Autonomia:ID_REG_GALICIA);                                                 
            let municipios = await ApiCoag.tipos.concellos(this.props.agente.Id_Provincia !=null?this.props.agente.Id_Provincia: ID_PROV_CORU);
       

            this.setState({                   
                paises: paises.AreasGeograficasPaises ? paises.AreasGeograficasPaises : [],
                regiones: regiones.AreasGeograficasAutonomias ? regiones.AreasGeograficasAutonomias : [],
                provincias: provincias.AreasGeograficasProvincias ? provincias.AreasGeograficasProvincias : [],
                municipios: municipios.AreasGeograficasConcellos ? municipios.AreasGeograficasConcellos : []                    
            })
        }
      
        
    } catch (error) {
        this.props.onErrorExpediente(formatMenssage(error.message))
    }
 }
    

      handleBlur = (name) => event => {
        let agente = {};
        Object.assign(agente, this.state.agente);

        /*if (this.esLetra(event.target.value)){   
            alert("es letra");
            return false;        
        } else {           
        } */       
       
        if (name=='Nif'){
            agente['Nif'] = limpiaNif(event.target.value);       
        }
        else{
            agente[name]=ltrim(event.target.value);
        }

        
        this.setState({ agente: agente })       
    }

    handleChangeNif = () => event => {
        let agente = {};
        Object.assign(agente, this.state.agente);         
        
        let validado=0;
        if (this.state.agente['Nif']!=null)
            validado=nifCifValido(this.state.agente['Nif'])

        let nif=limpiaNif(event.target.value)

        if (validado==0) {      
            let tipo=nifCifValido(nif+'A')
            if (nif.length==8 && tipo==1) {
                nif=nif+letra(nif,tipo)
            }
        }
        agente['Nif'] = nif
        this.setState({ agente: agente})
      
        this.props.onAddEditContacto(agente,'Nif')

    };


   
    handleChange = (name, checkedProperty = false) => event => {
        let agente = {};
        Object.assign(agente, this.state.agente);
        if (!checkedProperty)
            agente[name] = event.target.value;
        else {
            agente[name] = event.target.checked ? 1 : 0;
        }
        agente['Id_Tipo_Entidad']=this.props.tipoEntidad==0 || this.props.tipoEntidad == null ||this.props.tipoEntidad==1  ?tipoEntidadPersona:tipoEntidadOrganismo
        this.setState({ agente: agente })

        this.props.onAddEditContacto(agente,name)
    };

 
    /*hay que permitir direcciones fuera de España*/
    handlePais = name => async event => {
        let agente = {};
       
        Object.assign(agente, this.state.agente);
        agente[name] = event.target.value;

        if (this.state.agente.Id_Pais!=event.target.value){
            

        agente['Id_Autonomia'] = "";
        agente['Id_Provincia'] = "";        
        agente['Id_Concello'] = "";            
        
            
        if  (agente[name] != ID_PAIS_OTRO){

             //por defecto será España y Galicia si viene vacio             
             let regiones = await ApiCoag.tipos.regionesAutonomas(event.target.value);    
             let provincias = await ApiCoag.tipos.provincias(ID_REG_GALICIA);                                                 
             let municipios = await ApiCoag.tipos.concellos(ID_PROV_CORU);

            agente['Id_Pais'] = ID_PAIS_ESP;      
            agente['Id_Autonomia'] = ID_REG_GALICIA; 
            agente['Id_Provincia'] = ID_PROV_CORU; 
           

            this.setState({
            agente: agente,
            regiones: regiones.AreasGeograficasAutonomias ? regiones.AreasGeograficasAutonomias : [],
            provincias: provincias.AreasGeograficasProvincias,
            municipios: municipios.AreasGeograficasConcellos,                        
            domicilioExt:false
            })
        }
        else{
            agente['Id_Pais'] = ID_PAIS_OTRO;      
            agente['Id_Autonomia'] = ID_REG_OTRA;
            agente['Id_Provincia'] = ID_PROV_OTRA;       
            agente['Id_Concello'] = ID_CONCELLO_OTRO;            
            
            agente['Piso'] = '';      
            agente['Codigo_Postal'] = '';      
            agente['Numero'] = '';       
            
             this.setState({
            agente: agente,
            domicilioExt:true,
            openOcultarRegProvCon: true})           

            this.props.onAddEditContacto(agente,'Id_Pais')
           
        }  
    }
        
       

    };


  
    handleRegion = name => async event => {
        let agente = {};
        let provincias = await ApiCoag.tipos.provincias(event.target.value);
        Object.assign(agente, this.state.agente);
        agente[name] = event.target.value;
        if (this.state.agente['Id_Autonomia']!=event.target.value){
            agente['Id_Provincia'] = '';
            agente['Id_Concello'] = '';
            this.setState({
                agente: agente,
                provincias: provincias.AreasGeograficasProvincias ? provincias.AreasGeograficasProvincias : [],
                municipios: []
            })
        }
       
    };

    handleProvincias = name => async event => {
        let agente = {};
        let municipios = await ApiCoag.tipos.concellos(event.target.value);
        Object.assign(agente, this.state.agente);
        agente[name] = event.target.value;
        if (this.state.agente['Id_Provincia']!=event.target.value){
            agente['Id_Concello'] = '';
            this.setState({
            agente: agente,
            municipios: municipios.AreasGeograficasConcellos ? municipios.AreasGeograficasConcellos : []
        })
        }
    };

    async loadMunicipes(idProvince) {
        let municipios = await ApiCoag.tipos.concellos(idProvince);
        this.setState({ municipios: municipios.AreasGeograficasConcellos ? municipios.AreasGeograficasConcellos : [] });
    }

   

 

    render() {
        let { classes } = this.props;
        let { paises, regiones, provincias, municipios } = this.state;
        let apellidos=this.state.agente.Apellido1?this.state.agente.Apellido1+' '+this.state.agente.Apellido2:'';
        let nombre=this.props.modoConsulta ?  this.state.agente.Nombre + ' '+apellidos:this.state.agente.Nombre;
        let agenteExistente=this.props.agente.Id_Entidad>0?true:false
        let disabledDatosContacto= this.props.modoConsulta && !this.props.modoConsultaConEdicionDatosContacto
             
        let caracterRequerido=!this.props.modoConsulta ? " *":''
        return (
            <div ref={element => { this.element = element; }} style={{ width: "100%" }} >
                                  <div className={`p-3 ${agenteExistente }`}>          
                                        {!this.props.modoConsulta && 
                                this.props.tipoAgente=='otros' ? <Typography variant="button" gutterBottom>
                                    {this.props.tipoEntidad==tipoEntidadPersona?<Translate id="languages.agentes.titleDatosPersonales" />:<Translate id="languages.agentes.titleDatosOrganismo"/>}
                                </Typography>:
                                <Typography variant="subtitle1" gutterBottom>
                                {this.props.tipoEntidad==tipoEntidadPersona?<Translate id="languages.agentes.titleDatosPersonales" />:<Translate id="languages.agentes.titleDatosOrganismo"/>}
                            </Typography>}

                                <FormControl className={classes.formControlNif} >

                                    <Translate>
                                        {({ translate }) => <TextValidator
                                            name="nif"
                                            id="nif"
                                            label={'Nif ' + caracterRequerido}
                                            inputProps={{maxLength:12}}
                                            placeholder={this.props.tipoEntidad==tipoEntidadPersona?`${translate("languages.agentes.introduce")} NIF personal`:`${translate("languages.agentes.introduce")} NIF`}
                                            value={this.state.agente.Nif ? this.state.agente.Nif : ""}
                                            onChange={this.handleChangeNif()}                                            
                                            onBlur={this.handleBlur('Nif')}
                                            margin="normal"
                                            autoFocus={true}
                                            InputLabelProps={{
                                                shrink: true,                                                
                                                classes: {
                                                    root: classes.label
                                                }
                                            }}
                                            validators={['required','isNif']}
                                            errorMessages={[translate("languages.generalText.fieldRequired"),
                                                            this.props.tipoEntidad==tipoEntidadPersona?'Indica un NIF personal con formato válido':'Indica un NIF con formato válido']}
                                           
                                            disabled={this.props.modoConsulta || (agenteExistente &&  this.props.tipoAgente=='otros')}
                                        />}
                                    </Translate>
                                </FormControl>
                                <FormControl className={classes.formControl} style={{ width: this.props.tipoEntidad==tipoEntidadPersona? "60%":"95%" }}>
                                    <Translate>
                                        {({ translate }) => <TextValidator
                                            id="nombre"
                                            name="nombre"
                                            inputProps={{maxLength:50}}
                                            label={translate("languages.agentes.tableColumnName") + caracterRequerido}
                                            placeholder={translate("languages.agentes.introduce") + " " + translate("languages.agentes.tableColumnName").toLowerCase()}
                                            value={this.state.agente.Nombre?nombre:''}
                                            onChange={this.handleChange('Nombre')}                                            
                                            onBlur={this.handleBlur('Nombre')}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    root: classes.label
                                                }
                                            }}
                                            type="text"
                                            validators={['required']}
                                            errorMessages={[translate("languages.generalText.fieldRequired")]}
                                            disabled={this.props.modoConsulta}
                                        />}
                                    </Translate>
                                </FormControl>

                                {this.props.tipoEntidad==tipoEntidadPersona && !this.props.modoConsulta &&
                                              <FormControl className={classes.formControl} style={{ width: "60%" }}>
                                    <Translate>
                                        {({ translate }) => <TextValidator
                                            name="apellido1"
                                            id="apellido1"
                                            inputProps={{maxLength:50}}
                                            label={translate("languages.agentes.firstName") + caracterRequerido}
                                            placeholder={translate("languages.agentes.introduce") + " " + translate("languages.agentes.firstName").toLowerCase()}
                                            value={this.state.agente.Apellido1 ? this.state.agente.Apellido1 : ""}
                                            onChange={this.handleChange('Apellido1')}
                                            onBlur={this.handleBlur('Apellido1')}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    root: classes.label
                                                }
                                            }}
                                            type="text"
                                            validators={['required']}
                                            errorMessages={[translate("languages.generalText.fieldRequired")]}
                                            disabled={this.props.modoConsulta}
                                        />}
                                    </Translate>
                                </FormControl>}
                                {this.props.tipoEntidad==tipoEntidadPersona &&  !this.props.modoConsulta &&
                                <FormControl className={classes.formControl} style={{ width: "60%" }}>
                                    <Translate>
                                        {({ translate }) => <TextField
                                            id="apellido2"
                                            inputProps={{maxLength:50}}
                                            label={translate("languages.agentes.secondName")}
                                            placeholder={translate("languages.agentes.introduce") + " " + translate("languages.agentes.secondName").toLowerCase()}
                                            value={this.state.agente.Apellido2!=null ? this.state.agente.Apellido2 : ""}
                                            onChange={this.handleChange('Apellido2')}
                                            onBlur={this.handleBlur('Apellido2')}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    root: classes.label
                                                }
                                            }}
                                            type="text"
                                            disabled={this.props.modoConsulta}
                                        />}
                                    </Translate>
                                </FormControl>}
                        
                            </div>
                            {/**Contactos */}

                           
                            
                            <ExpansionPanel style={{ boxShadow: "none" }} className="m-0" expanded={this.state.expanded }>
                             {      this.props.datosContactoEnGrupo && <ExpansionPanelSummary expandIcon={<ExpandMore color={this.state.expanded ? "primary" : "default"} />}
                                    style={{ minHeight: 48, height: 48 }}
                                    className={`${classes.divGrey} ${this.state.expanded && classes.panelExpanded}`}                                  
                                    
                                    onClick={() => this.setState({ expanded: !this.state.expanded })}>
                                    {this.props.tipoAgente=='otros' ? <Typography variant='button'>
                                        <Translate id="languages.agentes.titleContacto" />
                                    </Typography>:
                                    <Typography variant="subtitle1">
                                    <Translate id="languages.agentes.titleContacto" />
                                </Typography>
                                    }
                                </ExpansionPanelSummary> }
                                <ExpansionPanelDetails className="p-0">
                                    {/* no debe ser gris <div className={`${classes.divGrey} ${this.props.readOnly && classes.readOnly}`}> */}
                                    <div className={`p-3 ${agenteExistente}`}>
                                    <Grid container spacing={0} >
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl}  style={{ width: "95%" }}>
                                            <Translate>
                                                {({ translate }) => <TextValidator
                                                    name="calle"
                                                    id="calle"
                                                    inputProps={{maxLength:250}}
                                                    label={!this.state.domicilioExt? translate("languages.generalAddress.calle") + caracterRequerido: translate("languages.generalAddress.domicilio") + caracterRequerido}
                                                    placeholder={translate("languages.agentes.introduce") + " " + translate("languages.generalAddress.calle").toLowerCase()}
                                                    value={this.state.agente.Calle ? this.state.agente.Calle : ""}
                                                    onChange={this.handleChange('Calle')}
                                                    onBlur={this.handleBlur('Calle')}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {
                                                            root: classes.label
                                                        }
                                                    }}
                                                    type="text"
                                                    validators={['required']}
                                                    errorMessages={[translate("languages.generalText.fieldRequired")]}
                                                    disabled={disabledDatosContacto}
                                                />}
                                            </Translate>
                                        </FormControl>
                                        </Grid>
                                    
                                        {!this.state.domicilioExt && 
                                            <Grid item xs={3}>
                                                <FormControl style={{ width: 80 }}>
                                                    <TextField
                                                        id="Numero"
                                                        label="Num"
                                                        placeholder=""
                                                        inputProps={{maxLength:50}}
                                                        value={this.state.agente.Numero ? this.state.agente.Numero : ""}
                                                        onChange={this.handleChange('Numero')}
                                                        onBlur={this.handleBlur('Numero')}
                                                        margin="normal"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            classes: {
                                                                root: classes.label
                                                            }
                                                        }}
                                                        type="text"
                                                        disabled={disabledDatosContacto}
                                                    />
                                                </FormControl>
                                            </Grid>}
                                            {!this.state.domicilioExt && <Grid item xs={3} >
                                                <FormControl style={{ width: 80 }}>
                                                    <Translate>
                                                        {({ translate }) => <TextField
                                                            id="Piso"
                                                            inputProps={{maxLength:50}}
                                                            label={translate("languages.generalAddress.piso")}
                                                            placeholder=""
                                                            value={this.state.agente.Piso ? this.state.agente.Piso : ""}
                                                            onChange={this.handleChange('Piso')}
                                                            onBlur={this.handleBlur('Piso')}
                                                           
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {
                                                                    root: classes.label
                                                                }
                                                            }}
                                                            disabled={disabledDatosContacto}
                                                        />}
                                                    </Translate>
                                                </FormControl>
                                            </Grid>}
                                           
                                            {!this.state.domicilioExt && <Grid item xs={3}  >
                                                <FormControl style={{ width: 90 }}  className="text-center"> 
                                                    <TextField
                                                        id="Codigo_Postal"
                                                        label="Código_Postal"
                                                        placeholder=""
                                                        inputProps={{maxLength:10}}
                                                        value={this.state.agente.Codigo_Postal ? this.state.agente.Codigo_Postal: ""}
                                                        onChange={this.handleChange('Codigo_Postal')}
                                                        onBlur={this.handleBlur('Codigo_Postal')}
                                                        margin="normal"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            classes: {
                                                                root: classes.label
                                                            }
                                                        }}
                                                        type="text"
                                                        disabled={disabledDatosContacto}
                                                    />
                                                </FormControl>
                                            </Grid>}
                                            {!this.state.domicilioExt && <Grid item xs={3}  ></Grid>     }
                                            
                                            
                                            <Grid xs="4" className={classes.formControl3} >
                                            {this.props.modoConsulta && this.state.domicilioExt 
                                              ? null  :
                                            <FormControl style={{ width: "90%" }} >
                                            <InputLabel htmlFor="pais" required shrink className={classes.label} style={{ transform: "translate(0, -12.5px) scale(0.75)" }}>
                                                <Translate id="languages.generalAddress.pais" />
                                            </InputLabel>
                                            <Translate>
                                                {({ translate }) => <SelectValidator
                                                    name="Id_Pais"
                                                    value={this.state.agente.Id_Pais ? this.state.agente.Id_Pais : ID_PAIS_ESP}
                                                    onChange={this.handlePais('Id_Pais')}
                                                    inputProps={{
                                                        name: 'pais',
                                                        id: 'pais',
                                                    }}
                                                    validators={['required']}
                                                    errorMessages={[translate("languages.generalText.fieldRequired")]}
                                                    disabled={disabledDatosContacto}
                                                >
                                                    {
                                                        paises ?
                                                            paises.map(value => {
                                                                return <MenuItem key={value.Id_Area} value={value.Id_Area}>{value.Nombre}</MenuItem>
                                                            })
                                                            : ""
                                                    }
                                                </SelectValidator>}
                                            </Translate>
                                        </FormControl>}
                                            </Grid>
                                           
                                           
                                            
                                            <Grid xs="8" >
                                            {!this.state.domicilioExt ?
                                        <FormControl className={classes.formControl3}>
                                            <InputLabel htmlFor="pais" required shrink className={classes.label} style={{ transform: "translate(0, -12.5px) scale(0.75)" }}>
                                                <Translate id="languages.generalAddress.region" />
                                            </InputLabel>
                                            <Translate>
                                                {({ translate }) => <SelectValidator
                                                    name="Id_Autonomia"
                                                    value={this.state.agente.Id_Autonomia ? this.state.agente.Id_Autonomia :ID_REG_GALICIA}
                                                    onChange={this.handleRegion('Id_Autonomia')}
                                                    inputProps={{
                                                        name: 'region',
                                                        id: 'region',
                                                    }}
                                                    validators={['required']}
                                                    errorMessages={[translate("languages.generalText.fieldRequired")]}
                                                    disabled={disabledDatosContacto}
                                                >
                                                    {
                                                        regiones ?
                                                            regiones.map(value => {
                                                                return <MenuItem key={value.Id_Area} value={value.Id_Area}>{value.Nombre}</MenuItem>
                                                            })
                                                            : ""
                                                    }
                                                </SelectValidator>}
                                            </Translate>
                                        </FormControl>
                                            :null  }                                      
                                            </Grid>
                                       
                                        
                                        
                                        <Grid xs="4" >                                        
                                        {!this.state.domicilioExt ?
                                        <FormControl className={classes.formControl3} style={{ width: "90%" }} >
                                            <InputLabel htmlFor="provincia" required shrink className={classes.label} style={{ transform: "translate(0, -12.5px) scale(0.75)" }}>
                                                <Translate id="languages.generalAddress.provincia" />
                                            </InputLabel>
                                            <Translate>
                                                {({ translate }) => <SelectValidator
                                                    name="provincia"
                                                    value={this.state.agente.Id_Provincia ? this.state.agente.Id_Provincia :ID_PROV_CORU}
                                                    onChange={this.handleProvincias('Id_Provincia')}
                                                    inputProps={{
                                                        name: 'provincia',
                                                        id: 'provincia',
                                                    }}
                                                    validators={['required']}
                                                    errorMessages={[translate("languages.generalText.fieldRequired")]}
                                                    disabled={disabledDatosContacto}
                                                >
                                                   
                                                    {provincias.length==0 && <MenuItem value="" disabled>
                                                        {translate("languages.agentes.selecciona") + " previamente " +
                                                            translate("languages.generalAddress.region")}
                                                    </MenuItem>}
                                                    {                                                       
                                                        provincias ?
                                                            provincias.map(value => {
                                                                return <MenuItem key={value.Id_Area} value={value.Id_Area}>{value.Nombre}</MenuItem>
                                                            })
                                                            : ""
                                                    }
                                                </SelectValidator>}
                                            </Translate>
                                        </FormControl>
                                        :null}
                                        </Grid>
                                       
                                        
                                        
                                        <Grid xs="8" >
                                            {!this.state.domicilioExt ?                                

                                        <FormControl className={classes.formControl3}>
                                            <InputLabel htmlFor="concello" required shrink className={classes.label} style={{ transform: "translate(0, -12.5px) scale(0.75)" }}>
                                                <Translate id="languages.generalAddress.municipio" />
                                            </InputLabel>
                                            <Translate>
                                                {({ translate }) => <SelectValidator
                                                    name="concello"
                                                    value={this.state.agente.Id_Concello ? this.state.agente.Id_Concello : ""}
                                                    onChange={this.handleChange('Id_Concello')}
                                                    inputProps={{
                                                        name: 'concello',
                                                        id: 'concello',
                                                    }}                                                    
                                                    validators={['required']}
                                                    errorMessages={[translate("languages.generalText.fieldRequired")]}
                                                    disabled={disabledDatosContacto}
                                                >
                                                    {municipios.length==0 && <MenuItem value="" disabled>
                                                        {translate("languages.agentes.selecciona") + " previamente " +
                                                            translate("languages.generalAddress.provincia")}
                                                    </MenuItem>}
                                                    {municipios.length!=0 && this.state.agente.Id_Concello=='' && <MenuItem key={0} value="" disabled>
                                                        {translate("languages.agentes.selecciona") + "  " +
                                                            translate("languages.generalAddress.municipio")}
                                                    </MenuItem>}
                                                    {
                                                        municipios ?
                                                            municipios.map(value => {
                                                                return <MenuItem key={value.Id_Area} value={value.Id_Area}>{value.Nombre}</MenuItem>
                                                            })
                                                            : ""
                                                    }
                                                </SelectValidator>}
                                            </Translate>
                                        </FormControl>
                                        :null}
                                        </Grid>
                                            


                                  
                                       
                                           
                                            <Grid xs="8" className={classes.formControl2} >
                                        <FormControl className={classes.formControl2}>
                                            <Translate>
                                                {({ translate }) => <TextValidator
                                                    id="mail"
                                                    name="mail"
                                                    label={translate("languages.agentes.titleCorreo") + (this.props.tipoAgente=='otros' ? caracterRequerido: '')}
                                                    inputProps={{maxLength:50}}
                                                    placeholder={!this.props.modoConsulta && this.props.tipoAgente=='otros'?translate("languages.agentes.introduce") + " " + translate("languages.agentes.titleCorreo"):''}
                                                    value={this.state.agente.Mail ? this.state.agente.Mail : ""}
                                                    onChange={this.handleChange('Mail')}
                                                    onBlur={this.handleBlur('Mail')}
                                                    margin="normal"
                                                    
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {
                                                            root: classes.label
                                                        }
                                                    }}
                                                    type="text"
                                                    validators={this.props.tipoAgente=='otros'?['required']:null}
                                                    errorMessages={[translate("languages.generalText.fieldRequired")]}
                                                    disabled={disabledDatosContacto}
                                                />}
                                            </Translate>
                                        </FormControl>
                                        </Grid>
                                        
                                        
                                         {/*hace falta telefono ?*/}
                                        <Grid xs="4" className={classes.formControl2} >
                                        <FormControl className={classes.formControl2}>
                                            <Translate>
                                                {({ translate }) => <TextField
                                                    id="telefono"
                                                    label={translate("languages.agentes.titleTelefono")}
                                                    //placeholder={!this.props.modoConsulta ?translate("languages.agentes.introduce") + " " + translate("languages.agentes.titleTelefono"):''}
                                                    value={this.state.agente.Telefono ? this.state.agente.Telefono : ''}
                                                    onChange={this.handleChange('Telefono')}
                                                    onBlur={this.handleBlur('Telefono')}
                                                    inputProps={{maxLength:50}}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {
                                                            root: classes.label
                                                        }
                                                    }}
                                                    type="tel"
                                                    disabled={disabledDatosContacto}
                                                />}
                                            </Translate>
                                        </FormControl>
                                         </Grid>                                       
                                                                                
                                    </Grid>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>


                          
                 
                         

                              
                
                    <Confirmation openConfirmation={this.state.openOcultarRegProvCon}     
                    title={<Translate id="languages.agentes.titleDomicilioExtranjero"/>}                           
                    confirmationMessage={<Translate id="languages.agentes.infoDomicilioExtranjero"/>}
                    onlyAccept={true}
                    aceptConfirmation={() => this.setState({openOcultarRegProvCon: false})}
                    />      

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(Contacto)));