


import React from 'react';
import { Row,Col } from 'reactstrap';
import { Typography,  Button,Tooltip, RadioGroup , FormControlLabel,Radio,FormControl, Select , MenuItem, InputLabel, CircularProgress } from "@material-ui/core"
import { Translate } from "react-localize-redux";
import { LocationOn, MyLocation } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import {isMobile} from "../../api/index";
import { connect } from 'react-redux';
const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    } ,  
    formControl: {
        width: "100%"
    }
});



class Resultados extends React.Component {
    constructor(props) {
        super(props);
        this.state = {         
          selectedColSoc:this.props.idColegiado
        };
      }

      componentDidMount() {
        // Simula el evento onChange al iniciar el componente
        if (this.props.idAccion==6)    
            this.handleChange({ target: { value: this.state.selectedColSoc } });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.colSoc) {
          return {
            selectedColSoc: nextProps.colSoc           
          };
        }        
    
        return null;
      }
   
    handleChange = (event) => {
        this.setState({ selectedColSoc: event.target.value });
        this.props.changeColSoc(event.target.value );
      }

      
  
    

      getUniqueSociedadValues(data) {
        // Extrae el campo 'sociedad' de cada objeto y filtra los valores únicos
        const uniqueColSocValues = [...new Set(data.map(item => item.Id_Col_Soc))];
        // Ordenar valores de menor a mayor
        uniqueColSocValues.sort((a, b) => a - b);
        
        return uniqueColSocValues;
      }

renderResultados(canRender,modal,datosTablaResult,datosTabla,haydatos,loadingSearch,tipoListado,types,localizacionesMapa,classes){
    return (
        canRender && 
        <Row>
        <Col xs={10} className="d-flex">
            <Typography variant="h6" gutterBottom className="pt-2 mr-1">
           <Translate id="languages.generalText.resultados"/>               
            </Typography>
            
            { (types === "trabajos" ) &&  
                <Typography variant="h6" gutterBottom color="primary" className="pt-2">
                     { !loadingSearch? ` (${modal ? datosTablaResult.length : datosTabla.length})`: ` (--)`}
                </Typography>
            }
          
            
            { types !== "trabajos" && haydatos &&
                <Button  
                    variant="outlined" 
                    color={tipoListado !== 'listado' ?  "default"  : "primary"} 
                    style={{height:'30px', minHeight:'30px', marginTop:8, marginLeft: 9, marginRight: 0, padding:0}} 
                    className={classes.button}  
                    onClick={async () => { await this.props.handleListadoMapa('listado') }}
                    >
                    Listado
                </Button>
            }
            
            { types !== "trabajos" &&
                <div  style={{width:'50px' }}>
                <Typography 
                    variant="h6" 
                    gutterBottom 
                    color={tipoListado === 'mapa' ? "default" : "primary"} 
                    className="pt-2" 
                    style={{marginRight: 0, marginLeft:3, padding:0 }}>
                    { !loadingSearch? ` (${modal ? datosTablaResult.length : datosTabla.length})`: ` (--)`}
                </Typography>
                </div>
            }

            
            { types !== "trabajos" && haydatos && localizacionesMapa!=null && 
                <Button 
                    className={classes.button}  
                    color={tipoListado !== 'mapa' ? "default" : "primary"} 
                    variant="outlined" 
                    style={{height:'30px', minHeight:'30px', marginTop:8, marginLeft:14, paddingLeft:0, paddingTop:0, paddingRight:0, paddingBottom:0, marginRight: 0}}  
                    onClick={async () => { await this.props.handleListadoMapa('mapa') }}
                    >
                    Mapa                                                         
                </Button>
            }
            
            { types !== "trabajos" && haydatos && localizacionesMapa!=null && 
              <div  style={{width:'50px' }}>
                <Typography 
                    variant="h6" 
                    gutterBottom 
                    color={tipoListado !== 'mapa' ? "default"  : "primary"} 
                    style={{marginRight: 0, marginLeft:3, padding:0}} 
                    className="pt-2">               
                    { !loadingSearch? ` (${localizacionesMapa.length})`: ` (--)`}
                </Typography>
                </div>
            }             
            
            {!isMobile() && types !== "trabajos" && haydatos &&  localizacionesMapa!=null && 
                <Typography gutterBottom className="align-self-center" style={{marginLeft: 5, marginTop: 2}} variant="subtitle2">
                    <Translate id="languages.search.generalDescriptionMap"/>  
                </Typography>
            }

            {/* enMapa && !isMobile() && <Typography gutterBottom className="align-self-center" variant="subtitle2" color="primary">{" (" + datosMapa.length + ")."}</Typography> */}     

        </Col>

        {!isMobile() && tipoListado=='listado' && types != "trabajos" && localizacionesMapa!=null  &&
                            <Col xs={2}>                                      
                                  <Typography gutterBottom className="align-self-center"  style={{marginLeft: 5,marginTop: 2}} variant="subtitle2" > <LocationOn style={{paddingTop: 0,paddingBottom: 0,marginBottom:-4,paddingRight:0,paddingLeft:0, height:'16px',color:grey[500]}}/>  <Translate id="languages.generalText.geolocalizado"/> </Typography>
                                  <Tooltip title={<Translate id="languages.expedients.helperTextGeolocalizable"/>} > 
                                            <Typography  className="align-self-center"  style={{marginLeft: 5,marginTop: 2}} variant="subtitle2" >
                                            <MyLocation style={{paddingTop: 0,paddingBottom: 0,marginBottom:-4,paddingRight:0,paddingLeft:0, height:'16px',color:'orange'}}/> <Translate id="languages.generalText.geolocalizable"/>     </Typography> 
                                    </Tooltip>
                                  </Col>}

        {isMobile() && types != "trabajos" &&
                                    <Col xs={12} className="d-flex">
                                        <Typography gutterBottom className="align-self-center"  style={{marginLeft: 5}} variant="subtitle2" > <Translate id="languages.search.generalDescriptionMap"/> </Typography> 
                                        {/*<Typography gutterBottom className="align-self-center" variant="subtitle2" color="primary" > {" (" +  datosMapa.length + ")."}</Typography>*/}
                                    </Col>
                                    }
        
    
        </Row>
    );

}

renderResultadosRenuncias(canRender,datosTablaResult,datosTabla,loadingSearch){

    const valuesColSoc=this.getUniqueSociedadValues( datosTablaResult?datosTablaResult:datosTabla);   
    const filteredDataColSoc = datosTablaResult?datosTablaResult.filter(item => item.Id_Col_Soc == (this.state.selectedColSoc)):datosTabla.filter(item => item.Id_Col_Soc == (this.state.selectedColSoc));
   
    return (
        canRender && 
        <Row>
        <Col xs={2} className="d-flex">
            <Typography variant="h6" gutterBottom className="pt-2 mr-1">
           <Translate id="languages.generalText.resultados"/>              
            </Typography>
            
            { !loadingSearch && 
                <Typography variant="h6" gutterBottom color="primary" className="pt-2">
                    {` (${ filteredDataColSoc.length})`}
                </Typography>
            }        
       </Col>
       
       
      {/*mostrarRadiosColSoc &&
       <RadioGroup
          row
          name="coltipo"
          value={this.state.colSoc}
          onChange={this.handleChange}
          style={{ fontSize: '4px', paddingLeft: '3px' }}
        >
          <FormControlLabel value="0" control={<Radio color="primary" />} label="Arquitecto" />
          <FormControlLabel value="1" control={<Radio color="primary" />} label="Sociedad" />
        </RadioGroup>*/}
        <Col xs={2} style={{textalign:'right'}}>  {valuesColSoc.length>1 && <Typography variant="h6" className="pt-2 mr-0 pr-0"> Renuncias de Col.:</Typography> } </Col>   
    <Col xs={8} > 
    
   
        {valuesColSoc.length>1 &&
        <FormControl  className="pt-2 mr-1 ">        
        <Select style={{paddingTop:'-1px',width:'100px',paddingLeft:'0px'}}
          labelId="sociedad-select-label"
          value={this.state.selectedColSoc}
          onChange={this.handleChange}
          label="Sociedad"
        >
          {valuesColSoc.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>}
      
        </Col>          
        </Row> 
    );

}

    render() {
        const { 
            types, 
            loadingSearch, 
            tipoListado, 
            datosTablaResult, 
            datosTabla, 
            localizacionesMapa,             
            modal,
            idAccion,
            classes          
        } = this.props;
        
        let canRender=(datosTablaResult!=null && datosTablaResult!=='') || (datosTabla!=null && datosTabla!=='');
        let haydatos=canRender && (datosTablaResult.length > 0 || datosTabla.length > 0);
        let enRenuncias=(idAccion===6);

        if (enRenuncias && canRender)
            return this.renderResultadosRenuncias(canRender,datosTablaResult,datosTabla,loadingSearch,classes);

        if (!enRenuncias)
            return this.renderResultados(canRender,modal,datosTablaResult,datosTabla,haydatos,loadingSearch,tipoListado,types,localizacionesMapa,classes);
                
    }
}

const mapStateToProps = state => ({
   idColegiado: state.user.DatosUsuarioValidado.Id_Colegiado ? state.user.DatosUsuarioValidado.Id_Colegiado: 0,
  });

  const mapDispatchToProps = {   
};


  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Resultados));
