import React, { Component } from 'react';

import './styles.css';
import { connect } from 'react-redux';
import TramitacionEnLote from '../components/TramitacionEnLote/index'
import Grid from "@material-ui/core/Grid";


class ContenedorTramitacionEnLote extends Component {




    render() {

        return (
            <div className="">
                <Grid container>

                    <Grid item xs={12}>
                        <TramitacionEnLote {...this.props}/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}



const mapStateToProps = state => ({


});





export default connect(mapStateToProps)(ContenedorTramitacionEnLote);


