import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {
    withStyles,
    Paper,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    CircularProgress,
    Grid,

    Button,
    Dialog,
    DialogTitle,
    DialogContent, DialogActions
} from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {fetchErrorExpediente} from "../../../../actions/expedientes";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import {grey} from "@material-ui/core/colors";
import {Col, Label, Row} from "reactstrap";
import {Search} from "@material-ui/icons";
import moment from "moment";
import ApiCoag from "../../../../api/ApiCoag";


const styles = theme => ({
    withoutRadius: {
        borderRadius: 0
    },
    tableRow: {
        cursor:'pointer',
        '&:hover': {backgroundColor: "#f0f0f0"}
    },
    headHeight: {
        height: 40
    }
});
const CustomTableHead = withStyles(theme => ({
    head: {
        backgroundColor: grey[100],
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


class Incidencias extends Component {
    constructor() {
        super();
        this.state = {
            expandSectionTempFile: false,
            loadingData: true,
            incidencias:[],
            incidenciaSeleccionada:null,
            showDetalles:false

        }
    }

    componentDidMount() {

        this.loadData();
    }
    async loadData(){
        let agentes = await ApiCoag.expedientes.trabajo.incidencias(this.props.work.Id_Expediente,this.props.work.Id_Trabajo);

        await this.setState({
            loadingData:false,
            OtrosAgentes:agentes.OtrosAgentes,
            incidencias:agentes.Incidencias
        })
    }
    detalles() {

        if(this.state.showDetalles){
            let {incidenciaSeleccionada} = this.state
            return (<div>
                <Dialog open={this.state.showDetalles}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Detalles
                    </DialogTitle>

                    <DialogContent>
                        <Grid container spacing={8}>

                            {
                                incidenciaSeleccionada.map((resp,p)=>(
                                    <Grid item md={12} style={{borderBottom:'1px solid #cecece'}}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Label style={{marginTop:10,marginBottom:0}}>No.</Label>
                                                <Typography variant={"body1"}>{resp.Num_Incidencia}</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Label style={{marginTop:10,marginBottom:0}}>Tipo</Label>
                                                <Typography variant={"body1"}>{resp.Tipo_Incidencia}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Label style={{marginTop:10,marginBottom:0}}>Texto</Label>
                                                <div
                                                    style={{
                                                        textAlign:"justify",
                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        fontFamily: 'Montserrat',
                                                        lineHeight: '1.46429em',
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: resp.Texto,

                                                    }}></div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Label style={{marginTop:10,marginBottom:0}}>Fecha Alta</Label>
                                                <Typography variant={"body1"}>{moment(new Date(resp.Fecha_Alta)).format("DD/MM/YYYY")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Label style={{marginTop:10,marginBottom:0}}>Fecha Baja</Label>
                                                <Typography variant={"body1"}>{resp.Fecha_Baja?moment(new Date(resp.Fecha_Baja)).format("DD/MM/YYYY"):'-'}</Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={async () => {
                            this.setState({ showDetalles: false });

                        }} variant="contained"
                                color="primary" autoFocus style={{ height: 30, minHeight: 30 }}
                                className="p-0">
                            Aceptar

                        </Button>

                    </DialogActions>
                </Dialog>
            </div>);
        }

    }
    detallesIncidencia(incidencia){
        this.setState({
            showDetalles:true,
            incidenciaSeleccionada:[incidencia]

        })

    }
    mostrarActivas(){
        let {incidencias}= this.state;
        let activas = incidencias.filter((incidencia)=>!incidencia.Fecha_Baja)
        this.setState({
            showDetalles:true,
            incidenciaSeleccionada:activas

        })
    }


    render() {
        let {classes} = this.props;
        return (
            <div style={{marginTop: 10}}>
                {
                    this.state.loadingData ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                        <Paper
                            style={{borderColor: '#cecece', borderWidth: 1, marginBottom: 10}}>
                            <ExpansionPanel expanded={this.state.expandSectionTempFile}
                                            onChange={() => this.setState({expandSectionTempFile: !this.state.expandSectionTempFile})}>
                                <ExpansionPanelSummary style={{paddingLeft: 8}} expandIcon={<ExpandMoreIcon/>}>
                                    <div className="d-flex justify-content-between align-items-center"
                                         style={{width: "100%", marginLeft: 10}}>
                                        <div>
                                            <Typography variant='button' style={{float:"left"}}>
                                              Incidencias 
                                            </Typography>
                                            

                                            {this.props.work.Incidencias==0 ?
                                             <Typography variant='button'
                                                        style={{color: '#ffc107', float: "left", marginLeft: 5}}>{' ( 0 activas)'}
                                             </Typography> :
                                             <Typography variant='button'
                                             style={{color: '#dc3545', float: "left", marginLeft: 5}}>{' ( ' +this.props.work.Incidencias+' activas)'}
                                             </Typography>                               
                                             }                                                                                     

                                        </div>

                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{padding: "8px 12px"}}>
                                    <Grid container xs={12}>
                                        {
                                            this.state.incidencias.length==0?
                                                <Grid item xs={12}>
                                                    <Typography variant={"body1"} style={{textAlign:'center',padding:20}}>Sin incidencias.</Typography>
                                                </Grid>:
                                                    <Grid item xs={12} style={{textAlign:"right"}}>
                                                        {
                                                            this.detalles()
                                                        }

                                                            <Button
                                                                color="primary" className={classes.button}
                                                                disabled={this.state.incidencias.filter((incidencia)=>!incidencia.Fecha_Baja).length==0}
                                                                onClick={()=>this.mostrarActivas()}>Ver descripción activas
                                                            </Button>


                                                        <Table className={classes.table}>
                                                            <TableHead >
                                                                <TableRow className={classes.headHeight}>
                                                                    <CustomTableHead >No.</CustomTableHead>
                                                                    <CustomTableHead>TIPO </CustomTableHead>
                                                                    <CustomTableHead >ALTA </CustomTableHead>
                                                                    <CustomTableHead >BAJA</CustomTableHead>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody className={classes.tableBodyHeight}>
                                                                {
                                                                    this.state.incidencias.map(incidencia=>
                                                                        <TableRow onClick={()=>{this.detallesIncidencia(incidencia)}} className={classes.tableRow}>
                                                                            <TableCell  style={{color:incidencia.Retiene?'#dc3545':'#007bff', textDecoration:"underline"}}>{incidencia.Num_Incidencia}</TableCell>
                                                                            <TableCell style={{color:incidencia.Retiene?'#dc3545':'#007bff'}}>{incidencia.Tipo_Incidencia}</TableCell>
                                                                            <TableCell style={{color:incidencia.Retiene?'#dc3545':'#007bff'}}>{moment(new Date(incidencia.Fecha_Alta)).format("DD/MM/YYYY")}</TableCell>
                                                                            <TableCell style={{color:incidencia.Retiene?'#dc3545':'#007bff'}}>{incidencia.Fecha_Baja?moment(new Date(incidencia.Fecha_Baja)).format("DD/MM/YYYY"):'-'}</TableCell>


                                                                        </TableRow>
                                                                    )
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                        }

                                    </Grid>


                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Paper>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps =
    {
        fetchErrorExpediente: fetchErrorExpediente
    };
    export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(Incidencias)));