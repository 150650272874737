import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {
    withStyles,
    Paper,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanel,
    ExpansionPanelDetails,
    Button,
    CircularProgress,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    FormControl,
    Select,
    MenuItem, Fab, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
//import Vault from '../../../../VaultUpload/Vault';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {Add} from "@material-ui/icons";
import {fetchErrorExpediente} from "../../../../../actions/expedientes";
import ApiCoag from "../../../../../api/ApiCoag";
import { feTurbulence } from 'react-dom-factories';

const styles = theme => ({
    withoutRadius: {
        borderRadius: 0
    },
  
    tickSize: {
        transform: "scale(0.8)",
    },
});

class DTProyectoEjecucion extends Component {
    constructor() {
        super();
        this.state = {
            expandSectionTempFile: false,
            loadingDatosTecnicos: true,
            Tipos_Plantas: [],
            respuestaCalculo: false,
            showDetalles: false,
            isModified: false,
            isModifiedDatosEst:false,
            allowSave: false,           
            isCalculated:false,

            Tipos_Superficie: [],
            plantas: [],
            request: {}

        }
    }

    async componentDidMount() {
         await this.loadNomencladores();
    }

    async loadNomencladores() {
        let datosTecnicosTrabajo = await ApiCoag.expedientes.trabajo.datosTecnicos.obtener(this.props.work.Id_Expediente, this.props.work.Id_Trabajo);
        let tiposPlanta = await ApiCoag.tipos.guia.datosTecnicos.plantas();
        let usosSuperfice = await ApiCoag.tipos.guia.datosTecnicos.usoEdificacion();     
        
        datosTecnicosTrabajo.Caracteristicas = datosTecnicosTrabajo.Caracteristicas[0]
        datosTecnicosTrabajo.Parametros = datosTecnicosTrabajo.Parametros[0]
        datosTecnicosTrabajo.DatosEstadisticos = datosTecnicosTrabajo.DatosEstadisticos[0]
        datosTecnicosTrabajo.Precios = datosTecnicosTrabajo.Precios ? datosTecnicosTrabajo.Precios : [{
            Complejidad: 0,
            Precio: 0,
            Complejidad_Final: 0,
            Precio_Final: 0
        }];
        //usosSuperfice.tipos_trabajos_usos_edificacion

       this.setState({
            loadingDatosTecnicos: false,
            allowSave:false,
            isModified:false ,
            isCalculated:datosTecnicosTrabajo.Superficies.length > 0 ,     
            isModifiedDatosEst:false,
            Tipos_Plantas: tiposPlanta.Tipos_Plantas,
            Tipos_Superficie: usosSuperfice.tipos_trabajos_usos_edificacion,
            datosTecnicos: datosTecnicosTrabajo,
            respuestaCalculo: {
                Precios: datosTecnicosTrabajo.Precios,
                DetallesCalculo: datosTecnicosTrabajo.DetallesCalculo
            },
            plantas: datosTecnicosTrabajo.Caracteristicas.Requiere_Superficies == 0 ? [] : datosTecnicosTrabajo.Superficies
        });

        if (datosTecnicosTrabajo.Caracteristicas.Requiere_Superficies == 1 && datosTecnicosTrabajo.Superficies.length == 0  ) {
            this.addPlanta();          
        }
        await this.checkMarkedElements();

        if (!this.compIntroSup()) //en este caso xa esta grabado que non hay que introducir sup, e polo tanto calculado
            this.setState({isCalculated: true});
    

    }

   

    removePlantas() {
        this.setState({plantas:[]})        
    }

    removePlanta(pos) {
        let plantas = this.state.plantas
        plantas.splice(pos, 1);        
        this.setState({plantas,isModified: true,isCalculated:false});
        /* si no quedan plantas se añade una vacia para mostrar interface de introduciión*/
        if ( this.state.plantas.length == 0) {

            this.addPlanta();
            this.setState({isModified: true})

            
        }
    }

    
    addPlanta() {
        let newPlanta = {
            Id_Expediente: this.props.work.Id_Expediente,
            Id_Trabajo: this.props.work.Id_Trabajo,
            Nivel: 1,
            Numero_Plantas: 1,
            Id_Tipo_Planta: null,
            Edificacion: 0,
            Estructura: 0,
            Envolvente: 0,
            Reforma_Interior: 0,
            Instalaciones: 0,
            Acabados: 0
        }
        this.state.Tipos_Superficie.map((item) => {
            newPlanta[item.Campo_Vinculado_Superficie] = 0;
        });
        let plantas = this.state.plantas
        plantas.push(newPlanta);     
        if (this.compIntroSup())   
            this.setState({plantas,isModified: true,isCalculated:false}); 
        else
            this.setState({plantas}); 
    }

    async saveDatosTecnicos() {
        
        let {plantas, datosTecnicos} = this.state;
        /*plantas.map(item => {
            item.Acabados = 0;
        })*/
        let {work} = this.props;

        
        if (this.compIntroSup())
         {   let errores = this.validatePlantas();              

            if (errores.length) {
                this.props.fetchErrorExpediente({MensajesProcesado: errores});
                this.setState({
                'respuestaCalculo': false
                })
                return null;
            }
        }

        this.validateDatosEstadisticos();

        if (this.compIntroSup())
            datosTecnicos.Superficies = plantas
        else
            datosTecnicos.Superficies = null


        await this.setState({
            fetchingSave: true
        })
        let datosTecnicosTrabajo = await ApiCoag.expedientes.trabajo.datosTecnicos.guardar(work.Id_Expediente, work.Id_Trabajo, datosTecnicos);

        if (datosTecnicosTrabajo.Caracteristicas) {
            datosTecnicosTrabajo.Caracteristicas = datosTecnicosTrabajo.Caracteristicas[0]
            datosTecnicosTrabajo.Parametros = datosTecnicosTrabajo.Parametros[0]
            datosTecnicosTrabajo.DatosEstadisticos = datosTecnicosTrabajo.DatosEstadisticos[0]


            await this.setState({

                datosTecnicos: datosTecnicosTrabajo,
                respuestaCalculo: {
                    Precios: datosTecnicosTrabajo.Precios,
                    DetallesCalculo: datosTecnicosTrabajo.DetallesCalculo
                },
                plantas: datosTecnicosTrabajo.Superficies
            });

            if (datosTecnicosTrabajo.Caracteristicas.Requiere_Superficies == 1 && datosTecnicosTrabajo.Superficies.length == 0 ) {

                this.addPlanta();
            }
            await this.checkMarkedElements();
            
        }


        this.setState({

            fetchingSave: false,
            isModified: false,
            isModifiedDatosEst:false,
            allowSave: false           
        })

       /*{ if (datosTecnicosTrabajo.MensajesProcesado && datosTecnicosTrabajo.MensajesProcesado.length > 0) {
       this.props.fetchErrorExpediente(datosTecnicosTrabajo)}
        }*/

    }

    async calcular() {
        let {plantas, datosTecnicos} = this.state;
       // plantas.map(item => {
       //     item.Acabados = 0;
       // })
        let {work} = this.props;
        let errores = this.validatePlantas();
        if (errores.length) {
            this.props.fetchErrorExpediente({MensajesProcesado: errores});
            this.setState({
                'respuestaCalculo': false
            })
            return null;
        }
        if (this.compIntroSup())
            datosTecnicos.Superficies = plantas
        else
            datosTecnicos.Superficies = null
                


        let response = await ApiCoag.expedientes.trabajo.datosTecnicos.simular(work.Id_Expediente, work.Id_Trabajo, datosTecnicos);
        if (!response || response == 'ERROR2' || (response.MensajesProcesado && response.MensajesProcesado.length > 0)) {
            if (response.MensajesProcesado) 
            this.props.fetchErrorExpediente(response)

            this.setState({
                'respuestaCalculo': false,
                isCalculated:false 

            })

        } else {
            this.setState({
                'respuestaCalculo': response,
                isCalculated:true,
                allowSave: true
            })

        }


    }
   
    valorUnidadeComplexidade(){
        if (this.state.datosTecnicos && this.state.respuestaCalculo && this.state.respuestaCalculo.Precios[0].Precio_Unitario_Unidad_Complejidad)
            return this.state.respuestaCalculo.Precios[0].Precio_Unitario_Unidad_Complejidad
        else
            return ''            
    }
    

    resultadoCalculo() {
        let {classes} = this.props;          
        let complejidadFinal = 0;
        let precioFinal = 0;
             
        return (
            <Grid container style={{backgroundColor: '#f9f9f9', padding: 48, paddingTop: 12, paddingBottom: 12}}>
                <Grid item xs={12}>
                {this.state.isCalculated ? <Translate id="languages.datosTecnicos.complejidadFinalYPrecio" /> : <Translate id="languages.datosTecnicos.complejidadFinalYPrecioPend" />}
                </Grid>
                
                {this.state.isCalculated  &&  
                        this.state.respuestaCalculo.Precios.map((precio) => {
                            complejidadFinal += precio.Complejidad;
                            precioFinal += parseFloat(precio.Precio).toFixed(2);
                            return (
                                <Grid container style={{backgroundColor: '#f9f9f9'}}>
                                    {/*
                                    <Grid item xs={6}>
                                        <label className='MuiFormLabel-root-214' style={{
                                            marginTop: 17,
                                            marginBottom: -12,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            padding: 0,
                                            fontSize: 10,
                                            fontFamily: 'Montserrat',
                                            lineHeight: 1
                                        }}>COMPLEJIDAD</label><br/>
                                        {precio.Complejidad}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className='MuiFormLabel-root-214' style={{
                                            marginTop: 17,
                                            marginBottom: -12,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            padding: 0,
                                            fontSize: 10,
                                            fontFamily: 'Montserrat',
                                            lineHeight: 1
                                        }}>PRECIO (SIN INVA)</label><br/>
                                        {parseFloat(precio.Precio).toFixed(2)} €
                                    </Grid>
                                    */}
                                    <Grid item xs={6}>
                                        <label className='MuiFormLabel-root-214' style={{
                                            marginTop: 17,
                                            marginBottom: -12,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            padding: 0,
                                            fontSize: 10,
                                            fontFamily: 'Montserrat',
                                            lineHeight: 1
                                        }}><Translate id="languages.datosTecnicos.complejidadFinal" /></label><br/>
                                        {precio.Complejidad_Final}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className='MuiFormLabel-root-214' style={{
                                            marginTop: 17,
                                            marginBottom: -12,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            padding: 0,
                                            fontSize: 10,
                                            fontFamily: 'Montserrat',
                                            lineHeight: 1
                                        }}><Translate id="languages.datosTecnicos.precioFinal" /></label><br/>
                                        {parseFloat(precio.Precio_Final).toFixed(2)} €
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    <Grid container style={{backgroundColor: '#f9f9f9'}}>

                    </Grid>
                    <Grid container style={{backgroundColor: '#f9f9f9'}}>
                        <Grid item xs={12}>
                            <label className='MuiFormLabel-root-214' style={{
                                marginTop: 17,
                                marginBottom: -12,
                                color: 'rgba(0, 0, 0, 0.54)',
                                padding: 0,
                                fontSize: 11,
                                fontFamily: 'Montserrat',
                                lineHeight: 1
                            }}>{this.props.translate("languages.datosTecnicos.precioFinalCoeficiente") + ' ' + this.valorUnidadeComplexidade()}</label>

                        </Grid>
                        {

                            this.state.isCalculated && this.state.respuestaCalculo.DetallesCalculo  ? <Grid item xs={12}>

                                <Button
                                    color="primary" className={classes.button}
                                    onClick={() => this.setState({showDetalles: true})}
                                ><Translate id="languages.datosTecnicos.mostrarDetallesCalculo" />
                                </Button>
                            </Grid> : null

                        }


                    </Grid>
                </Grid>
            )
        }
    

   
    notifyPropertyChange = (propertyName, pos = false) => event => {
        let plantas = this.state.plantas;
        this.setState({isModified: true,allowSave:false,isCalculated:false})

        let datosTecnicos = this.state.datosTecnicos;                    


        switch (propertyName) {
            case 'Tipos_Superficie':
                let element = this.state['Tipos_Superficie'];
                element[pos].checked = event.target.checked;
                this.setState({
                    Tipos_Superficie: element
                })
                break;
            case 'Nivel'     :

                plantas[pos][propertyName] = event.target.value;

                this.setState({
                    plantas
                })

                break;

                
            case 'Mejoras_Envolvente'     :

                datosTecnicos['Parametros'][propertyName] = event.target.type == 'checkbox' ? event.target.checked ? 1 : 0 : event.target.value;
               
                this.setState({
                    datosTecnicos
                })
               

                break;

            case 'Acondicionamientos_Elementos'     :

                    datosTecnicos['Parametros'][propertyName] = event.target.type == 'checkbox' ? event.target.checked ? 1 : 0 : event.target.value;
                   
                    this.setState({
                        datosTecnicos
                    })
                   
    
                    break;    

            case 'Documentacion_Parcial_Instalaciones_Visada'     :

                    datosTecnicos['Parametros'][propertyName] = event.target.type == 'checkbox' ? event.target.checked ? 1 : 0 : event.target.value;
                    this.setState({
                        datosTecnicos
                    })
    
                    break;    
         
            default:

                let valueIntro = event.target.value

                if (event.target.type == 'checkbox')
                {
                     valueIntro =event.target.checked;

                    if (propertyName == 'Edificacion' && valueIntro == true)
                    {
                        plantas[pos]['Acabados'] = 0;
                        plantas[pos]['Reforma_Interior'] = 0;
                        plantas[pos]['Instalaciones'] = 0;
                        plantas[pos]['Estructura'] = 0;
                    }   

                    if ( valueIntro == true && (propertyName =='Estructura' || propertyName == 'Instalaciones' || propertyName == 'Reforma_Interior' || propertyName == 'Acabados'))
                       plantas[pos]['Edificacion'] = 0;                                         
                       
                    if ((propertyName == 'Instalaciones' || propertyName == 'Reforma_Interior') && valueIntro == true)
                        plantas[pos]['Acabados'] = 1;                    
                    
                    if (valueIntro == false && ((propertyName == 'Instalaciones' && plantas[pos]['Reforma_Interior'] == 0) || (propertyName == 'Reforma_Interior' && plantas[pos]['Instalaciones'] == 0)))
                        plantas[pos]['Acabados'] = 0;

                    if (propertyName == 'Acabados' && valueIntro == false && (plantas[pos]['Reforma_Interior'] == 1 || plantas[pos]['Instalaciones'] ==1) )
                        valueIntro = 1; 

                    if (propertyName == 'Acabados' && valueIntro == true && (plantas[pos]['Edificacion'] == 0 && plantas[pos]['Estructura'] == 1  && plantas[pos]['Reforma_Interior'] == 0 && plantas[pos]['Instalaciones'] ==0) )
                        valueIntro = 0;    
                    
                    if (propertyName == 'Estructura' && valueIntro == true && (plantas[pos]['Reforma_Interior'] == 0 && plantas[pos]['Instalaciones'] ==0) )
                        plantas[pos]['Acabados'] = 0      ;
                                        
                }    


                plantas[pos][propertyName] = event.target.type == 'checkbox' ? valueIntro ? 1 : 0 : parseInt(valueIntro);
                this.setState({
                    plantas
                })

                break;

        }
    }


    notifyPropertyChangeSuperficie = (propertyName, pos = false) => event => {
        let plantas = this.state.plantas;
        this.setState({isModified: true,allowSave:false,isCalculated:false})
       
                
                plantas[pos][propertyName] = event.target.value;
                ;
                this.setState({
                    plantas
                })        
                                                                                   
        
    }

    modifyDatosEstadisticos = (propertyName) => event => {
        let datosTecnicos = this.state.datosTecnicos;
        /* md si está pendiente calculo no se puede guardar*/
        if (this.state.isCalculated || !this.state.isModified )
        {this.setState({isModifiedDatosEst:true,allowSave:true})}
        else
        {this.setState({isModifiedDatosEst:true})}

        switch (propertyName) {

            default:

                datosTecnicos['DatosEstadisticos'][propertyName] = event.target.type == 'checkbox' ? event.target.checked ? 1 : 0 : parseFloat(event.target.value);
                this.setState({
                    datosTecnicos
                })

                break;

        }
    }

    validateDatosEstadisticos (){

        let datosTecnicos = this.state.datosTecnicos;     
        
        let valor = datosTecnicos['DatosEstadisticos'].PEM;                 
        datosTecnicos['DatosEstadisticos'].PEM = (valor == '' || valor == null  || valor == 'NaN') ? null:  parseFloat(valor ).toFixed(2);

        valor = datosTecnicos['DatosEstadisticos'].Numero_Viviendas;
        datosTecnicos['DatosEstadisticos'].Numero_Viviendas= (valor == '' || valor == null  || valor == 'NaN') ? null:  parseInt(valor );

        valor = datosTecnicos['DatosEstadisticos'].Numero_Viviendas_Afectadas;
        datosTecnicos['DatosEstadisticos'].Numero_Viviendas_Afectadas = (valor == '' || valor == null  || valor == 'NaN') ? null:    parseInt(valor );
                
        this.setState({
             datosTecnicos
            })         
        
    }

    validatePlantas() {
        let errores = [];
        let plantas = this.state.plantas;

        if (this.compIntroSup())
        {
            plantas.map((planta, pos) => {          
            if(!planta.Id_Tipo_Planta){
                errores.push({
                    Mensaje:  "Debe seleccionar un tipo para cada planta."

                })
            }
            if (planta.Edificacion == 0 && planta.Estructura == 0 && planta.Envolvente == 0 && planta.Reforma_Interior == 0 && planta.Instalaciones == 0 && planta.Acabados == 0) {
                errores.push({
                    Mensaje: "Debe tipificar la obra para cada planta."
                    
                })
            }

            if (errores.length  == 0)
            {
             //redoneamos superficies a 2 decimales
             planta.Superficie_Uso_Administrativo = planta.Superficie_Uso_Administrativo == null ? null:  parseFloat(planta.Superficie_Uso_Administrativo).toFixed(2);
             planta.Superficie_Uso_Aparcamiento = planta.Superficie_Uso_Aparcamiento == null ? null:  parseFloat(planta.Superficie_Uso_Aparcamiento).toFixed(2);
             planta.Superficie_Uso_Comercial = planta.Superficie_Uso_Comercial == null ? null:  parseFloat(planta.Superficie_Uso_Comercial).toFixed(2);
             planta.Superficie_Uso_Docente = planta.Superficie_Uso_Docente== null ? null:  parseFloat(planta.Superficie_Uso_Docente).toFixed(2);
             planta.Superficie_Uso_Industrial_No_Asimilable= planta.Superficie_Uso_Industrial_No_Asimilable== null ? null:  parseFloat(planta.Superficie_Uso_Industrial_No_Asimilable).toFixed(2);
             planta.Superficie_Uso_Publica_Concurrencia= planta.Superficie_Uso_Publica_Concurrencia == null ? null:  parseFloat(planta.Superficie_Uso_Publica_Concurrencia).toFixed(2);
             planta.Superficie_Uso_Residencial_Publico= planta.Superficie_Uso_Residencial_Publico== null ? null:   parseFloat(planta.Superficie_Uso_Residencial_Publico).toFixed(2);
             planta.Superficie_Uso_Sanitario= planta.Superficie_Uso_Sanitario== null ? null:  parseFloat(planta.Superficie_Uso_Sanitario).toFixed(2);
             planta.Superficie_Uso_Vivienda_Colectiva= planta.Superficie_Uso_Vivienda_Colectiva== null ? null:  parseFloat(planta.Superficie_Uso_Vivienda_Colectiva).toFixed(2);
             planta.Superficie_Uso_Vivienda_Unifamiliar= planta.Superficie_Uso_Vivienda_Unifamiliar== null ? null:  parseFloat(planta.Superficie_Uso_Vivienda_Unifamiliar).toFixed(2);
            
             this.setState({
              plantas
                  });
              }

         
            });
        }
        return errores;
    }


    compIntroSup()

    {  if (this.state.datosTecnicos!=null && this.state.datosTecnicos.Parametros!=null)
        return (this.state.datosTecnicos.Parametros.Mejoras_Envolvente == null || (this.state.datosTecnicos.Parametros.Mejoras_Envolvente == 0 && this.state.datosTecnicos.Parametros.Acondicionamientos_Elementos == 0))  
       else
        return true          
    }


    lostFocusSuperficie (propertyName,value, pos)                                         
    {
        let plantas = this.state.plantas;
        if (value!=null)
        value = parseFloat(value).toFixed(2);

        plantas[pos][propertyName] =  (value);
                this.setState({
                    plantas
                })             
    }

  

    detalles() {

        if (this.state.showDetalles && this.state.isCalculated) {
            let {respuestaCalculo} = this.state
            return (<div>
                <Dialog open={this.state.showDetalles}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                    <Translate id="languages.datosTecnicos.detallesCalculo" />
                    </DialogTitle>

                    <DialogContent>
                        <Grid container spacing={8}>
                            {
                                respuestaCalculo.DetallesCalculo.map((resp, p) => (
                                    <Grid item md={12}>
                                        {resp.Parametro}:<br/> {resp.Valor_Parametro}
                                    </Grid>
                                ))
                            }
                        </Grid>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={async () => {
                            this.setState({showDetalles: false});

                        }} variant="contained"
                                color="primary" autoFocus style={{height: 30, minHeight: 30}}
                                className="p-0">
                            Aceptar

                        </Button>

                    </DialogActions>
                </Dialog>
            </div>);
        }

    }

    

    async checkMarkedElements() {
        let {Tipos_Superficie, plantas} = this.state;
        plantas.map((item, p) => {
            Tipos_Superficie.map((superficie, pos) => {
                if (plantas[p][superficie.Campo_Vinculado_Superficie] > 0) {
                    Tipos_Superficie[pos].checked = true;

                }
            })
        })

        await this.setState({Tipos_Superficie})

    }

    superficiesSeleccionadas() {
        return this.state.Tipos_Superficie.filter((item) => item.checked == true)
    }

    renderTiposSuperficie() {
        let edicion = this.state.datosTecnicos.Caracteristicas.SePuede_Editar;

        if (this.state.datosTecnicos.Caracteristicas.SeRequiere_IntroducirDatosTecnicos == 1 && this.state.datosTecnicos.Caracteristicas.Requiere_Superficies == 1 && this.compIntroSup() )
            return (
               
                <Grid container spacing={16} style={{margin: 0}}>
                    <Grid item xs={12}>
                        <Typography variant={"body1"} style={{
                            fontSize: 12,

                            textAlign: 'justify',
                            padding: '0 0px',

                        }}>
                            {edicion ==1 ? <Translate id="languages.datosTecnicos.seleccionUsos" /> :<Translate id="languages.datosTecnicos.titleseleccionUsos" />}
                        </Typography>
                    </Grid>                                    
                    {
                                              
                        this.state.Tipos_Superficie.map((item, pos) => {
                            return (  
                                <Grid item  md={edicion==1 ? 4 : 0} xs={12} className="p-0">   
                                {  (edicion == 1 ||  item.checked == 1) &&
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={item.checked == true}                                                
                                                style={{padding: 5, paddingLeft: 15}}
                                                disabled={edicion == 0}
                                                onChange={this.notifyPropertyChange("Tipos_Superficie", pos)}
                                                color="primary"/>
                                        }
                                        label={item.nombre}/>
                                    }
                                </Grid>
                                                            
                            )
                        })
                    }
                </Grid>
            )
    }

   
    renderDatosEstadisticos(){
        let {datosTecnicos} = this.state;
        let {classes} = this.props;
        let edicion = datosTecnicos.Caracteristicas.SePuede_Editar;
        return (
            <Grid container>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '100%', paddingRight: '10%'}}>
                        <TextField
                            id="Numero_Viviendas"
                            label={<Translate id="languages.datosTecnicos.viviendas" />}
                            disabled={parseInt(edicion)!=1}
                            type={'number'}
                            inputProps={{ min: 0,  step: "1" }}
                            value={datosTecnicos.DatosEstadisticos.Numero_Viviendas == null? '' :datosTecnicos.DatosEstadisticos.Numero_Viviendas}
                            onChange={this.modifyDatosEstadisticos('Numero_Viviendas')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '100%', paddingRight: '10%'}}>
                        <TextField
                            id="Numero_Viviendas_Afectadas"
                            label={<Translate id="languages.datosTecnicos.viviendasAfectadas" />}
                            type={'number'}
                            inputProps={{  min: 0, step: "1" }}
                            disabled={parseInt(edicion)!=1}
                            value={datosTecnicos.DatosEstadisticos.Numero_Viviendas_Afectadas == null ? '':datosTecnicos.DatosEstadisticos.Numero_Viviendas_Afectadas}
                            onChange={this.modifyDatosEstadisticos('Numero_Viviendas_Afectadas')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl 
                                 style={{width: '100%', paddingRight: '10%',fontSize:18}}>
                        <TextField
                            id="pem"
                            label="PEM"
                            type={'number'}
                            inputProps={{ min: 0,  step: "1" }}
                            disabled={parseInt(edicion)!=1}
                            value={datosTecnicos.DatosEstadisticos.PEM == null ? '' :datosTecnicos.DatosEstadisticos.PEM}
                            onChange={this.modifyDatosEstadisticos('PEM')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>                
                <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '100%', paddingRight: '10%'}}>
                        <TextField
                            id="Superficie"
                            label="SUPERFICIE TOTAL"
                            disabled={true}
                            type={'number'}
                            inputProps={{ min: 0,  step: "0.1" }}
                            value={datosTecnicos.DatosEstadisticos.Superficie}
                            onChange={this.modifyDatosEstadisticos('Superficie')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
              {/*  <Grid item xs={6}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '70%', paddingRight: '10%'}}>
                        <TextField
                            id="Numero_Viviendas_Afectadas"
                            label="Número de viviendas afectadas"
                            type={'number'}
                            //inputProps={{ min: "0",  step: "0.1" }}
                            disabled={parseInt(datosTecnicos.Caracteristicas.SePuede_Editar)!=1}
                            value={datosTecnicos.DatosEstadisticos.Numero_Viviendas_Afectadas}
                            onChange={this.modifyDatosEstadisticos('Numero_Viviendas_Afectadas')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '60%', paddingRight: '10%'}}>
                        <TextField
                            id="Superficie"
                            label="Superficie"
                            disabled={true}
                            type={'number'}
                            inputProps={{ min: "0",  step: "0.1" }}
                            value={datosTecnicos.DatosEstadisticos.Superficie}
                            onChange={this.modifyDatosEstadisticos('Superficie')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                        */}

            </Grid>
        )

    }
    renderPlantas() {
        let {classes} = this.props;
        let comSup = this.compIntroSup();
        let edicion=this.state.datosTecnicos.Caracteristicas.SePuede_Editar;
        if (this.state.plantas.length > 0 && this.state.datosTecnicos.Caracteristicas.SeRequiere_IntroducirDatosTecnicos == 1 ) {
            return (
                <Grid container spacing={16} style={{margin: 0}}>
                    <Grid item xs={12}>
                    {comSup && edicion == 1 && <Translate id="languages.datosTecnicos.introducirSuperficies" /> }
                    {comSup && edicion == 0 && <Translate id="languages.datosTecnicos.titleSuperficies" />}
                    </Grid>

                    {
                          comSup && this.state.plantas.map((item, pos) => {
                            return (
                                <Grid item xs={12} style={{
                                    marginTop: 10,
                                    padding: 8,
                                    paddingTop: 0,
                                    border: '1px solid #cecece',
                                    marginRight: 10
                                }}>
                                    <Grid container style={{margin: 0}}>
                                        <Grid item xs={10} md={10}>
                                            <label className='MuiFormLabel-root-214' style={{

                                                marginBottom: -12,

                                                padding: 0,
                                                fontSize: 11,
                                                fontFamily: 'Montserrat',
                                                lineHeight: 1
                                            }}> <Translate id="languages.datosTecnicos.datosPlantasGenerales" /></label>
                                        </Grid>
                                        <Grid item xs={2} md={2}
                                              style={{textAlign: 'right', display: pos === this.state.plantas.length-1 ? '' : 'none'}}>
                                            {edicion == 1 &&       
                                            <Fab size="small" color="primary" aria-label="Add" style={{marginTop: -25}}
                                                 className={classes.fab} onClick={() => {
                                                this.addPlanta()
                                                this.setState({isModified: true})
                                            }}>
                                                <Add/>                                                
                                            </Fab>
                                            }
                                        </Grid>
                                    </Grid>


                                    <FormControl className={classes.formControl2}
                                                 style={{width: '15%', paddingRight: '3%'}}>
                                        <TextField
                                            id="Cota"
                                            label="Nivel"
                                            type={'number'}                                           
                                            value={item.Nivel}
                                            onChange={this.notifyPropertyChange('Nivel', pos)}
                                            margin="normal"
                                            disabled={edicion == 0}
                                            InputLabelProps={{
                                                shrink: true,  maxLength: 10 
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.formControl2} style={{width: '10%'}}>
                                        <TextField
                                            id="numero_plantas"
                                            type={'number'}
                                            label={<Translate id="languages.datosTecnicos.numeroRepeticiones" />}
                                            value={item.Numero_Plantas}
                                            disabled={edicion == 0}
                                            onChange={this.notifyPropertyChange('Numero_Plantas', pos)}
                                            margin="normal"
                                            inputProps={{ min: 1}}                                            
                                            InputLabelProps={{                                              
                                                shrink: true,                                                
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.formControl2}
                                                 style={{width: '40%', paddingRight: '3%', paddingLeft: '5%'}}>
                                        <label className='MuiFormLabel-root-214' style={{
                                            marginTop: 17,
                                            marginBottom: -12,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            padding: 0,
                                            fontSize: 10,                                            
                                            fontFamily: 'Montserrat',
                                            lineHeight: 1
                                        }}><Translate id="languages.datosTecnicos.tipoPlanta" /></label>
                                        <Select
                                            id='id_tipo_planta'
                                            label={<Translate id="languages.datosTecnicos.tipoPlanta" />}
                                            onChange={this.notifyPropertyChange('Id_Tipo_Planta', pos)}
                                            value={item.Id_Tipo_Planta}
                                            inputProps={{
                                                name: 'Nombre',
                                                id: 'Id_Tipo_Planta',
                                            }}
                                            disabled={edicion == 0}
                                        >

                                            {this.state.Tipos_Plantas.map(item => (
                                                <MenuItem className="text-uppercase"
                                                          value={item.Id_Tipo_Planta}>{item.Nombre}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                    

                                    <Grid container style={{margin: 0}}>
                                        <Grid item xs={12}>
                                            <label className='MuiFormLabel-root-214' style={{

                                                marginBottom: -12,

                                                padding: 0,
                                                fontSize: 11,
                                                fontFamily: 'Montserrat',
                                                lineHeight: 1
                                            }}>
                                              {<Translate id="languages.datosTecnicos.tipoObra" />}</label>
                                        </Grid>
                                        <Grid item xs={12} >
                                        {(item.Edificacion == 1 || edicion == 1) && <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.Edificacion}
                                                        style={{padding: 5, paddingLeft:10}}
                                                        disabled={edicion == 0}
                                                        onChange={this.notifyPropertyChange("Edificacion", pos)}
                                                        color="primary"/>
                                                }
                                                label={<Translate id="languages.datosTecnicos.tipoPlantaNuevaPlantaoAmpliacion" />}/>
                                            }
                                        
                                        {(item.Estructura == 1 || edicion == 1) &&<FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.Estructura}
                                                        style={{padding: 5, paddingLeft:5}}
                                                        disabled={edicion == 0}
                                                        onChange={this.notifyPropertyChange("Estructura", pos)}
                                                        color="primary"/>
                                                }
                                                label={<Translate id="languages.datosTecnicos.tipoPlantaEstructura" />}/>}
                                       
                                       {(item.Reforma_Interior == 1 || edicion == 1) && <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.Reforma_Interior}
                                                        style={{padding: 5, paddingLeft:10}}
                                                        disabled={edicion== 0}
                                                        onChange={this.notifyPropertyChange("Reforma_Interior", pos)}
                                                        color="primary"/>
                                                }
                                                label={<Translate id="languages.datosTecnicos.tipoPlantaReformaInterior" />}/>}
                                       
                                       {(item.Instalaciones == 1 || edicion == 1) &&   <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.Instalaciones}
                                                        style={{padding: 5, paddingLeft: 10}}
                                                        disabled={edicion == 0}
                                                        onChange={this.notifyPropertyChange("Instalaciones", pos)}
                                                        color="primary"/>
                                                }
                                                label={<Translate id="languages.datosTecnicos.tipoPlantaInstalaciones" />}/>}
                                      
                                      {(item.Acabados == 1 || edicion == 1) &&  <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.Acabados}
                                                        style={{padding: 5, paddingLeft:10}}
                                                        disabled={edicion == 0}
                                                        onChange={this.notifyPropertyChange("Acabados", pos)}
                                                        color="primary"/>
                                                }
                                                label={<Translate id="languages.datosTecnicos.tipoPlantaAcabados" />}/>}
                                        </Grid>

                                    </Grid>
                                    <Grid container style={{margin: 0}}>
                                        <Grid item xs={12}>
                                            <label className='MuiFormLabel-root-214' style={{

                                                marginBottom: -12,

                                                padding: 0,
                                                fontSize: 11,
                                                fontFamily: 'Montserrat',
                                                lineHeight: 1
                                            }}>
                                               <Translate id="languages.datosTecnicos.superficiesUsoPlanta" /></label>
                                        </Grid>
                                        
                                        <Grid item xs={9} >
                                                 {
                                            this.superficiesSeleccionadas().map(superficie =>
                                                (
                                                    <FormControl className={classes.formControl2} style={{padding: 5,width:"25%"}}
                                                    >
                                                        <TextField

                                                            label={superficie.nombre}
                                                            type={'number'}
                                                            value={item[superficie.Campo_Vinculado_Superficie]}
                                                            onChange={this.notifyPropertyChangeSuperficie(superficie.Campo_Vinculado_Superficie, pos)}
                                                            margin="normal"
                                                            inputProps={{ min: 0,  step: "1" }}
                                                            //onBlur={this.lostFocusSuperficie(superficie.Campo_Vinculado_Superficie,item[superficie.Campo_Vinculado_Superficie],pos)}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                    </FormControl>


                                               ))
                                        }
                                        </Grid>
                                       <Grid item xs={3} style={{textAlign: "right", paddingRight: 5, paddingTop:20}}>
                                        {edicion == 1 &&      
                                            <Button
                                                color="primary" className={classes.button}
                                                /*disabled={this.state.plantas.length == 1}*/
                                                onClick={() => this.removePlanta(pos)}>ELIMINAR PLANTA
                                            </Button>
                                            }
                                        </Grid>


                                    </Grid>


                                </Grid>)
                        })
                    }                  

              {(edicion == 1 ||  this.state.datosTecnicos.Parametros.Acondicionamientos_Elementos == 1 || this.state.datosTecnicos.Parametros.Mejoras_Envolvente == 1 )  &&
              <Grid item xs={12} style={{
                                    marginTop: 10,
                                    padding: 8,
                                    paddingTop: 0,
                                    border: '1px solid #cecece',
                                    marginRight: 10
                                }}>                                    
             <Grid container style={{margin: 0}}>                                                  
                <Grid item  xs={12}   >                  
                 <span style={{ fontSize: '0.625rem' }} >   <Translate id="languages.datosTecnicos.NoComputanSuperficies" /></span>
                 </Grid>          

                 <Grid item  xs={12} >
                     <p></p> 
                    {(edicion == 1 || this.state.datosTecnicos.Parametros.Mejoras_Envolvente == 1) && 
                      <FormControlLabel 
                      control={
                     <Checkbox 
                   
                    size="small"  
                    className={classes.tickSize}                                 
                    checked={this.state.datosTecnicos.Parametros.Mejoras_Envolvente}
                    style={{ padding: 2, paddingLeft: 15, paddingTop: 0}}
                    disabled={edicion == 0}
                    onChange={this.notifyPropertyChange("Mejoras_Envolvente", 0)}
                    color="primary"/>
                    }
                     label={<span style={{ fontSize: '0.625rem' }}>{   <Translate id="languages.datosTecnicos.NoComputanSuperficies1" />}</span>} />  
                    }      
                </Grid>               

                <Grid item  xs={12}   > 
                     {(edicion== 1 || this.state.datosTecnicos.Parametros.Acondicionamientos_Elementos == 1) &&
                    <FormControlLabel  
                     control={
                      <Checkbox   
                     size="small"  
                    className={classes.tickSize}      
                    disabled={edicion == 0}                           
                    checked={this.state.datosTecnicos.Parametros.Acondicionamientos_Elementos}
                    style={{padding: 2, paddingLeft: 15, paddingTop: 0}}
                    onChange={this.notifyPropertyChange("Acondicionamientos_Elementos", 0)}
                    color="primary"/>
                     }
                  label={<span style={{ fontSize: '0.625rem' }}>{   <Translate id="languages.datosTecnicos.NoComputanSuperficies2" />}</span>} />         
                 }                     
                 </Grid>
                </Grid>               
            </Grid>
            }
           
            <Grid item  xs={9}  spacing={0} >
            {(edicion == 1 || this.state.datosTecnicos.Parametros.Documentacion_Parcial_Instalaciones_Visada == 1) &&
                 <FormControlLabel   
                control={
                <Checkbox    
                className={classes.tickSize} 
                    checked={this.state.datosTecnicos.Parametros.Documentacion_Parcial_Instalaciones_Visada}
                    disabled={edicion == 0}           
                    style={{padding: 0, paddingLeft: 15, paddingTop: 0}}
                    onChange={this.notifyPropertyChange("Documentacion_Parcial_Instalaciones_Visada", 0)}
                    color="primary"/>
                }
                  label={<span style={{ fontSize: '0.625rem' }}>{<Translate id="languages.datosTecnicos.parcialYaVisado" />}</span>} />    
            }                 
            </Grid>  
               
            
            <Grid item  xs={3}    >
                {edicion == 1 && 
                <Button  style={{textAlign: "right", marginTop: 0, Align: "right"}}
                color="primary" className={classes.button}
                variant={!(!this.state.isModified || this.state.isCalculated)?"contained":''}
                disabled= {!this.state.isModified || this.state.isCalculated}                
                onClick={() => this.calcular()}>Calcular
                </Button>
                }
            </Grid>                                            
        
            </Grid>                                                               
     
        
            )                   
        }
        
    
    }

    render() {
        let {classes} = this.props;
        return (
            <div style={{marginTop: 10}}>
                {
                    this.state.loadingDatosTecnicos  && this.state.expandSectionTempFile == false ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                        <Paper
                            style={{borderColor: '#cecece', borderWidth: 1, marginBottom: 10}}>
                            <ExpansionPanel expanded={this.state.expandSectionTempFile}
                                            onChange={() => this.setState({expandSectionTempFile: !this.state.expandSectionTempFile})}>


                                <ExpansionPanelSummary style={{paddingLeft: 8}} expandIcon={<ExpandMoreIcon/>}>
                                    <div className="d-flex justify-content-between align-items-center"
                                         style={{width: "100%", marginLeft: 10}}>
                                        <div>
                                            <Typography variant='button' style={{float: "left"}}>
                                            <Translate id="languages.datosTecnicos.datosTecnicosPrecio" />


                                            </Typography>
                                            {this.state.datosTecnicos.Caracteristicas.SeRequiere_IntroducirDatosTecnicos == 1 && this.state.datosTecnicos.Caracteristicas.Datos_Tecnicos_Precio_Pendientes == 1 &&
                                            <Typography variant='button'
                                                        style={{color: '#ffc107', float: "left", marginLeft: 5}}>  <Translate id="languages.generalButton.sinCalcular" /></Typography>
                                            }
                                        </div>


                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{padding: "8px 0px 0px"}}>
                                    <div style={{marginLeft: 24, marginRight: 24, marginTop: -25, marginBottom: 24}}>

                                        {
                                            this.renderTiposSuperficie()
                                        }
                                        <Typography variant={"body1"} style={{
                                            fontSize: 12,

                                            textAlign: 'justify',
                                            padding: '0 18px',

                                        }}>

                                        </Typography>
                                        {
                                            this.renderPlantas()
                                        }


                                        <div style={{marginLeft: -24, marginRight: -20}}>
                                            {
                                                this.resultadoCalculo()
                                            }
                                            {
                                                this.detalles()
                                            }
                                           
                                        </div>

                                        <Grid container>
                                            {this.renderDatosEstadisticos()}
                                        </Grid>
                                     
                                        {
                                            this.state.datosTecnicos.Caracteristicas.SeRequiere_IntroducirDatosTecnicos==1&& this.state.datosTecnicos.Caracteristicas.SePuede_Editar==1&&  <Grid container>
                                                <Grid item xs={12}
                                                      style={{textAlign: "right", paddingRight: 20, marginBottom: 0 ,  paddingTop: 10}}>

                                                    <Button
                                                        color="primary" className={classes.button}
                                                        disabled={!this.state.isModified && !this.state.isModifiedDatosEst}                                                       
                                                        onClick={() => this.loadNomencladores()}><Translate id="languages.generalButton.cancel" />
                                                    </Button>
                                                    {
                                                        this.state.fetchingSave ? null : <Button
                                                            variant="contained"
                                                            disabled={this.state.fetchingSave || !this.state.allowSave}
                                                            color="primary" className={classes.button}
                                                            onClick={() => this.saveDatosTecnicos()}><Translate id="languages.generalButton.save" />
                                                        </Button>
                                                    }

                                                    {this.state.fetchingSave &&
                                                    <CircularProgress size={24} className={classes.buttonProgress}/>}


                                                </Grid>
                                            </Grid>
                                        }

                                    </div>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Paper>
                }


            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps =
    {
        fetchErrorExpediente: fetchErrorExpediente
    };


export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(DTProyectoEjecucion)));