import {BASE_PATH } from "./config.js";  
import {fetchData, loginRequest} from './config'
import axios from "axios";
import {handleLoggout} from "../helpers/logout";
import {formatMenssage} from "./config";
import {getDefaultLanguage} from "./index";


export default class APICOAG {
    static expedientes = {
        trabajo: {
            estructuraDocumental: {
                archivos: async (id_expediente, id_trabajo, folderId) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/estructuradocumental/${folderId}/archivos`);
                },
                informacionDeCarpeta: async (id_expediente, id_trabajo, folderId) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/estructuradocumentalinfocarpeta/${folderId}`);
                },
                detallesArchivo: async (id_expediente, id_trabajo, folderId) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/Estructuradocumental/${folderId}`, {Detalle_archivo: 1});
                },
                getUrlDescarga: async (id_expediente, id_trabajo, folderId) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/Estructuradocumental/${folderId}/InfoArchivoDescarga`);
                },
                getUrlDescargaMultiple: async (idExpediente, idTrabajo, Archivos) => {
                    return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/InfoArchivosDescarga`, {Archivos}, 'POST');
                },
                getUrlDescargaMultipleCompendio: async (idExpediente, idTrabajo, Archivos) => {
                    return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/InfoArchivosDescargaCompendio`, {Archivos}, 'POST');
                },
                getUrlDescargaTrabajoParaRetirar: async (id_expediente, id_trabajo) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/InfoArchivoDescargaTrabajoParaRetirar`);
                }, 
                getUrlDescargaTrabajoParaRetirarCarpetasPrincipales: async (id_expediente, id_trabajo) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/InfoArchivoDescargaTrabajoParaRetirarCarpetasPrincipales`);
                }, 
                getUrlDescargaTrabajoParaRetirarSinCarpetas: async (id_expediente, id_trabajo) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/InfoArchivoDescargaTrabajoParaRetirarSinCarpetas`);
                }, 
                getUrlDescargaTrabajoParaRetirarCompendio: async (id_expediente, id_trabajo) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/InfoArchivoDescargaTrabajoParaRetirarCompendio`);
                },  
                getUrlDescargaCertPresentacion: async (id_expediente, id_trabajo) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/InfoArchivoDescargaCertificadoPresentacion`);
                },                
                infoArchivovisualizacion: async (id_expediente, id_trabajo, folderId) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/Estructuradocumental/${folderId}/InfoArchivovisualizacion`);
                },
                subirArchivo: async (idExpediente, idTrabajo, folderId, file) => {
                    // return await uploadFile(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/${folderId}/archivos`,file)
                    let data = new FormData();
                    data.append('file', file.data);
                    data.append('filename', file.filename)

                    return fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/${folderId}/archivos`, data, 'UPLOAD')
                },
                moverFicheroDeTemporal: async (idExpediente, idTrabajo, folderId, Nombre) => {
                    return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/${folderId}/archivosdesdealmacentemporal`, {Nombre}, 'POST')
                }, 
                
                moverFicheroDeTemporalMostrarEn: async (idExpediente, idTrabajo, folderId, folderIdShowIn,Nombre) => {
                    return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/${folderId}/archivosdesdealmacentemporal/mostrar/${folderIdShowIn}`, {Nombre}, 'POST')
                },  
                
                moverFicherosDeTemporalMostrarEn: async (idExpediente, idTrabajo, folderId, folderIdShowIn, files) => {
                    return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/${folderId}/archivoslistadesdealmacentemporal/mostrar/${folderIdShowIn}`, files, 'POST')
                },  
                         
                             
                comprobarFicherosDeTemporalParaMoverAEstructura: async (idExpediente, idTrabajo, folderId, Archivos) => {
                    return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/${folderId}/archivosdesdealmacentemporalcomprobaciones`, {Archivos}, 'GET')
                },  

                moverOtraEstructura: async (idExpediente, idTrabajo, folderId, files) => {
                    return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/${folderId}/moverarchivos`,files, 'PUT')
                },              
                moverOtraEstructuraMostrarEn: async (idExpediente, idTrabajo, folderId, folderIdShow,files) => {
                    return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/moverarchivos/${folderId}/mostrar/${folderIdShow}`,files, 'PUT')
                },
                removerFicheros: async (idExpediente, idTrabajo, Archivos) => {
                    return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/archivos/`, {Archivos}, 'DELETE')
                },
                importarArchivos: async (idExpediente, idTrabajo, idTrabajoOrigen) => {
                    return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/estructuradocumental/${idTrabajoOrigen}/importararchivos`,0, 'PUT')
                },


            },
            datosTecnicos: {
                obtener:
                    async (id_expediente, id_trabajo) => {
                        return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/datostecnicosprecio`);
                    },
                simular: async (expediente, trabajo, request) => {
                    return await fetchData(`/expedientes/${expediente}/trabajos/${trabajo}/datostecnicosprecio/calcular`, request, 'PUT');

                },             
                guardar: async (expediente, trabajo, datosTecnicos) => {
                    return await fetchData(`/expedientes/${expediente}/trabajos/${trabajo}/datostecnicosprecio`, datosTecnicos, 'PUT');

                },
                calcular: async (datosTecnicos) => {
                    return await fetchData(`/datostecnicosprecio/calcular`, datosTecnicos, 'PUT');

                }
            },
            getDatosGenerales:
                /**
                 * Obtiene los datos generales de un trabajo
                 */
                async (id_expediente, id_trabajo) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}`);
                },
            getEstructuraDocumental:
                async (id_expediente, id_trabajo) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/estructuradocumental`);
                },
                getEstructuraDocumentalYArchivosDeCarpeta:
                async (id_expediente, id_trabajo,id_carpeta_mostrar) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/estructuradocumentalyarchivosdecarpeta/${id_carpeta_mostrar}`);
                },
                getEstructuraDocumentalCarpetas:
                async (id_expediente, id_trabajo) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/estructuradocumentalcarpetas`);
                },    
            otrosAgentes: async (id_expediente, id_trabajo) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/otrosagentes`);
            },
            postOtrosAgentes: async (id_expediente, id_trabajo, agentes) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/otrosagentes`, agentes, 'POST');
            },
            putOtroAgenteTrabajo: async (expediente, trabajo, agentes) => {
                return await fetchData(`/expedientes/${expediente}/trabajos/${trabajo}/otrosagentes`, agentes, 'PUT');
            },
            deleteOtroAgenteTrabajo: async (expediente, trabajo, agente) => {
                return await fetchData(`/expedientes/${expediente}/trabajos/${trabajo}/otrosagentes/${agente}`, {}, 'DELETE');
            },
            datosEconomicos: async (id_expediente, id_trabajo) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/datoseconomicos`);
            },
            putDatosEconomicos: async (expediente, trabajo, datosEconomicos) => {
                return await fetchData(`/expedientes/${expediente}/trabajos/${trabajo}/datoseconomicos`, datosEconomicos, 'PUT');
            },
            urlFactura: async (id_expediente, id_trabajo, factura) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/datoseconomicos/${factura}/InfoArchivodescarga`);
            },
            urlFacturaUri: async (id_expediente, id_trabajo, factura) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/datoseconomicos/InfoArchivodescarga`,{factura: factura});
            },
            comunicacionTramitacion: async (id_expediente, id_trabajo) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/comunicaciontramitacion`);
            },
            comunicacionTramitacionUrgente: async (id_expediente, id_trabajo) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/comunicaciontramitacionurgente`);
            },
            putComunicacionTramitacion: async (expediente,trabajo,comunicacion) => {
              return await fetchData(`/expedientes/${expediente}/trabajos/${trabajo}/comunicaciontramitacion`,comunicacion,'PUT');
            },
            incidencias: async (id_expediente, id_trabajo) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/incidencias`);
            },
            retirarExpediente: async (id_expediente, id_trabajo) => {
              //  return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/infoarchivodescarga`);              
              return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/InfoArchivoDescargaTrabajoParaRetirar`);
            },
         
             
            autoretener: async (id_expediente, id_trabajo) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/abrirtrabajo`, {}, 'POST');
            },
            deleteTrabajo: async (idExpediente, idTrabajo) => {
                return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}`, {}, 'DELETE');

            },
            cerrarTrabajo: async (idExpediente, idTrabajo) => {
                return await fetchData(`/expedientes/${idExpediente}/trabajos/${idTrabajo}/cerrartrabajo`, {}, 'POST');
            },
            putTramitacionUrgente: async (expediente,trabajo,motivo) => {
              return await fetchData(`/expedientes/${expediente}/trabajos/${trabajo}/comunicaciontramitacionurgente`,{
                        "comunicacion": motivo
                    },'PUT');
            },
            putFichaTrabajo:async (id_expediente, id_Trabajo, data) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_Trabajo}`, data,'PUT');

            },
            putAutorizarEnvioAdmTrabajo:async (id_expediente, id_Trabajo,data) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_Trabajo}/AutorizarAccesoAdministracion`,data,'PUT');

            },
            putDesAutorizarEnvioAdmTrabajo:async (id_expediente, id_Trabajo,data) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_Trabajo}/DesAutorizarAccesoAdministracion`,data,'PUT');

            },
            postColegiados: async (id_expediente, id_trabajo, data) => {
                return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/promotores`, data, 'POST');
            },
            getPromotores:
            //todo: No se está utilizando
                async (id_expediente, id_trabajo) => {
                    return await fetchData(`/expedientes/${id_expediente}/trabajos/${id_trabajo}/promotores`);
                },
            putPromotores:async (data) => {
                    return await fetchData(`/api/entidades/`, data,'PUT');    
             },    

        },
        /**
         * Obtiene los datos del expediente
         * @param expediente
         * @returns {Promise<*|undefined>}
         */
        getDatosGenerales: async (expediente) => {
            return await fetchData(`/expedientes/${expediente}`)
        },
        putExpediente: async (data) => {
            return await fetchData(`/expedientes/${data.Id_Expediente}`, data,'PUT');
        },
        putExpedienteSubstituciones: async (data) => {
            return await fetchData(`/expedientes/${data.Id_Expediente}/Substituciones`, data,'PUT');
        },
        putExpedienteDatosEstudio: async (data) => {
            return await fetchData(`/expedientes/${data.Id_Expediente}/DatosEstudio`, data,'PUT');
        },
        getTrabajosExpediente:
            /**
             * Obtiene los trabajos asociados a un expediente
             * @param expediente
             * @returns {Promise<*|boolean>}
             */
            async (expediente) => {
                return await fetchData(`/expedientes/${expediente}/trabajos`)
            },
        getExpedientesUser:
            /**
             * Obtiene los trabajos asociados a un usuario
             * @param expediente
             * @returns {Promise<*|boolean>}
             */
            async () => {
                return await fetchData(`/expedientes`)
            },
        filtrarExpedientes:
            /**
             * Obtiene los expedientes en dependencia de la accion expecificada
             *
             */
            async (filtro, id_tipo_accion, pag, tam) => {
                return await fetchData(`/expedientes`, {filtro, pag, tam, id_tipo_accion})
            },
        getDatosSociedad: async (expediente, id_colegiado) => {
            return await fetchData(`/expedientes/${expediente}/datoscolegiadosparaencomenda`, {id_colegiado})
        },
        getEncomendaActual: async (expediente) => {
            return await fetchData(`/expedientes/${expediente}/EncomendaActual`)
        },
        getEncomendaActualSinObservaciones: async (expediente) => {
            return await fetchData(`/expedientes/${expediente}/EncomendaActualSinObservaciones`)
        },

        getEncomendaActualParaAsistenteTrabajo: async (expediente) => {
            return await fetchData(`/expedientes/${expediente}/EncomendaActualSinMensajes`)
        },
        
        
        sePuedeCrearTrabajo: async (expediente) => {
            return await fetchData(`/expedientes/${expediente}`, {sepuede: 'creartrabajo'})
        },
        getArchivosTemporales: async (expediente) => {
            return await fetchData(`/expedientes/${expediente}/AlmacenTemporalArchivos`)
        },
        getArchivoTemporal: async (expediente,fileName) => {
            return await fetchData(`/expedientes/${expediente}/AlmacenTemporalArchivos`,  {nombre: fileName})
        },
        postExpedienteAccion: async (expediente, id_tipo_accion) => {
            let params = {GET: {id_tipo_accion}}
            return await fetchData(`/expedientes/${expediente}/Accion`, params, 'POST')
        },
        postTrabajos: async (expediente, data,) => {
            return await fetchData(`/expedientes/${expediente}/trabajos`, data, 'POST');
        },
        postExpediente: async (data,) => {
            return await fetchData(`/expedientes`, data, 'POST');
        },
        manageEncomenda: async (expediente, data,) => {
            return await fetchData(`/expedientes/${expediente}/trabajos/EncomendayOtros`, data, 'POST');
        },
        subirArchivoATemporal: async (idExpediente, file) => {
            let data = new FormData();
            data.append('file', file.data);
            data.append('filename', file.filename)
            return fetchData(`/expedientes/${idExpediente}/AlmacenTemporalArchivos`, data, 'UPLOAD')
        },
        eliminarArchivosTemporales: async (idExpediente, arrayFiles) => {

            return fetchData(`/expedientes/${idExpediente}/AlmacenTemporalArchivos`,
                {Archivos: arrayFiles}, 'DELETE')
        },
     

        autoAssignFiles: async (idExpediente, idTrabajo, file) => {

            return fetchData(`/expedientes/${idExpediente}/AlmacenTemporalArchivos/AsignacionAutomatica`,                {
                    Id_Trabajo: idTrabajo,
                    Archivos: file,
                    InsertarArchivos: 0
                }, 'POST')
        },

        autoAssignFilesInfo: async (idExpediente, idTrabajo, file) => {

            return fetchData(`/expedientes/${idExpediente}/AlmacenTemporalArchivos/AsignacionAutomaticaInfo`,                {
                    Id_Trabajo: idTrabajo,
                    Archivos: file,
                    InsertarArchivos: 0
                }, 'POST')
        },

        autoAssignFilesSave: async (idExpediente, idTrabajo, file) => {

            return fetchData(`/expedientes/${idExpediente}/AlmacenTemporalArchivos/AsignacionGuardar`,                {
                    Id_Trabajo: idTrabajo,
                    Archivos: file                   
                }, 'POST')
        },

        downloadTemporalFiles: async (idExpediente, Archivos) => {


            return await fetchData(`/expedientes/${idExpediente}/almacentemporalarchivos/InfoArchivosDescarga/?idioma=${getDefaultLanguage()}`,
                {
                    Archivos
                }, 'POST'
            );

        },
        deleteExpediente: async (idExpediente) => {
            return await fetchData(`/expedientes/${idExpediente}`, {}, 'DELETE');
        },
        putEmplazamiento:async (idExpediente, data) => {
            return await fetchData(`/expedientes/${idExpediente}/emplazamientos`, data,'PUT');
        },

        deleteEmplazamiento:async (idExpediente,  idEmplazamiento) => {
            return await fetchData(`/expedientes/${idExpediente}/emplazamientos/${idEmplazamiento}`, {},'DELETE');
        },
        putEmplazamientoGeolocalizacion:async (idExpediente, data) => {
            return await fetchData(`/expedientes/${idExpediente}/emplazamientos/Geolocalizacion`, data,'PUT');
        },
        postExpedientesRenuncias:async (data) => {
            return await fetchData(`/expedientesrenuncias`, data,'POST');
        },
      

    };
    static tipos = {
        guia: {
            datosTecnicos: {
                usoEdificacion: async () => {
                    return await fetchData('/tipos/guia/datostecnicos/usosedificacion');
                },
                plantas: async () => {
                    return await fetchData('/tipos/guia/datostecnicos/plantas');
                },
                usosPlaneamientos: async () => {
                    return await fetchData('/tipos/guia/datostecnicos/UsosPlaneamientoUrbanismo');
                },
            },
            infoCarpetasDeTrabajo: async (id_tipo_trabajo, id_tipo_tramite, es_modificado) => {
                return await fetchData('/tipos/guia/infocarpetasdetrabajo', {
                    id_tipo_trabajo,
                    id_tipo_tramite,
                    es_modificado
                });
            },
            /**
             * Proporciona los tipos de trámite de un trabajo
             * @param idLanguage
             * @returns {Promise<AxiosResponse<any>>}
             */
            tiposautorizacionmunicipal: async () => {
                return await fetchData('/tipos/guia/Tiposautorizacionmunicipal');
            },
            autorizacionmunicipal: async (id_tipo_grupo_tematico) => {
                return await fetchData('/tipos/guia/Tiposautorizacionmunicipal', {id_tipo_grupo_tematico});
            },
            grupostematicos: async (id_tipo_grupo_raiz,idioma) => {
                return await fetchData('/tipos/guia/grupostematicos', {id_tipo_grupo_raiz,idioma});
            },
            fasesTrabajos: async (Id_Tipo_Grupo_Tematico, Id_Tipo_Autorizacion_Municipal,idioma) => {
                return await fetchData('/tipos/guia/Fasestrabajos', {
                    Id_Tipo_Grupo_Tematico,
                    Id_Tipo_Autorizacion_Municipal,
                    idioma
                });
            },
            tiposTramites: async (idioma) => {
                return await fetchData('/tipos/guia/tramites',{idioma});
            },
            gruposRaiz: async (idioma) => {
                return await fetchData('/tipos/guia/gruposraiz',{idioma});
            },
            funcionesOtrosAgentes: async () => {
                return await fetchData('/tipos/guia/funciones', {otros_Agentes: 1});
            },
            funcionesTipologia: async (id_tipo_grupo_tematico, id_tipo_autorizacion_municipal) => {
              
                return await fetchData('/tipos/guia/funciones', {
                    id_tipo_grupo_tematico,
                    id_tipo_autorizacion_municipal
                });
           
            },

            tipoAclaracion: async (Id_Tipo) => {
                return await fetchData('/tipos/guia/aclaraciones', {
                    Id_Tipo                  
                  
                });
            },
          

        },
        paises: async () => {
            return await fetchData('/tipos/paises');
        },
        regionesAutonomas: async (id_pais) => {
            return await fetchData('/tipos/autonomias', {id_pais});
        },
        provincias: async (id_autonomia) => {
            return await fetchData('/tipos/provincias', {id_autonomia});
        },
        concellos: async (id_provincia) => {
            return await fetchData('/tipos/Concellos', {id_provincia});
        },
        tiposPromotores: async () => {
            return await fetchData('/tipos/tipos_promotores');
        },
        tiposOtrosAgentes: async () => {
            return await fetchData('/tipos/Tipos_Otros_Agentes');
        },
        tiposOrganismos: async () => {
            return await fetchData('/tipos/tipos_organismos');
        }               

    };
    static datosCatastro = {
        validateAddress: async (referenciacatastral) => {
            
            if (referenciacatastral=='')
            {referenciacatastral='none';}
            //return await fetchData(`/DatosCatastro/${ref_catastral}`)
            return await fetchData('/DatosCatastro', {
                referenciacatastral            
            });
       
        },
        validateAddressUTM: async (este,norte,zona) => {
              
            return await fetchData('/DatosCatastro', {
                este,
                norte,
                zona
            });
       
        },
        validateAddressGeo: async (latitud,longitud) => {
              
            return await fetchData('/DatosCatastro', {
                latitud,
                longitud
            });
       
        },
        validateAddressCoor: async (coor1,coor2,zona=29) => {
              
            return await fetchData('/DatosCatastro', {
                coor1,
                coor2,
                zona
            });
       
        },
    };
    static usuario = {
        getToken: async () => {
            try {
                let clienteId = await localStorage.getItem('clienteid');
                let clienteClave = await localStorage.getItem('clienteclave');                
                let response = await axios.post(BASE_PATH + '/authenticate',
                    {
                        ClienteId: clienteId,
                        ClienteClave: clienteClave
                    });

                await localStorage.setItem('token', response.headers.token);
                return response;
            } catch (error) {
                handleLoggout();
                formatMenssage(error.message);
            }
        },
        login: async (datos) => {
            return await loginRequest(datos)
        },
        putUsuario: async (data) => {
            return await fetchData(`/usuarios`, data,'PUT');},
        getUsuario: async (data) => {
                return await fetchData(`/usuarios`);}    ,
        postUsuario: async (data) => {
                return await fetchData(`/usuarios/alta`,data,'POST',0);}    ,
        postUsuarioAclaracion: async (data) => {
                    return  fetchData(`/EntidadesAclaraciones`,data,'POST',1);}    ,                            
        putUsuarioAclaracionLeida: async (data) => {
            return  fetchData(`/EntidadesAclaraciones`,data,'PUT',1);}    ,  
        putUsuarioImp: async (data) => {
            return await fetchData(`/usuarios/imp`, data,'PUT');},  
        postUsuarioLogAccion: async (data) => {
                return await fetchData(`/logEntidadAccion`,data,'POST',1);}   

    }
    static accionesPendientes = {
        getTodas:
            /**
             *obtiene las acciones pendientes(todas), luego las pagina el componente
             */
            async () => {
                return await fetchData(`/AccionesPendientes`);
            },
        postAcciones: async () => {
            return await fetchData(`/AccionesPendientes`, {}, 'POST');
        },
    }
    static general = {
        buscador:
            /**
             * Búsqueda de expedientes
             * @returns {Promise<void>}
             */
            async (filtro, tipoBusqueda, pag = 1, tam = 25) => {
                let params = {
                    pag, tam
                }
                if (tipoBusqueda == 'colegiados' && !filtro) {
                    if (filtro) {
                        params.filtro = 'a'
                    }
                }
                if (filtro) {
                    params.filtro = filtro
                }
                return await fetchData(`/${tipoBusqueda}`, params);
            },
        buscadorOtrosAgentes: async (filtro,pag = 1, tam = 10) => {
            let params = {
                pag, tam
            }
            if (filtro) {
                params.filtro = filtro
            }
            return await fetchData(`/otrosagentes`, params)
        }
    }


}





