import React, { Component } from 'react';
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import {
  withStyles, Grid, Paper, Typography, Divider,
  TextField, Button, CircularProgress, Dialog,DialogContent,DialogTitle,DialogActions,Tooltip,Select,FormControl,MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { grey,blue } from '@material-ui/core/colors';
import moment from 'moment';
import { Table, TableCell, TableHead, TableBody, TableRow, Fab, IconButton   } from '@material-ui/core';
import { Add, Edit, Check, Close, Delete, AddCircle,MyLocation,LocationOn, LaptopWindows } from '@material-ui/icons';
import Confirmation from "../../../Errors/confirmation"
import { dispatchEditExpedienteEnTrabajo, fetchErrorExpediente} from '../../../../actions/expedientes';
import ValidateAddress from '../../../Address/index';
import ValidateAddressModal from '../../../Address/validateAddressModal';
import { elimardelatabla, saveAdressTostore } from "../../../../actions/expedientes";
import { some, findIndex } from 'lodash';
import classNames from 'classnames';
import ApiCoag from "../../../../api/ApiCoag";
import {URL_CATASTRO,ID_TIPO_EMP_GEO,ID_TIPO_RELACION_SUBS,ID_TIPO_RELACION_CF} from "../../../../api/config";
import {isMobile,urlUbicacion,urlCatastro} from "../../../../api/index";
import ReactQuill from 'react-quill';


const styles = theme => ({
  divGrey: {
    backgroundColor: grey[100],
    margin: 16
  },
  backgroundGrey: {
    backgroundColor: grey[100]
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "98%",
    textAlign: 'left',
    marginTop: 5
  },
  headHeight: {
    height: 30 //40
  },
  table: {
    minWidth: 200,
  },
  tableBodyHeight: {
    minHeight: 100
  },
  fab: {
    margin: theme.spacing.unit,
    marginTop: 25,
    position: 'absolute',
    marginLeft: 40
  },
  tableBorder: {
    border: "2px solid " + grey[200]
  },
  buttonEdit: {
    border: "1.2px solid",
    margin: 2,
    padding: 6,
  },
  withoutRadius: {
    borderRadius: 0
  },
  headerBorder: {
    border: "2px solid " + grey[200],
    borderBottom: 0
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  disable: {
    pointerEvents: "none",
    opacity: 0.6
  }
});

const CustomTableHead = withStyles(theme => ({
  head: {
    backgroundColor: grey[100],
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

class FichaExpediente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceExpediente: this.props.sourceExpediente,
      emplazamientos: this.props.expediente.Emplazamientos,
      location: {},
      isUpdate: false,
      isAddUbicacion: false,
      isShowAddress: false,
      isLoadingSave: false,
      isDeletingUbicacion: false,
      idEmplazamientoDeleting:-1,
      showModal:false,
      observaciones:'',
      isUpdateObservaciones:false,
      changingWorkData:false,
      idTipoRelacion:this.props.sourceExpediente.Id_Tipo_Relacion  
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.sourceExpediente.Substitucion!=prevState.sourceExpediente.Substitucion) {      
      let expedienteCopy = {};
      Object.assign(expedienteCopy, prevState.sourceExpediente);
      expedienteCopy['Id_Tipo_Relacion'] = nextProps.sourceExpediente.Id_Tipo_Relacion; 
      expedienteCopy['Substitucion'] = nextProps.sourceExpediente.Substitucion; 
      return {       
        idTipoRelacion: nextProps.sourceExpediente.Id_Tipo_Relacion,
        sourceExpediente: expedienteCopy 
      };
    }
 
    return null;
  }
 

  handleChangeDataExpedient = (propertyName) => (event) => {
    let expedienteCopy = {};
  
    Object.assign(expedienteCopy, this.state.sourceExpediente);
    if (propertyName !='Observaciones' && propertyName !='Id_Tipo_Relacion' ){
      expedienteCopy[propertyName] = event.target.value.trimStart();
      if (propertyName =='Antecedente') {
        if (expedienteCopy[propertyName]=='')
          this.setState({ idTipoRelacion:null})
        if (expedienteCopy[propertyName]!='' && this.props.sourceExpediente.Substitucion==1){
          this.setState({ idTipoRelacion:ID_TIPO_RELACION_SUBS})
          expedienteCopy['Id_Tipo_Relacion'] = ID_TIPO_RELACION_SUBS;             
        }
      }      

    }
    if (propertyName =='Observaciones' )
      expedienteCopy[propertyName] = event;
    if (propertyName =='Id_Tipo_Relacion' ){          
      let mensaje=false;
      if  (this.props.sourceExpediente.Substitucion==0 && event.target.value==ID_TIPO_RELACION_SUBS)
         mensaje=true;
      this.setState({ idTipoRelacion: event.target.value,showMensajeCambioFormatoASubs:mensaje});
      expedienteCopy['Id_Tipo_Relacion'] = event.target.value;     
    }

    this.setState({ sourceExpediente: expedienteCopy ,  changingWorkData:true });    

  }

  


  handleReloadDataExpedientOriginal () {
    let expedienteCopy = {};
    Object.assign(expedienteCopy,  this.props.sourceExpediente);

    let emplazamientosCopy = {};
    Object.assign(emplazamientosCopy,  this.props.expediente.Emplazamientos);
   
   
    this.setState({ sourceExpediente: expedienteCopy ,changingWorkData:false });    
  }

  handleChangeDataObservaciones= () => (event) => { 
        this.setState({ observaciones: event });    
  }

  
  b(val){
    return val==1?true:false;
  }

  fromb(val){
    return val?1:0;  
  }

  async handleSubmit() {
 
      
    this.setState({ isUpdate: true });
    let result = await ApiCoag.expedientes.putExpediente(this.state.sourceExpediente)
    if (result && result == 'ERROR2') //result.MensajesProcesado && result.MensajesProcesado.length > 0) 
    {
      //this.props.fetchErrorExpediente(result.data);
    } else {
      this.props.updateExpediente(result);      
      this.setState({ changingWorkData: false });
    }
    this.setState({ isUpdate: false  });
  }

  handleShowUbication(action) {
    if (action)
      if (this.state.sourceExpediente.SePuede_Editar==1) {
        this.setState({ isAddUbicacion: action, isShowAddress: false, fromAdd: true, location: null,fromGeo:false   });  
      }
      else{
        this.setState({ showMessageTramitado:true });  
      }        
    else
      this.setState({ isAddUbicacion: action, isShowAddress: false, fromAdd: true, location: null,fromGeo:false   });        
  }

  handleUpdateLocation(location,coor,introUtm) {
    if (location != null)
    {
     if(this.state.location){
       location["Id_Emplazamiento"] = this.state.location.Id_Emplazamiento;      
     }

     
        if (coor!=null)        
        {
          if (introUtm==0)
          { location["Latitud"]=coor[0];
            location["Longitud"]=coor[1];
          }

          if (introUtm==1)
          { location["UTM_X"]=coor[0];
            location["UTM_Y"]=coor[1];
            location["UTM_H"]=coor[2];        
          }

          location["INTRO_UTM"]=introUtm        
      }   
  
      this.setState({ location: location,isDisabledSave:false }); 
    }     
   
  }

  handleUpdateIsShowAddress(showAddress) {
    this.setState({ isShowAddress: showAddress && !this.state.fromGeo });
  }

  handleNoLocation() {
    this.setState({ isDisabledSave: true});
  }

 
  async handleSaveAddress() {

    if (this.state.sourceExpediente.SePuede_Editar!=1) 
      this.setState({ showMessageTramitado:true });  
    else{
    let { location, emplazamientos, fromAdd } = this.state;
    await this.setState({ isLoadingSave: true });
    let locations = [];
    Object.assign(locations, emplazamientos);

    if (!emplazamientos.some(x => location.Id_Emplazamiento && x.Id_Emplazamiento === location.Id_Emplazamiento)) {
      locations.push(location);
    } else {
      let index = emplazamientos.findIndex(x => x.Id_Emplazamiento === location.Id_Emplazamiento);
      locations[index] = location;
    }

    let response = await ApiCoag.expedientes.putEmplazamiento(this.state.sourceExpediente.Id_Expediente, { "Emplazamientos": locations, "ignorarobservaciones": 1 });
    if (response =='ERROR2'){//(response && response.MensajesProcesado && response.MensajesProcesado.length > 0) {
    //  this.props.fetchErrorExpediente(response.data);
      this.setState({ isLoadingSave: false });
    }
    else {
      let emplazamientos = response && response.Emplazamientos
        ? response.Emplazamientos : locations;
      this.setState({
        emplazamientos: emplazamientos,
        isShowAddress: false, isLoadingSave: false
      });
      this.handleShowUbication(false);
    }
  }
  }

  async handleSaveGeo(emplazamientos) {
      
      this.setState({
        emplazamientos: emplazamientos,
        isShowAddress: false, isLoadingSave: false
      });
      this.handleShowUbication(false);
  }


  async handleRemoveAddress(idEmplazamiento) {

    if (this.state.sourceExpediente.SePuede_Editar!=1) 
      this.setState({ showMessageTramitado:true });  
    else
    {
    this.setState({  isDeletingUbicacion: true , idEmplazamientoDeleting:idEmplazamiento });
    
    let response = await ApiCoag.expedientes.deleteEmplazamiento(this.state.sourceExpediente.Id_Expediente,idEmplazamiento);
    this.setState({ isDeletingUbicacion:false , idEmplazamientoDeleting:-1  });
    if (response && response.MensajesProcesado && response.MensajesProcesado.length > 0) {
     
    }
    else {
      if (response.Emplazamientos != null )
      { let emplazamientos = response.Emplazamientos
         this.setState({
         emplazamientos: emplazamientos         
      });}  
    }
    } 
    
  }


  ifEqual(data, address) {
    let equal = some(data, address);
    let index = -1;
    if (equal) {
      index = findIndex(data, address);
    }
    return index;
  }

  handleEdit(location) {
    this.setState({ isShowAddress: true, isAddUbicacion: true, fromAdd: false, location: location ,fromGeo: false})
  }

  handleEditGeo(location) {
    this.setState({ isAddUbicacion: true, isShowAddress: false, fromGeo: true, fromAdd: false,location: location });
  }

  verObservaciones() {

    this.setState({   
      observaciones:this.state.sourceExpediente.Observaciones,
        showModal: true            
    })

}

 async guardarObservaciones() {
  //guardar en api
  this.setState({ isUpdateObservaciones: true});

  let expedienteCopy = {};
  Object.assign(expedienteCopy, this.state.sourceExpediente);
  expedienteCopy['Observaciones'] = this.state.observaciones;
  await this.setState({ sourceExpediente: expedienteCopy });  


  let result = await ApiCoag.expedientes.putExpedienteDatosEstudio(this.state.sourceExpediente)
  if (result && result.MensajesProcesado && result.MensajesProcesado.length > 0) {
    //this.props.fetchErrorExpediente(result.data);
  } else {
    this.props.updateExpediente(result);
  }
  this.setState({ isUpdateObservaciones: false });

}

catastroEnlace = (empr,ref) => {

  if (ref!=null && ref!='' && (ref.trim().indexOf(",")==-1 &&  ref.trim().indexOf(" ")==-1 )){        
      return (   
        <Tooltip title='Ir a catastro' >
      <a alt='catastro' target="_blank" href={URL_CATASTRO +ref} > {empr}  </a>           
      </Tooltip>              
      );
    }
 
    return empr
}




handleShowGeo(ref,lat,lon){
  if (ref!=null && ref!='')
   { window.open(urlCatastro(ref))
  };
  window.open(urlUbicacion(lat,lon), '_blank'); 
}


comprobarExisteGeoEnRow = (lat) => {

   
   if (lat==null || lat=='' || lat=='0')  
      return false     
   else
      return true  
    }

 comprobarExisteGeo (emplazamientos)  
 {
  if (emplazamientos.filter(item => item.Latitud !== null && item.Longitud !== null && item.Latitud!=0 && item.Longitud!=0 && item.Latitud!='' && item.Longitud!='').length>0)
   return true
  else
    return false
 }

   
  detalles() {

  if (this.state.showModal) {
  //  let {titulo} = this.state
    return (<div>
        <Dialog  maxWidth="md" fullWidth={true}  minHeight="300px" open={this.state.showModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                Edición de  <Translate id="languages.fichaExpediente.labelObservationsEstudio" />
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={12}>
                    <Grid item xs={12}>
                        {/*titulo*/}
                    </Grid>
                    <Grid item xs={12} style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 0}}>
                      
                        <ReactQuill   autoFocus={true}  value={this.state.observaciones}                                                                  
                                                                    placeholder={  this.state.observaciones  == null  || this.state.observaciones =='' ? this.props.translate("languages.expedients.helperTextObservaciones"): ''}
                                                                    style={{  height: '170px' , resize: 'vertical'}}                                                                                                 
                                                                    onChange={this.handleChangeDataObservaciones()} 
                                                                    />                            

                      
                    </Grid>
                    <Grid item xs={12}>
                        <br></br>
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
            <Grid container spacing={12}>
            <Grid item xs={10}>
            </Grid> 
            <Grid item xs={1}>
            <Button onClick={ async () => {
                    await this.guardarObservaciones()
                  
                }} variant="contained"                        
                        color="primary"  style={{ minHeight: 20 }}
                        className="p-1">                    
                     <Translate id="languages.generalButton.save" />

              </Button>
              </Grid>
              <Grid item xs={1}>
                              <Button onClick={async () => {
                    this.setState({
                        showModal: false
                    })

                }} variant="contained"
                        color="primary"  style={{minHeight: 20}}
                        className="p-1">
                   <Translate id="languages.fileUpload.cerrar" />

                </Button>
                </Grid>                
                </Grid>
            </DialogActions>
        </Dialog>
    </div>);
    }
  }




 

  renderUbicationTable() {
    let { classes } = this.props;    
    let bExisteGeo=this.comprobarExisteGeo(this.state.emplazamientos)

    return (
      <div className="p-2">
        <Grid container >
          <Grid item xs={10}>
            <Typography variant="subtitle1" gutterBottom className="m-1">
              <Translate id="languages.fichaExpediente.titleUbication" />
            </Typography>
          </Grid>
        
          <Grid item xs={2}>
            <Fab size="small" color="primary" aria-label="Add"
              onClick={() => this.handleShowUbication(true)}
              className={classes.fab}>
              <Add />
            </Fab>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.tableWrapper}>
              <Table className={`${classes.table} ${classes.tableBorder}`}>
                <TableHead>
                  <TableRow className={classes.headHeight}>
                    <CustomTableHead className="text-uppercase px-2" width="66%">
                      <Translate id="languages.fichaExpediente.tableLabelStret" />
                    </CustomTableHead>
                    {/*}
                    <CustomTableHead className="text-uppercase" >NUM</CustomTableHead>
                    <CustomTableHead className="pl-3 text-uppercase" width="20%">
                      <Translate id="languages.fichaExpediente.tableLabelPiso" />
    </CustomTableHead>*/}
                      {/*<CustomTableHead className="text-uppercase px-2" >CP</CustomTableHead>*/}
                    <CustomTableHead className="text-uppercase px-1"  >
                      <Translate id="languages.fichaExpediente.tableLabelMunicipe" />
                    </CustomTableHead>
                    <CustomTableHead className="text-uppercase px-2" width="17%"></CustomTableHead>
                  </TableRow>
                </TableHead>

                <TableBody className={classes.tableBodyHeight}>
                  { 
                    this.state.emplazamientos.length === 0 ?
                      <TableRow>
                        <TableCell colSpan={6}></TableCell>
                      </TableRow>
                      : this.state.emplazamientos.map((row, index) => {
                        let bExisteGeoEnRow=this.comprobarExisteGeoEnRow(row.Latitud);
                        return (                    
                          <TableRow className={classes.row} key={index}>
                            <TableCell component="th" scope="row" className="px-1 text-center" width="66%">
                              {this.catastroEnlace(row.Calle + ' ' +row.Numero + ' '+ row.Piso + (row.Alias!=''?' ['+row.Alias+']' :'') ,row.Georeferencia)}
                              {/*row.Calle + ' ' +row.Numero + ' '+ row.Piso + (row.Alias!=''?' ['+row.Alias+']' :'') */} 

                            </TableCell>
                           {/* <TableCell className="pl-3">{row.Numero + ' '+ row.Piso }</TableCell>
                            <TableCell className="p-3">{row.Piso}</TableCell>*/}
                              {/* <TableCell className="p-0">{row.Codigo_Postal}</TableCell>*/}
                            <TableCell className="p-0">{row.Concello}</TableCell>
                            <TableCell width="14%" className="px-2">      
                            <Tooltip title={<Translate id="languages.generalText.modificarEmplazamiento" />} >                   
                            <IconButton className={classes.buttonEdit} aria-label="Edit" color="primary" onClick={() => { this.handleEdit(row) }}>
                               <Edit />
                             </IconButton>
                             </Tooltip>
                              { this.state.emplazamientos.length > 1 ?                              
                              this.state.isDeletingUbicacion && row.Id_Emplazamiento === this.state.idEmplazamientoDeleting ? <CircularProgress  size={18}/>   
                              :
                              <IconButton className={classes.buttonEdit} color="primary" aria-label="Delete"
                                        onClick={() => this.handleRemoveAddress(row.Id_Emplazamiento) }>   
                                                                             
                              <Delete />
                              </IconButton>     
                              : null
                              }
                                {
                                 bExisteGeoEnRow && <Tooltip title={<Translate id="languages.generalText.irAEmplazamiento" />} >
                              <IconButton className={classes.buttonEdit} style={row.Tipo==ID_TIPO_EMP_GEO?{color:blue[200]}:{color:blue[500]}} aria-label="ShowLocation"
                                      onClick={() => { this.handleShowGeo(row.Georeferencia,row.Latitud,row.Longitud) }} 
                                       >                                                                                                                   
                              <LocationOn/>
                              </IconButton>     
                              </Tooltip>                         
                               }
                              {((!bExisteGeoEnRow && !bExisteGeo && this.state.sourceExpediente.SePuede_Editar!=1) || row.Tipo==ID_TIPO_EMP_GEO) &&                              
                              <Tooltip title={!bExisteGeoEnRow? <Translate id="languages.generalText.geolocalizarEmplazamiento" />:<Translate id="languages.generalText.modificarGeolocalizacionEmplazamiento" />} >
                              <IconButton className={classes.buttonEdit} color="primary" aria-label="EditGeoLocation"
                                        onClick={() => { this.handleEditGeo(row) }} 
                                        style={!bExisteGeoEnRow?  {color:'orange'}:null} >                                        
                                                                             
                              <MyLocation/>
                              </IconButton>     
                              </Tooltip> }
                           
                             

                            </TableCell>
                          </TableRow>
                        );
                      })
                  }
                </TableBody>
              </Table>
            </div>

           

          </Grid>
        </Grid>
        {
          this.state.isAddUbicacion &&
          <Grid item xs={12} className="pt-2">
            {!this.state.fromGeo?
            <ValidateAddress updateLocation={(location,coor,introUtm) => { this.handleUpdateLocation(location,coor,introUtm) }}
              isShowAddress={this.state.isShowAddress}
              updateIsShowAddress={(showAddress) => { this.handleUpdateIsShowAddress(showAddress) }}
              updateNoLocation={() => { this.handleNoLocation() }}
              location={this.state.location} 
              key={this.state.location ? this.state.location.Id_Emplazamiento : 1} 
               />:
              <ValidateAddressModal open={this.state.isAddUbicacion} 
              idExpediente={this.state.sourceExpediente.Id_Expediente}         
              idEmplazamiento={this.state.location.Id_Emplazamiento}
              expedienteCodigo={this.state.sourceExpediente.Expediente_Codigo}       
              updateLocation={(location,coor,introUtm) => { this.handleUpdateLocation(location,coor,introUtm) }}            
              location={this.state.location} 
              updateNoLocation={() => { this.handleNoLocation() }}
              key={this.state.location ? this.state.location.Id_Emplazamiento : 1} 
              updateIsShowAddress={(showAddress) => { this.handleUpdateIsShowAddress(showAddress) }}
              onClickCancel={() => { this.handleShowUbication(false) }}
              saveGeo={(emplazamientos) => { this.handleSaveGeo(emplazamientos) }}    
               />
            }

          {!this.state.fromGeo && 
            <Grid item xs={12} className="text-right">
              <Button color="primary" size="small" className={`${classes.button} mx-2`}
                onClick={() => { this.handleShowUbication(false) }}>
                <Translate id="languages.generalButton.cancel" /><Close className={classes.rightIcon} />
              </Button>              
                <Button variant="contained" size="small" color="primary" className={classes.button}
                disabled={this.state.isDisabledSave || this.state.isLoadingSave || (!this.state.location ||  (this.state.location["Calle"] == null || this.state.location["Calle"].trim() == "") || (this.state.location["Id_Concello"] == null || this.state.location["Id_Concello"] == "") ) }
                onClick={() => { this.handleSaveAddress() }}>               
                   <Translate id={ this.state.fromAdd?"languages.generalButton.added":"languages.generalButton.modification"}  />                  
               {this.state.isLoadingSave && <CircularProgress size={20} />}                            
              </Button> 
            </Grid>}
         
          </Grid>
          
        }
      </div>
    );
  }






  render() {
    let { classes } = this.props;
    let disabledEditTitle = this.props.expediente && this.props.expediente.Trabajos
      && this.props.expediente.Trabajos.some(x => x.Id_Estado >= 3);
      let esSubstitucion=false;
      let muestraSubstitucion=!disabledEditTitle||esSubstitucion
 
    return (
      <div>      

        <Paper className={!isMobile() ? `${classes.withoutRadius} m-3` : `${classes.withoutRadius} m-1`}>
          <ValidatorForm ref="form" onSubmit={async () => { await this.handleSubmit() }}>
            <Grid container spacing={16} className={classNames("my-3", this.props.disable ? classes.disable : "")} >
         
              <Grid item xs={12}>
                <Grid item xs={12} className="d-flex justify-content-between">
                  <Typography variant="display4" gutterBottom className="mx-2 my-1">
                    <Translate id="languages.fichaExpediente.titleFichaExpediente" />
                  </Typography>
                
                  <Button type="submit" color="primary" style={{textAlign:"right",marginRight:5}} disabled={this.state.isUpdate}>
                 
                  </Button>   
                 
               
                </Grid>
                <Divider style={{ height: 3 }} />
              </Grid>
              <Grid item xs={12}>

                {/*<Grid container spacing={16}>*/}
                <Grid container spacing={16}>
                  <Grid item xs={12} className="ml-2 mr-2">
                    <Grid container spacing={24}>
                      <Grid item xs={12} >
                        <TextValidator
                          value={this.state.sourceExpediente.Titulo}
                          label={<Translate id="languages.fichaExpediente.labelExpedienteName" />}
                          className={classes.textField}
                          validators={['required']}
                          disabled={disabledEditTitle}
                          inputProps={{ maxLength: 250 }}
                          errorMessages={[this.props.translate("languages.fichaExpediente.requiredField")]}
                          onChange={this.handleChangeDataExpedient("Titulo")}
                          name="name" />                                                                
                      </Grid>   
                                     
                       
                      <Grid item xs={3} >
                       
                      <TextField
                          value={this.state.sourceExpediente.Expediente_Codigo_Estudio}
                          label={<Translate id="languages.fichaExpediente.labelExpedienteCode" />}
                          className={`${classes.textField} mt-2`}
                          //validators={['required']}
                          //errorMessages={[this.props.translate("languages.fichaExpediente.requiredField")]}
                          onChange={this.handleChangeDataExpedient("Expediente_Codigo_Estudio")}
                          inputProps={{ maxLength: 15 }}
                          InputLabelProps={{ shrink: true }}
                          name="code" />
                           </Grid>  
                    
                           <Grid item xs={3} >                        
                        <TextValidator
                            value={moment(new Date(this.state.sourceExpediente.Fecha_Entrada)).format("DD/MM/YYYY")}
                            label={this.state.sourceExpediente.Expediente_Codigo?<Translate id="languages.fichaExpediente.labelEntryDate" />:<Translate id="languages.fichaExpediente.labelAltaDate" />}
                            className={`${classes.textField} mt-2`}
                            validators={['required']}
                            style={{ width: "94%" }}                            
                            disabled={true}
                            errorMessages={[this.props.translate("languages.fichaExpediente.requiredField")]}
                            onChange={this.handleChangeDataExpedient("Fecha_Entrada")}
                            name="entrada" />                        
                        </Grid>  
                         
                      <Grid item xs={3} >                              
                        <TextField
                          value={this.state.sourceExpediente.Expediente_Codigo}
                          label={<Translate id="languages.fichaExpediente.labelExpedienteCodeCOAG" />}
                          className={`${classes.textField} mt-2`}
                          placeholder=""
                          disabled={true}
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleChangeDataExpedient("Expediente_Codigo")}
                          name="codeCOAG"  />                          
                          </Grid> 
                         
                        <Grid item xs={3} ></Grid>

                        <Grid item xs={3} >   
                        <TextField
                          value={this.state.sourceExpediente.Antecedente}
                          label={<Translate id="languages.fichaExpediente.labelExpedienteAnteced" />}
                          className={`${classes.textField} mt-2`}                         
                          inputProps={{ maxLength: 15 }}
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleChangeDataExpedient("Antecedente")}
                          name="antecedente" 
                          disabled={disabledEditTitle}/>
                          </Grid>

                        {((!disabledEditTitle && this.state.sourceExpediente.Antecedente!='' ) || (disabledEditTitle && this.state.idTipoRelacion>=ID_TIPO_RELACION_CF)) && 
                        <Grid item xs={9} > 
                                <FormControl 
                                                 style={{width: '100%', paddingRight: '3%', paddingLeft: '0%'}}>
                                        <label className='MuiFormLabel-root-214' style={{
                                            marginTop: 9,
                                            marginBottom: -18,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            padding: 0,
                                            fontSize: 8,                                            
                                            fontFamily: 'Montserrat',
                                            lineHeight: 1
                                        }}>TIPO DE ANTECEDENTE</label>
                                        <Select
                                            id='tipoRelacion'
                                            label={'tipoRelacion'}   
                                            style={{   marginTop: 24  }}
                                            onChange={this.handleChangeDataExpedient("Id_Tipo_Relacion")}     
                                            disabled={this.props.sourceExpediente.Substitucion==1 }                     
                                            value={this.state.idTipoRelacion==null && this.props.sourceExpediente.Substitucion==0?-1:this.props.sourceExpediente.Substitucion?ID_TIPO_RELACION_SUBS:this.state.idTipoRelacion}
                                        >
                                             <MenuItem value={-1}  disabled><em>Indica tipo de relación con antecedente</em></MenuItem>
                                             <MenuItem value={ID_TIPO_RELACION_CF}>{'Cambio de formato en expediente propio'}</MenuItem>
                                             <MenuItem value={ID_TIPO_RELACION_SUBS}><Translate id="languages.expedients.relsubsotroArquitecto"/></MenuItem>                                          
                                        </Select>
                                    </FormControl>            
                       </Grid>  }                 
                    
                    </Grid>
                  </Grid>
                
                  {/*<Grid item xs={12} className="p-4">*/}
                  <Grid item xs={12} className="p-3">
             
                    {this.renderUbicationTable()}
                  
                    </Grid>
                   {/* <div style={{textAlign:"right",marginRight:5}}>*/}

                 
                 
                  
                   <Grid item xs={1} className="d-flex justify-content-between align-items-center">
                  </Grid>
                   <Grid item xs={2} className="d-flex justify-content-between align-items-center">                                        
                  
                   {!isMobile() ? 
                   
                  
                    <Button                           
                             onClick={() => this.verObservaciones()}>
                         <Translate id="languages.fichaExpediente.labelObservations" />                                                                        
                     </Button>                                            
                                                            
                     : null                   
                   }   
                    
                    {!isMobile() ?                   
                  
                   <Button color="primary"                            
                      onClick={() => this.verObservaciones()}>
                      {this.state.sourceExpediente.Observaciones == null || this.state.sourceExpediente.Observaciones == ''  ?  <AddCircle/> :<Check/> }    
                      </Button>                                                                                                     
                    : null                   
                  }                     

                  </Grid>

                  
                  <Grid item xs={!isMobile()?3:2} className="p-1">
                  </Grid>

                  <Grid item xs={!isMobile()?2:3}  className="d-flex justify-content-between align-items-center">             
                   <Button type="button"  color="primary"  disabled={!this.state.changingWorkData}
                    onClick={async () => {  this.handleReloadDataExpedientOriginal() }}>
                    Cancelar
                  </Button> 
                  </Grid>
                  
                  <Grid item xs={4}  className="d-flex justify-content-between align-items-center">    
                  <Button type="submit" color="primary" style={{textAlign:"right",marginRight:5}} disabled={this.state.isUpdate || !this.state.changingWorkData} 
                  >
                    <Translate id="languages.generalButton.generalButton" /> <Check />
                  </Button>    
                  </Grid>                           
                   {/*</div>*/}
                  

                  {this.detalles()}
                   
                 
                  
                  {/*
                  <Grid item xs={12} className={`${classes.divGrey} p-3`}> 
                    <Typography variant="subtitle1" gutterBottom className="m-0"
                      style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: 11 }}>
                      <Translate id="languages.fichaExpediente.labelObservations" />
                    </Typography>
                    <TextField id="outlined-bare"
                      className={`${classes.textField} m-0`}
                      defaultValue={this.state.sourceExpediente.Observaciones}
                      onChange={this.handleChangeDataExpedient("Observaciones")}
                      margin="normal" multiline rows="2"
                      variant="outlined" />
                  </Grid>*/}
                 
                 
                 
                </Grid>

              </Grid>
            </Grid>
          </ValidatorForm>


          <Confirmation openConfirmation={this.state.showMessageTramitado}
                    title={'Incidencias' }
                    onlyAccept={true} /*solo se avisa*/
                    buttonVariant={"text"}
                    confirmationMessage={<Translate id="languages.messages.expedienteHaiTrabajosTramitados"/>}                   
                    aceptConfirmation={async () => {                                                    
                        this.setState({showMessageTramitado: false}) 
                    }}
                    />

        <Confirmation openConfirmation={this.state.showMensajeCambioFormatoASubs}
                    title={'Incidencias' }
                    onlyAccept={true} /*solo se avisa*/
                    buttonVariant={"text"}
                    confirmationMessage={<Translate id="languages.messages.expedienteNodeSubstitucion"/>}                     
                    aceptConfirmation={async () => {                                                    
                        this.setState({showMensajeCambioFormatoASubs: false,idTipoRelacion:ID_TIPO_RELACION_CF}) 
                    }}
                    />                    
        </Paper>
        
      </div>
    )
  }
}

FichaExpediente.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  funcionesTipologicas: state.trabajos.funcionesTipologia.data ? state.trabajos.funcionesTipologia.data.Tipos_Trabajos_Tunciones : [],
  catastro: state.expedientes.addressreducida ? state.expedientes.addressreducida : [], /*Contiene arreglo de la tabla de ubicaciones */
  arrayReferencias: state.expedientes.arrayReferencias ? state.expedientes.arrayReferencias : [] /*Contiene arreglo con las referencial catastrales de cada direccion de la tabla ubicacion*/,
  addressData: state.expedientes.address ? state.expedientes.address : ''
})

const mapDispatchToProps = {
  editExpedienteEnTrabajo: dispatchEditExpedienteEnTrabajo,
  fetchErrorExpediente,
  saveAdressTostore: saveAdressTostore,
  elimardelatabla: elimardelatabla,
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(FichaExpediente)));