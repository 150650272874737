import React, {Component} from 'react';
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';
import {withLocalize} from "react-localize-redux";
import {Translate} from "react-localize-redux";
import {Typography, Grid, Paper, Button, TextField, FormControlLabel, Checkbox,CircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import UserIcon from '@material-ui/icons/SupervisedUserCircle';
import Close from '@material-ui/icons/Close';
import {fetchErrorExpediente} from "../../../actions/expedientes";
import ApiCoag from "../../../api/ApiCoag";


const styles = theme => ({
    resultPanel: {
        padding: "20px 30px"
    },
    subtitleData: {
        marginTop: 10,
        fontWeight: "normal"
    },
    usericon: {
        margin: theme.spacing.unit,
        fontSize: 150,
    },
    mt0: {
        marginTop: -10
    },
    button: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    }
});

class FormSociedad extends Component {
    constructor(props) {
        super(props);

        let arquitect = {};
        Object.assign(arquitect, this.props.arquitecto);
        if(!arquitect.Colegiados){
          
        }
        if (!arquitect.Funciones) {
            arquitect["Funciones"] = [];

            //  md corregir para que no falle con sociedades ya que no tienen funciones*/
            if (arquitect.Id_Tipo_Colegiado==1) {
            arquitect["Porciento"] = 100;
            arquitect["acceptTerm1"] = false;
            arquitect["acceptTerm2"] = false;
            }
        }
        arquitect["percentChecked"] = arquitect["PorcentajesEquitativos"] == null ? 1:arquitect["PorcentajesEquitativos"];


        this.state = {
            arquitecto: arquitect,
            colegiados: [],
            loading:false
        }
        if (this.element)
            this.element.scrollIntoView({behavior: 'smooth', block: "start"});
    }

    // UNSAFE_componentWillMount() {
    //     this.initSelectedProperties();
        
    // }

    componentDidMount() {
        
        this.element.scrollIntoView({behavior: 'smooth', block: "start"});
        this.loadSociedadData();
    }

    async initSelectedProperties() {
        let arquitect = {};
        Object.assign(arquitect, this.state.arquitecto);
        if(!arquitect.Colegiados){
          
        }
        if (!arquitect.Funciones) {
            arquitect["Funciones"] = [];

            //  md corregir para que no falle con sociedades ya que no tienen funciones*/
            if (arquitect.Id_Tipo_Colegiado==1) {
            arquitect["Porciento"] = 100;
            arquitect["acceptTerm1"] = false;
            arquitect["acceptTerm2"] = false;
            }
        }
        arquitect["percentChecked"] = arquitect["PorcentajesEquitativos"] == null ? 1:arquitect["PorcentajesEquitativos"];
        this.setState({arquitecto: arquitect});
       
    }

    notifyPropertyChange = (propertyName,pos=false) => event => {
        let newArquitect = {};
        let newColegiados = {};
        Object.assign(newArquitect, this.state.arquitecto);
        Object.assign(newColegiados, this.state.colegiados);
        switch (propertyName) {
            case "Porcentaje":
                newArquitect[propertyName] = event.target.value;
                this.setState({arquitecto: newArquitect});
                break;

            case "Funciones":
                let tag = event.target.tagName === "SPAN" ? event.target.parentNode : event.target;
                let functionCode = event.target.tagName === "SPAN"
                    ? event.target.textContent
                    : event.target.firstChild.textContent;

                    

                //md para que funcione bien también si un miembro  no tiene funciones inicialmente if (!this.state.colegiados[pos].Funciones.some(x => x === functionCode)) {
                if (this.state.colegiados[pos].Funciones != null )    
                {
                    if (!this.state.colegiados[pos].Funciones.some(x => x === functionCode)) {
                    newColegiados[pos].Funciones.push(functionCode);
                    tag.className = tag.className + " slectedFunction";
                    this.setState({colegiados1: newColegiados});
                    } else {
                   
                    newColegiados[pos].Funciones = newColegiados[pos].Funciones.filter(x => x !== functionCode);
                    tag.className = tag.className.replace("slectedFunction", "");
                    this.setState({colegiados1: newColegiados});
                    }   
                } 
                else
                {   newColegiados[pos].Funciones=[];   
                    newColegiados[pos].Funciones.push(functionCode);
                    tag.className = tag.className + " slectedFunction";
                    this.setState({colegiados1: newColegiados});
                }


                    
                   
                
                break;

            default:
                newArquitect[propertyName] = event.target.checked;
                this.setState({arquitecto: newArquitect});
                break;
        }
    }

    async loadSociedadData() {

        //let result =  await  getDatosSociedad(this.props.expediente,this.props.arquitecto.Id_Entidad);

        if(this.props.colegiadosSociedad!=undefined && this.props.colegiadosSociedad){ 

            this.setState({colegiados: this.props.colegiadosSociedad})
        }else{

            this.setState({loading: true})
            let result = await  ApiCoag.expedientes.getDatosSociedad(this.props.expediente,this.props.arquitecto.Id_Colegiado);
            if(result!='ERROR2'){
                for(let i = 0;i<result.Colegiados.length; i++){
                    result.Colegiados[i]['Funciones']=[];
                }
                this.setState({colegiados: result.Colegiados})               

                this.setState({loading:false})               
            }
            else            
            { this.setState({loading:false})              
              this.props.handleCanSearch(false)}

        }


    }
    almostOneArquitectHaveRol(){
      let flag = false;
      this.state.colegiados.map((item, pos) => {
        if (item.Id_Sociedad == this.props.arquitecto.Id_Colegiado) {
            //  md corregir para que no falle con miembros de sociedad que no tienen funciones*/
          if(item.Funciones != null && this.comprobarFunciones(item.Funciones)>0){
            flag=true;
          }
        }
      })
      return flag;
    }

    comprobarFunciones(funcionesCol){  
        let i=0
        this.props.funcionesTipologia.map((valueTiplogia) => {       
            if (funcionesCol.some(x => x === valueTiplogia.Codigo))   
            i+=1
          })
        return i
      }

    render() {
        let value = this.props.arquitecto;
        let {classes} = this.props;
        return (
            <div ref={element => {
                this.element = element;
            }}>
                <Grid item xs={12}>
                    <Paper className={classes.resultPanel}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    <Translate id="languages.agentes.sociedadDataTitle"/>
                                </Typography>
                                <Grid container>
                                   {/* md Nif  no se debe mostrar <Grid item xs={6}>
                                        <Typography variant="body2" className={classes.subtitleData}>BIF</Typography>
                                        <Typography variant="subtitle2" gutterBottom>{value.Nif}</Typography>
        </Grid>*/}
                                    <Grid item xs={12}>
                                        <Typography variant="body2" className={classes.subtitleData}><Translate id="languages.agentes.numeroColegiado" /></Typography>
                                        <Typography variant="subtitle2" gutterBottom>{value.Id_Colegiado}</Typography>
                                    </Grid>
                                </Grid>


                                <Typography variant="body2" className={classes.subtitleData}>
                                    <Translate id="languages.agentes.tableColumnName"/>
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>{value.Nombre_Completo}</Typography>


                            </Grid>

                            {/*<Grid item xs={4}> //por agora non hai foto
                                <UserIcon className={classes.usericon} color="secondary"/>
                            </Grid>*/}


                            <Grid item xs={12}>
                                
                                <Grid container spacing={0}>
                                <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.arquitecto.percentChecked}
                                                    onChange={this.notifyPropertyChange("percentChecked")}
                                                    color="primary"/>
                                            }
                                            label={<Translate id="languages.agentes.percentLabelArquitecto"/>}/>
                                    </Grid>

                                    { !this.state.arquitecto.percentChecked &&
                                     <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <TextField
                                            label={''}
                                            disabled={this.state.arquitecto.percentChecked}
                                            inputProps={{ min: 0,  max:100, step: "1" }}
                                            className={classes.mt0}
                                            value={this.state.arquitecto.Porcentaje}
                                            placeholder=""
                                            type="number"
                                            onChange={this.notifyPropertyChange("Porcentaje")}
                                            margin="normal"/>
                                    </Grid>
                                    </Grid>}
                                </Grid>
                            </Grid>
                            {
                                (this.state.loading ?  
                                <Grid item xs={12} align="center" >
                                     <CircularProgress  />
                                </Grid>   : 
                               
                               this.state.colegiados && this.state.colegiados.map((item, pos) => {
                                    
                                    if (item.Id_Sociedad == this.props.arquitecto.Id_Colegiado) {
                                        return (
                                           
                                            <Grid item xs={12} className="functionTipology" style={{backgroundColor:'#f5f5f5',padding:10,margin:'5px 0px'}}>
                                                <Typography variant="body2"
                                                            className={`${classes.subtitleData} text-uppercase`}>
                                                    <Translate id="languages.agentes.functionsAndData"/> 
                                                </Typography>
                                                <Grid item xs={12}>
                                                    {/*<Typography variant="h6" gutterBottom>
                                                        <Translate id="languages.agentes.arquitectDataTitle"/>
                                                    </Typography>
                                                    // md nif no se debe mostrar, si el número de col}
                                                    <Typography variant="body2"
                                                                className={classes.subtitleData}>NIF</Typography>
                                                                <Typography variant="subtitle2" gutterBottom>{item.Nif}</Typography>
                                                        */}
                                                          <Typography variant="body2"
                                                                className={classes.subtitleData}><Translate id="languages.agentes.numeroColegiado" /></Typography>
                                                        
                                                    <Typography variant="subtitle2" gutterBottom>{item.Id_Colegiado}</Typography>

                                                    <Typography variant="body2" className={classes.subtitleData}>
                                                        <Translate id="languages.agentes.tableColumnName"/>
                                                    </Typography>
                                                    <Typography variant="subtitle2"
                                                                gutterBottom>{item.Nombre_Completo}</Typography>

                                                    {/*<Typography variant="body2"
                                                                className={`${classes.subtitleData} `}>
                                                        <Translate id="languages.agentes.firstName"/>
                                                    </Typography>
                                                    <Typography variant="subtitle2"
                                                                gutterBottom>{item.Apellido1}</Typography>

                                                    <Typography variant="body2"
                                                                className={`${classes.subtitleData} `}>
                                                        <Translate id="languages.agentes.secondName"/>
                                                    </Typography>
                                                    <Typography variant="subtitle2"
                                                    gutterBottom>{item.Apellido2}</Typography>*/}
                                                </Grid>  
                                                <br></br>                                                
                                                <Grid item xs={12}>
                                                    <Typography variant="body2"
                                                                className={`${classes.subtitleData} text-uppercase`}>
                                                        <Translate id="languages.agentes.functionsTitle"/>
                                                    </Typography>

                                                </Grid>
                                                {
                                                    this.props.funcionesTipologia.map((valueTiplogia, indexCode) => {
                                                        return <Button onClick={this.notifyPropertyChange("Funciones",pos).bind(this)}
                                                                       className={this.state.colegiados[pos].Funciones != null && this.state.colegiados[pos].Funciones.some(x => x === valueTiplogia.Codigo) ? "slectedFunction" : ""}                                                                       
                                                                       variant="contained"
                                                                       key={indexCode}>{valueTiplogia.Codigo}
                                                        </Button>
                                                    })
                                                }
                                            </Grid>
                                            
                                        );
                                    
                                    }
                                
                                })
                                )
                            }

                            <Grid item xs={12}>
                                {!this.state.arquitecto.acceptTerm1 || !this.state.arquitecto.acceptTerm2
                                    ? <Typography variant="caption" gutterBottom color="error" className="ml-3 pl-3">
                                        <Translate id="languages.agentes.termsValidation"/> *
                                    </Typography> : ""}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.arquitecto.acceptTerm1}
                                            onChange={this.notifyPropertyChange("acceptTerm1")}
                                            color="primary"/>
                                    }
                                    label={<Translate id="languages.agentes.conditionTermn1"/>}/>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.arquitecto.acceptTerm2}
                                            onChange={this.notifyPropertyChange("acceptTerm2")}
                                            color="primary"/>
                                    }
                                    label={<Translate id="languages.agentes.conditionTermn2"/>}/>
                            </Grid>

                            <Grid item xs={12} className="text-right">
                                <Button color="primary" size="small" className={classes.button}
                                        onClick={() => {
                                            this.props.handleCanSearch(false)
                                        }}>
                                    <Translate id="languages.generalButton.cancel"/><Close
                                    className={classes.rightIcon}/>
                                </Button>
                                <Button variant="contained" size="small" color="primary" className={classes.button}
                                        onClick={() => this.props.addAgenteTrabajoToSelection(this.state.arquitecto,this.state.colegiados)}
                                        disabled={this.state.arquitecto.acceptTerm1 && this.state.arquitecto.acceptTerm2
                                        && this.almostOneArquitectHaveRol()
                                        && (this.state.arquitecto.Porcentaje !== "" || this.state.arquitecto.percentChecked) ? false : true}>
                                    {this.props.existAgentSelected(this.state.arquitecto.Id_Colegiado)
                                        ? <Translate id="languages.generalButton.save"/>
                                        : <Translate id="languages.generalButton.added"/>}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = ()=>{}

const mapDispatchToProps =
    {
        fetchErrorExpediente: fetchErrorExpediente
    };

FormSociedad.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(FormSociedad)));