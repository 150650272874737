import React, { Component } from "react";
//import SvgCarpeta from "./IconosEstados"


import './estados.css';
class EstadoRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estadoName: this.props.value,
      idTrabajo: this.props.data.Id_Trabajo,
      color: '000',
      //icono: './IconosEstados/default.png',
    }
  }

  getCleanedString(cadena) {
    if (cadena == null)
      return '';   
    cadena = cadena.toLowerCase();   
    return cadena;
  }
 

  estado = (nombre) => {
    let nombreLimpio = this.getCleanedString(nombre);
    if (nombreLimpio !== '')
        return (
            <div /*className={nombreLimpio}*/>
                {<img alt={200} src={this.iconUrl(nombreLimpio)} />}
                {nombre}
            </div>);

}

iconUrl = (nombreLimpio) => {
    try {
        return require(`./IconosEstados/${nombreLimpio}.svg`);
    } catch (e) {
      let nombreLimpio='borrador'
      return require(`./IconosEstados/${nombreLimpio}.svg`);
    }
}

 
  render() {
    return (<div className="estados">
      {this.estado(this.props.value)}
      
    </div>
    );
  }
};
export default EstadoRenderer;
