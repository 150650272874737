import React, { Component } from "react";
import { Select,MenuItem } from '@material-ui/core';



class FoldersRenderer extends Component {

    constructor(props) {
        super(props);
        this.state = {
          carpetas: null, //this.props.value        
          carpetaId:(props.data.Id_Documentacion!=''?props.data.Id_Documentacion:0)         
        }
      }
      

handleChange = () => (event) => {  
    if (event.target.value != this.state.carpetaid )
      {this.setState({ carpetaId: event.target.value });
       this.props.cellValueChanged(event.target.value)
       }                           
}


render() {
    let carpetas = this.props.carpetas;   

    return (<div >      
      <Select  value={this.state.carpetaId} style={{ paddingBottom:'1px',fontSize:12,width: '100%',color:this.props.data.Id_Documentacion_Inicial==this.props.data.Id_Documentacion?'black':'orange' }}
               displayEmpty   
               onChange={this.handleChange()             
                     }            
                    
               inputProps={{name: 'name',id: 'id'  }}>
                <MenuItem  style={{ height:8,fontSize:12}}
                           value={0}>{' - '}</MenuItem>
                        {carpetas.map(c=> (
                        <MenuItem   style={{ height:8,fontSize:12}}
                           value={c.Id_Documentacion}>{c.Titulo}</MenuItem>
                                            ))}
      </Select>
    </div>
    );
  }
}

export default FoldersRenderer;



