import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { CircularProgress, Grid } from '@material-ui/core';
import { fetchErrorExpediente } from '../../actions/expedientes/index';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { Typography, Button } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import TiposTrabajo from './tiposTrabajo';
import CrearTrabajo from './crearTrabajo';
import { NavLink } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText ,Tooltip, IconButton} from "@material-ui/core";
import ApiCoag from "../../api/ApiCoag";
import {isMobile} from "../../api/index";
import {formatMenssage} from "../../api/index";
import Info from '@material-ui/icons/InfoRounded'; 
import ReactQuill from "react-quill";

const styles = theme => ({
  titleMainPanel: {
    borderBottom: `2px solid ${grey[100]}`
  },
  withoutRadius: {
    borderRadius: "0 !important"
  }
});

class AsistenteTrabajo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiposTrabajos: true,
      isLoading: true,
      encomenda: null,
      trabajoSeleccion: [],
      dialogOpen: false,
      dialogTitle: "",
      dialogContent: "",
    }
  }

  async componentDidMount() {
    try {
      if (this.props.match.params.id) {
        await this.loadEncomenda(this.props.match.params.id);
      }
      else {
        this.props.fetchErrorExpediente(formatMenssage("Error url"));
        this.setState({ isLoading: false });
      }
      await this.getTipoAclaracion(true);
    }      
    catch (error) {

    }
  }

  async loadEncomenda(idExpediente) {

    let sePuede  = await ApiCoag.expedientes.sePuedeCrearTrabajo(idExpediente);
    if (sePuede && sePuede.MensajesProcesado && sePuede.MensajesProcesado.length > 0) {

      this.props.history.push(`/visualizar-expediente/${idExpediente}`)
    }
    

    let result = await ApiCoag.expedientes.getEncomendaActualParaAsistenteTrabajo(idExpediente);
    if (result=='ERROR2' ) {//&& result.MensajesProcesado && result.MensajesProcesado.length > 0) {

      this.props.history.push(`/visualizar-expediente/${idExpediente}`)
    }
    else {
      this.setState({ encomenda: result });
    }
    this.setState({ isLoading: false });
  }

  handleNavigation(isTrabajos) {
    this.setState({ tiposTrabajos: isTrabajos });
   
      this.getTipoAclaracion(isTrabajos)

  }

  async updateTrabajoSeleccion(trabajos) {
    this.setState({ trabajoSeleccion: trabajos.sort((a, b) => a.Id_Tipo_Fase - b.Id_Tipo_Fase) });
  }

  renderNotify(tipo,mensaje) {
    return (     
      <div>
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" >
            {tipo==0?'Confirmación':<h2 style={{color:'#ed4140',fontSize:'1rem'}}>Incidencias</h2>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography component="h2" variant="display1" gutterBottom style={{fontSize:"1rem"}}>               
              <ReactQuill value={mensaje} readOnly theme='bubble' />
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            
            {/*-1 es encomenda no vigente por baja de miembro no validado, 
               -2 baja de miembro validado participando en solitario o en sociedad
                0 sin encomenda 
                1 modificacion*/
            (tipo == -1 || tipo==0) &&  
          <Button   className="mx-2" color="primary"
              onClick={() => {
                this.props.history.push("/comunicacion/" + this.props.match.params.id);
              }}>
              <Translate id="languages.generalButton.addEncomenda" />
            </Button>}
           
            <Button   className="mx-2" color="primary"
              onClick={() => {
                this.props.history.push("/visualizar-expediente/" + this.props.match.params.id);
              }}>
              {tipo!=-2?<Translate id="languages.generalButton.backExpedient" />:<Translate id="languages.generalButton.aceptar" />}
            </Button>
           
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  handleShowDialog(title, content) {     
      this.setState({dialogOpen: true, dialogTitle: title, dialogContent: content});
  }

  handleCloseDialog = () => {
     this.setState({ dialogOpen: false });
  };

  async getTipoAclaracion(isTrabajos) {
    let response=null;
    if (isTrabajos)
    { response = await ApiCoag.tipos.guia.tipoAclaracion(1)}
    else
    { response = await ApiCoag.tipos.guia.tipoAclaracion(2)}

    if (response) {           
        this.setState({ aclaraciones: response.Aclaraciones[0].Nombre });
    }  
   
  }

  showExpCod(){
      return this.state.encomenda.Expediente[0].Expediente_Codigo!=null?this.state.encomenda.Expediente[0].Expediente_Codigo:this.state.encomenda.Expediente[0].Expediente_Codigo_Estudio
  } 

  

  render() {
    let { classes } = this.props;
    return (
      this.state.isLoading ?
        <Grid item xs={12} className="text-center py-3">
          <CircularProgress />
        </Grid>
        : this.state.encomenda.EncomendaActual && this.state.encomenda.EncomendaActual.length > 0 && this.state.encomenda.Expediente[0].SePuede_CrearTrabajo==1
          ? <Container className="my-4 px-4">
            <BreadcrumbsItem to={'/crear-trabajo/' + this.props.match.params.id} >
             
              
              <NavLink to={'/visualizar-expediente/' + this.props.match.params.id}>
                {/*<Translate id="languages.crearTrabajo.panelTitle" />*/}
                {` ${this.showExpCod()+ ' - '}`}
              </NavLink>              
              <Translate id="languages.trabajo.nuevoTrabajo" />
            </BreadcrumbsItem>
            <ExpansionPanel expanded={true} className={classes.withoutRadius}>
              <ExpansionPanelSummary className={classes.titleMainPanel}
                style={{ minHeight: 48, height: 48 }}>
                  {this.state.tiposTrabajos ?                
                <Typography variant="title" gutterBottom className="mb-0">
                  <Translate id="languages.trabajo.trabajoTramitarTitle" />
                </Typography>
              
                :
             
                <Typography variant="title"  className="mb-0">
                <Translate id="languages.trabajo.trabajoConfiguracionTitle" />
              </Typography>                                     
                  }

               {this.state.aclaraciones!=null && <Tooltip title={!isMobile()?this.props.translate("languages.generalButton.aclaraciones"):''}>
                <IconButton   color="primary" aria-label="Info"  className="mb-0 mr-0" style={{paddingRight:10 ,position: "absolute", right: "0.5em", top: 0}} >
                <Info  onClick={()=>{this.handleShowDialog("Aclaracións",this.state.aclaraciones)}}></Info>
                </IconButton>
                </Tooltip>}
                 
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
              <Grid item xs={12}>
                {this.state.tiposTrabajos ?
                  <TiposTrabajo encomenda={this.state.encomenda} previusSelection={this.state.trabajoSeleccion}
                    handleNavigation={isTrabajos => this.handleNavigation(isTrabajos)}
                    updateTrabajoSeleccion={seleccion => this.updateTrabajoSeleccion(seleccion)} key={1} />

                  : <CrearTrabajo handleNavigation={isTrabajos => this.handleNavigation(isTrabajos)} encomenda={this.state.encomenda}
                    trabajos={this.state.trabajoSeleccion} />}

                {
                  
                  <Dialog
                      open={this.state.dialogOpen}
                      onClose={this.handleCloseDialog}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                  >
                      <DialogTitle id="alert-dialog-title">
                          {this.state.dialogTitle}
                          </DialogTitle>
                      <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                              <ReactQuill value={this.state.dialogContent} readOnly theme='bubble' />
                          </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
                              <Translate id="languages.generalButton.aceptar"/>
                          </Button>
                      </DialogActions>
                  </Dialog>
             
                }    
                 </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Container>
          :  this.renderNotify(this.state.encomenda.Expediente[0].SePuede_CrearTrabajo,this.state.encomenda.Expediente[0].Mensaje_CrearTrabajo)              
         
    )
  }

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  fetchErrorExpediente
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(AsistenteTrabajo)));