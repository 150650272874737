import React, { Component } from 'react';

import TipoExpediente from './tipoExpediente';
import ApiCoag from "../../../../api/ApiCoag";
import ComunicacionVisado from "../ProyectoEjecucion/ComunicacionVisado";
import Incidencias from "../ProyectoEjecucion/Incidencias";
import {
  Grid,
  CircularProgress,
  Typography,
  ExpansionPanel, 
  ExpansionPanelSummary,
  ExpansionPanelDetails,   
  withStyles
 
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment'
import * as actionsExpedientes from '../../../../actions/expedientes';


const mapDispatchToProps =
{
    fetchErrorExpediente: actionsExpedientes.fetchErrorExpediente    
    
};

const mapStateToProps = (state) => ({
})

const styles = theme => ({
   
    formControl: {
        width: "100%"
    },
  
    disabledContainer: {
        pointerEvents: "none",
        opacity: 0.7
    },
    backgroundColor: {
        backgroundColor: "#f5f5f5"
    },
});




class TrabajoComunicacion extends Component {


    constructor(props) {
        super(props);
        this.state = {
            fetching: true ,
            fichaTrabajoOpen:false
        }
    }


  //async UNSAFE_componentWillMount() {
    async componentDidMount() {

    //'Nota-este -1 es para cuando queremos refrescar pantalla trabajo tras presentar o auto-retener- enviamos primeri id_trabajo=-1 para vaciar datos y después el que corresponda

    if ((this.props.trabajo && this.props.trabajo>0) || this.props.trabajo == -1) 
    {
   await this.loadGeneralInformation();
    }
 
  }

  
  

  async loadGeneralInformation(withoutLoading=false) {
    if(!withoutLoading){
      //  await this.setState({ fetching: true })
    }

    let expediente = this.props.expediente.Expediente[0];

  
        try {
            let workDetails = await ApiCoag.expedientes.trabajo.getDatosGenerales(expediente.Id_Expediente, this.props.trabajo);
            
            if (workDetails)    {
                if  (workDetails != 'ERROR2') {
                    await this.setState({
                    expediente,                
                    workDetails,
                    fetching: false                                
                           })
                }
                else
                {
                await this.setState({                        
                    workDetails:null,
                    fetching: false                                
                           })
                    
                }
            }


        } catch (e) {
            this.props.fetchErrorExpediente(e);
        }
  

    if(!withoutLoading){
        await this.setState({ fetching: false })
    }
}

getCleanedString(cadena) {
    if (cadena == null)
      return '';
    
    cadena = cadena.toLowerCase();
   
    return cadena;
  }

estadoColor = (nombre) => {
  let nombreLimpio = this.getCleanedString(nombre);
  if (nombreLimpio !== '')
      return (
          <div className={nombreLimpio}>
              {/*<img alt={200} src={this.iconUrl(nombreLimpio)} />*/}
              {nombre}
          </div>);
}


  renderComunicacionVisado(){
    let work = (this.state.workDetails && this.state.workDetails.Trabajos && this.state.workDetails.Trabajos.length) > 0
        ? this.state.workDetails.Trabajos[0] : {};
    return (<ComunicacionVisado work={work}/>)
}

renderIncidencias(){
    let work = this.state.workDetails && this.state.workDetails.Trabajos && this.state.workDetails.Trabajos.length > 0
        ? this.state.workDetails.Trabajos[0] : {};
    return (<Incidencias work={work}/>)
}



renderFichaTrabajo() {
  let { classes } = this.props;
 
 
  return (this.state.workDetails && this.state.workDetails.Trabajos
      && this.state.workDetails.Trabajos.length > 0)
      && <ExpansionPanel expanded={!this.state.fichaTrabajoOpen}
          onChange={() => this.setState({ fichaTrabajoOpen: !this.state.fichaTrabajoOpen })}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                  <Typography variant='button'>
                     {'Ficha'/* <Translate id="languages.fileUpload.formWork" />*/}
                  </Typography>

              </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ padding: 0 }}
              /*className={`${work.SePuede_Editar !== 1 ? classes.disabledContainer : ""}`}*/>
              <Grid container spacing={0}>          
                                             
                  <Grid item xs={12} className={classes.backgroundColor}>
                      <Grid container spacing={0} className="p-4">
                      <Grid item xs={4}>
                              <Typography variant="subtitle1" gutterBottom className="m-0 text-uppercase"
                                  style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: "0.7rem" }}>
                                  <Translate id="languages.fileUpload.state" />
                              </Typography>
                              <div className='estados'>
                                  {this.estadoColor(this.state.workDetails.Trabajos[0].Estado ? this.state.workDetails.Trabajos[0].Estado : "")}
                              </div>
                          </Grid>
                          
                      <Grid item xs={4}>
                              <Typography variant="subtitle1" gutterBottom className="m-0 text-uppercase"
                                          style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: "0.7rem" }}>                                  
                                  {this.state.workDetails.Trabajos[0].Presentado==1?<Translate id="languages.fileUpload.entryDate" />:<Translate id="languages.fichaExpediente.labelAltaDate" />}
                              </Typography>
                              <Typography variant="subtitle2" gutterBottom>                              
                                  {this.state.workDetails.Trabajos[0].Fecha_Entrada ? moment(new Date(this.state.workDetails.Trabajos[0].Fecha_Entrada)).format("DD/MM/YYYY") : ""}
                              </Typography>
                          </Grid>
                      <Grid item xs={3}>
                              <Typography variant="subtitle1" gutterBottom className="m-0 text-uppercase"
                                  style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: "0.7rem" }}>
                                  {this.state.workDetails.Trabajos[0].Presentado==1?<Translate id="languages.fileUpload.tramitacionDate" />:''}
                              </Typography>
                              <Typography variant="subtitle2" gutterBottom>
                                  {this.state.workDetails.Trabajos[0].Fecha_Tramitacion ? moment(new Date(this.state.workDetails.Trabajos[0].Fecha_Tramitacion)).format("DD/MM/YYYY") : ""}
                              </Typography>
                          </Grid>                                                 
                    
                      </Grid>
  
                  </Grid>                              
                              
                 
                  <Grid item xs={12}>               
                 
                       
                           
                   
                  </Grid>
              </Grid>
          </ExpansionPanelDetails>
      </ExpansionPanel>
}


  render() {

    return (
      <Grid container spacing={0}>
       {/* <Grid item md={6} xs={12} >
         } <FichaExpediente disable={true} expediente={this.props.expediente} 
            sourceExpediente={this.props.expediente.Expediente.length > 0 ? this.props.expediente.Expediente[0] : {}}
          />
    </Grid>*/}
        <Grid item md={6} xs={12} >
            <TipoExpediente expediente={this.props.expediente} 
            sourceExpediente={(this.props.expediente.Expediente && this.props.expediente.Expediente.length > 0) ? this.props.expediente.Expediente[0] : {}}
            trabajo={this.props.trabajo}           
           />
        </Grid>

       {this.props.trabajo && <Grid item md={6} xs={12} className={"p-3"}>

                        { (this.state.workDetails && this.state.workDetails!='ERROR2' && !this.state.fetching) && 
                                this.renderFichaTrabajo()
                                /*: <div className="text-center">
                                { this.state.workDetails!='ERROR2' ? <CircularProgress/> : null}
                                  </div> */
                        }
                        { this.state.workDetails && this.state.workDetails!='ERROR2'  && this.state.workDetails.Trabajos[0].Id_Estado <3 /*&& this.state.workDetails.Trabajos[0].SePuede_Editar == 1*/?
                                this.renderComunicacionVisado()
                                : null
                        }

                        { this.state.workDetails && this.state.workDetails!='ERROR2' && this.state.workDetails.Trabajos[0].Id_Estado!=0 && this.state.workDetails.Trabajos[0].Id_Estado!=11 ? 
                                    this.renderIncidencias():
                                    null      
                    }            
                      </Grid>}
      </Grid>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(TrabajoComunicacion)));
//export default TrabajoComunicacion;