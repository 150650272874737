import React, { useState } from "react";
import { Translate } from "react-localize-redux";
import ApiCoag from "../../api/ApiCoag";
import { URL_CATASTRO} from "../../api/config";
import {iconCastastro} from "../../api/config";
import {enlaceCastastro} from "../../api/index";
import { LocationOn } from '@material-ui/icons';

import {
  Button,
  Tooltip,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import moment from "moment";
import {iconUbicacion} from "../../api/config";
import {imgCatastro} from "../../api/index";



const locationIcon = new L.Icon({
  iconUrl: require('../Address/Iconos/'+iconUbicacion),
  iconSize: [24, 24],
  iconAnchor: [16, 24],
});



/* Define el icono personalizado utilizando el icono de LocationOn
const locationIcon0 = L.divIcon({
  className: 'location-icon',
  html: '<svg width="24" height="24" viewBox="0 0 24 24" fill="#1976d2">' +
  '<path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>' +
  '</svg>',
  iconSize: [24, 24], // Tamaño del icono
});*/

const MapaBusqueda = ({
  zoom,
  height,
  localizaciones,
  datosExp,
  habilitarVerTrabajos ,
  onClickExpediente,
  onClickTrabajo  
}) => {
  const [mostrandoTrabajos, setMostrandoTrabajos] = useState(false);
  const [trabajos, setTrabajos] = useState(null);
  const [idExpediente, setIdExpediente] = useState(-1);

  const formatTexto = (texto) => {
    if (texto.length > 40) return texto.substring(0, 40).toUpperCase() + "...";
    else return texto.toUpperCase();
  };

  


  const handleMarkerClick = () => {   
    setTrabajos(null)
    setIdExpediente(-1);
  };

  
  const handleClickVerTrabajos = async (idExpedienteActual) => {
    if (trabajos === null  && idExpedienteActual!=idExpediente ) {
      let response = await ApiCoag.expedientes.getTrabajosExpediente(idExpedienteActual);

      if (response) {
        setTrabajos(response.Trabajos);
        setIdExpediente(idExpedienteActual);       
        setMostrandoTrabajos(true);
      }
    }
  };

  const getCleanedString = (cadena) => {
    if (cadena == null) return "";
    cadena = cadena.toLowerCase();
    return cadena;
  };

  const renderEstado = (nombre) => {
    let nombreLimpio = getCleanedString(nombre);
    if (nombreLimpio !== "")
      return (
        <div style={{ fontSize: "10px" }}>
          {<img style={{ marginTop: "-2px" }} alt={200} src={iconUrl(nombreLimpio)} />}
        </div>
      );
  };

  const renderTitulo = (row) => {
    return (
      <div style={{ fontSize: "10px" }}>
        <a
          style={{ cursor: "pointer", color: row.Incidencias && row.SePuede_Editar > 0 ? "#dc3545" : "#007bff" }}
          onClick={() => onClickTrabajo(row.Id_Expediente, row.Id_Trabajo)}
        >
          {row.Id_Trabajo + "-" + row.Titulo}
        </a>
        {row.Fecha_Tramitacion ? " ( " + moment(row.Fecha_Tramitacion).format("DD/MM/YY") + " ) " : row.Fecha_Entrada ? " ( " + moment(row.Fecha_Entrada).format("DD/MM/YY") + " ) " : ""}
      </div>
    );
  };

  const iconUrl = (nombreLimpio) => {
    try {
      return require(`../Tramitaciones/IconosEstados/${nombreLimpio}.svg`);
    } catch (e) {
      let nombreLimpio = "borrador";
      return require(`../Tramitaciones/IconosEstados/${nombreLimpio}.svg`);
    }
  };

  const renderTrabajos = () => {
    return (
      <div style={{ maxWidth: "320px", maxHeight: "212px", overflow: "auto" }}>
        <Table>
          <TableBody style={{ minHeight: 80, maxWidth: "400px" }}>
            {trabajos.length === 0 ? null : trabajos.map((row, index) => (
              <TableRow style={{ fontSize: "10px", height: "35px" }} key={index}>
                <TableCell className="p-0">{renderEstado(row.Estado ? row.Estado.replace("Pendente", "Pen.").replace("Pendiente", "Pen.") : "")}</TableCell>
                <TableCell>
                  <div className="estados">{renderTitulo(row)}</div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <div>
      <Paper elevation={3} style={{ padding: "0px", margin: "0px auto", maxWidth: "100%" }}>
        <MapContainer center={[42.8, -8.05]} zoom={zoom} style={{ height: height, width: "100%" }}>
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
          {localizaciones.map((location, index) => (
            <Marker key={index} position={location} icon={locationIcon} eventHandlers={{ click: handleMarkerClick }}>
              <Popup key={index}>
                <div style={{ width: "400px" }}>
                  <Tooltip title={"Ir a expediente"}>
                    <a style={{ cursor: "pointer", color: "#007bff" }} onClick={() => onClickExpediente(datosExp[index][0])}>
                      {datosExp[index][1]}
                    </a>
                  </Tooltip>{" "}
                  &nbsp;
                  {datosExp[index][6] != null && datosExp[index][6] !== "" ? <Tooltip title={"Ir a catastro"}>{imgCatastro(datosExp[index][6],18)}</Tooltip> :""}
                  <br></br>
                  <br></br>
                  {formatTexto(datosExp[index][2])}
                  <br></br>
                  {formatTexto(datosExp[index][3])}
                  <br></br>
                  {formatTexto(datosExp[index][4])}
                  <br></br>
                  {formatTexto(datosExp[index][5])}
                  <br></br>
                  <br></br>
                  {habilitarVerTrabajos ? (
                    idExpediente !== datosExp[index][0] ? (
                      <Button
                        key={index}
                        color="primary"
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                        onClick={() => {                         
                          handleClickVerTrabajos(datosExp[index][0]);
                          setMostrandoTrabajos(true);
                        }}
                      >
                        Ver <Translate id="languages.generalText.trabajos" />
                      </Button>
                    ) : (
                      <Typography variant="subtitle1" style={{ color: "#007bff" }}>
                        <Translate id="languages.generalText.trabajos" />
                      </Typography>
                    )
                  ) : null}
                  {trabajos != null && datosExp[index][0] === idExpediente && mostrandoTrabajos && renderTrabajos()}
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </Paper>
    </div>
  );
};

export default MapaBusqueda;