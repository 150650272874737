import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import { Grid,  FormControlLabel, Checkbox, Button } from '@material-ui/core';

import { fetchErrorExpediente} from "../../../actions/expedientes";
import { connect } from "react-redux";
import { grey } from '@material-ui/core/colors';
import Close from '@material-ui/icons/Close';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Confirmation from "../../Errors/confirmation";
import ApiCoag from "../../../api/ApiCoag";
import Contacto, { tipoEntidadOrganismo, tipoEntidadPersona } from "../contacto"
import {nifCifValido,letra} from "../contacto"
import {ID_PAIS_OTRO,ID_REG_OTRA,ID_PROV_OTRA,ID_CONCELLO_OTRO} from "../../../api/config";


const styles = theme => ({
    formControl: {
        width: "100%"
    },  
    formControl100: {
        width: "100%"
    },
    formControlNif: {
        width: "45%"
    },    
    label: {
        textTransform: "uppercase"
    },
    formControl2: {
        width: "90%",
        marginTop: 16,
        marginBottom: 8
    },
    formControl3: {
        width: "95%",
        marginTop: 30,
        marginBottom: 8
    },
    divGrey: {
        backgroundColor: grey[100],
        padding: 16,
        borderBottom: "1px solid #eee",
        borderTop: "1px solid #eee"
    },
    button: {
        margin: theme.spacing.unit,
    },
    readOnly: {
        pointerEvents: "none",
        opacity: 0.5
    } 
})


const mapStateToProps = (state) => (
    {
        errorExpediente: state.expedientes.error && state.expedientes.error.MensajesProcesado ? state.expedientes.error.MensajesProcesado : [],
        selectedPromoters: state.expedientes.promotores,
        state: state.expedientes
    }
);

const mapDispatchToProps = (dispatch) => {
    return {
        onErrorExpediente: (value) => dispatch(fetchErrorExpediente(value)),
    }
}
 /*aaui*/
class AddPersonOrg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promotor: this.props.promotor ? this.props.promotor : {
                "Id_Entidad": -1,
                "Nif": "",
                "Id_Tipo_Entidad": this.props.tipo==0?1:2,
                "Nombre": "",
                "Apellido1": "",
                "Apellido2": "",
                "Observaciones": "",
                "Mail": "",
                "Telefono": "",
                "Calle": "",
                "Numero": "",
                "Piso": "",
                "Codigo_Postal": "",
                "Porcentaje": null,
                "PorcentajesEquitativos": 1,
                "Id_Concello": "",
                "Id_Provincia": "",
                "Id_Autonomia": 71,
                "Id_Pais": 100,
                "Nif_Representado": ""
            },            
            tiposPromotor: [],
            domicilio: [],
            paises: [],
            regiones: [],
            provincias: [],
            municipios: [],
            checkedRepresentado: false,
            selectedRepresentados: [],
            checkedLey: this.props.isModification,
            //expanded: false || this.props.soloConsulta || !this.props.isModification,
            flag: this.props.promotor,
            tipo:this.props.tipo,
            nifInicial: this.props.promotor ? this.props.promotor['Nif']:null,
            domicilioExt:this.props.promotor ? (this.props.promotor['Id_Pais'] == 999 ?true:false) :false
        };

    }

    //  async UNSAFE_componentWillMount() {
    //      let language = this.props.activeLanguage.code ? this.props.activeLanguage.code : null;
    //      try {
    //          if (language) {
    //             let tiposPromotor = await ApiCoag.tipos.tiposPromotores();
  
    //              this.setState({
    //                  tiposPromotor: tiposPromotor.Tipos_Promotores ? tiposPromotor.Tipos_Promotores : [],                   
    //              })
    //          }           
            
    //      } catch (error) {
    //         // this.props.onErrorExpediente(formatMenssage(error.message))
    //      }

    //  }  
 
 

   
    componentDidMount() {
        if (this.element)
            this.element.scrollIntoView({ behavior: 'smooth', block: "start" });

            ValidatorForm.addValidationRule('isNif', (value) => {               
               return nifCifValido(value)
            });           
    }

  
   

    handleChange = (name, checkedProperty = false) => event => {
        let promotor = {};
        Object.assign(promotor, this.state.promotor);
        if (!checkedProperty)
            promotor[name] = event.target.value;
        else {
            promotor[name] = event.target.checked ? 1 : 0;
        }
        promotor['Id_Tipo_Entidad']=this.props.tipo==0?1:2
        this.setState({ promotor: promotor })
    };


    handleChangeContacto (promotorContacto,nameCampoContacto) {
        let promotor = {};
        Object.assign(promotor, this.state.promotor);
       
      
        promotor[nameCampoContacto] = promotorContacto[nameCampoContacto] ;                 
   
        promotor['Id_Tipo_Entidad']=this.props.tipo==0?1:2
        
        if (nameCampoContacto=="Id_Pais" || nameCampoContacto=="Id_Concello" || nameCampoContacto=="Id_Autonomia" || nameCampoContacto=="Id_Provincia" )   {
            promotor['Id_Pais'] =promotorContacto['Id_Pais'];
            promotor['Id_Autonomia'] =promotorContacto['Id_Autonomia'];
            promotor['Id_Provincia'] = promotorContacto['Id_Provincia'];       
            promotor['Id_Concello'] = promotorContacto['Id_Concello'];      
        }

       this.setState({ promotor: promotor })
    };

    


    handlePorcentageChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };


   

    handleSubmit() {
      
        let nif=this.state.promotor['Nif']
        let tipoNif=nifCifValido(nif)
        let per=this.props.tipo==0?true:false
        let letraOK=0;
        if (tipoNif==1 && letra(nif,1)=='OK'){ 
            letraOK=1;
        }
        if (this.state.promotor['Apellido2']==null)
            this.state.promotor['Apellido2']=''

        if ((per && tipoNif==1 && letraOK==1) || (per && tipoNif==2) || (!per && tipoNif>2) ){                       
            this.props.onAddEditPersonOrg(this.state.promotor,this.state.nifInicial)
        }
        else
        if (per && tipoNif==1 && letraOK!=1){            
            this.setState({ openMesLetra:true});             
        }
        else{
            this.setState({ openMes:true});             
        }    
    }



 

 

    render() {
        let { classes } = this.props;
      
        return (
            <div ref={element => { this.element = element; }}>
                <ValidatorForm
                    ref="form"
                    onSubmit={() => { this.handleSubmit() }}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} >

                            {/* md el color no debe ser gris
                            <div className={`${classes.divGrey} ${this.props.readOnly && classes.readOnly}`}>*/}          

                                                                    
                                                       
                            {/**Contactos */}                            
                            <Contacto agente={this.state.promotor} tipoEntidad={this.props.tipo==0?tipoEntidadPersona:tipoEntidadOrganismo} 
                            tipoAgente='promotor'
                            datosContactoEnGrupo={!this.props.modoConsulta}      
                            datosContactoEnGrupoExpandidos={this.state.promotor.Id_Entidad===-1 || this.props.modoConsulta }                             
                            modoConsulta={this.props.modoConsulta}                            
                            modoConsultaConEdicionDatosContacto={false}                          
                            onAddEditContacto={(agente,namecampo) => this.handleChangeContacto(agente,namecampo)} />
                                
                            {/* md por ahora sin representante                                                       
                            <div className={`p-3 ${this.props.readOnly && classes.readOnly}`}>
                                <Translate>
                                    {({ translate }) => <FormControlLabel className="ml-0 mr-0"
                                        control={
                                            <Checkbox
                                                checked={this.state.checkedRepresentado}
                                                onChange={this.handlePorcentageChange('checkedRepresentado')}
                                                value="checkedPercentage"
                                                color="primary"
                                            />
                                        }
                                        label={translate("languages.agentes.titleRepresentadoA")}
                                    />}
                                </Translate>
                                <FormControl className={classes.formControl}>
                                    <Translate>
                                        {({ translate }) => <Select
                                            value={this.state.promotor.Nif_Representado ? this.state.promotor.Nif_Representado : ""}
                                            onChange={this.handleChange('Nif_Representado')}
                                            inputProps={{
                                                name: 'representado',
                                                id: 'representado',
                                            }}
                                            displayEmpty>
                                            <MenuItem value="" disabled>
                                                {translate("languages.agentes.selecciona") + " " +
                                                    translate("languages.agentes.promotor")}
                                            </MenuItem>
                                            {
                                                this.props.selectedPromoters ? this.props.selectedPromoters.map(value => {
                                                    return <MenuItem key={value.Nif} value={value.Nif}>{value.Nombre}</MenuItem>
                                                })
                                                    : ""
                                            }
                                        </Select>}
                                    </Translate>
                                </FormControl>                                
                            </div>
                                        */}
                            {/*<div className={`${classes.divGrey} ${this.props.readOnly && classes.readOnly}`}>*/}
                            {!this.props.modoConsulta && 
                            <div className={`${classes.divGrey} ${this.props.isModification}`}>                            
                                <Translate>
                                    {({ translate }) => <FormControlLabel className="ml-0 mr-0 align-items-start text-justify"
                                        control={
                                            <Checkbox
                                                checked={this.state.checkedLey}
                                                onChange={this.handlePorcentageChange('checkedLey')}
                                                value="checkedLey"
                                                color="primary"
                                            />
                                        }
                                        label={translate("languages.agentes.textLeyOrganica")}
                                    />}
                                </Translate>
                            </div>}
                            {!this.props.modoConsulta && 
                            <div className="p-3 text-right">
                                <Button color="primary" size="small" className={classes.button} onClick={() => { this.props.onCancelPromotor() }}>
                                    <Translate id="languages.generalButton.cancel" />
                                    <Close className={classes.rightIcon} />
                                </Button>
                                <Button type="submit" variant="contained" size="small" color="primary" className={classes.button}
                                    disabled={!this.state.checkedLey}>
                                    {//this.props.readOnly ?
                                    this.props.isModification ?
                                        <Translate id="languages.generalButton.editSave" />
                                        : <Translate id="languages.generalButton.addedSave" />
                                    }
                                </Button>
                            </div>}

                        </Grid>

                    </Grid>
                </ValidatorForm>

                <Confirmation openConfirmation={this.state.openMes}     
                    title={<Translate id="languages.generalText.observaciones"/> }                           
                    confirmationMessage={ this.props.tipo==0?<Translate id="languages.agentes.nifdeEntidad"/>:<Translate id="languages.agentes.nifdePersona"/>}
                    aceptConfirmation={async () => {
                        this.setState({openMes: false})
                        this.props.changePersonOrg();                       
                    }}
                    onlyAccept={true}
                    declineConfirmation={() => this.setState({openMes: false})}/>      
                 
                 <Confirmation openConfirmation={this.state.openMesLetra}     
                    title={<Translate id="languages.generalText.observaciones"/> }                           
                    confirmationMessage={<Translate id="languages.agentes.letraNifError"/>}
                    aceptConfirmation={async () => {
                        this.setState({openMesLetra: false})
                        this.props.onAddEditPersonOrg(this.state.promotor,this.state.nifInicial)          
                    }}                    
                    declineConfirmation={() => this.setState({openMesLetra: false})}/>        
                  

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(AddPersonOrg)));