import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { Button, IconButton, Grid,Tooltip } from '@material-ui/core';
import { Container, Row, Col } from 'reactstrap';
import coag from './images/coag.jpg';
import './MenuAppBar.css';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { Translate } from "react-localize-redux";
import { Add,  Search } from "@material-ui/icons";
import MenuUser from "../Menus/user/index";
import MenuUserHelp from "../Menus/user/help";
import MenuLanguage from "../Menus/language";
import { purgarStore, goHome, goExpedientesUser, dispatchSetActiveLanguage } from './../../actions/usuarios/index';
import { fetchMuestraModal, fetchCambiaStatoModalBuscador, fetchCambiaStadoModal,fetchocultaModal, fetchCambiaStadoModalFalse  } from './../../actions/interfaz/index';
import { fetchIdAccion } from './../../actions/usuarios/index'

import { CSSTransitionGroup } from 'react-transition-group'
import Modalacciones from '../../components/Home/Modalacciones';



//import Help from '@material-ui/icons/Help'; 

import {isMobile} from "../../api/index";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flex: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    button: {
        margin: theme.spacing.unit / 2,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    paper: {
        marginRight: theme.spacing.unit * 2,
    },
    col: {
        alignSelf: "center"
    },
    menuUser: {
        display: "flex",
        textAlign: "right",
        [theme.breakpoints.down('sm')]: {
            display: "inline",
        },
    }
   
});

class MenuAppBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: true,
            open: false,
        };

    }

    handleHome = () => {
        this.props.goHome();
        this.props.history.push('/');
       
    }

    handleMisexpe = () => {
        this.props.goExpedientesUser();
        this.props.history.push('/');
    }


    handleBuscador() {
        this.props.fetchIdAccion(1); //Nota:el buscador general es como la primera acción. Importante actualizar esto para saber que salimos de Renuncias
        this.props.fetchMuestraModal();
        this.props.fetchCambiaStatoModalBuscador();
        this.props.fetchCambiaStadoModal();
    }

    handleCatalogo() {
        this.props.history.push(`/comunicacion/0/1`)       
    }

    handleNav(uri) {
        this.props.fetchocultaModal();
        this.props.fetchCambiaStadoModalFalse();
        this.props.history.push(uri);
       
    }
    activeButton(){
        let  route =this.props.location.pathname.split('/');
        if(route.length>2){
            if(route[1]=="visualizar-expediente"||route[1]=="tramitacion-en-lote" ){
                return route[2];
            }else return false;

        }else{
           return false
        }
    }

       


    dragOver = (permitido=false) => (event) => {
         
        event.preventDefault();
        if (permitido)
            {event.dataTransfer.dropEffect = "copy"}               
        else
            {event.dataTransfer.dropEffect = "none"}                        
        }

  

    render() {
        let { classes } = this.props;


        return (
            <Container className="full">
                <Grid container spacing={16}  onDragOver={this.dragOver(false)}>
                    <Grid item xs={9} sm={9} md={4} lg={3} className={classes.col}>
                        <div><a  target="_blank" href="http://portal.coag.es"><img src={coag} alt="logo" height="50" className="logo-coag"/></a></div>
                    </Grid>
                    <Grid item xs={3} sm={3} md={2} lg={2} className={classes.col}>
                        <Button color="secondary" className={classes.button} onClick={() => { this.handleNav("/") }}>
                            <Translate id="languages.header.titleHome" />
                        </Button>
                       

                    </Grid>
                    <Grid item xs={8} sm={9} md={4} lg={5} className={classes.col}>
                        <div className="botonesBarra">
                            <Button variant="contained" color="primary" className={classes.button} onClick={() => { this.props.history.push("/nuevo-expediente") }}>
                                <Translate id="languages.header.btnNewExpedient" />
                                <Add className={classes.rightIcon} />

                            </Button>
                            {/* md por ahora no está disponible
                            <Button disabled={!this.activeButton()} onClick={() => { this.props.history.push("/tramitacion-en-lote/"+this.activeButton()) }} variant="outlined" color="secondary" className={classes.button}>
                                <Translate id="languages.header.btnTramitacionLote" />
                                <Settings className={classes.rightIcon} />
                            </Button>
                            */}
                              <Tooltip title={  !isMobile() ? 'Búsqueda de expedientes':''} >
                            <IconButton color="secondary" className={classes.button}
                                onClick={() => { this.handleBuscador() }}>
                                <Search />
                            </IconButton>
                            </Tooltip>

                        
                        </div>

                    </Grid>
                    <Grid item xs={3} sm={3} md={2} lg={2} className={classes.col} >
                  
                        <div className={classes.menuUser}>
                            { !isMobile() ? 
                        <Button color="secondary"  className={classes.button}
                                onClick={() => { this.handleCatalogo() }}>
                                     Catálogo&nbsp;de  <Translate id="languages.generalText.trabajos" />
                                                                   </Button>
                            :                          
                             <Button align="RIGHT" color="secondary" className={classes.button}
                            onClick={() => { this.handleCatalogo() }}>
                                   CATÁLOGO
                            </Button>
                                /*<IconButton color="secondary" className={classes.button}
                                onClick={() =>{ this.handleCatalogo() }}>                               
                                 <Settings/> 
                            </IconButton>*/
                            }
         
                            {/* <div>
                           <Tooltip id="t1" title={ !isMobile() ?'Manual':''}>
                                                                        <IconButton  
                                                                        color="secondary"
                                                                        buttonRef={node => {
                                                                            this.anchorEl = node;
                                                                        }}
                                                                        aria-owns={this.state.open ? 'menu-list-grow' : undefined}
                                                                        aria-haspopup="true"                                                                        
                                                                        >
                                                                         <Help  style={{marginLeft: 0,marginTop: 1, marginBottom: 2, padding: '0px 0px'}}
                                                                         variant="outlined" onClick={() => {this.openDocInfo();}} />                                                                           
                                                                        </IconButton>  
                                                                    </Tooltip>      
                            </div> */}    
                            <MenuUserHelp align="right" />
                            <MenuLanguage align="right" dispatchSetActiveLanguage={this.props.dispatchSetActiveLanguage}/>
                            <MenuUser align="right" />
                            
                        </div>
                                             
                       

                    </Grid>
                </Grid>
                <div>
                    <CSSTransitionGroup
                        transitionName="acciones"
                        transitionEnterTimeout={2000}
                        transitionLeaveTimeout={100}>
                        {
                            this.props.mostrarModal === true ?                   
                                <Modalacciones modal={true} key={this.props.idAccion} />
                                : ''}
                    </CSSTransitionGroup>
                </div>
            </Container>);
    }
}

MenuAppBar.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => (
    {
        usuario: state.user.DatosUsuarioValidado.Usuario ? state.user.DatosUsuarioValidado.Usuario : 'Login',
        mostrarModal: state.status.modalAcciones,
        idAccion: state.user.idAccion ? state.user.idAccion : 0
    }
);

const mapDispatchToProps = {
    purgarStore,
    goHome,
    goExpedientesUser,
    fetchMuestraModal,
    fetchIdAccion,
    fetchCambiaStatoModalBuscador,
    fetchCambiaStadoModal,
    dispatchSetActiveLanguage,
    fetchocultaModal, 
    fetchCambiaStadoModalFalse
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(MenuAppBar))));
