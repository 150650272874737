import React, { Component } from 'react';
import { List, ListItem, ListItemText, LinearProgress, Grid, CircularProgress ,Tooltip} from '@material-ui/core';
import {
  Divider, Collapse, withStyles, ListItemIcon
} from '@material-ui/core';
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { ExpandLess, ExpandMore} from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh'
import Dropzone from "react-dropzone";

import { red, green, orange } from '@material-ui/core/colors';
import classNames from 'classnames';

import '../../index.css'
import * as actionsExpedientes from '../../../../actions/expedientes';
import {isMobile} from "../../../../api/index";

const styles = theme => ({
  openOption: {
    background: theme.palette.primary.main,
    color: "white"
  },
  textWhite: {
    color: "white",
    fontSize: 14,

  },
  textRed: {
    color: '#dc3545',
    fontSize: 14,

  },
  textWhite13: {
    color: "white",
    fontSize: 12,

  },
  font13: {
    fontSize: 12,

  },
  font13bold: {
    fontSize: 12,
     fontWeight:600

  },
  font14: {
    fontSize: 14,       
  },
  font12: {
    fontSize: 12,
  },
  dragItems: {
    border: 'solid 1px #b26a00',
    borderRadius: 5
  },
  item: {
    border: '1px solid ' + theme.palette.default,
  },
  greyColor: {
    backgroundColor: theme.palette.default,
    fontSize: 14
  },  
  greyColor13: {
    backgroundColor: theme.palette.default,    
    fontSize: 12
  },
  red13: {
    color: red[500],
    fontSize: 12

  },
  red: {
    color: red[500],
    fontSize: 14

  },
  green: {
    color: green[500],
    fontSize: 14
  },
  green13: {
    color: green[500],
    fontSize: 12
  },
  orange: {
    color: orange[500],
    fontSize: 14
  },
  orange13: {
    color: orange[500],
    fontSize: 13
  },
  padding0: {
    padding: 5
  },
  padding0DropZone: {
    padding: 1
  }
});



const mapStateToProps = (state) => {
  return (
      {
          fileUpload: state.status.files
      }
  )
};

const mapDispatchToProps =
{
  fetchErrorExpediente: actionsExpedientes.fetchErrorExpediente,
  uploadFiles: actionsExpedientes.uploadFiles,
  resetUploadStates: actionsExpedientes.resetUpladStates,
  showUploadComponent: actionsExpedientes.showUploadComponent,
  hideUploadComponent: actionsExpedientes.hideUploadComponent,
  formatMessage: actionsExpedientes.formatMenssage,
  dispatchSetFetchingDone: actionsExpedientes.dispatchSetFetchingDone
};



class MenuProyectoEjecucion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openExcecutionMenu: this.props.active,
      openEstructura: false,
      estructurasAbiertas: [],
      isOpenTrabajo: this.props.trabajo.Id_Trabajo.toString() === this.props.activeTrabajo ,
      estructuraDocumental: [],
      estructurasPadre: [],
      isLoading: false,
      showArrow: true,
      isEstructuraTarget:0,
      userLocalFileInsertRequest:-1,
      userLocalDropEstr:0
    }
  }

  // async UNSAFE_componentWillMount() {
  //   if (!this.state.isOpenTrabajo)
  //      this.setState({ isOpenTrabajo: this.props.trabajo.Id_Trabajo.toString() === this.props.activeTrabajo });
  // }

  async componentDidMount() {
    if (this.state.isOpenTrabajo) {
      await this.handleOpenTrabajo(true);
      //await this.props.changeOption(this.props.trabajo.Id_Trabajo) Nota:Non fai falla, xa se carga no obexto superior
      if (this.props.idParamEstructura && this.state.estructurasPadre
        && this.state.estructurasPadre.length > 0) {
        let estr = this.state.estructurasPadre.filter(item => item.Id_Estructura == this.props.idParamEstructura)
        if (estr.length == 1) {
          this.handleSelectStructure(estr[0].Titulo, this.props.trabajo.Id_Trabajo, false);
        }
      }
    }
    
  }

  // async UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

  //   if(nextProps.refreshRequest){
  //     let {estructuraDocumental}=this.state;
  //     let {estructurasPadre}=this.state;
  //     estructuraDocumental=nextProps.estructuraDocumental;
  //     estructurasPadre=nextProps.estructurasPadre;
  //     this.setState({estructuraDocumental,estructurasPadre})
  //   }
    
  //   if (nextProps.expansionRequest) {
  //     let { expansionRequest } = nextProps;
  //     if (this.props.trabajo.Id_Trabajo === expansionRequest.data.trabajo) {
  //       //Abrir opcion del menu que corresponde al trabajo
  //       if (!this.state.isOpenTrabajo)
  //         await this.handleOpenTrabajo(true);

  //       //Abrir opcion del menu correspondiente a la estructura 
  //       let estructura = null;
  //       for (const prop in this.state.estructuraDocumental) {
  //         if (this.state.estructuraDocumental[prop].some &&
  //           this.state.estructuraDocumental[prop].some(x => x.Id_Estructura === expansionRequest.data.item.Id_Estructura_Padre)) {
  //           estructura = prop;
  //         }
  //       }

  //       if (estructura) {
  //         this.handleExpandExtructura(estructura, true);
  //         this.markStructure(estructura);
  //       }
  //     }
  //   }

  //   if(nextProps.estructuraUpload==0){ //Importantísimo essto para que se de por finalizada la subida de archivos arrastrando desde equipo
  //     await this.setState({userLocalDropEstr:nextProps.estructuraUpload})
  //   } 
    
    
  //  /* if (nextProps.collapseRequest) {
  //     if (estructura) {
  //     this.handleExpandExtructura(estructura, true);
  //     this.markStructure(estructura);
  //      }     
  //     } */

  // }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.refreshRequest) {
      return {
        estructuraDocumental: nextProps.estructuraDocumental,
        estructurasPadre: nextProps.estructurasPadre
      };
    }

    if (nextProps.estructuraUpload === 0 && nextProps.estructuraUpload !== prevState.userLocalDropEstr) {
      return { userLocalDropEstr: nextProps.estructuraUpload };
    }

    return null;
  }

  // async componentDidUpdate(prevProps) {
  //   if (this.props.expansionRequest) // && this.props.expansionRequest !== prevProps.expansionRequest) {
  //     const { expansionRequest } = this.props;
  //     if (this.props.trabajo.Id_Trabajo === expansionRequest.data.trabajo) {
  //       // Abrir opción del menú correspondiente al trabajo
  //       if (!this.state.isOpenTrabajo) {
  //         await this.handleOpenTrabajo(true);
  //       }

  //       // Abrir opción del menú correspondiente a la estructura 
  //       let estructura = null;
  //       for (const prop in this.state.estructuraDocumental) {
  //         if (this.state.estructuraDocumental[prop].some &&
  //           this.state.estructuraDocumental[prop].some(x => x.Id_Estructura === expansionRequest.data.item.Id_Estructura_Padre)) {
  //           estructura = prop;
  //         }
  //       }

  //       if (estructura) {
  //         this.handleExpandExtructura(estructura, true);
  //         this.markStructure(estructura);
  //       }
  //     }
  //   }
  // }

needLoadEstructure() {
  if (this.state.estructurasPadre.some(x => x.Id_Trabajo === this.props.trabajo.Id_Trabajo))
    return false; 
   return true;
}

  handleClick(idEstructura) {
        this.setState({ openEstructura: idEstructura });
    if (idEstructura) {
      let estructuraPadre = this.state.estructurasPadre ? this.state.estructurasPadre.find(e => e.Titulo === idEstructura) : "";
      this.props.changeEstructura(estructuraPadre.Id_Estructura, estructuraPadre.Titulo, estructuraPadre);
    }
   
  };

  handleClickTrabajo()
  {
    this.props.changeOption(this.props.trabajo.Id_Trabajo,false);
    this.props.setTrabajoActivo(this.props.trabajo.Id_Trabajo);
    this.handleClick(null);    
    //window.history.pushState('/visualizar-expediente/', 'Title', `/visualizar-expediente/${this.props.trabajo.Id_Expediente}/${this.props.trabajo.Id_Trabajo}`);
         
  }

async refrescar(){
  this.loadTrabajo(true);
}
  
async loadTrabajo(refrescando=false)
{
  
  if (refrescando ){    
     this.props.changeOption(0,false);    
    } /*simulamos un cambio de trabajo fictio al trabajo 0 y así se carga el objeto de nuevo, esto se hace al refrescar sobre el icono del*/
  
    this.setState({ isLoading: true})   
   
if (this.props.trabajo.Id_Trabajo>0 )
{  if (this.props.trabajo.Id_Tratamiento_Formato==2) {
      let data = await this.props.getSetEstructuraDocumentalYArchivos(this.props.trabajo.Id_Expediente,this.props.trabajo.Id_Trabajo,this.props.idEstructuraActiva,true,null);
      this.setState({
        estructuraDocumental: data.estructuraDocumental,
        estructurasPadre: data.estructurasPadre,
        isLoading: false,    
        showArrow: data.estructurasPadre.length > 0
        });

      if (refrescando)  
        this.props.recargaNavBarYListaTrabajos()

    }
    else
      this.setState({
      estructuraDocumental: null,
      estructurasPadre: null,
      isLoading: false,    
      showArrow: false  
       });

     /* md si despliego un trabajo tendo que abrirlo y cambiar de trabajo*/
  }
   
  if (this.props.trabajo.Id_Trabajo>0 && this.props.trabajo.Id_Tratamiento_Formato==2) 
   await this.handleClickAndOpenTrabajo(false,true);       
   
   return;
  
}
  async handleOpenTrabajo(isOpen,refrescando) {
    this.setState({ isOpenTrabajo: isOpen });
    if (this.needLoadEstructure()) {    
      this.loadTrabajo();
      }      
    
    // md si despliego un trabajo tendo que abrirlo y cambiar de trabajo
    if (isOpen){        
      this.handleClickTrabajo(refrescando);    
      }
        
      
  };


  async handleClickAndOpenTrabajo(open=false,loading=false)
  {
    this.props.changeOption(this.props.trabajo.Id_Trabajo,loading);
    this.props.setTrabajoActivo(this.props.trabajo.Id_Trabajo);
    this.handleClick(null);
    this.props.resetExpansionRequest();    
    //window.history.pushState('/visualizar-expediente/', 'Title', `/visualizar-expediente/${this.props.trabajo.Id_Expediente}/${this.props.trabajo.Id_Trabajo}`);
  
    await this.handleOpenTrabajo(false)
 
  }
 
  throwTrabajo(){
    window.history.pushState('/visualizar-expediente/', 'Title', `/visualizar-expediente/${this.props.trabajo.Id_Expediente}/${this.props.trabajo.Id_Trabajo}`);
  }
  /*handleClickEstructura1(estructura)
  {window.history.pushState('/visualizar-expediente/', 'Title', `/visualizar-expediente/${this.props.trabajo.Id_Expediente}/${this.props.trabajo.Id_Trabajo}`);
   this.handleSelectStructure(estructura, this.props.trabajo.Id_Trabajo) 
  }*/


  handleExpandExtructura(idEstructura,onlyOpen = false) {
    let newIdEstructura = this.state.estructurasAbiertas;
    let pos = this.state.estructurasAbiertas.indexOf(idEstructura)
    if (onlyOpen) {
      if (pos === -1) {
        newIdEstructura.push(idEstructura)
      }
    }
    else {
      if (pos === -1) {
        newIdEstructura.push(idEstructura)
      } else {
        newIdEstructura.splice(pos, 1)
      }
    }
    this.setState({ estructurasAbiertas: newIdEstructura });    
    //if (onlyOpen ) // && idTrabajoDeEstructura!=0 && this.props.trabajo.Id_Trabajo!=idTrabajoDeEstructura)
    //this.handleClickEstructura1(idEstructura)
  }




 
  expandExtructure(idEstructura, load = false, onlyOpen = false) {
    let newIdEstructura = this.state.estructurasAbiertas;
    let pos = this.state.estructurasAbiertas.indexOf(idEstructura)
    if (onlyOpen) {
      if (pos === -1) {
        newIdEstructura.push(idEstructura)
      }
    }
    else {
      if (pos === -1) {
        newIdEstructura.push(idEstructura)
      } else {
        newIdEstructura.splice(pos, 1)
      }
    }

    this.setState(state => ({ estructurasAbiertas: newIdEstructura, openEstructura: state.openEstructura === idEstructura ? -1 : idEstructura }));
    if (load) {
      let estructuraPadre = this.state.estructurasPadre ? this.state.estructurasPadre.find(e => e.Titulo === idEstructura) : "";
      this.props.changeEstructura(estructuraPadre.Id_Estructura, estructuraPadre.Titulo, estructuraPadre);
    }
  }
  async markStructure(idEstructura) {
    await this.setState(state => ({ openEstructura: idEstructura }));      
  }

  // async initialExpanded(estructure) {
  //   await this.markStructure(estructure.Titulo)
  //   await this.setState({ estructurasAbiertas: [estructure.Titulo] })
  // }


  isSelectMenuOption = () => {
    let { trabajo, activeTrabajo } = this.props;
    if (trabajo.Id_Trabajo === activeTrabajo)    
      return true;    
    else
    { 
    return false;
    }
  }

  handleSelectStructure = (estructura, idTrabajo, resetExpansion = true) => {
    this.handleClick(estructura);
    this.props.setTrabajoActivo(idTrabajo);
    this.props.setWorkSpaceToTrabajoEjecucion(idTrabajo);
    if (resetExpansion) this.props.resetExpansionRequest();
  }


async handleDragFiles(idEstructura) {
    //TODO
    if (this.state.overChildren)
      {this.setState({ overChildren: false}); }   
    
      //console.log("handleDragFILES");
    
      // se expande estructura, hay que hacerlo antes de arrastrar, si no es "farragoso"  
    if (idEstructura!='0')
      this.expandExtructure(idEstructura, false, true);
}

async handleDragFilesChildren(idEstructura) {  
  /// Si hay item que mover o ya estoy subiendo no puedo estar arrastrando desde equipo
 if (!this.props.dragItems && !(this.props.fileUpload.uploadInProgress&&this.props.fileUpload.typeUpload=='toEstructura'))   
    this.dragOverUploadFile(idEstructura)

  await setTimeout(() => {
    this.setState({ overChildren: true});
  }, 1)

  //if (this.props.dragItems)  
  //console.log("handleDragFILESchildren");
  //console.log(this.state.overChildren);  
}

async dragOverUploadFile(idEstructura) {
    
  let makeid=this.makeid(50);

  /*NOTA 22/11/2022 comentado  mientras no se puede arrastrar desde equipo*/
  await this.setState({userLocalDropEstr:idEstructura,userLocalFileInsertRequest: idEstructura,makeid})
  setTimeout(async()=>{
  if(this.state.makeid==makeid){
      await  this.setState({userLocalFileInsertRequest: -1,})
  }},2000)

}

async dragOverUploadFileEnd() {          
}



dragOverChildren = (permitido=false) => (event) => {
  /*si es numerico es estructura que permite arrastrar*/
  event.preventDefault();
   
  if (permitido)
      {event.dataTransfer.dropEffect = "copy"}          
  else
      {event.dataTransfer.dropEffect = "none"}                             
      
  }

  dragOver = (permitido=false) => (event) => {
      /*si es numerico es estructura que permite arrastrar*/
      //console.log(this.state.overChildren);
      if (!this.state.overChildren)
      {
      event.preventDefault();
      if (permitido)
          {event.dataTransfer.dropEffect = "copy"}               
      else
          {event.dataTransfer.dropEffect = "none"}                        
      }}
      // else{
      //    this.setState({ overChildren: false })        
     // }
    
async onDropMoveItem(children){
 
// Si no hay item que mover o estoy arrastrando desde equipo (userLocalDropEstr>0), no se hace nada  
if (this.props.dragItems && this.state.userLocalDropEstr==0) {
    await this.setState({isEstructuraTarget:children.Id_Estructura});      

    await this.props.moveItemTo(children.Id_Estructura,children.Proporcion_Tamanho_Maximo_Archivos)   

    await this.setState({ isEstructuraTarget:0 }); 
  }
}

  dragEnd = () => (event) => {
      event.dataTransfer.dropEffect = "default"                      
      //equipo
      this.dragOverUploadFileEnd()
   }



async onDrop(acceptedFiles,estructura,estructuraPadre,tamMax) {
  //alert(acceptedFiles.length)
  if (acceptedFiles.length>0){    
      this.props.onDrop(acceptedFiles,estructura,estructuraPadre,tamMax)
      if (this.props.notInFolderPlace) {
          this.props.showUploadComponent()
      }
  } 

  this.dragOverUploadFileEnd()

}

makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
 
 
  getCleanedString(cadena) {
    if (cadena == null)
        return '';
    
    cadena = cadena.toLowerCase();    
    return cadena;
}

  estado = (nombre) => {
    let nombreLimpio = this.getCleanedString(nombre);
    if (nombreLimpio !== '')
        return (
            <div className={nombreLimpio}>
                {<img alt={200} src={this.iconUrl(nombreLimpio)} />}                
            </div>);
}

iconUrl = (nombreLimpio) => {
    try {
        return require(`../../../Tramitaciones/IconosEstados/${nombreLimpio}.svg`);
    } catch (e) {
        //return require(`../../../Tramitaciones/IconosEstados/borrador.svg`);
    }
}



  render() {
    let { classes } = this.props;
    
    let { isLoading } = this.state;
    let isSelect = this.isSelectMenuOption();

    let isMobile_= isMobile();    
   
    return (
      <div className="arbol_estados">
       {/* md quitamos esta clase para pintar con el color '#2196f3'
       <ListItem button className={isSelect ? `${classes.openOption} active` : ""}*/}
         <ListItem button 
          //style={{ padding: "0 24px 0 0" }}
          style={{ padding: "0 24px 0 0" , backgroundColor:  isSelect ? '#2196f3' : "white" }}
          onDragEnter={() => this.handleDragFiles(0)}
          onDragEnd={this.dragEnd()}
          onDragOver={this.dragOver(false)}       
          >
          

        <ListItemIcon style={{ marginRight: 0, fontSize: 18 , marginLeft: 12}}>                     
                     {this.estado(this.props.trabajo.Estado)}
      </ListItemIcon>

          <ListItemText primary={ this.props.trabajo.Id_Trabajo +' - '+this.props.trabajo.Titulo} style={{ padding: "11px 0 11px 4px" }} 
            classes={{ primary: isSelect ? classes.textWhite : (this.props.trabajo.Incidencias > 0 && this.props.trabajo.SePuede_Editar? classes.textRed: classes.font14)}}
         
            className="max-expanssion"
            onClick={() => { 
              
                this.handleClickAndOpenTrabajo(); /*md handleClickOpenTrabajo*/
            
            }} />
          {this.props.trabajo.Tiene_Carpetas === 1
            ? <div className="arrow-right"
              onClick={async () => {
                if (this.props.activeTrabajo == this.props.trabajo.Id_Trabajo)
                  await this.refrescar();            
                else
                  await this.handleClickAndOpenTrabajo(!this.state.isOpenTrabajo);
                }}
              >


              {this.props.activeTrabajo == this.props.trabajo.Id_Trabajo//this.state.isOpenTrabajo
                ? !this.state.isLoading ? 
                <Tooltip title={'Refrescar ' +' ' +this.props.trabajo.Id_Trabajo +' - '+this.props.trabajo.Titulo}>
                  <RefreshIcon style={{ color: "white" }} size={12}  className="my-auto mx-auto"                
                /></Tooltip> 
                :  <CircularProgress className="my-auto mx-auto"  size={18} color="inherit"/>              
                : <ExpandMore className="my-auto mx-auto"               
                /> }


            </div>
            : <div></div>
          }
        </ListItem>
        <Divider />

        {this.state.estructuraDocumental && 
        <Collapse className="arbol_rama" in={this.props.activeTrabajo == this.props.trabajo.Id_Trabajo } timeout="auto" unmountOnExit> {/*in={this.state.isOpenTrabajo} timeout="auto" unmountOnExit>}*/}
          {this.state.isLoading   ? <LinearProgress className="m-3" />
            : Object.keys(this.state.estructuraDocumental).map((estructura, position) => {
              let estructuraPadre = this.state.estructurasPadre ? this.state.estructurasPadre.find(e => e.Titulo === estructura) : "";
              let estructuraActual = this.state.estructuraDocumental[estructura];
              let isOpenStructure = this.state.estructurasAbiertas.indexOf(estructura) != -1;
              let uploading=(this.props.fileUpload.uploadInProgress&&this.props.fileUpload.typeUpload=='toEstructura')
                          

              return <List key={`menu-${position}-${this.props.trabajo.Id_Trabajo}`} component="div" disablePadding>
                {this.state.estructuraDocumental[estructura].length && this.state.estructuraDocumental[estructura].length > 0 ?
                  <div 
                   // Revisar esto no está funcionado bien, es decir, cambiar icono si no se puede soltar 
                   onDragEnter={() => this.handleDragFiles(estructura)}
                   onDragEnd={this.dragEnd()}
                   onDragOver={this.dragOver(false)}
                    >
                                        
                    <ListItem 
                    
                    style={{ backgroundColor: this.state.openEstructura == estructura && isSelect ?'#cde5fa'  : "white" }} 
                      button className={`${classes.item}  paddingStructure`}>
 

                    <ListItemIcon  style={{ marginRight: 0, fontSize: 14, marginLeft: 48 }}
                      className={estructuraPadre.Estado_Visual === 0 ? classes.red : (estructuraPadre.Estado_Visual === 1 && classes.green)}>
                      {estructuraPadre.Archivo_Requerido == 1 ? this.estado("carpeta0") : this.estado("carpeta")}     
                    </ListItemIcon>
                                     

                      <ListItemText
                        primary={estructura + ((estructuraPadre && estructuraPadre.Archivo_Requerido !== null && estructuraPadre.Archivo_Requerido == 1) ? ' *' : '') + (estructuraPadre.Numero_Archivos_Carpeta > 0  ? ' (' + estructuraPadre.Numero_Archivos_Carpeta + ')': '')   }                                                                                          
                        classes={{ root: classes.padding0, primary:  (estructuraPadre.Estado_Visual === 0 ? classes.red13 : (estructuraPadre.Estado_Visual === 1 ? classes.green13 : (estructuraPadre.Archivo_Requerido == 1 ? classes.font13bold :  classes.font13)) ) }}
                        
                        style={{ color: this.state.openEstructura == estructura && isSelect ? 'white' : "black"  ,paddingTop: 11, paddingBottom: 11 }} /*10px 0 10px 48px" */
                        onClick={() => { 
                          this.throwTrabajo();
                          this.handleSelectStructure(estructura, this.props.trabajo.Id_Trabajo) 
                          this.handleExpandExtructura(estructura,true)
                        }}
                        className="max-expanssion-child"/>

                        
                      {/*this.props.idEstructuraActiva == estructuraPadre.Id_Estructura && ((this.props.fileUpload.uploadInProgress&&this.props.fileUpload.typeUpload=='toEstructura') || this.props.removingFiles ) ?  <CircularProgress size={16}/>
                       :  */    //Nota: esto sería si queremos que muestre icono refrescando al eliminar archivos en carpeta padre
                            
                      <div className="arrow-right"
                        onClick={() => this.handleExpandExtructura(estructura, !isOpenStructure)}>
                        {this.state.estructurasAbiertas.indexOf(estructura) != -1
                          ? <ExpandLess className="my-auto mx-auto" onClick={() => this.handleExpandExtructura(estructura, true)} />
                          : <ExpandMore className="my-auto mx-auto" onClick={() => this.handleExpandExtructura(estructura, false)} />}
                      </div>
                     }

                 

                    </ListItem>

                    <Collapse in={this.state.estructurasAbiertas.indexOf(estructura) != -1} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {this.state.estructuraDocumental[estructura].map((children, pos) => {
                          return <ListItem key={'menu-item' + pos} 
                          
                          onDrop={async () => {this.onDropMoveItem(children) }}                         

                          onDragEnter={() => this.handleDragFilesChildren(children.Id_Estructura)}
                          onDragEnd={this.dragEnd()}
                          onDragOver={this.dragOverChildren(this.props.trabajo.SePuede_Editar==1 && children.Permite_Anexar_Archivos==1 && !uploading)}
                            
                            // md no tiene que repintar al ser dragItems, solo desplegar   
                            //className={classNames((this.props.dragItems ? classes.dragItems : '')  ,
                            //className={classNames((this.props.dragItems ? '' : '')  ,(children.Id_Estructura === this.props.idEstructuraActiva ? classes.openOption : classes.greyColor13))}
                            className={classNames((this.props.dragItems ? '' : '')) }

                            // md pintamos al seleccionar carpeta con mismo color de fondo que el trabajo y la carpeta padre
                            style={{ backgroundColor: children.Id_Estructura === this.props.idEstructuraActiva  ? '#cde5fa' : "#f1f1f1" }}
                            //onDragOver={() => { this.setState({ drop: pos }) }} 
                            button  onClick={() => {
                              this.throwTrabajo();
                              this.markStructure(children.Titulo_Padre)
                              this.props.changeEstructura(children.Id_Estructura, children.Titulo, children)
                              this.props.setWorkSpaceToTrabajoEjecucion(this.props.trabajo.Id_Trabajo);
                              this.props.resetExpansionRequest();
                            }}>

                          

                            {!(this.state.userLocalFileInsertRequest == children.Id_Estructura) &&
                             <ListItemIcon style={{ marginRight: 0, fontSize: 14, marginLeft: 40 }}
                              className={children.Estado_Visual === 0 ? classes.red : (children.Estado_Visual === 1 ? classes.green : classes.font14)}>
                              {/*children.Estado_Visual === 0 ? <Close style={{ fontSize: 18 }}/>  : (children.Estado_Visual === 1 ? <Check style={{ fontSize: 18 }} /> : <Block style={{ fontSize: 18 }} />)
                              */} 
                               { children.Estado_Visual === 0 ? this.estado("carpetako") :  (children.Estado_Visual === 1 ? this.estado("carpetaok")  : this.estado("carpeta0") )} 
                            </ListItemIcon>
                            }                            
                           
                            {!(this.state.userLocalFileInsertRequest == children.Id_Estructura) &&  
                            <ListItemText inset primary={children.Titulo + (children.Archivo_Requerido !== null && children.Archivo_Requerido == 1 ? ' * ': ' ')  + (children.Numero_Archivos_Carpeta > 0  ? ' (' + children.Numero_Archivos_Carpeta + ')' : '')   }                             
                             classes={{ root: classes.padding0, primary:  (children.Estado_Visual === 0 ? classes.red13 : (children.Estado_Visual === 1 ? classes.green13 : (children.Archivo_Requerido == 1 ? classes.font13bold :  classes.font13)) ) }}
                              style={{  paddingTop: 0, paddingBottom: 0 }}
                            />                            
                          }

                             {(this.state.userLocalFileInsertRequest == children.Id_Estructura) && 
                            <Dropzone accept="application/pdf"
                                                style={{width:'calc(100% - 6px)',height:24,marginBottom:0,marginLeft:0,marginRight:2}}


                                                onDrop={(acceptedFiles) => this.onDrop(acceptedFiles,children.Id_Estructura,children.Id_Estructura_Padre,children.Proporcion_Tamanho_Maximo_Archivos)}>
                                                {() => (
                                                    <div  style={{
                                                        //flex: 1,
                                                        //display: 'flex',
                                                        //flexDirection: "column",
                                                        alignItems:"left",
                                                        padding: 0,
                                                        borderWidth: 2,
                                                        borderRadius: 2,
                                                        borderColor:' #eeeeee',
                                                        borderStyle:"dashed",
                                                        width:'100%',
                                                        height:'100%',
                                                        backgroundColor:"#fafafa",
                                                        color: "#bdbdbd",
                                                        outline: "none",
                                                        transition:"border .24s ease-in-out"
                                                    }}>                 
                                              
                                                 {/*} <ListItemIcon style={{ marginRight: 0, fontSize: 14, marginLeft: 40 }}
                                                    className={children.Estado_Visual === 0 ? classes.red : (children.Estado_Visual === 1 ? classes.green : classes.font13)}>                             
                                                    { children.Estado_Visual === 0 ? this.estado("carpetako") :  (children.Estado_Visual === 1 ? this.estado("carpetaok")  : this.estado("carpeta0") )} 
                                                  </ListItemIcon>   */}
                                                  <ListItemText inset primary={children.Titulo + (children.Archivo_Requerido !== null && children.Archivo_Requerido == 1 ? ' * ': ' ')  + (children.Numero_Archivos_Carpeta > 0  ? ' (' + children.Numero_Archivos_Carpeta + ')' : '')   }                             
                                                      classes={{ root: classes.padding0, primary:  (children.Estado_Visual === 0 ? classes.red13 : (children.Estado_Visual === 1 ? classes.green13 : (children.Archivo_Requerido == 1 ? classes.font13bold :  classes.font13)) ) }}
                                                      style={{  paddingTop: 0, paddingBottom: 0 }}
                                                   /> 

                                                </div>
                                               )}                                  

                          </Dropzone>
                          }

                           {this.state.userLocalFileInsertRequest <0 && this.state.isEstructuraTarget == children.Id_Estructura && this.props.idEstructuraActiva != this.state.isEstructuraTarget  ?                              
                            <CircularProgress size={16}/> :null}
                           
                           {(this.state.userLocalDropEstr == children.Id_Estructura  || (this.props.estructuraUpload == children.Id_Estructura && this.state.userLocalDropEstr==0)) && ( uploading || this.props.removingFiles ) ?  
                            <CircularProgress size={16}/>:null}                           
                        
                         </ListItem>
                        })}

                      </List>
                    </Collapse>
                  </div> :
                  (
                                       
                 
                  <ListItem key={'menu-item' + position}
                    onDrop={async () => {this.onDropMoveItem(estructuraActual) }}                
                    
                    onDragEnter={() => this.handleDragFilesChildren(estructuraActual.Id_Estructura)}
                    onDragEnd={this.dragEnd()}
                    onDragOver={this.dragOver(this.props.trabajo.SePuede_Editar==1 && estructuraActual.Permite_Anexar_Archivos==1 && !uploading)}
                                                          
                   
                    // md no tiene que repintar al ser dragItems, solo desplegar   
                            //className={classNames((this.props.dragItems ? classes.dragItems : '')  ,
                            //className={classNames((this.props.dragItems ? '' : '')  ,  (estructuraActual.Id_Estructura === this.props.idEstructuraActiva  ? classes.openOption : ""), classes.item)}                             
                            className={classNames((this.props.dragItems ? '' : '')) }

                      
                      /* md comentar lo siguiente que está para que salga en azul ya lo seleccionado*/
                      style={{ backgroundColor: estructuraActual.Id_Estructura === this.props.idEstructuraActiva ?  '#cde5fa' : "white" }} /*##b6d7f2 classes.openOption*/
                      /*button className={`${classes.item}  `}*/
                      
                    //onDragOver={() => { this.setState({ drop: position }) }} 
                    button onClick={() => {
                      this.throwTrabajo();
                      this.handleClick(estructura);
                      this.props.setWorkSpaceToTrabajoEjecucion(this.props.trabajo.Id_Trabajo);
                      this.props.resetExpansionRequest();
                      
                    }}   >
                    
                                          
                     {
                     /* md iconos también a carpetas padre? 
                     Nota: Por alguna razón que desconocemos al verlo tamaño pequeño las carpetas primer nivel sin hijos se desplazan hacia la izquierda. 
                           Debido a esto cambiamos margen left según isMobile */}  
                    
                    {!(this.state.userLocalFileInsertRequest == estructuraActual.Id_Estructura) &&  
                    <ListItemIcon style={{ marginRight: 0, fontSize: 14 , marginLeft:!isMobile_ ?24:(window.innerWidth < 600? 32:25)}}
                         className={estructuraActual.Estado_Visual === 0 ? classes.red : (estructuraActual.Estado_Visual === 1 && classes.green)}>
                         {estructuraActual.Estado_Visual === 0 ? this.estado("carpetako") : (estructuraActual.Estado_Visual === 1 ? this.estado("carpetaok") :  this.estado("carpeta0") ) }
                    </ListItemIcon>
                     }    
                    
                    {!(this.state.userLocalFileInsertRequest == estructuraActual.Id_Estructura) &&  
                     <ListItemText inset primary={estructuraActual.Titulo + (estructuraActual.Archivo_Requerido !== null && estructuraActual.Archivo_Requerido == 1 ? ' * ': ' ')  + (estructuraActual.Numero_Archivos_Carpeta > 0  ? ' (' + estructuraActual.Numero_Archivos_Carpeta + ')': '')   }                      
                          classes={{ root: classes.padding0, primary:  (estructuraActual.Estado_Visual === 0 ? classes.red13 : (estructuraActual.Estado_Visual === 1 ? classes.green13 : (estructuraActual.Archivo_Requerido == 1 ? classes.font13bold :  classes.font13))) }}
                          style={{  paddingTop: 0, paddingBottom: 0 }}
                       /> 
                    }                                           
                       
                    {(this.state.userLocalFileInsertRequest == estructuraActual.Id_Estructura) && 
                      <Dropzone accept="application/pdf"
                                                style={{width:'calc(100% - 6px)',height:24,marginBottom:0,marginLeft:0,marginRight:2}}


                                                onDrop={(acceptedFiles) =>  this.onDrop(acceptedFiles,estructuraActual.Id_Estructura,estructuraActual.Id_Estructura_Padre,estructuraActual.Proporcion_Tamanho_Maximo_Archivos)}>
                                                {() => (
                                                    <div  style={{
                                                        //flex: 1,
                                                        //display: 'flex',
                                                        //flexDirection: "column",
                                                        alignItems:"left",
                                                        padding: 0,
                                                        borderWidth: 2,
                                                        borderRadius: 2,
                                                        borderColor:' #eeeeee',
                                                        borderStyle:"dashed",
                                                        width:'100%',
                                                        height:'100%',
                                                        backgroundColor:"#fafafa",
                                                        color: "#bdbdbd",
                                                        outline: "none",
                                                        transition:"border .24s ease-in-out"
                                                    }}>  
                                                                                                      
                                                    {/*<ListItemIcon style={{ marginRight: 0, fontSize: 14 , marginLeft:!isMobile_ ?24:(window.innerWidth < 600? 32:25)}}
                                                         className={estructuraActual.Estado_Visual === 0 ? classes.red : (estructuraActual.Estado_Visual === 1 && classes.green)}>
                                                         {estructuraActual.Estado_Visual === 0 ? this.estado("carpetako") : (estructuraActual.Estado_Visual === 1 ? this.estado("carpetaok") :  this.estado("carpeta0") ) }
                                                  </ListItemIcon>                    */}
                   
                                                   <ListItemText inset primary={estructuraActual.Titulo + (estructuraActual.Archivo_Requerido !== null && estructuraActual.Archivo_Requerido == 1 ? ' * ': ' ')  + (estructuraActual.Numero_Archivos_Carpeta > 0  ? ' (' + estructuraActual.Numero_Archivos_Carpeta + ')': '')   }                      
                                                          classes={{ root: classes.padding0, primary:  (estructuraActual.Estado_Visual === 0 ? classes.red13 : (estructuraActual.Estado_Visual === 1 ? classes.green13 : (estructuraActual.Archivo_Requerido == 1 ? classes.font13bold :  classes.font13))) }}
                                                           style={{  paddingTop: 0, paddingBottom: 0 }}
                                                      />  
                   
                                              </div>
                                                )}
                          </Dropzone>
                    }

                    {this.state.isEstructuraTarget == estructuraActual.Id_Estructura && this.props.idEstructuraActiva != this.state.isEstructuraTarget?  
                    <CircularProgress size={16}/> :null}               
                    {(this.state.userLocalDropEstr == estructuraActual.Id_Estructura  || (this.props.estructuraUpload == estructuraActual.Id_Estructura && this.state.userLocalDropEstr==0)) && ( uploading || this.props.removingFiles ) ?  
                    <CircularProgress size={16}/> :null}                               

                     {/*(this.state.userLocalFileInsertRequest  == estructuraActual.Id_Estructura) && ((this.props.fileUpload.uploadInProgress&&this.props.fileUpload.typeUpload=='toEstructura')) ?  
                    <CircularProgress size={16}/> :null*/}                        
                                        

                  </ListItem> 
                  )
                 
                }
              </List>
            })}
        </Collapse>
        }

      </div>
    )
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(MenuProyectoEjecucion)));