export const FETCH_TIPO_OBRA = 'FETCH_TIPO_OBRA';
export const FETCH_TIPOS_TRABAJO = 'FETCH_TIPOS_TRABAJO';
export const FETCH_TRABAJOS_ERROR = 'FETCH_TRABAJOS_ERROR';
export const FETCH_TIPOS_AUTORIZACION = 'FETCH_TIPOS_AUTORIZACION';
export const FETCH_FASES_TRABAJOS = 'FETCH_FASES_TRABAJOS';
export const CAMBIO_CONTENEDOR_CENTRAL = 'CAMBIO_CONTENEDOR_CENTRAL';
export const CAMBIO_CONTENEDOR_CENTRAL_RESET = 'CAMBIO_CONTENEDOR_CENTRAL_RESET';
export const FETCH_ESTRUCTURA_DOCUMENTAL_TRABAJO = 'FETCH_ESTRUCTURA_DOCUMENTAL_TRABAJO';
export const FILES_TO_UPLOAD = 'FILES_TO_UPLOAD';
export const FETCH_GRUPOS_RAIZ = "FETCH_GRUPOS_RAIZ";
export const FETCH_COMUNICACION_ENCARGO = "FETCH_COMUNICACION_ENCARGO";
export const FETCH_FUNCIONES_TIPOLOGIA = 'FETCH_FUNCIONES_TIPOLOGIA';
export const ADD_AGENTE_TRABAJO = "ADD_AGENTE_TRABAJO";
export const DELETE_AGENTE_TRABAJO = "DELETE_AGENTE_TRABAJO";
export const EDIT_AGENTE_TRABAJO = "EDIT_AGENTE_TRABAJO";
export const SAVE_TIPOS_OBRA = "SAVE_TIPOS_OBRA";