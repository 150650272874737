/*
*Configuración base para las llamadas axios, se asegura que
* tenga el token, en caso de no tenerlo lo añade si este existe
*/



import {handleLoggout } from './../helpers/logout.js'
import axios from 'axios';
import {fetchErrorExpediente,awaitConfirmation} from "../actions/expedientes";
import {store} from '../index'
import {getDefaultLanguage,requireConfirmation,operationCancelled} from './index'

export const BASE_PATH = "https://servicios.coag.es/api";
export const VERSION_APP = "v2.19.08";
export const VERSION_DATA = "2410101300";

export const TAM_MAX =150;
export const TAM_BASE =10;

export const URL_MANUAL="https://docs.google.com/document/d/14VkXR3-sRvxAm4shCvXiwurV1tBGLR3RNxo4uPAPgQg/view"
export const URL_FAQ="https://docs.google.com/document/d/1sN_JFYhgAARqON4yg0f30DDpL1Vx9l05V0GhvXIGCcA/view"
export const URL_VIDEOS="https://portal.coag.es/exemplos-video/"
export const URL_CONINTEROP="https://docs.google.com/document/d/1zGGSV0T0oc-8CS4a4emfrldA5EMGuOJLLzV4s8bXPoE/view?rm=minimal"
export const URL_AUTOASIGN_1="https://docs.google.com/document/d/1vISgDiQoP68VCp4OOn2A7MepyRfdpQzdBCkMb03ny7w/edit"
export const URL_AUTOASIGN_2="https://docs.google.com/document/d/1YaU79TCGXCStPcGiBwYtdVCp5FXnSOXD5k2ZCoqoD9c"
export const URL_GUIA="https://docs.google.com/document/d/1xXVo8Wcm_Rvmzmr0sWXvZPzzr3ZlcGUVS9Jkm90gSoE/view"
export const URL_ALTA_USU="https://www.coag.es/profesion/xestionscolexiais/xestionusuarios/SolicitudeAltaUsuario.aspx"
export const URL_RECUPERAR="https://www.coag.es/profesion/xestionscolexiais/xestionusuarios/recuperarcontrasinal.aspx"
export const URL_CATASTRO="https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCBusqueda.aspx?fromVolver=ListaBienes&TipUR=&codVia=&comVia=&final=&pest=rc&pol=&par=&Idufir=&RCCompleta="
export const URL_MAPS="https://maps.google.com/maps?q="

export const iconUbicacion ='ubicacion.png' 

export const ID_PAIS_ESP=100;
export const ID_REG_GALICIA=71;
export const ID_PROV_CORU=15;
export const ID_PAIS_OTRO=999
export const ID_REG_OTRA=9999;
export const ID_PROV_OTRA=9999;
export const ID_CONCELLO_OTRO=99999;

export const ID_TRABAJO_ENCOMENDA = 219
export const ID_TRABAJO_VAL_GEOTECNICO = 270
export const ID_DOCUMENTACION_GEOTECNICO=204 
export const ID_DOCUMENTACION_ANEXOS_MEMORIA=203

export const ID_TRAMITE_VISADO=0
export const ID_TRAMITE_REGISTRO=2

export const ID_TIPO_EMP_GEO=2; //emprazamento geolocalizado*/

export const ID_TRABAJO_CESAMENTO = 271
export const ID_TIPO_RELACION_SUBS=6; 
export const ID_TIPO_RELACION_CF=5; 
export const ID_FORMATO_DIGITAL=2

export async function fetchData(url,params={},method='GET',ignoreObservation=0,intent=1){
    let getParams,sendParams=null;
    try{
        let response = null;
        switch (method) {
            case "GET":
                if(!params.ignorarobservaciones){
                    params.ignorarobservaciones=ignoreObservation;
                }
                //md no se estaba cargando idioma actual en tipos
                if (!params.idioma)
                params.idioma=getDefaultLanguage();
                
                response = await api.get(url,{
                    params:params
                })
                break;
            case "POST":
                 getParams = {
                        idioma:getDefaultLanguage(),
                        ignorarobservaciones:ignoreObservation
                    };
                 params.idioma=getDefaultLanguage();
                 params.ignorarobservaciones=ignoreObservation;
                if(params.GET){
                    Object.keys(params.GET).map((key)=>{
                        getParams[key]=params.GET[key];
                    })
                }
                sendParams = Object.assign({},params)
                delete sendParams.GET;

                response = await api.post(`${url}${generateStrGetParams(getParams)}`,sendParams)
                break;
            case "UPLOAD":
                getParams = {
                    idioma:getDefaultLanguage(),
                    ignorarobservaciones:ignoreObservation
                };
                response = await axios.post(BASE_PATH+`${url}${generateStrGetParams(getParams)}`,params,
                    {
                        headers:{
                            'Content-Type': 'multipart/form-data',
                            'Token': localStorage.getItem('token'),
                        }
                    })
                break;
            case "PUT":
                getParams = {
                    idioma:getDefaultLanguage(),
                    ignorarobservaciones:ignoreObservation
                };
                if(params.GET){
                    Object.keys(params.GET).map((key)=>{
                        getParams[key]=params.GET[key];
                    })
                }
                sendParams = Object.assign({},params)
                delete sendParams.GET;
                response = await api.put(`${url}${generateStrGetParams(getParams)}`,sendParams)
                break;
            case "DELETE":
                getParams = {
                    idioma:getDefaultLanguage(),
                    IgnorarObservaciones:ignoreObservation
                };
                if(params.GET){
                    Object.keys(params.GET).map((key)=>{
                        getParams[key]=params.GET[key];
                    })
                }
                params.idioma=getDefaultLanguage();
                params.IgnorarObservaciones=ignoreObservation;
                sendParams = Object.assign({},params)
                delete sendParams.GET;
                response = await api.delete(`${url}${generateStrGetParams(getParams)}`,
                    {data:sendParams})
                break;
            default:
                store.dispatch(fetchErrorExpediente(formatMenssage(`Error consultando ${method}-${url}.`)));
                return false;
        }

        return await tramitarRespuesta(response,url,params,method);
    }
    catch (error) {

        if(error.response){
            if(error.response.data&&error.response.data.Message){
                store.dispatch(fetchErrorExpediente(formatMenssage(error.response.data.Message)));
                return false;
            }
            else if (error.response.data.MensajesProcesado && error.response.data.MensajesProcesado.length > 0) {

                return await  tramitarRespuesta(error.response,url,params,method);
            }
        }else{

            if(intent==3){
                store.dispatch(fetchErrorExpediente(formatMenssage(error.message)));
                return false;
            }else{
                intent++
                return await fetchData(url,params,method,ignoreObservation,intent)
            }
        }
    }

}
 function awaitResponse(){
    return new Promise((resolve,reject)=>{
        let num = 0
        let result = setInterval(()=>{
            num++
            console.log(store.getState())
            if(store.getState().expedientes.awaitConfirmation!='pending'){
                clearInterval(result)
                console.log('Listo')
                resolve();
            }else{
                console.log(store.getState().expedientes.awaitConfirmation)
            }
        },1000);
    })
}
async function tramitarRespuesta(response,url,params,method){
   // response.data.MensajesProcesado=  [{ "Mensaje": 'Mensaje ejemplo',IdTipo:1 }]; //todo: Forzando la respuesta.

    if(response.data.MensajesProcesado && response.data.MensajesProcesado.length>0){

        if(requireConfirmation(response.data.MensajesProcesado)){
            store.dispatch(awaitConfirmation('pending'))

            store.dispatch(fetchErrorExpediente(response.data));
            await awaitResponse();
            if(store.getState().expedientes.awaitConfirmation=='ok'){
                console.log('dijo que si')
                return await fetchData(url,params,method,1)
            }else{
                console.log('dijo que no')
                return 'ERROR2'; //pasa a ser un mensaje que impide operacion
            }
        }else{
            store.dispatch(awaitConfirmation('pending'))
            store.dispatch(fetchErrorExpediente(response.data))
            await awaitResponse();
            if(operationCancelled(response.data.MensajesProcesado)){
                return 'ERROR2'; //mensaje que impide operacion
            }else{
                return response.data
            }
        }
    }else{
        return response.data;
    }
}



  


const api = axios.create({
    baseURL: BASE_PATH,
    timeout: 60000,
    header: {
        'Token': localStorage.getItem('token')
    },
    transformRequest: [function (data, headers) {

        return JSON.stringify(data);
    }],
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Token': localStorage.getItem('token') || ''
    }
});
api.interceptors.request.use(async function (request) {
    if (request.url === "/login") return request;
    request.headers['Token'] = await localStorage.getItem('token') || '';
    return request
})
api.interceptors.response.use(function (response) {
    return response
}, async function (error) {

    const originalRequest = error.config
    if (error.request && error.request.responseURL
        && error.request.responseURL.indexOf("/login") !== -1) {
        return Promise.reject(error)
    }

    if (error.response && error.response.status === 401) {
        const redirectLogin = new Promise(async (resolve) => {
            handleLoggout();
        })
        return redirectLogin
    }
    return Promise.reject(error)
})




export const formatMenssage = (error) => (
    {
        "MensajesProcesado": [{ "Mensaje": error,IdTipo:4 }]
    }
)

const  generateStrGetParams = (objectParams)=>{
    let params = [];
    Object.keys(objectParams).map((key)=>{
        params.push(`${key}=${objectParams[key]}`)
    });
    return params.length>0? `?${params.join('&')}`:''
}
export const loginRequest = async (datos) => {
    try {
        let response = await api.post('/login', { Usuario: datos.usuario, password: datos.password });
        return response;
    }
    catch (error) {
        return formatMenssage(error.message);
    }
}
