import React, { useState, useEffect } from 'react';
import {  
  Grid,  
  FormControlLabel,
  Checkbox,Card
} from '@material-ui/core';
import Confirmation from "../Errors/confirmation";
import {Translate} from "react-localize-redux";


    const ChecksSubstituciones = ({ 
       substitucion = false,      
       comunicada = false,  
       voluntaria = false,     
       mostrarBorde=false,
       disabledChecks=false,
       onChange,
      }) => {
        const [openModal, setOpenModal] = useState(false);
        const [checkSubstitucion, setCheckSubstitucion] = useState(substitucion);        
        const [checkComunicada, setCheckComunicada] = useState(comunicada);
        const [checkVoluntaria, setCheckVoluntaria] = useState(voluntaria);
                    
      
        useEffect(() => {
            setCheckSubstitucion(substitucion);            
            setCheckComunicada(comunicada);
            setCheckVoluntaria(voluntaria);
          }, [substitucion, comunicada, voluntaria]);

        useEffect(() => {
          onChange({ checkSubstitucion, checkVoluntaria, checkComunicada });
        }, [checkSubstitucion, checkVoluntaria, checkComunicada, onChange]);
      
        const handleSubstitucionChange = () => {
          if (!checkSubstitucion) {
            setOpenModal(true);
          }
          setCheckSubstitucion(!checkSubstitucion);
          if (!checkSubstitucion==false){
            setCheckVoluntaria(false);
            setCheckComunicada(false);
          }
          //onChange && onChange({ checkSubstitucion: !checkSubstitucion, checkVoluntaria, checkComunicada });
          onChange({ checkSubstitucion:!checkSubstitucion, checkVoluntaria:(!checkSubstitucion==true) && checkVoluntaria, checkComunicada:(!checkSubstitucion==true) && checkComunicada});
        };

        const handleComunicadaChange = () => {
          setCheckComunicada(!checkComunicada);
          //onChange && onChange({ checkSubstitucion, checkVoluntaria, checkComunicada: !checkComunicada });
          onChange({ checkSubstitucion:checkSubstitucion, checkVoluntaria, checkComunicada: !checkComunicada });
        };
      
      
        const handleVoluntariaChange = () => {
          setCheckVoluntaria(!checkVoluntaria);
          //onChange && onChange({ checkSubstitucion, checkVoluntaria: !checkVoluntaria, checkComunicada });
          onChange({ checkSubstitucion:checkSubstitucion, checkVoluntaria: !checkVoluntaria, checkComunicada});
        };
      
         
        const handleModalClose = () => {           
            setOpenModal(false);           
          };


          const handleModalCloseCanceled = () => {
            handleSubstitucionChange();
            setOpenModal(false);
          };


        
          const renderAllChecks= () => {
            return (
            
            <Grid className="ml-2 mr-2" container  alignItems="center" >
            <Grid item xs="12" >
              <FormControlLabel
                control={<Checkbox disabled={disabledChecks} checked={checkSubstitucion} onChange={handleSubstitucionChange} />}
                label={<Translate id="languages.expedients.sustituirArquitecto"/>}           
              />
            </Grid>                
              <Grid item     >
                <FormControlLabel className="ml-2 mr-2 mt-0 mb-0"
                  control={<Checkbox disabled={disabledChecks} checked={checkComunicada} onChange={handleComunicadaChange} />}
                  label={<Translate id="languages.expedients.substitucionComunicada"/>}           
                />
              </Grid>
            
                
              <Grid item>
                <FormControlLabel className="ml-2 mr-2 mt-0 "
                  control={<Checkbox  disabled={disabledChecks} checked={checkVoluntaria} onChange={handleVoluntariaChange} />}
                  label={<Translate id="languages.expedients.substitucionVoluntaria"/>}                           
                />
              </Grid>                
                 </Grid>)
          }
        
      
        return (          
          <Grid>  
            {!checkSubstitucion ?              
            <Grid className="ml-2 mr-2" container  alignItems="center" >
              <Grid item xs="12" >
                <FormControlLabel
                  control={<Checkbox disabled={disabledChecks} checked={checkSubstitucion} onChange={handleSubstitucionChange} />}
                  label= {<Translate id="languages.expedients.sustituirArquitecto"/>}
                />
              </Grid>
            </Grid>
            :          
             (mostrarBorde?
              <Card>
             {renderAllChecks()}
             </Card>:                               
                renderAllChecks()
              )           
                 
            }
             
      <Confirmation openConfirmation={openModal}
                    title={'Expediente de substitución' }                
                    confirmationMessage={<Translate id="languages.messages.expedienteSubstitucion"/>}
                    acceptCancel={true}
                    aceptConfirmation={handleModalClose}
                    declineConfirmation={handleModalCloseCanceled}   
                    botonConfirmarTexto={'Continuar'}
                    maxWidth={"md"}
                    confirmationCheckItem={true}
                    confirmationCheckItemText={'ENTERADO'}
                    />

     
    
      </Grid>     
  
  );
};

export default ChecksSubstituciones ;