import React, { Component } from "react";
import moment from "moment";


class FechaRenderer extends Component {
  constructor(props) {
    super(props);
    
  }
    
  render() {    
    
    return (<div >                                                       
                {this.props.data.Fecha_Tramitacion!=null?moment(this.props.data.Fecha_Tramitacion).format('DD/MM/YY'):moment(this.props.data.Fecha_Entrada).format('DD/MM/YY')}                
    </div>
    );
  }
};
export default FechaRenderer;