import React, { Component } from "react";
import {iconEstados} from "../../api/index"
import '../Tramitaciones/estados.css';

class TituloTrabajoRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estadoName: this.props.value,
      idTrabajo: this.props.data.Id_Trabajo,
      estado: this.props.data.Estado,
      titulo:this.props.data.Titulo_Trabajo,
      color: '000',
      //icono: './IconosEstados/default.png',
    }
  }

  getCleanedString(cadena) {
    if (cadena == null)
      return '';   
    cadena = cadena.toLowerCase();   
    return cadena;
  }
 

  estadoTitulo = (estado,titulo) => {
    let estadoLimpio = this.getCleanedString(estado);
    if (estadoLimpio !== '')
        return (
            <div /*className={nombreLimpio}*/>
                {<img alt={200} src={iconEstados(estadoLimpio)} />}
                {titulo}
            </div>);
  }

 
  render() {
    return (<div className="estados">
      {this.estadoTitulo(this.state.estado,this.state.titulo)}      
    </div>
    );
  }
};
export default TituloTrabajoRenderer;
