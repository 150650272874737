import React, { Component } from 'react';
import { Grid,Paper } from '@material-ui/core';
import FichaExpediente from './fichaExpediente';
import ListaTrabajos from './listaTrabajos';
import TipoExpediente from './tipoExpediente';
import TemporalFolder from '../ProyectoEjecucion/TemporalFolder'
import Substituciones from "./Substituciones"
import {isMobile} from "../../../../api/index";

//import TrabajoEjecucion from "../ProyectoEjecucion";
class ExpedienteGeneral extends Component {





    render() {

        const disabledEdit = this.props.expediente && this.props.expediente.Trabajos
        && this.props.expediente.Trabajos.some(x => x.Id_Estado >= 3);

        const esSubstitucion = this.props.expediente.Expediente[0].Substitucion;
     
      
        return (
            <Grid container spacing={0}>
               {/* 
               md na pantalla de expediente non necesitamos o arbol <Grid item md={6} xs={12} >

                <FichaExpediente disable={false} expediente={this.props.expediente}
                        sourceExpediente={this.props.expediente.Expediente.length > 0 ? this.props.expediente.Expediente[0] : {}}
                        updateExpediente={(expediente) => this.props.updateExpediente(expediente)} 
                       />

                    <TipoExpediente expediente={this.props.expediente} 
                    sourceExpediente={this.props.expediente.Expediente.length > 0 ? this.props.expediente.Expediente[0] : {}} 
                    />                 

                </Grid>                
                */}


            <Grid item md={4} xs={12} >

              <FichaExpediente disable={false} expediente={this.props.expediente}
                    sourceExpediente={this.props.expediente.Expediente.length > 0 ? this.props.expediente.Expediente[0] : {}}
                    updateExpediente={(expediente) => this.props.updateExpediente(expediente)} 
                    />        

                {esSubstitucion==1 || !disabledEdit ? 
                     <Substituciones disable={false} expediente={this.props.expediente}
                    sourceExpediente={this.props.expediente.Expediente.length > 0 ? this.props.expediente.Expediente[0] : {}}
                    updateExpediente={(expediente) => this.props.updateExpediente(expediente)}                     
                    />   : null
                    }
                        
                    
            </Grid>  
                  

            <Grid item md={4} xs={12} >
                <TipoExpediente expediente={this.props.expediente} 
                    sourceExpediente={this.props.expediente.Expediente.length > 0 ? this.props.expediente.Expediente[0] : {}} 
                    /> 

            </Grid>



                <Grid item md={4/*6*/} xs={12} >
                <ListaTrabajos
                        changeEstructura={(idTrabajo,idTipoTrabajo) => this.props.changeEstructura(idTrabajo,idTipoTrabajo)}
                        expediente={this.props.expediente} 
                        />


                    {/*isMobile &&
                    <Grid item xs={12} className={"p-1 "}>
                        <p></p>
                    </Grid>*/
                    }

                    
                {/*no tiene sentido en esta pantalla el directorio temporal
                <div className={!isMobile() ? "m-3" : "m-1"}>
                        <TemporalFolder collapseTemporalFolder= {true} notInFolderPlace={true}  dragging={(state) => this.props.dragging(state)}   expediente={this.props.expediente} }/>
                </div>*/}
                </Grid>
            </Grid>
        );
    }
}

export default ExpedienteGeneral;