import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {
    withStyles,
    Paper,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    CircularProgress,
    Grid,
    TextField,
    FormControl,  
    Button,
    Dialog,
    DialogTitle,
    DialogContent, DialogActions
} from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {fetchErrorExpediente} from "../../../../../actions/expedientes";
import ApiCoag from "../../../../../api/ApiCoag";


const styles = theme => ({
    withoutRadius: {
        borderRadius: 0
    }
});




class DTOtros extends Component {
    constructor() {
        super();
        this.state = {
            expandSectionTempFile: false,
            loadingDatosTecnicos: true,
            respuestaCalculo: false,
            tiposUso: [],
            showDetalles:false,
            fetchingSave:false,
            isModified:false,
            allowSave:false,
            loaded:false           
        }
    }

    componentDidMount() {
        this.loadDatosTecnicos()

    }

    //async UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    //     if (this.state.loaded && this.state.datosTecnicos.Caracteristicas.Es_Trabajo_Modificado_Requerido_Administracion!=this.props.work.Es_Trabajo_Modificado_Requerido_Administracion)
    //     {await this.loadDatosTecnicos()}        
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            if (this.state.loaded && this.state.datosTecnicos.Caracteristicas.Es_Trabajo_Modificado_Requerido_Administracion!=this.props.work.Es_Trabajo_Modificado_Requerido_Administracion)
                {this.loadDatosTecnicos()}        
            }
        }

    async loadDatosTecnicos() {
        await this.setState({loadingDatosTecnicos: true})

        let datosTecnicosTrabajo = await ApiCoag.expedientes.trabajo.datosTecnicos.obtener(this.props.work.Id_Expediente, this.props.work.Id_Trabajo);
        datosTecnicosTrabajo.Caracteristicas = datosTecnicosTrabajo.Caracteristicas ? datosTecnicosTrabajo.Caracteristicas[0] : {"Id_Tipo_Calculo": 2}
        datosTecnicosTrabajo.Parametros = datosTecnicosTrabajo.Parametros ? datosTecnicosTrabajo.Parametros[0] : {"Demolicion_Volumen": null}
        datosTecnicosTrabajo.DatosEstadisticos = datosTecnicosTrabajo.DatosEstadisticos ? datosTecnicosTrabajo.DatosEstadisticos[0] : {}

        await this.setState({
            datosTecnicos: datosTecnicosTrabajo,
            loadingDatosTecnicos: false,
            allowSave:false,
            isModified:false,
            loaded:true            
        })
        if (datosTecnicosTrabajo.Caracteristicas.SeRequiere_IntroducirDatosTecnicos == 0) {
            this.setState({
                respuestaCalculo: {Precios: datosTecnicosTrabajo.Precios}
            })
        }

    }
    async saveDatosTecnicos(){
        let {datosTecnicos} = this.state;
        let {work} = this.props;


        await this.setState({
            fetchingSave:true
        })

        this.validateDatosEstadisticos()

        let response = await ApiCoag.expedientes.trabajo.datosTecnicos.guardar(work.Id_Expediente,work.Id_Trabajo,datosTecnicos);
        if(response.Parametros){
            let datosTecnicosTrabajo = response;
            datosTecnicosTrabajo.Caracteristicas = datosTecnicosTrabajo.Caracteristicas[0]
            datosTecnicosTrabajo.Parametros = datosTecnicosTrabajo.Parametros[0]
            datosTecnicosTrabajo.DatosEstadisticos = datosTecnicosTrabajo.DatosEstadisticos[0]
            await this.setState({
                datosTecnicos: datosTecnicosTrabajo,

            })
        }
        this.setState({
            'respuestaCalculo':false,
            fetchingSave:false
        })
        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
            this.props.fetchErrorExpediente(response)


        }else{
            this.setState({
                'respuestaCalculo':response,
                fetchingSave:false,
                isModified:false,
                allowSave:false                
            })

        }


    }

    valorUnidadeComplexidade(){
        if (this.state.datosTecnicos && this.state.respuestaCalculo && this.state.respuestaCalculo.Precios[0].Precio_Unitario_Unidad_Complejidad)
            return this.state.respuestaCalculo.Precios[0].Precio_Unitario_Unidad_Complejidad
        else
            return ''            
    }

    resultadoCalculo() {
        let {classes} = this.props;
      
        if (this.state.respuestaCalculo != false) {


            return (
                <Grid container style={{backgroundColor: '#f9f9f9', padding: 48, paddingTop: 12, paddingBottom: 12}}>
                    <Grid item xs={12}>
                    <Translate id="languages.datosTecnicos.complejidadFinalYPrecio" />
                    </Grid>

                        {/*
                    <Grid container style={{backgroundColor: '#f9f9f9'}}>
                        <Grid item xs={6}>
                            <label className='MuiFormLabel-root-214' style={{
                                marginTop: 17,
                                marginBottom: -12,
                                color: 'rgba(0, 0, 0, 0.54)',
                                padding: 0,
                                fontSize: 10,
                                fontFamily: 'Montserrat',
                                lineHeight: 1
                            }}>COMPLEJIDAD</label><br/>
                            {this.state.datosTecnicos.Precios[0].Complejidad}
                        </Grid>
                        <Grid item xs={6}>
                            <label className='MuiFormLabel-root-214' style={{
                                marginTop: 17,
                                marginBottom: -12,
                                color: 'rgba(0, 0, 0, 0.54)',
                                padding: 0,
                                fontSize: 10,
                                fontFamily: 'Montserrat',
                                lineHeight: 1
                            }}>PRECIO (SIN INVA)</label><br/>
                            {parseFloat(this.state.datosTecnicos.Precios[0].Precio).toFixed(2)} €
                        </Grid>
                        </Grid> */}


                    <Grid container style={{backgroundColor: '#f9f9f9'}}>
                        <Grid item xs={6}>
                            <label className='MuiFormLabel-root-214' style={{
                                marginTop: 17,
                                marginBottom: -12,
                                color: 'rgba(0, 0, 0, 0.54)',
                                padding: 0,
                                fontSize: 10,
                                fontFamily: 'Montserrat',
                                lineHeight: 1
                            }}><Translate id="languages.datosTecnicos.complejidadFinal" /></label><br/>
                            {parseFloat(this.state.datosTecnicos.Precios[0].Complejidad_Final).toFixed(2)}
                        </Grid>
                        <Grid item xs={6}>
                            <label className='MuiFormLabel-root-214' style={{
                                marginTop: 17,
                                marginBottom: -12,
                                color: 'rgba(0, 0, 0, 0.54)',
                                padding: 0,
                                fontSize: 10,
                                fontFamily: 'Montserrat',
                                lineHeight: 1
                            }}><Translate id="languages.datosTecnicos.precioFinal" /></label><br/>
                            {  parseFloat(this.state.datosTecnicos.Precios[0].Precio_Final).toFixed(2)} €
                        </Grid>
                    </Grid>
                    <Grid container style={{backgroundColor: '#f9f9f9'}}>
                        <Grid item xs={12}>
                            <label className='MuiFormLabel-root-214' style={{
                                marginTop: 17,
                                marginBottom: -12,
                                color: 'rgba(0, 0, 0, 0.54)',
                                padding: 0,
                                fontSize: 11,
                                fontFamily: 'Montserrat',
                                lineHeight: 1
                            }}>{this.props.translate("languages.datosTecnicos.precioFinalCoeficiente") + ' ' + this.valorUnidadeComplexidade()}</label>

                        </Grid>
                        <Grid item xs={12}>

                            <Button
                                color="primary" className={classes.button}
                                onClick={() => this.setState({showDetalles: true})}
                            ><Translate id="languages.datosTecnicos.mostrarDetallesCalculo" />
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            )
        }
    }


  
    renderContenidoAntiguo() {
        let sHtmlStyle = "<style type='text/css'> .mdap table{border:1px solid  #A9A9A9; border-collapse:collapse;} .mdap table th{background-color:#F0F0F0;color:black;font-weight:normal} .mdap table th, .mdap table td{border-bottom:1px solid #A9A9A9; padding:0.2em 0.4em; empty-cells:show;}</style>"  
        sHtmlStyle += "<div class='mdap'>"

        return(
            <Grid container >
                    <Grid item xs={12}>
                        <p></p>
                        
            <div dangerouslySetInnerHTML={{ __html: sHtmlStyle+this.state.datosTecnicos.Parametros.Contenido +"</div>"}}></div>
            </Grid>
            </Grid>
         
        );
      }

  

    detalles() {

        if (this.state.showDetalles) {
            let {datosTecnicos} = this.state
            return (<div>
                <Dialog open={this.state.showDetalles}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                    <Translate id="languages.datosTecnicos.detallesCalculo" />
                    </DialogTitle>

                    <DialogContent>
                        <Grid container spacing={8}>
                            {
                                datosTecnicos.DetallesCalculo.map((resp, p) => (
                                    <Grid item md={12}>
                                        {resp.Parametro}:<br/> {resp.Valor_Parametro}
                                    </Grid>
                                ))
                            }
                        </Grid>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={async () => {
                            this.setState({showDetalles: false});

                        }} variant="contained"
                                color="primary" autoFocus style={{height: 30, minHeight: 30}}
                                className="p-0">
                            Aceptar

                        </Button>

                    </DialogActions>
                </Dialog>
            </div>);
        }

    }

    validateDatosEstadisticos (){

        let datosTecnicos = this.state.datosTecnicos;     
        
        let valor = datosTecnicos['DatosEstadisticos'].PEM;                 
        datosTecnicos['DatosEstadisticos'].PEM = (valor == '' || valor == null  || valor == 'NaN') ? null:  parseFloat(valor ).toFixed(2);

        valor = datosTecnicos['DatosEstadisticos'].Numero_Viviendas;
        datosTecnicos['DatosEstadisticos'].Numero_Viviendas= (valor == '' || valor == null  || valor == 'NaN') ? null:   parseInt(valor );

        valor = datosTecnicos['DatosEstadisticos'].Numero_Viviendas_Afectadas;
        datosTecnicos['DatosEstadisticos'].Numero_Viviendas_Afectadas = (valor == '' || valor == null  || valor == 'NaN') ? null:    parseInt(valor );
                
        this.setState({
             datosTecnicos
            })         
        
    }


    modifyDatosEstadisticos = (propertyName) => event => {
        let datosTecnicos = this.state.datosTecnicos;
        this.setState({isModified:true})
        switch (propertyName) {

            default:

                datosTecnicos['DatosEstadisticos'][propertyName] = event.target.type == 'checkbox' ? event.target.checked ? 1 : 0 : parseFloat(event.target.value);
                this.setState({
                    datosTecnicos
                })

                break;

        }
    }

    renderDatosEstadisticos(){
        let {datosTecnicos} = this.state;
        let {classes} = this.props;
        return (
            <Grid container>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '100%', paddingRight: '10%'}}>
                        <TextField
                            id="Numero_Viviendas"
                            label={<Translate id="languages.datosTecnicos.viviendas" />}                 
                            disabled={parseInt(datosTecnicos.Caracteristicas.SePuede_Editar)!=1}
                            type={'number'}
                            inputProps={{ min: "0",  step: "1" }}
                            value={datosTecnicos.DatosEstadisticos.Numero_Viviendas == null ? '': datosTecnicos.DatosEstadisticos.Numero_Viviendas}
                            onChange={this.modifyDatosEstadisticos('Numero_Viviendas')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>              
                <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '100%', paddingRight: '10%'}}>
                        <TextField
                            id="Numero_Viviendas_Afectadas"
                            label={<Translate id="languages.datosTecnicos.viviendasAfectadas" />}
                            type={'number'}
                            //inputProps={{ min: "0",  step: "0.1" }}
                            disabled={parseInt(datosTecnicos.Caracteristicas.SePuede_Editar)!=1}
                            value={datosTecnicos.DatosEstadisticos.Numero_Viviendas_Afectadas == null? '':datosTecnicos.DatosEstadisticos.Numero_Viviendas_Afectadas}
                            onChange={this.modifyDatosEstadisticos('Numero_Viviendas_Afectadas')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                  <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '100%', paddingRight: '10%'}}>
                        <TextField
                            id="pem"
                            label="PEM"
                            type={'number'}
                            inputProps={{ min: "0",  step: "1" }}
                            disabled={parseInt(datosTecnicos.Caracteristicas.SePuede_Editar)!=1}
                            value={datosTecnicos.DatosEstadisticos.PEM == null?'':datosTecnicos.DatosEstadisticos.PEM}
                            onChange={this.modifyDatosEstadisticos('PEM')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                {/* md si no se introducen superficies no hace falta
                <Grid item xs={3}>
                    <FormControl className={classes.formControl2}
                                 style={{width: '100%', paddingRight: '10%'}}>
                        <TextField
                            id="Superficie"
                            label="SUPERFICIE TOTAL"
                            disabled={true}
                            type={'number'}
                            inputProps={{ min: "0",  step: "0.1" }}
                            value={datosTecnicos.DatosEstadisticos.Superficie}
                            onChange={this.modifyDatosEstadisticos('Superficie')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                        */}
                

            </Grid>
        )

    }
    render() {
        let {classes} = this.props;
        
        return (
            <div style={{marginTop: 10}}>
                {
                    this.state.loadingDatosTecnicos && this.state.expandSectionTempFile == false  ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                        <Paper
                            style={{borderColor: '#cecece', borderWidth: 1, marginBottom: 10}}>
                            <ExpansionPanel expanded={this.state.expandSectionTempFile}
                                            onChange={() => this.setState({expandSectionTempFile: !this.state.expandSectionTempFile})}>
                                <ExpansionPanelSummary style={{paddingLeft: 8}} expandIcon={<ExpandMoreIcon/>}>
                                    <div className="d-flex justify-content-between align-items-center"
                                         style={{width: "100%", marginLeft: 10}}>
                                        <div>
                                            <Typography variant='button' style={{float: "left"}}>
                                            <Translate id="languages.datosTecnicos.datosTecnicosPrecio" />
                                            </Typography>
                                            {this.state.datosTecnicos.Caracteristicas.SeRequiere_IntroducirDatosTecnicos == 1 && this.state.datosTecnicos.Caracteristicas.Datos_Tecnicos_Precio_Pendientes == 1 &&
                                            <Typography variant='button'
                                                        style={{color: '#ffc107', float: "left", marginLeft: 5}}><Translate id="languages.generalButton.sinCalcular" /></Typography>
                                            }
                                        </div>

                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{padding: "8px 0px 0px"}}>
                                {this.state.datosTecnicos.Caracteristicas.Id_Tipo_Calculo <0  ? 

                                <div  style={{ fontSize:12 , marginLeft: 24, marginRight: 24, marginTop: -25, marginBottom: 24}}>   
                                { this.renderContenidoAntiguo() }        
                                </div>
                                :
                                    <div style={{marginLeft: 24, marginRight: 24, marginTop: -25, marginBottom: 24}}>

                                        <div style={{marginLeft: -24, marginRight: -24}}>
                                            {
                                                this.resultadoCalculo()
                                            }
                                            {
                                                this.detalles()
                                            }

                                        </div>
                                        {
                                            this.renderDatosEstadisticos()
                                        }
                                        {this.state.datosTecnicos.Caracteristicas.SePuede_Editar ? 
                                             <Grid container>

                                                <Grid item xs={12} style={{textAlign:"right",paddingRight:20, marginBottom:0,  paddingTop: 10 }}>

                                                    <Button
                                                    disabled={!this.state.isModified}
                                                        color="primary" className={classes.button}
                                                        onClick={()=>this.loadDatosTecnicos()}>Cancelar
                                                    </Button>
                                                    {
                                                        this.state.fetchingSave?null:<Button
                                                            variant="contained"
                                                            disabled={this.state.fetchingSave||!this.state.isModified}
                                                            color="primary" className={classes.button}
                                                            onClick={()=>this.saveDatosTecnicos()}>Guardar
                                                        </Button>
                                                    }

                                                    {this.state.fetchingSave && <CircularProgress size={24} className={classes.buttonProgress} />}


                                                </Grid>
                                            </Grid>
                                            : null
                                        }

                                    </div>
                                }

                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Paper>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps =
    {
        fetchErrorExpediente: fetchErrorExpediente
    };
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(DTOtros)));