import React, {Component} from 'react';
import {connect} from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {
    withStyles,
    Paper,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    CircularProgress,
    Grid,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Button,
    Dialog,
    DialogTitle,
    DialogContent, DialogActions, Snackbar, IconButton
} from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {fetchErrorExpediente} from "../../actions/expedientes";
import {Check, Close} from "@material-ui/icons";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {Container} from "reactstrap";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import Table from "@material-ui/core/Table";
import {grey} from "@material-ui/core/colors";
import ApiCoag from "../../api/ApiCoag";


const styles = theme => ({
    withoutRadius: {
        borderRadius: 0
    }, paper: {
        padding: 24,
        width: "100%"
    },
    margin: {
        marginTop: 30
    },
    formControl1: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        width: "60%"
    },
    formControl2: {
        width: 150, marginRight: 16
    },
    formControl: {
        width: "100%"
    },
    textFieldInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
    divider: {

        borderBottom: "1px solid #ced4da"


    },
    button: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    close: {
        padding: theme.spacing.unit / 2,
    },
    marginPanel: {
        margin: '15px 0px'
    },
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 200,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    subtitle: {
        padding: "10px 20px"
    },
    tableBodyHeight: {
        minHeight: 100
    },
    headHeight: {
        height: 40
    },
    fab: {
        margin: theme.spacing.unit,
        marginTop: -25,
        position: 'absolute',
        marginLeft: '7%'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300,
        textAlign: 'left',
        marginTop: 5
    },
    centerText: {
        textAlign: "center"
    },

    paddingButtons: {
        padding: 15
    },

    resultPanel: {
        padding: "20px 30px"
    },
    subtitleData: {
        marginTop: 10,
        fontWeight: "normal"
    },
    usericon: {
        margin: theme.spacing.unit,
        fontSize: 150,
    },
    mt0: {
        marginTop: -10
    },
    iconoption: {
        margin: theme.spacing.unit,
        color: theme.palette.text.primary
    },
    buttonEdit: {
        border: "1px solid",
        padding: 8,
        margin: 4
    },
});
const CustomTableHead = withStyles(theme => ({
    head: {
        backgroundColor: grey[100],
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class TramitacionEnLote extends Component {

    constructor() {
        super();
        this.state = {
            expandSectionTempFile: false,
            loadingComponent: true,
            trabajos:[],
            currentExpediente:false,


        }
    }

    componentDidMount() {
        this.fetchExpediente();

    }

    handleClose() {

    }

    async fetchExpediente() {


        let response = await ApiCoag.expedientes.getDatosGenerales(this.props.match.params.id);




        //3- Success
        if (response) {
            let expediente = response;
            let currentExpediente = expediente.Expediente.length > 0 ? expediente.Expediente[0] : null;
            let activeTrabajo = this.props.match.params.idTrabajo
                ? this.props.match.params.idTrabajo
                : null;
            await this.setState({
                expediente: expediente,
                currentExpediente: currentExpediente,
                idTrabajoActivo: activeTrabajo
            });
            await this.loadFasesTrabajos(currentExpediente)
        }

        this.setState({
            loadingComponent: false
        })


    }
    countItems = (relations) => {
        return relations.reduce((prev, item) => prev + item.trabajos.length, 0);
    }
    async loadFasesTrabajos(currentExpediente) {
        try {


            let fases = await ApiCoag.tipos.guia.fasesTrabajos(currentExpediente.Id_Tipo_Grupo_Tematico_Actual,
                currentExpediente.Id_Tipo_Autorizacion_Municipal_Actual);

            if (fases && fases.FasesTrabajos) {
                let result = [];

                for (let i = 0; i < fases.FasesTrabajos.length; i++) {
                    let trabajo = fases.FasesTrabajos[i];
                    if (result.some(x => x.Id_Tipo_Fase === trabajo.Id_Tipo_Fase)) {
                        let index = result.findIndex(x => x.Id_Tipo_Fase === trabajo.Id_Tipo_Fase);
                        result[index].trabajos.push(trabajo);
                    }
                    else {
                        result.push({
                            fase: trabajo.Fase,
                            Id_Tipo_Fase: trabajo.Id_Tipo_Fase,
                            trabajos: [trabajo]
                        })
                    }
                }


                this.setState({ trabajos: result,  isLoading: false });

            }
        }
        catch (error) {
            console.log(error)
            this.setState({ isLoading: false });
        }
    }

    renderFirstLine() {
        let {currentExpediente} = this.state;
        let {classes} = this.props;
        return (
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl1} style={{width: '90%'}}>
                        <TextField
                            id="tipo_expediente"
                            label={"Tipo de expediente"}
                            value={currentExpediente.Descripcion_Encomenda_Actual}
                            disabled={true}
                            margin="normal"

                            InputLabelProps={{
                                shrink: true,
                            }}

                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <FormControl className={classes.formControl1} style={{width: '95%'}}>
                        <TextField
                            id="firma_requerida"
                            label={"FIRMA REQUERIDA"}
                            // value={currentExpediente.Descripcion_Encomenda_Actual}

                            margin="normal"

                            InputLabelProps={{
                                shrink: true,
                            }}

                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <FormControl className={classes.formControl1} style={{width: '95%'}}>
                        <TextField
                            id="tipo_tamite"
                            label={"TIPO DE TRÁMITE"}
                            // value={currentExpediente.Descripcion_Encomenda_Actual}

                            margin="normal"

                            InputLabelProps={{
                                shrink: true,
                            }}

                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <FormControl className={classes.formControl1} style={{width: '95%'}}>
                        <TextField
                            id="trabajos_tramitar"
                            label={"No. TRABAJOS A TRAMITAR"}
                            // value={currentExpediente.Descripcion_Encomenda_Actual}
                            type={'number'}
                            margin="normal"

                            InputLabelProps={{
                                shrink: true,
                            }}

                        />
                    </FormControl>
                </Grid>

            </Grid>
        )
    }
    renderWorks(){
        let {expediente,trabajos} = this.state;

        let {classes} = this.props;
        return (
            <Grid container style={{}}>
                <Grid item xs={12} >
                    <Typography variant={"display3"} style={{marginTop:20,marginBottom:10,fontSize:13}}>
                        <Translate id="languages.tramitacionEnLote.trabajosATramitar"/>
                    </Typography>
                        {
                            trabajos.map((item,pos)=>(
                                <div style={{marginTop:20}}>
                                    <div style={{width:'100%',border: "1px solid #ced4da"}}>
                                        <Typography variant={"display3"} style={{paddingLeft:12,marginTop:5,marginBottom:5}}>{item.fase}</Typography>
                                    </div>
                                    <Table className={classes.table}>

                                        <TableHead>
                                            <TableRow className={classes.headHeight}>
                                                <CustomTableHead ></CustomTableHead>
                                                <CustomTableHead >TÍTULO TRABAJO</CustomTableHead>
                                                <CustomTableHead>OBLIGATORIO </CustomTableHead>
                                                <CustomTableHead >COMP. VISADO </CustomTableHead>
                                                <CustomTableHead >COMP. REGISTRO</CustomTableHead>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={classes.tableBodyHeight}>
                                            {
                                                item.trabajos.map((trabajo,pos)=>

                                                    <TableRow className={classes.headHeight}>
                                                        <TableCell style={{padding:'0px 5px'}}></TableCell>
                                                        <TableCell style={{padding:'0px 5px'}}>{trabajo.Trabajo_Titulo}</TableCell>
                                                        <TableCell style={{padding:'0px 5px'}}>{trabajo.Obligatorio?trabajo.Obligatorio:'-'}</TableCell>
                                                        <TableCell style={{padding:'0px 5px'}}>{trabajo.comvisado?trabajo.comvisado:'-'}</TableCell>
                                                        <TableCell style={{padding:'0px 5px'}}>{trabajo.comrexistro?trabajo.comrexistro:'-'}</TableCell>


                                                    </TableRow>

                                                )

                                            }
                                        </TableBody>


                                    </Table>
                                </div>

                            ))
                        }

                </Grid>
            </Grid>
        )
    }

    render() {
        let {classes} = this.props;

        return (
            <Container className={classes.margin}>
                <BreadcrumbsItem to={'/nuevo-expediente'}>
                    <Translate id="languages.tramitacionEnLote.titulo"/>
                </BreadcrumbsItem>
                <Grid container >
                    <Grid item xs={12}>
                        {
                            this.state.loadingComponent ?
                                <div className="text-center">
                                    <CircularProgress/>
                                </div> :
                                <Paper className={classes.paper}>
                                    <Grid container spacing={12}>
                                        <Grid item xs={12} className={classes.divider}>
                                            <Typography variant={"h1"}>Nueva Tramitación en lote</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.divider}>
                                            {
                                                this.renderFirstLine()
                                            }
                                        </Grid>
                                        <Grid item md={6}>
                                            {
                                                this.renderWorks()
                                            }
                                        </Grid>
                                    </Grid>
                                </Paper>

                        }

                    </Grid>

                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps =
    {
        fetchErrorExpediente: fetchErrorExpediente
    };
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(TramitacionEnLote)));