import React, {Component} from 'react';
import {Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import { withLocalize } from 'react-localize-redux';
import { Translate } from "react-localize-redux";
import { fetchocultaModal, fetchCambiaStadoModalFalse } from '../../actions/interfaz/index'
import {fetchBuscador } from '../../actions/expedientes/index'
import {fetchSelect, fetchSuscepAcciones } from '../../actions/usuarios/index'
import './styles.css';
import ModalDatos  from '../Busquedas/ModalDatos';
import ModalDatosRenuncias  from '../Busquedas/ModalDatosRenuncias';
import Resultados  from '../Busquedas/Resultados';
import Buscador  from '../Busquedas/Buscador';
//import TablaBusquedaArquitectos  from '../Busquedas/TablaBusquedaArquitectos';
import {Divider,  CircularProgress  } from "@material-ui/core";

import {Close} from "@material-ui/icons";
import {fetchErrorExpediente, formatMenssage} from "../../actions/expedientes";
import {ltrim,rtrim,isMobile} from "../../api/index";
import ApiCoag from "../../api/ApiCoag";
import RenunciasResumen  from "../Busquedas/RenunciasResumen";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    modal: {
        zIndex: 999,        
        position: "absolute",
        right: 0,
        top: 0,
        backgroundColor: "white",
        border: "grey solid 0.5px",
            
               
        minHeight: "880px",        
        flexGrow: 1,

        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
        [theme.breakpoints.up('xs')]: {
            width: "47%"
        },       
               
    },
    modalMobile: {
        zIndex: 999,
        position: "absolute",
        right: 0,
        top: 0,
        backgroundColor: "white",
        border: "grey solid 0.5px",
        height: '100%', /*100 % 900px"*/
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
        [theme.breakpoints.up('xs')]: {
            width: "100%"
        },
    },
    modalRenuncias: {
        zIndex: 999,
        position: "absolute",
        overflowY: 'scroll',   
        right: 0,
        top: 0,
        backgroundColor: "white",
        border: "grey solid 0.0px",
        height: '100%', /*100 % 900px"*/
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
        [theme.breakpoints.up('xs')]: {
            width: "100%"
        },
    },
  
   divRenuncias:{
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto'
   },
    formControl: {
        width: "100%"
    }
});
class Modalacciones extends Component { 
    
    constructor(props) {
        super(props);
        this.state = {
            filtro: '',
            tablaArquitectos: false,
            text: "",
            types: "expedientes",
            loading: false,
            loadingSearch: false,
            tipoListado:'listado',     
            renuncias:false,
            renunciando:false,
            data:[],
            colSoc:0,
            mostrarRenunciasResumen:false                   
                };
         this.prevTypes = 'expedientes';

               
      }

    async componentDidMount(){
        this.props.fetchSelect(this.state.types);
        this.setState({loading: true});
        try {
            if(this.props.modal){
                await this.props.fetchBuscador(this.state.text, this.state.types, 1, 10000 );
            }
            else {
                await this.props.fetchSuscepAcciones(this.state.text, this.props.idAccion, 1, 10000);
                //await this.props.fetchBuscador(this.state.text, this.state.types, 1, 10000 );
            }
            this.setState({loading: false});
            this.handleLogEnBusqueda()
        }
        catch (e) {
            this.props.fetchErrorExpediente(formatMenssage(e.message));
            this.setState({loading: false});
        }

    }


    componentDidUpdate(prevProps, prevState) {                
          this.prevTypes= prevState.types; //esto para controlar si cambiamos de busqueda de expedientes a trabajos                
      }
   
    

        

    async handleSearch(text) {
        this.setState({loadingSearch: true});
        try {
            if(this.props.modal){
                  
                    await this.props.fetchBuscador(text, this.state.types);

            }
            else {
                if (this.props.idAccion==6) 
                    this.setState({colSoc:this.props.idColegiado});
                
                await this.props.fetchSuscepAcciones(text, this.props.idAccion, 1, 10000);
            }
            this.setState({loadingSearch: false})
           
        }
        catch (e) {
            this.props.fetchErrorExpediente(formatMenssage(e.message));
            this.setState({loadingSearch: false});
        }

    }

   
    async handleCancel() {
        this.setState({text: "",loadingSearch: true});
        try {
            await this.props.fetchBuscador("", this.props.selectBuscador);
            this.setState({loadingSearch: false});
        }
        catch (e) {
            this.props.fetchErrorExpediente(formatMenssage(e.message));
            this.setState({loadingSearch: false});
        }
    }

    async handleSelectChange(e){ 
        let value = e.target.value;        
        this.setState({types: value,loadingSearch: true});
        if (this.state.types === "trabajos"){
            this.setState({types: value,loadingSearch: true,tipoListado:'listado'});
        }
        else
        {
            this.setState({types: value,loadingSearch: true});
        }

        if(value === 'colegiados' || value === 'promotores' || value === 'otrosAgentes'){
            this.setState({tablaArquitectos: true,itemSelected:value})
        }
        else {
            /* md se añade valor seleccionado */
            this.setState({tablaArquitectos: false,itemSelected:value})
        }

       // this.props.fetchSelect(value);

        await this.props.fetchBuscador(this.state.text, value);
        
        this.setState({loadingSearch: false})       

    }



    handleClose(){
        this.setState({closing: true})
        this.props.fetchocultaModal();
        this.props.fetchCambiaStadoModalFalse();
       
    }

    handleChangeText(text){
        this.setState({text:text});
    }
        
    

    /*handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            let filtro=ltrim(rtrim(this.state.text));
            this.setState({
                text: filtro,
            });
            this.handleSearch(filtro)
        }
    }*/

    handleBlur = name => event => {
        this.setState({
            [name]: ltrim(rtrim(event.target.value)),
        });
    }


    async handleLogEnMapa() {
        let response=null;
          
        let data = {
                 "Tipo": 102,
                 "Identificador": "",
                 "Descripcion": ""                  
             }     
        response=ApiCoag.usuario.postUsuarioLogAccion(data)                                
        }
    
        async handleLogEnBusqueda() {
            let response=null;
              
            let data = {
                     "Tipo": 101,
                     "Identificador": "",
                     "Descripcion": ""                  
                 }     
            response=ApiCoag.usuario.postUsuarioLogAccion(data)                                
            }    


    async handleListadoMapa(tipo) {
        if (this.state.tipoListado!=tipo){
           this.setState({tipoListado:tipo});       
            if (tipo=='mapa')  
            {this.handleLogEnMapa()
         }
        }              
    }

    handleChangeColSoc(value){
        this.setState({colSoc:value});       
    }

    handleMostrarRenunciasResumen(valor,datos){
        this.setState({mostrarRenunciasResumen:valor,datosResumen:datos});
    }

    handleCloseResumen(){
        this.setState({mostrarRenunciasResumen:false});               
    }


  obtenerLocalizacionesEnMapa(datos){
    return datos.filter(item => item.Latitud !== null && item.Longitud !== null && item.Latitud!=0 && item.Longitud!=0).map(item => [item.Latitud, item.Longitud])
  }

  obtenerDatosEnMapa(datos){
    return datos.filter(item => item.Latitud !== null && item.Longitud !== null).map(item => [item.Id_Expediente,item.Expediente_Codigo, item.Titulo,item.Emplazamiento,item.Concello,item.Promotor,item.Georeferencia])   
  }



  renderBuscadorYResultadosGeneral(modal,divHeight,divWidth,localizacionesMapa,datosMapa)  {
    return(<div>
                                      <Buscador
                                            modal={this.props.modal}
                                            tituloModal={this.props.tituloModal}
                                            descripcion={this.props.descripcion}
                                            text={this.state.text}
                                            types={this.state.types}
                                            loadingSearch={this.state.loadingSearch}
                                            handleClose={()=>this.handleClose()}
                                            handleSearch={(text)=>this.handleSearch(text)}
                                            handleChangeText={(text)=>this.handleChangeText(text)}
                                            handleSelectChange={(event)=>this.handleSelectChange(event)}
                                            _handleKeyDown={(e)=>this.handleKeyDown(e)}
                                            idAccion={this.props.idAccion}    />                                                                     
                                     
                                    <Divider/>      
                                                                      
                                 <Resultados 
                                        types={this.state.types}
                                        loadingSearch={this.state.loadingSearch}
                                        tipoListado={this.state.tipoListado}
                                        datosTablaResult={this.props.datosTablaResult}
                                        datosTabla={this.props.datosTabla}
                                        localizacionesMapa={localizacionesMapa}
                                        handleListadoMapa={(tipo)=>this.handleListadoMapa(tipo)}                    
                                        modal={modal}     
                                        idAccion={this.props.idAccion}
                                    />                           
                                                               
                                    
                                    <Divider/>

                                    <Row>
                                        <Col xs={12}>
                                            {//modal ? 
                                            //RenderTipoTabla(localizacionesMapa,datosMapa,divHeight) //: 
                                            /*<ModalDatos data={this.props.datosTabla} isTrabajo={this.state.types === "trabajos"}  tipoListado={this.state.tipoListado} divHeight={divHeight}/>*/}
                                        
                                              <ModalDatos data={modal?this.props.datosTablaResult:this.props.datosTabla}  isTrabajo={this.state.types === "trabajos"}  
                                                     tipoListado={this.state.tipoListado}                                                     
                                                     divHeight={divHeight} 
                                                     divWidth={divWidth}
                                                     localizacionesMapa={localizacionesMapa} 
                                                     datosMapa={datosMapa}
                                                     refreshData={() => this.handleSearch()}            
                                                     showLoadingSearch={this.state.loadingSearch && this.state.types!=this.prevTypes}                                          
                                                     />                                                                                                                                                                                                                                                                                                  
                                                                                                                                                                         
                                        </Col>
                                    </Row>
                                    </div>

    )
}

renderRenuncias(modal,divHeight,divWidth,classes){
    let filteredDataColSoc=[];
    if (this.state.colSoc>0)
        filteredDataColSoc = this.props.datosTablaResult?this.props.datosTablaResult.filter(item => item.Id_Col_Soc == (this.state.colSoc)):this.props.datosTabla?this.props.datosTabla.filter(item => item.Id_Col_Soc == (this.state.colSoc)):[];
    else
        filteredDataColSoc =this.props.datosTablaResult?this.props.datosTablaResult:this.props.datosTabla


    return (

       
    <div id="renuncias" className={classes.divRenuncias}>
         {!this.state.mostrarRenunciasResumen ?  <div> 
                                    <Buscador
                                            modal={this.props.modal}
                                            tituloModal={this.props.tituloModal}
                                            descripcion={this.props.descripcion}
                                            text={this.state.text}
                                            types={this.state.types}
                                            loadingSearch={this.state.loadingSearch}
                                            handleClose={()=>this.handleClose()}
                                            handleSearch={(text)=>this.handleSearch(text)}
                                            handleChangeText={(text)=>this.handleChangeText(text)}
                                            handleSelectChange={(event)=>this.handleSelectChange(event)}
                                            _handleKeyDown={(e)=>this.handleKeyDown(e)}
                                            idAccion={6}    
                                         />                                                                     
                                     
                                    <Divider/>  

                                  
                                   <Resultados 
                                        types={this.state.types}
                                        loadingSearch={this.state.loadingSearch}
                                        tipoListado={this.state.tipoListado}
                                        datosTablaResult={this.props.datosTablaResult}
                                        datosTabla={this.props.datosTabla}                                        
                                        modal={modal}     
                                        idAccion={this.props.idAccion}        
                                        colSoc={this.state.colSoc}
                                        changeColSoc={(value)=>this.handleChangeColSoc(value)}

                                    />          
                                   
                                    
                                    <Divider/>
    
                                    <ModalDatosRenuncias   ref={this.modalDatosRenunciasRef}  
                                        data={filteredDataColSoc}                                     
                                        divHeight={divHeight} 
                                        divWidth={divWidth}                                                                        
                                        refreshData={() => this.handleSearch()}                 
                                        seleccionRenuncias={(data) => this.handleSeleccionRenuncias(data)}         
                                        mostrarRenunciasResumen={(valor,datos)=>this.handleMostrarRenunciasResumen(valor,datos)}
                                    />
                                </div>: 

                                    <RenunciasResumen
                                        handleClose={()=>this.handleCloseResumen()}
                                        data1={this.state.datosResumen && this.state.datosResumen.Datos1 }  //filter(item => item.Obras_Realizadas==1 && item.Presentado==1)}    
                                        data2={this.state.datosResumen && this.state.datosResumen.Datos2 }  //filter(item => item.Obras_Realizadas==2 && item.Encomenda_Oficio!=2  && item.Presentado==1)}    
                                        data3={this.state.datosResumen && this.state.datosResumen.Datos3 } //filter(item => item.Obras_Realizadas==2 && item.Encomenda_Oficio==2 && item.Presentado==1)}    
                                        data4={this.state.datosResumen && this.state.datosResumen.Datos4 } // filter(item => item.Obras_Realizadas==2 && item.Presentado==0)}     
                                        data5={this.state.datosResumen && this.state.datosResumen.Datos5 }                                  
                                    />   
                                }
 
 </div>

    )
}  



    render() {
           
  

        let {classes} = this.props;
        let divHeight = 880;
        let divWidth=600;
        let divElement = document.getElementById('busqueda');
        if (divElement!=null){                   
                    divHeight = divElement.offsetHeight;
                    divWidth = divElement.offsetWidth;
        }
        if (isMobile())
            divHeight=window.innerHeight;
       
       
        let datosMapa=null;
        let localizacionesMapa=null;       
           
        if ((this.props.datosTablaResult!=='' || this.props.datosTabla!=='') && this.state.loading===false) {  
           localizacionesMapa = this.obtenerLocalizacionesEnMapa(this.props.datosTablaResult!==''?this.props.datosTablaResult:this.props.datosTabla)
           datosMapa= this.obtenerDatosEnMapa(this.props.datosTablaResult!==''?this.props.datosTablaResult:this.props.datosTabla)        
        }
                              

        return (           

            
            <div id="busqueda" style={!isMobile()?{ bottom: '0' }:{bottom: '0'  ,height:document.documentElement.scrollHeight  }} className={ !isMobile() ? this.props.idAccion!=6? classes.modal:classes.modalRenuncias :classes.modalMobile} >
                <Container className="full" >
             
                    <Row>
                 {/* Mostrar los datos como JSON*
        <pre>{JSON.stringify(renuncias, null, 2)}</pre> */}


                        {
                            (((this.props.loading === true || this.state.loading) && !this.state.closing) || ( this.props.datosTablaResult==='' && this.props.datosTabla==='') )  ? 
                                <div style={this.props.idAccion!=6?{margin: "50%"}:{marginLeft: "50%",marginTop: "50vh"}}>
                                    <CircularProgress/>
                                </div> :
                                                              
                                <Col xs={12}>
                                                                                                         
                                    {this.props.idAccion!=6 ? 
                                     this.renderBuscadorYResultadosGeneral(this.props.modal,divHeight,divWidth,localizacionesMapa,datosMapa) :
                                     this.renderRenuncias(this.props.modal,divHeight,divWidth,classes)
                                     }

                                </Col>
                        }
                     </Row>

                                        
                </Container>
            </div>
        );
    }
}




const mapStateToProps = state => ({
    loading: state.status.modalLoading ? state.status.modalLoading : false,
    datosTabla: state.user.datosModal.expedientes ? state.user.datosModal.expedientes : '',
    datosTablaResult: state.user.datosModal.resultados ? state.user.datosModal.resultados :'',
    tituloModal:state.user.datosModal.tituloModal ? state.user.datosModal.tituloModal : '',
    descripcion: state.user.datosModal.descripcion ? state.user.datosModal.descripcion : '',
    modal: state.status.modal ? state.status.modal : '',
    idAccion: state.user.idAccion ? state.user.idAccion : 0,
    filtroBusqueda: state.user.filtroBusqueda ? state.user.filtroBusqueda : '',
    selectBuscador: state.user.selectBusqueda ? state.user.selectBusqueda : 'expedientes',
    idColegiado: state.user.DatosUsuarioValidado.Id_Colegiado ? state.user.DatosUsuarioValidado.Id_Colegiado: 0
   
  });

const mapDispatchToProps = {
    fetchocultaModal,
    fetchCambiaStadoModalFalse,
    fetchBuscador,
    fetchSelect,
    fetchErrorExpediente,
    fetchSuscepAcciones
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(Modalacciones)));


