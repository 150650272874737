import React, {Component} from 'react';
import {
    Dialog,DialogContent,DialogTitle,DialogActions,Button,Typography
} from '@material-ui/core';

import {Translate} from "react-localize-redux";
import ApiCoag from "../../api/ApiCoag";
import {ID_TIPO_EMP_GEO} from "../../api/config";
import ValidateAddress from './index';




class ValidateAddressModal extends Component {

    constructor(props) {
        super(props);
        this.state = {             
          locationForm:null     
        }
      }

   
      async handleSaveGeo() {
        let { location } = this.state;
         this.setState({ isLoadingSave: true });
        let locations = [];      
          
        locations.push(location);
      
    
        let response = await ApiCoag.expedientes.putEmplazamientoGeolocalizacion(this.props.idExpediente, { "Emplazamientos": locations, "ignorarobservaciones": 1 });
        if (response =='ERROR2'){
          this.setState({ isLoadingSave: false });
        }
        else {            
          this.setState({          
            isLoadingSave: false
          }); 
          this.props.saveGeo(response.Emplazamientos) 
         //this.props.refreshData()        
        }
      }


      handleUpdateLocation(locationForm,coor,introUtm) {
        
       
        if (locationForm!=null && locationForm.Id_Pais!=null){
            let location = {
                 Id_Emplazamiento: this.props.idEmplazamiento,
                  Id_Expediente: this.props.idExpediente,   
                  Id_Concello:locationForm.Id_Concello!=null?locationForm.Id_Concello:null,  
                  Georeferencia:locationForm.Georeferencia,
                  Latitud:locationForm.Latitud,
                  Longitud:locationForm.Longitud,
                  INTRO_UTM:null,
                  UTM_X:null,
                  UTM_Y:null,
                  UTM_H:null  
            }                              
          
                     
            if (coor!=null){ /*metemos coor que se introducen en form, ya que pueden no dectarse automaticamente*/
                if (introUtm==1){
                    location.UTM_X=coor[0];
                    location.UTM_Y=coor[1];
                    location.UTM_H=29; 
            }             
                else{
                    location.Latitud=coor[0];
                    location.Longitud=coor[1];                  
                }   
                location.INTRO_UTM=introUtm;
            }                      
            
            location.Tipo=ID_TIPO_EMP_GEO;
             
            this.setState({ location: location,disabledSave: false}) ; 

            this.props.updateLocation(location,coor,introUtm) 
          }
        else {

          this.setState({ location: [] })
          
        }
    }

    updateNoLocation(){
      this.setState({ disabledSave: true})
    }
  
render() {  

    let { classes } = this.props;

    return (<div>
        <Dialog
          open={this.props.open}          
          maxWidth="sm"   
          fullWidth={true} 
       >      
          <DialogTitle id="alert-dialog-title">
          <Translate id="languages.expedients.labelGeolocalizacion"/>   expediente {this.props.expedienteCodigo}
          </DialogTitle>
          <DialogContent>
          <div className="p-2">
          <Typography variant="subtitle1" gutterBottom className="m-1">
            <Translate id="languages.expedients.infoTextGeoValidate"/>              
          </Typography>
          <br></br>
          </div>
          <ValidateAddress updateLocation={(location,coor,introUtm) => { this.handleUpdateLocation(location,coor,introUtm) }}
              isShowAddress={false}            
              location={this.props.location} 
              updateIsShowAddress={(showAddress) => { this.props.updateIsShowAddress(showAddress) }}
              updateNoLocation={() => { this.updateNoLocation() }}
              key={this.props.location ? this.props.location.Id_Emplazamiento : 1} 
              fromFichaExpediente={false} 
              fromGeo={true}/>
          </DialogContent>
          <DialogActions>                      
        
          <Button   className="mx-2" color="primary"
           disabled={(this.state.disabledSave || this.state.location==[]) || (this.state.location && this.state.location.Georeferencia==null && this.state.location.Latitud==null)}
           onClick={() => { this.handleSaveGeo() }}>
             Aceptar
            </Button>          
          
            <Button   className="mx-2" color="primary"
              onClick={() => { this.props.onClickCancel() }}>
            Cancelar
            </Button>
           
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ValidateAddressModal;
