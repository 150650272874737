import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Card,  CardHeader, CardText,Container } from 'reactstrap';
import {    Grid, TextField ,Select, MenuItem, Button  ,FormControl,FormControlLabel, Checkbox,CircularProgress, textField } from "@material-ui/core";
//import moment from 'moment'
import { Translate } from "react-localize-redux";
import ApiCoag from "../../api/ApiCoag";
import {VERSION_APP} from "../../api/config.js";
import {isMobile} from "../../api/index";

const styles = theme => ({
  
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
        textAlign: 'left',
        marginTop: 5
    }
   
})




class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
           idioma : -1,
           hayCambios:false,
           loading:true,
           applying:false,
           contenidoInicio:0
        }
    }


    handleChange = (propertyName) => (event) => {

  
        if (propertyName=='Idioma_Predefinido' && event.target.value != this.state.idioma )
          this.setState({ idioma: event.target.value , hayCambios : true });  
        
        if (propertyName=='Envio_Administracion' && event.target.value != this.state.envioAdministracion )
          this.setState({ envioAdministracion: event.target.checked ? 1 : 0, hayCambios : true });   
        
          if (propertyName=='Orden_Estr_Desc' && event.target.value != this.state.ordenEstrDesc )
          this.setState({ ordenEstrDesc: event.target.checked ? 1 : 0, hayCambios : true });   

          if (propertyName=='Contenido_Inicio' && event.target.value != this.state.contenidoInicio )          
          this.setState({ contenidoInicio: event.target.value , hayCambios : true });  

          if (propertyName=='Col' && event.target.value != this.state.idColegiado )
          this.setState({ idColegiado: event.target.value });                    
           
    }

    async handleApply() {
        if (this.state.hayCambios)
        {
            let response=null;

           this.setState({  applying :true }); 
         
           let data = {
                    "Idioma_Predefinido": this.state.idioma,
                    "Envio_Administracion": this.state.envioAdministracion,    
                    "Orden_Estructura_Desc":this.state.ordenEstrDesc  ,
                    "Contenido_Inicio":this.state.contenidoInicio                                        
                }     
           response=await ApiCoag.usuario.putUsuario(data)          
          
            
            if (response && response != 'ERROR2') 
            {   
                this.setState({  hayCambios :false,applying:false });                                                                                                                      
            }  
           
        }   
    }

    async handleImp() {
       
            let response=null;
           
            this.setState({  applyingImp :true }); 

            let data = {
                  
                    "Id_Colegiado":this.state.idColegiado                                 
                }     
            response=await ApiCoag.usuario.putUsuarioImp(data)             
                      
             if ( response &&  response.DatosUsuarioValidado==undefined)
             {
                this.handleLoad()
             }
             this.setState({  applyingImp:false });                                                                                                                      
             
           
    }   
   


    async handleLoad() {
        let response = await ApiCoag.usuario.getUsuario();
      
       if (response && response != 'ERROR2')  {

             let esAdmin=response.UsuarioCOAGAdministrador.length>0;
             let esInterterritorial=response.UsuarioCOAGInterterritorial.length>0;
             let idColegiado=esAdmin?response.UsuarioCOAGAdministrador[0].Id_Colegiado:(esInterterritorial?response.UsuarioCOAGInterterritorial[0].Id_Colegiado:this.props.datosUsuarios.Id);
                                     

             this.setState({ 
                  configuracionId:response.DatosConfiguracionesUsuario[0].Id,
                  configuracionUsuario:response.DatosConfiguracionesUsuario[0].Usuario,
                  idioma : response.DatosConfiguracionesUsuario[0].Idioma_Predefinido,
                  envioAdministracion: response.DatosConfiguracionesUsuario[0].Envio_Administracion,
                  ordenEstrDesc:response.DatosConfiguracionesUsuario[0].Orden_Estructura_Desc,
                  contenidoInicio:response.DatosConfiguracionesUsuario[0].Contenido_Inicio,
                  hayCambios:false,
                  loading:false,
                  usuarioCOAGAdministrador:esAdmin,
                  usuarioCOAGInterterritorial:esInterterritorial,
                  idColegiado:idColegiado
             });    
            }  

    }

    componentDidMount() {
        this.handleLoad()
    }


    
    render() {
        let { classes } = this.props;
        let version=VERSION_APP;
        return (
                      
        (this.state.loading ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
            <div>
                <Container className="my-4 px-4">
                   <Grid container spacing={0}>
             
                <Grid item xs={12} className="ml-2 mr-2">
           
                <Card className="ml-2 mr-2 mb-2 ">
           
                    <div onClick={this.handdleClick}>
                        <CardHeader>
                        <div style={{ float:"right", fontSize: "0.7rem" ,color: "rgba(0, 0, 0, 0.55)" }}>{version}</div>
                        <p>Preferencias <b>{this.props.datosUsuarios.Usuario} </b></p>
                                                  
                        </CardHeader>
                    </div>                    
                    <CardText tag="div" >

                    <TextField className="ml-4 mr-2 mt-3 text-uppercase"
                                        style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: "0.7rem" }}
                          value={this.state.idColegiado}
                          label={"NUM. COL."}
                          onChange={this.handleChange("Col")}
                          //className={`${classes.textField} mt-2`}                      
                          disabled = {this.state.usuarioCOAGAdministrador || this.state.usuarioCOAGInterterritorial?false:true}                      
                          name="Col" />
                            {(this.state.usuarioCOAGAdministrador || this.state.usuarioCOAGInterterritorial) && (this.state.applyingImp ? <CircularProgress className="ml-4 mr-2 mt-4" size={18}/> :
                         <Button className="ml-2 mr-2 mt-4" size="small" variant="contained" color="primary"   onClick={async () => { await this.handleImp() }} > 
                           Imp
                          </Button>)
                        }
                    <br></br>
                    

                    {/*<TextField className="ml-4 mr-2 mt-4 text-uppercase"
                                        style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: "0.7rem" }}
                          value={moment(new Date(this.props.datosUsuarios.Fecha_Ultima_Conexion)).format("DD/MM/YYYY HH:mm") }
                          label={"ULTIMA CONEXIÓN"}
                          //className={`${classes.textField} mt-2`}                      
                          disabled = {true}                      
                        name="CONEXION" />*/}
                      
                      <br></br>    
                      <br></br>       

                            <label className="ml-4 mr-2 mt-0 text-uppercase"
                                        style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: "0.7rem" }}>
                            Idioma preferido
                            </label>

                            <Select className="ml-2 mr-2 mt-0 text-uppercase"                            
                                        style={{ fontSize:!isMobile()?12:10,color: "rgba(0, 0, 0, 0.55)"}}                                                                                                                                             
                                                inputProps={{
                                                    name: 'Idioma_Predefinido_Nombre',
                                                    id: 'Idioma_Predefinido',

                                                }}
                                                value={this.state.idioma}
                                                onChange={this.handleChange("Idioma_Predefinido")}>
                                                    <MenuItem
                                                    value={1}>{<Translate id="languages.header.castellano"/>}</MenuItem>

                                                     <MenuItem
                                                    value={2}>{<Translate id="languages.header.gallego"/>}</MenuItem>
                                                       
                                            
                           </Select>

                           <p></p>


                           <label className="ml-4 mr-2 mt-0 text-uppercase"
                                        style={{ color: "rgba(0, 0, 0, 0.55)", fontSize: "0.7rem" }}>
                            Mostrar en pantalla de inicio
                            </label>

                            <Select className="ml-4 mr-2 mt-0 text-uppercase"                            
                                        style={{ fontSize:!isMobile()?12:10,color: "rgba(0, 0, 0, 0.55)"}}                                                                                                                                             
                                                inputProps={{
                                                    name: 'Contenido_Inicio',
                                                    id: 'Contenido_Inicio',                                                    
                                                }}
                                                value={this.state.contenidoInicio}
                                                onChange={this.handleChange("Contenido_Inicio")}>
                                                    <MenuItem
                                                    value={0}  ><Translate id="languages.expedients.tramitacionesCursoSolo"/></MenuItem>

                                                     <MenuItem 
                                                    value={1}>{<Translate id="languages.expedients.tramitacionesCursoYAct"/>} (5) </MenuItem>

                                                    <MenuItem 
                                                    value={2}>{<Translate id="languages.expedients.tramitacionesCursoYAct"/>} (10) </MenuItem>                                                     
                                            
                                            </Select>
                                       
                                       

                      <p></p>

                                             
                        <FormControl>  
                     <FormControlLabel 
                          className="ml-2 mr-2 mt-2 text-uppercase"                                                            
                          key="Envio_Administracion"                                                                                     
                          control={
                         <Checkbox

                             checked={this.state.envioAdministracion == 1 ? true : false}                                                                                        
                             onChange={this.handleChange("Envio_Administracion")}                                
                             value={"EnvioAdministracion"}                                                                                        
                             color="primary" />
                           }
                         label={<Translate id="languages.trabajo.textUserEnvioAdministracion"/>}
                           />                                                                                       
                                                                          
                      </FormControl> 

                      <p></p>      

                         <FormControl>  
                     <FormControlLabel 
                          className="ml-2 mr-2 mt-2 text-uppercase"                                                            
                          key="OrdenEstrDesc"                                                                                     
                          control={
                         <Checkbox
                             checked={this.state.ordenEstrDesc    == 1 ? true : false}                                                                                        
                             onChange={this.handleChange("Orden_Estr_Desc")}                                
                             value="OrdenEstrDesc"                                                                                       
                             color="primary" />
                           }
                         label={<Translate id="languages.trabajo.textUserOrderEstrDesc"/>}
                           />                                                                                       
                                                                  
                      </FormControl>                                                           
                                                                                               

                    </CardText>
                </Card>

                    {!this.state.usuarioCOAGInterterritorial &&
                    <div>
                        { <Button  disabled={!this.state.hayCambios} className="ml-2 mt-2 mb-4"  color="primary"   onClick={async () => { await this.handleLoad() }} > 
                                            Cancelar
                         </Button>}
                   
                         {this.state.applying ? <CircularProgress className="align-self-center" size={24}/> :                        
                         <Button   className="ml-2 mt-2 mb-4" variant="contained" color="primary"  disabled={!this.state.hayCambios}  onClick={async () => { await this.handleApply() }} > 
                                   {!this.state.usuarioCOAGAdministrador? 'Aplicar':'Aplicar a '+  this.state.idColegiado}
                         </Button>}
                         </div>    
                        }
                             
               
                </Grid>
                
                </Grid>
               
                </Container>
            </div>
        )

        )
    }
}

const mapStateToProps = state => ({
datosUsuarios:state.user.DatosUsuarioValidado,
idiomaConf:state.user.DatosConfiguracionesUsuario.Idioma_Predefinido,
});


export default connect(mapStateToProps,{ })(Profile);
