import React, { Component } from 'react';
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import { withStyles, Grid, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Table, TableCell, TableHead, TableBody, TableRow, Paper, Fab, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { withRouter } from 'react-router-dom';
import { red, green, orange } from '@material-ui/core/colors';
import moment from "moment";
import {isMobile} from "../../../../api/index";

const styles = theme => ({
  subtitle: {
    padding: "10px 20px"
  },
  fab: {
    margin: theme.spacing.unit,
    marginTop: 25,
    position: 'absolute',
    marginLeft: 30
  },
  table: {
    minWidth: 200,
  },
  tableBodyHeight: {
    minHeight: 80
  },
  headHeight: {
    height: 30
  },
  withoutRadius: {
    borderRadius: 0
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  titleColor: {
    //color: `${theme.palette.primary.main} !important`,
    cursor: "pointer"
  }
 
});

const CustomTableHead = withStyles(theme => ({
  head: {
    backgroundColor: grey[100],
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

class ListaTrabajos extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  

  async crearTrabajo() {
    this.props.history.push(`/crear-trabajo/${this.props.match.params.id}`);
  }


  getCleanedString(cadena) {
    if (cadena == null)
        return '';
    
    cadena = cadena.toLowerCase();    
    return cadena;
}

estado = (nombre) => {
    let nombreLimpio = this.getCleanedString(nombre);
    if (nombreLimpio !== '')
        return (
            <div /*className={nombreLimpio}*/>
                {<img alt={200} src={this.iconUrl(nombreLimpio)} />}
                {nombre}
            </div>);
}

iconUrl = (nombreLimpio) => {
    try {
        return require(`../../../Tramitaciones/IconosEstados/${nombreLimpio}.svg`);
    } catch (e) {
        //let nombreLimpio='borrador'
        //return require(`../../../Tramitaciones/IconosEstados/${nombreLimpio}.svg`);
    }
}

onClickTrabajo(Id_Expediente ,Id_Trabajo) {

  this.props.history.push("/visualizar-expediente/" + Id_Expediente + "/" + Id_Trabajo);
}

  render() {
    let { classes } = this.props;
    return (
      <Paper className={!isMobile() ? `${classes.withoutRadius} m-3` : `${classes.withoutRadius} m-1`}>
        <Grid container >
          <Grid item xs={10} md={10}>
            <Typography variant="display4" gutterBottom className="m-3">
              <Translate id="languages.fichaExpediente.titleListaTrabajos" />
            </Typography>
              
          </Grid>
          
          <Grid item xs={2}  md={2} >
            {
              this.props.expediente.Expediente[0].SePuede_CrearTrabajo==1 &&
              <Fab size="small" color="primary" aria-label="Add"   className={classes.fab}
              onClick={() => { this.crearTrabajo() }}>
              <Add />
              </Fab>
            }
          </Grid>

          <Grid item md={12}>
            <Divider style={{ height: 3 }} />
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.headHeight}>
                <CustomTableHead className="text-uppercase px-3">N</CustomTableHead>
                <CustomTableHead className="text-uppercase">
                  <Translate id="languages.trabajo.tableColumnTitle" />
                </CustomTableHead>
                <CustomTableHead className="pl-3 text-uppercase">
                  <Translate id="languages.trabajo.tableColumnState" />
                </CustomTableHead>
                <CustomTableHead className="text-uppercase px-2">
                  <Translate id="languages.generalButton.date" />
                </CustomTableHead>
                <CustomTableHead className="text-uppercase px-2">
                  <Translate id="languages.trabajo.tableColumnVisado" />
                </CustomTableHead>

                {/*<CustomTableHead className="text-uppercase px-2 text-right">
                  <Translate id="languages.trabajo.tableColumnInc" />
          </CustomTableHead>*/}
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBodyHeight}>
              {
                this.props.expediente.Trabajos.length === 0 ?
                  <TableRow>
                    <TableCell colSpan={6}></TableCell>
                  </TableRow>
                  : this.props.expediente.Trabajos.map((row, index) => {
                    return (
                      <TableRow className={classes.row} key={index}>
                        <TableCell component="th" scope="row" className="px-1 text-center">
                          {row.Id_Trabajo}
                        </TableCell>
                        <TableCell className="p-1">
                          {/*<a className={ classes.titleColor } onClick={() => this.props.changeEstructura(row.Id_Trabajo)}>
                            {row.Titulo}
                    </a>*/}
                          {/*<a className={ classes.titleColor } style={{color:row.Incidencias && row.SePuede_Editar > 0 ?'#dc3545':'#007bff'}} onClick={() => this.props.changeEstructura(row.Id_Trabajo,row.Id_Tipo_Trabajo)}>
                            {row.Titulo}
                  </a>*/}
                          <a className={ classes.titleColor } style={{color:row.Incidencias && row.SePuede_Editar > 0 ?'#dc3545':'#007bff'}} onClick={() => this.onClickTrabajo(row.Id_Expediente,row.Id_Trabajo)}>
                            {row.Titulo}
                          </a>
                        </TableCell>
                        <TableCell className="p-2"> 
                                <div className='estados'>
                                        {this.estado(row.Estado ? row.Estado.replace("Pendente","Pen.").replace("Pendiente","Pen.") : "")}
                                    </div>
                                    {/*{row.Estado}*/}</TableCell>
                        <TableCell className="p-0 text-center">{row.Fecha_Entrada ? moment(row.Fecha_Entrada).format("DD/MM/YY") : "-"}</TableCell>
                        <TableCell className="p-0 text-center">{row.Fecha_Tramitacion ? moment(row.Fecha_Tramitacion).format("DD/MM/YY") : "-"}</TableCell>
                       {/*<TableCell className="p-0 text-center">{row.Incidencias !== undefined ? row.Incidencias : ''}</TableCell>*/}
                      </TableRow>
                    );
                  })
              }
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
};

ListaTrabajos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(ListaTrabajos))));