import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize, Translate } from "react-localize-redux";

import {
    Grid, List, ListItem,   Button, Typography,CircularProgress , IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Tooltip,TableCell,
    ExpansionPanel,ExpansionPanelSummary,ExpansionPanelDetails, Collapse
} from "@material-ui/core";


import ExpandLess from '@material-ui/icons/AddCircle'; 
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Info from '@material-ui/icons/InfoRounded'; 
import ExpandMore from '@material-ui/icons/Cancel';
//import RemoveRedEyeRounded from '@material-ui/icons/RemoveRedEyeRounded';

import { withRouter } from "react-router-dom";
import {fetchErrorExpediente} from "../../actions/expedientes";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import {grey} from "@material-ui/core/colors/index";
import {URL_AUTOASIGN_1,URL_AUTOASIGN_2} from "../../api/config";
import {isMobile} from "../../api/index";

//import ApiCoag from "../../api/ApiCoag";
const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        width: '100%',
        paddingTop: 0,
        borderTop: "solid 1px" + theme.palette.secondary.light,
    },
    item: {
        borderTop: "solid 1px" + theme.palette.secondary.light,
        padding: 0
    },
    border: {
        borderLeft: "solid 1px" + theme.palette.secondary.light,
        borderRight: "solid 1px" + theme.palette.secondary.light,
        borderBottom: "solid 1px" + theme.palette.secondary.light,
    },
    headerProyect: {
        backgroundColor:  theme.palette.default,
        //borderBottom: "solid 1px" + theme.palette.secondary.light,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
    button: {
        margin: theme.spacing.unit,
    },
    expand: {
        marginRight: 8,
        fontSize: 'medium'
    },
    subHeader: {
        backgroundColor: theme.palette.default
    },
    headHeight: {
        height: 40
    },
    table: {
        minWidth: 200,
    },
    tableArquitecto: {
        minWidth: 190,
    },
    tableBodyHeight: {
        minHeight: 100
    },
    fab: {
        margin: theme.spacing.unit,
        marginTop: 25,
        position: 'absolute',
        marginLeft: 40
    },
    tableBorder: {
        border: "2px solid " + grey[200]
    },
    buttonEdit: {
        border: "1.2px solid",
        margin: 2,
        padding: 6,
    },
    withoutRadius: {
        borderRadius: 0
    },
    headerBorder: {
        border: "2px solid " + grey[200],
        borderBottom: 0
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
        textAlign: 'left',
        marginTop: 5
    },
    borderPrimary: {
        border: "1px solid " +theme.palette.primary.main,
    },
    borderSecondary: {
        border: "1px solid " + theme.palette.secondary.light,
    },
    borderBottomPrimary: {
        borderBottom: "1px solid " +theme.palette.primary.main,
    },
    borderBottomSecondary: {
        borderBottom: "1px solid " + theme.palette.secondary.light,
    },
    panelSumary: {
        margin: "8px 0 !important",
        minHeight: "26px !important"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    expandIcon25: {
        top: "25%"
    },
    expandIcon50: {
        top: "50%"
    },
    labelButton: {
        textTransform: 'capitalize',
      },
      titleColor: {
        color: `${theme.palette.primary.main} !important`,
        cursor: "pointer"
      }
})
const CustomTableHead = withStyles(theme => ({
    head: {
        backgroundColor: grey[100],
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    }
}))(TableCell);
class InfoCarpetas extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            dataEncomenda: this.props.encomenda && this.props.encomenda.EncomendaActual && this.props.encomenda.EncomendaActual.length > 0
                ? this.props.encomenda.EncomendaActual[0] : null,            
                     
            isCarpetas: false,
            expanded: null,
            expandedControl:null,
            dialogOpen: false,
            dialogTitle: "",
            dialogContent: ""                      
        }
    }    
    
    handleClick = (index) => {
        let carpetas = {};
        Object.assign(carpetas, this.props.inforCarpetas);
        carpetas[index]['open'] = this.props.inforCarpetas[index]['open'] ? !this.props.inforCarpetas[index]['open'] : true;
        this.setState({ carpetas: carpetas });
    };    

     handleShowDialog(title, content) {
         this.setState({dialogOpen: true, dialogTitle: title, dialogContent: content});
     }

    handleCloseDialog = () => {
        this.setState({ dialogOpen: false });
    };

    CarpetasHijasReq(carpeta){
        let b=false;        
        if (carpeta.children)
        for (let i = 0; i <= carpeta.children.length-1; i++) {   
            if (carpeta.children[i].Archivo_Requerido == 1)
            {b = true;
             return b   
            }
        }        
        return b
    }
    
    openDocInfo (titulocolumna){

        let language=2
         
        if (titulocolumna.indexOf('Nombres')>=0)
        {language=1;}
       
        if (language == 2)
        { window.open(URL_AUTOASIGN_1, '_blank')}
        else
        { window.open(URL_AUTOASIGN_2, '_blank')}

    }

    renderInfoNombres(nombres,claves){      
        if (claves=='' && nombres=='')
        {nombres='--'}          
        
        if (nombres!='')
        {       return(            
                <Typography variant="body1" gutterBottom >            
                {nombres}
                </Typography>                                                                              
                )
        }        
                            
    }
    renderInfoClaves(claves){ 
        if (claves!='')
        { 
              return(            
            <Typography variant="body1" gutterBottom style={{ fontWeight: 600 }}>            
                 {claves}
            </Typography>                                                                      
            )
        }                  
     }

    firmasRequeridasHijas(carpeta){
        let firmas='';
        
        if (carpeta.children)
        for (let i = 0; i <= carpeta.children.length-1; i++) {   
            if (carpeta.children[i].Firmas_Requeridas != '--' && !firmas.includes(carpeta.children[i].Firmas_Requeridas))
            {firmas += carpeta.children[i].Firmas_Requeridas;
             
            }
        }
        
        if (!isMobile())
        return firmas
        else
        return ' ('+firmas+')'
    }
   
    renderDetallesCarpetasFirmasRequeridas() {
      
        let { classes } = this.props;
       
        let column1 =!isMobile() ? 5:11
        let column2 =!isMobile() ? 3:0
        let column3 =!isMobile() ? 1:1
        let column4 =!isMobile() ? 3:0
        
        return (
        <ExpansionPanel className={`${ classes.borderPrimary } shadow-none`} expanded={true} >
    
        <ExpansionPanelSummary classes={{expanded: classes.panelSumary, expandIcon:  classes.expandIcon25 }} className={`${ classes.borderBottomPrimary } py-0 px-3`}  expandIcon={false}>
            <Grid container spacing={0}>
            
            {/*<Grid item xs={12} className="align-self-center pt-1 pl-0 pb-3">
            <Typography  variant="title2" gutterBottom > 
                               <Translate id="languages.trabajo.CarpetasFirmasRequeridas" />
                               </Typography   >
        </Grid> */}
                <Grid item xs={column1 == 11 ? 12 : column1 } className="align-self-center">
                    <Typography variant="button" gutterBottom color={false ? "primary" : "secondary"}>
                           {column1 != 11 && <Translate id="languages.trabajo.previCarpetaTitle" /> }
                           {column1 == 11 && <Translate id="languages.trabajo.previCarpetaTitleFirmasRequeridas" /> }
                    </Typography>
                </Grid>
                { column2 != 0 &&
                <Grid item xs={column2} className="align-self-center">
                    <Typography variant="button" gutterBottom color={false ? "primary" : "secondary"}>                    
                    <Translate id="languages.fileUpload.requiredFirms" />                        
                    </Typography>
                </Grid>
               
                }
                <Grid item xs={column3} className={!isMobile() ? "align-self-center": "align-self-right"}>
                    <Typography variant="button" gutterBottom color={false ? "primary" : "secondary"}>
                    { !isMobile() ?<Translate id="languages.trabajo.aclaraciones" />    : ''        }
                    </Typography>
                </Grid>
                { column4 != 0 &&
                <Grid item xs={ column4} className="align-self-center" >
                                                                                                                                                                                        
                    <Typography variant="button" gutterBottom color={true ? "primary" : "secondary"}>
                       <a id="l1" onClick={() => {this.openDocInfo(document.getElementById("l1").text);}}>{<Translate id="languages.fileUpload.autoAsignNombresClaves"/>}  </a> 
                    </Typography>                     
                
                </Grid>
                }
                          
               
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            {this.state.isCarpetas ? <CircularProgress />
                : <Grid container spacing={0}>
                    <Grid item xs={12} className="px-0">
                        <Grid container spacing={0}>
                            <Grid item xs={column1}>
                                {/*
                                <Typography variant="title2" gutterBottom className="py-2">
                                
                                {this.props.trabajoTitulo}
                                
                                
                                </Typography>*/}
                            </Grid>
                            { column2 != 0 &&
                            <Grid item xs={column2}>

                            </Grid>
                                }
                            <Grid item xs={column3} >
                                                
                                                </Grid>
                            { column4 != 0 &&
                            <Grid item xs={ column4}>

                            </Grid>

                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{backgroundColor: "#fafafa"}} className="px-0">
                        {
                            this.props.inforCarpetas.map((carpeta, indexCarpeta) => {
                                return <List key={indexCarpeta} component="div" disablePadding >

                                    <ListItem button onClick={() => { this.handleClick(indexCarpeta) }} className="pt-1 " >
                                        <Grid container spacing={0}>
                                            <Grid item xs={column1} className="d-flex align-self-center pt-1 ">
                                                {carpeta.children && carpeta.children.length>0  ? (carpeta.open ?   <ExpandMore color="primary" className={classes.expand} /> :  <ExpandLess color="primary" className={classes.expand}/>) : <RemoveCircle color="primary" className={classes.expand}/>}
                                                
                                                {carpeta.Archivo_Requerido != 1 &&
                                                <Typography variant="subtitle2" gutterBottom className={!carpeta.children ?"pl-0" :''}>                                                
                                                    {carpeta.Nombre + ((carpeta.Archivo_Requerido && carpeta.Archivo_Requerido == 1) || (!carpeta.open && this.CarpetasHijasReq(carpeta)) ? " *" : "")}
                                                     {(column2 == 0) ? (carpeta.Firmas_Requeridas  ? '  (' +carpeta.Firmas_Requeridas+') ' : (!carpeta.open ? this.firmasRequeridasHijas(carpeta):'') ): null}
                                                </Typography>}
                                                {carpeta.Archivo_Requerido == 1 &&
                                                <Typography variant="subtitle2" style={{fontWeight:"600"}} gutterBottom className={!carpeta.children ?"pl-0" :''}>                                                
                                                    {carpeta.Nombre + ((carpeta.Archivo_Requerido && carpeta.Archivo_Requerido == 1) || (!carpeta.open && this.CarpetasHijasReq(carpeta)) ?   " *" : "")}
                                                     {(column2 == 0) ? (carpeta.Firmas_Requeridas  ? '  (' +carpeta.Firmas_Requeridas+') ' : (!carpeta.open ? this.firmasRequeridasHijas(carpeta):'') ): null}
                                                </Typography>}

                                            </Grid>
                                            { column2 != 0 &&
                                            <Grid item xs={column2} className=" pt-1 align-self-center pl-1"  >
                                                <Typography variant="body1" gutterBottom>
                                                {carpeta.Firmas_Requeridas ? carpeta.Firmas_Requeridas : (!carpeta.open ? this.firmasRequeridasHijas(carpeta)  : "--")}
                                                </Typography>
                                            </Grid>
                                            }
                                            <Grid item xs={column3} className={!isMobile()? "align-self-left-center pt-1":"align-self-left-center pt-0"} >
                                            {carpeta.Aclaraciones != '' ?
                                                //<Button color="primary" onClick={()=>{this.handleShowDialog(carpeta.Nombre, carpeta.Aclaraciones)}}>
                                                //    Ver
                                                //    {/*<Translate id="languages.generalButton.masInfo"/>*/}
                                                //</Button>*/
                                                <Tooltip title={'Ver información sobre ' + carpeta.Nombre}>
                                                <IconButton   color="primary" aria-label="Info"  
                                                onClick={()=>{this.handleShowDialog(carpeta.Nombre, carpeta.Aclaraciones)}}>
                                                <Info/>
                                                </IconButton>
                                                </Tooltip> 
                                                :   ''
                                                }
                                            </Grid>
                                            { column4 != 0 &&
                                            <Grid item xs={column4} className="align-self-left-center pt-3" >
                                            <Typography variant="body1" gutterBottom>
                                                   {this.renderInfoNombres(carpeta.Alias_Nombre_Archivo,carpeta.Prefijos_Nombre_Archivo)}                                                                    
                                                   {this.renderInfoClaves(carpeta.Prefijos_Nombre_Archivo)}                                                         
                                                </Typography>
                                            </Grid>
                                            }
                                           
                                        </Grid>
                                    </ListItem>
                                    <Collapse in={carpeta.open ? carpeta.open : false} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem className="pt-0 pb-0 pl-0 pr-0" >
                                                <List component="div" disablePadding style={{ width: '100%' }}>
                                                    {carpeta.children && carpeta.children.map((c, i) => {
                                                        return <ListItem className="pt-1 pb-1">
                                                            <Grid container spacing={0} >
                                                                <Grid item xs={column1} className="pt-1 pb-0 pl-5 pr-1 align-self-center">   
                                                                {c.Archivo_Requerido != 1 &&                                                           
                                                                    <Typography  className="text-left" variant="body1"  gutterBottom>
                                                                        {c.Nombre + (c.Archivo_Requerido && c.Archivo_Requerido == 1 ? " *" : "") }
                                                                        {(column2 == 0) ? (c.Firmas_Requeridas ?  '  ('+c.Firmas_Requeridas+') ' : " " ): null}
                                                                    </Typography> }    
                                                                {c.Archivo_Requerido == 1 &&                                                           
                                                                    <Typography  className="text-left" variant="body1" style={{fontWeight:600}}  gutterBottom>
                                                                        {c.Nombre + (c.Archivo_Requerido && c.Archivo_Requerido == 1 ? " *" : "") }
                                                                        {(column2 == 0) ? (c.Firmas_Requeridas ?  '  ('+c.Firmas_Requeridas+') ' : " " ): null}
                                                                    </Typography> }                                                                  
                                                                </Grid>
                                                                { column2 != 0 &&
                                                                <Grid item xs={column2} className="align-self-center pl-1 pt-1"  >
                                                                    <Typography variant="body1" gutterBottom>
                                                                        {c.Firmas_Requeridas ? c.Firmas_Requeridas : "--"}
                                                                    </Typography>
                                                                </Grid>
                                                                 }
                                                                <Grid item xs={column3} className={!isMobile()? "align-self-left-center pt-1 ":"align-self-left-center pt-0"} >
                                                                {carpeta.Aclaraciones != '' ?
                                                //<Button color="primary" onClick={()=>{this.handleShowDialog(c.Nombre, c.Aclaraciones)}}>
                                                //    Ver {/*<Translate id="languages.generalButton.masInfo"/>*/}
                                                //</Button>
                                                 <Tooltip title={'Ver información sobre ' + c.Nombre}>
                                                 <IconButton   color="primary" aria-label="Info"  
                                                onClick={()=>{this.handleShowDialog(c.Nombre,c.Aclaraciones)}}>
                                                 <Info/>
                                                 </IconButton>
                                                 </Tooltip> 
                                                : "--"
                                                }
                                                                </Grid>
                                                                { column4 != 0 &&
                                                                <Grid item xs={column4} className="align-self-left-center pt-3" >
                                                                    {this.renderInfoNombres(c.Alias_Nombre_Archivo,c.Prefijos_Nombre_Archivo)}                                                                    
                                                                    {this.renderInfoClaves(c.Prefijos_Nombre_Archivo)}                                                                    
                                                                </Grid>
                                                                }
                                                            </Grid>
                                                        </ListItem>
                                                    })}
                                                </List>
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </List>
                            })
                        }
                    </Grid>
                    
                    
                    <Grid xs="12" className="pt-2 text-right">
                            <Typography variant="subtitle2"  className="pt-3 pb-0">
                            <Translate id="languages.trabajo.Leyenda1"/>
                             </Typography>                            
                    </Grid>

                </Grid>
            }

        </ExpansionPanelDetails>
    </ExpansionPanel>



        )

    }

  


   




    handleCancel(){
        this.props.history.push("/visualizar-expediente/" + this.state.dataEncomenda.Id_Expediente);
    }

    render() {
        let { classes } = this.props;
        
        return (
            <div>
            <Grid container spacing={0}>                          
            <Grid item xs={12}>
                <p></p>
                </Grid>
               
                <Grid item xs={12}>
                 
                        
                            <Grid container spacing={0} className={classes.border}>
                                <Grid item xs={12}>                                    

                                   {this.renderDetallesCarpetasFirmasRequeridas()}
                                   
                                </Grid>
                              
                            </Grid>                                                     
                   
                </Grid>
             

                <Grid item xs={12}>
                    <Dialog
                        open={this.state.dialogOpen}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {this.state.dialogTitle}
                            </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <ReactQuill value={this.state.dialogContent} readOnly theme='bubble' />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
                                <Translate id="languages.generalButton.aceptar"/>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
                              
           

                
            </Grid>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    fetchErrorExpediente
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(InfoCarpetas))));