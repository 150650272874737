import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import {IconButton , MenuList, MenuItem, Popper, Grow, Paper, ClickAwayListener,Tooltip } from '@material-ui/core';
import { Help} from "@material-ui/icons";
import persistor from "../../../index";
import { Translate } from "react-localize-redux";
import {URL_MANUAL,URL_FAQ,URL_VIDEOS,URL_CONINTEROP} from "../../../api/config";



const styles = theme => ({
    button: {
        margin: theme.spacing.unit / 2,
    },
    paper: {
        marginRight: theme.spacing.unit * 2,
    }
});

class MenuUserHelp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: true,
            open: false,
        };
    }

    handleLoggout =() =>{
        localStorage.clear();
        persistor.purge();
        this.props.history.push('/');
    }

    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));       
    };


    handleClose = () => {
        this.setState({open: false });
    };

    handleNav(uri){
        this.setState({ open: false });
        this.props.history.push(uri);
    }

    openDocInfo (tipo){
        this.setState({ open: false });
        if (tipo=='m')
            window.open(URL_MANUAL,'_blank')       
        if (tipo=='p')
            window.open(URL_FAQ,'_blank')                
        if (tipo=='v')
            window.open(URL_VIDEOS,'_blank')       
        if (tipo=='c')
            window.open(URL_CONINTEROP,'_blank')       
    }


    render() {

        let {open} = this.state;

        return (
            <div>
                 <Tooltip id="t1" title={!this.state.open?<Translate id="languages.generalText.ayuda"/>:''} >
                <IconButton color="secondary"
                            buttonRef={node => {
                                this.anchorEl = node;
                            }}
                            aria-owns={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleToggle}
                >
                    <Help/>
                </IconButton>
                </Tooltip>
                <Popper open={open} anchorEl={this.anchorEl} style={{zIndex:2 }} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>                                  
                                        <MenuItem onClick={()=>{this.openDocInfo('m')}}><Translate id="languages.generalText.manualApp"/></MenuItem>
                                        <MenuItem  onClick={()=>{this.openDocInfo('p')}}><Translate id="languages.generalText.prFrecuentes"/></MenuItem>
                                        <MenuItem  onClick={()=>{this.openDocInfo('v')}}><Translate id="languages.generalText.videosEj"/></MenuItem>
                                        <MenuItem  onClick={()=>{this.openDocInfo('c')}}><Translate id="languages.generalText.concellosInterop"/></MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
            );
    }
}

MenuUserHelp.propTypes = {
    classes: PropTypes.object,
};


export default withRouter(withStyles(styles)(MenuUserHelp));
