import React, { Component } from 'react';
import {
    Grid,
    Paper,
    CircularProgress,
    Typography,
    Button,
    ExpansionPanel,
    Checkbox,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    withStyles,
   ListItemText,
    Divider,
    Tooltip,
    IconButton,MenuList, MenuItem, Popper, Grow, ClickAwayListener
} from '@material-ui/core';
import * as api from '../../../../api'
import CloudUpload from '@material-ui/icons/CloudUpload';
import CheckCircle from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Menu from '@material-ui/icons/MoreVert'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dropzone from "react-dropzone";
import * as actionsExpedientes from '../../../../actions/expedientes';
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";
import { red, green, orange } from '@material-ui/core/colors';
import Info from '@material-ui/icons/InfoRounded'; 
import moment from 'moment'

import '../../../Tramitaciones/estados.css';
import { formatMenssage } from "../../../../actions/expedientes";
import ApiCoag from "../../../../api/ApiCoag";
import {getDefaultLanguage,isMobile} from "../../../../api/index";
import AsignFilesFolders from "./AsignFilesFolders"
import {ID_TRABAJO_VAL_GEOTECNICO,ID_DOCUMENTACION_GEOTECNICO} from "../../../../api/config";



const styles = theme => ({
    root: {
        width: '100%',
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonProgress: {

        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    red: {
        color: red[500],

    },
    green: {
        color: green[500]
    },
    orange: {
        color: orange[500],
        overflow: 'hidden',
        marginRight: '5%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    size: {
        fontSize: 12,
        marginTop: 6
    },
    formControl: {
        width: "100%"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
        textAlign: 'left',
    },
    textFieldInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
    link: {
        color: theme.palette.primary.main,
        cursor: "pointer",
        fontSize: 12
    },
    black: {
        fontWeight: 700
    },
    margin: {
        margin: 0
    },
    backgroundColor: {
        backgroundColor: "#f5f5f5"
    },
    rootPanel: {
        position: "inherit"
    },
    helperText: {
        textAlign: "end",
        fontWeight: "bold"
    },
    disabledContainer: {
        pointerEvents: "none",
        opacity: 0.4
    },
    masOpciones:{        
        "&:hover": {
            background: "white"
          }
    }
});
const mapStateToProps = (state) => {
    return (
        {
            fileUpload: state.status.files
        }
    )
};

const mapDispatchToProps =
{
    fetchErrorExpediente: actionsExpedientes.fetchErrorExpediente,
    //uploadFiles: actionsExpedientes.uploadFiles,
    resetUploadStates: actionsExpedientes.resetUpladStates,
    showUploadComponent: actionsExpedientes.showUploadComponent,
    hideUploadComponent: actionsExpedientes.hideUploadComponent,
    formatMessage: actionsExpedientes.formatMenssage,
    dispatchSetFetchingDone: actionsExpedientes.dispatchSetFetchingDone
};

class FilesInFolder2 extends Component {
    //  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
      
    //      this.reloadAfterChangeDataFolder(nextProps)      

    //  }

     static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {};

        // Verifica si hay cambios en los props relevantes y actualiza el estado en consecuencia
        if (nextProps.trabajo > 0 && nextProps.dataFolder!=null && nextProps.dataFolder !== prevState.data) {          
           // Actualiza el estado fetchingCenter y data
            nextState = {
                fetchingCenter: false,
                data: nextProps.dataFolder,
                
            };
        }
           
        return Object.keys(nextState).length > 0 ? nextState : null;
        
    }
    
    async componentDidUpdate(prevProps, prevState) {
    
        if (this.props.dataFolder!=null&& !this.state.openConfirm && !this.state.itemSelectedToDragAndDrop && !this.props.moving) {
            //this.setButtonsAndCheckAll();
        }           
    }
   
    componentWillUnmount() {
        this.props.showUploadComponent()
    }

    // async reloadAfterChangeDataFolder(nextProps) {
    //     if (this.props.trabajo>0)
    //     {                                                         
                             
                           
    //         if (nextProps.dataFolder!= null && !this.state.openConfirm && !this.state.itemSelectedToDragAndDrop && !this.props.moving ) //desde que empiezo a mover hasta que acabo de mover no actualizo folder
    //          {  
    //             this.setState({ fetchingCenter: false, data: this.props.dataFolder})
    //             this.setButtonsAndCheckAll()                    
    //             }                
            
    //         if (!this.props.savingAsign)
    //             {  this.setState({openConfirm:false}) }              

         
    //      }
                                                       
    //}


    // async reloadNew(folder) {
    //     if (this.props.trabajo>0)
    //     {                                                                                      
                                  
    //         if (folder!= null && !this.state.openConfirm && !this.state.itemSelectedToDragAndDrop && !this.props.moving ) //desde que empiezo a mover hasta que acabo de mover no actualizo folder
    //          {  
    //             this.setState({ fetchingCenter: false, data: this.props.dataFolder})
    //             this.setButtonsAndCheckAll()                    
    //             }                
            
    //         if (!this.props.savingAsign)
    //             {  this.setState({openConfirm:false}) }              

                            
    //      }
                                                       
    // }

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            error: false,
            data: [],
            firmasDigitales: [],
            panelExpanded: false,            
            expediente: false,
            workDetails: false,
            aclaracionesOpen: false,
            uploadInProgress: false,
            uploadLength: 0,
            currentUpload: 0,
            itemSelected: [],
            checkAll:false,
            currentUploadItem: false,
            pendingUploadList: [],
            fetchingRemove: 0,
            allowUpload: true,            
            detallesArchivo: null,
            loadingDetallesArchivo: false,          
            loadingUpdateFichaTrabajo: false,
            temporalFiles: [],            
            userLocalFileInsertRequest:false ,
            itemSelectedToDragAndDrop:false,
            idEstrUpload:0,
            idEstrUploadPadre:0,
            showImportButton:this.props.trabajosAbiertos.length>0 ,
            openMenu: false,
            fetchingCenter:true
        }
    }

      


    abortUpload() {
        this.setState({
            uploadInProgress: false,
            uploadLength: 0,
            currentUpload: 0,
            currentUploadItem: false,
            pendingUploadList: [],
        })
    }

    async componentDidMount() {       
       this.props.hideUploadComponent()
       this.props.showUploadComponent()
       if (this.props.trabajo>0)
        {//await this.loadGeneralInformation();        
            //this.setState({ fetchingCenter: true })
            let expediente = this.props.expediente.Expediente[0];
            this.setState({ expediente, fetchingCenter: false})
        }
    }

   //async UNSAFE_componentWillMount() {
       
       //this.props.showUploadComponent()
       //if (this.props.trabajo>0)
       //{await this.loadGeneralInformation();        
       // }
        
    //}
  
    /*async loadGeneralInformation() {
        await this.setState({ fetching: true })
        let expediente = this.props.expediente.Expediente[0];

        this.setState({ expediente})
        //await this.loadInformation();
        await this.setState({ fetching: false ,fetchingCenter: false});
    }*/


    //async loadInformation(cargar=false) {
      
         //this.setState({ fetchingCenter: true })
         
        // /*if (cargar){
        //     let expediente = this.props.expediente.Expediente[0];
        //     if (this.props.estructura)
        //     {
          
        //     //  if (this.props.estructurasArchivosRefrescar.length == 0) {
        //     let idEstructuraMostrar = this.props.estructura.id;
                       
        //     let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.archivos(expediente.Id_Expediente, this.props.trabajo, idEstructuraMostrar );
              
        //     let documentos = response.Archivos;
        //     let firmasDigitales = response.FirmasDigitales;

        //     await this.setState({ fetchingCenter: false, data: documentos, firmasDigitales })
                      
          
        //     }else{
        //     let r = await ApiCoag.expedientes.trabajo.getEstructuraDocumental(expediente.Id_Expediente, this.props.trabajo);
        //     let documentos = r.Archivos;
        //     let firmasDigitales = r.FirmasDigitales;
        //     await this.setState({ fetchingCenter: false, data: documentos, firmasDigitales })             
        //     }
        // }   */ 
        //await setTimeout(() => {
        //    this.setState({ fetchingCenter: false})                                  
        //}, 200)
       
    //}

    handleToggle = () => {
        this.setState(state => ({ openMenu: !state.openMenu }));       
    };

    handleClose = () => {
        this.setState({openMenu: false });
    };

    

    expandPanel = (nombre, idEstructura) => async (event, expanded) => {
        this.setState({ panelExpanded: expanded ? (idEstructura ? idEstructura : nombre) : false, loadingDetallesArchivo: true });
        try {
            if (expanded && idEstructura) {
                let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.detallesArchivo(this.state.expediente.Id_Expediente, this.props.trabajo, idEstructura);
                if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {

                    this.setState({ loadingDetallesArchivo: false });
                }
                else {

                    this.setState({ detallesArchivo: response, loadingDetallesArchivo: false });
                }
            }
        }
        catch (e) {
            this.props.fetchErrorExpediente(e.message);
            this.setState({ loadingDetallesArchivo: false })

        }

    };

    handleCheckAll = () => async event => {
        this.setChecks (event.target.checked)      
    }

    async setChecks (value) {
        let tf = [...this.state.temporalFiles];

        tf.map(item => {
            item.checked = value;
        });
        let data = [...this.state.data];
        data.map(item => {
            item.checked = value
        });
        this.setState({ temporalFiles: tf, data, checkAll: value});
        let { files, temporalFiles } = this.itemsSelected();
        this.setButtons(files,temporalFiles)
    }

    setButtons(files,temporalFiles){     
        if (files!=null && temporalFiles!=null )   {
        if (files.length || temporalFiles.length) {
            this.setState({ showDeleteButton: true, showDownloadButton: true,showAsignButton:true })
        } else {
            this.setState({ showDeleteButton: false, showDownloadButton: false,showAsignButton:false })
        }
    }
    }

    setCheckAll(files){
        if (this.state.data!=null){
        if (this.state.checkAll == false &&   (this.state.data.length>0 && files.length === this.state.data.length )){
            this.setState({ checkAll:true })}
        if (this.state.checkAll  == true  && (this.state.data.length==0 || files.length != this.state.data.length )){
            this.setState({ checkAll:false })}
        }
    }

    setButtonsAndCheckAll(){
        let { files, temporalFiles } = this.itemsSelected();
        this.setButtons(files,temporalFiles)
        this.setCheckAll(files)     
    }



    handleChange = (name, index, arrName) => async event => {
        let a = [];
        Object.assign(a, this.state[arrName]);
        a[index][name] = event.target.checked;
        this.setState({ [arrName]: a, panelExpanded: -1 });       

        this.setButtonsAndCheckAll()
    };



    itemsSelected() {
        let temporalFiles = this.state.temporalFiles ? this.state.temporalFiles.filter((item) => item.checked) : []
        let files = this.state.data ? this.state.data.filter((item) => item.checked) : []
        return { files, temporalFiles }
    }

    /*getSelectedItems() {
        let temporalFiles = this.state.temporalFiles ? this.state.temporalFiles.filter((item) => item.checked) : []
        let files = this.state.data ? this.state.data.filter((item) => item.checked) : []
        return files.concat(temporalFiles);
    }*/

   

    async handleRemove() {
        let { files, temporalFiles } = this.itemsSelected();
        let count = 0;
        count += files.length
        count += temporalFiles.length
        let response =null;
        let newData = [...this.state.data];
         
        if (count) {
            //this.props.startRemovingFilesFolder2();

            await this.setState({ fetchingRemove: true, showDownloadButton: false })
            if (files.length) {
                let arrayArchivos = [];
                files.map(item => {
                    arrayArchivos.push({ id_estructura: item.Id_Estructura })
                    return null
                });
                let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.removerFicheros(this.state.expediente.Id_Expediente, this.props.trabajo, arrayArchivos)
                if (response =='ERROR2') {
                    //this.props.fetchErrorExpediente(response);
                } else {

                    
                    files.map(item => {
                        newData = newData.filter(current => current.Id_Estructura !== item.Id_Estructura)
                        return null
                    });

                    await this.setState({ data: newData ,   checkAll:false, showDownloadButton: false})
                }

            }

            await this.setState({ fetchingRemove: false, showDeleteButton: false,showAsignButton:false  })
        }
        //this.props.refreshTree()

        this.props.refreshEstadoVisualEstructuraAfterRemoveFolder2(newData);

    }

   
    download_file(fileURL, fileName) {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
            save.download = fileName || filename;
            if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href;
                // window event not working here
            } else {
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
        }

        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    }

    async handleDownload(compendio=false) {
        let { files, temporalFiles } = this.itemsSelected(); 
        let count = 0;
        count += files.length
        count += temporalFiles.length
        if (count) {


            await this.setState({ openMenu:false,fetchingDownload: 1 })
            if (files.length) {
                if (files.length === 1 && !compendio) {
                    let item = files[0];
                    await this.setState({ fetchingDownload: this.state.fetchingDownload++ })
                    let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.getUrlDescarga(this.state.expediente.Id_Expediente, this.props.trabajo, item.Id_Estructura)
                    if (response.Archivos && response.Archivos.length === 1) {
                        this.download_file(response.Archivos[0].Url, response.Archivos[0].Nombre);
                        let state_files = this.state.data
                        state_files.map(async (_item, _pos) => {
                            if (_item.Id_Estructura === item.Id_Estructura)
                                await this.handleChange("checked", _pos, 'data')
                        })
                    }
                    await this.setState({ fetchingDownload: this.state.fetchingDownload-- })
                    await this.setState({ fetchingDownload: 0 })
                } else {
                    let arrayFiles = []
                    files.map(async item => {
                        await this.setState({ fetchingDownload: this.state.fetchingDownload++ })
                        arrayFiles.push({
                            Id_Estructura: item.Id_Estructura
                        })
                    });
                   
                    if (!compendio){
                        let response = await ApiCoag.expedientes.trabajo.estructuraDocumental.getUrlDescargaMultiple(this.state.expediente.Id_Expediente, this.props.trabajo, arrayFiles)
                        if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
                            //this.props.fetchErrorExpediente(response);
                        }    
                        if (response.Archivos) {
                            response.Archivos.map((i, p) => {
                                this.download_file(i.Url, i.Nombre);
                                return null
                            })
                        }
    
                    }
                    else{
                        let responsec = await ApiCoag.expedientes.trabajo.estructuraDocumental.getUrlDescargaMultipleCompendio(this.state.expediente.Id_Expediente, this.props.trabajo, arrayFiles)
                        if (responsec.MensajesProcesado && responsec.MensajesProcesado.length > 0) {                            
                        }    
                        if (responsec.Archivos) {
                            responsec.Archivos.map((i, p) => {
                                this.download_file(i.Url, i.Nombre);
                                return null
                            })
                        }
    
                     }
                    
                    await this.setState({ fetchingDownload: this.state.fetchingDownload-- })
                    await this.setState({ fetchingDownload: 0 })

                }
            }
        }
    }

    


    async handleDocumentView(file) {
        try {
            let expediente = this.props.expediente.Expediente[0];
            let response = file.Id_Estructura
                ? await ApiCoag.expedientes.trabajo.estructuraDocumental.infoArchivovisualizacion(expediente.Id_Expediente, this.props.trabajo, file.Id_Estructura)
                : await ApiCoag.expedientes.downloadTemporalFiles(expediente.Id_Expediente, [{ Nombre: file.Nombre }])

            if (response.Archivos && response.Archivos.length > 0) {
                window.open(response.Archivos[0].Url, "_blank");
            }
            else if (response.MensajesProcesado && response.MensajesProcesado.length > 0) {
                this.props.fetchErrorExpediente(response);
            }
        } catch (error) {
            this.props.fetchErrorExpediente(formatMenssage(error));
        }
    }


    renderSize(size) {
        if (size < 1048576) {
            return (size / 1024).toFixed(2) + ' Kb'
        } else if (size < 1073741824) {
            return (size / 1024 / 1024).toFixed(2) + ' Mb'
        } else {
            return (size / 1024 / 1024 / 1024).toFixed(2) + ' Gb'
        }

    }
  

    async endDrag() {        
        this.setState({itemSelectedToDragAndDrop:false})  //aquí hay que entrar cuando se suelta       
    }

    
    dragStart = (item, temporal) => (event) => {
        let allFiles = this.itemsSelected();
        if (!item.checked && temporal)
            allFiles.temporalFiles.push(item);
        if (!item.checked && !temporal)
            allFiles.files.push(item);            

        this.setState({itemSelectedToDragAndDrop:true})    

        this.props.dragging(allFiles)           
                                
        }


    hayRequisitosFirmaNoCompletos ()
    { 
        let badFiles=null;

        if (this.props.dataFolder!=null)
         badFiles = this.props.dataFolder.find(t => t.Requisitos_Firma_Completos ==0); /*ponemos la propiedad y no el estado pues es más rápido*/

        if (badFiles != null )
           return true
        else
           return false
    }

    
    obtieneNumFiles ()
    { 
        if (this.props.dataFolder!=null)        
        return this.props.dataFolder.length /*ponemos la propiedad y no el estado pues es más rápido*/
        else
        return 0
        
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

 
    async dragOverUploadFile(dragInternoOn=false) {
        if (!this.state.itemSelectedToDragAndDrop && this.props.permiteArchivos==1)
        {      
            let makeid=this.makeid(50);

            /*NOTA 22/11/2022 comentado  mientras no se puede arrastrar desde equipo*/
            await this.setState({userLocalFileInsertRequest: true,makeid})
            setTimeout(async()=>{
            if(this.state.makeid==makeid){
                await  this.setState({userLocalFileInsertRequest: false})
            }},2000)
        }
    }
 

    async dragOverUploadFileEnd(dragInternoOn=false){       
        setTimeout(async()=>{
            await  this.setState({userLocalFileInsertRequest: false})
        },500)
    }


    async onDropUpload(acceptedFiles) {      
        this.props.onDrop(acceptedFiles)        
    }

    async onDropMoveItem(acceptedFiles) {
        
        if (acceptedFiles.length>0){          
                       
            this.props.onDrop(acceptedFiles)
            if (this.props.notInFolderPlace) {
                this.props.showUploadComponent()
            }            
        }       

        if (this.props.itemsDragging.temporalFiles.length>0){
            await  this.setState({mustRefresh: true})
            //venimos del temporal
            this.props.moveItemTo(this.props.estructura.id)
        }

        this.dragOverUploadFileEnd()

        await  this.setState({mustRefresh: false})
    }

    async cancelAsign(){
        this.props.handleOpenAsign(false)
        
    }
    async handleSaveAsign(){      
        if (this.state.filesAutoEdit.length === 0) {    
            return true;            }       

        
        let newData = this.state.filesAutoEdit.filter(current => current.Id_Documentacion != current.Id_Documentacion_Inicial)    
                        
        let dataPost = {
            Archivos: newData.map(element => {
                return {Id_Estructura: element.Id_Estructura,
                        Id_Documentacion:element.Id_Documentacion,                       
                        Id_Estructura_Destino:this.props.folders.find(x=>x.Id_Documentacion==element.Id_Documentacion).Id_Estructura}
            })
        };
        
       await this.props.handleSaveAsign(dataPost)               

     
    }


    async autoAsign(){
        let filesAutoCopy = [];

        let { files, temporalFiles } = this.itemsSelected();        
        files.map(item => {
        filesAutoCopy.push({
        Id_Estructura:item.Id_Estructura,                
        Nombre: item.Archivo,
        Id_Documentacion_Inicial:item.Id_Documentacion,
        Id_Documentacion:item.Id_Documentacion,     
        Orden:item.Orden
         })})
     
       
       this.setState({filesAutoEdit:filesAutoCopy,asignados:0,openConfirm:true,checkAll:false,showDeleteButton:false,showAsignButton:false,showDownloadButton:false })
       this.setChecks(false)

       await this.props.handleOpenAsign(true);
     
    }

    async handleImport(){        
        this.setState({openMenu:false})
        await this.props.handleImport()                         
    }
    async handleImportGeo(){        
        this.setState({openMenu:false})
        await this.props.handleImport(true)                         
    } 
   

 
    async cellValueChanged(idDoc,nombre){
        let newFiles=this.state.filesAutoEdit
        let fileSel=newFiles.find(x => x.Nombre === nombre)
        let rowIndex=newFiles.indexOf(fileSel)
        newFiles[rowIndex]["Id_Documentacion"]=idDoc
        if (idDoc!=0)
            newFiles[rowIndex]["Orden"]=this.props.folders.find(x=>x.Id_Documentacion==idDoc).Orden_Documentacion
        else
            newFiles[rowIndex]["Orden"]=0;       

        let asign=newFiles.filter(x => x.Id_Documentacion!=x.Id_Documentacion_Inicial)
             
        this.setState({filesAutoEdit: newFiles,asignados:asign.length})
    }       

    async changeOrder(type){
        let newFiles=[...this.state.filesAutoEdit]
        if (type=='c')
        {  
            newFiles.sort((a, b) => {
                let oa = (a.Nombre).toLowerCase()
                let ob = (b.Nombre).toLowerCase()
                 if (oa < ob) {return -1;}
                 if (oa> ob) {return 1;}
                 })

            newFiles.sort((a, b) => {
            let oa=0
            let ob=0
            oa = a.Orden?parseInt(a.Orden):0
            ob = b.Orden?parseInt(b.Orden):0
            if (oa < ob) {return -1;}
            if (oa> ob) {return 1;}
            })
        }
        else
        {newFiles.sort((a, b) => {
            let oa = (a.Nombre).toLowerCase()
            let ob = (b.Nombre).toLowerCase()
             if (oa < ob) {return -1;}
             if (oa> ob) {return 1;}
             })}
        this.setState({filesAutoEdit: newFiles})
    }

    
  
    renderConfirmAsign() {       
       
        return (this.props.openAsign ?
                    <AsignFilesFolders openConfirm={this.props.openAsign} 
                                aceptConfirmation={async () => {
                                     await this.handleSaveAsign();                                   
                                    }}                            
                                declineConfirmation={() => this.cancelAsign()}                                                                                          
                                cellValueChanged={(idDoc,name)=>this.cellValueChanged(idDoc,name)}
                                changeOrder={(orderEst)=>this.changeOrder(orderEst)}                               
                                archivos={this.state.filesAutoEdit}
                                carpetas={this.props.folders}
                                asignados={this.state.asignados}
                                identificados={this.state.filesAutoEdit}
                                title={''}  
                                filesInFolders={true}
                                />
                                :null                            
            )
        }                

    render() {
        let { classes } = this.props
        let disableButtons = this.props.disableActions;    
        let {openMenu} = this.state;
       
        let isMobile_=isMobile();
        let numfiles= this.obtieneNumFiles();
        let reqFirma= this.hayRequisitosFirmaNoCompletos();
        let esCarpetaPadre = (this.props.estructura.id != null &&  this.props.estructuraEditable == 0);    
        let uploading= this.props.fileUpload.uploadInProgress;
        let moving=  this.props.moving;
        let recargaPendiente=(this.state.mustRefresh || this.props.mustRefresh) &&  (uploading || moving) 
        let noRecargando=  this.props.notDigital ||(!this.state.fetchingRemove && !this.props.fetchingData && !this.props.savingImport && !(this.state.data!=null && numfiles!=this.state.data.length )  &&  !recargaPendiente) 
        let disabledDropzone=this.props.estructura.id != null && this.props.permiteArchivos!=1 || this.state.fetchingAddFile || this.props.workEditable!=1 || this.props.fileUpload.uploadInProgress || this.state.fetchingRemove || this.props.moving 

        return (
            <div>
                {this.state.fetchingCenter ?
                    <Paper className="mt-3 mb-3">
                        <Grid container spacing={24}>
                            <Grid item xs={12} className='p-3 text-center'>
                                <CircularProgress/>
                            </Grid>
                        </Grid>
                    </Paper>:
                   

                    
                    this.props.openAsign ?
                    <Paper>
                        <Grid container spacing={16}>
                                <Grid item xs={12} className="py-2 mt-1">
                                <Typography variant="display4" className="d-flex mb-0 mr-1" style={{paddingLeft: 16}}gutterBottom >
                                        <Translate id="languages.fileUpload.changeFiles" /> {'('}
                                        <Typography variant="display4" color="primary" style={{paddingLeft: 1,paddingRight: 6,color: 'orange'}}  gutterBottom >
                                        {this.state.asignados}  </Typography>  {' de ' + this.state.filesAutoEdit.length+')'}                            
                                    </Typography>  
                                        
                                    <Typography variant={"body1"} style={{
                                        fontSize: 12,
                                        paddingLeft:25,
                                        marginRight:10
                                    }}>     
                                    <Translate id="languages.fileUpload.ExplanationChangeFolders"/>                                              
                                    </Typography>                                    
                                </Grid>                 
                        
                        </Grid>
                        {this.renderConfirmAsign()}
                        </Paper>    
                         :
                    
                    <Paper style={{ paddingLeft: 10,paddingTop:15 }}>
                         <div>
                            <Grid container spacing={16}>
                                <Grid item xs={12} className="py-2 mt-1">
                                    <Typography variant={"display4"} style={{paddingLeft:10,marginBottom:5}}>{this.props.estructura
                                        ? this.props.permiteArchivos==1? <Translate id="languages.fileUpload.filesInFolderWithFiles" />  :<Translate id="languages.fileUpload.filesInFolder" />
                                        : <Translate id="languages.fileUpload.filesOfWork" />}    
                                        <b>{  this.props.estructura.id == null ? null :' '+   this.props.titleEstructuraActiva}</b>
                                        {  noRecargando ? <text
                                        /* md style={{color: 'red'}}> ({this.state.data.length})</text></Typography> */
                                        style={{color: numfiles>0 ? (reqFirma ? 'red':'green') : 'orange'}}> {'('+numfiles+')'}</text>    
                                       : <CircularProgress size={14} style={{marginLeft: 6, marginTop: 0}}/>}                                                                                                                   
                                        </Typography> 
                                        
                                    <Typography variant={"body1"} style={{
                                        fontSize: 12,

                                        paddingLeft:25,
                                        marginRight:10

                                    }}>     
                                            {  this.props.workEditable == "1"  && <b><Translate id="languages.fileUpload.ExplanationFilesAdd" /></b> }   
                                            {  this.props.workEditable == "1"  &&  <Translate id="languages.fileUpload.ExplanationFilesAdd2" />}                                                
                                            {  this.props.workEditable == "1"  && (this.props.estructura.id != null && this.props.permiteArchivos==1 ?".":"," )}                                            
                                            {  this.props.workEditable == "1" && this.props.estructura.id == null && <Translate id="languages.fileUpload.ExplanationFilesAddWork"/>}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                                            {  this.props.workEditable == "1"  && esCarpetaPadre  && <Translate id="languages.fileUpload.ExplanationFilesAddFolder" /> }                                                                                                                                                                                                                                                  
                                            {  this.props.workEditable == "1" && esCarpetaPadre && this.props.titleEstructuraActiva}                                                                                                                                    
                                            {  this.props.workEditable == "1" && esCarpetaPadre && <Translate id="languages.fileUpload.ExplanationFilesAddFolder2"/>}                                            
                                            {  this.props.workEditable == "1"  && numfiles>0 && <Translate id="languages.fileUpload.ExplanationFilesMove" /> }     
                                    </Typography>
                                </Grid>                              
                               

                               { !this.state.userLocalFileInsertRequest  && 
                                <Grid item xs={12} className="px-3 py-0 text-right">
                                    <Grid container>                                      

                                        <Grid item xs={12} className="pr-1 text-right" style={{marginBottom:8}}>
                                            
                                        <div className="" style={{ float: 'right' }}> 

                                            {(this.props.estructura.id==null) ?
                                            <div className="" style={{ float: 'right' }}>    
                                            <Tooltip id="t1" title={!this.state.openMenu?this.props.translate("languages.generalButton.masOpciones"):''}>
                                                <IconButton   color="primary" aria-label="Info"  style={{marginLeft:0,marginTop: 2,marginRight:10,fontSize: 14, padding: '0px 0px 0px'}}
                                                  buttonRef={node => {
                                                    this.anchorEl = node;
                                                }}
                                                aria-owns={openMenu ? 'menu-list-grow' : undefined}
                                                aria-haspopup="true"
                                                onClick={this.handleToggle}>                                    
                                                        <Menu style={{paddingTop:6}}/>                                    
                                               </IconButton>                                     
                                            </Tooltip>   
                                            <Popper open={openMenu} anchorEl={this.anchorEl} style={{zIndex:2 }} transition disablePortal >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    id="menu-list-grow"
                                                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={this.handleClose}>
                                                            <MenuList >                                                                                       
                                                            {this.props.workEditable==1 && this.state.showImportButton && <MenuItem className={classes.masOpciones} onClick={()=>{this.handleImport()}} >
                                                                    <Button color="primary" className="px-2"> <Translate id="languages.fileUpload.importFiles" />                                                      
                                                                    </Button>
                                                                </MenuItem>}
                                                                
                                                                <MenuItem className={classes.masOpciones} onClick={()=>{this.handleDownload(true)}} >
                                                                <Tooltip id="t1" title={this.props.translate("languages.fileUpload.helpCompendio")}>                                                                                                                
                                                                    <Button color="primary" className="px-2"
                                                                    disabled={this.state.showDownloadButton !== true || this.state.fetchingDownload  || this.state.fetchingRemove || this.props.fileUpload.uploadInProgress}>
                                                                         <Translate id="languages.fileUpload.downloadCompendio" />                                                 
                                                                    </Button>
                                                                    </Tooltip>    
                                                                    </MenuItem> 
                                                                    
                                                                     
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                            </Popper>
                                            </div>
                                            :null}
                                                                      
                                                <Dropzone
                                                    className={'dropSelector'}
                                                    style={{

                                                        borderStyle: 'none',

                                                    }}
                                                    disabled={disabledDropzone}
                                                    accept="application/pdf"
                                                    onDrop={(acceptedFiles) => this.onDropUpload(acceptedFiles) }>
                                                    {(this.props.workEditable == "1" && this.props.estructura.id != null && this.props.permiteArchivos == 1) && <Button color="primary"                                                    
                                                            style={{
                                                                fontSize: 12,
                                                                padding: '0px 8px'                                                                
                                                            }}
                                                            disabled={disabledDropzone}>
                                                        <Translate id="languages.generalButton.addFiles" />
                                                        {!uploading?  
                                                            <CloudUpload style={{marginLeft: 5}}/> : 
                                                            <CircularProgress size={22} style={{marginLeft: 2}}/>
                                                         }
                                                        
                                                        
                                                    </Button>}
                                                </Dropzone> 
                                               
                                                {this.state.fetchingAddFile ? <CircularProgress size={24}
                                                                                                className={classes.buttonProgress} /> : null}
                                            </div>
                                            <div className="" style={{ float: 'right' }}>

                                            {this.state.fetchingDownload > 0 ? <CircularProgress size={24}
                                                                                                 className="align-self-center" /> : 
                                                <Button className="px-2"
                                                        color="primary"
                                                        style={{fontSize: 12, padding: '8px 8px'}}
                                                        onClick={() => {
                                                            this.handleDownload()
                                                        }}
                                                        disabled={this.state.showDownloadButton !== true || this.state.fetchingDownload  || this.state.fetchingRemove || this.props.fileUpload.uploadInProgress}>
                                                    <Translate id="languages.generalButton.download" />
                                                </Button>
                                                }
                                            </div>
                                            <div className="" style={{ float: 'right' }}>
                                            {this.state.fetchingRemove ? <CircularProgress size={24}
                                                                                                    className="align-self-center" /> :
                                              this.props.workEditable==1 &&<Button className="px-2"
                                                        color="primary"
                                                        style={{fontSize: 12, padding: '4px 8px '}}
                                                        onClick={() => {this.handleRemove()   } }   
                                                                                                                                                        
                                                       // onClick={() => {this.props.handleRemove(this.itemsToRemove)}}
                                                        disabled={this.state.showDeleteButton !== true || this.state.fetchingRemove   ||  disableButtons ||  this.props.workEditable!=1 || this.state.fetchingDownload || this.props.fileUpload.uploadInProgress}>
                                                    <Translate id="languages.generalButton.delete" />
                                                </Button>
                                                }
                                            </div>

                                            <div className="" style={{ float: 'right' }}>                                               

                                                    {this.props.workEditable==1 &&
                                                            <Button className="px-1"
                                                                            style={{fontSize: 12, padding: '4px 10px '}}
                                                                            color="primary"
                                                                            onClick={() => {this.autoAsign()}}
                                                                            disabled={this.state.showAsignButton !== true || this.state.fetchingRemove  || disableButtons || this.state.fetchingDownload || this.props.fileUpload.uploadInProgress}>
                                                                        <Translate id="languages.fileUpload.changeFiles" />
                                                                                                                                   
                                                                    </Button>}                                                    
                                                                    
                                            </div>

                                            <div className="" style={{ float: 'left' }}>

                                                    {this.props.workEditable==1 && this.props.estructuraIdDocumentacion==ID_DOCUMENTACION_GEOTECNICO && this.props.idTipoTrabajo!=ID_TRABAJO_VAL_GEOTECNICO && this.props.trabajosGeoRegistrado.length>0 && 
                                                            <Button className="px-1"
                                                                    style={{fontSize: 12, padding: '4px 10px '}}
                                                                    color="primary"
                                                                    onClick={()=>{this.handleImportGeo()}} 
                                                                    disabled={ this.state.fetchingRemove  ||  this.state.fetchingDownload || this.props.fileUpload.uploadInProgress}>
                                                                     <Translate id="languages.fileUpload.importFilesGeo" />
                                                                                   
                                                                </Button>}
                                            </div>
                                      
                                        </Grid>

                                    </Grid>
                                </Grid>
                                }
                            </Grid>
                            
                            {!this.state.userLocalFileInsertRequest  && 
                            <div style={{ marginLeft: -10, background: "#f5f5f5" }}>
                                
                               <Divider height={2} />
                                
                                <Grid container className="py-1 px-2">
                               

                                    <Grid item md={6} xs={6} className="d-flex mr-3">
                                        <Checkbox
                                            checked={this.state.checkAll}
                                            onChange={this.handleCheckAll()}
                                            disabled={this.state.data.length==0}
                                            className="pl-1"
                                            style={{
                                                padding: 0,
                                                marginRight: 6
                                            }}
                                        />
                                        <Typography variant="subtitle2" className="text-uppercase text-center">
                                            <Translate id="languages.fileUpload.fileName" />
                                        </Typography>
                                    </Grid>
                                    <Grid item  xs={!isMobile_ ? 4 : 4}>
                                        <Typography variant="subtitle2" className="text-uppercase text-left pl-2">
                                          {!isMobile_ ? <Translate id="languages.fileUpload.folder" /> : null}
                                        </Typography>
                                    </Grid>
                                    <Grid item  xs={!isMobile_ ? 1 : 1}  className={!isMobile_ ? "text-uppercase text-left" : "text-uppercase text-left"}> {/*text-right pr-2">*/}
                                        <Typography variant="subtitle2">
                                        {!isMobile_ ?<Translate id="languages.fileUpload.firm" /> : null}
                                        </Typography>
                                    </Grid>
                                </Grid>
                               <Divider height={2} />
                            </div>
                            }
                        </div>



                         {this.state.userLocalFileInsertRequest ?
                                        <Grid item xs={12}
                                              style={{paddingLeft: 2, paddingBottom: 2,paddingRight: 2}}>
                                            {<Dropzone accept="application/pdf"
                                                      style={{width:'calc(100% - 20px)',height:200,marginBottom:6,marginLeft:6,marginRight:6}}


                                                onDrop={(acceptedFiles) => this.onDropMoveItem(acceptedFiles)}>
                                                {() => (
                                                    <div  style={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        flexDirection: "column",
                                                        alignItems:"center",
                                                        padding: 20,
                                                        borderWidth: 2,
                                                        borderRadius: 2,
                                                        borderColor:' #eeeeee',
                                                        borderStyle:"dashed",
                                                        width:'100%',
                                                        height:'100%',
                                                        backgroundColor:"#fafafa",
                                                        color: "#bdbdbd",
                                                        outline: "none",
                                                        transition:"border .24s ease-in-out"
                                                    }}> 
                                                        <inpu />
                                                           <p><Translate id="languages.fileUpload.fileUploadDrop"/></p>
                                                    </div>
                                                )}
                                            </Dropzone>}

                                        </Grid>
                                        :
                                        null
                        }


                        {this.state.fetchingCenter ?
                            <Paper style={{ marginLeft: -10 }} className="mt-3">
                                <Grid container spacing={24}>
                                    <Grid item xs={12} className='p-3 text-center'>
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                            </Paper> :

                            !this.state.userLocalFileInsertRequest  &&
                            <Grid containter>
                                <div 
                                    onDragLeave={async () => {this.dragOverUploadFileEnd()}}
                                    onDragEnter={async () => {this.dragOverUploadFile()}} > 
                                <Grid container spacing={16}>                                                           
                              
                             
                                {
                                    !(this.state.data && this.state.data.length > 0) || (this.state.temporalFiles && this.state.temporalFiles.length > 0) ?

                                        
                                        <Grid item xs={12}>
                                            {//noRecargando?
                                            <Grid container spacing={24}>
                                                <Grid item xs={12} className="text-center py-4">
                                                {//noRecargando &&
                                                    <Typography variant="subtitle1" gutterBottom>
                                                    <Translate id="languages.fileUpload.noResult" />   
                                                    </Typography>}

                                                    {this.props.workEditable == "1"  && this.props.estructura.id != null && this.props.permiteArchivos==1 && //noRecargando &&                                                   
                                                    <Typography variant="body1" className="text-center py-2" gutterBottom>                                                                                
                                                        <Translate  id="languages.fileUpload.noFileUploadFolder"/>
                                                     </Typography> }                                                                                                                                                
                                                    {this.props.workEditable == "1"  && this.props.estructura.id == null &&   //noRecargando && 
                                                     <Typography variant="body1" className="text-center py-2" gutterBottom>
                                                        <Translate  id="languages.fileUpload.noFileUploadFolder2"/> 
                                                     </Typography>}                                                                                                                                                                                                    
                                                                                                      
                                                 </Grid>
                                            </Grid>
                                            // :
                                            // <Grid item xs={12} className='p-3 text-center'>
                                            //  <CircularProgress size={24}/>
                                            //  </Grid>
                                            }

                                        </Grid>
                                        :
                                        <Grid item xs={12}>
                                            <Grid container spacing={24}>
                                           
                                                <Grid item xs={12} style={{ paddingLeft: 2, paddingBottom: 2 }}>
                                             
                                                    {
                                                        this.state.data && this.state.data.map((item, pos) => {
                                                       
                                                            let r1=item.Razon_Firma_KO_1;
                                                            let r2=item.Razon_Firma_KO_2;
                                                            let sD=this.state.detallesArchivo;

                                                            return (<div draggable={this.props.workEditable==1}
                                                                         className={'draggable'}
                                                                         onDragEnd={() => { 
                                                                                    this.props.dragging(false); 
                                                                                    this.endDrag();}}
                                                                         onDragStart={this.dragStart(item, false)}
                                                                         

                                                                         style={{ backgroundColor: '#cecece' }}>
                                                                <ExpansionPanel classes={{ root: classes.rootPanel }}
                                                                                expanded={this.state.panelExpanded === item.Id_Estructura}
                                                                                onChange={this.expandPanel(item.Archivo, item.Id_Estructura)}
                                                                                >
                                                                   
                                                                     <ExpansionPanelSummary expandIcon={!isMobile_ ? <ExpandMoreIcon/> : item.Requisitos_Firma_Completos ? <ExpandMoreIcon style={{ color: green[500] }} /> : <ExpandMoreIcon style={{ color: red[500] }} />}
                                                                                           classes={{ content: classes.margin, expanded: classes.margin, root: pos % 2 !== 0 && classes.backgroundColor }}
                                                                                           className="pl-0">                     
                                                                        <Grid container spacing={0}>  

                                                                                                                                       
                                                                        {!isMobile_? <Grid item xs={1} className= "align-self-center"  >
                                                                                <Checkbox 
                                                                                    checked={item.checked ? item.checked : false}
                                                                                    onChange={this.handleChange("checked", pos, 'data')}
                                                                                    value={item.Archivo}
                                                                                />
                                                                               </Grid> :
                                                                               <Grid item xs={1} className= "align-self-center"  style={{paddingLeft:0 ,  align: 'left'}}>
                                                                               <Checkbox 
                                                                                   checked={item.checked ? item.checked : false}
                                                                                   onChange={this.handleChange("checked", pos, 'data')}
                                                                                   value={item.Archivo}
                                                                               />
                                                                              </Grid> 
                                                                            }   
                                                                               <Grid item xs={!isMobile_? 6:7} className="align-self-center" style={{  paddingLeft:15 , align: 'left'}} >
                                                                             {!isMobile_?
                                                                                <Typography   title={ item.Archivo }  style={{  overflow: 'hidden',   marginRight: '10%', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className={item.Requisitos_Firma_Completos ? "" : classes.red}>
                                                                                    { item.Archivo }
                                                                                </Typography>
                                                                              : <Typography   title={ item.Archivo.replace("_signed","").replace("_firmado","")} style={{   overflow: 'hidden',   marginRight: '10%', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className={item.Requisitos_Firma_Completos ? "" : classes.red}>
                                                                              {  item.Archivo.replace("_signed","").replace("_firmado","")}
                                                                                </Typography>                                                                       
                                                                                 }  
                                                                            </Grid>
                                                                            <Grid item xs={ 4} className="align-self-center" style={{   align: 'left'}} >
                                                                                <Typography onClick={()=>{
                                                                                    //TODO: Expandir toda la estructura en el árbol del menu lateral izquierdo
                                                                                    this.props.handleChangeEstructura(item.Id_Estructura_Padre,item.Carpeta)
                                                                                    this.props.expandMenuTree(this.props.trabajo, item);
                                                                                }} style={{textDecoration:'underline'}} className={item.Requisitos_Firma_Completos ? "" : classes.red}>
                                                                                    {!isMobile_ ? item.Carpeta : item.Carpeta!= null ? item.Carpeta.substring(item.Carpeta.indexOf("-")+1) : null  }
                                                                                </Typography>
                                                                            </Grid>
                                                                            {!isMobile_ ?    <Tooltip title={  item.Requisitos_Firma_Completos == 1 ? 'Requisitos firma digital completos' : (item.Requisitos_Firma_Completos == -1 ?'Requisitos firma digital a evaluar en COAG': 'Requisitos firma digital incompletos. Click para +info.') } >                                                                            
                                                                            <Grid item xs={1} className="text-right align-self-center">
                                                                                    
                                                                                {item.Requisitos_Firma_Completos == 1 && <CheckCircle className={classes.green} /> }
                                                                                {item.Requisitos_Firma_Completos == 0 && <Close className={classes.red} /> }
                                                                                {item.Requisitos_Firma_Completos == -1 && <Info className={classes.green} /> }   
                                                                                                                                                         
                                                                            </Grid>
                                                                            </Tooltip>:
                                                                             null
                                                                            }
                                                                         
                                                                        </Grid>

                                                                    </ExpansionPanelSummary>
                                                                    <ExpansionPanelDetails className={pos % 2 !== 0 && classes.backgroundColor}>
                                                                                                                                                                 
                                                                                                                                        
                                                                        {this.state.loadingDetallesArchivo
                                                                            ? <CircularProgress size="18" align="center"/>
                                                                            : <Grid container spacing={6}>
                                                                                <Grid item xs={12}>
                                                                                    <Grid container spacing={0}>
                                                                                        <Grid item xs={6}>
                                                                                            <Typography variant="button" gutterBottom className="text-uppercase">
                                                                                                <Translate id="languages.fileUpload.fileSize" />
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={6}>
                                                                                            <Typography gutterBottom>
                                                                                                {sD && sD.Archivos && sD.Archivos[0] ? this.renderSize(sD.Archivos[0].Longitud) : ""}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Grid item xs={12}>
                                                                                    <Grid container spacing={0}>
                                                                                        <Grid item xs={6}>
                                                                                            <Typography variant="button" gutterBottom className="text-uppercase">
                                                                                                <Translate id="languages.fileUpload.uploadDate" />
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={6}>
                                                                                            <Typography gutterBottom>
                                                                                                {sD && sD.Archivos && sD.Archivos[0] ? sD.Archivos[0].Fecha : ""}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Grid item xs={12}>
                                                                                    <Grid container spacing={0}>
                                                                                        <Grid item xs={6}>
                                                                                            <Typography variant="button" gutterBottom>
                                                                                                <Translate id="languages.fileUpload.requiredFirms" />
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={6}>
                                                                                            <Typography gutterBottom>
                                                                                                {sD && sD.Archivos && sD.Archivos[0] ? sD.Archivos[0].Firmas_Requeridas : ""}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Grid item xs={12}>
                                                                                    <Grid container spacing={0}>
                                                                                        <Grid item xs={6}>
                                                                                            <Typography variant="button" gutterBottom>
                                                                                                <Translate id="languages.fileUpload.fileFirms" />
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                                                                                                              
                                                                                           <Grid item xs={6}>    
                                                                                            <Grid item xs={12} className="pt-0">
                                                                                                {
                                                                                                    sD &&
                                                                                                    sD.FirmasDigitales &&
                                                                                                    sD.FirmasDigitales.length > 0
                                                                                                        ? sD.FirmasDigitales.map((fd, pos) => {
                                                                                                            return fd.Id_Archivo === item.Id_Archivo
                                                                                                                ? <div className="pt-0 pb-2 px-0">
                                                                                                                    <Typography variant="body1">
                                                                                                                        {`${fd.Nif} ${fd.Nombre}`}
                                                                                                                        <br/>
                                                                                                                        {`${fd.Numero_Serie != null &&  fd.Numero_Serie.length>15 ? fd.Numero_Serie.substring(0,15) + "..." : fd.Numero_Serie}`}
                                                                                                                        <br/>
                                                                                                                        {`${fd.Autoridad_Certificadora}`}
                                                                                                                        <br/>
                                                                                                                        {fd.Fecha_Fin_Validez_Certificado && 'Caduca ' + `${moment(new Date(fd.Fecha_Fin_Validez_Certificado)).format("DD/MM/YYYY hh:mm:ss")}` }
                                                                                                                        <br/>                                                                                                                       
                                                                                                                     
                                                                                                                    </Typography>

                                                                                                                </div>
                                                                                                                : ""
                                                                                                        })
                                                                                                        : <div>
                                                                                                            <ListItemText primary="--" />
                                                                                                        </div>                                                                                                        
                                                                                                }   
                                                                                                                                                                                                   
                                                                                            </Grid>                                                                                            
                                                                                            </Grid>
                                                                                                                                                                                                                                                   

                                                                                    </Grid>                                                                                    
                                                                                </Grid>

                                                                                {(r1!==''||r2!=='') && <Grid item xs={12}>
                                                                                    <Grid container spacing={0}>
                                                                                        <Grid item xs={6}>
                                                                                            <Typography variant="button" gutterBottom>{'Incidencias'}                                                                                             
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={6}>
                                                                                            {r1!=='' && <Typography variant="body1" className={classes.red}>{r1}</Typography> }       
                                                                                            {r2!=='' && <Typography variant="body1" className={classes.red}>{r2}</Typography>  }     
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>}
                                                                              
                                                                                <Grid item xs={12}>
                                                                            <span className={classes.link} onClick={() => this.handleDocumentView(item)}>
                                                                                <Translate id="languages.fileUpload.viewDocument" /> { item.Archivo }
                                                                            </span>
                                                                                </Grid>
                                                                            </Grid>}
                                                                        
                                                                    </ExpansionPanelDetails>                                                                    
                                                                </ExpansionPanel>
                                                            </div>);
                                                        })
                                                    }
                                                   
                                                </Grid>
                                                 
                                            </Grid>                                            
                                        </Grid>                                    
                                      
                                }
                                </Grid>
                            </div> 
                            </Grid>
                        }

                       
                    </Paper>
    
                }
            </div>

        );
    }


}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(FilesInFolder2)));
