import React, {Component, Fragment} from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import { Typography, Grid, Paper, TextField, Button } from '@material-ui/core';
import { Table, TableCell, TableHead, TableBody, TableRow, Divider } from '@material-ui/core';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import ReactQuill from "react-quill";
import PropTypes from 'prop-types';
import { fetchErrorExpediente } from '../../../../actions/expedientes';
import { withRouter } from 'react-router-dom';
import ApiCoag from "../../../../api/ApiCoag";
import PersonOrg from '../../../Agentes/Promotores/addPerson';
import {isMobile} from "../../../../api/index";

const styles = theme => ({
  divGrey: {
    backgroundColor: grey[100],
    margin: 16
  },
  backgroundGrey: {
    backgroundColor: grey[100]
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "97%",
    textAlign: 'left',
    marginTop: 5
  },
  headHeight: {
    height: 30 //40
  },
  table: {
    minWidth: 200,
  },
  tableArquitecto: {
    minWidth: 190,
  },
  tableBodyHeight: {
    minHeight:50
  },
  fab: {
    margin: theme.spacing.unit,
    marginTop: 25,
    position: 'absolute',
    marginLeft: 40
  },
  tableBorder: {
    border: "2px solid " + grey[200]
  },
  buttonEdit: {
    border: "1.2px solid",
    margin: 2,
    padding: 6,
  },
  withoutRadius: {
    borderRadius: 0
  },
  headerBorder: {
    border: "2px solid " + grey[200],
    borderBottom: 0
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRow: {
    cursor:'pointer',
    '&:hover': {backgroundColor: "#f0f0f0"}
},
});

const CustomTableHead = withStyles(theme => ({
  head: {
    backgroundColor: grey[100],
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

class TipoExpediente extends Component {
    constructor(props) {
    super(props);
    this.state = {
      sourceExpediente: this.props.sourceExpediente,
      //expediente: this.props.expediente,
      colegiados:this.props.expediente.Colegiados,
      promotores:this.props.expediente.Promotores,
      descripcionEncomendaActual:this.props.sourceExpediente.Descripcion_Encomenda_Actual,
      promotorSel:null
    }
  }

 

  //async UNSAFE_componentWillMount() {
    async componentDidMount() {
    if (this.props.trabajo >0 && this.props.trabajo!=this.state.sourceExpediente.Id_Trabajo_Encomenda_Actual)
    {
      let workDetails =    await ApiCoag.expedientes.trabajo.getDatosGenerales(this.state.sourceExpediente.Id_Expediente, this.props.trabajo);
    
    await this.setState({
        colegiados:workDetails.Colegiados,
        promotores:workDetails.Promotores  ,
        descripcionEncomendaActual:workDetails.Trabajos[0].Descripcion_Encomenda
      })
    }
  }
  getColegiadosSociedad(sociedad){
    return this.state.colegiados.filter((item) => item.Id_Sociedad === sociedad);
  }

  async ModificarEncomenda(idExpediente) {      

    let result = await ApiCoag.expedientes.getEncomendaActual(idExpediente);
    if (result && result.MensajesProcesado && result.MensajesProcesado.length == 0 ) {      
      if (result.Expediente[0].SePuede_CrearTrabajo>=-1)
          this.props.history.push(`/comunicacion/${this.state.sourceExpediente.Id_Expediente}/1`)      
      else
          this.setState({openDialog:true,mensaje:result.Expediente[0].Mensaje_CrearTrabajo})
    }
    
  }

   

  renderAgentsTable() {
    let { classes } = this.props;
    return (
      <div className="p-3">
        <Grid container className={`${classes.headerBorder}`}>
          <Grid item md={12}>
            <Typography variant="subtitle1" gutterBottom className="m-1">
              <Translate id="languages.fichaExpediente.titleAgents" />
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <Table className={`${classes.tableArquitecto} ${classes.tableBorder}`}>
            <TableHead>
              <TableRow className={classes.headHeight}>
                <CustomTableHead className="text-uppercase px-3 text-center"></CustomTableHead>
                <CustomTableHead className="text-uppercase px-3 text-center">
                  <Translate id="languages.fichaExpediente.tableColumnName" />
                </CustomTableHead>
                <CustomTableHead className="px-2 text-uppercase text-center">%</CustomTableHead>
                <CustomTableHead className="text-uppercase px-1 text-center">
                  <Translate id="languages.fichaExpediente.tableColumnFunctions" />
                </CustomTableHead>
                <CustomTableHead className="text-uppercase px-1"></CustomTableHead>
              </TableRow>
            </TableHead>


            <TableBody className={classes.tableBodyHeight}>
              {
                this.state.colegiados!=null ? this.state.colegiados.length === 0 ?
                    <TableRow>
                      <TableCell colSpan={5}></TableCell>
                    </TableRow>
                    : this.state.colegiados.map((row, index) => {
                      if((row.Id_Tipo_Colegiado===1)&&(row.Id_Sociedad===0||row.Id_Sociedad===undefined)){
                        return (
                            <TableRow className={`${classes.row}`} key={index}>
                              <TableCell component="th" scope="row" className="px-1 text-center">
                                {row.Id_Colegiado}
                              </TableCell>
                              <TableCell className="p-0 text-center">{row.Nombre_Completo}</TableCell>
                              <TableCell className="p-3 text-center">{row.Porcentaje != null ? `${parseFloat(row.Porcentaje).toFixed(2).toString().replace('.00','')}` : ""}</TableCell>
                              <TableCell className="p-0 text-center">{row.Funcion}</TableCell>
                              <TableCell style={{padding:0}} className=" text-center">

                              </TableCell>


                            </TableRow>
                        );
                      }else if((row.Id_Tipo_Colegiado>1)){
                        return (
                            <Fragment>
                              <TableRow className={`${classes.row}`} key={index}>
                                <TableCell style={{borderBottom:'0px solid',padding:0}}  scope="row" className="px-1 text-center">
                                  {row.Id_Colegiado}<br/>

                                </TableCell>
                                <TableCell style={{borderBottom:'0px solid',padding:0}} className="p-0 text-center">
                                  {row.Nombre_Completo}


                                </TableCell>
                                <TableCell style={{borderBottom:'0px solid',padding:0}} className="p-3 text-center">
                                  {row.Porcentaje != null ? `${parseFloat(row.Porcentaje).toFixed(2).toString().replace('.00','')}` : ""}

                                </TableCell>
                                <TableCell style={{borderBottom:'0px solid',padding:0}} className="p-0 text-center">
                                  <p>-</p>

                                </TableCell>
                                <TableCell style={{borderBottom:'0px solid',padding:0}} className=" text-center">

                                </TableCell>


                              </TableRow>
                              {
                                this.getColegiadosSociedad(row.Id_Colegiado).map((colegiado,p)=> {
                                  let conBorde = p<(this.getColegiadosSociedad(row.Id_Colegiado).length-1)?'0px solid':'1px solid rgba(224, 224, 224, 1);'
                                  return (<TableRow className={`${classes.row}`} key={index}>
                                    <TableCell style={{borderBottom:conBorde,padding:0}} scope="row" className="text-center">
                                      -
                                    </TableCell>
                                    <TableCell style={{borderBottom:conBorde,padding:0}} className=" text-center">
                                      {colegiado.Nombre_Completo+' ('+colegiado.Id_Colegiado+')'}

                                    </TableCell>
                                    <TableCell  style={{borderBottom:conBorde,padding:0}}className=" text-center">
                                      -
                                    </TableCell>
                                    <TableCell style={{borderBottom:conBorde,padding:0}} className=" text-center">

                                      {colegiado.Firma == 1 ? colegiado.Funcion : ''}
                                    </TableCell>
                                    <TableCell style={{borderBottom:conBorde,padding:0}} className=" text-center">

                                    </TableCell>


                                  </TableRow>)
                                })
                              }
                            </Fragment>
                        );
                      }

                    }):null
              }
            </TableBody>

          </Table>
        </div>
      </div>
    );
  }

  renderPromotorsTable() {
    let { classes } = this.props;
    return (
      <div className="p-3">
        <Grid container className={classes.headerBorder}>
          <Grid item md={10}>
            <Typography variant="subtitle1" gutterBottom className="m-1">
              <Translate id="languages.fichaExpediente.titlePromotors" />
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <Table className={`${classes.table} ${classes.tableBorder}`}>
            <TableHead>
              <TableRow className={classes.headHeight}>
                <CustomTableHead className="text-uppercase px-3 text-center">Nif</CustomTableHead>
                <CustomTableHead className="text-uppercase text-center">
                  <Translate id="languages.fichaExpediente.tableColumnName" />
                </CustomTableHead>
              {/* <CustomTableHead className="pl-3 text-uppercase text-center">%</CustomTableHead>*/}
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBodyHeight}>
              {
               this.state.promotores!=null ? this.state.promotores.length === 0 ?
                  <TableRow>
                    <TableCell colSpan={3}></TableCell>
                  </TableRow>
                  : this.state.promotores.map((row, index) => {
                    return (
                      <TableRow  onClick={()=>{this.detallesPromotor(row)}} className={classes.tableRow} key={index} >
                        <TableCell component="th" scope="row" className="px-1 text-center">
                          {row.Nif}
                        </TableCell>
                        <TableCell className="pl-3 text-center">{row.Nombre_Completo}</TableCell>
                       {/*} <TableCell className="p-3 text-center">{row.Porcentaje}</TableCell>*/}
                      </TableRow>
                    );
                  }):null
              }
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }




  render() {
    let { classes } = this.props;
    return (
      
      <Paper className={!isMobile() ? `${classes.withoutRadius} m-3` : `${classes.withoutRadius} m-1`}>        
        <Grid container spacing={16} className="my-3 p-2">
          
          <Grid item xs={12} className="p-0">
            <Grid item xs={12} className=" d-flex justify-content-between">
              <Typography variant="display4" gutterBottom className="mx-2 my-1">
                <Translate id="languages.fichaExpediente.Encargo" />
              </Typography>
              <Button color="primary" onClick={
                //() => this.props.history.push(`/comunicacion/${this.state.sourceExpediente.Id_Expediente}/1`)
                
                ()=> this.ModificarEncomenda(this.state.sourceExpediente.Id_Expediente)
              }               
              disabled={(this.props.trabajo && this.state.sourceExpediente.Id_Trabajo_Encomenda_Actual != this.props.trabajo)  } 
                >
                  {this.state.sourceExpediente.Id_Trabajo_Encomenda_Actual 
                      ? <Translate id="languages.fichaExpediente.editExpOptionTitle" />
                      : <Translate id="languages.fichaExpediente.addCurrentEncomenda" />
                  }
               {/* <Check />*/}
              </Button>
            </Grid>
            <Divider style={{ height: 3 }} />
          </Grid>

          {/* md <Grid container spacing={24}> */}
          <Grid container spacing={0} >           
           <Grid item xs={12} className="p-2" >             
              <TextField
                value={this.state.descripcionEncomendaActual}
                label={<Translate id="languages.fichaExpediente.titleExpedientType" />}
                className={`${classes.textField} mt-2 text-uppercase`}
              
                disabled={true}
                name="expedientType" />
            </Grid>            

            <Grid item xs={12}>
            {this.renderPromotorsTable()}
            {this.renderAgentsTable()}          
            </Grid>
            
          </Grid>
        </Grid>
        {this.renderNotify()}
        {this.detalles()}
      </Paper>
    );
  }

  renderNotify() {
    return (     
      <div>
        <Dialog
          open={this.state.openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" >
            <h2 style={{color:'#ed4140',fontSize:'1rem'}}>Incidencias</h2>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography component="h2" variant="display1" gutterBottom style={{fontSize:"1rem"}}>              
                  <ReactQuill value={this.state.mensaje} readOnly theme='bubble' />
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>                      
            <Button  className="mx-2" color="primary"
              onClick={() => { this.setState({openDialog:false})                             
              }}>
               <Translate id="languages.generalButton.aceptar"/>
            </Button>           
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  detallesPromotor(promotor){
    this.setState({
        showDetalles:true,
        promotorSel:promotor
        

    })

}

  detalles() {

    if(this.state.showDetalles){      
        return (<div>
            <Dialog open={this.state.showDetalles}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                {'Datos de contacto de Promotor '}   <Translate id="languages.generalText.enEncargo"/>              
                </DialogTitle>

                <DialogContent>
                <PersonOrg key={this.state.promotorSel.Nif} tipo={this.state.value} promotor={this.state.promotorSel } 
          onCancelPromotor={() => { this.handleCancel() }}          
          modoConsulta={true}
           />

                </DialogContent>

                <DialogActions>
                    <Button onClick={async () => {
                        this.setState({ showDetalles: false });

                    }} variant="contained"
                            color="primary" autoFocus style={{ height: 30, minHeight: 30 }}
                            className="p-0">
                        Aceptar

                    </Button>

                </DialogActions>
            </Dialog>
        </div>);
    }

}
  

}




const mapStateToProps = (state) => ({
  funcionesTipologia: state.trabajos.funcionesTipologia.data ? state.trabajos.funcionesTipologia.data.Tipos_Trabajos_Tunciones : [],
})

const mapDispatchToProps = {
  fetchErrorExpediente
};

TipoExpediente.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(TipoExpediente))));