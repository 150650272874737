import React, { Component } from "react";
import {  withStyles,Tooltip,IconButton} from '@material-ui/core';  
import { MyLocation,LocationOn } from '@material-ui/icons';
import {grey} from '@material-ui/core/colors';
import {urlCatastro} from "../../api/index";
import {getDefaultLanguage} from "../../api/index";




const iconUrl = (nombre) => { 
    return require(`../Address/Iconos//${nombre}`); 
};


const styles = theme => ({
  buttonAction: {
      border: "2px solid",
      padding: "4px", margin: "4px"
  },
  truncate: {
    overflow: "hidden",
    textOverflow: "ellipsis" 
      }
});


class GeoRenderer extends Component {
  constructor(props) {
    super(props);
    
  }
   
    haiGeo= (referencia,latitud) => { 
        if (referencia!=null && referencia!='' )
            return 'ref';

        if  (latitud!=null && latitud!='')
          return 'latitud';

        return ''           
    
  }

 
  render() {
    let { classes } = this.props;
    let haiGeo=this.haiGeo(this.props.data.Referencia,this.props.data.Latitud)
    let idi=getDefaultLanguage();   
    let irAEmp=(idi==2?'Ir á xeolocalización do emprazamento':'Ir a geolocalización del emplazamiento');
    let irACatastro='Ir a catastro'; 

    let geoEmp=(idi==2?'Xeolocalizar':'Geolocalizar');
    return (<div className={classes.truncate}>                         
                {haiGeo==''?
                  this.props.data.Hay_Tramitados!=null &&  this.props.data.Hay_Tramitados!=0 && this.props.ocultarGeolocalizacion!=true? 
                <Tooltip title={geoEmp} >
                <IconButton  color="secondary"  style={{paddingTop: 0,paddingBottom: 0,margin:0,paddingRight:3,paddingLeft:3}}>
                    <MyLocation style={{  color:'orange'}}/>                  
                </IconButton>
                </Tooltip>:
                <IconButton  color="primary"  style={{marginRight:24,paddingRight:3,paddingLeft:3}}>
                                    
                </IconButton>
                 :                  
                 <Tooltip title={irAEmp} >
                  <IconButton  color="secondary" style={{paddingTop: 0,paddingBottom: 0,margin:0,paddingRight:3,paddingLeft:3}}>            
                  <LocationOn style={{ padding:0,margin:0,size:10 , color:this.props.data.Tipo!=2?grey[500]:grey[400]}} />                                                 
                 </IconButton>
              </Tooltip>}
              
             {this.props.data.Georeferencia?
                 <Tooltip  title={irACatastro} >
             <a alt='catastro' style={{color:'black'}} target="_blank" href={urlCatastro(this.props.data.Georeferencia)} >{this.props.data.Ubicacion}</a>             
             </Tooltip>  :
             this.props.data.Ubicacion
              }
               
             
                
    </div>
    );
  }
};
export default withStyles(styles)(GeoRenderer);